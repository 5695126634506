/**
 * @file TripItineraryCard
 *
 * Component is used in both create and update flows.
 * Only change is which variant of AdditionalTripInfoBlock is passed in.
 */

import React from "react";
import moment from "moment-timezone";
import first from "lodash/first";

import { Box, Card, Divider, Typography } from "@mui/material";

import { useScreenSize } from "../../../../../globals/hooks";
import { dateFormatter } from "../../../../../globals/utils/helpers";
import {
  Airport,
  RoundTripVariant,
  TextRecipientEnum,
  TripCategory,
} from "../../../../../types";
import TripCancelledBanner from "../../../TripCancelledBanner";
import TripTimeline from "./components/TripTimeline/TripTimeline";
import TripItineraryHeader from "./components/TripItineraryHeader";
import TripMap from "./components/TripMap/TripMap";
import FlightChangeDisplay from "components/globals/FlightChangeDisplay";
import { ApolloQueryResult } from "@apollo/client";

type TripItineraryCardProps = {
  orderNumber?: string;
  refetch?: (
    variables?: Partial<{
      id: string;
    }>
  ) => Promise<ApolloQueryResult<any>>;
  trip: {
    id?: string;
    totalDuration?: number;
    estimatedDuration?: number;
    useTotalDuration?: boolean;
    totalGroupSize: number;
    tripCategory: TripCategory;
    roundTripVariant?: RoundTripVariant;
    cancelledAt?: Date;
    googleDirectionsResult?: {};
    tripNumber?: string;
    routes: {
      vehicle?: {};
      publicId?: string;
    }[];
    stops: {
      stopIndex: number;
      location: string;
      dateTime?: string;
      airport?: Airport;
      originalDateTime: Date;
      flightOffset: number;
    }[];
    statusUpdateTextTo?: TextRecipientEnum;
  };
  additionalTripInfoBlock: React.ReactNode;
  isQuote: boolean;
  mode: "update" | "create";
  hasReturnTrip?: boolean;
  isReturnTrip?: boolean;
};

function TripItineraryCard(props: TripItineraryCardProps) {
  const {
    trip,
    orderNumber,
    additionalTripInfoBlock,
    isQuote,
    mode,
    hasReturnTrip,
    isReturnTrip,
  } = props;

  const confirmationNumber = orderNumber && `${orderNumber}-${trip.tripNumber}`;
  const pickUpStop = first(trip.stops);

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <>
      <Card variant="outlined">
        <Box m={3}>
          <TripItineraryHeader
            totalDuration={trip.totalDuration}
            estimatedDuration={trip.estimatedDuration}
            useTotalDuration={trip.useTotalDuration}
            totalGroupSize={trip.totalGroupSize}
            confirmationNumber={confirmationNumber}
            tripCategory={trip.tripCategory}
            roundTripVariant={trip.roundTripVariant}
            tripId={trip.id}
            statusUpdateTextTo={trip.statusUpdateTextTo}
            mode={mode}
            hasReturnTrip={hasReturnTrip}
            isReturnTrip={isReturnTrip}
          />
          {trip.cancelledAt && (
            <Box mt={3}>
              <TripCancelledBanner cancelledAt={trip.cancelledAt} />
            </Box>
          )}
          <Box
            mt={2}
            mb={1}
            display="flex"
            flexDirection={isMobileView ? "column" : "row"}
            justifyContent="space-between"
          >
            <Box width="100%">
              <Typography variant="overline" style={{ marginBottom: "6px" }}>
                PICK-UP DATE & TIME
              </Typography>
              <Box display="flex" flexDirection="row">
                <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
                  {dateFormatter(
                    moment.utc(first(trip.stops).dateTime),
                    "medium",
                    {
                      mode: "dateTime",
                    }
                  )}
                </Typography>
                <FlightChangeDisplay
                  flightOffset={pickUpStop?.flightOffset}
                  originalDateTime={pickUpStop?.originalDateTime}
                  dateTime={pickUpStop?.dateTime}
                />
              </Box>

              {/* trip timeline */}
              <Box mt={4.5}>
                <TripTimeline trip={trip} isQuote={isQuote} />
              </Box>
            </Box>

            {/* map */}
            <Box width="100%" {...(isMobileView ? { mb: 4 } : { ml: 8 })}>
              <TripMap trip={trip} />
            </Box>
          </Box>
          <Divider />

          {/* additional trip info */}
          <Box mt={2}>{additionalTripInfoBlock}</Box>
        </Box>
      </Card>
    </>
  );
}

export default TripItineraryCard;
