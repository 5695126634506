/**
 * @file useOperator
 * Hook that abstracts operator query.
 */

import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

import { useSnackbar } from "./useSnackbar";
import { useOperatorSlug } from "./useOperatorSlug";
import { LOAD_OPERATOR_QUERY } from "../graphql";
import { Operator } from "../../types";

function useOperator() {
  // hooks
  const operatorSlug = useOperatorSlug();
  const snackbar = useSnackbar();
  const history = useHistory();

  // queries
  const { data, error, loading } = useQuery(LOAD_OPERATOR_QUERY, {
    variables: {
      operatorSlug: operatorSlug,
    },
    skip: !operatorSlug,
  });

  if (error) {
    console.error(error);
    snackbar.error("Operator not found");
  }

  const operator: Operator = data?.operator || {};

  // redirect
  useEffect(() => {
    if (operator.nameSlug && !operator.plan) {
      history.replace(`/${operator.nameSlug}/deactivated`);
    }
  }, [history, operator.nameSlug, operator.plan]);

  return {
    operator,
    loading,
    error,
    isElSegundoConnect:
      operator.id === process.env.REACT_APP_EL_SEGUNDO_CONNECT_OPERATOR_ID,
  };
}

export { useOperator };
