import React, { useState, useCallback } from "react";

import {
  Typography,
  Box,
  Drawer,
  Divider,
  useTheme,
  Backdrop,
  IconButton,
  Collapse,
} from "@mui/material";

import {
  black,
  blueLabelText,
  grayDark,
  grayMedium,
} from "../../../design-system/colors";
import MapContainer from "./MapContainer";
import { ChevronDownIcon, ChevronUpIcon } from "../../../design-system/icons";

import { useAnalytics, useOperator } from "../../../globals/hooks";
import { RouteDispatch, Trip } from "types";
import DriverStatusRibbon from "./DriverStatusRibbon";

type TripFooterProps = {
  routeDispatch: RouteDispatch;
  trip: Trip;
};

function TripFooter(props: TripFooterProps) {
  const { routeDispatch, trip } = props;

  // hooks
  const theme = useTheme();
  const { track } = useAnalytics();
  const { isElSegundoConnect } = useOperator();

  // state
  const [isMapExtended, setExpandMap] = useState(false);

  // event handlers
  const handleAccordionToggle = useCallback(() => {
    if (isMapExtended === false) {
      // tracks only when customer opens view map
      track("trip_viewMap");
    }
    setExpandMap((prevState) => !prevState);
  }, [isMapExtended, setExpandMap, track]);

  return (
    <Drawer
      variant="persistent"
      anchor="bottom"
      open
      sx={{
        zIndex: theme.zIndex.drawer + 1, // Needed to have child backdrop be prioritized z-index
      }}
    >
      <Backdrop sx={{ color: black }} open={isMapExtended} />
      <Box
        position="fixed"
        bottom="0"
        width="100%"
        sx={{
          boxShadow: `0 -1px 0 0 ${grayMedium}`,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          mx="auto"
          justifyContent="center"
          alignItems="center"
          sx={{ color: grayDark }}
          maxWidth={theme.breakpoints.values.sm}
        >
          <Box width="100%" px={1} pt={1}>
            <Box mb={1}>
              <Box
                sx={{
                  display: "flex",
                  flex: "1",
                  mb: 1,
                  mx: 1,
                  mt: 0.5,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  color: blueLabelText,
                }}
              >
                <Box display="flex" alignItems="center">
                  <Typography style={{ color: black, fontWeight: "bold" }}>
                    Driver Tracking
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography
                    mr={1}
                    variant="button"
                    onClick={handleAccordionToggle}
                  >
                    {isMapExtended ? "Hide Map" : "View Map"}
                  </Typography>
                  <IconButton onClick={handleAccordionToggle}>
                    {isMapExtended ? (
                      <ChevronDownIcon color={blueLabelText} />
                    ) : (
                      <ChevronUpIcon color={blueLabelText} />
                    )}
                  </IconButton>
                </Box>
              </Box>

              {!isElSegundoConnect && routeDispatch?.currentStatus && (
                <Box mx={1} mb={1}>
                  <DriverStatusRibbon routeDispatch={routeDispatch} />
                </Box>
              )}

              <Collapse in={isMapExtended}>
                <Box height="250px" mx={1}>
                  <MapContainer trip={trip} />
                </Box>
              </Collapse>
            </Box>

            <Divider />
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}

export default TripFooter;
