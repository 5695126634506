import JsFileDownloader from "js-file-downloader";
import { stringify } from "querystring";

import { useOperatorSlug } from ".";
import { getServerLink } from "globals/utils/getServerLink";

function useDownloader() {
  // hooks
  const operatorSlug = useOperatorSlug();
  const link = getServerLink();

  const downloader = async (
    endpoint: string,
    params?:
      | {
          id: string;
        }
      | {
          startDate: string;
          endDate: string;
        }
  ) => {
    return new JsFileDownloader({
      forceDesktopMode: true,
      withCredentials: true,
      timeout: 270000,
      headers: [
        {
          name: "timezoneOffset",
          value: new Date().getTimezoneOffset().toString(),
        },
        {
          name: "operatorSlug",
          value: operatorSlug,
        },
      ],
      url: link + `${endpoint}?${stringify(params)}`,
    });
  };

  return downloader;
}

export { useDownloader };
