import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import { LOAD_TRIP_QUERY } from "globals/graphql";

export function useParamTripExists() {
  // hooks
  const { tripId } = useParams<{ tripId: string }>();

  // queries
  const { data: tripData, loading } = useQuery(LOAD_TRIP_QUERY, {
    variables: {
      id: tripId,
    },
  });

  return {
    tripExists: loading || tripData?.node,
    tripId,
  };
}
