import React from "react";

import { Typography } from "@mui/material";

import { grayDark } from "design-system/colors";
import { TripReview } from "types";

type StarRatingTextProps = {
  stars: number;
  tripReview: TripReview;
};

function StarRatingText(props: StarRatingTextProps) {
  const { stars, tripReview } = props;

  const rating = tripReview?.rating;

  const ratingText = {
    0: "We will receive your feedback",
    1: "Very disappointing. What went wrong?",
    2: "Pretty bad. What went wrong?",
    3: "Just average. What went wrong?",
    4: "Pretty good, but what could be better?",
    5: "Excellent rating! We appreciate your feedback!",
  };

  return (
    <Typography
      variant="body1"
      mt={3}
      mb={5}
      color={grayDark}
      textAlign="center"
    >
      {!!(tripReview && rating < 5)
        ? "We appreciate your review."
        : ratingText[stars || 0]}
    </Typography>
  );
}

export default StarRatingText;
