import { Box, Divider, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useAnalytics, useOperator } from "globals/hooks";
import { OrderType } from "types";
import { RHFAutocomplete } from "components/react-hook-form/autocomplete/RHFAutocomplete";
import { allOrderTypes } from "./data"; // TODO: source from server to minimize need for manual update

type OrderTypeBlockProps = {
  showAllOrderTypes?: boolean;
};

function OrderTypeBlock(props: OrderTypeBlockProps) {
  const { showAllOrderTypes } = props;

  // hooks
  const { operator } = useOperator();
  const { watch } = useFormContext();
  const { track } = useAnalytics();
  const [orderType] = watch(["orderType"]);

  // state
  const [filteredOrderTypes, setFilteredOrderTypes] = useState<OrderType[]>([]);
  const enabledOrderTypes = showAllOrderTypes
    ? allOrderTypes
    : operator.enabledOrderTypes;

  // event handlers
  const handleFilterOrderType = (_, value) => {
    const searchOrderType = value;

    if (!searchOrderType) {
      setFilteredOrderTypes(enabledOrderTypes);
    } else {
      track("bookingTool_orderTypeSelected");
      const filterOrderType = enabledOrderTypes.filter(
        (orderType) =>
          orderType.category
            .toLowerCase()
            .includes(searchOrderType.toLowerCase()) ||
          orderType.name.toLowerCase().includes(searchOrderType.toLowerCase())
      );
      setFilteredOrderTypes(filterOrderType);
    }
  };

  // set initial order types
  useEffect(() => {
    setFilteredOrderTypes(enabledOrderTypes);
  }, [enabledOrderTypes]);

  return (
    <>
      <Box mb={1}>
        <Typography variant="h4">Order Type</Typography>
      </Box>
      <Box mb={1}>
        <Divider light />
      </Box>
      <Box mb={2}>
        <RHFAutocomplete
          id="grouped-order-type"
          name="orderType"
          disabled={!enabledOrderTypes}
          options={filteredOrderTypes || []}
          transform={{
            input: (value) => value?.name,
          }}
          groupBy={(option) => option.category}
          getOptionLabel={(option) => (option as OrderType)?.name || ""}
          filterOptions={(options) => options}
          isOptionEqualToValue={(option, value) => option.name === value?.name}
          renderOption={(props, { name }) => (
            <li {...props}>
              <Typography variant="body2">{name}</Typography>
            </li>
          )}
          onInputChange={handleFilterOrderType}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Order Type"
              fullWidth
              required
              variant="outlined"
              value={orderType}
            />
          )}
        />
      </Box>
    </>
  );
}

export default OrderTypeBlock;
