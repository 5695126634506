import React from "react";

type TableIconProps = {
  color?: string;
  size: "small";
};

const TableIcon = (props: TableIconProps) => {
  const { color } = props;

  // size === small
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33325 1.33337L2.28563 6.73688M2.28563 6.73688L1.33325 10.6667M2.28563 6.73688H4.19039C4.44298 6.73688 4.68522 6.84039 4.86383 7.02464C5.04244 7.20888 5.14278 7.45878 5.14278 7.71934V10.6667M14.6666 1.33337L13.7142 6.73688M13.7142 6.73688L14.6666 10.6667M13.7142 6.73688H11.8094C11.5569 6.73688 11.3146 6.84039 11.136 7.02464C10.9574 7.20888 10.8571 7.45878 10.8571 7.71934V10.6667M7.99992 3.78951V10.6667M4.66659 3.78951H11.3333"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TableIcon;
