import React, { useState, MouseEvent } from "react";

import { Menu, MenuItem, Typography, Box, IconButton } from "@mui/material";

import { granite } from "design-system/colors";
import { TrashIcon, EditIcon, EllipsesVertIcon } from "design-system/icons";
import { useScreenSize } from "globals/hooks";

type EditRemoveStopButtonsProps = {
  canRemoveStop: boolean;
  onRemoveStopClick: () => void;
  onEditAdditionalStopInfoClick: () => void;
  stopLabel: string;
  showAdditionalStopInfoButton?: boolean;
};

function EditRemoveStopButtons(props: EditRemoveStopButtonsProps) {
  const {
    canRemoveStop,
    showAdditionalStopInfoButton,
    onRemoveStopClick,
    onEditAdditionalStopInfoClick,
    stopLabel,
  } = props;

  const { isMobileView } = useScreenSize();

  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const menuOpen = !!anchorEl;

  // event handlers
  const handleMenuClick = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditAdditionalStopClick = () => {
    handleMenuClose();
    onEditAdditionalStopInfoClick();
  };

  const handleRemoveStopClick = () => {
    handleMenuClose();
    onRemoveStopClick();
  };

  return (
    <Box>
      {isMobileView ? (
        <>
          {/* elipsis menu */}
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleMenuClick}
            size="large"
          >
            <EllipsesVertIcon color={granite} />
          </IconButton>

          {/* dropdown menu */}
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={menuOpen}
            onClose={handleMenuClose}
          >
            {canRemoveStop && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <MenuItem
                  onClick={handleRemoveStopClick}
                  style={{ width: "100%" }}
                >
                  <Box mr={1}>
                    <TrashIcon color={granite} />
                  </Box>
                  <Typography
                    style={{ fontWeight: 500, color: granite }}
                    variant="body2"
                  >
                    Remove Stop
                  </Typography>
                </MenuItem>
              </Box>
            )}
            {showAdditionalStopInfoButton && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <MenuItem onClick={handleEditAdditionalStopClick}>
                  <Box mr={1}>
                    <EditIcon color={granite} />
                  </Box>
                  <Typography
                    style={{ fontWeight: 500, color: granite }}
                    variant="body2"
                  >
                    Additional {stopLabel} Info
                  </Typography>
                </MenuItem>
              </Box>
            )}
          </Menu>
        </>
      ) : (
        <>
          {/* desktop buttons */}
          {canRemoveStop && (
            <IconButton onClick={handleRemoveStopClick} size="large">
              <TrashIcon color={granite} />
            </IconButton>
          )}
          {showAdditionalStopInfoButton && (
            <IconButton onClick={handleEditAdditionalStopClick} size="large">
              <EditIcon color={granite} />
            </IconButton>
          )}
        </>
      )}
    </Box>
  );
}

export default EditRemoveStopButtons;
