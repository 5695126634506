/**
 * Future improvement
 * - naming of this component is vague - should really be related to pricing
 */
import React from "react";

import { Box, Typography } from "@mui/material";

import PriceOverviewPriceItem from "./PriceOverviewPriceItem";
import { currency } from "utils/helpers";

type ActiveTripDetailsProps = {
  pricingLineItems: {
    label: string;
    value: number;
    isNeg?: boolean;
    removeField?: string;
  }[];
  tripIndex: number;
  totalAmt: number;
};

function ActiveTripDetails(props: ActiveTripDetailsProps) {
  const { pricingLineItems, tripIndex, totalAmt } = props;

  return (
    <>
      <Box mb={1}>
        <Typography variant="h4">Trip {tripIndex || ""} Pricing</Typography>
      </Box>
      <Box>
        {pricingLineItems.map((lineItem) => (
          <Box key={lineItem.label} mb={1}>
            <PriceOverviewPriceItem {...lineItem} />
          </Box>
        ))}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mb={3}
      >
        <Typography variant="subtitle2">
          Trip {tripIndex || ""} Total
        </Typography>
        <Typography variant="subtitle2">{currency(totalAmt)}</Typography>
      </Box>
    </>
  );
}

export default ActiveTripDetails;
