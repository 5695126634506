/**
 * @file graphql/terms.ts
 * queries for Terms.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_TERMS_QUERY = gql`
  query TermTypes($operatorSlug: String!) {
    terms(operatorSlug: $operatorSlug) {
      id
      name
      description
    }
  }
`;
