import React, { useState } from "react";

import { Box, Typography } from "@mui/material";

import { black, grayLight, grayDark } from "../../design-system/colors";
import MoovsTooltip from "./MoovsTooltip";
import MoovsBottomDrawer from "./MoovsBottomDrawer";
import { useScreenSize } from "../../globals/hooks";

type MoovsIconPanelProps = {
  items: {
    icon: React.ReactChild;
    renderItem: boolean;
    mainText: string;
    subText?: string | string[];
    additionalText?: string; // only used with credit cards
    isCreditCard?: boolean;
  }[];
  bottomDrawerHeader: string;
  onEditClick?: () => void;
  centerAlignDrawerText?: boolean;
};

const styles = {
  hoverIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    width: "32px",
    height: "32px",
    "&:hover": {
      backgroundColor: grayLight,
    },
    "&:hover *": {
      stroke: `${black} !important`,
    },
  },
  hoverIconStroke: {
    "&:hover *": {
      stroke: `${black} !important`,
    },
  },
  mobileIconPanel: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: grayLight,
    },
  },
  "mobileIconPanel--active": {
    backgroundColor: grayLight,
  },
};

function MoovsIconPanel(props: MoovsIconPanelProps) {
  const {
    items,
    onEditClick,
    bottomDrawerHeader,
    centerAlignDrawerText = true,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // state
  const [bottomModalOpen, setBottomModalOpen] = useState(false);

  const isMobileAndHasCreditCard =
    isMobileView && items.some(({ isCreditCard }) => isCreditCard);

  // helpers
  const formatSubText = (value: string | string[]) => {
    if (typeof value === "string") {
      return value;
    }

    return value.map((field) => (
      <div key={field}>
        {field}
        <br />
      </div>
    ));
  };

  return (
    <>
      <Box display="flex" alignItems="center" flexDirection="row" mr={1}>
        {isMobileView ? (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            mr={2}
            borderRadius="4px"
            onClick={() => setBottomModalOpen(true)}
            sx={{
              ...(isMobileView && styles.mobileIconPanel),
              ...(!isMobileAndHasCreditCard && styles.hoverIconStroke),
              ...(bottomModalOpen && styles["mobileIconPanel--active"]),
            }}
          >
            {/* mobile */}
            {items.map((item, index) => {
              const { renderItem, icon } = item;
              return (
                renderItem && (
                  <Box
                    key={index}
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    height="36px"
                    width="36px"
                  >
                    {icon}
                  </Box>
                )
              );
            })}
          </Box>
        ) : (
          <Box display="flex" flexDirection="row">
            {/* desktop */}
            {items.map((item, index) => {
              const {
                mainText,
                subText,
                additionalText,
                isCreditCard,
                icon,
                renderItem,
              } = item;
              return (
                renderItem && (
                  <MoovsTooltip
                    tooltipText={mainText}
                    tooltipSubtext={formatSubText(subText)}
                    tooltipAdditionalText={additionalText}
                    key={index}
                    showcaseMainText={isCreditCard}
                    noMaxWidth={isCreditCard}
                  >
                    <Box
                      sx={{
                        ...styles.hoverIcon,
                        ...(!isCreditCard && styles.hoverIconStroke),
                      }}
                    >
                      {icon}
                    </Box>
                  </MoovsTooltip>
                )
              );
            })}
          </Box>
        )}
      </Box>
      {isMobileView && (
        <MoovsBottomDrawer
          open={bottomModalOpen}
          onClose={() => setBottomModalOpen(false)}
          title={bottomDrawerHeader}
          onEditClick={onEditClick}
        >
          <Box display="flex" flexDirection="column">
            {items.map((item, index) => {
              const {
                mainText,
                subText,
                additionalText,
                icon,
                renderItem,
                isCreditCard,
              } = item;

              return (
                renderItem && (
                  <Box
                    key={index}
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    {...(centerAlignDrawerText && { alignItems: "center" })}
                    mt={2}
                  >
                    <Box justifySelf="flex-end">{icon}</Box>
                    <Box ml={2}>
                      {isCreditCard ? (
                        <>
                          <Typography
                            variant="body2"
                            style={{ color: grayDark }}
                          >
                            Credit Card
                          </Typography>
                          <Typography style={{ fontSize: 14 }}>
                            {mainText} {subText}
                          </Typography>
                          <Typography style={{ fontSize: 14 }}>
                            {additionalText}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography
                            variant="body2"
                            style={{ color: grayDark }}
                          >
                            {mainText}
                          </Typography>
                          <Typography style={{ fontSize: 14 }} component="span">
                            {formatSubText(subText)}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Box>
                )
              );
            })}
          </Box>
        </MoovsBottomDrawer>
      )}
    </>
  );
}

export default MoovsIconPanel;
