import React from "react";

import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

import { useScreenSize } from "globals/hooks";

type MoovsToggleButtonGroupProps = {
  options: // one of icon or label is required
  (
    | {
        ariaLabel?: string;
        value: string;
        icon: any;
        dataTestId?: string;
        label?: string;
      }
    | {
        ariaLabel?: string;
        value: string;
        icon?: any;
        dataTestId?: string;
        label: string;
      }
  )[];
  value: any;
  onChange: (_: any, newType) => void;
  sx?: Record<string, unknown>;
  size?: "small" | "large";
  fullWidth?: boolean;
};

function MoovsToggleButtonGroup(props: MoovsToggleButtonGroupProps) {
  const { options, value, onChange, sx, size = "small", fullWidth } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // event handler
  const handleToggle = (e: React.MouseEvent<HTMLElement>, variant: unknown) => {
    if (onChange) {
      onChange(e, variant);
    }
  };

  return (
    <ToggleButtonGroup
      exclusive
      value={value}
      onChange={handleToggle}
      fullWidth={fullWidth}
    >
      {options.map((toggleButton, i) => {
        const { icon, label, ariaLabel = "", dataTestId = "" } = toggleButton;

        const Icon = icon;

        return (
          <ToggleButton
            sx={[sx]}
            key={i}
            disableRipple
            value={toggleButton.value}
            aria-label={ariaLabel}
            data-testid={dataTestId}
          >
            <Box
              display="flex"
              flexDirection={isMobileView ? "column" : "row"}
              justifyContent="center"
              alignItems="center"
            >
              {/* icon */}
              {icon && <Icon size={size} />}

              {/* spacing, only needed if both icon and label */}
              {icon && label && <Box width="8px" mt={1} />}

              {/* label */}
              {label && (
                <Typography noWrap variant="button" mt={fullWidth && 1}>
                  {label}
                </Typography>
              )}
            </Box>
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}

export default MoovsToggleButtonGroup;
