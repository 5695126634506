import React from "react";
import { Box, List, ListItem, Link, Typography, useTheme } from "@mui/material";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import { LOAD_TERMS_QUERY } from "../../globals/graphql/term.graphql";
import { granite } from "../../design-system/colors";
import { useScreenSize } from "../../globals/hooks";

function TermsAndConditionsPage() {
  const { operatorSlug } = useParams<{ operatorSlug }>();
  const theme = useTheme();
  const { isMobileView } = useScreenSize();

  // queries
  const { data: termsData } = useQuery(LOAD_TERMS_QUERY, {
    variables: {
      operatorSlug: operatorSlug,
    },
  });

  return (
    <Box
      mx={isMobileView ? 0 : "auto"}
      sx={{
        [theme.breakpoints.down(undefined)]: {
          // in desktop mode, when topbar takes full width, add padding to the sides of content
          padding: `0 ${theme.spacing(11)}`,
        },
        [theme.breakpoints.down("sm")]: {
          padding: `0 ${theme.spacing(2)}`,
        },
      }}
      maxWidth="1140px"
    >
      <Box pt={isMobileView ? 3 : 7} pb={isMobileView ? 2 : 4}>
        <Typography
          sx={{
            [theme.breakpoints.up(380)]: {
              fontSize: "1.8rem",
            },
            [theme.breakpoints.down(undefined)]: {
              fontSize: "1.5rem", // reduces header font size for iphone5
            },
          }}
          variant={isMobileView ? "h1" : "h2"}
        >
          Terms and Conditions
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection={isMobileView ? "column-reverse" : "row"}
      >
        <Box width={isMobileView ? "100%" : "60vw"} flex={2} mr={10}>
          <List>
            {termsData?.terms.map(({ id, name, description }) => (
              <ListItem disableGutters key={id}>
                <Box id={id} display="flex" flexDirection="column" mb={2}>
                  <Box mb={2}>
                    <Typography variant="h3">{name}</Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {description}
                  </Typography>
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box flex={1} px={isMobileView ? 0 : 2}>
          <List sx={isMobileView && { position: "sticky", top: 0 }}>
            {termsData?.terms.map(({ id, name }, index) => (
              <ListItem disableGutters key={id}>
                <Link
                  sx={{ fontWeight: 500, color: granite }}
                  variant="body2"
                  href={`#${id}`}
                >{`${index + 1}. ${name}`}</Link>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
}
export default TermsAndConditionsPage;
