import React from "react";

type PetsIconProps = {
  color?: string;
  size: "small";
};

const PetsIcon = (props: PetsIconProps) => {
  const { color } = props;

  // size === small
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.02765 12.3333H3.28277C2.88268 10.7115 2.59139 9.06756 2.41062 7.41133C2.41039 7.14175 2.46662 6.87477 2.57609 6.62563C2.68557 6.3765 2.84615 6.15011 3.04866 5.95938C3.25116 5.76865 3.49163 5.61733 3.75632 5.51406C4.02101 5.4108 4.30473 5.35761 4.59128 5.35753H8.51502C8.72216 5.37352 8.92988 5.33405 9.11418 5.24369C9.29849 5.15333 9.45187 5.01577 9.5566 4.84688M11.1338 3.51124C11.1338 4.30435 11.352 4.94756 10.2611 4.94756C9.17017 4.94756 8.95258 4.53814 9.38776 3.30765C9.65351 2.55817 9.90498 1.66663 11.5684 1.66663C11.9566 1.68425 12.3304 1.81089 12.6414 2.03021C12.9524 2.24954 13.1864 2.55148 13.3133 2.89712L14.6212 3.30709C14.6212 3.30709 15.1117 5.58965 12.8763 5.35865C12.4406 7.00024 12.8763 8.23129 11.5684 9.05012V12.3327H12.4411M7.2083 7.81963C7.2083 7.81963 7.2083 9.46122 5.02765 9.46122L4.51875 12.3333M11.5696 12.3333H9.82532L9.38895 9.05068H8.51681M2.84818 6.17861C2.84818 6.17861 0.665747 5.76863 1.54027 3.30765"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PetsIcon;
