/**
 * @file FlightInfoCard.tsx
 * Renders info about a future or upcoming flight.
 * Flight info comes from flight aware.
 *
 * components:
 *  FlightInfoCard
 */

import React from "react";
import { Box } from "@mui/material";

import { grayLight } from "../../design-system/colors";
import { FlightDetails, DivertedFlightInfo } from "./components";
import { TrackedFlight } from "../../types";
import FlightInfoCardContentMobile from "./FlightInfoCardContent.mobile";
import FlightInfoCardContentDesktop from "./FlightInfoCardContent.desktop";
import { useScreenSize } from "../../globals/hooks";

export type FlightInfoCardContentProps = {
  refreshFlightProps: {
    isRefreshingFlight?: boolean;
    onClickRefreshFlight?: () => void;
  };
  airlineHeader: string;
  trackedFlight: TrackedFlight;
};

type FlightInfoCardProps = {
  trackedFlight: TrackedFlight;
  refreshFlightProps?: {
    onClickRefreshFlight?: () => void;
    isRefreshingFlight?: boolean;
  };
};

function FlightInfoCard(props: FlightInfoCardProps) {
  const { trackedFlight, refreshFlightProps } = props;

  const { isMobileView } = useScreenSize();

  const { origin, destination, airline, flightNumber, flightStatus } =
    trackedFlight;

  const airlineHeader = `${airline.airlineName} (${airline.iataCode}) ${flightNumber}`;

  return (
    <Box p={2} style={{ backgroundColor: grayLight, borderRadius: "4px" }}>
      {isMobileView ? (
        <FlightInfoCardContentMobile
          trackedFlight={trackedFlight}
          refreshFlightProps={refreshFlightProps}
          airlineHeader={airlineHeader}
        />
      ) : (
        <FlightInfoCardContentDesktop
          trackedFlight={trackedFlight}
          refreshFlightProps={refreshFlightProps}
          airlineHeader={airlineHeader}
        />
      )}

      <Box display="flex" justifyContent="space-between">
        <FlightDetails
          transitStop={origin}
          transitStopType="origin"
          flightStatus={flightStatus}
        />
        <FlightDetails
          transitStop={destination}
          transitStopType="destination"
          flightStatus={flightStatus}
        />
      </Box>
      {flightStatus === "DIVERTED" && (
        <DivertedFlightInfo flightNumber={trackedFlight.flightNumber} />
      )}
    </Box>
  );
}

export default FlightInfoCard;
