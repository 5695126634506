import React from "react";

type WarningTriangleIconProps = {
  color?: string;
  size: "small";
};

const WarningTriangleIcon = (props: WarningTriangleIconProps) => {
  const { color } = props;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#warning-triangle-icon-clip-path)">
        <path
          d="M1 15.0005H15L8 1.00049L1 15.0005Z"
          stroke={color || "#B3B3B3"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 6.50049L8 9.70049M8 12.5005L7.993 12.5005"
          stroke={color || "#B3B3B3"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="warning-triangle-icon-clip-path">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.000488281)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WarningTriangleIcon;
