import React from "react";

import { Box, Card } from "@mui/material";

import { PaymentMethod } from "types";
import { CardDetails, UnlinkCardMenuButton } from "./components";

type LinkedToYouCardProps = {
  paymentMethod: PaymentMethod;
};

function LinkedToYouCard(props: LinkedToYouCardProps) {
  const { paymentMethod } = props;

  const { billingDetails, card } = paymentMethod;

  if (!card || !billingDetails) return null;

  return (
    <Card variant="outlined" sx={{ marginTop: 2, marginBottom: 1 }}>
      <Box p={2} display="flex" justifyContent="space-between">
        <CardDetails card={card} name={billingDetails.name} />

        {/* unlink */}
        <Box display="flex" flex="2" justifyContent="flex-end">
          <Box>
            <UnlinkCardMenuButton
              buttonText="Linked to You"
              menuOptionText="Unlink"
              paymentMethodId={paymentMethod.id}
            />
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default LinkedToYouCard;
