/**
 * @file TripMap.tsx
 * Wrapper for google map.
 *
 * Can be used in create and update flow
 */
import React from "react";
import first from "lodash/first";

import {
  Box,
  Button,
  ButtonBase,
  styled,
  Typography,
  useTheme,
} from "@mui/material";

import { OpenIcon } from "design-system/icons";
import { useAnalytics, useOperatorSlug, useScreenSize } from "globals/hooks";
import GoogleStaticMap from "./components/GoogleStaticMap";

type TripMapProps = {
  trip: {
    googleDirectionsResult: any;
    stops: {
      airport: any;
      location: any;
    }[];
    routes: {
      publicId?: string;
    }[];
  };
};

function TripMap(props: TripMapProps) {
  const { trip } = props;
  const { googleDirectionsResult, stops, routes } = trip;

  // hooks
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();
  const operatorSlug = useOperatorSlug();
  const theme = useTheme();

  // derived state
  const publicId = first(trip.routes)?.publicId;

  // event handlers
  const handleGoogleMapClick = () => {
    const formatStopForGoogleDirections = ({ airport, location }) => {
      return airport
        ? `${airport.coordinates.x},${airport.coordinates.y}`
        : location;
    };

    const mapOptions = {
      travelmode: "driving",
      origin: formatStopForGoogleDirections(stops[0]),
      destination: formatStopForGoogleDirections(stops[stops.length - 1]),
      waypoints: stops
        .slice(1, stops.length - 1)
        .map((stop) => formatStopForGoogleDirections(stop))
        .join("|"),
    };

    const searchParams = new URLSearchParams(mapOptions);

    window.open(
      `https://www.google.com/maps/dir/?api=1&${searchParams}`,
      "_blank"
    );
  };

  const handleViewRideStatusClick = () => {
    track("reservationConfirmation_viewRidestatus");
    window.open(`/${operatorSlug}/trip/${routes[0].publicId}`, "_blank");
  };

  return (
    <Box>
      <ImageButton focusRipple onClick={handleGoogleMapClick}>
        {googleDirectionsResult && (
          <>
            <GoogleStaticMap
              aspectRatio={isMobileView ? 29 / 16 : 1 / 1}
              googleDirectionsResult={googleDirectionsResult}
            />
            <ImageBackdrop className="MuiImageBackdrop-root" />
            <ImageContent>
              <Box
                border={`2px solid ${theme.palette.primary.main}`}
                borderRadius="4px"
                px={2}
                py={1}
              >
                <Typography variant="button" color="primary">
                  Open in Google Maps
                </Typography>
              </Box>
            </ImageContent>
          </>
        )}
      </ImageButton>

      {publicId && (
        <Box mt={1.5}>
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            startIcon={<OpenIcon size="small" />}
            onClick={handleViewRideStatusClick}
          >
            View Ride Status
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default TripMap;

const ImageButton = styled(ButtonBase)(() => ({
  position: "relative",
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.8,
    },
    "& .MuiImageButton-root": {
      opacity: 1,
    },
  },
}));

const ImageContent = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  opacity: 0,
  "&:hover, &.Mui-focusVisible": {
    opacity: 0.8,
  },
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: "#F5F8FF",
  opacity: 0,
  transition: theme.transitions.create("opacity"),
}));
