import React from "react";
import moment from "moment-timezone";

import { errorRed, tintRed } from "../../design-system/colors";
import MoovsBanner from "../globals/MoovsBanner";

type TripCancelledBannerProps = {
  cancelledAt: Date;
};

function TripCancelledBanner(props: TripCancelledBannerProps) {
  const { cancelledAt } = props;

  // derived state
  const formattedDate = moment(cancelledAt).format("MMMM Do YYYY, h:mm A");

  return (
    <MoovsBanner
      bannerText={`The trip was cancelled on ${formattedDate}`}
      bannerBgColor={tintRed}
      bannerTextColor={errorRed}
    />
  );
}

export default TripCancelledBanner;
