/**
 * @file googleDirections.graphql.ts
 * queries/mutations for googleDirections.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_GOOGLE_DIRECTIONS_QUERY = gql`
  query GoogleDirections($stops: [StopInformationInputForAutomatedPricing]) {
    googleDirections(stops: $stops) {
      googleDirectionsResult
      estimatedDuration
    }
  }
`;
