import { createTheme, Components } from "@mui/material/styles";
import { useOperator } from "globals/hooks";
import { useEffect, useRef, useState } from "react";
import { ColorThemeVariant } from "types";

import {
  alabaster,
  errorRed,
  grayDark,
  grayMedium,
  grayLight,
  successGreen,
  moovsBlue,
  moovsBlueSelected,
  swoopGreen,
  warningYellow,
  white,
  black,
  granite,
  dudaWebsiteColors,
  purple,
  purpleLight,
  moovsBlueHover,
  purpleHover,
  moovsBlueDark,
  navyLight,
  navyHover,
  skyLight,
  skyMain,
  skyHover,
  violetMain,
  violetLight,
  violetHover,
  roseMain,
  tintRed,
  roseHover,
  redDark,
  burgundyHover,
  midnightLight,
  midnightHover,
  forestHover,
  greenDark,
  tintGreen,
  grassLight,
  grassMain,
  grassHover,
  tintAqua,
  green,
  oceanHover,
} from "./design-system/colors";

/* Custom Swoop Brand Colors */
export const primaryMainColor = moovsBlue;
export const primaryHoverColor = grayLight; //moovsBlueLight exists, but not used
export const primaryDisabledColor = moovsBlueSelected;

export const secondaryMainColor = swoopGreen;

/* Moovs zIndex Values */
export const confettiParticlesZIndex = 99999999;

/* Color Theme Offered By Operator Plans */
export const operatorPlanColorTheme = {
  [ColorThemeVariant.Moovs]: {
    light: moovsBlueSelected,
    main: moovsBlue,
    hover: moovsBlueHover,
  },
  [ColorThemeVariant.Navy]: {
    light: navyLight,
    main: moovsBlueDark,
    hover: navyHover,
  },
  [ColorThemeVariant.Sky]: {
    light: skyLight,
    main: skyMain,
    hover: skyHover,
  },
  [ColorThemeVariant.Lavender]: {
    light: purpleLight,
    main: purple,
    hover: purpleHover,
  },
  [ColorThemeVariant.Violet]: {
    light: violetLight,
    main: violetMain,
    hover: violetHover,
  },
  [ColorThemeVariant.Rose]: {
    light: tintRed,
    main: roseMain,
    hover: roseHover,
  },
  [ColorThemeVariant.Burgundy]: {
    light: tintRed,
    main: redDark,
    hover: burgundyHover,
  },
  [ColorThemeVariant.Midnight]: {
    light: midnightLight,
    main: black,
    hover: midnightHover,
  },
  [ColorThemeVariant.Forest]: {
    light: tintGreen,
    main: greenDark,
    hover: forestHover,
  },
  [ColorThemeVariant.Grass]: {
    light: grassLight,
    main: grassMain,
    hover: grassHover,
  },
  [ColorThemeVariant.Ocean]: {
    light: tintAqua,
    main: green,
    hover: oceanHover,
  },
};

/* Override mui Styles */
const defaultComponents: Components = {
  MuiAutocomplete: {
    styleOverrides: {
      root: { width: "100%" },
      inputRoot: {
        backgroundColor: white,
      },
    },
  },
  MuiBadge: {
    styleOverrides: {
      colorError: {
        backgroundColor: errorRed,
        border: "1px solid #FFFFFF",
        fontSize: 11,
        lineHeight: 18,
        height: 18,
        minWidth: 18,
        padding: "0 4px",
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        "&.Mui-disabled": {
          path: {
            stroke: grayDark,
          },
        },
      },

      startIcon: {
        path: {
          stroke: moovsBlue,
        },
      },
      endIcon: {
        path: {
          stroke: moovsBlue,
        },
      },
      contained: {
        minHeight: 44,
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
        "&:hover": {
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
        },
      },
      outlinedPrimary: {
        minHeight: 44,
        borderWidth: "2px",
        "&:hover": {
          borderWidth: "2px",
        },
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        backgroundColor: grayLight,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        "& fieldset": {
          borderColor: grayMedium,
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      outlined: {
        borderColor: grayLight,
        boxShadow: "0 4px 15px 0 rgba(0, 0, 0, 0.03)",
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        border: `${grayMedium} 1px solid`,
        color: granite,
        ":focus": {
          backgroundColor: alabaster,
        },
        "&.Mui-selected": {
          ":hover": {
            backgroundColor: `${moovsBlueHover}`,
          },
          color: moovsBlue,
          backgroundColor: moovsBlueSelected,
          path: {
            stroke: moovsBlue, // icon
          },
        },
      },
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: 12,
        lineHeight: 1.5,
        padding: "8px 16px",
        backgroundColor: granite,
        borderRadius: 4,
      },
    },
  },
};

const defaultTheme = createTheme({
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1140, //1280
      xl: 1920,
    },
  },
  palette: {
    background: {
      default: alabaster,
      paper: white,
    },
    primary: {
      main: primaryMainColor,
      light: moovsBlueSelected,
      contrastText: "#fff",
    },
    secondary: {
      main: secondaryMainColor,
      contrastText: "#000",
    },
    error: { main: errorRed },
    warning: { main: warningYellow },
    success: { main: successGreen },
    action: {
      hover: primaryHoverColor,
    },
    text: {
      primary: black,
    },
    dudaDarkBlue: {
      main: dudaWebsiteColors.darkBlue,
      contrastText: "#fff",
    },
    dudaLightBlue: {
      main: dudaWebsiteColors.lightBlue,
      contrastText: "#fff",
    },
    dudaGreen: {
      main: dudaWebsiteColors.green,
      contrastText: "#fff",
    },
    dudaOrangeRed: {
      main: dudaWebsiteColors.orangeRed,
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Poppins",
    h1: {
      fontWeight: 600,
      fontSize: "2rem", // 32,
      lineHeight: 1.25,
      letterSpacing: "-0.031rem",
    },
    h2: {
      fontWeight: 600,
      fontSize: "1.5rem", // 24,
      lineHeight: 1.33,
      letterSpacing: "-0.031rem",
    },
    h3: {
      fontWeight: 600,
      fontSize: "1.25rem", // 20,
      lineHeight: 1.2,
      letterSpacing: "-0.009rem",
    },
    h4: {
      fontWeight: 600,
      fontSize: "1rem", // 16,
      lineHeight: 1.5,
      letterSpacing: "0.006rem",
    },
    h5: {
      fontWeight: 600,
      fontSize: "0.875rem", // 14,
      lineHeight: 1.43,
      letterSpacing: "0.025rem",
      textTransform: "uppercase",
    },
    h6: {
      fontWeight: 600,
      fontSize: "0.75rem", // 12,
      lineHeight: 1.5,
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: "1rem", // 16,
      lineHeight: 1.5,
      letterSpacing: "-0.006rem",
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: "0.875rem", // 14,
      lineHeight: 1.43,
    },
    body1: {
      fontWeight: 400,
      fontSize: "1rem", // 16,
      lineHeight: 1.33,
    },
    body2: {
      fontWeight: 400,
      fontSize: "0.875rem", // 14,
      lineHeight: 1.43,
    },
    button: {
      textTransform: "none",
      fontWeight: 500,
      fontSize: "1rem", // 16,
      letterSpacing: "-0.016rem",
    },
    caption: {
      fontWeight: 400,
      fontSize: "0.75rem", // 12,
      lineHeight: 1.5,
    },
    overline: {
      fontWeight: 500,
      fontSize: "0.75rem", // 12,
      lineHeight: 1.5,
      letterSpacing: "0.016rem",
      color: grayDark,
    },
  },
  spacing: 8,
  components: defaultComponents,
});

function useTheme() {
  // hooks
  const { operator } = useOperator();

  // state
  const [theme, setTheme] = useState(defaultTheme);

  // refs
  const hasInitialized = useRef(false);

  // useEffect
  useEffect(() => {
    if (hasInitialized.current) return;

    const primary = operatorPlanColorTheme[operator.settings?.colorTheme];

    if (primary) {
      hasInitialized.current = true;

      setTheme({
        ...theme,
        // changing palette with primary color theme
        palette: {
          ...theme.palette,
          primary: {
            ...theme.palette.primary,
            main: primary.main,
            light: primary.light,
          },
        },
        // changing components with primary color theme
        components: {
          ...theme.components,
          // MUI TOGGLE BUTTON
          MuiToggleButton: {
            styleOverrides: {
              root: {
                ...theme.components.MuiToggleButton.styleOverrides.root,
                "&.Mui-selected": {
                  ":hover": {
                    backgroundColor: primary.hover,
                  },
                  ":focus": {
                    backgroundColor: primary.hover,
                  },
                  color: primary.main,
                  backgroundColor: primary.light,
                  path: {
                    stroke: primary.main, // icon
                  },
                },
              },
            },
          },

          // MUI BUTTON ICONS
          MuiButton: {
            styleOverrides: {
              ...theme.components.MuiButton.styleOverrides,
              root: {
                ...theme.components.MuiButton.styleOverrides.root,
              },
              contained: {
                "&:hover": {
                  backgroundColor: primary.main,
                },
              },
              startIcon: {
                path: {
                  stroke: primary.main,
                },
              },
              endIcon: {
                path: {
                  stroke: primary.main,
                },
              },
            },
          },
        },
      });
    }
  }, [operator, theme]);

  return theme;
}

export default useTheme;
