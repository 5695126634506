import filter from "lodash/filter";
import pick from "lodash/pick";
import every from "lodash/every";
import isNull from "lodash/isNull";

import {
  RoutePricing,
  Vehicle,
  VehicleChildSeat,
  ChildSeatVariant,
} from "types";

const { Booster, ForwardFacing, RearFacing } = ChildSeatVariant;

type UseDisplayAdditionalItemsParams =
  | {
      mode: "update";
      vehicle: Vehicle;
      tripPricing?: RoutePricing;
    }
  | {
      mode: "create";
      vehicle: Vehicle;
      tripPricing?: never;
    };

export type AdditionalItems = {
  childSeats: VehicleChildSeat[];
};

export const useDisplayAdditionalItems = (
  params: UseDisplayAdditionalItemsParams
) => {
  const { vehicle, tripPricing } = params;
  const childSeatsPricing = pick(tripPricing, [
    "rearFacingSeatAmt",
    "rearFacingSeatQuantity",
    "forwardFacingSeatAmt",
    "forwardFacingSeatQuantity",
    "boosterSeatAmt",
    "boosterSeatQuantity",
  ]);

  let displayAdditionalItems = false;
  let additionItems: AdditionalItems = {
    childSeats: [],
  };

  // return early if no additional items
  if (!vehicle.settings) {
    return {
      displayAdditionalItems,
      additionItems,
    };
  }

  const { rearFacingSeat, forwardFacingSeat, boosterSeat } = vehicle.settings;

  additionItems.childSeats = filter(
    [rearFacingSeat, forwardFacingSeat, boosterSeat],
    "active"
  );

  // if trip pricing has child seats, update price for seat
  if (!every(childSeatsPricing, isNull)) {
    additionItems.childSeats = additionItems.childSeats.map((childSeat) => {
      const {
        rearFacingSeatAmt,
        rearFacingSeatQuantity,
        forwardFacingSeatAmt,
        forwardFacingSeatQuantity,
        boosterSeatAmt,
        boosterSeatQuantity,
      } = childSeatsPricing;

      // clone child seat item to modify amt
      let childSeatClone = { ...childSeat };

      switch (childSeat.type) {
        case RearFacing:
          if (!rearFacingSeatQuantity) break;
          childSeatClone.amt = rearFacingSeatAmt / rearFacingSeatQuantity;
          break;
        case ForwardFacing:
          if (!forwardFacingSeatQuantity) break;
          childSeatClone.amt = forwardFacingSeatAmt / forwardFacingSeatQuantity;
          break;
        case Booster:
          if (!boosterSeatQuantity) break;
          childSeatClone.amt = boosterSeatAmt / boosterSeatQuantity;
          break;
      }

      return childSeatClone;
    });
  }

  if (additionItems.childSeats.length > 0) {
    displayAdditionalItems = true;
  }

  return {
    displayAdditionalItems,
    additionItems,
  };
};
