/**
 * If first stop date time exists AND duration exists
 * Set the last stop's date time for the given trip variant
 */
import { useEffect } from "react";
import moment from "moment";
import { useFormContext, useWatch } from "react-hook-form";
import { InfoStepState } from "../InfoStepFormProvider";

const useLastStopDateTimeBasedOnDuration = (variant: "trip" | "returnTrip") => {
  const { setValue, control } = useFormContext<InfoStepState>();

  const [stops, firstStopDateTime, totalDuration, useTotalDuration] = useWatch({
    name: [
      `${variant}.stops`,
      `${variant}.stops.0.dateTime`,
      `${variant}.totalDuration`,
      `${variant}.useTotalDuration`,
    ],
    control,
  });

  // set last stop date time if there is a duration
  useEffect(() => {
    if (!stops || stops.length === 0) return;
    const numStops = stops.length;
    const lastStopIndex = numStops - 1;

    let lastStopDateTime = null;

    if (firstStopDateTime && totalDuration && useTotalDuration) {
      lastStopDateTime = moment
        .utc(firstStopDateTime)
        .add(Number(totalDuration), "minutes")
        .toISOString();
    }

    const lastStopKey = `${variant}.stops.${lastStopIndex}.dateTime`;
    setValue(lastStopKey as "trip.stops.0.dateTime", lastStopDateTime);
  }, [
    setValue,
    stops,
    firstStopDateTime,
    totalDuration,
    useTotalDuration,
    variant,
  ]);
};

export { useLastStopDateTimeBasedOnDuration };
