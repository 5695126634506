import { useState, useEffect } from "react";

import { Trip } from "types";

type UseActiveTripIndexParams = {
  trips: Trip[];
  activeTripId?: string;
};

export const useActiveTripIndex = (params: UseActiveTripIndexParams) => {
  const { trips, activeTripId } = params;

  // state
  const [activeTripIndex, setActiveTripIndex] = useState(0);

  // derived state
  const activeTrip = trips[activeTripIndex];

  // event handlers
  const handleActiveTripIndexChange = (activeTripIndex: number) => {
    setActiveTripIndex(activeTripIndex);
  };

  useEffect(() => {
    if (activeTripId) {
      const activeTripIndex = trips.findIndex(
        (trip) => trip.id === activeTripId
      );
      if (activeTripIndex !== -1) {
        setActiveTripIndex(activeTripIndex);
      }
    }
  }, [activeTripId, trips]);

  return {
    activeTrip,
    activeTripIndex,
    onActiveTripIndexChange: handleActiveTripIndexChange,
  };
};
