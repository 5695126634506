import { gql } from "@apollo/client";

export const CREATE_WALLET_MUTATION = gql`
  mutation CreateWallet($input: CreateWalletInput!) {
    createWallet(input: $input) {
      clientSecret
    }
  }
`;

export const CREATE_PAYMENT_METHOD_MUTATION = gql`
  mutation CreatePaymentMethod($input: CreatePaymentMethodInput!) {
    createPaymentMethod(input: $input) {
      paymentMethod {
        id
        stripeId
        card {
          id
          expMonth
          expYear
          last4
          brand
        }
      }
    }
  }
`;

export const UNLINK_PASSENGER_FROM_PAYMENT_METHOD_MUTATION = gql`
  mutation UnlinkPassengerFromPaymentMethod(
    $input: UnlinkPassengerFromPaymentMethodInput!
  ) {
    unlinkPassengerFromPaymentMethod(input: $input) {
      paymentMethod {
        id
        linkedPassenger {
          id
        }
      }
    }
  }
`;

export const LINK_PASSENGER_TO_PAYMENT_METHOD_MUTATION = gql`
  mutation LinkPassengerToPaymentMethod(
    $input: LinkPassengerToPaymentMethodInput!
  ) {
    linkPassengerToPaymentMethod(input: $input) {
      paymentMethod {
        id
      }
    }
  }
`;
