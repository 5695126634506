import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { applyUTCOffsetToTime } from "../../../globals/utils/helpers";
import MoovsDateTimePicker, {
  MoovsDateTimePickerProps,
} from "../../globals/MoovsDateTimePicker";
import MoovsDateTimePickerWithConfirm from "components/globals/MoovsDateTimePickerWithConfirm/MoovsDateTimePickerWithConfirm";

type RHFDateTimePickerProps = {
  name: string;
  trackDateTimeSelected?: (name: string, analytics?: {}) => void;
  displayConfirmTime?: boolean;
} & Omit<MoovsDateTimePickerProps, "onAccept" | "onChange" | "value">;

export const RHFDateTimePicker = (props: RHFDateTimePickerProps) => {
  const {
    name,
    trackDateTimeSelected,
    displayConfirmTime,
    ...dateTimePickerProps
  } = props;

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control} // optional when using form provider
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const renderInputProps = {
          ...props.renderInputProps,
          name,
          error: !!error?.message,
          helperText: error?.message,
        };

        // date picker automatically accounts for timezone
        // because our values are handled in UTC time, they are appended with "Z" to indicate UTC timezone
        // by slicing "Z" off, the string is timezone agnostic, so the datetimepicker displays the time as is, which is what we want.
        const utcDateValueToLocalTimezone = value ? value.slice(0, -1) : null;

        // ordering of props is delicate because of what we want to override
        // be careful when changing

        const DateTimePicker = displayConfirmTime
          ? MoovsDateTimePickerWithConfirm
          : MoovsDateTimePicker;

        return (
          <DateTimePicker
            {...dateTimePickerProps}
            name={name}
            value={utcDateValueToLocalTimezone}
            renderInputProps={renderInputProps}
            onAccept={(date) => {
              trackDateTimeSelected &&
                trackDateTimeSelected("bookingTool_dateTimeSelected");
              const dateInUTC = date
                ? applyUTCOffsetToTime(date, "subtract").toISOString()
                : null;

              // ex: user selects 12/21/24 - 2:53pm (14:53)
              // this saves as 2021-12-24T14:53:37.000Z
              onChange(dateInUTC);
            }}
          />
        );
      }}
    />
  );
};
