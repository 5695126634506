import React from "react";

import { ToggleButtonGroup, Box } from "@mui/material";

import { useOperator } from "globals/hooks";
import GratuityToggleButton from "./GratuityToggleButton";

type GratuityToggleButtonGroupProps = {
  value: number | "cash";
  onChange: (gratuityPct: number | "cash") => void;
  baseRateAmt: number;
  customGratuityInputOpen: boolean;
  hideCashOption?: boolean;
  error?: boolean;
};

function GratuityToggleButtonGroup(props: GratuityToggleButtonGroupProps) {
  const {
    value,
    onChange,
    baseRateAmt,
    customGratuityInputOpen,
    hideCashOption,
    error,
  } = props;

  // hooks
  const {
    operator: { driverGratuityPresets, cashGratuityWhenBooking },
  } = useOperator();

  // event handlers
  const handleClick = (newGratuityPercent: number | "cash") => () => {
    onChange(newGratuityPercent);
  };

  return (
    <ToggleButtonGroup
      exclusive
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <Box
        display="flex"
        width="100%"
        flexDirection="row"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        {driverGratuityPresets.map((gratuityPct: number) => (
          <Box mr={0.5} flex="1" key={gratuityPct}>
            <GratuityToggleButton
              key={gratuityPct}
              selected={value === gratuityPct && !customGratuityInputOpen}
              label={`${gratuityPct}%`}
              value={baseRateAmt * (gratuityPct / 100)}
              onClick={handleClick(gratuityPct)}
              error={error}
            />
          </Box>
        ))}
      </Box>

      {/* cash gratuity */}
      {!hideCashOption && cashGratuityWhenBooking && (
        <Box flex="1">
          <GratuityToggleButton
            selected={value === "cash" && !customGratuityInputOpen}
            label="Cash"
            value={"cash"}
            onClick={handleClick("cash")}
            error={error}
          />
        </Box>
      )}
    </ToggleButtonGroup>
  );
}

export default GratuityToggleButtonGroup;
