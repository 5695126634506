import React from "react";
import sortBy from "lodash/sortBy";

import { Box } from "@mui/material";

import CancellationPolicyConditions from "./CancellationPolicy/CancellationPolicyConditions";
import MoovsDialog from "../globals/MoovsDialog";
import { CancellationPolicy } from "../../types";
import { useScreenSize } from "../../globals/hooks";

type CancellationPolicyDialogProps = {
  cancellationPolicyOpen: boolean;
  onCancellationPolicyClose: () => void;
  cancellationPolicies: CancellationPolicy[];
};

function CancellationPolicyDialog(props: CancellationPolicyDialogProps) {
  const {
    cancellationPolicyOpen,
    onCancellationPolicyClose,
    cancellationPolicies = [],
  } = props;

  // hook
  const { isMobileView } = useScreenSize();

  // sort policies to always be in order (flexible, moderate, strict).
  const sortedCancellationPolicies = sortBy(cancellationPolicies, ["variant"]);

  return (
    <MoovsDialog
      open={cancellationPolicyOpen}
      onClose={onCancellationPolicyClose}
      size="md"
      dialogTitle="Cancellation Policies"
    >
      <Box minHeight={500} pl={1} py={1} {...(isMobileView && { pr: 1 })}>
        <CancellationPolicyConditions policies={sortedCancellationPolicies} />
      </Box>
    </MoovsDialog>
  );
}

export default CancellationPolicyDialog;
