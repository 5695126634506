import React from "react";

import { Box, Divider, Typography } from "@mui/material";

import { grayMedium } from "design-system/colors";
import MoovsDialog from "components/globals/MoovsDialog";
import { AdditionalItems } from "../../hooks";
import ChildSeatItem from "./components/ChildSeatItem";
import { useViewChildSeatPhoto } from "./hooks/useViewChildSeatPhoto";

type AdditionalItemsBlockProps = AdditionalItems & {
  childSeatsFormPath: string;
};

function AdditionalItemsBlock(props: AdditionalItemsBlockProps) {
  const { childSeats, childSeatsFormPath } = props;

  // hooks
  const {
    photoUrl,
    photoDialogTitle,
    photoDialogOpen,
    handleViewPhotoClick,
    handleClosePhotoDialog,
  } = useViewChildSeatPhoto();

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="overline" id="driver-gratuity">
          Additional Items (Optional)
        </Typography>
      </Box>

      <Box
        mt={1.5}
        p={2}
        sx={{ border: 1, borderColor: grayMedium, borderRadius: 1 }}
      >
        <Typography variant="body1" fontWeight={500} mb={1.5}>
          Child Seat
        </Typography>
        {childSeats.map((seat, index) => {
          const isLastItem = index === childSeats.length - 1;

          return (
            <Box key={seat.id}>
              <ChildSeatItem
                childSeat={seat}
                onViewPhotoClick={handleViewPhotoClick}
                childSeatsFormPath={childSeatsFormPath}
              />
              {!isLastItem && <Divider sx={{ my: 1.5 }} />}
            </Box>
          );
        })}
      </Box>

      <MoovsDialog
        open={photoDialogOpen}
        onClose={handleClosePhotoDialog}
        dialogTitle={photoDialogTitle}
        size="sm"
        removeCloseButton
      >
        <Box display="flex" alignItems="center" height="100%">
          <img width="100%" src={photoUrl} alt="child-seat" loading="lazy" />
        </Box>
      </MoovsDialog>
    </Box>
  );
}

export default AdditionalItemsBlock;
