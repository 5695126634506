import React from "react";
import { Box, Button, TableCell } from "@mui/material";

import { Stop } from "../../../../types";

type MapCellProps = {
  stops: Stop[];
};

function MapCell(props: MapCellProps) {
  const stops = props.stops as any;

  const handleGoogleMapClick = () => {
    const formatStopForGoogleDirections = ({ airport, location }) => {
      return airport
        ? `${airport.coordinates.x},${airport.coordinates.y}`
        : location;
    };

    const mapOptions = {
      travelmode: "driving",
      origin: formatStopForGoogleDirections(stops[0]),
      destination: formatStopForGoogleDirections(stops[stops.length - 1]),
      waypoints: stops
        .slice(1, stops.length - 1)
        .map((stop) => formatStopForGoogleDirections(stop))
        .join("|"),
    };

    const searchParams = new URLSearchParams(mapOptions);

    window.open(
      `https://www.google.com/maps/dir/?api=1&${searchParams}`,
      "_blank"
    );
  };

  return (
    <TableCell>
      <Box minWidth={140}>
        <Button variant="outlined" onClick={handleGoogleMapClick}>
          View Map
        </Button>
      </Box>
    </TableCell>
  );
}

export default MapCell;
