/**
 * @file useAuth.tsx
 * Abstracts wrapping AuthContext with useContext.
 * Main point is to prevent importing two functions
 * when you want access to AuthContext.
 *
 */
import { useContext } from "react";
import AuthContext from "./Context";
import { SSOTypes } from "./Provider";

export type LoginDialogProps = {
  mobilePhone?: {
    mobilePhone: string;
    phoneCountryCode: string;
    phoneCountryDialCode: string;
    phoneCountryName: string;
    phoneCountryFormat: string;
  };
  email?: string;
  isNetflixLogin?: boolean;
  verifyButtonText?: string;
  verifyButtonIcon?: any;
  onVerifySuccess?: () => void;
  sendCodeText?: string;
  verifyCodeText?: string;
  disableClose?: boolean;
  renderContinueAsGuest?: boolean;
};

export type LoginData = {
  email?: string;
  id?: string;
  contactId?: string;
  firstName?: string;
  lastName?: string;
  mobilePhone?: string;
  phoneCountryCode?: string;
  phoneCountryDialCode?: string;
  phoneCountryFormat?: string;
  phoneCountryName?: string;
};

type AuthContextType = {
  authStage: "pending" | "authenticated" | "rejected";
  onLogin?: () => Promise<void>;
  onLogout?: () => Promise<void>;
  onLoginDialogOpen?: (props?: LoginDialogProps) => void;
  onLoginDialogClose?: () => void;
  SSOType?: SSOTypes;
  loginData: LoginData | null;
  setLoginData?: (props: Partial<LoginData>) => void;
};

// see useAuth/Provider.tsx for implementation
function useAuth(): AuthContextType {
  const AuthData = useContext<AuthContextType>(AuthContext);
  if (!AuthData) return { authStage: "rejected", loginData: null }; // prevents error and ensures booking contact for white label
  return AuthData;
}

export default useAuth;
