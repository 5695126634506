import React from "react";

import { Box, Link, Typography } from "@mui/material";

import { currency } from "../../utils/helpers";
import { grayDark } from "../../design-system/colors";
import { OpenIcon } from "../../design-system/icons";
import { Contact } from "types";

type OrderListItemDesktopProps = {
  orderNumber: string;
  reservationLink: string;
  requestTotals: {
    totalAmount: number;
    amountDue: number;
    totalPaid: number;
  };
  bookingContact?: Contact;
};

function OrderListItemDesktop(props: OrderListItemDesktopProps) {
  const { orderNumber, reservationLink, requestTotals, bookingContact } = props;

  const { totalAmount, amountDue, totalPaid } = requestTotals;

  return (
    <Box display="flex" flexDirection="row" flex="1" p={2} alignItems="center">
      {/* Order No */}
      <Box
        display="flex"
        flex=".25"
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <Typography
          variant="button"
          color="primary"
          style={{ fontWeight: 700 }}
        >
          <Link target="_blank" href={reservationLink}>
            {orderNumber}
          </Link>
        </Typography>
        <Box mt="6px" ml={1}>
          <Link target="_blank" href={reservationLink}>
            <OpenIcon color={grayDark} size="small" />
          </Link>
        </Box>
      </Box>
      {/* Billing Contact for Company Invoices */}
      {bookingContact && (
        <Box display="flex" flex=".25" flexDirection="column">
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 700, lineHeight: "20px" }}
          >
            {`${bookingContact.firstName} ${bookingContact.lastName}`}
          </Typography>
        </Box>
      )}
      {/* Pick Up Address Empty Column */}
      <Box display="flex" flex=".23" flexDirection="column"></Box>
      {/* Amount */}
      <Box display="flex" flex=".2">
        <Typography
          variant="subtitle2"
          style={{ fontWeight: 700, lineHeight: "20px" }}
        >
          {currency(totalAmount)}
        </Typography>
      </Box>
      {/* Paid */}
      <Box display="flex" flex=".14">
        <Typography
          variant="subtitle2"
          style={{ fontWeight: 700, lineHeight: "20px" }}
        >
          {currency(totalPaid)}
        </Typography>
      </Box>
      {/* Amount Due */}
      <Box display="flex" flex=".13" justifyContent="flex-end">
        <Typography
          variant="subtitle2"
          style={{ fontWeight: 700, lineHeight: "20px" }}
        >
          {currency(amountDue)}
        </Typography>
      </Box>
    </Box>
  );
}

export default OrderListItemDesktop;
