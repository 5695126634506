import React from "react";

import { Box, Typography, Stack } from "@mui/material";

import { ChildSeatVariant, VehicleChildSeat } from "types";
import { FeatureItem } from ".";
import RoundChip from "design-system/components/RoundChip";
import { grayDark, white } from "design-system/colors";

// constants
const CHILD_SEAT_NAME_MAP = {
  [ChildSeatVariant.Booster]: "Booster",
  [ChildSeatVariant.ForwardFacing]: "Forward-Facing",
  [ChildSeatVariant.RearFacing]: "Rear-Facing",
};

type ChildSeatFeaturesProps = {
  childSeats: VehicleChildSeat[];
  hasTripConflict: boolean;
};

function ChildSeatFeatures(props: ChildSeatFeaturesProps) {
  const { childSeats, hasTripConflict } = props;

  return (
    <Box>
      <Typography variant="overline">Child Seats</Typography>

      <Stack spacing={1.5} mt={1.5}>
        {childSeats.map((seat) => (
          <Box key={seat.type} display="flex" gap={0.5}>
            <FeatureItem
              key={seat.id}
              name={CHILD_SEAT_NAME_MAP[seat.type]}
              hasTripConflict={hasTripConflict}
            />

            <RoundChip
              label={seat.quantity.toString()}
              backgroundColor={grayDark}
              color={white}
            />
          </Box>
        ))}
      </Stack>
    </Box>
  );
}

export default ChildSeatFeatures;
