import React from "react";
import capitlize from "lodash/capitalize";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "../../VehicleImageCarousel.css";

import { Box, Typography, Button } from "@mui/material";

import MissingImage from "design-system/images/MissingImage.png";
import { granite, grayMedium } from "design-system/colors";
import { VehiclePhoto } from "types";
import { WhiteLabelVehicleListItemProps } from "./WhiteLabelVehicleListItem";

function WhiteLabelVehicleListItemDesktop(
  props: WhiteLabelVehicleListItemProps
) {
  const { vehicle, buttonText, onVehicleSelect } = props;

  return (
    <Box
      display="flex"
      flex="1"
      flexDirection="column"
      justifyContent="flex-start"
      maxWidth="700px"
      mt={2}
    >
      <Box
        display="flex"
        flex="1"
        flexDirection="row"
        justifyContent="space-between"
        mb={1}
      >
        <Box display="flex" flexDirection="row">
          <img
            src={vehicle.operator.companyLogoUrl}
            alt=""
            style={{
              height: "30px",
              marginRight: "8px", // equivalent to mui's mr={1}
            }}
          />
          <Typography
            variant="h3"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              mt: 0.5,
            }}
          >
            {vehicle.operator.name || "no name"}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="row" mt={1}>
          <Typography variant="subtitle2">
            {capitlize(vehicle.exteriorColor) || ""}{" "}
            {capitlize(vehicle.vehicleType.typeName) || ""}
          </Typography>
          <Typography mx={1} color={grayMedium} fontWeight={800}>
            ·
          </Typography>
          <Typography variant="body2" color={granite}>
            {vehicle.capacity} person{vehicle.capacity > 1 ? "s" : ""}
          </Typography>
        </Box>
      </Box>

      <ImageGallery
        lazyLoad
        showBullets
        showThumbnails
        showNav={false}
        showFullscreenButton={false}
        showPlayButton={false}
        onErrorImageURL={MissingImage}
        items={vehicle.photos?.map((photo: VehiclePhoto) => ({
          original: photo.url,
          originalTitle: vehicle.exteriorColor + vehicle.vehicleType.typeName,
          originalAlt: vehicle.exteriorColor + vehicle.vehicleType.typeName,
          thumbnail: photo.url,
        }))}
      />

      <Box
        mt={1.5}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex">
          <Box mr={3}>
            <Button
              disableRipple
              variant="contained"
              color="primary"
              onClick={onVehicleSelect}
            >
              {buttonText}
            </Button>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant="h6" style={{ color: granite, fontWeight: 500 }}>
            Request for Pricing
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default WhiteLabelVehicleListItemDesktop;
