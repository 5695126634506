import React from "react";

import moment from "moment-timezone";

import { Box, Typography, Divider } from "@mui/material";

import { FarmRelationshipEnum, Trip } from "../../types";
import {
  grayMedium,
  grayDark,
  alabaster,
  errorRed,
  tintRed,
} from "../../design-system/colors";
import { currency } from "../../utils/helpers";
import PricingItemsBlock from "./PricingItemsBlock";
import RoundChip from "../../design-system/components/RoundChip";
import { useScreenSize } from "../../globals/hooks";

export type PricingBlockProps = {
  trip: Trip;
  requestOrderNo: string;
};

function PricingBlock(props: PricingBlockProps) {
  const { isMobileView } = useScreenSize();
  const { trip, requestOrderNo } = props;

  const confNo = trip.tripNumber
    ? `${requestOrderNo}-${trip.tripNumber}`
    : requestOrderNo;

  const passengerName = trip.tempPassenger?.name
    ? `${trip.tempPassenger.name}`
    : `${trip.contact.firstName} ${trip.contact.lastName}`;

  const { farmRelationship, isFarmedRoute } = trip.routes[0];

  const isFarmee =
    isFarmedRoute && farmRelationship === FarmRelationshipEnum.Farmee;

  const isCancelledTrip = !!trip.cancelledAt;
  const hasCancellationFee =
    isCancelledTrip && !!trip.routes[0].pricing.cancellationFee;
  const shouldShowPricingBlock =
    !isCancelledTrip || (isCancelledTrip && hasCancellationFee);

  // render methods
  const renderCancelledChip = () => (
    <RoundChip label="CANCELLED" color={errorRed} backgroundColor={tintRed} />
  );

  const renderPricingBlock = () => (
    <>
      <Box
        display="flex"
        flex="1"
        flexDirection="row"
        mb={1}
        px={isMobileView ? 0 : 2}
      >
        <Box display="flex" flex="1.5">
          <Typography variant="overline" sx={{ color: grayDark }}>
            item
          </Typography>
        </Box>
        <Box display="flex" flex=".5" justifyContent="flex-end">
          <Typography variant="overline" sx={{ color: grayDark }}>
            amount
          </Typography>
        </Box>
      </Box>
      <Box
        bgcolor={isMobileView ? "" : alabaster}
        pt={2}
        px={isMobileView ? 0 : 2}
      >
        <PricingItemsBlock
          pricing={
            isFarmee ? trip.routes[0].farmeePricing : trip.routes[0].pricing
          }
        />
      </Box>
      {isMobileView && <Divider />}
      <Box
        display="flex"
        flex="1"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="baseline"
        mt={isMobileView ? 2 : 4}
      >
        <Box mr={4}>
          <Typography variant="h6" sx={!isMobileView && { fontWeight: "bold" }}>
            Subtotal
          </Typography>
        </Box>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {currency(trip.totalAmount)}
        </Typography>
      </Box>
    </>
  );

  return (
    <Box
      border={1}
      borderColor={grayMedium}
      borderRadius="4px"
      display="flex"
      flex="1"
      flexDirection="column"
      py={2}
      px={isMobileView ? 2 : 3}
    >
      <Box
        display="flex"
        flex="1"
        flexDirection={isMobileView ? "column" : "row"}
        {...(!isMobileView && { alignItems: "flex-start" })}
      >
        <Box display="flex" mr={2}>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            mb={isMobileView ? 1 : 0}
          >
            <Typography variant="overline" sx={{ color: grayDark }}>
              conf no
            </Typography>
            <Typography variant="h6">{confNo}</Typography>
          </Box>
          {isMobileView && isCancelledTrip && renderCancelledChip()}
        </Box>
        <Box display="flex" mr={2}>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            mb={isMobileView ? 1 : 0}
          >
            <Typography variant="overline" sx={{ color: grayDark }}>
              passenger name
            </Typography>
            <Typography variant="h6">{passengerName}</Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          mb={isMobileView ? 1 : 0}
          mr={2}
        >
          <Typography variant="overline" sx={{ color: grayDark }}>
            vehicle
          </Typography>
          <Typography variant="h6">{trip.routes[0].vehicle?.name}</Typography>
        </Box>
        <Box
          display="flex"
          flex="3"
          flexDirection="column"
          mb={isMobileView ? 1 : 0}
        >
          <Typography variant="overline" sx={{ color: grayDark }}>
            trip date & time
          </Typography>
          <Typography variant="h6">
            {moment.utc(trip.stops[0].dateTime).format("LLLL")}
          </Typography>
        </Box>
        {!isMobileView && isCancelledTrip && renderCancelledChip()}
      </Box>

      {shouldShowPricingBlock && (
        <>
          <Box my={2}>
            <Divider />
          </Box>
          {renderPricingBlock()}
        </>
      )}
    </Box>
  );
}

export default PricingBlock;
