// server enums generate OrderTypeEnum as SNAKE_CASE, but orderType.slug (value) is in lowercase-kebab-case, so these enum maps help compare those
const orderTypeToEnumMap = {
  airport: "AIRPORT",
  "airport-drop-off": "AIRPORT_DROP_OFF",
  "airport-pick-up": "AIRPORT_PICK_UP",
  anniversary: "ANNIVERSARY",
  "bachelor-bachelorette": "BACHELOR_BACHELORETTE",
  bar: "BAR",
  "bar-bat-mitzvah": "BAR_BAT_MITZVAH",
  baseball: "BASEBALL",
  basketball: "BASKETBALL",
  birthday: "BIRTHDAY",
  "birthday-21": "BIRTHDAY_21",
  "brew-tour": "BREW_TOUR",
  "bridal-party": "BRIDAL_PARTY",
  "bride-groom": "BRIDE_GROOM",
  "business-trip": "BUSINESS_TRIP",
  concert: "CONCERT",
  corporate: "CORPORATE",
  "corporate-event": "CORPORATE_EVENT",
  "day-tour": "DAY_TOUR",
  "family-reunion": "FAMILY_REUNION",
  "field-trip": "FIELD_TRIP",
  football: "FOOTBALL",
  funeral: "FUNERAL",
  golf: "GOLF",
  graduation: "GRADUATION",
  hockey: "HOCKEY",
  holiday: "HOLIDAY",
  "kids-birthday": "KIDS_BIRTHDAY",
  leisure: "LEISURE",
  medical: "MEDICAL",
  "night-out": "NIGHT_OUT",
  other: "OTHER",
  "personal-trip": "PERSONAL_TRIP",
  "point-to-point": "POINT_TO_POINT",
  "prom-homecoming": "PROM_HOMECOMING",
  quinceanera: "QUINCEANERA",
  retail: "RETAIL",
  school: "SCHOOL",
  "school-fundraiser": "SCHOOL_FUNDRAISER",
  "special-occasion": "SPECIAL_OCCASION",
  seaport: "SEAPORT",
  "sporting-event": "SPORTING_EVENT",
  "sweet-16": "SWEET_16",
  "train-station": "TRAIN_STATION",
  wedding: "WEDDING",
  "wine-tour": "WINE_TOUR",
};

const orderTypeEnumToNameMap = {
  AIRPORT: "Airport",
  AIRPORT_DROP_OFF: "Airport Drop Off",
  AIRPORT_PICK_UP: "Airport Pick Up",
  ANNIVERSARY: "Anniversary",
  BACHELOR_BACHELORETTE: "Bachelor/Bachelorette",
  BAR: "Bar",
  BAR_BAT_MITZVAH: "Bar/Bat Mitzvah",
  BASEBALL: "Baseball",
  BASKETBALL: "Basketball",
  BIRTHDAY: "Birthday",
  BIRTHDAY_21: "21st Birthday",
  BREW_TOUR: "Brew Tour",
  BRIDAL_PARTY: "Bridal Party",
  BRIDE_GROOM: "Bride/Groom",
  BUSINESS_TRIP: "Business Trip",
  CONCERT: "Concert",
  CORPORATE: "Corporate",
  CORPORATE_EVENT: "Corporate Event",
  DAY_TOUR: "Day Tour",
  FAMILY_REUNION: "Family Reunion",
  FIELD_TRIP: "Field Trip",
  FOOTBALL: "Football",
  FUNERAL: "Funeral",
  GOLF: "Golf",
  GRADUATION: "Graduation",
  HOCKEY: "Hockey",
  HOLIDAY: "Holiday",
  KIDS_BIRTHDAY: "Kids Birthday",
  LEISURE: "Leisure",
  MEDICAL: "Medical",
  NIGHT_OUT: "Night Out",
  OTHER: "Other",
  PERSONAL_TRIP: "Personal Trip",
  POINT_TO_POINT: "Point-to-Point",
  PROM_HOMECOMING: "Prom/Homecoming",
  QUINCEANERA: "Quinceanera",
  RETAIL: "Retail",
  SCHOOL: "School",
  SCHOOL_FUNDRAISER: "School Fundraiser",
  SEAPORT: "Seaport",
  SPECIAL_OCCASION: "Special Occasion",
  SPORTING_EVENT: "Sporting Event",
  SWEET_16: "Sweet 16",
  TRAIN_STATION: "Train Station",
  WEDDING: "Wedding",
  WINE_TOUR: "Wine Tour",
};

export { orderTypeToEnumMap, orderTypeEnumToNameMap };
