import React from "react";
import uniqBy from "lodash/uniqBy";
import map from "lodash/map";

import { Box, Card, Typography } from "@mui/material";

import { useScreenSize } from "../../globals/hooks";
import { SubPayment, PaymentMethodEnum } from "../../types";
import { currency } from "../../utils/helpers";
import { white } from "../../design-system/colors";
import { CreditCardIcon } from "../../design-system/icons";
import { toTitleCase } from "../../globals/utils/string";

type PaymentSourceBlockProps = {
  subPayments: SubPayment[];
};

function PaymentSourceBlock(props: PaymentSourceBlockProps) {
  const { subPayments } = props;

  const { isMobileView } = useScreenSize();

  // this is deduplicating the subpayments by payment id and then
  // setting the amount field to that of the total payment amount
  // so that payments are not divided by trip.
  const uniqByPayment = uniqBy(subPayments, "paymentId");

  const paymentsList = map(uniqByPayment, (subPayment) => ({
    ...subPayment,
    amount: subPayment.payment.amount,
  }));

  return (
    <>
      {paymentsList.map((payment) => {
        const {
          amount,
          payment: { method: methodOfPayment, paymentMethod },
        } = payment;

        return (
          <Card variant="outlined" key={payment.id}>
            <Box py={1.5} px={2} bgcolor={white}>
              <Box mb={isMobileView ? 1 : 0} flex={isMobileView ? 0 : 0.5}>
                {methodOfPayment === PaymentMethodEnum.Card ? (
                  <>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="overline">Payment Card</Typography>
                      <Typography variant="body2">Total</Typography>
                    </Box>

                    <Box display="flex" justifyContent="space-between">
                      <Box display="flex" alignItems="center">
                        <CreditCardIcon brand={paymentMethod.card.brand} />
                        <Box ml={3}>
                          <Typography variant="body2">
                            {`****-${paymentMethod.card.last4}`}
                          </Typography>
                        </Box>
                      </Box>
                      <Typography variant="subtitle1">
                        {currency(amount)}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <Box>
                    <Typography variant="overline">Payment Method</Typography>
                    <Typography variant="body2">
                      {methodOfPayment === PaymentMethodEnum.GenericCard
                        ? "Credit Card"
                        : toTitleCase(methodOfPayment)}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Card>
        );
      })}
    </>
  );
}

export default PaymentSourceBlock;
