import first from "lodash/first";

import { ErrorMessages } from "./errorCodesMap";

// function to extract code from error object
export const getErrorCode = (error) => {
  return (
    first(error?.graphQLErrors)?.code ||
    error?.networkError?.result?.errors[0]?.code
  );
};

// based on error object, get friendly error message
export const getErrorMessage = (error) => ErrorMessages[getErrorCode(error)];
