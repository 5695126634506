import React from "react";

import { Box, Button } from "@mui/material";

import { black, grayLight, white } from "design-system/colors";
import { ArrowRightIcon, InfoIcon } from "design-system/icons";
import MoovsTooltip from "components/globals/MoovsTooltip";
import { PRICING_SELECT_MIN_WIDTH } from "./VehiclePricingSelect/VehiclePricingSelect";

type PricelessSelectProps = {
  showTooltip: boolean;
  onVehicleSelect: (e: any) => void;
};

function PricelessSelect(props: PricelessSelectProps) {
  const { onVehicleSelect, showTooltip } = props;

  return (
    <>
      <Box
        bgcolor={grayLight}
        borderRadius="4px"
        minWidth={PRICING_SELECT_MIN_WIDTH}
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="fit-content"
        py={2}
      >
        <Box display="flex" alignItems="center" width="100%" px={1.5}>
          <Button fullWidth variant="contained" onClick={onVehicleSelect}>
            Choose Vehicle
            <Box ml={1} />
            <ArrowRightIcon size="small" color={white} />
          </Button>
          {showTooltip ? (
            <Box ml={0.5} mb={-1}>
              <MoovsTooltip tooltipText="Price will be added after your reservation request is sent">
                <InfoIcon color={black} />
              </MoovsTooltip>
            </Box>
          ) : null}
        </Box>
      </Box>
    </>
  );
}

export default PricelessSelect;
