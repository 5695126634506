/**
 * @file TripPage.tsx
 * Page for customers to view upcoming trip details, including
 * driver status
 */

import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import first from "lodash/first";

import {
  Typography,
  Box,
  CircularProgress,
  Divider,
  useTheme,
} from "@mui/material";

import { LOAD_OPERATOR_ROUTE_QUERY } from "../../globals/graphql";
import { toTitleCase } from "../../globals/utils/string";
import { formatPhoneNumber } from "../../utils/phoneNumberFormatter/phoneNumberFormatter";
import { OperatorRoute } from "../../types";
import TripFooter from "./components/TripFooter";
import DriverContactBlock from "../../components/trip/DriverContactBlock";
import TripNoteBlock from "../../components/trip/TripNoteBlock";
import VehicleBlock from "../../components/trip/VehicleBlock";
import HeaderBlock from "../../components/trip/HeaderBlock";
import TripPlannerBlock from "../../components/trip/TripPlannerBlock";
import PassengerContactBlock from "../../components/trip/PassengerContactBlock";
import { alabaster } from "design-system/colors";
import { useOperator } from "globals/hooks";

function DispatchPage() {
  const { publicId } = useParams<{ publicId: string }>();
  const theme = useTheme();
  const { isElSegundoConnect } = useOperator();

  // queries
  const {
    data: operatorRouteData,
    loading: operatorRouteLoading,
    error: operatorRouteError,
  } = useQuery(LOAD_OPERATOR_ROUTE_QUERY, {
    variables: {
      publicId: publicId,
    },
    skip: !publicId,
    fetchPolicy: "network-only",
    pollInterval: 10 * 1000,
  });

  const operatorRoute: OperatorRoute = operatorRouteData?.operatorRoute;
  const request = operatorRoute?.request;
  const trip = operatorRoute?.trip;

  // routeDispatch is the driver app route info
  const route = first(trip?.routes);
  const routeDispatch = route?.routeDispatch;

  const isTemporaryPassenger = !!trip?.temporaryPassenger?.name;

  const passengerName = isTemporaryPassenger
    ? trip?.tempPassenger.name
    : `${trip?.contact.firstName} ${trip?.contact.lastName}`;

  const passengerMobilePhone = isTemporaryPassenger
    ? trip?.tempPassenger.mobilePhone
    : formatPhoneNumber(
        trip?.contact.mobilePhone,
        trip?.contact.phoneCountryCode,
        trip?.contact.phoneCountryDialCode
      )?.formatted;

  if (
    operatorRouteError ||
    (operatorRouteData && operatorRouteData.node === null)
  ) {
    return (
      <Box
        position="fixed"
        display="flex"
        flexDirection="column"
        flex="1"
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h3">Server Error</Typography>
        <Typography variant="body1">
          Please check the provided url string.
        </Typography>
      </Box>
    );
  }

  if (operatorRouteLoading) {
    return (
      <Box
        position="fixed"
        display="flex"
        flexDirection="column"
        flex="1"
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  const { firstName, lastName = "" } = operatorRoute?.driver || {};
  const driverName = firstName && `${firstName} ${lastName}`;

  return (
    <>
      <Box width="100%" bgcolor={alabaster}>
        <Box
          display="flex"
          minHeight="100%"
          flexDirection="column"
          position="relative"
        >
          <Box
            display="flex"
            minHeight="100%"
            justifyContent="center"
            paddingBottom="94px"
          >
            <Box
              display="flex"
              flexDirection="column"
              flex="1"
              maxWidth={theme.breakpoints.values.sm}
            >
              <HeaderBlock
                trip={trip}
                tripType={toTitleCase(request?.type)}
                statusSlug={operatorRoute.statusSlug}
              />
              <Divider light />
              <Box mx={2}>
                {!isElSegundoConnect && (
                  <Box my={3}>
                    <DriverContactBlock
                      name={driverName}
                      phone={operatorRoute?.driver?.mobilePhone}
                      driverProfilePhoto={
                        operatorRoute?.driver?.driverProfilePhoto
                      }
                    />
                  </Box>
                )}

                <Box my={3}>
                  <PassengerContactBlock
                    name={passengerName}
                    phone={passengerMobilePhone}
                  />
                </Box>
                <Box my={3}>
                  <TripNoteBlock note={trip?.note} luggage={route} />
                </Box>
                <Box my={3}>
                  <TripPlannerBlock stops={trip?.stops} />
                </Box>
                <Box my={3}>
                  <VehicleBlock vehicle={operatorRoute?.vehicle} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Stickied Footer */}
      {route.dispatchStatus !== "done" &&
        route.dispatchStatus !== "pending" && (
          <TripFooter trip={trip} routeDispatch={routeDispatch} />
        )}
    </>
  );
}

export default DispatchPage;
