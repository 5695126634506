/**
 * @file DriverGratuityBlock.tsx
 * Where users can input driver gratuity from four buttons.
 * Only displays if driver gratuity was not previously added by an operator.
 */
import React, { useState } from "react";

import { Box, Typography } from "@mui/material";
import GratuityToggleButtonGroup from "./components/GratuityToggleButtonGroup";
import CustomGratuityInput from "./components/CustomGratuityInput";
import { Control, Controller } from "react-hook-form";

type DriverGratuityBlockProps = {
  control: Control<any>;
  baseRateAmt: number;
};

function DriverGratuityBlock(props: DriverGratuityBlockProps) {
  const { control, baseRateAmt } = props;

  // state
  const [customGratuityInputOpen, setCustomGratuityInputOpen] = useState(false);

  return (
    <Controller
      control={control}
      name="driverGratuityPctCustomerInput"
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        // event handlers
        const handleGratuityButtonChange = (
          driverGratuityPctCustomerInput: number
        ) => {
          setCustomGratuityInputOpen(false);
          onChange(driverGratuityPctCustomerInput);
        };

        const handleCustomGratuityChange = (
          driverGratuityPctCustomerInput: number
        ) => {
          setCustomGratuityInputOpen(true);
          onChange(driverGratuityPctCustomerInput);
        };

        return (
          <Box>
            <Box mb={2}>
              <Typography variant="overline" id="driver-gratuity">
                Add Gratuity
              </Typography>
            </Box>
            <GratuityToggleButtonGroup
              baseRateAmt={baseRateAmt}
              value={value}
              onChange={handleGratuityButtonChange}
              customGratuityInputOpen={customGratuityInputOpen}
              error={!!error?.message}
            />

            {/* custom gratuity */}
            <CustomGratuityInput
              value={value}
              baseRateAmt={baseRateAmt}
              onChange={handleCustomGratuityChange}
              customGratuityInputOpen={customGratuityInputOpen}
              error={!!error?.message}
            />

            {error?.message && (
              <Box mb={1}>
                <Typography color="error" variant="caption">
                  {error.message}
                </Typography>
              </Box>
            )}
          </Box>
        );
      }}
    ></Controller>
  );
}

export default DriverGratuityBlock;
