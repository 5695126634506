import React from "react";

type WifiIconProps = {
  color?: string;
  size: "small";
};

const WifiIcon = (props: WifiIconProps) => {
  const { color } = props;

  // size === small
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6409 7.50739C9.8074 6.68999 8.67702 6.23078 7.49837 6.23078C6.31971 6.23078 5.18933 6.68999 4.35585 7.50739M12.5283 5.65794C11.1947 4.35002 9.38607 3.61525 7.50017 3.61525C5.61427 3.61525 3.8056 4.35002 2.47202 5.65794M14.1668 3.57478C12.3568 1.92009 9.97449 1 7.50016 1C5.02584 1 2.64352 1.92009 0.833496 3.57478M9.27794 11.2563C9.27794 12.2193 8.482 13 7.50016 13C6.51832 13 5.72239 12.2193 5.72239 11.2563C5.72239 10.2933 6.51832 9.51262 7.50016 9.51262C8.482 9.51262 9.27794 10.2933 9.27794 11.2563Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WifiIcon;
