/**
 * @file CircleAvatar.tsx
 * Restyling of Avatar component with custom
 * props to be used throughout the site for
 * user customization.
 *
 * components:
 *  Circle Avatar
 *
 * variables:
 *  'alt',
 *    accepts a string used for img alt tag
 *  'src',
 *    accepts a string used for img src
 *  'placeholder',
 *    accepts a string or string[]. Takes first
 *    initial to use in Avatar
 *  'size',
 *    accepts 'small', 'medium', 'large' and 'xl
 *
 * author: sienag
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import { Avatar, useTheme } from "@mui/material";
import { grayDark, grayLight, grayMedium } from "../colors";

function CircleAvatar({
  alt,
  size = "medium", // default
  src,
  placeholder,
}: {
  alt?: string;
  size?: "small" | "medium" | "large" | "xl" | "xxl";
  src?: string;
  placeholder?: string | string[];
}) {
  const theme = useTheme();
  const avatarSizeStyles = (size) => {
    switch (size) {
      case "small":
        return {
          width: theme.spacing(4),
          height: theme.spacing(4),
          fontSize: 13,
        };
      case "medium":
        return {
          width: theme.spacing(5),
          height: theme.spacing(5),
          fontSize: 14,
        };
      case "large":
        return {
          width: theme.spacing(8),
          height: theme.spacing(8),
          fontSize: 24,
        };
      case "xl":
        return {
          width: theme.spacing(9),
          height: theme.spacing(9),
          fontSize: 32,
        };
      case "xxl":
        return {
          width: theme.spacing(15.5),
          height: theme.spacing(15.5),
          fontSize: 32,
        };
    }
  };

  const styles = {
    root: {
      backgroundColor: grayLight,
      color: grayDark,
      letterSpacing: "0.5px",
      fontWeight: 500,
    },
    img: {
      objectFit: "contain",
    },
    circleBorder: {
      border: `1px solid ${grayMedium}`,
    },
  };

  let initials = placeholder ? placeholder[0] : "";
  if (Array.isArray(placeholder)) {
    initials = `${placeholder[0]?.[0] || ""}${placeholder[1]?.[0] || ""}`;
  }

  return (
    <Avatar
      alt={alt}
      src={src}
      sx={[
        styles.root,
        avatarSizeStyles(size),
        //src && { "& .MuiAvatar-root": { border: 1, borderColor: grayMedium } },
      ]}
      {...(src && { style: { border: `1px solid ${grayMedium}` } })} //this is insane
    >
      {initials && initials.toUpperCase()}
    </Avatar>
  );
}

export default CircleAvatar;
