import React from "react";
import { Control, Controller } from "react-hook-form";

import { MenuItem, TextField } from "@mui/material";

type RHFSingleSelectProps = {
  control: Control<any>;
  name: string;
  options: {
    id: string;
    value: string;
    label: JSX.Element | string;
  }[];
  defaultValue?: string;
  label?: string;
  disabled?: boolean;
  errors?: string;
};

function RHFSingleSelect(props: RHFSingleSelectProps) {
  const { name, control, options, defaultValue = "", disabled, label } = props;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        return (
          <TextField
            {...field}
            select
            fullWidth
            label={defaultValue ? "" : label}
            error={!!error?.message}
            helperText={error?.message}
            disabled={disabled}
          >
            {options?.map((option) => {
              return (
                <MenuItem value={option.value} key={option.id}>
                  {option.label}
                </MenuItem>
              );
            })}
          </TextField>
        );
      }}
    />
  );
}

export default RHFSingleSelect;
