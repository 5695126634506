import React from "react";

import { Button } from "@mui/material";

import { useAuth } from "../../../../globals/hooks";
import { getIsNetflixLogin } from "utils/auth/isNetflixLogin";

type LoginButtonProps = {
  renderContinueAsGuest?: boolean;
};

function LoginButton(props: LoginButtonProps) {
  const { renderContinueAsGuest } = props;
  const isNetflixLogin = getIsNetflixLogin();

  // hooks
  const { onLoginDialogOpen } = useAuth();

  // event handlers
  const handleLoginClick = () => {
    onLoginDialogOpen({
      renderContinueAsGuest,
      isNetflixLogin,
    });
  };

  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      onClick={handleLoginClick}
    >
      Login
    </Button>
  );
}

export default LoginButton;
