import { Typography } from "@mui/material";
import React from "react";
import { RoundTripVariant, TripCategory } from "../../../../../../types";

type TripTypeLabelProps = {
  tripCategory?: TripCategory;
  roundTripVariant?: RoundTripVariant;
};

function TripTypeLabel(props: TripTypeLabelProps) {
  const { tripCategory, roundTripVariant } = props;

  switch (tripCategory) {
    case TripCategory.RoundTrip:
      return (
        <Typography variant="h3">
          Round Trip:{" "}
          {roundTripVariant === RoundTripVariant.Outbound
            ? "Pick-Up"
            : "Return"}
        </Typography>
      );

    case TripCategory.Hourly:
      return <Typography variant="h3">Hourly</Typography>;

    case TripCategory.OneWay:
      return <Typography variant="h3">One Way</Typography>;

    case TripCategory.ShuttlePickUp:
      return (
        <Typography
          style={{ whiteSpace: "pre-line" }}
          variant="h3"
        >{`Shuttle Transfer:\nPick-up`}</Typography>
      );

    case TripCategory.ShuttleReturn:
      return (
        <Typography style={{ whiteSpace: "pre-line" }} variant="h3">
          {"Shuttle Transfer:\nReturn"}
        </Typography>
      );

    default:
      return <Typography variant="h3">Trip Itinerary</Typography>;
  }
}

export default TripTypeLabel;
