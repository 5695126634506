import React from "react";
import moment from "moment-timezone";

import {
  Box,
  Typography,
  CircularProgress,
  IconButton,
  useTheme,
} from "@mui/material";

import { granite, grayMedium } from "../../design-system/colors";
import { SyncIcon } from "../../design-system/icons";
import RoundChip from "../../design-system/components/RoundChip";
import { AirportInfo, FlightProgressDisplay } from "./components";
import { FlightInfoCardContentProps } from "./FlightInfoCard";
import flightStatusMap from "./flightStatusMap";

function FlightInfoCardContentDesktop(props: FlightInfoCardContentProps) {
  const { airlineHeader, refreshFlightProps = {}, trackedFlight } = props;

  const { origin, destination, flightStatus, updatedAt } = trackedFlight;
  const { isRefreshingFlight, onClickRefreshFlight } = refreshFlightProps;

  const { statusColor, allowRefresh } = flightStatusMap(flightStatus);

  // hooks
  const theme = useTheme();

  return (
    <>
      <Box
        pb={2}
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        sx={{
          borderBottomWidth: "1px",
          borderBottomColor: grayMedium,
          borderBottomStyle: "solid",
        }}
      >
        <Box>
          <Box mb={1}>
            <Typography
              sx={{ color: theme.palette.text.primary, fontWeight: 600 }}
              variant="body2"
            >
              {airlineHeader}
            </Typography>
          </Box>
          <RoundChip
            label={flightStatus}
            color="white"
            backgroundColor={statusColor}
            textTransform="uppercase"
          />
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          {isRefreshingFlight ? (
            <Box p={1.5}>
              <CircularProgress size={20} thickness={4} />
            </Box>
          ) : (
            <>
              {updatedAt && (
                <Box display="flex" flexDirection="column">
                  <Typography
                    sx={{ color: theme.palette.text.primary, fontWeight: 600 }}
                    variant="h6"
                  >
                    Last Updated:
                  </Typography>
                  <Typography variant="body2">
                    {moment(updatedAt).calendar()}
                  </Typography>
                </Box>
              )}
              {!!onClickRefreshFlight && allowRefresh && (
                <IconButton onClick={onClickRefreshFlight} size="large">
                  <SyncIcon color={granite} />
                </IconButton>
              )}
            </>
          )}
        </Box>
      </Box>
      <Box py={2} display="flex" justifyContent="space-between">
        <Box flex={1}>
          <AirportInfo
            airportCode={origin.airport.iataCode || "-"}
            airportName={origin.airport.airportName || ""}
            transitStopType="origin"
          />
        </Box>
        <Box flex={3}>
          <FlightProgressDisplay flightStatus={flightStatus} />
        </Box>
        <Box flex={1}>
          <AirportInfo
            airportCode={destination.airport.iataCode || "-"}
            airportName={destination.airport.airportName || ""}
            transitStopType="destination"
          />
        </Box>
      </Box>
    </>
  );
}

export default FlightInfoCardContentDesktop;
