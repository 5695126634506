import React, {
  createRef,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { DragDropContext } from "react-beautiful-dnd";
import isEqual from "lodash/isEqual";
import moment from "moment-timezone";

import { Box } from "@mui/material";

import {
  checkIfStopTimesAreInOrder,
  reorderStops,
} from "globals/utils/helpers";
import { useSnackbar } from "globals/hooks/useSnackbar";
import { TripCategory } from "types";
import {
  CreateStopState,
  CreateTripState,
} from "pages/new/context/initialState";
import StopsBlockItem from "./StopsBlockItem";
import AddStopButton from "./AddStopButton";

type StopsBlockProps = {
  trip: CreateTripState;
  onTripChange: (newTrip: Partial<CreateTripState>) => void;
  requestErrors: any;
  setRequestErrors: any;
  disableEditingLastDateTime: boolean;
  canOnlyUpdateStopDate?: boolean;
  setIsFetchingFlightInfo?: Dispatch<SetStateAction<boolean>>;
};

function StopsBlock(props: StopsBlockProps) {
  const {
    trip,
    onTripChange,
    requestErrors,
    setRequestErrors,
    disableEditingLastDateTime,
    canOnlyUpdateStopDate,
    setIsFetchingFlightInfo,
  } = props;

  const snackbar = useSnackbar();

  // state
  const [stopRefs, setStopRefs] = useState([]);
  const [reorderedStops, setReorderedStops] = useState<any[]>(null);
  const [draggableIndexes, setDraggableIndexes] = useState<{
    sourceIndex: number;
    destinationIndex: number;
  }>(null);
  const [renderWarningForStop, setRenderWarningForStop] = useState<boolean[]>(
    []
  );

  // event handlers
  const handleCreateStop = () => {
    const stops = [...trip.stops];
    const lastStop = stops.pop();

    const newStops = [
      ...stops,
      {
        variant: "address" as "address" | "airport",
        location: "",
        dateTime: null,
        airport: null,
        airline: null,
        flightNumber: null,
        trackedFlight: null,
        stopIndex: trip.stops.length,
      },
      {
        ...lastStop,
        stopIndex: trip.stops.length + 1,
      },
    ];

    const stopsErrors = [...requestErrors.stops];
    const lastStopErrors = stopsErrors.pop();
    requestErrors.stops = [...requestErrors.stops];

    setRequestErrors({
      ...requestErrors,
      stops: [
        ...stopsErrors,
        {
          location: "",
          dateTime: "",
          stopIndex: requestErrors.stops.length,
        },
        {
          ...lastStopErrors,
          stopIndex: requestErrors.stops.length + 1,
        },
      ],
    });

    onTripChange({
      stops: newStops,
    });
  };

  const handleRemoveStop = (index: number) => () => {
    const newStops = trip.stops
      .filter((stop) => stop.stopIndex - 1 !== index)
      .map((stop, index) => {
        stop.stopIndex = index + 1;
        return stop;
      });

    onTripChange({
      stops: newStops,
    });

    requestErrors.stops.splice(index, 1);

    setRequestErrors({
      ...requestErrors,
      stops: requestErrors.stops,
    });
  };

  const handleStopChange = (index: number) => (newStop: CreateStopState) => {
    // updates last stop dateTime to equal first stop dateTime + duration
    const shouldUpdateLastStopDateTime =
      trip.tripCategory === TripCategory.Hourly &&
      index === 0 &&
      trip.totalDuration &&
      newStop.dateTime;

    let newStops: CreateStopState[] = trip.stops.map(
      (stop: CreateStopState, j: number) => {
        if (j !== index) return stop;

        return {
          ...stop,
          ...newStop,
        };
      }
    );

    if (shouldUpdateLastStopDateTime) {
      const lastStopDateTime = moment(newStop.dateTime)
        .add(Number(trip.totalDuration), "hours")
        .utc()
        .toISOString();

      newStops = [
        ...newStops.slice(0, -1),
        {
          ...newStops[trip.stops.length - 1],
          dateTime: lastStopDateTime,
        },
      ];
    }

    onTripChange({
      stops: newStops,
    });
  };

  const handleScrollToStop = (ref) => {
    ref?.current?.focus();
    ref?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const handleDragEnd = (e) => {
    const { destination, source } = e;

    if (!destination) {
      return;
    }

    const nextStops = reorderStops(trip.stops, source.index, destination.index);

    onTripChange({
      stops: nextStops,
    });

    // prevents stops from flashing
    setTimeout(() => {
      setDraggableIndexes(null);
      setReorderedStops(null);
    }, 0);
  };

  const handleDragUpdate = (e) => {
    const { destination, source } = e;

    if (!destination) {
      setReorderedStops(null);
      setDraggableIndexes(null);
      return;
    }

    const nextStops = reorderStops(trip.stops, source.index, destination.index);

    setReorderedStops(nextStops);
    setDraggableIndexes({
      sourceIndex: source.index,
      destinationIndex: destination.index,
    });
  };

  // effects
  useEffect(() => {
    // add or remove refs
    setStopRefs((stopRefs) => {
      const newStopRefs = trip.stops.map(createRef);

      // only focus after adding new stop, not on drawer mount
      if (stopRefs.length && trip.stops.length > stopRefs.length) {
        // we need timeout for ref.current to not be undefined
        setTimeout(
          // scroll to added stop
          () => handleScrollToStop(newStopRefs[trip.stops.length - 2]),
          0
        );
      }
      return newStopRefs;
    });
  }, [trip.stops]);

  // show/hide stop time out of order warning
  useEffect(() => {
    const stopsInOrderArray = checkIfStopTimesAreInOrder(trip.stops);

    if (!isEqual(stopsInOrderArray, renderWarningForStop)) {
      // if in previous render, any stop didn't have a warning and now does, render snackbar
      if (
        renderWarningForStop.some(
          (warning, index) => !warning && !!stopsInOrderArray[index]
        )
      ) {
        snackbar.warning("Date & times are not in order");
      }

      setRenderWarningForStop(stopsInOrderArray);
    }
  }, [setRenderWarningForStop, renderWarningForStop, snackbar, trip]);

  return (
    <DragDropContext onDragEnd={handleDragEnd} onDragUpdate={handleDragUpdate}>
      {trip.stops.map((stop: Partial<CreateStopState>, index) => {
        const { sourceIndex, destinationIndex } = draggableIndexes || {};
        let nextStop;

        if (
          draggableIndexes &&
          reorderedStops &&
          destinationIndex !== sourceIndex
        ) {
          nextStop = reorderedStops[index];
        }

        return (
          <Box mb={3} key={stop.id}>
            <StopsBlockItem
              showIncorrectStopOrderWarning={!!renderWarningForStop[index]}
              addressRef={stopRefs[index]}
              onStopChange={handleStopChange(index)}
              stop={stop}
              firstStop={trip.stops[0]}
              canRemoveStop={index !== 0 && index !== trip.stops.length - 1}
              canOnlyUpdateStopDate={canOnlyUpdateStopDate}
              stopsLength={trip.stops.length}
              requestErrors={requestErrors}
              setRequestErrors={setRequestErrors}
              onRemoveStop={handleRemoveStop(index)}
              disableEditingLastDateTime={
                disableEditingLastDateTime && index === trip.stops.length - 1
              }
              setIsFetchingFlightInfo={setIsFetchingFlightInfo}
              // draggable props
              nextStop={nextStop}
              isSourceIndex={sourceIndex === index}
              isDestinationIndex={destinationIndex === index}
            />

            {index === trip.stops.length - 2 && (
              <AddStopButton onClick={handleCreateStop} />
            )}
          </Box>
        );
      })}
    </DragDropContext>
  );
}

export default StopsBlock;
