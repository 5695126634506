import React, { useMemo } from "react";
import moment from "moment-timezone";
import first from "lodash/first";
import isNil from "lodash/isNil";
import { useHistory } from "react-router-dom";

import { Box, Button, Typography, useTheme } from "@mui/material";

import { grayMedium, grayDark, black } from "../../../design-system/colors";
import { Trip } from "../../../types";
import { useOperatorSlug } from "../../../globals/hooks";
import { CreditCardIcon } from "../../../design-system/icons/CreditCardIcons";
import MoovsTooltip from "../../../components/globals/MoovsTooltip";
import { getPaymentMethodInfoString } from "../../../globals/utils/helpers";
import { currency } from "utils/helpers";

export type TripCardProps = {
  trip: Trip;
};

function TripCard(props: TripCardProps) {
  const { trip } = props;

  const { orderNumber, preferredPaymentMethod } = trip.request;

  // hooks
  const history = useHistory();
  const operatorSlug = useOperatorSlug();
  const theme = useTheme();

  // event handlers
  const handleViewTripClick = () => {
    history.push({
      pathname: `/${operatorSlug}/trip/${trip.routes[0]?.publicId}`,
      state: {
        from: "orders",
      },
    });
  };

  const firstTripStartTime = moment.utc(first(trip.stops).dateTime);
  const firstTripStartTimeDisplay = firstTripStartTime.format(
    "MMM Do YYYY, h:mm A"
  );
  const { brand } = trip.request?.preferredPaymentMethod?.card || {};

  // show pricing if baseRateAmt is not null
  const showPricing = useMemo(
    () => !isNil(trip.routes[0].pricing?.baseRateAmt),
    [trip.routes]
  );

  return (
    <Box
      sx={{
        position: "relative",
        border: `1px solid ${grayMedium}`,
        borderRadius: "4px",
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.03)",
        minHeight: "178px",
        height: "100%",
        width: "100%",
        padding: theme.spacing(2),
      }}
    >
      <Box display="flex" flexDirection="column" height="100%">
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box display="flex" flexDirection="row">
            <Box mr={1}>
              <Typography
                variant="body2"
                style={{ fontWeight: 700, color: grayDark }}
              >
                {orderNumber}-{trip.tripNumber}
              </Typography>
            </Box>
          </Box>
          {showPricing && (
            <Box mt={1} display="flex" flexDirection="row">
              {preferredPaymentMethod && (
                <Box mr={1}>
                  <MoovsTooltip
                    tooltipPretext="Card Used:"
                    tooltipText={getPaymentMethodInfoString(
                      trip.request?.preferredPaymentMethod?.card
                    )}
                    noMaxWidth={true}
                  >
                    <CreditCardIcon brand={brand} />
                  </MoovsTooltip>
                </Box>
              )}
              <Typography
                variant="h3"
                style={{ fontWeight: 500, color: black }}
              >
                {currency(trip.totalAmount)}
              </Typography>
            </Box>
          )}
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="body1" style={{ fontWeight: 700 }}>
            {firstTripStartTimeDisplay}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="end"
          marginTop="auto"
          alignItems="flex-end"
        >
          <Box>
            <Button
              color="primary"
              fullWidth
              variant="outlined"
              onClick={handleViewTripClick}
            >
              View
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default TripCard;
