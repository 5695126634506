import React from "react";

import useIntroTermsAndCondtions from "pages/new/hooks/useIntroTermsAndConditions";
import { Box, Typography } from "@mui/material";

function TermsAndConditionsIntro() {
  // hooks
  const [introAgreement, introAgreement2, introAgreement3] =
    useIntroTermsAndCondtions();

  return (
    <Box>
      <Typography variant="body2" mt={2} mb={1}>
        {introAgreement}
      </Typography>
      <Typography variant="body2" my={3}>
        {introAgreement2}
      </Typography>
      <Typography variant="body2" mt={1} mb={2}>
        {introAgreement3}
      </Typography>
    </Box>
  );
}

export default TermsAndConditionsIntro;
