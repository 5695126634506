import React from "react";

type OneWayIconProps = {
  color?: string;
};

const OneWayIcon = (props: OneWayIconProps) => {
  const { color } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2852 11.5714L7.71367 11.5714M16.2852 11.5714L12.5649 15.1428M16.2852 11.5714L12.5649 7.99997"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 11.5715C22 11.966 21.6802 12.2857 21.2858 12.2857C20.8913 12.2857 20.5715 11.966 20.5715 11.5715C20.5715 11.177 20.8913 10.8572 21.2858 10.8572C21.6802 10.8572 22 11.177 22 11.5715Z"
        fill={color || "#B3B3B3"}
      />
      <path
        d="M3.42857 11.5715C3.42857 11.966 3.10877 12.2857 2.71429 12.2857C2.3198 12.2857 2 11.966 2 11.5715C2 11.177 2.3198 10.8572 2.71429 10.8572C3.10877 10.8572 3.42857 11.177 3.42857 11.5715Z"
        fill={color || "#B3B3B3"}
      />
      <path
        d="M22 11.5715C22 11.966 21.6802 12.2857 21.2858 12.2857C20.8913 12.2857 20.5715 11.966 20.5715 11.5715C20.5715 11.177 20.8913 10.8572 21.2858 10.8572C21.6802 10.8572 22 11.177 22 11.5715Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.42857 11.5715C3.42857 11.966 3.10877 12.2857 2.71429 12.2857C2.3198 12.2857 2 11.966 2 11.5715C2 11.177 2.3198 10.8572 2.71429 10.8572C3.10877 10.8572 3.42857 11.177 3.42857 11.5715Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default OneWayIcon;
