import React, { ChangeEvent, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useLazyQuery } from "@apollo/client";

import { Box, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { LOAD_PROMO_CODE_VALIDATION_QUERY } from "globals/graphql";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

type PromoCodeBlockProps = {
  pickUpDateTime: string;
};

function PromoCodeBlock(props: PromoCodeBlockProps) {
  const { pickUpDateTime } = props;

  // state
  const [promoCodeInput, setPromoCodeInput] = useState("");
  const [error, setError] = useState("");

  // hooks
  const { control } = useFormContext();

  const [validatePromoCode, { loading }] = useLazyQuery(
    LOAD_PROMO_CODE_VALIDATION_QUERY,
    {
      fetchPolicy: "no-cache", // fresh data on every request
    }
  );

  return (
    <Controller
      control={control}
      name="promoCodeCustomerInput"
      render={({ field: { onChange } }) => {
        // event handlers
        const handlePromoCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
          setError("");

          // If the input contains non-alphanumeric characters, filter them out
          const filteredValue = e.target.value.replace(/[^a-zA-Z0-9]/g, "");

          setPromoCodeInput(filteredValue.toUpperCase());
        };

        const handleValidatePromoCode = async () => {
          setError("");

          const { data, error } = await validatePromoCode({
            variables: {
              promoCodeName: promoCodeInput,
              pickUpDateTime,
            },
          });

          if (error) {
            const errorMessage =
              getErrorMessage(error) || "Error validating promo code";

            setError(errorMessage);
            return;
          }

          onChange(data.loadPromoCodeValidation);
        };

        return (
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            alignContent="center"
            gap={2}
            mt={3}
            mb={3}
          >
            <TextField
              variant="outlined"
              onChange={handlePromoCodeChange}
              label={"Enter Promo Code"}
              value={promoCodeInput}
              error={!!error}
              helperText={error}
              fullWidth
            />
            <LoadingButton
              variant="outlined"
              disabled={!promoCodeInput}
              loading={loading}
              onClick={handleValidatePromoCode}
              sx={{ height: "46px", mt: 0.5 }}
            >
              Apply
            </LoadingButton>
          </Box>
        );
      }}
    />
  );
}

export default PromoCodeBlock;
