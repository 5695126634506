import React, { ChangeEvent, useState } from "react";
import { useElements, CardElement, useStripe } from "@stripe/react-stripe-js";
import { SetupIntent, StripeCardElementChangeEvent } from "@stripe/stripe-js";
import { useMutation } from "@apollo/client";
import pickBy from "lodash/pickBy";

import { Box, TextField, Typography } from "@mui/material";

import MoovsStripeCardElement from "../../../globals/MoovsStripeCardElement";
import { LockIcon } from "../../../../design-system/icons";
import { useAnalytics, useSnackbar } from "../../../../globals/hooks";
import {
  CREATE_PAYMENT_METHOD_MUTATION,
  CREATE_WALLET_MUTATION,
  LOAD_ME_QUERY,
} from "../../../../globals/graphql";
import PayButton from "./PayButton";
import { getErrorMessage } from "../../../../moovsErrors/getErrorMessage";
import MobileAndEmailAuthentication from "../../../auth/ExistingMobileEmailAuthentication/MobileAndEmailAuthentication";
import { fromGlobalId } from "utils/auth/helpers";

type ChargeCustomerBlockProps = {
  totalDue: string;
  onCreatePayment: (paymentMethodId: string) => void;
  isLoading: boolean;
  contactId?: string;
  farmAffiliateId?: string;
  companyId?: string;
};

function UnauthenticatedChargeCustomerBlock(props: ChargeCustomerBlockProps) {
  const {
    contactId,
    farmAffiliateId,
    totalDue,
    onCreatePayment,
    isLoading,
    companyId,
  } = props;

  // hooks
  const snackbar = useSnackbar();
  const stripeElements = useElements();
  const stripe = useStripe();
  const { track } = useAnalytics();

  // state
  const [billingContact, setBillingContact] = useState({ name: "" });
  const [stripeElement, setStripeElement] = useState({
    isComplete: false,
    errorMessage: "",
  });
  const [errors, setErrors] = useState({ name: "", creditCard: "" });
  const [isCreatingPaymentMethod, setIsCreatingPaymentMethod] = useState(false);

  // mutations
  const [createWallet] = useMutation(CREATE_WALLET_MUTATION, {
    onError(error) {
      setIsCreatingPaymentMethod(false);
      const errorMessage = getErrorMessage(error) || "Error creating payment";

      snackbar.error(errorMessage);
      console.log(error);
    },
  });
  const [createPaymentMethod] = useMutation(CREATE_PAYMENT_METHOD_MUTATION, {
    refetchQueries: [{ query: LOAD_ME_QUERY }],
    onError(error) {
      setIsCreatingPaymentMethod(false);
      const errorMessage = getErrorMessage(error) || "Error creating payment";

      snackbar.error(errorMessage);
      console.log(error);
    },
  });

  // Get a reference to a mounted CardElement. Elements knows how
  // to find your CardElement because there can only ever be one of
  // each type of element.
  const cardElement = stripeElements?.getElement(CardElement);

  // helpers
  const createStripeWalletCard = async (): Promise<{
    setupIntent: SetupIntent;
  }> =>
    // creates stripe wallet and card.
    {
      const { data: walletData } = await createWallet({
        variables: {
          input: pickBy({ contactId, farmAffiliateId, companyId }),
        },
      });

      if (!walletData) {
        return;
      }

      const data = {
        createWallet: { clientSecret: walletData.createWallet.clientSecret },
      };

      const confirmCardSetupResult = await stripe.confirmCardSetup(
        data.createWallet.clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: `${billingContact.name}`,
            },
          },
        }
      );

      if (confirmCardSetupResult.error) {
        console.error(confirmCardSetupResult.error);
        snackbar.error(confirmCardSetupResult.error.message);
        return;
      }

      return {
        setupIntent: confirmCardSetupResult.setupIntent,
      };
    };

  const createStripePaymentMethod = async () => {
    if (!stripe || !stripeElements) {
      // if stripe elements fail to load, prevent form submission.
      snackbar.error("error loading payment elements");
    }

    let stripePaymentMethodId;
    let setupIntent;

    // create stripe wallet
    const createStripeWalletResponse = await createStripeWalletCard();
    setupIntent = createStripeWalletResponse?.setupIntent;
    stripePaymentMethodId = setupIntent?.payment_method;

    if (!stripePaymentMethodId) {
      setIsCreatingPaymentMethod(false);
      snackbar.error("Error creating charge");
      return;
    }

    // create stripe payment method
    const {
      paymentMethod: stripePaymentMethod,
      error: stripePaymentMethodError,
    } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: billingContact.name,
      },
    });

    if (stripePaymentMethodError) {
      console.error(stripePaymentMethodError);
      snackbar.error(stripePaymentMethodError.message);
      return;
    }

    // save stripe payment method
    const { data: paymentMethodData } = await createPaymentMethod({
      variables: {
        input: {
          stripePaymentMethod,
          ...pickBy({ contactId, farmAffiliateId, companyId }),
        },
      },
    });

    const { id } = paymentMethodData.createPaymentMethod.paymentMethod;

    if (companyId) {
      track("company_creditCardAdded", {
        company_id: fromGlobalId(companyId).id,
      });
    }

    setIsCreatingPaymentMethod(false);
    onCreatePayment(id);
  };

  // event handlers
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    let name = event.target.name;
    let value = event.target.value;

    setBillingContact({
      ...billingContact,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleCreditCardChange = (e: StripeCardElementChangeEvent) => {
    setStripeElement({
      isComplete: e.complete,
      errorMessage: e.error?.message || "",
    });

    setErrors({
      ...errors,
      creditCard: "",
    });
  };

  const handleUnauthenticatedPay = async () => {
    let errors = [];

    if (!billingContact.name) {
      errors = [...errors, { name: "Please enter cardholder name" }];
    }
    if (!stripeElement.isComplete) {
      errors = [...errors, { creditCard: "Please enter a credit card" }];
    }

    if (errors.length > 0) {
      setErrors(errors.reduce((acc, value) => ({ ...acc, ...value }), {}));

      return;
    }

    setIsCreatingPaymentMethod(true);
    await createStripePaymentMethod();
  };

  return (
    <>
      <Box mb={2} display="flex" flexDirection="column">
        <MobileAndEmailAuthentication />
        <Box mb={2}>
          <Typography variant="h4">Credit Card Details</Typography>
        </Box>
        <TextField
          required
          fullWidth
          variant="outlined"
          label="Cardholder name"
          name="name"
          onChange={handleNameChange}
          error={!!errors.name}
          helperText={errors.name}
        />

        <Box my={1}>
          <MoovsStripeCardElement
            errorMessage={errors?.creditCard}
            onCreditCardChange={handleCreditCardChange}
          />
        </Box>
      </Box>
      <PayButton
        isDisabled={isCreatingPaymentMethod || isLoading}
        onClick={handleUnauthenticatedPay}
        totalDue={totalDue}
        isLoading={isCreatingPaymentMethod || isLoading}
      />
      <Box mt={2} display="flex" alignItems="center" justifyContent="center">
        <LockIcon size="small" />
        <Box ml={1.5}>
          <Typography variant="overline">Secure Checkout</Typography>
        </Box>
      </Box>
    </>
  );
}

export default UnauthenticatedChargeCustomerBlock;
