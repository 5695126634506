import sum from "lodash/sum";

import { useOperator } from "./useOperator";

type UseLuggageParams = {
  carryOnLuggage?: number;
  checkedLuggage?: number;
  oversizeLuggage?: number;
};

export const useLuggage = (params?: UseLuggageParams) => {
  const {
    carryOnLuggage = 0,
    checkedLuggage = 0,
    oversizeLuggage = 0,
  } = params || {};

  // hooks
  const {
    operator: { settings },
  } = useOperator();

  // derived state
  const hasLuggage = !!sum([carryOnLuggage, checkedLuggage, oversizeLuggage]);
  const isLuggageEnabled = settings?.luggageEnabled || hasLuggage;

  return { hasLuggage, isLuggageEnabled };
};
