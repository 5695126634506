/*
 * File: MapContainer.tsx
 * Description: This component acts as a container for map hooks
 * and prevents the "google is not defined" error behavior
 * auth: Ben W.
 */
import React, { useMemo, useRef } from "react";
import { useInitializeMap } from "../hooks/useInitializeMap";
import useActiveDriverIcon from "../hooks/useActiveDriverIcon";
import { useTrackedDriver } from "../hooks/useTrackedDriver";
import useAddTripRouteToMap from "../hooks/useAddTripRouteToMap";

function MapContainer(props) {
  const { trip } = props;

  const googleMapRef = useRef();
  const { map } = useInitializeMap({ googleMapRef });
  useAddTripRouteToMap({ map, trip });

  const trips = useMemo(() => {
    return [trip];
  }, [trip]);

  const { trackedDriver } = useTrackedDriver(trips);
  useActiveDriverIcon({
    map,
    trackedDrivers: trackedDriver,
  });

  return (
    <div
      style={{
        display: "block",
        width: "100%",
        height: "100%",
      }}
      id="map"
      ref={googleMapRef}
    />
  );
}

export default MapContainer;
