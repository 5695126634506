import { formatPhoneNumber } from "utils/phoneNumberFormatter/phoneNumberFormatter";
import * as yup from "yup";
import { InternalOptions } from "yup/lib/types";

export type CreateQuoteFormState = yup.InferType<
  typeof createQuoteFormErrorSchema
>;

export const createQuoteFormErrorSchema = yup.object({
  bookingContact: yup
    .object({
      firstName: yup.string().required("Please provide a valid First name"),
      lastName: yup.string().required("Please provide a valid Last name"),
      email: yup.string().required("Please provide valid email addresss"),
      phoneFields: yup
        .object({
          mobilePhone: yup
            .string()
            .required("Please provide valid phone number")
            .test(
              "is possible phone number",
              (value, { path, createError, options }) => {
                const { parent } = options as InternalOptions; // yup TS is off

                const isPossible = formatPhoneNumber(
                  value,
                  parent.phoneCountryCode,
                  parent.phoneCountryDialCode
                )?.isPossible;

                if (!isPossible) {
                  return createError({
                    path,
                    message: "Please enter a valid phone number.",
                  });
                }

                return true;
              }
            ),
          phoneCountryCode: yup.string(),
          phoneCountryDialCode: yup.string(),
          phoneCountryFormat: yup.string(),
          phoneCountryName: yup.string(),
        })
        .required("Please provide valid phone number"),
    })
    .when("$isCreatingBookingContact", {
      is: true,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
});
