import React from "react";

import MoovsToggleButtonGroup from "components/MoovsToggleButtonGroup";

type AddressAirportToggleProps = {
  value: "address" | "airport";
  onChange: (_: any, newType: "address" | "airport") => void;
};

function AddressAirportToggle(props: AddressAirportToggleProps) {
  const { value, onChange } = props;

  const toggleButtons = [
    {
      value: "address",
      ariaLabel: "address",
      label: "Address",
    },
    {
      value: "airport",
      ariaLabel: "airport",
      label: "Airport",
    },
  ];

  return (
    <MoovsToggleButtonGroup
      onChange={onChange}
      options={toggleButtons}
      value={value}
    />
  );
}

export default AddressAirportToggle;
