import React from "react";

export const refund100Icon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="10" fill="#28B299" />
    <path
      d="M16 6L7.75 14L4 10.3636"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const refund50Icon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="9" stroke="#EEE647" strokeWidth="2" />
  </svg>
);

export const refund25Icon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="9" stroke="#FFB301" strokeWidth="2" />
  </svg>
);

export const noRefundIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="10" fill="#960031" />
    <path
      d="M10 4V16M12.5 6.18182H8.75C8.28587 6.18182 7.84075 6.38295 7.51256 6.74098C7.18437 7.099 7 7.58459 7 8.09091C7 8.59723 7.18437 9.08282 7.51256 9.44084C7.84075 9.79886 8.28587 10 8.75 10H11.25C11.7141 10 12.1592 10.2011 12.4874 10.5592C12.8156 10.9172 13 11.4028 13 11.9091C13 12.4154 12.8156 12.901 12.4874 13.259C12.1592 13.617 11.7141 13.8182 11.25 13.8182H7"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
