import React from "react";

type PowerOutletsIconProps = {
  color?: string;
  size: "small";
};

const PowerOutletsIcon = (props: PowerOutletsIconProps) => {
  const { color } = props;

  // size === small
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.77794 6.66671V9.33337M10.2224 6.66671V9.33337M3.11127 1.33337H12.8891C13.8709 1.33337 14.6668 2.12931 14.6668 3.11115V12.8889C14.6668 13.8708 13.8709 14.6667 12.8891 14.6667H3.11127C2.12943 14.6667 1.3335 13.8708 1.3335 12.8889V3.11115C1.3335 2.12931 2.12943 1.33337 3.11127 1.33337ZM12.8891 8.00004C12.8891 10.7001 10.7002 12.8889 8.00016 12.8889C5.3001 12.8889 3.11127 10.7001 3.11127 8.00004C3.11127 5.29998 5.3001 3.11115 8.00016 3.11115C10.7002 3.11115 12.8891 5.29998 12.8891 8.00004Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PowerOutletsIcon;
