import React from "react";

type StarIconFilledProps = {
  fillColor?: string;
  borderColor?: string;
};

const StarIconFilled = (props: StarIconFilledProps) => {
  const { fillColor, borderColor } = props;

  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5486 11.7074C22.6672 11.9822 22.9379 12.1602 23.2372 12.1602H31.5466C31.5569 12.1602 31.5671 12.16 31.5773 12.1595C31.842 12.1487 32.1038 12.2202 32.3267 12.3645C32.5496 12.5088 32.723 12.719 32.8226 12.9665C32.9222 13.2141 32.943 13.4866 32.8822 13.7466C32.8214 14.0066 32.6819 14.241 32.4834 14.4179L32.4821 14.4191L25.2706 20.8738C25.0299 21.0893 24.9542 21.4348 25.0828 21.7312L29.0755 30.9332C29.1893 31.2092 29.2144 31.5141 29.1472 31.8053C29.0796 32.0981 28.9222 32.3619 28.6976 32.5597C28.473 32.7575 28.1924 32.8794 27.8955 32.9086C27.5987 32.9377 27.3 32.8728 27.0417 32.7226L27.0417 32.7226L27.0338 32.7181L17.3889 27.2679C17.1599 27.1385 16.8799 27.1385 16.6509 27.2679L7.006 32.7181L7.00598 32.7181L6.99816 32.7226C6.73977 32.8728 6.44113 32.9377 6.14427 32.9086C5.8474 32.8794 5.56686 32.7575 5.34224 32.5597L4.8466 33.1226L5.34224 32.5597C5.11759 32.3619 4.96019 32.0981 4.89262 31.8053C4.82543 31.5142 4.85052 31.2093 4.96423 30.9333L8.96063 21.7314C9.08972 21.4342 9.01331 21.0877 8.77119 20.8723L1.51575 14.418C1.5157 14.418 1.51565 14.4179 1.5156 14.4179C1.31749 14.2413 1.17829 14.0073 1.11737 13.7479C1.05643 13.4884 1.07691 13.2163 1.17591 12.969L0.479623 12.6903L1.17591 12.969C1.2749 12.7217 1.44746 12.5115 1.66963 12.3668C1.89178 12.2221 2.15282 12.1498 2.41711 12.1596C2.42638 12.16 2.43566 12.1602 2.44495 12.1602H10.7581C11.0573 12.1602 11.3278 11.9824 11.4465 11.7078L15.7108 1.84536C15.8365 1.61778 16.0195 1.42748 16.2416 1.29355C16.4712 1.15509 16.7338 1.08203 17.0014 1.08203C17.2689 1.08203 17.5316 1.15509 17.7611 1.29355C17.9831 1.42744 18.1662 1.61768 18.2918 1.84518L22.5486 11.7074Z"
        fill={fillColor || "#E5EEFF"}
        stroke={borderColor || "#739CFF"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StarIconFilled;
