import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";

import { useScreenSize } from "globals/hooks";
import VehicleDescription from "../VehicleDescriptionAndFeatures/VehicleDescription/VehicleDescription";
import VehicleFeatures from "../VehicleDescriptionAndFeatures/VehicleFeatures";
import { MinusIcon, PlusIcon } from "design-system/icons";
import { VehicleChildSeat, VehicleFeature } from "types";

type VehicleDescriptionAndFeaturesProps = {
  description: string;
  features: VehicleFeature[];
  hasTripConflict: boolean;
  activeChildSeats: VehicleChildSeat[];
};

function VehicleDescriptionAndFeatures(
  props: VehicleDescriptionAndFeaturesProps
) {
  const { features, description, hasTripConflict, activeChildSeats } = props;

  // state
  const [expand, setExpand] = useState(false);

  // hooks
  const { isMobileView } = useScreenSize();
  const theme = useTheme();

  // event handlers
  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };

  const colorTheme = theme.palette.primary.main;

  // mobile view
  if (isMobileView) {
    return (
      <>
        <Accordion
          expanded={expand}
          elevation={0}
          sx={{
            "&:before": {
              display: "none",
              padding: 0,
            },
          }}
        >
          <AccordionSummary sx={{ display: "none" }}></AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <Box>
              <VehicleDescription
                description={description}
                hasTripConflict={hasTripConflict}
              />
            </Box>
            <Box>
              <VehicleFeatures
                features={features}
                childSeats={activeChildSeats}
                hasTripConflict={hasTripConflict}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Box>
          <Button
            onClick={toggleAcordion}
            sx={{ color: colorTheme, pl: 0, my: 1 }}
          >
            <Typography fontWeight={500} fontSize="16px">
              {expand ? "Show Less" : "Show More"}
            </Typography>
            <Box ml={1} mb={-0.4}>
              {expand ? (
                <MinusIcon color={colorTheme} size="small" />
              ) : (
                <PlusIcon color={colorTheme} size="small" />
              )}
            </Box>
          </Button>
        </Box>
      </>
    );
  }

  // desktop view
  return (
    <Box display="flex" flexDirection="column">
      <Box mt={2} mb={1}>
        <VehicleDescription
          description={description}
          hasTripConflict={hasTripConflict}
        />
      </Box>
      <Box>
        <VehicleFeatures
          features={features}
          childSeats={activeChildSeats}
          hasTripConflict={hasTripConflict}
        />
      </Box>
    </Box>
  );
}

export default VehicleDescriptionAndFeatures;
