import React from "react";
import { useFormContext } from "react-hook-form";

import { Box, IconButton, Typography, useTheme } from "@mui/material";

import { granite, grayDark } from "../../../../../../design-system/colors";
import { currency } from "../../../../../../utils/helpers";
import { CrossIcon } from "design-system/icons";

type PriceItemProps = {
  label: string;
  value: number;
  isNeg?: boolean;
  removeField?: string;
};

function PriceOverviewPriceItem(props: PriceItemProps) {
  const { label, value, isNeg, removeField } = props;

  // hooks
  const theme = useTheme();
  const { setValue } = useFormContext();

  // event handlers
  const handleRemoveField = () => {
    setValue(removeField, null);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box display="flex" alignItems="center">
        <Typography variant="body2" style={{ color: grayDark }}>
          {label}
        </Typography>
        {removeField ? (
          <IconButton onClick={handleRemoveField} sx={{ ml: 0.5 }}>
            <CrossIcon size="small" color={theme.palette.primary.main} />
          </IconButton>
        ) : null}
      </Box>
      <Typography variant="body2" style={{ color: granite }}>
        {isNeg && "- "}
        {currency(value)}
      </Typography>
    </Box>
  );
}

export default PriceOverviewPriceItem;
