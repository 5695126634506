import React, { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
  useTheme,
} from "@mui/material";

import { LOAD_REQUEST_QUERY } from "../../globals/graphql";
import GQLQueryStatusIndicator from "../../components/GQLQueryStatusIndicator";
import { alabaster, grayLight } from "../../design-system/colors";
import { FarmRelationshipEnum, Request } from "../../types";
import PriceSummaryHeader from "../../components/price-summary/PriceSummaryHeader";
import PricingBlock from "../../components/price-summary/PricingBlock";
import PaymentsList from "../../components/price-summary/PaymentsList.desktop";
import PaymentsListMobile from "../../components/price-summary/PaymentsList.mobile";
import { currency } from "../../utils/helpers";
import { useOperator, useScreenSize } from "../../globals/hooks";
import first from "lodash/first";
import { fromGlobalId } from "../../utils/auth/helpers";

const FURBUS_OPERATOR_ID = "97f813e8-964a-11eb-8480-9f7b3898ae68";
const GOGO_ATLANTA_OPERATOR_ID = "6e152132-f939-11ea-bdc5-571760700adb";
const GOGO_MIAMI_OPERATOR_ID = "18d53ac8-ff5f-11ea-a0b1-2bd643683115";
const GOGO_ORLANDO_OPERATOR_ID = "bbde1e92-4965-11eb-91b7-ff5b825dbd89";

const excludedOperators = [
  FURBUS_OPERATOR_ID,
  GOGO_ATLANTA_OPERATOR_ID,
  GOGO_MIAMI_OPERATOR_ID,
  GOGO_ORLANDO_OPERATOR_ID,
];

function PriceSummaryPage() {
  // hooks
  const history = useHistory();
  const { operator } = useOperator();
  const { requestId } = useParams<{ requestId: string }>();
  const { isMobileView } = useScreenSize();
  const theme = useTheme();

  // queries
  const {
    data: requestData,
    error: requestError,
    refetch: requestRefetch,
    loading: requestLoading,
  } = useQuery(LOAD_REQUEST_QUERY, {
    variables: {
      id: requestId,
    },
  });

  // event handlers
  const handleRedirectToPay = () => {
    history.push(`/${operator.nameSlug}/price-summary/${requestId}/pay`);
  };

  // derived state
  const request: Request = requestData?.node;

  const requestTotalAmount = request?.totalAmount;
  const requestAmountDue = request?.amountDue;
  const requestAmountPaid = useMemo(
    () => requestTotalAmount - requestAmountDue,
    [requestTotalAmount, requestAmountDue]
  );

  // farmee is viewing price summary
  const farmedRouteOfFarmee = first(
    request?.trips.find(
      (trip) =>
        first(trip.routes).isFarmedRoute &&
        first(trip.routes).farmRelationship === FarmRelationshipEnum.Farmee
    )?.routes
  );

  const shouldRenderPayNow =
    requestAmountDue > 0 &&
    !farmedRouteOfFarmee &&
    !excludedOperators.includes(fromGlobalId(operator.id).id);

  // use farmor as booking contact when farmee is viewing - otherwise, use customer contact info
  const bookingContact = !!farmedRouteOfFarmee
    ? {
        name: farmedRouteOfFarmee.farmAffiliate.operatorName,
        email: farmedRouteOfFarmee.farmAffiliate.operatorEmail,
        mobilePhone: farmedRouteOfFarmee.farmAffiliate.operatorPhone,
      }
    : {
        name: `${request?.bookingContact?.firstName} ${request?.bookingContact?.lastName}`,
        email: request?.bookingContact?.email,
        mobilePhone: request?.bookingContact?.mobilePhone,
      };

  const companyInfo = !!farmedRouteOfFarmee
    ? // only farmor can see company info
      null
    : request?.company
    ? {
        name: request.company.name,
        email: request.company.email,
        phoneNumber: request.company.phoneNumber,
      }
    : null;

  return (
    <>
      {requestLoading && (
        <Box
          width="100%"
          height="90vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={40} thickness={2} />
        </Box>
      )}
      {requestError && !requestLoading && (
        <Box
          width="100%"
          height="90vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <GQLQueryStatusIndicator
            name="Request"
            data={requestData}
            error={requestError}
            refetch={requestRefetch}
          />
        </Box>
      )}

      {request && (
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          mb={15}
          data-testid="price-summary-page"
        >
          <Box display="flex" sx={{ width: "100%" }}>
            <Container
              sx={{
                padding: 0,
                backgroundColor: alabaster,
              }}
              maxWidth="lg"
            >
              <Container maxWidth="md">
                <Box my={7}>
                  <PriceSummaryHeader
                    bookingContact={bookingContact}
                    companyInfo={companyInfo}
                    orderNumber={request.orderNumber}
                    createdAt={request.createdAt}
                    updatedAt={request.updatedAt}
                    requestId={request.id}
                  />
                </Box>
              </Container>
            </Container>
          </Box>
          <Container maxWidth="md">
            {/* Pricing */}
            <Box
              display="flex"
              flex="1"
              flexDirection="column"
              mt={isMobileView ? 3 : 6}
            >
              <Box
                mb={3}
                display="flex"
                flexDirection={isMobileView ? "column-reverse" : "row"}
                alignItems={isMobileView ? "start" : "center"}
                justifyContent="space-between"
              >
                <Typography variant="h3">Pricing</Typography>
                {shouldRenderPayNow && (
                  <Button
                    fullWidth={isMobileView}
                    onClick={handleRedirectToPay}
                    variant="contained"
                    color="primary"
                    {...(isMobileView && {
                      style: { marginBottom: theme.spacing(3) },
                    })}
                  >
                    Pay Now
                  </Button>
                )}
              </Box>
              {request.trips.map((trip) => (
                <Box key={trip.id} mb={3}>
                  <PricingBlock
                    trip={trip}
                    requestOrderNo={request.orderNumber}
                  />
                </Box>
              ))}
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Box
                sx={{
                  borderRadius: 1,
                  minWidth: 224,
                  height: 48,
                  padding: "0 16px",
                }}
                display="flex"
                justifyContent="center"
                bgcolor={grayLight}
              >
                <Box
                  alignSelf="center"
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  justifyContent="space-between"
                >
                  <Box mr={4}>
                    <Typography
                      variant="body1"
                      sx={
                        isMobileView
                          ? { fontSize: "14px" }
                          : { fontWeight: "bold" }
                      }
                    >
                      Total Amount
                    </Typography>
                  </Box>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {currency(requestTotalAmount)}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* Payments */}
            {isMobileView ? (
              <PaymentsListMobile
                subPayments={request?.combinedSubPayments}
                refunds={request?.combinedRefunds}
              />
            ) : (
              <PaymentsList
                subPayments={request?.combinedSubPayments}
                refunds={request?.combinedRefunds}
              />
            )}

            <Box display="flex" justifyContent="flex-end" mb={1}>
              <Box
                sx={{
                  borderRadius: 1,
                  minWidth: 224,
                  height: 48,
                  padding: "0 16px",
                }}
                display="flex"
              >
                <Box
                  alignSelf="center"
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  justifyContent="space-between"
                >
                  <Box mr={4}>
                    <Typography
                      variant="body1"
                      sx={
                        isMobileView
                          ? { fontSize: "14px" }
                          : { fontWeight: "bold" }
                      }
                    >
                      Amount Paid
                    </Typography>
                  </Box>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {currency(requestAmountPaid)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Box
                sx={{
                  borderRadius: 1,
                  minWidth: 224,
                  height: 48,
                  padding: "0 16px",
                }}
                display="flex"
                bgcolor={grayLight}
              >
                <Box
                  alignSelf="center"
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  justifyContent="space-between"
                >
                  <Box mr={4}>
                    <Typography
                      variant="body1"
                      sx={
                        isMobileView
                          ? { fontSize: "14px" }
                          : { fontWeight: "bold" }
                      }
                    >
                      Amount Due
                    </Typography>
                  </Box>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {currency(requestAmountDue)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
}

export default PriceSummaryPage;
