import React from "react";

type ChildSeatRearIconProps = {
  color?: string;
  size?: "small";
};

const ChildSeatRearIcon = (props: ChildSeatRearIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.24152 2.976C6.11901 2.68647 5.91398 2.43949 5.65205 2.2659C5.39012 2.09232 5.0829 1.99984 4.76876 2H3.46331C3.35421 2.0008 3.24642 2.02386 3.14652 2.06778C3.04663 2.11169 2.95673 2.17553 2.88232 2.2554C2.80791 2.33527 2.75056 2.42949 2.71376 2.5323C2.67697 2.6351 2.6615 2.74434 2.66831 2.85333L3.30772 12.5067C3.33479 12.9123 3.51509 13.2923 3.81198 13.5697C4.10888 13.847 4.50011 14.0009 4.90623 14H12.1779C12.529 14.0007 12.8706 13.8856 13.1499 13.6726C13.4292 13.4596 13.6306 13.1604 13.7231 12.8213L13.9437 12.0101C14.0143 11.7512 14.0187 11.4787 13.9565 11.2177C13.8942 10.9566 13.7674 10.7155 13.5877 10.5163C13.408 10.3172 13.1811 10.1665 12.9279 10.0782C12.6747 9.98979 12.4034 9.96658 12.139 10.0107L7.40737 10.8C7.70578 8.13488 7.30392 5.43796 6.24152 2.976Z"
          stroke={color || "#565656"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.36217 4.464C9.17839 4.0297 8.87085 3.65923 8.47796 3.39886C8.08506 3.13848 7.62423 2.99976 7.15302 3H5.19484C5.03119 3.0012 4.8695 3.0358 4.71966 3.10167C4.56982 3.16754 4.43497 3.2633 4.32336 3.3831C4.21175 3.50291 4.12571 3.64423 4.07052 3.79844C4.01533 3.95265 3.99213 4.11651 4.00235 4.28L4.96146 18.76C5.00207 19.3684 5.27251 19.9385 5.71785 20.3545C6.16319 20.7706 6.75005 21.0014 7.35923 21H18.2667C18.7934 21.001 19.3058 20.8284 19.7247 20.5089C20.1437 20.1893 20.4458 19.7406 20.5845 19.232L20.9154 18.0152C21.0213 17.6268 21.0279 17.2181 20.9346 16.8265C20.8413 16.435 20.651 16.0732 20.3814 15.7745C20.1118 15.4758 19.7715 15.2498 19.3917 15.1172C19.012 14.9847 18.605 14.9499 18.2083 15.016L11.1109 16.2C11.5586 12.2023 10.9558 8.15695 9.36217 4.464Z"
        stroke={color || "#565656"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChildSeatRearIcon;
