import React, { useEffect, useMemo } from "react";
import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form";
import sum from "lodash/sum";
import first from "lodash/first";
import isNil from "lodash/isNil";

import { Card, Box, Divider } from "@mui/material";

import {
  PriceOverviewBlock,
  DriverGratuityBlock,
  PaymentMethodBlock,
  TermsAndConditionsBlock,
  SubmitButton,
  YourCardWillNotBeChargedBlock,
} from "components/confirm/form/components";
import { useAuth, useOperator, useScreenSize } from "globals/hooks";
import { ConfirmReservationFormState } from "pages/order/components/ConfirmReservationForm/form/schemaValidation";
import { useIsGratuityEnabled } from "components/confirm/form/hooks/useIsGratuityEnabled";
import { getOrderPricing } from "globals/hooks/getOrderPricing";
import { Request } from "types";
import { CustomerPricingType } from "globals/hooks/utils";
import { currencyConverter as cc } from "utils/currency";
import MobileAndEmailAuthentication from "components/auth/ExistingMobileEmailAuthentication/MobileAndEmailAuthentication";
import RequestStatusFixedFooter from "components/confirm/RequestStatusFixedFooter";
import ConsentSignatureSubmitButton from "components/confirm/form/components/ConsentSignatureSubmitButton";
import MeetGreetBlock from "components/confirm/form/components/MeetGreetBlock";
import { useDisplayMeetGreet } from "components/confirm/form/hooks/useDisplayMeetGreet";
import { useAutoChargePayment } from "pages/new/hooks";
import SmsConsentMessage from "components/sms-consent/SmsConsentMessage";
import { useDisplayAdditionalItems } from "components/confirm/form/hooks";
import AdditionalItemsBlock from "components/confirm/form/components/AdditionalItemsBlock";

type ConfirmReservationFormFieldsProps = {
  control: Control<ConfirmReservationFormState>;
  watch: UseFormWatch<ConfirmReservationFormState>;
  onConsentSignatureDialogOpen: () => void;
  formId: string;
  isSubmitting: boolean;
  request: Request;
  activeTripIndex: number;
  hasTripConflict: boolean;
  setValue: UseFormSetValue<ConfirmReservationFormState>;
  validToOpenSignatureDialog: boolean;
};

function ConfirmReservationFormFields(
  props: ConfirmReservationFormFieldsProps
) {
  const {
    control,
    watch,
    onConsentSignatureDialogOpen,
    formId,
    isSubmitting,
    request,
    activeTripIndex,
    hasTripConflict,
    setValue,
    validToOpenSignatureDialog,
  } = props;

  // hooks
  const {
    operator: {
      enableCreditCardWhenBooking,
      settings: { requireSignatureWhenBooking },
    },
  } = useOperator();
  const { authStage } = useAuth();
  const { isMobileView } = useScreenSize();

  const { displayMeetGreet, meetGreetAmt } = useDisplayMeetGreet({
    isAirportPickUpStop:
      !!request.trips[activeTripIndex].stops[0].airport?.icaoCode ||
      !!request.trips[activeTripIndex].returnTrip?.stops[0]?.airport?.icaoCode,
    mode: "update",
    operatorSetMeetGreet:
      !isNil(request.trips[activeTripIndex].routes[0].pricing.meetGreetAmt) ||
      !isNil(
        request.trips[activeTripIndex].returnTrip?.routes[0]?.pricing
          ?.meetGreetAmt
      ),
  });

  const isGratuityEnabled = useIsGratuityEnabled({
    trips: request.trips,
    mode: "update",
  });

  const { displayAdditionalItems, additionItems } = useDisplayAdditionalItems({
    mode: "update",
    vehicle: request.trips[activeTripIndex].routes[0].vehicle,
    tripPricing: request.trips[activeTripIndex].routes[0].pricing,
  });
  // derived state
  const baseRateAmt = useMemo(() => {
    const allTripBaseRate = request.trips.map((trip) => {
      const returnTripPricing = trip.returnTrip?.routes
        ? cc.toCents(first(trip.returnTrip.routes).pricing.baseRateAmt)
        : 0;

      return (
        cc.toCents(first(trip.routes).pricing.baseRateAmt) + returnTripPricing
      );
    });

    return cc.toDollars(sum(allTripBaseRate));
  }, [request.trips]);

  const orderPricing = getOrderPricing({
    request,
    userSelectedDriverGratuityPercent: watch("driverGratuityPctCustomerInput"),
    shouldRenderDriverGratuity: isGratuityEnabled,
    pickUpVariantSelected: watch("pickUpVariantSelected"),
    pricingLayoutMeetGreet: meetGreetAmt,
    userAppliedPromoCode: watch("promoCodeCustomerInput"),
    additonalItemsByTrip: watch(`additonalItemsByTrip`),
  });

  const pricings = orderPricing.map(
    (pricing, arrayIndex): CustomerPricingType => ({
      tripIndex: arrayIndex + 1,
      outboundTripPricing: pricing.pricing,
      outboundTripTotalAmt: pricing.totalAmt,
      orderTotalAmt:
        pricing.totalAmt + (pricing.returnTripPricing?.totalAmt || 0),
      returnTripPricing: pricing.returnTripPricing?.pricing,
      returnTripTotalAmt: pricing.returnTripPricing?.totalAmt,
    })
  );

  const orderTotalAmt = pricings.reduce(
    (orderTotalAmt, pricing) => pricing.orderTotalAmt + orderTotalAmt,
    0
  );

  const pickUpDateTime = request.trips[0].stops[0].dateTime;

  const { autoPaymentAmt, autoChargeButtonText, autoPaymentType } =
    useAutoChargePayment({ pricings });

  // effects
  useEffect(() => {
    setValue("autoPaymentAmt", autoPaymentAmt);
    setValue("autoPaymentType", autoPaymentType);
  }, [autoPaymentAmt, setValue, autoPaymentType]);

  return (
    <Card variant="outlined">
      <Box display="flex" flexDirection="column" m={3}>
        {/* Price Overview */}
        <PriceOverviewBlock
          pricings={pricings}
          orderTotalAmt={orderTotalAmt}
          activeTripIndex={activeTripIndex}
          pickUpDateTime={pickUpDateTime}
        />
        {/* Meet & Greet */}
        {displayMeetGreet && (
          <MeetGreetBlock control={control} meetGreetAmt={meetGreetAmt} />
        )}
        {/* Driver Gratuity */}
        {isGratuityEnabled && (
          <DriverGratuityBlock control={control} baseRateAmt={baseRateAmt} />
        )}
        {/* Additional Items */}
        {displayAdditionalItems && (
          <AdditionalItemsBlock
            {...additionItems}
            childSeatsFormPath={`additonalItemsByTrip.${activeTripIndex}.childSeats`}
          />
        )}
        <Divider sx={{ mb: 3 }} />
        {/* Booking Contact Phone Number (for auth) */}
        {authStage === "rejected" && <MobileAndEmailAuthentication />}
        {/* Payment Method */}
        {enableCreditCardWhenBooking && (
          <PaymentMethodBlock
            control={control}
            trackingFrom="quoteRequest"
            request={request}
            setValue={setValue}
          />
        )}
        {/* Terms and Conditions / Cancellation Policy */}
        <Box mb={3}>
          <TermsAndConditionsBlock
            trips={request.trips}
            isSubmitting={false}
            control={control}
          />
        </Box>
        {/* Submit Button */}
        {isMobileView ? (
          <RequestStatusFixedFooter
            variant="review-and-reserve"
            formId={formId}
            isSubmitting={isSubmitting}
            requireSignatureWhenBooking={requireSignatureWhenBooking}
            onConsentSignatureDialogOpen={onConsentSignatureDialogOpen}
            disabled={hasTripConflict}
            autoChargeButtonText={autoChargeButtonText}
            validToOpenSignatureDialog={validToOpenSignatureDialog}
          />
        ) : requireSignatureWhenBooking ? (
          <ConsentSignatureSubmitButton
            isSubmitting={isSubmitting}
            onConsentSignatureDialogOpen={onConsentSignatureDialogOpen}
            disabled={hasTripConflict}
            autoChargeButtonText={autoChargeButtonText}
            validToOpenSignatureDialog={validToOpenSignatureDialog}
          />
        ) : (
          <SubmitButton
            isSubmitting={isSubmitting}
            formId={formId}
            disabled={hasTripConflict}
            autoChargeButtonText={autoChargeButtonText}
          />
        )}

        {/* SMS Consent Message */}
        <SmsConsentMessage
          actionButtonName={autoChargeButtonText ? "Pay" : "Reserve"}
          typographySx={{ mt: isMobileView ? 0 : 3 }}
        />

        {/* Your Card Will Not Be Charged */}
        {!autoPaymentAmt && enableCreditCardWhenBooking ? (
          <Box mt={2}>
            <YourCardWillNotBeChargedBlock />
          </Box>
        ) : null}
      </Box>
    </Card>
  );
}

export default ConfirmReservationFormFields;
