import React from "react";

import { Box, Typography } from "@mui/material";
import { useCancellationPolicies } from "../../hooks/useCancellationPolicies";
import { deriveStrictestCancellationPolicy } from "pages/new/utils/deriveStrictestCancellationPolicy";

type VehicleCancellationPolicyProps = {
  selectedVehicleIds: string[];
};

function VehicleCancellationPolicy(props: VehicleCancellationPolicyProps) {
  const { selectedVehicleIds } = props;

  // hooks
  const { cancellationPolicies: cancellationPoliciesData } =
    useCancellationPolicies({ trips: null });

  // derived state
  const cancellationPolicy = deriveStrictestCancellationPolicy({
    cancellationPoliciesData,
    selectedVehicleIds,
  });

  // if no cancellation policy, return empty
  if (!cancellationPolicy.length) {
    return null;
  }

  return (
    <Box>
      <Typography variant="h4" mb={2}>
        Cancellation Policy
      </Typography>
      {cancellationPolicy.map((policy) => {
        return <Typography variant="body2">{policy}</Typography>;
      })}
    </Box>
  );
}

export default VehicleCancellationPolicy;
