import { TemporaryPassenger } from "types";
import { AdditionalTripInfoState } from "./schemaValidation";

export const getAdditionalTripInfoDefaultValues = (initialState: {
  totalGroupSize: number;
  note?: string;
  temporaryPassenger?: TemporaryPassenger;
}): AdditionalTripInfoState => ({
  totalGroupSize: null,
  note: "",
  ...initialState,
  // passenger contact (i.e. temporary passenger)
  temporaryPassenger: {
    name: "",
    ...initialState.temporaryPassenger,
    // phone fields pulled from temporary passenger to work with RHF international phone input
    phoneFields: {
      mobilePhone: "",
      mobilePhoneInternational: "",
      phoneCountryCode: "",
      phoneCountryDialCode: "",
      phoneCountryName: "",
      phoneCountryFormat: "",
      ...initialState.temporaryPassenger,
    },
  },
});
