import React from "react";
import moment from "moment";

import { useScreenSize } from "globals/hooks";
import { convertMinutesToHoursMinutes } from "utils/helpers";
import { RequestSummaryDesktop, RequestSummaryMobile } from "./components";

export type RequestSummaryProps = {
  pickUpDateTime: string;
  pickUpAddress: string;
  passengerCount: number;
  onEditClick: () => void;
  dropOffAddress?: string;
  returnDateTime?: string;
  tripDuration?: number;
};

function RequestSummary(props: RequestSummaryProps) {
  const {
    pickUpDateTime,
    pickUpAddress,
    dropOffAddress,
    passengerCount,
    returnDateTime,
    tripDuration,
    onEditClick,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  const requestSummaryItems = [
    {
      label: "pick-up date & time",
      value: moment(pickUpDateTime).utc().format("dddd, MMM Do, YYYY h:mm A"),
    },
    {
      label: "pick-up address",
      value: pickUpAddress,
    },
    {
      label: "drop-off address",
      value: dropOffAddress || null,
    },
    {
      label: "return date & time",
      value: returnDateTime
        ? moment(returnDateTime).utc().format("dddd, MMM Do, YYYY h:mm A")
        : null,
    },
    {
      label: "duration",
      value: tripDuration
        ? convertMinutesToHoursMinutes(tripDuration, true)
        : null,
    },
    {
      label: "passenger count",
      value: passengerCount,
    },
  ];

  return (
    <>
      {isMobileView ? (
        <RequestSummaryMobile
          requestSummaryItems={requestSummaryItems}
          onEditClick={onEditClick}
        />
      ) : (
        <RequestSummaryDesktop
          requestSummaryItems={requestSummaryItems}
          onEditClick={onEditClick}
        />
      )}
    </>
  );
}

export default RequestSummary;
