import React from "react";
import { Control } from "react-hook-form";

import { Box, Typography } from "@mui/material";

import { RHFMoovsIncrementer } from "components/react-hook-form/RHFMoovsIncrementer";

type LuggageCountBlockProps = {
  control?: Control<any>;
};

function LuggageCountBlock(props: LuggageCountBlockProps) {
  const { control } = props;

  return (
    <Box display="flex" flexDirection="column" mt={1}>
      <Typography variant="h4">Luggage Count</Typography>
      {/* Carry On */}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body2">Carry-On</Typography>
        <RHFMoovsIncrementer
          name="route.carryOnLuggage"
          control={control}
          limits={[MIN_GROUP_SIZE, MAX_GROUP_SIZE]}
          ButtonProps={{ variant: "text" }}
        />
      </Box>

      {/* Checked */}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body2">Checked</Typography>
        <RHFMoovsIncrementer
          name="route.checkedLuggage"
          control={control}
          limits={[MIN_GROUP_SIZE, MAX_GROUP_SIZE]}
          ButtonProps={{ variant: "text" }}
        />
      </Box>

      {/* Oversize */}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body2">Oversize</Typography>
        <RHFMoovsIncrementer
          name="route.oversizeLuggage"
          control={control}
          limits={[MIN_GROUP_SIZE, MAX_GROUP_SIZE]}
          ButtonProps={{ variant: "text" }}
        />
      </Box>
    </Box>
  );
}

export default LuggageCountBlock;

// constants
const MAX_GROUP_SIZE = 99;
const MIN_GROUP_SIZE = 1;
