import React from "react";

type HelpIconProps = {
  color?: string;
  size?: "small" | "large";
};

const HelpIcon = (props: HelpIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.963 5.9C6.12757 5.43217 6.45241 5.03767 6.87997 4.78639C7.30753 4.53511 7.81023 4.44326 8.29902 4.5271C8.78782 4.61094 9.23117 4.86507 9.55055 5.24447C9.86994 5.62387 10.0447 6.10407 10.044 6.6C10.044 8 7.944 8.7 7.944 8.7M8 11.5H8.007M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
          stroke={color || "#B3B3B3"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  if (size === "large") {
    return (
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.1502 13.0007C13.542 11.8868 14.3154 10.9475 15.3334 10.3492C16.3514 9.75092 17.5483 9.53221 18.7121 9.73184C19.8759 9.93146 20.9315 10.5365 21.692 11.4399C22.4524 12.3432 22.8686 13.4865 22.8668 14.6673C22.8668 18.0007 17.8668 19.6673 17.8668 19.6673M18.0002 26.334H18.0168M34.6668 18.0007C34.6668 27.2054 27.2049 34.6673 18.0002 34.6673C8.79542 34.6673 1.3335 27.2054 1.3335 18.0007C1.3335 8.7959 8.79542 1.33398 18.0002 1.33398C27.2049 1.33398 34.6668 8.7959 34.6668 18.0007Z"
          stroke={color || "#B3B3B3"}
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.09 9C9.3251 8.33167 9.78915 7.76811 10.4 7.40913C11.0108 7.05016 11.7289 6.91894 12.4272 7.03871C13.1255 7.15849 13.7588 7.52152 14.2151 8.06353C14.6713 8.60553 14.9211 9.29152 14.92 10C14.92 12 11.92 13 11.92 13M12 17H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HelpIcon;
