import React from "react";

type LuggageIconProps = {
  color?: string;
  size: "small";
};

const LuggageIcon = (props: LuggageIconProps) => {
  const { color } = props;

  // size === small
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6666 12.3333V2.85181C10.6666 2.53748 10.5261 2.23602 10.2761 2.01376C10.026 1.79149 9.68687 1.66663 9.33325 1.66663H6.66659C6.31296 1.66663 5.97383 1.79149 5.72378 2.01376C5.47373 2.23602 5.33325 2.53748 5.33325 2.85181V12.3333M2.66659 4.037H13.3333C14.0696 4.037 14.6666 4.56762 14.6666 5.22218V11.1481C14.6666 11.8027 14.0696 12.3333 13.3333 12.3333H2.66659C1.93021 12.3333 1.33325 11.8027 1.33325 11.1481V5.22218C1.33325 4.56762 1.93021 4.037 2.66659 4.037Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LuggageIcon;
