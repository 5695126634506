import React from "react";
import size from "lodash/size";

import { Box, Stack, Typography } from "@mui/material";

import { VehicleFeature } from "types";
import { FeatureItem } from ".";

type MultimediaFeaturesProps = {
  features: VehicleFeature[];
  hasTripConflict: boolean;
};

function MultimediaFeatures(props: MultimediaFeaturesProps) {
  const { features, hasTripConflict } = props;

  return (
    <Box>
      <Typography variant="overline">Multimedia</Typography>

      <Box display="flex" flexDirection="row" mt={1.5}>
        {!size(features) && "-"}

        {/* row 1 */}
        <Stack spacing={1.5} justifyContent="flex-start" mr={0.5}>
          {features.slice(0, 5).map(({ id, name }) => (
            <Box key={id}>
              <FeatureItem name={name} hasTripConflict={hasTripConflict} />
            </Box>
          ))}
        </Stack>

        {/* row 2 */}
        <Stack spacing={1.5} justifyContent="flex-start">
          {features.slice(5).map(({ id, name }) => (
            <Box key={id}>
              <FeatureItem name={name} hasTripConflict={hasTripConflict} />
            </Box>
          ))}
        </Stack>
      </Box>
    </Box>
  );
}

export default MultimediaFeatures;
