/**
 * fetches google direction results from form stops and saves to form
 *
 * potential changes
 * - current implementation is tied to the form
 *  - if we wanted to allow more generic usage of the query, replace 'variant' param with array of stops and return query data instead of setting value in onCompleted
 */
import { useQuery } from "@apollo/client";
import { CreateStopState } from "pages/new/context/initialState";
import { useMemo } from "react";
import { LOAD_GOOGLE_DIRECTIONS_QUERY } from "../googleDirections.graphql";

type UseGoogleDirectionsQueryParams = {
  stops: CreateStopState[];
  skip?: boolean;
};

function useGoogleDirectionsQuery(
  params: UseGoogleDirectionsQueryParams
): [number, any, boolean] {
  const { stops, skip = false } = params;

  const shouldSkipQuery =
    skip ||
    !stops ||
    stops.length === 0 ||
    stops.some(({ location, airport }) => !location && !airport) ||
    !stops[0].dateTime;

  const shapedStopsForQuery = shouldSkipQuery
    ? null
    : stops.map((stop) => {
        return {
          dateTime: stop.dateTime,
          airportIcao: stop.airport?.icaoCode,
          coordinates: stop.airport?.coordinates,
          location: stop.airport
            ? stop.airport.airportName
            : stop.location || "",
        };
      });

  // queries
  const { data, loading } = useQuery(LOAD_GOOGLE_DIRECTIONS_QUERY, {
    variables: {
      stops: shapedStopsForQuery,
    },
    skip: shouldSkipQuery,
  });

  const [estimatedDuration, directionsMap] = useMemo(() => {
    return [
      data?.googleDirections?.estimatedDuration,
      data?.googleDirections?.googleDirectionsResult,
    ];
  }, [
    data?.googleDirections?.estimatedDuration,
    data?.googleDirections?.googleDirectionsResult,
  ]);

  return [estimatedDuration, directionsMap, loading];
}

export { useGoogleDirectionsQuery };
