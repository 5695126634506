/**
 * @file useCurrentUser
 * Hook that abstracts meQuery calls and just returns current user.
 */

import { useQuery } from "@apollo/client";

import { useSnackbar } from "./useSnackbar";
import { useAuth } from "./useAuth";
import { LOAD_ME_QUERY } from "../graphql";
import { Contact } from "types";
import { getIsNetflixLogin } from "../../utils/auth/isNetflixLogin";

export const defaultPhoneNumber = "0000000000";
export const defaultName = "user";

function useCurrentUser(): Contact {
  const { authStage } = useAuth();
  const snackbar = useSnackbar();
  const isNetflixLogin = getIsNetflixLogin();

  // queries
  const { data, error } = useQuery(LOAD_ME_QUERY, {
    skip: authStage !== "authenticated",
  });

  if (data && data.loadMe && isNetflixLogin) {
    const contact = { ...data.loadMe };

    if (contact.mobilePhone === defaultPhoneNumber) {
      contact.mobilePhone = null;
      contact.phoneCountryFormat = null;
      contact.phoneCountryName = null;
      contact.phoneCountryDialCode = null;
      contact.phoneCountryCode = null;
    }

    if (contact.firstName === defaultName) {
      contact.firstName = null;
    }

    if (contact.lastName === defaultName) {
      contact.lastName = null;
    }

    return {
      ...contact,
      name: `${contact.firstName} ${contact.lastName}`,
    };
  }

  if (error) {
    snackbar.error(error.message);
  }

  return data?.loadMe
    ? {
        ...data.loadMe,
        name: `${data.loadMe.firstName} ${data.loadMe.lastName}`,
      }
    : null;
}

export { useCurrentUser };
