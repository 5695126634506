/**
 * @file useAuth/Provider.tsx
 * ContextProvider for Authentication
 */
import React, { useEffect, useState, useCallback } from "react";
import { useApolloClient, useLazyQuery } from "@apollo/client";

import { LOAD_ME_QUERY } from "../../graphql";
import authApi from "../../../utils/auth/authApi";
import AuthContext from "./Context";
import LoginDialog from "../../../components/auth/LoginDialog/LoginDialog";
import { LoginData, LoginDialogProps } from "./useAuth";
import { fromGlobalId } from "../../../utils/auth/helpers";
import { useOperator } from "../useOperator";
import { formatPhoneNumber } from "utils/phoneNumberFormatter/phoneNumberFormatter";
import { useHistory } from "react-router-dom";
import { useOperatorSlug } from "../useOperatorSlug";
import { getIsNetflixLogin } from "utils/auth/isNetflixLogin";

export enum SSOTypes {
  NETFLIX = "netflix",
}

export function AuthProvider({ children }) {
  const client = useApolloClient();
  const { operator } = useOperator();

  // state
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const [loginData, setLoginData] = useState<LoginData | null>(null);
  const [loginDialogProps, setLoginDialogProps] = useState<LoginDialogProps>(
    {}
  );
  const [authStage, setAuthStage] = useState<
    "pending" | "authenticated" | "rejected"
  >("pending");
  const [SSOType, setSSOType] = useState("");
  const history = useHistory();
  const operatorSlug = useOperatorSlug();
  const isNetflixLogin = getIsNetflixLogin();

  // queries
  const [loadMeQuery, { data, refetch: refetchLoadMeQuery }] = useLazyQuery(
    LOAD_ME_QUERY,
    {
      onCompleted: (data) => {
        if (
          (data?.loadMe?.mobilePhone || SSOType === SSOTypes.NETFLIX) &&
          operator?.id
        ) {
          const operatorId = fromGlobalId(operator.id).id;
          const formattedTwilioNumber = formatPhoneNumber(
            operator.twilioPhoneNumber?.phoneNumber
          )?.formatted;

          window.analytics.identify(fromGlobalId(data.loadMe.id).id, {
            operator_id: operatorId,
            email: data.loadMe.email,
            name: `${data.loadMe.firstName} ${data.loadMe.lastName}`,
            source: "customer",
          });

          window.analytics.group(operatorId, {
            operator_id: operatorId,
            name: operator.name,
            phone: formattedTwilioNumber || operator.voicePhoneNumber,
            email: operator.generalEmail,
            location: operator.address,
            plan: operator.plan,
            source: "customer",
          });

          if (!loginData) {
            setLoginData(data.loadMe);
          }

          if (isNetflixLogin) {
            setSSOType(SSOTypes.NETFLIX);
          }

          setAuthStage("authenticated");
        } else {
          handleLogout();
        }
      },
      onError: (error) => {
        console.error(error);
        handleLogout();
      },
      fetchPolicy: "network-only",
    }
  );

  // event handlers
  const handleSetSSOType = useCallback((ssoType: string) => {
    setSSOType(ssoType);
  }, []);

  // login dialog open
  const handleLoginDialogOpen = useCallback((props?: any) => {
    setLoginDialogProps(props);
    setLoginDialogOpen(true);
  }, []);

  // login dialog close
  const handleLoginDialogClose = useCallback(() => {
    setLoginDialogOpen(false);
  }, []);

  // login / signup
  const handleLogin = useCallback(async () => {
    if (isNetflixLogin) {
      setSSOType(SSOTypes.NETFLIX);
    }

    // loadMeQuery();
    await refetchLoadMeQuery();
    setAuthStage("authenticated");
  }, [refetchLoadMeQuery, isNetflixLogin]);

  const handleSetLoginData = useCallback((props: object) => {
    setLoginData(props);
  }, []);

  // logout / clear user cache
  const handleLogout = useCallback(async () => {
    await authApi.logout({
      operatorId: fromGlobalId(operator.id).id,
    });

    // clears cache if coming from authenticated or if it has data
    if (authStage === "authenticated" || data?.loadMe?.mobilePhone) {
      client.clearStore();
    }

    if (SSOType === SSOTypes.NETFLIX) {
      history.push(`/${operatorSlug}/new/info?loginType=netflix`);
    }

    setAuthStage("rejected");
  }, [
    operator.id,
    authStage,
    data,
    client,
    SSOType,
    history,
    operatorSlug,
  ]);

  // effects
  // mounting app
  useEffect(() => {
    setAuthStage("pending");

    // if operator query hasn't finished loading,
    // we wait to attempt login.
    if (operator.nameSlug) {
      loadMeQuery();
    }
  }, [loadMeQuery, operator.nameSlug]);

  return (
    <AuthContext.Provider
      value={{
        authStage,
        onLogin: handleLogin,
        onLogout: handleLogout,
        onLoginDialogOpen: handleLoginDialogOpen,
        onLoginDialogClose: handleLoginDialogClose,
        loginData,
        setLoginData: handleSetLoginData,
        SSOType,
        handleSetSSOType,
      }}
    >
      <LoginDialog
        open={loginDialogOpen}
        onClose={handleLoginDialogClose}
        onExited={() => setLoginDialogProps({})}
        onLoginDataSuccess={(loginData: object) => setLoginData(loginData)}
        {...loginDialogProps}
      />
      {children}
    </AuthContext.Provider>
  );
}
