import cloneDeep from "lodash/cloneDeep";
import every from "lodash/every";
import isNull from "lodash/isNull";

import { CreateQuoteFormState } from "pages/new/steps/confirm/components/CreateQuoteForm/form/schemaValidation";
import { CreateReservationFormState } from "pages/new/steps/confirm/components/CreateReservationForm/form/schemaValidation";
import { CreateRequestState } from "../initialState";

export const mergeConfirmRequestFormState = (
  request: CreateRequestState,
  formState: CreateQuoteFormState | CreateReservationFormState
): CreateRequestState => {
  const { bookingContact } = formState;

  const clonedRequest = cloneDeep(request);

  if (bookingContact) {
    clonedRequest.bookingContact = {
      ...clonedRequest.bookingContact,
      firstName: bookingContact.firstName,
      lastName: bookingContact.lastName,
      email: bookingContact.email,
      // flatten phoneFields back into contact
      ...bookingContact.phoneFields,
    };
  }

  // if customer has entered a promo code, add it to the route
  if (
    "promoCodeCustomerInput" in formState &&
    formState.promoCodeCustomerInput
  ) {
    clonedRequest.trip.routes[0] = {
      ...clonedRequest.trip.routes[0],
      promoCodeId: formState.promoCodeCustomerInput.id,
      promoCodeAmt: formState.promoCodeCustomerInput.promoCodeAmt,
      promoCodePercent: formState.promoCodeCustomerInput.promoCodePercent,
    };
  }

  // if customer has entered child seats or other additional items, add them to the route
  if (
    "additonalItemsByTrip" in formState &&
    formState.additonalItemsByTrip &&
    formState.additonalItemsByTrip[0] &&
    !every(formState.additonalItemsByTrip[0].childSeats, isNull)
  ) {
    clonedRequest.trip.routes[0] = {
      ...clonedRequest.trip.routes[0],
      ...formState.additonalItemsByTrip[0].childSeats,
    };
    if (clonedRequest.returnTrip) {
      clonedRequest.returnTrip.routes[0] = {
        ...clonedRequest.returnTrip?.routes[0],
        ...formState.additonalItemsByTrip[0].childSeats,
      };
    }
  }

  return clonedRequest;
};
