import React from "react";

import { Grid } from "@mui/material";

import OrderCard from "pages/orders/components/OrderCard";
import { useScreenSize } from "globals/hooks";
import { Request } from "types";

type OrdersListProps = {
  requests: Request[];
};

function OrdersList(props: OrdersListProps) {
  const { requests } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <Grid container spacing={isMobileView ? 2 : 3}>
      {requests.map((request) => (
        <Grid item xs={12} md={6} key={request.id}>
          <OrderCard request={request} />
        </Grid>
      ))}
    </Grid>
  );
}

export default OrdersList;
