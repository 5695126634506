import React from "react";

type BriefcaseIconProps = {
  color?: string;
  size?: "small";
};

const BriefcaseIcon = (props: BriefcaseIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.8 13V2.33333C10.8 1.97971 10.6525 1.64057 10.3899 1.39052C10.1274 1.14048 9.7713 1 9.4 1H6.6C6.2287 1 5.8726 1.14048 5.61005 1.39052C5.3475 1.64057 5.2 1.97971 5.2 2.33333V13M2.4 3.66667H13.6C14.3732 3.66667 15 4.26362 15 5V11.6667C15 12.403 14.3732 13 13.6 13H2.4C1.6268 13 1 12.403 1 11.6667V5C1 4.26362 1.6268 3.66667 2.4 3.66667Z"
          stroke={color || "#B3B3B3"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 21V5C16 4.46957 15.7893 3.96086 15.4142 3.58579C15.0391 3.21071 14.5304 3 14 3H10C9.46957 3 8.96086 3.21071 8.58579 3.58579C8.21071 3.96086 8 4.46957 8 5V21M4 7H20C21.1046 7 22 7.89543 22 9V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19V9C2 7.89543 2.89543 7 4 7Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BriefcaseIcon;
