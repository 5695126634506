import React from "react";

import { Box, Typography } from "@mui/material";

import { grayDark } from "design-system/colors";

function PassengerListHeader() {
  return (
    <Box display="flex" my={3}>
      <Box flex="4">
        <Typography variant="overline" color={grayDark}>
          Name
        </Typography>
      </Box>
      <Box flex="1.5">
        <Typography variant="overline" color={grayDark}>
          Email
        </Typography>
      </Box>
      <Box flex="1">
        <Typography variant="overline" color={grayDark}>
          Phone
        </Typography>
      </Box>
    </Box>
  );
}

export default PassengerListHeader;
