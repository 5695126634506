import React from "react";

type IceChestIconProps = {
  color?: string;
  size: "small";
};

const IceChestIcon = (props: IceChestIconProps) => {
  const { color } = props;

  // size === small
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 4.00004H2.83333M2.83333 4.00004H13.5M2.83333 4.00004V13.3334C2.83333 13.687 2.97381 14.0261 3.22386 14.2762C3.47391 14.5262 3.81304 14.6667 4.16667 14.6667H10.8333C11.187 14.6667 11.5261 14.5262 11.7761 14.2762C12.0262 14.0261 12.1667 13.687 12.1667 13.3334V4.00004H2.83333ZM4.83333 4.00004V2.66671C4.83333 2.31309 4.97381 1.97395 5.22386 1.7239C5.47391 1.47385 5.81304 1.33337 6.16667 1.33337H8.83333C9.18696 1.33337 9.52609 1.47385 9.77614 1.7239C10.0262 1.97395 10.1667 2.31309 10.1667 2.66671V4.00004"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IceChestIcon;
