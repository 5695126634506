import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import { Box, Typography, CircularProgress } from "@mui/material";

import GQLQueryStatusIndicator from "../../components/GQLQueryStatusIndicator";
import { LOAD_INVOICE_QUERY } from "../../globals/graphql";
import InvoiceHeader from "../../components/invoice/InvoiceHeader";
import InvoiceSummaryBlock from "../../components/invoice/InvoiceSummaryBlock";
import InvoiceOrdersList from "../../components/invoice/InvoiceOrdersList";
import { white, grayLight } from "../../design-system/colors";
import { currency } from "../../utils/helpers";
import { useScreenSize } from "../../globals/hooks";
import { Invoice, InvoiceStatus } from "../../types";

function InvoicePageContent() {
  // hooks
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const { isMobileView } = useScreenSize();

  // queries
  const {
    data: invoiceData,
    loading: invoiceLoading,
    error: invoiceError,
    refetch: invoiceRefetch,
  } = useQuery(LOAD_INVOICE_QUERY, {
    variables: {
      id: invoiceId,
    },
    skip: !invoiceId,
    fetchPolicy: "network-only",
  });

  if (invoiceLoading) {
    return (
      <Box
        width="100%"
        height="90vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress size={40} thickness={2} />
      </Box>
    );
  }

  if (invoiceError && !invoiceLoading) {
    return (
      <Box
        width="100%"
        height="90vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <GQLQueryStatusIndicator
          name="Invoice"
          data={invoiceData}
          error={invoiceError}
          refetch={invoiceRefetch}
        />
      </Box>
    );
  }

  const invoice: Invoice = invoiceData.node;
  const dueDate = invoice.dueDate;

  const invoiceAmountDue = currency(invoice.amountDue);
  const isPaid = invoice.status === InvoiceStatus.Paid;

  return (
    <Box width="100%" bgcolor={white} pb={2}>
      <Box mb={isMobileView ? 3 : 5}>
        <InvoiceHeader invoice={invoice} />
      </Box>
      <Box maxWidth={840} px={2} mb={isMobileView ? 4 : 5} mx="auto">
        <InvoiceSummaryBlock
          dueDate={dueDate}
          amountDue={invoice.amountDue}
          isPaid={isPaid}
          subPayments={invoice.subPayments}
          memo={invoice.memo}
        />
      </Box>
      <Box maxWidth={840} mx="auto" mb={2}>
        <InvoiceOrdersList invoice={invoice} />
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        maxWidth={840}
        mx={isMobileView ? 2 : "auto"}
        mb={3}
      >
        <Box
          display="flex"
          borderRadius="4px"
          minWidth={224}
          height={48}
          px={2}
          mr={2}
          bgcolor={grayLight}
        >
          <Box
            alignSelf="center"
            display="flex"
            flexDirection="row"
            width="100%"
            justifyContent="space-between"
          >
            <Box mr={4}>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Amount Due
              </Typography>
            </Box>
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              {invoiceAmountDue}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default InvoicePageContent;
