// includes CardElement and Cardholder Name fields only, with no "Same as booking contact feature".

import React from "react";
import { Control } from "react-hook-form";

import { Box } from "@mui/material";

import { useAnalytics, useScreenSize } from "globals/hooks";
import { ConfirmRequestFormState } from "pages/order/components/ConfirmReservationForm/form/schemaValidation";
import { RHFTextField } from "components/react-hook-form/text-field/RHFTextField";
import RHFStripeCreditCardInput from "components/react-hook-form/RHFStripeCreditCardInput";

type PaymentFormProps = {
  control: Control<ConfirmRequestFormState>;
  trackingFrom?: "reserve" | "quoteRequest";
};

function PaymentForm(props: PaymentFormProps) {
  const { control, trackingFrom } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();

  // events
  const onCardholderNameInputFocus = () => {
    if (trackingFrom) {
      track(`${trackingFrom}_cardholderNameSelected`);
    }
  };

  const onCardDetailsInputFocus = () => {
    if (trackingFrom) {
      track(`${trackingFrom}_creditCardSelected`);
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection={isMobileView ? "column" : "row"}
        mb={1}
      >
        {/* cardholder name */}
        <RHFTextField
          required
          fullWidth
          variant="outlined"
          label="Cardholder name"
          name="paymentMethod.cardholderName"
          control={control}
          onFocus={onCardholderNameInputFocus}
        />
      </Box>
      {/* card element */}
      <Box mb={4} minHeight="56px" display="flex" flexDirection="column">
        <RHFStripeCreditCardInput
          name="paymentMethod.creditCardError"
          control={control}
          onFocus={onCardDetailsInputFocus}
        />
      </Box>
    </Box>
  );
}

export default PaymentForm;
