import sortBy from "lodash/sortBy";

import { CancellationPolicy } from "../../../types";

type DeriveStrictestCancellationPolicyProps = {
  cancellationPoliciesData: CancellationPolicy[];
  selectedVehicleIds: string[];
};

export const deriveStrictestCancellationPolicy = (
  props: DeriveStrictestCancellationPolicyProps
) => {
  const { cancellationPoliciesData, selectedVehicleIds } = props;

  const cancellationPolicies = sortBy(cancellationPoliciesData, "variant");

  const highestPolicy = cancellationPolicies.find((policy) =>
    policy.vehicles.some((vehicle) => selectedVehicleIds.includes(vehicle.id))
  );

  if (!highestPolicy) {
    return [];
  }

  const { refund100, refund50, refund25 } = highestPolicy;

  // derive the lowest refund value possible for no refund
  const noRefund = refund25.active
    ? refund25
    : refund50.active
    ? refund50
    : refund100.active
    ? refund100
    : undefined;

  // if no active policy we return
  if (!noRefund) return [];

  return [
    ...(refund100.active
      ? [
          `Full refund if cancelled more than ${refund100.periodValue} ${
            refund100.periodName.toLowerCase() +
            (refund100.periodValue === 1 ? "" : "s")
          } prior to scheduled trip.`,
        ]
      : []),
    ...(refund50.active
      ? [
          `50% refund if cancelled more than ${refund50.periodValue} ${
            refund50.periodName.toLowerCase() +
            (refund50.periodValue === 1 ? "" : "s")
          } prior to scheduled trip.`,
        ]
      : []),
    ...(refund25.active
      ? [
          `25% refund if cancelled more than ${refund25.periodValue} ${
            refund25.periodName.toLowerCase() +
            (refund25.periodValue === 1 ? "" : "s")
          } prior to scheduled trip.`,
        ]
      : []),
    `No refund if cancelled less than ${noRefund.periodValue} ${
      noRefund.periodName.toLowerCase() +
      (noRefund.periodValue === 1 ? "" : "s")
    } prior to scheduled trip.`,
  ];
};
