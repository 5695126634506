import React from "react";

import { Box, Button, Card, Typography, useTheme } from "@mui/material";

import RequestSummaryItem from "./RequestSummaryItem";
import { EditIcon } from "design-system/icons";

export type RequestSummaryDesktopProps = {
  requestSummaryItems: { label: string; value: string | number }[];
  onEditClick: () => void;
};

function RequestSummaryDesktop(props: RequestSummaryDesktopProps) {
  const { requestSummaryItems, onEditClick } = props;

  const theme = useTheme();

  return (
    <Box mt={3}>
      <Card variant="outlined">
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h3" pt={2} px={2}>
            Request Summary
          </Typography>
          <Box display="flex" justifyContent="center" alignItems="center" p={1}>
            <Button sx={{ width: "20px" }} onClick={onEditClick}>
              <Typography color={theme.palette.primary.main} pr={1}>
                Edit
              </Typography>
              <EditIcon size="small" color={theme.palette.primary.main} />
            </Button>
          </Box>
        </Box>
        <Box display="flex">
          {requestSummaryItems.map(({ label, value }) =>
            value ? (
              <RequestSummaryItem label={label} value={value} key={label} />
            ) : null
          )}
        </Box>
      </Card>
    </Box>
  );
}

export default RequestSummaryDesktop;
