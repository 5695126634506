import { useReducer } from "react";
import moment from "moment";
import first from "lodash/first";
import last from "lodash/last";

const addTripReducerActionTypesEnum = {
  INTIAL_STATE: "INTIAL_STATE",
  STOPS: "STOPS",
  TOTAL_GROUP_SIZE: "TOTAL_GROUP_SIZE",
  TRIP_NOTES: "TRIP_NOTES",
  VEHICLE_ID: "VEHICLE_ID",
  CARRY_ON_LUGGAGE: "CARRY_ON_LUGGAGE",
  CHECKED_LUGGAGE: "CHECKED_LUGGAGE",
  OVERSIZE_LUGGAGE: "OVERSIZE_LUGGAGE",
};

export type newTripAddedOnQuoteProps = {
  totalGroupSize: number;
  note: string;
  vehicleId: string;
  stops: {
    location: string;
    dateTime: string | null;
    stopIndex: number;
  }[];
  carryOnLuggage: number;
  checkedLuggage: number;
  oversizeLuggage: number;
};

const newTripAddedOnQuoteIntialState: newTripAddedOnQuoteProps = {
  totalGroupSize: 0,
  note: "",
  vehicleId: "",
  stops: [
    {
      location: "",
      dateTime: "",
      stopIndex: 1,
    },
    {
      location: "",
      dateTime: null,
      stopIndex: 2,
    },
  ],
  carryOnLuggage: 0,
  checkedLuggage: 0,
  oversizeLuggage: 0,
};

const handleStopUpdate = (
  currTripAddedOnQuote: newTripAddedOnQuoteProps,
  stopValue
) => {
  const firstStop = first(currTripAddedOnQuote.stops);
  const lastStop = last(currTripAddedOnQuote.stops);
  let updatedStops = [];
  if (stopValue.stopIndex === 1 && !!stopValue.dateTime) {
    updatedStops = [
      {
        ...firstStop,
        dateTime: moment(stopValue.dateTime).toISOString(),
      },
      lastStop,
    ];
  } else if (stopValue.stopIndex === 1) {
    updatedStops = [
      {
        ...firstStop,
        location: stopValue.location,
      },
      lastStop,
    ];
  } else {
    updatedStops = [
      firstStop,
      {
        ...lastStop,
        location: stopValue.location,
      },
    ];
  }
  return {
    ...currTripAddedOnQuote,
    stops: updatedStops,
  };
};

function addTripToQuoteReducer(
  newTripAddedOnQuote: newTripAddedOnQuoteProps,
  action: { type: string; value?: any }
) {
  const {
    INTIAL_STATE,
    STOPS,
    TOTAL_GROUP_SIZE,
    TRIP_NOTES,
    VEHICLE_ID,
    CARRY_ON_LUGGAGE,
    CHECKED_LUGGAGE,
    OVERSIZE_LUGGAGE,
  } = addTripReducerActionTypesEnum;

  switch (action.type) {
    case INTIAL_STATE:
      return newTripAddedOnQuoteIntialState;
    case STOPS:
      return handleStopUpdate(newTripAddedOnQuote, action.value);
    case TOTAL_GROUP_SIZE:
      const newCount =
        action.value < 0 ? 0 : action.value > 99 ? 99 : action.value;
      return { ...newTripAddedOnQuote, totalGroupSize: newCount };
    case TRIP_NOTES:
      return { ...newTripAddedOnQuote, note: action.value };
    case VEHICLE_ID:
      return { ...newTripAddedOnQuote, vehicleId: action.value };
    case CARRY_ON_LUGGAGE:
      const carryOnCount =
        action.value < 0 ? 0 : action.value > 99 ? 99 : action.value;
      return { ...newTripAddedOnQuote, carryOnLuggage: carryOnCount };
    case CHECKED_LUGGAGE:
      const checkedCount =
        action.value < 0 ? 0 : action.value > 99 ? 99 : action.value;
      return { ...newTripAddedOnQuote, checkedLuggage: checkedCount };
    case OVERSIZE_LUGGAGE:
      const oversizeCount =
        action.value < 0 ? 0 : action.value > 99 ? 99 : action.value;
      return { ...newTripAddedOnQuote, oversizeLuggage: oversizeCount };
    default:
      return newTripAddedOnQuote;
  }
}

const useAddTripToQuoteReducer = () =>
  useReducer(addTripToQuoteReducer, newTripAddedOnQuoteIntialState);

export { useAddTripToQuoteReducer, addTripReducerActionTypesEnum };
