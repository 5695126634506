import React from "react";
import Cohere from "cohere-js";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";

import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";

import SnackbarProvider from "./globals/hooks/useSnackbar/Provider";
import Router from "./Router";
import "./App.css";

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_PUBLIC_DSN });

Cohere.init(process.env.REACT_APP_COHERE_API_KEY, {
  disableRecording: true,
});

function App() {
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <SnackbarProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </SnackbarProvider>
    </LocalizationProvider>
  );
}

export default App;
