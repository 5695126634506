/**
 * How RHFStripeCreditCardInput works with React Hook Form
 * The Stripe Card Element does not allow us to read the card number directly.
 * Instead, we must use the StripeCardElementChangeEvent returned by the CardElement.
 * Instead of the form storing the value of the card number, we store errors returned from stripe instead.
 * Then, by passing 'validateStripeCreditCard' to the error schema of React Hook Form, the form reads that value and validates appropriately.
 */
import React from "react";
import { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import { Control, Controller, useFormContext } from "react-hook-form";

import MoovsStripeCardElement from "../../globals/MoovsStripeCardElement";

type RHFStripeCreditCardInputProps = {
  name: string;
  control?: Control<any>;
  onFocus?: () => void;
};

function RHFStripeCreditCardInput(props: RHFStripeCreditCardInputProps) {
  const { name, onFocus } = props;

  // hooks
  const context = useFormContext();

  // derived state
  const control = props.control || context.control;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange }, fieldState: { error } }) => {
        // event handlers
        const handleCreditCardChange = (e: StripeCardElementChangeEvent) => {
          let value = null;

          if (e?.error?.message) {
            value = e.error;
          } else if (e?.empty || !e?.complete) {
            value = REQUIRED_ERROR;
          }

          onChange(value);
        };

        // not sure what this does...
        const setInitialError = () => {
          onChange(REQUIRED_ERROR);
        };

        return (
          <MoovsStripeCardElement
            errorMessage={error?.message || ""}
            onCreditCardChange={handleCreditCardChange}
            onReady={setInitialError}
            onFocus={onFocus}
          />
        );
      }}
    />
  );
}

export default RHFStripeCreditCardInput;

// constants
const REQUIRED_ERROR = {
  type: "required",
  message: "Please fill out missing payment information.",
};
