import React from "react";

import { Box, Button, useTheme } from "@mui/material";

import { PlusIcon } from "design-system/icons";

type AddStopButtonProps = {
  onClick: () => void;
};

function AddStopButton(props: AddStopButtonProps) {
  const { onClick } = props;

  // hooks
  const theme = useTheme();

  return (
    <Box
      mt={1}
      mb={1}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box height="1px" width="100%" bgcolor={theme.palette.primary.light} />
      <Box
        width="100%"
        display="flex"
        minWidth="120px"
        justifyContent="center"
        alignItems="center"
      >
        <Button
          color="primary"
          onClick={onClick}
          size="large"
          startIcon={<PlusIcon />}
        >
          Add Stop
        </Button>
      </Box>
      <Box height="1px" width="100%" bgcolor={theme.palette.primary.light} />
    </Box>
  );
}

export default AddStopButton;
