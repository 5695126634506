import { decodeGlobalId } from "utils/helpers";

const getDataForUpdateContact = (formState, loginData) => {

  return {
    variables: {
      input: {
        id: loginData.contactId
          ? loginData.contactId
          : decodeGlobalId(loginData.id),
        firstName: formState.bookingContact.firstName,
        lastName: formState.bookingContact.lastName,
        mobilePhone: formState.bookingContact.phoneFields.mobilePhone,
        phoneCountryCode: formState.bookingContact.phoneFields.phoneCountryCode,
        phoneCountryDialCode:
          formState.bookingContact.phoneFields.phoneCountryDialCode,
        phoneCountryName: formState.bookingContact.phoneFields.phoneCountryName,
        phoneCountryFormat:
          formState.bookingContact.phoneFields.phoneCountryFormat,
      },
    },
  };

};

export default getDataForUpdateContact;
