import React, { useState, MouseEvent, ReactElement } from "react";

import { Box, Tooltip, Typography } from "@mui/material";

import { black, granite, grayMedium, white } from "../../design-system/colors";
import { InfoIcon } from "../../design-system/icons";
import { useScreenSize } from "../../globals/hooks";

type MoovsTooltipProps = { children?: ReactElement; noMaxWidth?: boolean } & (
  | MoovsTooltipUsingContentType
  | MoovsTooltipUsingPropsType
);

type MoovsTooltipUsingContentType = {
  tooltipContent: React.ReactNode;
};

type MoovsTooltipUsingPropsType = {
  tooltipText?: string;
  tooltipSubtext?: React.ReactNode;
  tooltipAdditionalText?: string;
  tooltipPretext?: string;
  showcaseMainText?: boolean;
};

function MoovsTooltip(props: MoovsTooltipProps) {
  const { children, noMaxWidth } = props;

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { isMobileView } = useScreenSize();

  // prevent option from being selected when touching tooltip on mobile
  const handleMobileTooltipOpen = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const content = children || (
    <Box display="flex" onClick={isMobileView ? handleMobileTooltipOpen : null}>
      <InfoIcon color={tooltipOpen ? black : granite} size="small" />
    </Box>
  );

  return (
    <Tooltip
      enterTouchDelay={0}
      leaveTouchDelay={3000}
      onOpen={() => setTooltipOpen(true)}
      onClose={() => setTooltipOpen(false)}
      placement="top"
      componentsProps={{
        tooltip: {
          sx: {
            ...{
              backgroundColor: granite,
              borderRadius: "4px",
              ...("tooltipContent" in props && {
                backgroundColor: white,
                color: granite,
                boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.08)",
                borderRadius: "4px",
                border: `1px solid ${grayMedium}`,
                maxWidth: 220,
              }),
              ...(noMaxWidth && { maxWidth: "none" }),
            },
          },
        },
      }}
      title={
        "tooltipContent" in props ? (
          <>{props.tooltipContent}</>
        ) : (
          <Box
            p={0}
            maxWidth={noMaxWidth ? "none" : "153px"}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box display="flex" flexDirection="row">
              {props.tooltipPretext && (
                <Typography
                  variant="h6"
                  style={{ fontWeight: 400, whiteSpace: "pre" }}
                >
                  {`${props.tooltipPretext} `}
                </Typography>
              )}
              <Typography
                variant="caption"
                {...(props.showcaseMainText && { style: { fontWeight: 700 } })}
              >
                {props.tooltipText}
              </Typography>
            </Box>
            <Typography
              variant="caption"
              {...(!props.showcaseMainText && { style: { fontWeight: 700 } })}
            >
              {props.tooltipSubtext}
            </Typography>
            <Typography
              variant="caption"
              {...(!props.showcaseMainText && { style: { fontWeight: 700 } })}
            >
              {props.tooltipAdditionalText}
            </Typography>
          </Box>
        )
      }
    >
      <Box>{content}</Box>
    </Tooltip>
  );
}

export default MoovsTooltip;
