import React from "react";

import { Tabs, useTheme, Tab, Typography } from "@mui/material";

import { grayMedium } from "../../design-system/colors";
import { useScreenSize } from "../../globals/hooks";

type MoovsTabsProps = {
  onChange: (newValue: any) => void;
  activeTab: string;
  tabValues: { label: string; bigLabel?: string; value: string }[];
  indicatorWidth?: number;
};

function MoovsTabs(props: MoovsTabsProps) {
  const { onChange, activeTab, tabValues, indicatorWidth } = props;
  const theme = useTheme();

  const styles = {
    selected: {
      color: theme.palette.primary.main,
    },
    root: {
      fontSize: 14,
      fontWeight: 600,
      maxWidth: "160px",
      padding: `${theme.spacing(2)} `,
      borderBottom: `1px solid ${grayMedium}`,
    },
    indicator: { width: `${indicatorWidth}px !important` },
  };

  const { isMobileView } = useScreenSize();

  return (
    <Tabs
      {...(isMobileView && {
        sx: { "& .MuiTabs-indicator": styles.indicator },
      })}
      indicatorColor="primary"
      value={activeTab}
      variant={isMobileView ? "fullWidth" : "standard"}
      onChange={(event, newValue) => onChange(newValue)}
    >
      {tabValues.map((tabValue) => {
        const { label, value, bigLabel } = tabValue;
        const isActive = activeTab === label;

        return (
          <Tab
            {...(bigLabel
              ? {
                  icon: <Typography variant="h3">{bigLabel}</Typography>,
                  label: (
                    <Typography
                      variant="overline"
                      {...(isActive && { style: { color: primaryMainColor } })}
                    >
                      {label}
                    </Typography>
                  ),
                }
              : { label: label })}
            sx={{
              "&& .MuiTab-selected": styles.selected,
              "& .MuiTab-root": styles.root,
            }}
            value={value}
            key={value}
          />
        );
      })}
    </Tabs>
  );
}

export default MoovsTabs;
