import React from "react";
import moment from "moment-timezone";

import { Box, Button, Typography, useTheme } from "@mui/material";

import {
  SubPayment,
  PaymentAction,
  PaymentRefund,
  PaymentMethodEnum,
} from "types";
import { grayDark, grayMedium } from "design-system/colors";
import { currency } from "utils/helpers";
import { toTitleCase } from "globals/utils/string";
import { OpenIcon } from "design-system/icons";
import { useViewReceiptClick } from "./hooks/useViewReceiptClick";

type PaymentListItemMobileProps = {
  subPayment?: SubPayment;
  refund?: PaymentRefund;
};

function PaymentListItemMobile(props: PaymentListItemMobileProps) {
  const { subPayment, refund } = props;

  // hooks
  const theme = useTheme();
  const handleViewReceipt = useViewReceiptClick({ subPayment, refund });

  if (!subPayment && !refund) return null;

  // derived state
  const note = refund ? refund.note : subPayment.payment.note;
  const createdAt = refund ? refund.createdAt : subPayment.createdAt;
  const amount = refund ? refund.amount : subPayment.amount;
  const method = refund ? refund.method : subPayment.payment.method;
  const payment = refund ? refund.payment : subPayment.payment;

  const methodOfPayment =
    method === PaymentMethodEnum.Card
      ? `Card ****-${payment.paymentMethod.card.last4}`
      : method === PaymentMethodEnum.GenericCard
      ? "Credit Card"
      : toTitleCase(method);

  return (
    <Box
      border={`${grayMedium} 1px solid`}
      borderRadius="4px"
      display="flex"
      flex="1"
      flexDirection="column"
      p={2}
      mb={1}
    >
      <Box display="flex" flexDirection="column" mb={1}>
        <Typography variant="overline" sx={{ color: grayDark }}>
          type
        </Typography>
        <Typography variant="h6">
          {toTitleCase(refund ? PaymentAction.Refund : PaymentAction.Charge)}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" mb={1}>
        <Typography variant="overline" sx={{ color: grayDark }}>
          payment method
        </Typography>
        <Typography variant="h6">{methodOfPayment}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" mb={1}>
        <Typography variant="overline" sx={{ color: grayDark }}>
          note
        </Typography>
        <Typography variant="h6">{note || "-"}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" mb={1}>
        <Typography variant="overline" sx={{ color: grayDark }}>
          date & time
        </Typography>
        <Typography variant="h6">{moment(createdAt).format("llll")}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" mb={1}>
        <Typography variant="overline" sx={{ color: grayDark }}>
          amount
        </Typography>
        <Typography variant="h6">{`${refund ? "-" : ""} ${currency(
          amount
        )}`}</Typography>
      </Box>
      <Box display="flex">
        {method === PaymentMethodEnum.Card && (
          <Button onClick={handleViewReceipt} sx={{ px: 0 }}>
            <Typography variant="body2" mr={1}>
              View Receipt
            </Typography>
            <OpenIcon size="small" color={theme.palette.primary.main} />
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default PaymentListItemMobile;
