/**
 * @file LoginDialog
 * Handles contact auth using twilio verify codes
 *
 * Has two state, sending code and verifying code.
 *
 */
import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";

import MoovsDialog from "../../globals/MoovsDialog";
import VerifyCodeBlock from "./components/VerifyCodeBlock";
import SendCodeBlock from "./components/SendCodeBlock";
import SSOCodeBlock from "./components/SSOCodeBlock";
import CompanyLogo from "../../globals/CompanyLogo";
import { useAnalytics, useScreenSize } from "globals/hooks";

type LoginDialogProps = {
  open: boolean;
  isNetflixLogin?: boolean;
  onClose: () => void;
  mobilePhone?: {
    mobilePhone: string;
    phoneCountryCode: string;
    phoneCountryDialCode: string;
    phoneCountryName: string;
    phoneCountryFormat: string;
  };
  email?: string;
  disableClose?: boolean;
  sendCodeText?: string;
  verifyCodeText?: string;
  verifyButtonText?: string;
  verifyButtonIcon?: any;
  closeOnSuccess?: boolean;
  onVerifySuccess?: (() => Promise<void>) | (() => void);
  renderContinueAsGuest?: boolean;
  onExited?: () => void;
  onLoginDataSuccess?: (loginData: object) => void;
};

function LoginDialog(props: LoginDialogProps) {
  const {
    open,
    isNetflixLogin,
    onClose,
    verifyCodeText,
    verifyButtonText,
    verifyButtonIcon,
    onVerifySuccess,
    renderContinueAsGuest,
    closeOnSuccess = true,
    mobilePhone: mobilePhoneViaProps,
    email: emailViaProps,
    onExited,
    onLoginDataSuccess,
  } = props;

  // hooks
  const { track } = useAnalytics();
  const { isMobileView } = useScreenSize();

  // state
  const [isVerificationSent, setIsVerificationSent] = useState(false);
  const [mobilePhone, setMobilePhone] = useState(null);
  const [email, setEmail] = useState(null);

  const handleCodeSuccessfullySent = () => {
    setIsVerificationSent(true);
  };

  const handleVerifySuccess = () => {
    if (onVerifySuccess) onVerifySuccess();
    if (closeOnSuccess) onClose();

    track("bookingTool_login", {
      type: email || emailViaProps ? "Email" : "Phone",
    });
  };

  // effects
  useEffect(() => {
    // only run when opening to prevent UI changing while closing
    if (open) {
      // clear previous state
      setMobilePhone(null);
      setEmail(null);
      // if mobilePhone/email is passed in via props this
      // means the send-code api call happened prior
      // to opening thie dialog so we immediately
      // go to verify-code block
      setIsVerificationSent(!!mobilePhoneViaProps || !!emailViaProps);
    }
  }, [open, mobilePhoneViaProps, emailViaProps]);

  const styles = {
    ssoBox: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: "32px",
      marginBottom: "32px",
    },
  };

  return (
    <MoovsDialog
      removeCloseButton
      hideTopBorder
      hideBottomBorder
      open={open}
      onClose={onClose}
      size={isMobileView ? "sm" : "xs"}
      dialogTitle="Login"
      onExited={onExited}
    >
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Box display="flex" justifyContent="center" mb={4}>
          <CompanyLogo />
        </Box>
        {isNetflixLogin ? (
          <Box sx={styles.ssoBox}>
            <SSOCodeBlock
              onVerifySuccess={handleVerifySuccess}
              onLoginDataSuccess={onLoginDataSuccess}
            />
          </Box>
        ) : isVerificationSent ? (
          <VerifyCodeBlock
            mobilePhone={mobilePhoneViaProps || mobilePhone}
            email={emailViaProps || email}
            verifyCodeText={verifyCodeText}
            verifyButtonText={verifyButtonText}
            verifyButtonIcon={verifyButtonIcon}
            onVerifySuccess={handleVerifySuccess}
          />
        ) : (
          <SendCodeBlock
            onClose={onClose}
            mobilePhone={mobilePhone}
            onCodeSuccessfullySent={handleCodeSuccessfullySent}
            renderContinueAsGuest={renderContinueAsGuest}
            email={email}
            setMobilePhone={setMobilePhone}
            setEmail={setEmail}
          />
        )}
      </Box>
    </MoovsDialog>
  );
}

export default LoginDialog;
