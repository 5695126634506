import React from "react";

import { Box, Typography, ThemeProvider } from "@mui/material";

import deactivatedIllustation from "design-system/illustrations/deactivatedAccount.svg";
import { grayDark } from "design-system/colors";
import useTheme from "useTheme";

function DeactivatedPage() {
  // hooks
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        minHeight="90vh"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          component="img"
          src={deactivatedIllustation}
          alt="A plug pulled out of an electrical outlet"
        />
        <Typography variant="h1" textAlign="center">
          Oops, Something's Not Right.
        </Typography>
        <Typography variant="body2" color={grayDark} textAlign="center" mt={2}>
          This link is no longer valid
        </Typography>
      </Box>
    </ThemeProvider>
  );
}

export default DeactivatedPage;
