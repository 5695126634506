/**
 * @file useOrderPricing.tsx
 * Hook that helps calculate order pricing.
 * This is for existing orders, not when creating
 * a new order. (see useAutomatedPricing)
 */

import isNil from "lodash/isNil";

import { PickUpVariant, PromoCode } from "types";
import { calculateTripPricing, UseOrderPricingType } from "./utils";

export type OrderPricing = {
  tripId: string;
  totalAmt: number;
  pricing: UseOrderPricingType;
  returnTripPricing: {
    pricing: UseOrderPricingType;
    totalAmt: number;
    tripId: string;
  };
};

type getOrderPricingParams = {
  request: any;
  userSelectedDriverGratuityPercent?: number | "cash";
  shouldRenderDriverGratuity: boolean;
  pickUpVariantSelected: PickUpVariant;
  pricingLayoutMeetGreet: number;
  userAppliedPromoCode?: Pick<
    PromoCode,
    "id" | "promoCodeName" | "promoCodeAmt" | "promoCodePercent"
  >;
  additonalItemsByTrip?: {
    childSeats: {
      boosterSeatAmt?: number;
      boosterSeatQuantity?: number;
      forwardFacingSeatAmt?: number;
      forwardFacingSeatQuantity?: number;
      rearFacingSeatAmt?: number;
      rearFacingSeatQuantity?: number;
    };
  }[];
};

function getOrderPricing(params: getOrderPricingParams): OrderPricing[] {
  const {
    request,
    shouldRenderDriverGratuity,
    pickUpVariantSelected,
    pricingLayoutMeetGreet,
    userAppliedPromoCode,
    additonalItemsByTrip,
  } = params;

  const userSelectedDriverGratuityPercent =
    params.userSelectedDriverGratuityPercent === "cash"
      ? 0
      : params.userSelectedDriverGratuityPercent;

  let tripCount = 0;

  return request.trips.map((trip, index) => {
    const operatorSetOutboundMeetGreet = trip.routes[0].pricing.meetGreetAmt;
    const operatorSetReturnMeetGreet =
      trip.returnTrip?.routes[0]?.pricing?.meetGreetAmt;

    const isFirstTrip = index === 0;

    return {
      ...calculateTripPricing({
        trip,
        userSelectedDriverGratuityPercent,
        shouldRenderDriverGratuity,
        pricingLayoutMeetGreet,
        pickUpVariantSelected:
          pickUpVariantSelected ||
          (!isNil(operatorSetOutboundMeetGreet) && PickUpVariant.MeetGreet),
        ...(isFirstTrip && { userAppliedPromoCode }),
        childSeatInput: additonalItemsByTrip?.[index]?.childSeats,
      }),
      tripIndex: ++tripCount,

      // return trip
      ...(trip.returnTrip && {
        returnTripPricing: {
          ...calculateTripPricing({
            trip: trip.returnTrip,
            userSelectedDriverGratuityPercent,
            shouldRenderDriverGratuity,
            pricingLayoutMeetGreet,
            pickUpVariantSelected:
              pickUpVariantSelected ||
              (!isNil(operatorSetReturnMeetGreet) && PickUpVariant.MeetGreet),
            childSeatInput: additonalItemsByTrip?.[index]?.childSeats,
          }),
          tripIndex: ++tripCount,
        },
      }),
    };
  });
}

export { getOrderPricing };
