import { useCallback, useEffect, useMemo, useState } from "react";
import first from "lodash/first";
import last from "lodash/last";

import wayPointMarkerSVG from "../../../design-system/illustrations/dispatchVehicles/wayPointMarker.svg";
import pickUpMarkerSVG from "../../../design-system/illustrations/dispatchVehicles/pickUpMarker.svg";
import dropOffMarkerSVG from "../../../design-system/illustrations/dispatchVehicles/dropOffMarker.svg";
import { createTripStopMarker } from "../utils/createTripStopMarker";
import { Stop } from "types";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { useOperator, useSnackbar } from "globals/hooks";
import { moovsBlue } from "design-system/colors";

type RouteDataParam = {
  wayPoints: google.maps.DirectionsWaypoint[];
  pickUpStop: Stop;
  dropOffStop: Stop;
};

function useAddTripRouteToMap(props) {
  const { map, trip } = props;
  const snackbar = useSnackbar();
  const { isElSegundoConnect } = useOperator();

  // state
  const [tripStopMarkers, setTripStopMarkers] = useState<
    Map<string, google.maps.Marker>
  >(new Map());

  // memoize
  const { directionsService, directionsRenderer } = useMemo(() => {
    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
      polylineOptions: { strokeColor: moovsBlue },
    });
    directionsRenderer.setMap(map);
    return { directionsService, directionsRenderer };
  }, [map]);

  const tripStops = useMemo(() => {
    const stopsMap = new Map();
    trip.stops.forEach((stop) => {
      stopsMap.set(stop.id, stop);
    });
    return stopsMap;
  }, [trip]);

  // helper functions
  const displayTripRoute = useCallback(
    async (routeData: RouteDataParam) => {
      const { pickUpStop, dropOffStop, wayPoints } = routeData;
      try {
        // create route path between all stops
        const directionsResult = await directionsService.route({
          origin: `${pickUpStop.coordinates.x},${pickUpStop.coordinates.y}`,
          destination: `${dropOffStop.coordinates.x},${dropOffStop.coordinates.y}`,
          waypoints: wayPoints,
          travelMode: google.maps.TravelMode.DRIVING,
        });
        directionsRenderer.setDirections(directionsResult);
      } catch (error) {
        const errorMessage =
          getErrorMessage(error) || "Error displaying trip route.";
        snackbar.error(errorMessage);
      }
    },
    [directionsRenderer, directionsService, snackbar]
  );

  const checkSameTripStops = useCallback(
    (prevTripStopsMap, currTripStopsMap) => {
      if (prevTripStopsMap.size !== currTripStopsMap.size) return false;

      let isSame = true;
      currTripStopsMap.forEach(
        ({ stopIndex: currStopIndex, coordinates: currCoords }, currStopId) => {
          if (!prevTripStopsMap.has(currStopId)) {
            isSame = false;
          } else {
            if (
              !hasSameCoordsandStopIndex(
                prevTripStopsMap,
                currStopId,
                currCoords,
                currStopIndex
              )
            ) {
              isSame = false;
            }
          }
        }
      );
      return isSame;
    },
    []
  );

  const createTripMarkersAndWayPoints = useCallback(
    async (trip, map) => {
      const tripStopMarkersMap = new Map();

      // we display markers differently for moovs account 'el-segundo-connect'
      const markers = {
        pickUpNum: isElSegundoConnect ? 1 : null,
        dropOffNum: isElSegundoConnect ? trip.stops.length : null,
        midStopIndexDifference: isElSegundoConnect ? 0 : -1,
      };

      // pick-up
      const pickUpStop: Stop = first(trip.stops);
      tripStopMarkersMap.set(
        pickUpStop.id,
        await createTripStopMarker(
          pickUpStop,
          map,
          isElSegundoConnect ? wayPointMarkerSVG : pickUpMarkerSVG,
          markers.pickUpNum
        )
      );

      // drop off
      const dropOffStop: Stop = last(trip.stops);
      tripStopMarkersMap.set(
        dropOffStop.id,
        await createTripStopMarker(
          dropOffStop,
          map,
          isElSegundoConnect ? wayPointMarkerSVG : dropOffMarkerSVG,
          markers.dropOffNum
        )
      );

      // mid stops
      const wayPoints = [];
      if (trip.stops.length > 2) {
        const midStops = trip.stops.slice(1, trip.stops.length - 1);
        midStops.forEach(async (midStop) => {
          wayPoints.push({
            location: `${midStop.coordinates?.x},${midStop.coordinates?.y}`,
            stopover: true,
          });
          tripStopMarkersMap.set(
            midStop.id,
            await createTripStopMarker(
              midStop,
              map,
              wayPointMarkerSVG,
              midStop.stopIndex + markers.midStopIndexDifference
            )
          );
        });
      }

      return {
        tripStopMarkersMap,
        wayPoints,
        dropOffStop,
        pickUpStop,
      };
    },
    [isElSegundoConnect]
  );

  const hasSameCoordsandStopIndex = (
    prevTripStopsMap,
    currStopId,
    currCoords,
    currStopIndex
  ) => {
    const prevTripStop = prevTripStopsMap.get(currStopId);
    const prevLat = prevTripStop.getPosition().lat();
    const prevLng = prevTripStop.getPosition().lng();
    return (
      prevLat === currCoords?.x &&
      prevLng === currCoords?.y &&
      prevTripStop["stopIndex"] === currStopIndex
    );
  };

  // create new markers and route path if there is a change in trip stops
  useEffect(() => {
    const verify = async () => {
      // if there is a change in trip stops
      if (!checkSameTripStops(tripStopMarkers, tripStops)) {
        // remove all markers
        tripStopMarkers.forEach((tripStopMarker) => {
          tripStopMarker.setMap(null);
        });
        // create new markers
        const { wayPoints, tripStopMarkersMap, pickUpStop, dropOffStop } =
          await createTripMarkersAndWayPoints(trip, map);

        setTripStopMarkers(tripStopMarkersMap);

        // add route path to map
        displayTripRoute({
          wayPoints,
          pickUpStop,
          dropOffStop,
        });
      }
    };
    verify();
  }, [
    map,
    trip,
    directionsRenderer,
    directionsService,
    tripStopMarkers,
    tripStops,
    displayTripRoute,
    checkSameTripStops,
    createTripMarkersAndWayPoints,
  ]);
}

export default useAddTripRouteToMap;
