/**
 * @file PrivateRoute.tsx
 * Protected route HOC for redirecting unauthenticated users. Wrapper for react-router Route component
 * Reference: https://reacttraining.com/react-router/web/example/auth-workflow
 *
 * components:
 *  PrivateRoute
 *
 */

import React, { useEffect } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Cohere from "cohere-js";

import { useAuth } from "../../globals/hooks";
import { LOAD_ME_QUERY } from "../../globals/graphql";

function PrivateRoute({ children, ...rest }) {
  // hooks
  const { authStage } = useAuth();
  const history = useHistory();

  // queries
  const { data: meData } = useQuery(LOAD_ME_QUERY, {
    skip: authStage !== "authenticated",
  });

  // effects
  // setup cohere
  useEffect(() => {
    if (meData?.loadMe) {
      const { id, firstName, lastName, email } = meData.loadMe;

      const environment = process.env.REACT_APP_ENVIRONMENT.slice(0, 3);

      Cohere.identify(id, {
        displayName: `${firstName} ${lastName}`,
        email: `${email} - ${environment} ${process.env.REACT_APP_VERSION}`,
      });
    }
  }, [meData]);

  const authStageMap = {
    pending: null,
    rejected: (
      <Route
        {...rest}
        render={({ location, match }) => (
          <Redirect
            to={{
              pathname: `/${match.params.operatorSlug}/new`,
              search: history.location.search,
              state: { from: location },
            }}
          />
        )}
      />
    ),
    authenticated: <Route {...rest} render={() => children} />,
  };

  return authStageMap[authStage];
}

export default PrivateRoute;
