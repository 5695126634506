import React from "react";
import moment from "moment";

import { Box, Typography } from "@mui/material";

import { Request, Trip } from "../../types";
import { currency } from "../../utils/helpers";
import { alabaster } from "../../design-system/colors";
import { confirmationNumberFromRequest } from "../../globals/utils/helpers";
import { currencyConverter } from "../../utils/currency";

type TripListItemDesktopProps = {
  trip: Trip;
  request: Request;
  isCompanyInvoice: boolean;
};

function TripListItemDesktop(props: TripListItemDesktopProps) {
  const { trip, request, isCompanyInvoice } = props;

  const { stops, contact, totalAmount, amountDue, temporaryPassenger } = trip;

  const firstStopDateTime = moment
    .utc(stops[0].dateTime)
    .format("MM/DD/YY h:mm a");

  const totalPaid = currencyConverter.toDollars(
    currencyConverter.toCents(totalAmount) -
      currencyConverter.toCents(amountDue)
  );

  const tripContactName = temporaryPassenger?.name
    ? temporaryPassenger.name
    : `${contact.firstName} ${contact.lastName}`;

  return (
    <Box
      display="flex"
      bgcolor={alabaster}
      flexDirection="row"
      flex="1"
      p={2}
      alignItems="center"
    >
      {/* Trip Information */}
      <Box display="flex" flex=".25" flexDirection="column">
        <Box ml="12px">
          <Typography variant="subtitle2">
            {confirmationNumberFromRequest(request, trip)}
          </Typography>
          <Typography variant="body2">{firstStopDateTime}</Typography>
          <Typography variant="body2">{tripContactName}</Typography>
        </Box>
      </Box>

      {/* IF company invoice, Empty billing contact column */}
      {isCompanyInvoice && (
        <Box display="flex" flex=".25" flexDirection="column" />
      )}

      {/* Pick Up Address */}
      <Box display="flex" flex=".23" flexDirection="column">
        <Box mr={1}>{stops[0].location}</Box>
      </Box>

      {/* Amount */}
      <Box display="flex" flex=".2">
        <Typography variant="body2">{currency(totalAmount)}</Typography>
      </Box>

      {/* Paid */}
      <Box display="flex" flex=".14">
        <Typography variant="body2">{currency(totalPaid)}</Typography>
      </Box>

      {/* Amount Due */}
      <Box display="flex" flex=".13" justifyContent="flex-end">
        <Typography variant="body2">{currency(amountDue)}</Typography>
      </Box>
    </Box>
  );
}

export default TripListItemDesktop;
