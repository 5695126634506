/**
 * Notes on Stripe's CardElement
 * - The Stripe Card Element does not allow us to read the card number directly. Instead, we must use the StripeCardElementChangeEvent returned by the CardElement.
 *
 * Quick Summary of StripeCardElementChangeEvent object
 * - 'empty' - returns true, if there are no values in the card element
 * - 'complete' - returns true if the card number, CVC AND zipcode are filled with valid values
 * - 'error' - returns an error object with a message if an error is detected on the card number, CVC OR zipcode values. otherwise, returns undefined.
 */
import { Box, Typography, useTheme } from "@mui/material";
import { CardElement, CardElementProps } from "@stripe/react-stripe-js";
import React from "react";
import { black, errorRed, grayDark } from "../../design-system/colors";

type MoovsStripeCardElementProps = {
  errorMessage: string; // if falsy, then there's no error
  onCreditCardChange: CardElementProps["onChange"];
  onReady?: CardElementProps["onReady"];
  onFocus?: CardElementProps["onFocus"];
};

function MoovsStripeCardElement(props: MoovsStripeCardElementProps) {
  const { errorMessage, onCreditCardChange, onReady, onFocus } = props;

  const theme = useTheme();

  const styles = {
    cardElementContainer: {
      alignItems: "center",
      display: "flex",
      height: 56,
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: 1,
    },
    cardElementContainerError: {
      border: `1px solid ${errorRed}`,
    },
    cardElement: {
      color: black,
      fontSize: 16,
      fontFamily: "Poppins",
      width: "100%",
      height: "100%",
      padding: "18.5px 14px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  };
  // hooks
  const creditCardStyleOptions = {
    style: {
      base: {
        fontFamily: "Poppins",
        color: errorMessage ? errorRed : grayDark,
        "::placeholder": {
          color: errorMessage ? errorRed : grayDark,
        },
      },
      invalid: {
        color: errorMessage ? errorRed : black,
      },
    },
  };

  return (
    <Box my={1}>
      <Box
        sx={{
          ...styles.cardElementContainer,
          ...(errorMessage && styles.cardElementContainerError),
          "& .StripeElement": {
            color: black,
            fontSize: 16,
            fontFamily: "Poppins",
            width: "100%",
            height: "100%",
            padding: "18.5px 14px",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          },
        }}
      >
        <CardElement
          options={creditCardStyleOptions}
          onChange={onCreditCardChange}
          onReady={onReady}
          onFocus={onFocus}
        />
      </Box>
      <Box ml="14px">
        <Typography color="error" variant="caption">
          {errorMessage}
        </Typography>
      </Box>
    </Box>
  );
}

export default MoovsStripeCardElement;
