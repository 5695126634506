import React from "react";

type RoundTripIconProps = {
  color?: string;
};

const RoundTripIcon = (props: RoundTripIconProps) => {
  const { color } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.78947 12V16.924C4.78947 17.7398 5.08891 18.5222 5.62191 19.0991C6.15491 19.6759 6.87781 20 7.63158 20H16.1579M4.78947 12L8.57895 16M4.78947 12L1 16M19.2105 12L19.2105 7.07598C19.2105 6.26018 18.9111 5.47779 18.3781 4.90093C17.8451 4.32408 17.1222 4 16.3684 4L7.84211 4M19.2105 12L15.4211 8M19.2105 12L23 8"
        stroke={color || "#B3B3B3"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RoundTripIcon;
