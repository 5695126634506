import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { granite, white } from "design-system/colors";
import { NumberFormatNatural } from "design-system/components/inputs/NumberFormat";
import {
  CalendarIcon,
  NoteIcon,
  PassengerCountIcon,
} from "design-system/icons";
import { Stop } from "types";
import MoovsDialog from "components/globals/MoovsDialog";
import { RHFDateTimePicker } from "components/react-hook-form/date-time/RHFDateTimePicker";
import { RHFTextField } from "components/react-hook-form/text-field/RHFTextField";

const MAX_GROUP_SIZE = 999;
const MIN_GROUP_SIZE = 1;

type AdditionalStopInfoDialogProps = {
  stopLabel: string;
  open: boolean;
  onClose: () => void;
  stop: Partial<Stop>;
  canOnlyUpdateStopDate?: boolean;
  clearable: boolean;
  onSave: (stop: Partial<Stop>) => void;
  formNameKey?: string;
  shouldHideDateTimePicker?: boolean;
};

const errorSchema = yup.object({
  note: yup.string().max(120, "Max character limit of 120 reached"),
});

function AdditionalStopInfoDialog(props: AdditionalStopInfoDialogProps) {
  const {
    stopLabel,
    open,
    onClose,
    stop,
    canOnlyUpdateStopDate = false,
    clearable,
    onSave,
    formNameKey,
    shouldHideDateTimePicker,
  } = props;

  const formId = `additional-stop-info-dialog-${formNameKey || stop.stopIndex}`;

  const methods = useForm({
    defaultValues: {
      dateTime: "",
      groupSize: "",
      note: "",
    },
    mode: "onSubmit",
    resolver: yupResolver(errorSchema),
  });

  // event handlers
  const handleSaveClick = (data) => {
    onClose();
    onSave(data);
  };

  // effects
  useEffect(() => {
    // on open reset to saved values
    if (open) {
      methods.reset();
    }
  }, [methods, open]);

  return (
    <MoovsDialog
      hideTopBorder
      hideLoadingIndicator
      open={open}
      onClose={onClose}
      dialogTitle={`Additional ${stopLabel} Info`}
      size="xs"
      acceptButtonText="Save"
      acceptDisabled={!!methods.formState.errors.note}
      submitFormId={formId}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={(e) => {
            // prevent submit from bubbling up to parent form
            e.stopPropagation();
            methods.handleSubmit(handleSaveClick)(e);
          }}
          id={formId}
        >
          {stop.stopIndex !== 1 && !shouldHideDateTimePicker && (
            <Box
              flex="1"
              mb={1}
              bgcolor={white}
              display="flex"
              flexDirection="row"
            >
              <Box
                mr={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CalendarIcon color={granite} />
              </Box>
              {/* date time */}
              <RHFDateTimePicker
                name="dateTime"
                clearable={clearable}
                hideTabs
                inputFormat="MMM Do YYYY, h:mm a"
                label={`${stopLabel} Date & Time`}
                renderInputProps={{
                  name: "pickupDateTime",
                }}
              />
            </Box>
          )}

          {!canOnlyUpdateStopDate && (
            <>
              <Box mb={1} display="flex" flexDirection="row">
                <Box
                  mr={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <PassengerCountIcon color={granite} />
                </Box>
                <RHFTextField
                  name="groupSize"
                  fullWidth
                  variant="outlined"
                  label={`${stopLabel} Passenger Count`}
                  style={{ backgroundColor: white }}
                  InputProps={{
                    inputComponent: NumberFormatNatural as any,
                    inputProps: {
                      isAllowed: ({ floatValue }) =>
                        (floatValue >= MIN_GROUP_SIZE &&
                          floatValue <= MAX_GROUP_SIZE) ||
                        floatValue === undefined,
                    },
                  }}
                />
              </Box>
              <Box mb={3} display="flex" flexDirection="row">
                <Box
                  mr={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <NoteIcon color={granite} />
                </Box>
                <RHFTextField
                  name="note"
                  fullWidth
                  multiline
                  variant="outlined"
                  label={`${stopLabel} Note`}
                  sx={{ backgroundColor: white }}
                />
              </Box>
            </>
          )}
        </form>
      </FormProvider>
    </MoovsDialog>
  );
}

export default AdditionalStopInfoDialog;
