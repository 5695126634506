import React from "react";

type BluetoothIconProps = {
  color?: string;
  size: "small";
};

const BluetoothIcon = (props: BluetoothIconProps) => {
  const { color } = props;

  // size === small
  return (
    <svg
      width="11"
      height="16"
      viewBox="0 0 11 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 4.66671L9.5 11.3334L5.5 14.6667V1.33337L9.5 4.66671L1.5 11.3334"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BluetoothIcon;
