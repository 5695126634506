import React from "react";

import { LoadingButton } from "@mui/lab";

import { white } from "design-system/colors";
import { LockIcon } from "design-system/icons";

type SubmitButtonProps = {
  formId: string;
  isSubmitting: boolean;
  isCreateQuote?: boolean;
  disabled?: boolean;
  autoChargeButtonText?: string;
};

function SubmitButton(props: SubmitButtonProps) {
  const {
    formId,
    isCreateQuote = false,
    isSubmitting,
    disabled,
    autoChargeButtonText,
  } = props;

  return (
    <LoadingButton
      {...(disabled && { disabled })}
      fullWidth
      disableRipple
      variant="contained"
      color="primary"
      loading={isSubmitting}
      {...(!isCreateQuote && {
        startIcon: !isSubmitting && <LockIcon color={white} />,
      })}
      type="submit"
      form={formId}
      // WORKAROUND: MUI Button primary color is overridding the icon color
      sx={{
        "& .MuiButton-startIcon path": {
          stroke: white,
        },
      }}
    >
      {isCreateQuote ? "Send Request" : autoChargeButtonText || "Reserve"}
    </LoadingButton>
  );
}

export default SubmitButton;
