import React from "react";
import { Control, SetFieldValue, UseFormWatch } from "react-hook-form";

import { Box, Typography } from "@mui/material";

import { useAuth, useCurrentUser } from "../../../../../globals/hooks";
import BookingContactForm from "./components/BookingContactForm";
import AuthenticatedUserBlock from "./components/AuthenticatedUserBlock";
import { CreateQuoteFormState } from "../../../../../pages/new/steps/confirm/components/CreateQuoteForm/form/schemaValidation";
import { getIsNetflixLogin } from "utils/auth/isNetflixLogin";

type BookingContactBlockProps = {
  control: Control<any>;
  watch: UseFormWatch<any>;
  trackingFrom?: "reserve" | "quoteRequest";
  setValue: SetFieldValue<CreateQuoteFormState>;
  getValues: any;
};

function BookingContactBlock(props: BookingContactBlockProps) {
  const { control, watch, trackingFrom, setValue, getValues } = props;

  const currentUser = useCurrentUser();
  const { authStage } = useAuth();
  const isNetflixLogin = getIsNetflixLogin();
  const shouldShowAuthUserBlock =
    (authStage === "authenticated" && !isNetflixLogin) ||
    (isNetflixLogin && currentUser.mobilePhone);
  const shouldShowUnAuthBlock =
    authStage === "rejected" || (isNetflixLogin && !currentUser.mobilePhone);

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h3">Booking Contact</Typography>
      {shouldShowAuthUserBlock && <AuthenticatedUserBlock />}
      {shouldShowUnAuthBlock && (
        <BookingContactForm
          control={control}
          watch={watch}
          trackingFrom={trackingFrom}
          setValue={setValue}
          getValues={getValues}
        />
      )}
    </Box>
  );
}

export default BookingContactBlock;
