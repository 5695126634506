import React from "react";

import { Box, Typography, Grid } from "@mui/material";

import AccountInfoBlock from "../../components/profile/AccountInfoBlock";
import CardsOnFileBlock from "../../components/profile/CardsOnFileBlock";
import LoginLogoutBlock from "../../components/auth/LoginLogoutBlock/LoginLogoutBlock";
import { useCurrentUser, useScreenSize } from "../../globals/hooks";

function ProfilePage() {
  const user = useCurrentUser();
  const { isMobileView } = useScreenSize();

  return (
    <Box display="flex" justifyContent="center" flex="1">
      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        maxWidth="1140px"
        px={isMobileView ? 2 : 0}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          my={2}
        >
          <Typography variant="h1" style={{ fontWeight: 600 }}>
            Profile
          </Typography>
          <LoginLogoutBlock />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <AccountInfoBlock user={user} />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardsOnFileBlock user={user} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ProfilePage;
