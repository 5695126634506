import React from "react";
import startCase from "lodash/startCase";

import { Box, Typography, Card } from "@mui/material";

import { orange } from "../../design-system/colors";
import StarIcon from "../../design-system/icons/info/StarIcon";
import { PaymentMethod } from "../../types";

type CardListItemMobileProps = { paymentMethod: PaymentMethod };

function CardListItemMobile(props: CardListItemMobileProps) {
  const { paymentMethod } = props;
  const { card, isPrimary } = paymentMethod;
  const { expMonth, expYear, last4, brand } = card;

  return (
    <Card variant="outlined">
      <Box
        py={2}
        px={2}
        minHeight="170px"
        display="flex"
        flexDirection="column"
        width="100%"
        position="relative"
      >
        {/* brand */}
        <Typography variant="overline">brand</Typography>
        <Typography variant="body2">{startCase(brand)}</Typography>

        {/* card number */}
        <Box mt={1.5}>
          <Typography variant="overline">card number</Typography>
          <Typography variant="body2">{`****-${last4}`}</Typography>
        </Box>
        {/* expiration date */}
        <Box mt={1.5}>
          <Typography variant="overline">expiration date</Typography>
          <Typography variant="body2">{`${expMonth}/${expYear}`}</Typography>
        </Box>
        <Box position="absolute" top="16px" right="16px">
          {isPrimary && <StarIcon size="small" color={orange} />}
        </Box>
      </Box>
    </Card>
  );
}

export default CardListItemMobile;
