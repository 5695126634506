import React from "react";

import { Box, Typography } from "@mui/material";

import { useCurrentUser } from "globals/hooks";

function AuthenticatedUserBlock() {
  const { email, firstName, lastName, mobilePhone } = useCurrentUser() || {};

  return (
    <Box mb={1}>
      {/* name */}
      <Box mt={2}>
        <Typography variant="overline">Name</Typography>
        <Typography variant="body2">{`${firstName} ${lastName}`}</Typography>
      </Box>
      {/* email */}
      <Box mt={2}>
        <Typography variant="overline">Email</Typography>
        <Typography variant="body2">{email}</Typography>
      </Box>
      {/* mobile phone */}
      <Box mt={2}>
        <Typography variant="overline">Mobile Phone</Typography>
        <Typography variant="body2">{mobilePhone}</Typography>
      </Box>
    </Box>
  );
}

export default AuthenticatedUserBlock;
