import { CreateRequestState } from "pages/new/context/initialState";
import { CreateReservationFormState } from "./schemaValidation";

export const getCreateReservationFormDefaultValues = (
  bookingContact: CreateRequestState["bookingContact"]
): CreateReservationFormState => {
  return {
    bookingContact: {
      firstName: bookingContact.firstName || "",
      lastName: bookingContact.lastName || "",
      email: bookingContact.email || "",

      // store phoneFields as object instead of flattended into booking contact
      // so that they can be passed into RHF international phone number format component
      phoneFields: {
        mobilePhone: bookingContact.mobilePhone || "",
        phoneCountryCode: bookingContact.phoneCountryCode || "",
        phoneCountryDialCode: bookingContact.phoneCountryDialCode || "",
        phoneCountryFormat: bookingContact.phoneCountryFormat || "",
        phoneCountryName: bookingContact.phoneCountryName || "",
      },
    },
    paymentMethod: {
      cardholderName: "",
      creditCardError: null,
    },
    stripePaymentMethodId: null,
    isTermsAccepted: false,
    driverGratuityPctCustomerInput: null,
    pickUpVariantSelected: null,
    printedName: "",
    autoPaymentAmt: null,
    autoPaymentType: null,
    promoCodeCustomerInput: null,
    additonalItemsByTrip: [
      {
        childSeats: {
          boosterSeatAmt: null,
          boosterSeatQuantity: null,
          forwardFacingSeatAmt: null,
          forwardFacingSeatQuantity: null,
          rearFacingSeatAmt: null,
          rearFacingSeatQuantity: null,
        },
      },
    ],
  };
};
