import { useMemo } from "react";
import some from "lodash/some";

import { PricelessBookingTarget, Vehicle, Contact } from "types";

type UseIsPricelessBookingParams = {
  vehicle: Vehicle | undefined;
  authenticatedUser?: Contact | null;
};

export const useIsPricelessBooking = (
  params: UseIsPricelessBookingParams
): boolean => {
  const { vehicle, authenticatedUser } = params;

  return useMemo(() => {
    if (!vehicle) return false;

    const {
      pricelessBookingEnabled,
      pricelessBookingCompanies,
      pricelessBookingContacts,
      pricelessBookingTarget,
    } = vehicle.settings;

    if (!pricelessBookingEnabled) return false;

    if (pricelessBookingTarget === PricelessBookingTarget.All) return true;

    // target specific contacts
    // user needs to be authenticated to check
    if (!authenticatedUser) return false;

    return (
      some(
        pricelessBookingContacts,
        (contact) => contact.id === authenticatedUser.id
      ) ||
      some(
        pricelessBookingCompanies,
        (company) => company.id === authenticatedUser.company?.id
      )
    );
  }, [vehicle, authenticatedUser]);
};
