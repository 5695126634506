/**
 * InfoStepFormProvider
 * - The 'linkedOutboundTripStopId' property is added when return trip stops are created. This allows for changes in outbound trip stops to reflect in return trip stops.
 *   if a stop is created in the return trip, no outbound trip stop is created, and therefore, it is not linked to a stop. This is as intended.
 *
 * Error Schema/Validation with Yup
 * - 'this.parent' - provides the form values directly of the parent object relative to the field being tested
 * - 'this.from' - provides entire schema and form values
 *
 * Temporarily Removed Features
 * - not blurring on drag bc not currently intercepting the autocomplete refs and react-hook-form does not provide a "clearFocus" method.
 *   intercept the ref if we wish to add this back in
 *     @link https://www.react-hook-form.com/faqs/#Howtosharerefusage
 *
 * - validation does not use MUI required prop, so asterisk does not show up next to fields.
 *
 * Caveats with using React Hook Form
 * - 'watch' provided by useForm/useFormContext does not trigger re-render when watching an array
 *   ex: watch(trip.stops) will not re-render if trip.stops.0.location is updated
 *   when watching an array, use 'useWatch' instead
 *
 * - Be careful when searching documentation / stackoverflow - there are breaking changes between v5, v6, and v7 of react-hook-form
 *
 * - defaultValues are cached on the first render, so must be reset if you want to immediately use them
 *   @link https://react-hook-form.com/api/useform
 */

import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { infoPageErrorSchema } from "./errorSchema";
import { useLastStopDateTimeBasedOnDuration } from "./hooks";
import { OrderType } from "types";
import { CreateTripState } from "pages/new/context/initialState";
import { useCreateRequestContext } from "pages/new/context/useCreateRequestContext";

interface InfoStepFormProviderProps {
  children: React.ReactNode;
}

export type InfoStepState = {
  orderType: OrderType;
  trip: CreateTripState;
  returnTrip: CreateTripState;
};

const InfoStepFormProvider = (props: InfoStepFormProviderProps) => {
  const { children } = props;

  // hooks
  const [{ orderType, trip, returnTrip }] = useCreateRequestContext();

  const initialState: InfoStepState = {
    orderType,
    trip,
    returnTrip,
  };

  const methods = useForm<InfoStepState>({
    defaultValues: initialState,
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(infoPageErrorSchema),
  });

  return (
    <FormProvider {...methods}>
      <InfoPageFormSideEffects>{children}</InfoPageFormSideEffects>
    </FormProvider>
  );
};

// this component is wrapped by FormProvider, allowing access to useFormContext in contained hooks
const InfoPageFormSideEffects = ({ children }) => {
  useLastStopDateTimeBasedOnDuration("trip");
  useLastStopDateTimeBasedOnDuration("returnTrip");

  return children;
};

export { InfoStepFormProvider };
