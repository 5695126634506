import React from "react";

import { useOperator } from "globals/hooks";
import { TextRecipientEnum } from "types";

import TripBellOnUpdate from "./components/TripBellOnUpdate";
import TripBellOnCreate from "./components/TripBellOnCreate";

type TripNotificationBellProps = {
  mode: "create" | "update";
  tripId?: string;
  statusUpdateTextTo?: TextRecipientEnum;
  hasReturnTrip?: boolean;
  isReturnTrip?: boolean;
};

function TripNotificationBell(props: TripNotificationBellProps) {
  const { tripId, statusUpdateTextTo, mode, hasReturnTrip, isReturnTrip } =
    props;

  const isInUpdateMode = mode === "update";
  const {
    operator: {
      driverStatusUpdateTextsEnabled,
      settings: { memberChangedStatusTextEnabled },
    },
  } = useOperator();

  const shouldNotShowTripNotification =
    !driverStatusUpdateTextsEnabled && !memberChangedStatusTextEnabled;

  if (shouldNotShowTripNotification || isReturnTrip) return null;

  return (
    <>
      {isInUpdateMode ? (
        <TripBellOnUpdate
          tripId={tripId}
          statusUpdateTextTo={statusUpdateTextTo}
        />
      ) : (
        <TripBellOnCreate hasReturnTrip={hasReturnTrip} />
      )}
    </>
  );
}

export default TripNotificationBell;
