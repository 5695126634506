import React from "react";
import size from "lodash/size";
import ImageGallery from "react-image-gallery";
import "../../../../../VehicleImageCarousel.css";
import "../../../../../ImageGallery.css";

import { Box, useTheme } from "@mui/material";

import MissingImage from "design-system/images/MissingImage.png";
import { VehiclePhoto } from "types";
import { useScreenSize } from "globals/hooks";
import { LeftNav, RightNav } from "./components";

type VehicleImageCarouselProps = {
  photos: VehiclePhoto[];
  name: string;
};

function VehicleImageCarousel(props: VehicleImageCarouselProps) {
  const { name, photos } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const theme = useTheme();

  document.documentElement.style.setProperty(
    "--border-color",
    theme.palette.primary.main
  );

  return (
    <Box mb={4} width={isMobileView ? "100%" : "400px"} display="flex">
      <ImageGallery
        lazyLoad
        showNav
        showThumbnails={false}
        showFullscreenButton={false}
        showPlayButton={false}
        onErrorImageURL={MissingImage}
        showBullets={size(photos) > 1 ? true : false}
        items={photos.map((photo: VehiclePhoto) => ({
          original: photo.url,
          originalTitle: name,
          originalAlt: name,
        }))}
        renderLeftNav={(onClick, disabled) => {
          return <LeftNav onClick={onClick} disabled={disabled} />;
        }}
        renderRightNav={(onClick, disabled) => {
          return <RightNav onClick={onClick} disabled={disabled} />;
        }}
      />
    </Box>
  );
}

export default VehicleImageCarousel;
