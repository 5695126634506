import React from "react";

import { Box, Divider } from "@mui/material";

import DurationDisplay from "./DurationDisplay";
import PassengerCountDisplay from "./PassengerCountDisplay";
import HeaderConfirmationNumber from "./HeaderConfirmationNumber";
import { useScreenSize } from "../../../../../../globals/hooks";
import {
  RoundTripVariant,
  TextRecipientEnum,
  TripCategory,
} from "../../../../../../types";
import TripTypeLabel from "./TripTypeLabel";
import TripNotificationBell from "./TripNotificationBell/TripNotificationBell";

type TripItineraryHeaderProps = {
  totalDuration: number;
  estimatedDuration: number;
  useTotalDuration: boolean;
  totalGroupSize: number;
  mode: "create" | "update";
  confirmationNumber?: string;
  tripCategory?: TripCategory;
  roundTripVariant?: RoundTripVariant;
  tripId?: string;
  statusUpdateTextTo?: TextRecipientEnum;
  hasReturnTrip?: boolean;
  isReturnTrip?: boolean;
};

function TripItineraryHeader(props: TripItineraryHeaderProps) {
  const {
    totalDuration,
    estimatedDuration,
    useTotalDuration,
    totalGroupSize,
    confirmationNumber,
    tripCategory,
    roundTripVariant,
    tripId,
    statusUpdateTextTo,
    mode,
    hasReturnTrip,
    isReturnTrip,
  } = props;

  const { isMobileView } = useScreenSize();

  return (
    <Box
      display="flex"
      flexDirection={isMobileView ? "column" : "row"}
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <TripTypeLabel
          tripCategory={tripCategory}
          roundTripVariant={roundTripVariant}
        />
        {isMobileView && (
          <TripNotificationBell
            tripId={tripId}
            statusUpdateTextTo={statusUpdateTextTo}
            mode={mode}
            hasReturnTrip={hasReturnTrip}
            isReturnTrip={isReturnTrip}
          />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        {...(isMobileView && { mt: "18px" })}
      >
        {!isMobileView && (
          <TripNotificationBell
            tripId={tripId}
            statusUpdateTextTo={statusUpdateTextTo}
            mode={mode}
            hasReturnTrip={hasReturnTrip}
            isReturnTrip={isReturnTrip}
          />
        )}

        <HeaderConfirmationNumber confirmationNumber={confirmationNumber} />

        {/* duration */}
        <DurationDisplay
          totalDuration={totalDuration}
          estimatedDuration={estimatedDuration}
          isEstimated={!useTotalDuration}
        />

        <Divider
          flexItem
          orientation="vertical"
          style={{ marginLeft: "16px", marginRight: "16px" }}
        />

        {/* passengers */}
        <PassengerCountDisplay passengerCount={totalGroupSize} />
      </Box>
    </Box>
  );
}

export default TripItineraryHeader;
