import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import first from "lodash/first";

import { Box, Button } from "@mui/material";

import {
  useAnalytics,
  useGoogleTracking,
  useOperatorSlug,
  useQueryParams,
  useScreenSize,
  useScrollToTop,
} from "globals/hooks";
import { TripCategory, Vehicle } from "types";
import { useCreateRequestContext } from "../../context/useCreateRequestContext";
import { useDudaTrip } from "../../hooks/useDudaTrip";
import { CreateRequestReduxActionTypes } from "../../context/reducer/types";
import StatusStepper from "../components/StatusStepper";
import WhiteLabelVehicleList from "../info/components/vehicle/WhiteLabelVehicleList";
import VehicleList from "../info/components/vehicle/VehicleList";
import { useBookingWidgetTrip } from "pages/new/hooks/useBookingWidgetTrip";
import RequestSummary from "../components/RequestSummary";

type VehicleStepProps = {
  isWhiteLabel?: boolean;
};

function VehicleStep(props: VehicleStepProps) {
  const { isWhiteLabel } = props;

  // hooks
  const { track } = useAnalytics();
  const { isTabletView } = useScreenSize();
  const operatorSlug = useOperatorSlug();
  const history = useHistory();
  const queryParams = useQueryParams();
  useScrollToTop({ scrollOnMount: true });
  const { isDudaTrip } = useDudaTrip(); // TODO: deprecate duda widget 11/2/2022
  const { isBookingWidgetTrip } = useBookingWidgetTrip();
  const { googleTrack } = useGoogleTracking();

  // form hooks
  const [{ trip, returnTrip }, dispatch] = useCreateRequestContext();

  // derived state
  const { tripCategory, routes } = trip;
  const { vehicle } = first(routes);

  // effects
  useEffect(() => {
    googleTrack("moovs_page_view_vehicle");
  }, [googleTrack]);

  useEffect(() => {
    track("bookingTool_chooseVehicleInitiated");
  }, [track]);

  // event handlers
  const handleBackClick = () => {
    track("bookingTool_previousStep");

    if (isDudaTrip || isBookingWidgetTrip) {
      isDudaTrip ? queryParams.delete("dudaTrip") : queryParams.delete("trip");
      history.push({
        ...history.location,
        pathname: `/${operatorSlug}/new/info`,
        search: queryParams.toString(),
      });
    } else {
      history.goBack();
    }
  };

  const handleVehicleSelect = (vehicle: Vehicle) => () => {
    dispatch({
      type: CreateRequestReduxActionTypes.UpdateVehiclePage,
      payload: vehicle,
    });

    history.push({
      ...history.location,
      pathname: isWhiteLabel
        ? `/${operatorSlug}/booking/confirm`
        : `/${operatorSlug}/new/confirm`,
    });

    track(
      isWhiteLabel ? "whiteLabel_chooseVehicle" : "bookingTool_chooseVehicle",
      {
        type: vehicle.baseRateAutomation
          ? "BRA"
          : vehicle.settings?.pricelessBookingEnabled
          ? "Reservation Request"
          : "Quote Request",
      }
    );
  };

  return (
    <Box width="100%" mb={8}>
      <StatusStepper activeStep={1} hideLogin={isWhiteLabel} />

      <RequestSummary
        pickUpDateTime={trip.stops[0].dateTime}
        pickUpAddress={trip.stops[0].location}
        dropOffAddress={
          tripCategory === TripCategory.RoundTrip ||
          tripCategory === TripCategory.OneWay
            ? trip.stops[trip.stops.length - 1].location
            : null
        }
        passengerCount={trip.totalGroupSize}
        returnDateTime={
          tripCategory === TripCategory.RoundTrip
            ? returnTrip.stops[0].dateTime
            : null
        }
        tripDuration={
          tripCategory === TripCategory.Hourly ? trip.totalDuration : null
        }
        onEditClick={handleBackClick}
      />

      <Box display="flex" flexDirection={isTabletView ? "column" : "row"}>
        {/* left panel */}
        <Box display="flex" flex="3" flexDirection="column">
          {/* vehicle */}
          {isWhiteLabel ? (
            <WhiteLabelVehicleList
              totalGroupSize={trip.totalGroupSize}
              stops={trip.stops}
              vehicleId={vehicle?.id}
              onVehicleSelect={handleVehicleSelect}
            />
          ) : (
            <VehicleList
              trip={trip}
              returnTrip={returnTrip}
              vehicleId={vehicle?.id}
              tripCategory={tripCategory}
              onVehicleSelect={handleVehicleSelect}
            />
          )}

          {/* stepper buttons */}
          <Box
            mt={2}
            mb={6}
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Button color="primary" onClick={handleBackClick}>
              Previous Step
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default VehicleStep;
