/**
 * @file HeaderBlock.tsx
 * Header of driver dispatch view
 *
 * components:
 *  HeaderBlock
 */

import React from "react";
import moment from "moment-timezone";
import first from "lodash/first";

import { Box, Divider, Typography } from "@mui/material";
import IconInfoSegment from "design-system/components/IconInfoSegment";
import {
  ClockIcon,
  EstimationIcon,
  TripIcon,
  UserIcon,
} from "design-system/icons";
import { grayDark, white } from "design-system/colors";
import { convertMinutesToHoursMinutes } from "utils/helpers";
import { Trip } from "types";
import { useOperator, useScreenSize } from "globals/hooks";
import TripStatus from "./TripStatus";
import FlightChangeDisplay from "components/globals/FlightChangeDisplay";

type HeaderBlockProps = {
  trip: Trip;
  tripType: string;
  statusSlug: string;
};

function HeaderBlock(props: HeaderBlockProps) {
  const { trip, tripType, statusSlug } = props;

  const pickUpStop = first(trip.stops);
  const pickUpOriginalTime = moment
    .utc(pickUpStop?.originalDateTime)
    .format("h:mm A");
  const pickUpDate = moment.utc(pickUpStop?.dateTime).format("M/DD/YY");
  const pickUpTime = moment.utc(pickUpStop?.dateTime).format("h:mm A");
  const isDateTimeSame =
    moment
      .utc(pickUpStop?.originalDateTime)
      .diff(moment.utc(pickUpStop?.dateTime), "minutes") === 0;

  // hooks
  const { isMobileView } = useScreenSize();
  const { isElSegundoConnect } = useOperator();

  return (
    <Box bgcolor={white} pb={3} pt={1} px={2}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" flexDirection="row">
          <Box mr={2}>
            <Typography variant="overline" style={{ color: grayDark }}>
              Trip Date
            </Typography>
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              {pickUpDate}
            </Typography>
          </Box>
          <Box>
            <Typography variant="overline" style={{ color: grayDark }}>
              Time
            </Typography>
            <Box>
              {pickUpStop?.flightOffset && !isDateTimeSame ? (
                <>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: "bold",
                      textDecorationLine: "line-through",
                      color: grayDark,
                    }}
                  >
                    {pickUpOriginalTime}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold", marginRight: 5 }}
                    >
                      {pickUpTime}
                    </Typography>
                    <FlightChangeDisplay
                      flightOffset={pickUpStop?.flightOffset}
                      originalDateTime={pickUpStop?.originalDateTime}
                      iconSize="small"
                    />
                  </Box>
                </>
              ) : (
                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                  {pickUpTime}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>

        <Box>
          {!isElSegundoConnect && <TripStatus statusSlug={statusSlug} />}
        </Box>
      </Box>

      <Divider />

      <Box
        mt={2}
        display="flex"
        flexDirection="row"
        flexWrap={isMobileView ? "wrap" : "nowrap"}
      >
        <Box mr={3}>
          <IconInfoSegment
            label="Passenger Count"
            value={trip.totalGroupSize}
            icon={UserIcon}
          />
        </Box>
        <Box mr={3}>
          <IconInfoSegment label="Trip Type" value={tripType} icon={TripIcon} />
        </Box>
        <Box mr={3}>
          <IconInfoSegment
            label={
              trip.useTotalDuration ? "total duration" : "estimated duration"
            }
            value={
              trip.useTotalDuration ? (
                convertMinutesToHoursMinutes(trip.totalDuration)
              ) : (
                <Box display="flex" alignItems="center">
                  <Box mr={0.5}>
                    {convertMinutesToHoursMinutes(trip.estimatedDuration)}
                  </Box>
                  <EstimationIcon size="small" />
                </Box>
              )
            }
            icon={ClockIcon}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default HeaderBlock;
