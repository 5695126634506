import React from "react";
import moment from "moment";

import { Box, Button, Typography, useTheme } from "@mui/material";
import { ToolbarComponentProps } from "@mui/lab/internal/pickers/typings/BasePicker";

import { grayDark } from "design-system/colors";
import { ChevronLeftIcon, ChevronRightIcon } from "../../design-system/icons";

function MoovsDateTimePickerToobar(props: ToolbarComponentProps<unknown>) {
  const isLeftChevronDisabled = props.openView === "day";
  const isRightChevronDisabled = !props.date || props.openView === "minutes";

  const dateMoment = props.date && moment(props.date);

  // hooks
  const theme = useTheme();
  return (
    <Box padding={"10px"}>
      <Typography
        color={grayDark}
        style={{ textAlign: "left", fontSize: "14px", marginBottom: "5px" }}
      >
        {props.toolbarTitle}
      </Typography>
      <Typography
        style={{
          textAlign: "center",
          fontSize: "24px",
          paddingTop: "5px",
          whiteSpace: "pre-wrap",
        }}
      >
        {dateMoment ? dateMoment.format("dddd MMM Do") : "\n\n"}
      </Typography>
      {dateMoment && (
        <Typography
          style={{
            textAlign: "center",
            fontSize: "48px",
          }}
        >
          {dateMoment.format("h:mm A")}
        </Typography>
      )}
      <Box
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          marginTop: "10px",
        }}
      >
        <Button
          fullWidth
          size="large"
          color="primary"
          onClick={() =>
            props.setOpenView(props.openView === "hours" ? "day" : "hours")
          }
          disabled={isLeftChevronDisabled}
          startIcon={
            <ChevronLeftIcon
              color={!isLeftChevronDisabled && theme.palette.primary.main}
            />
          }
        />
        <Button
          fullWidth
          size="large"
          color="primary"
          onClick={() =>
            props.setOpenView(props.openView === "hours" ? "minutes" : "hours")
          }
          disabled={isRightChevronDisabled}
          startIcon={
            <ChevronRightIcon
              color={!isRightChevronDisabled && theme.palette.primary.main}
            />
          }
        />
      </Box>
    </Box>
  );
}

export default MoovsDateTimePickerToobar;
