import { useOperator } from "globals/hooks";

function useIntroTermsAndConditions() {
  // hooks
  const { operator } = useOperator();

  const introAgreement = `This Service Terms and Agreement for Transportation Reservations (the "Agreement") is by and between ${operator.name} and you (either individually or on behalf of any entity whom you are representing)("Client" or "you")`;
  const introAgreement2 =
    "BY SIGNING ELECTRONICALLY AND MAKING A RESERVATION, UTILIZING THE TRANSPORTATION SERVICES, YOU ARE SIGNIFYING YOUR ACCEPTANCE OF THIS AGREEMENT YOU REPRESENT AND WARRANT THAT (A) YOU ARE AUTHORIZED TO ENTER THIS AGREEMENT FOR AND ON BEHALF OF YOURSELF AND, IF APPLICABLE, YOUR ORGANIZATION, AND ARE DOING SO, (B) YOU AND YOUR ORGANIZATION CAN LEGALLY ENTER INTO THESE TERMS AND (C) YOU HAVE READ AND UNDERSTAND AND AGREE THAT YOU AND THE ORGANIZATION SHALL BE BOUND BY THE TERMS OF THIS AGREEMENT";
  const introAgreement3 = `You agree that you are fully responsible for the actions of all of you, your passengers, guests and
  invitees (collectively, the "Passengers") aboard the vehicles provided through ${operator.name}. This 
  agreement will be applicable for all trips currently on this reservation and added this reservation`;

  return [introAgreement, introAgreement2, introAgreement3];
}

export default useIntroTermsAndConditions;
