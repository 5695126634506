import { useLocation } from "react-router-dom";

import { useOperator } from "globals/hooks";

export const useIsWhiteLabel = () => {
  const location = useLocation();
  const {
    operator: { nameSlug },
  } = useOperator();

  return { isWhiteLabel: location.pathname.includes(`/${nameSlug}/booking/`) };
};
