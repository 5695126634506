import { useLazyQuery } from "@apollo/client";

import { PaymentRefund, SubPayment } from "types";
import { useAnalytics } from "globals/hooks";
import { LOAD_STRIPE_PAYMENT_RECEIPT_LINK } from "globals/graphql";

type useViewReceiptClickProps = {
  subPayment?: SubPayment;
  refund?: PaymentRefund;
};

export function useViewReceiptClick(props: useViewReceiptClickProps) {
  const { subPayment, refund } = props;

  // hooks
  const { track } = useAnalytics();
  const [getReceiptLink] = useLazyQuery(LOAD_STRIPE_PAYMENT_RECEIPT_LINK);

  // derived state
  const { id: paymentId } = refund ? refund.payment : subPayment.payment;

  const handleViewReceipt = async () => {
    const {
      data: { stripePaymentReceiptLink: receiptUrl },
    } = await getReceiptLink({
      variables: {
        paymentId,
      },
    });

    track("priceSummary_receiptViewed");

    window.open(receiptUrl, "_blank").focus();
  };

  return handleViewReceipt;
}
