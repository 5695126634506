import { useMutation } from "@apollo/client";

import { CREATE_TRIP_REVIEW_MUTATION } from "globals/graphql";
import { useSnackbar } from "./useSnackbar";

export function useCreateTripReview() {
  // hook
  const snackbar = useSnackbar();

  const [createTripReview, { loading: createTripReviewLoading, data }] =
    useMutation(CREATE_TRIP_REVIEW_MUTATION, {
      onError() {
        snackbar.error("Can not submit review.");
      },
    });

  return {
    tripReview: data?.createTripReview?.tripReview,
    createTripReview,
    createTripReviewLoading,
  };
}
