import React from "react";

import { Box, Typography } from "@mui/material";

import { black, granite, grayDark } from "design-system/colors";
import { getVehicleFeatureIcon } from "../utils/getVehicleFeatureIcon";

type FeatureItemProps = {
  name: string;
  hasTripConflict: boolean;
};

function FeatureItem(props: FeatureItemProps) {
  const { name, hasTripConflict } = props;

  const Icon = getVehicleFeatureIcon(name);

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box
        width={16}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Icon size="small" color={hasTripConflict ? grayDark : granite} />
      </Box>
      <Typography
        ml={1.5}
        maxWidth={100}
        variant="caption"
        color={hasTripConflict ? grayDark : black}
      >
        {name}
      </Typography>
    </Box>
  );
}

export default FeatureItem;
