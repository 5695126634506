import { useState } from "react";
import isEqual from "lodash/isEqual";
import first from "lodash/first";
import last from "lodash/last";

import { Stop } from "../../../types";

type StopsTypeProps = {
  location: string;
  dateTime?: string | null;
  stopIndex: number;
  variant?: string;
  airport?;
  airline?;
  flightNumber?;
  trackedFlight?;
}[];

function useSolicitChangesForTripStopsErrors() {
  // state
  const [tripStopsError, setTripError] = useState("");

  const validateSolicitChangesForTripStops = (
    stops: StopsTypeProps,
    updatedTripStops: StopsTypeProps
  ) => {
    const initialStops = stops.map((stop: Stop) => {
      return {
        id: stop.id,
        location: stop.location,
        dateTime: stop.dateTime,
        stopIndex: stop.stopIndex,
        variant: stop?.variant,
        airport: stop?.airport,
        airline: stop?.airline,
        flightNumber: stop?.flightNumber,
        trackedFlight: stop?.trackedFlight,
      };
    });
    const sameStops = isEqual(initialStops, updatedTripStops);
    let error;
    const firstStop = first(updatedTripStops);
    const lastStop = last(updatedTripStops);

    error = sameStops ? "Please make a change to the trip." : error;
    error = !!firstStop?.dateTime
      ? error
      : "Please enter a pick-up date & time";
    error =
      !!firstStop.location || !!firstStop.trackedFlight
        ? error
        : "Please enter pick-up location";
    error =
      !!lastStop.location || !!lastStop.trackedFlight
        ? error
        : "Please enter drop-off location";

    setTripError(error);

    return !!error;
  };

  const setErrorToInitialState = () => setTripError("");

  return {
    tripStopsError,
    validateSolicitChangesForTripStops,
    setErrorToInitialState,
  };
}

export { useSolicitChangesForTripStopsErrors };
