/**
 * @file useCreateQuote
 *
 * Creates a request w/o pricing (quote)
 */

import { useMutation } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import { UseFormSetError } from "react-hook-form";

import {
  CREATE_REQUEST_MUTATION,
  UPDATE_CONTACT_MUTATION,
} from "../../../globals/graphql";
import {
  useAnalytics,
  useAuth,
  useCurrentUser,
  useDefaultPassenger,
  useGoogleTracking,
  useLaunchDarklyFlags,
  useOperator,
  useOperatorSlug,
  useQueryParams,
  useSnackbar,
} from "../../../globals/hooks";
import {
  getErrorCode,
  getErrorMessage,
} from "../../../moovsErrors/getErrorMessage";
import { useCreateRequestContext } from "../context/useCreateRequestContext";
import { Request } from "../../../types";
import { fromGlobalId } from "../../../utils/auth/helpers";
import { formatPhoneNumber } from "utils/phoneNumberFormatter/phoneNumberFormatter";
import { sanitizeRequestForCreateMutation } from "components/confirm/hooks/helpers";
import { CreateQuoteFormState } from "../steps/confirm/components/CreateQuoteForm/form/schemaValidation";
import { CreateRequestState } from "../context/initialState";
import { getIsNetflixLogin } from "utils/auth/isNetflixLogin";
import getDataForUpdateContact from "../utils/getDataForUpdateContact";

type UseCreateQuoteParams = {
  setError: UseFormSetError<any>;
};

function useCreateQuote(params: UseCreateQuoteParams) {
  const { setError } = params;

  // hooks
  const [request] = useCreateRequestContext();
  const { authStage, loginData } = useAuth();
  const operatorSlug = useOperatorSlug();
  const currentUser = useCurrentUser();
  const { operator } = useOperator();
  const snackbar = useSnackbar();
  const history = useHistory();
  const { track } = useAnalytics();
  const { googleTrack, getGclid } = useGoogleTracking();
  const queryParams = useQueryParams();
  const { enableLinkedPassenger } = useLaunchDarklyFlags();
  const { selectedPassenger } = useDefaultPassenger() || {};
  const isNetflixLogin = getIsNetflixLogin();
  // refs
  // used to make sure data passed to createQuote when a callback
  // is updated (otherwise is a closure). This also requires a
  // setTimeout to wait for next render
  const authStageRef = useRef(authStage);
  const currentUserRef = useRef(currentUser);
  const tripStatusUpdateTextTo = operator.settings.tripStatusUpdateTextTo;

  // effects
  useEffect(() => {
    authStageRef.current = authStage;
  }, [authStage]);

  useEffect(() => {
    currentUserRef.current = currentUser;
  }, [currentUser]);

  // state
  const [isCreatingQuote, setIsCreatingQuote] = useState(false);

  // mutations
  const [createRequest] = useMutation(CREATE_REQUEST_MUTATION, {
    onCompleted(data) {
      // linked passenger tracking
      let linkedPassengerCount = 0;
      let temporaryPassengerCount = 0;
      let bookingContactCount = 0;

      data.createRequest.request.trips.forEach((trip) => {
        // outbound trip
        if (trip.tempPassenger?.name) {
          temporaryPassengerCount++;
        } else if (
          trip.contact.id === data.createRequest.request.bookingContact.id
        ) {
          bookingContactCount++;
        } else {
          linkedPassengerCount++;
        }

        // return trip
        if (trip.returnTrip) {
          if (trip.returnTrip.tempPassenger?.name) {
            temporaryPassengerCount++;
          } else if (
            trip.returnTrip.contact.id ===
            data.createRequest.request.bookingContact.id
          ) {
            bookingContactCount++;
          } else {
            linkedPassengerCount++;
          }
        }
      });

      if (enableLinkedPassenger && linkedPassengerCount) {
        track("trip_linkedPassengerAdded", {
          totalCount: linkedPassengerCount,
        });
      }

      const operatorId = fromGlobalId(operator.id).id;

      // set analytics to track user if not authenticated
      // if authenticated this already happened
      if (authStageRef.current !== "authenticated") {
        const bookingContact = data.createRequest.request.bookingContact;
        const formattedTwilioNumber = formatPhoneNumber(
          operator.twilioPhoneNumber?.phoneNumber
        )?.formatted;

        window.analytics.identify(fromGlobalId(bookingContact.id).id, {
          operator_id: operatorId,
          email: bookingContact.email,
          name: `${bookingContact.firstName} ${bookingContact.lastName}`,
          source: "customer",
        });

        window.analytics.group(operatorId, {
          operator_id: operatorId,
          name: operator.name,
          phone: formattedTwilioNumber || operator.voicePhoneNumber,
          email: operator.generalEmail,
          location: operator.address,
          plan: operator.plan,
          source: "customer",
        });
      }

      if (enableLinkedPassenger) {
        track("quotes_requestReceived", {
          passengerType: {
            bookingContact: bookingContactCount,
            temporaryPassenger: temporaryPassengerCount,
            linkedPassengerCount: linkedPassengerCount,
          },
        });
      } else {
        track("quotes_requestReceived");
      }

      track("Create Quote");
      googleTrack("moovs_create_quote");

      setIsCreatingQuote(false);
      const request: Request = data.createRequest.request;
      history.push(`/${operatorSlug}/order/${request.id}/?successDialog=true`);
    },
    onError(error) {
      console.error(error);
      track("quoteRequest_submitAttempted");

      setIsCreatingQuote(false);

      if (getErrorCode(error) === "MOOVS_INVALID_EMAIL") {
        setError("bookingContact.email", {
          message: "Please enter valid email address",
        });
      }

      const errorMessage = getErrorMessage(error) || "Error creating request";

      snackbar.error(errorMessage);
    },
  });

  const [updateContact] = useMutation(UPDATE_CONTACT_MUTATION, {
    onCompleted: () => {
      snackbar.success("Successfully updated contact!");
    },
    onError: (error) => {
      console.error(error);
      snackbar.error(error.message || "Error updating passenger");
    },
  });

  const createQuote = async (createQuoteFormState: CreateQuoteFormState) => {
    setIsCreatingQuote(true);
    let verifiedContact = currentUserRef.current;

    const updatedRequest = mergeCreateQuoteFormState(
      request,
      createQuoteFormState
    );

    if (isNetflixLogin && createQuoteFormState.bookingContact && loginData) {
      const dataForResponse = getDataForUpdateContact(
        createQuoteFormState,
        loginData
      );
      const updatedContact = await updateContact(dataForResponse);

      verifiedContact.mobilePhone =
        updatedContact.data.updateContact.contact.mobilePhone;

      if (enableLinkedPassenger) {
        selectedPassenger.mobilePhone =
          updatedContact.data.updateContact.contact.mobilePhone;
      }
    }

    const createRequestInput = sanitizeRequestForCreateMutation({
      verifiedContact,
      request: updatedRequest,
      enableLinkedPassenger,
      selectedPassenger,
      tripStatusUpdateTextTo,
    });

    await createRequest({
      variables: {
        input: {
          ...createRequestInput,
          gclid: getGclid(),
          queryString: queryParams.toString(),
        },
      },
    });
  };

  return {
    createQuote,
    isCreatingQuote,
  };
}

export { useCreateQuote };

export const mergeCreateQuoteFormState = (
  request: CreateRequestState,
  confirmRequestFormState: CreateQuoteFormState
): CreateRequestState => {
  const { bookingContact } = confirmRequestFormState;

  const clonedRequest = cloneDeep(request);

  if (bookingContact) {
    clonedRequest.bookingContact = {
      ...clonedRequest.bookingContact,
      firstName: bookingContact.firstName,
      lastName: bookingContact.lastName,
      email: bookingContact.email,
      // flatten phoneFields back into contact
      ...bookingContact.phoneFields,
    };
  }

  return clonedRequest;
};
