/**
 * @file TripCategoryBlock
 *
 * HOURLY: Price has set start and end time
 * ONE_WAY(transfer): Price depends on distance
 * ROUND_TRIP: not supported
 *
 */
import React from "react";
import size from "lodash/size";
import reverse from "lodash/reverse";
import cloneDeep from "lodash/cloneDeep";

import { Box, MenuItem, Typography } from "@mui/material";

import { useFormContext, useWatch } from "react-hook-form";
import { NumberFormatNatural } from "design-system/components/inputs/NumberFormat";
import { TripCategory } from "types";
import { hourlyOptions, allTripTypes } from "./data"; // TODO: create tripTypes source on server to minimize need for manual update?
import { useOperator } from "globals/hooks";
import { RHFTextField } from "components/react-hook-form/text-field/RHFTextField";
import TripCategoryToggle from "./TripCategoryToggle";
import { InfoStepState } from "../context/InfoStepFormProvider";
import { getInitialTrip } from "pages/new/context/initialState";

type TripCategoryBlockProps = {
  showAllTripCategories?: boolean;
};

function TripCategoryBlock(props: TripCategoryBlockProps) {
  const { showAllTripCategories } = props;

  // hooks
  const { operator } = useOperator();
  const { setValue, control, getValues } = useFormContext<InfoStepState>();

  const [tripCategory, tripStops] = useWatch({
    name: ["trip.tripCategory", "trip.stops"],
    control,
  });

  const enabledTripCategories = showAllTripCategories
    ? allTripTypes
    : operator.enabledTripCategories;

  const createReturnTrip = () => {
    // return trip stops do not use date_time or flight info (except for airport) of outbound trip
    const reversedStops = reverse(cloneDeep(tripStops)).map((stop, index) => ({
      ...stop,
      stopIndex: index + 1,
      trackedFlight: null,
      airline: null,
      flightNumber: null,
      dateTime: null,
    }));

    const reverseTrip = {
      ...getInitialTrip(),
      tripCategory: TripCategory.RoundTrip,
      stops: reversedStops,
    };

    setValue("returnTrip", reverseTrip);
  };

  const removeReturnTrip = () => {
    setValue("returnTrip", null);
  };

  // duration only exists on hourly, so clear when switching types
  const clearDuration = () => {
    setValue(`trip.stops.${size(tripStops) - 1}.dateTime`, null);
  };

  const handleTripCategoryChange = (tripCategory: TripCategory) => {
    if (!tripCategory) return;
    setValue("trip.tripCategory", tripCategory);

    if (tripCategory === TripCategory.RoundTrip) {
      createReturnTrip();
    } else {
      removeReturnTrip();
    }

    if (tripCategory === TripCategory.Hourly) {
      setValue("trip.useTotalDuration", true);
    } else {
      setValue("trip.useTotalDuration", false);
    }

    clearDuration();
  };

  const handleAdditionalDurationAction = () => {
    if (tripCategory === TripCategory.Hourly) {
      setValue("trip.useTotalDuration", true);
    }
  };

  return (
    <>
      <Box mb={1}>
        <Typography variant="h4">Trip Type</Typography>
      </Box>
      <Box mb={2}>
        <TripCategoryToggle
          onChange={handleTripCategoryChange}
          enabledTripCategories={enabledTripCategories}
          value={getValues("trip.tripCategory")}
        />
      </Box>

      {tripCategory === TripCategory.Hourly && (
        <Box mb={2}>
          <RHFTextField
            name="trip.totalDuration"
            required
            select
            fullWidth
            id="duration"
            label="Duration"
            variant="outlined"
            onAdditionalAction={handleAdditionalDurationAction}
            InputProps={{
              inputComponent: NumberFormatNatural as any,
            }}
          >
            {hourlyOptions.map((option) => (
              <MenuItem key={option} value={option * 60}>
                {!!option ? `${option}h` : ""}
              </MenuItem>
            ))}
          </RHFTextField>
        </Box>
      )}
    </>
  );
}

export default TripCategoryBlock;
