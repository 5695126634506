import React from "react";
import isNil from "lodash/isNil";

import { CustomerPricingType } from "globals/hooks/utils";
import ActiveTripDetails from "./ActiveTripDetails";

type ActiveSingleTripBlockProps = {
  activeTripPricing: CustomerPricingType;
};

function ActiveSingleTripBlock(props: ActiveSingleTripBlockProps) {
  const { activeTripPricing } = props;

  const outboundPricingLineItems = Object.values(
    activeTripPricing.outboundTripPricing
  ).filter((pricingItem) => !isNil(pricingItem.value) || pricingItem?.selected);

  return (
    <ActiveTripDetails
      pricingLineItems={outboundPricingLineItems}
      totalAmt={activeTripPricing.orderTotalAmt}
      tripIndex={activeTripPricing.tripIndex}
    />
  );
}

export default ActiveSingleTripBlock;
