import React from "react";
import uniqBy from "lodash/uniqBy";
import map from "lodash/map";

import { Box, Typography } from "@mui/material";

import { SubPayment, PaymentRefund } from "types";
import PaymentListItemMobile from "./PaymentListItem.mobile";

type PaymentsListProps = {
  subPayments: SubPayment[];
  refunds: PaymentRefund[];
};

function PaymentsListMobile(props: PaymentsListProps) {
  const { subPayments, refunds } = props;

  // this is deduplicating the subpayments by payment id and then
  // setting the amount field to that of the total payment amount
  // so that payments are not divided by trip.
  const uniqByPayment = uniqBy(subPayments, "paymentId");

  const paymentsList = map(uniqByPayment, (subPayment) => ({
    ...subPayment,
    amount: subPayment.payment.amount,
  }));

  return (
    <Box display="flex" flex="1" flexDirection="column" mt={6} mb={2}>
      <Box mb={3}>
        <Typography variant="h3">Payments</Typography>
      </Box>
      {paymentsList.map((subPayment) => (
        <PaymentListItemMobile subPayment={subPayment} key={subPayment.id} />
      ))}
      {refunds.map((refund) => (
        <PaymentListItemMobile refund={refund} key={refund.id} />
      ))}
    </Box>
  );
}

export default PaymentsListMobile;
