import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import startCase from "lodash/startCase";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import sortBy from "lodash/sortBy";

import { Box } from "@mui/material";

import RHFStripeCreditCardInput, {
  validateStripeCreditCard,
} from "components/react-hook-form/RHFStripeCreditCardInput";
import {
  CREATE_PAYMENT_METHOD_MUTATION,
  LOAD_ME_QUERY,
} from "../../globals/graphql";
import MoovsDialog from "../globals/MoovsDialog";
import {
  useAnalytics,
  useCurrentUser,
  useLaunchDarklyFlags,
  useSnackbar,
} from "../../globals/hooks";
import { getErrorMessage } from "../../moovsErrors/getErrorMessage";
import { RHFTextField } from "../react-hook-form/text-field/RHFTextField";
import { CreateLinkedPassengerDialog } from "pages/passengers/components";
import PassengerRHFAutocomplete from "../react-hook-form/autocomplete/PassengerRHFAutocomplete";

export type AddCreditCardDialogProps = {
  open: boolean;
  setAddCreditCardDialogOpen: Dispatch<SetStateAction<boolean>>;
  setStripePaymentMethodId?: Dispatch<SetStateAction<string>>;
  onSetPaymentMethod?: Dispatch<SetStateAction<string>>;
  setPreferredPaymentMethodId?: Dispatch<SetStateAction<string>>;
  trackingName?: string;
};
const errorSchema = yup.object({
  name: yup.string().required("Please enter cardholder name"),
  creditCard: validateStripeCreditCard,
  linkToCreditCardPassenger: yup
    .object({
      id: yup.string(),
      firstName: yup.string(),
      lastName: yup.string(),
      email: yup.string(),
      mobilePhone: yup.string().required(),
    })
    .nullable(),
});

function AddCreditCardDialog(props: AddCreditCardDialogProps) {
  const {
    open,
    setAddCreditCardDialogOpen,
    setStripePaymentMethodId,
    setPreferredPaymentMethodId,
    onSetPaymentMethod,
    trackingName,
  } = props;

  // hooks
  const stripeElements = useElements();
  const stripe = useStripe();
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const currentUser = useCurrentUser();
  const { enableLinkedPassenger } = useLaunchDarklyFlags();

  const passengers = currentUser
    ? sortBy(
        currentUser.linkedPassengers.map((passenger) => {
          return {
            id: passenger.id,
            email: passenger.email,
            mobilePhone: passenger.mobilePhone,
            firstName: startCase(passenger.firstName),
            lastName: startCase(passenger.lastName),
          };
        }),
        ["firstName", "lastName"]
      )
    : [];

  // state
  const [linkedPassengerDialogOpen, setLinkedPassengerDialogOpen] =
    useState(false);

  // form state
  const formId = `add-credit-card-dialog-${currentUser.id}`;
  const methods = useForm<{
    name: string;
    creditCard: {
      type: string;
      message: string;
    };
    linkToCreditCardPassenger: {
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      mobilePhone: string;
    };
  }>({
    defaultValues: {
      name: "",
      creditCard: {},
      linkToCreditCardPassenger: null,
    },
    mode: "onSubmit",
    resolver: yupResolver(errorSchema),
  });

  const { linkToCreditCardPassenger } = methods.watch();

  // mutations
  const [createPaymentMethod, { loading: createPaymentLoading }] = useMutation(
    CREATE_PAYMENT_METHOD_MUTATION,
    {
      onCompleted(data) {
        const { id, stripeId, card } = data.createPaymentMethod.paymentMethod;
        const { last4, brand } = card;

        if (setStripePaymentMethodId) {
          setStripePaymentMethodId(stripeId);
        }

        if (onSetPaymentMethod) {
          onSetPaymentMethod(id);
        }

        if (setPreferredPaymentMethodId) {
          setPreferredPaymentMethodId(id);
        }

        snackbar.success(`${startCase(brand)} ****-${last4} has been added`);
        if (trackingName) track(trackingName);
        handleClose();
      },
      refetchQueries: [{ query: LOAD_ME_QUERY }],
      onError(error) {
        const errorMessage =
          getErrorMessage(error) || "Error creating payment.";

        snackbar.error(errorMessage);
      },
    }
  );

  // event handlers
  const handleClose = () => {
    setAddCreditCardDialogOpen(false);
  };

  const createStripePaymentMethod = async () => {
    const {
      paymentMethod: stripePaymentMethod,
      error: stripePaymentMethodError,
    } = await stripe.createPaymentMethod({
      type: "card",
      card: stripeElements.getElement(CardElement),
      billing_details: {
        name: methods.getValues().name,
      },
    });

    if (stripePaymentMethodError) {
      snackbar.error(stripePaymentMethodError.message);
      return;
    }

    createPaymentMethod({
      variables: {
        input: {
          contactId: currentUser.id,
          stripePaymentMethod,
          ...(linkToCreditCardPassenger && {
            linkedPassengerId: linkToCreditCardPassenger.id,
          }),
        },
      },
    });
  };

  // effects
  useEffect(() => {
    // reset values on open
    if (open) {
      methods.reset();
    }
  }, [methods, open]);

  const handleAddNewPassengerClick = () => {
    setLinkedPassengerDialogOpen(true);
  };

  const setSelectedPassenger = (newPassenger) => {
    methods.setValue("linkToCreditCardPassenger", newPassenger);
  };

  return (
    <>
      <MoovsDialog
        hideTopBorder
        size="xs"
        dialogTitle="Add Credit Card"
        acceptButtonText="Add"
        open={open}
        onClose={handleClose}
        dialogMarginTop="-20vh"
        acceptDisabled={
          createPaymentLoading || methods.formState.isSubmitting // disable accept/show loading indicator as soon as the form starts submitting
        }
        submitFormId={formId}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={(e) => {
              e.stopPropagation();
              methods.handleSubmit(createStripePaymentMethod)(e);
            }}
            id={formId}
          >
            <RHFTextField
              name="name"
              fullWidth
              variant="outlined"
              label="Cardholder name"
            />
            <Box my={1.5}>
              <RHFStripeCreditCardInput name="creditCard" />
            </Box>

            {enableLinkedPassenger ? (
              <PassengerRHFAutocomplete
                passengers={passengers}
                handleAddNewPassengerClick={handleAddNewPassengerClick}
                linkToCreditCardPassengerId={linkToCreditCardPassenger?.id}
              />
            ) : null}
          </form>
        </FormProvider>
      </MoovsDialog>

      {enableLinkedPassenger ? (
        <CreateLinkedPassengerDialog
          open={linkedPassengerDialogOpen}
          setLinkedPassengerDialogOpen={setLinkedPassengerDialogOpen}
          onCreateLinkedPassenger={setSelectedPassenger}
        />
      ) : null}
    </>
  );
}

export default AddCreditCardDialog;
