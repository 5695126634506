import some from "lodash/some";

import { useOperator } from "globals/hooks";
import { Request } from "types";

type UseAdditionalDriverGratuityByRouteProps = {
  request?: Request;
  userSelectedDriverGratuityPercent: number;
};

function useAdditionalDriverGratuityByRoute(
  props: UseAdditionalDriverGratuityByRouteProps
) {
  const { request, userSelectedDriverGratuityPercent } = props;

  // hooks
  const { operator } = useOperator();

  if (!request) return {};

  const isPaid = request.amountDue <= 0;
  const driverGratuityExist = some(
    request.trips,
    (trip) => trip.routes[0].pricing.driverGratuityAmt
  );

  const canAddDriverGratuityToRoutes =
    !driverGratuityExist && operator.gratuityWhenBooking && !isPaid;

  let additionalDriverGratuityByRoute;
  let additionalDriverGratuityAmt = 0;

  if (canAddDriverGratuityToRoutes && userSelectedDriverGratuityPercent) {
    additionalDriverGratuityByRoute = request.trips.map((trip) => {
      const { baseRateAmt } = trip.routes[0].pricing;

      const driverGratuityAmt =
        (userSelectedDriverGratuityPercent / 100) * baseRateAmt;

      additionalDriverGratuityAmt += driverGratuityAmt;

      return {
        routeId: trip.routes[0].id,
        driverGratuityAmt: Number(driverGratuityAmt.toFixed(2)),
      };
    });
  }

  return {
    displayGratuityBlock: canAddDriverGratuityToRoutes,
    additionalDriverGratuityAmt: Number(additionalDriverGratuityAmt.toFixed(2)),
    additionalDriverGratuityByRoute,
  };
}

export default useAdditionalDriverGratuityByRoute;
