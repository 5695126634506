import React from "react";
import startCase from "lodash/startCase";

import { Box, Typography, Card } from "@mui/material";

import { orange } from "../../design-system/colors";
import StarIcon from "../../design-system/icons/info/StarIcon";
import { PaymentMethod } from "../../types";

type CardListItemDesktopProps = { paymentMethod: PaymentMethod };

function CardListItemDesktop(props: CardListItemDesktopProps) {
  const { paymentMethod } = props;
  const { card, isPrimary } = paymentMethod;
  const { expMonth, expYear, last4, brand } = card;

  return (
    <>
      <Card variant="outlined">
        <Box
          p={2}
          display="flex"
          flexDirection="row"
          flex="1"
          width="100%"
          alignItems="center"
        >
          <Box display="flex" flexDirection="column" flex="1">
            <Typography variant="overline">brand</Typography>
            <Typography variant="body2">{startCase(brand)}</Typography>
          </Box>
          <Box display="flex" flexDirection="column" flex="2">
            <Typography variant="overline">card number</Typography>
            <Typography variant="body2">{`****-${last4}`}</Typography>
          </Box>
          <Box display="flex" flexDirection="column" flex="3">
            <Typography variant="overline">expiraton date</Typography>
            <Typography variant="body2">{`${expMonth}/${expYear}`}</Typography>
          </Box>
          <Box display="flex">
            {isPrimary && <StarIcon size="small" color={orange} />}
          </Box>
        </Box>
      </Card>
    </>
  );
}

export default CardListItemDesktop;
