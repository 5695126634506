import React, { ChangeEventHandler, KeyboardEvent } from "react";

import { InputAdornment, Box, CircularProgress } from "@mui/material";

import { NumberFormatFlight } from "design-system/components/inputs/NumberFormat";
import { white } from "design-system/colors";
import { ConditionalTextField } from "components/react-hook-form/text-field/ConditionalTextField";

type FlightNumberInputProps = {
  onFlightSearch: () => void;
  onFlightNumberInputChange?: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  isLoading: boolean;
  airlineIata: string;
  error?: boolean;
  value?: string;
  label?: string;

  // react hook form only props
  name?: string;
};

function FlightNumberInput(props: FlightNumberInputProps) {
  const {
    onFlightNumberInputChange,
    onFlightSearch,
    airlineIata,
    error,
    value,
    label,
    name,
    isLoading,
  } = props;

  // event handlers
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.keyCode === 13 || event.key === "Enter") {
      onFlightSearch();
    }
  };

  return (
    <Box flex="1" mb={1} display="flex">
      <ConditionalTextField
        name={name}
        label={label}
        variant="outlined"
        fullWidth
        onChange={onFlightNumberInputChange}
        sx={{
          backgroundColor: white,
          "& .MuiInputBase-root": {
            lineHeight: 1.43,
          },
        }}
        error={error}
        onBlur={onFlightSearch}
        onKeyDown={handleKeyDown}
        InputProps={{
          inputComponent: NumberFormatFlight as any,
          ...(airlineIata && {
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ "& .MuiInputBase-root": { lineHeight: 1.43 } }}
              >
                {airlineIata}
              </InputAdornment>
            ),
          }),
          ...(isLoading && {
            endAdornment: (
              <InputAdornment position="end">
                <CircularProgress size={20} />
              </InputAdornment>
            ),
          }),
        }}
        // value only if provided, and removes all non-numeric characters
        // this is so we can provide the full flight number
        {...(typeof value === "string" && { value: value.replace(/\D/g, "") })}
      />
    </Box>
  );
}

export default FlightNumberInput;
