import { useEffect } from "react";
import { useLocation } from "react-router-dom";

type useScrollToTopProps = {
  scrollOnMount?: boolean;
};

function useScrollToTop(props?: useScrollToTopProps) {
  const { scrollOnMount } = props || {};

  // hooks
  const { pathname } = useLocation();

  // effects
  useEffect(() => {
    if (scrollOnMount) {
      window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    }
  }, [pathname, scrollOnMount]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  };

  return { scrollToTop };
}

export { useScrollToTop };
