import React from "react";

type CircleRejectCancelIconProps = {
  color?: string;
  size: "small";
};

const CircleRejectCancelIcon = (props: CircleRejectCancelIconProps) => {
  const { color } = props;

  // size === small
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.363 18.363L5.63548 5.63546M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CircleRejectCancelIcon;
