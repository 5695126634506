import startCase from "lodash/startCase";
import React, { useCallback, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import sortBy from "lodash/sortBy";

import { Box } from "@mui/material";

import MoovsDialog from "components/globals/MoovsDialog";
import { useCurrentUser } from "globals/hooks";
import { CreateLinkedPassengerDialog } from "pages/passengers/components";
import { SearchLinkedPassengerAutocomplete } from "components/autocompletes";
import { SelectedPassenger } from "globals/hooks/useDefaultPassenger";

type AddLinkedPassengerDialogProps = {
  open: boolean;
  setSelectedPassenger: React.Dispatch<React.SetStateAction<SelectedPassenger>>;
  setAddLinkedPassengerDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setMenuOpen: React.Dispatch<React.SetStateAction<HTMLElement>>;
  setValue: UseFormSetValue<any>;
};

function AddLinkedPassengerDialog(props: AddLinkedPassengerDialogProps) {
  const {
    open,
    setSelectedPassenger,
    setAddLinkedPassengerDialogOpen,
    setMenuOpen,
    setValue,
  } = props;

  // state
  const [selectedLinkedPassenger, setSelectedLinkedPassenger] =
    useState<SelectedPassenger>(null);
  const [selectPassengerErrorMessage, setSelectPassengerErrorMessage] =
    useState("");
  const [createLinkedPassengerDialogOpen, setCreateLinkedPassengerDialogOpen] =
    useState(false);

  // hooks
  const currentUser = useCurrentUser();

  const passengers: SelectedPassenger[] = currentUser
    ? sortBy(
        currentUser.linkedPassengers.map((passenger) => {
          return {
            id: passenger.id,
            email: passenger.email,
            mobilePhone: passenger.mobilePhone,
            firstName: startCase(passenger.firstName),
            lastName: startCase(passenger.lastName),
          };
        }),
        ["firstName", "lastName"]
      )
    : [];

  // callbacks
  const clearTemporaryPassenger = useCallback(() => {
    setValue("temporaryPassenger.name", "");
    setValue("temporaryPassenger.phoneFields", {
      mobilePhone: "",
      mobilePhoneInternational: "",
      phoneCountryCode: "",
      phoneCountryDialCode: "",
      phoneCountryName: "",
      phoneCountryFormat: "",
    });
  }, [setValue]);

  // event handlers
  const handleDialogClose = () => {
    setSelectPassengerErrorMessage("");
    setAddLinkedPassengerDialogOpen(false);
    setMenuOpen(null);
  };

  const handleSelectPassengerOnChange = (
    _,
    selectedLinkedPassenger: SelectedPassenger
  ) => {
    setSelectPassengerErrorMessage("");
    setSelectedLinkedPassenger(selectedLinkedPassenger);
  };

  const handleAddLinkedPassengerSave = () => {
    if (!selectedLinkedPassenger) {
      setSelectPassengerErrorMessage("Please select a passenger");
      return;
    }

    clearTemporaryPassenger();
    setSelectedPassenger(selectedLinkedPassenger);
    setAddLinkedPassengerDialogOpen(false);
    setMenuOpen(null);
  };

  const handleAddNewPassengerClick = () => {
    setCreateLinkedPassengerDialogOpen(true);
  };

  return (
    <>
      <MoovsDialog
        size="xs"
        dialogTitle="Add Linked Passenger"
        acceptButtonText="Save"
        closeButtonText="Cancel"
        open={open}
        onClose={handleDialogClose}
        onAccept={handleAddLinkedPassengerSave}
        dialogMarginTop="-10vh"
      >
        <Box my={3}>
          <SearchLinkedPassengerAutocomplete
            id="search-linked-passengers"
            value={selectedLinkedPassenger}
            passengers={passengers}
            handleSelectPassengerOnChange={handleSelectPassengerOnChange}
            selectPassengerErrorMessage={selectPassengerErrorMessage}
            handleAddNewPassengerClick={handleAddNewPassengerClick}
          />
        </Box>
      </MoovsDialog>

      <CreateLinkedPassengerDialog
        open={createLinkedPassengerDialogOpen}
        setLinkedPassengerDialogOpen={setCreateLinkedPassengerDialogOpen}
        onCreateLinkedPassenger={setSelectedLinkedPassenger}
      />
    </>
  );
}
export default AddLinkedPassengerDialog;
