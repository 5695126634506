import React from "react";

const VehicleUnavailableDialogIcon = () => {
  return (
    <svg
      width="140"
      height="140"
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="140" height="140" rx="70" fill="#E5EEFF" />
      <path
        opacity="0.6"
        d="M109.977 97.7326C109.977 97.7326 103.741 97.66 101.767 97.7326C101.767 96.0905 100.796 92.7205 96.9142 92.3768C93.0319 92.0331 92.0124 95.5177 91.9879 97.303C74.4725 97.1205 40.7553 98.0474 39.4416 96.2958C38.3469 93.4715 35.0628 91.8294 32.326 92.3768C30.1366 92.8147 29.4831 95.8978 29.43 97.3846L19.1935 96.2958C18.1699 96.2233 17 95.9329 17 94.7716V92.8844C17 92.0859 17.7312 92.1585 18.6086 91.9408C18.7548 89.8358 19.7785 87.8034 21.0946 87.5857C23.9462 87.1501 27.529 86.8598 31.4773 86.7146L35.1332 83.0854C36.1569 81.924 39.4472 81.7063 41.202 81.6337C51.4385 81.4159 62.333 81.3434 74.6168 81.5611C78.2727 81.6337 82.221 82.6499 85.6576 84.8274L89.021 87.1501C89.4597 87.4405 90.0446 87.5857 90.5565 87.6582C97.0639 88.0938 103.645 88.747 107.812 90.1261C108.982 90.6342 109.758 91.3547 109.977 92.8064C109.977 92.8064 111.072 93.9011 111.072 94.9958C111.072 95.784 111.072 97.1853 109.977 97.7326Z"
        fill="url(#paint0_linear_49_42027)"
      />
      <path
        d="M110.05 97.3029C110.05 97.3029 118.853 97.2453 120.389 96.7372C121.047 96.5194 121.485 96.0113 121.558 95.2855V94.9951C121.924 94.487 122.07 93.9064 121.997 93.3983C121.924 93.0353 121.705 92.8176 121.412 92.6724L120.681 92.3821C120.462 91.003 119.73 90.0594 118.561 89.6238C114.685 88.3173 108.251 86.8656 102.182 86.4301C100.866 86.3575 97.4295 84.6881 97.4295 84.6881C97.4295 84.6881 94.7242 83.3815 93.7005 82.946C92.6038 82.5105 91.7995 82.2928 90.8489 82.1476C89.3866 81.8573 87.9973 81.5669 86.2425 81.4943C85.5845 81.4217 85.4382 81.4217 83.9759 81.3492C82.2942 81.2766 81.3436 81.204 81.3436 81.204C81.3436 81.204 79.1501 81.1314 77.5415 81.1314C76.2985 81.1314 75.0555 81.1314 73.8125 81.1314"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.4414 96.7557L91.9877 97.1002"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.84 97.303C103.814 97.2304 110.051 97.303 110.051 97.303C111.145 96.7556 111.145 95.3544 111.145 94.5662C111.145 93.4715 110.051 92.3768 110.051 92.3768C109.831 90.9251 109.055 90.2046 107.886 89.6965C103.718 88.3174 97.1372 87.6641 90.6297 87.2286C90.1179 87.156 89.5329 87.0109 89.0942 86.7205L85.7308 84.3978C82.2943 82.2203 78.3459 81.2041 74.69 81.1315C62.4063 80.9137 51.5117 80.9863 41.2752 81.2041C39.5204 81.2767 36.2301 81.4944 35.2064 82.6558L31.5506 86.285C27.6022 86.4302 24.0194 86.7205 21.1678 87.156C19.8517 87.3738 18.8281 89.4062 18.6818 91.5111C17.8044 91.7289 17.0732 91.6563 17.0732 92.4547V94.3419C17.0732 95.5033 18.2431 95.7936 19.2668 95.8662L29.5033 96.955"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.0962 96.8824C92.0962 94.2693 94.2166 92.1644 96.8488 92.1644C99.4811 92.1644 101.602 94.2693 101.602 96.8824C101.602 99.4954 99.4811 101.6 96.8488 101.6C94.2166 101.673 92.0962 99.4954 92.0962 96.8824ZM94.8015 96.8824C94.8015 98.0437 95.7521 98.9148 96.8488 98.9148C98.0187 98.9148 98.8961 97.9712 98.8961 96.8824C98.8961 95.7936 97.9456 94.85 96.8488 94.85C95.679 94.85 94.8015 95.7936 94.8015 96.8824Z"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.35 97.5358C112.35 99.7859 110.522 101.673 108.182 101.673C105.916 101.673 104.015 99.8585 104.015 97.5358"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.8105 81.2041C39.374 81.2041 37.3267 83.672 37.3267 87.2286H90.2639"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.144 81.7551L54.144 87.2286"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.0195 81.4296L75.0195 86.9031"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.5762 96.8824C29.5762 94.2693 31.6966 92.1644 34.3288 92.1644C36.9611 92.1644 39.0815 94.2693 39.0815 96.8824C39.0815 99.4954 36.9611 101.6 34.3288 101.6C31.6966 101.6 29.5762 99.4954 29.5762 96.8824ZM32.2815 96.8824C32.2815 98.0437 33.2321 98.9148 34.3288 98.9148C35.4256 98.9148 36.3761 97.9712 36.3761 96.8824C36.3761 95.7936 35.4256 94.85 34.3288 94.85C33.2321 94.85 32.2815 95.7936 32.2815 96.8824Z"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.4149 97.1727C50.4149 99.6406 48.3676 101.673 45.8816 101.673C43.3956 101.673 41.4214 99.6406 41.4214 97.1727"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.6"
        d="M55.7373 41.9639L99.3198 41.5051L100.237 51.1391H58.0311L55.7373 41.9639Z"
        fill="url(#paint1_linear_49_42027)"
      />
      <path
        opacity="0.6"
        d="M47.0206 42.4227L52.067 41.9639L51.1495 49.7629L40.8308 51.1392L39.2217 50.2217L47.0206 42.4227Z"
        fill="url(#paint2_linear_49_42027)"
      />
      <path
        opacity="0.6"
        d="M100.696 36H109.412L112.165 39.2113L114 63.5258H102.99L102.531 51.3686L102.072 39.2113L100.696 36Z"
        fill="url(#paint3_linear_49_42027)"
      />
      <path
        d="M89.3687 64.1243C89.3687 66.9441 91.6859 69.3187 94.6012 69.3187C97.5166 69.3187 99.8338 67.0183 99.8338 64.1243"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.8435 64.1242C96.8435 65.3857 95.797 66.3504 94.6009 66.3504C93.3302 66.3504 92.3584 65.3115 92.3584 64.1242"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.0527 64.1243C79.0527 61.3045 81.37 59.0041 84.2106 59.0041C87.0511 59.0041 89.3684 61.3045 89.3684 64.1243C89.3684 66.9442 87.0511 69.2446 84.2106 69.2446C81.37 69.2446 79.0527 66.9442 79.0527 64.1243ZM81.968 64.1243C81.968 65.3859 82.9398 66.3505 84.2106 66.3505C85.4814 66.3505 86.4531 65.3859 86.4531 64.1243C86.4531 62.8628 85.4814 61.8982 84.2106 61.8982C82.9398 61.8982 81.968 62.8628 81.968 64.1243Z"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.9466 36C96.1709 36.0742 103.123 36 108.281 36C110.374 36 111.869 37.6325 112.018 39.6361L113.513 61.6755C113.588 62.7886 112.691 63.7533 111.57 63.7533L95.3486 64.1243"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.5557 64.1243H79.0526"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.3685 64.1243H95.0496C95.0496 64.1243 98.8619 63.9017 101.254 63.8275C102.3 63.7533 103.123 62.937 103.123 61.8981V53.2159C102.824 47.0568 102.525 40.675 101.403 37.4841C101.104 36.5937 100.207 36 99.2356 36C92.209 36 65.3733 36 54.1605 36C51.6938 36 49.3017 36.9647 47.4329 38.6714C43.9944 41.8623 38.3133 47.2052 36.0707 49.283C35.3979 49.8766 34.7252 50.3219 33.9029 50.6929C32.1836 51.435 29.1936 52.6965 27.25 53.5128C26.2035 53.958 25.5308 54.9969 25.5308 56.11V61.0076C25.5308 62.7144 26.951 64.1243 28.6703 64.1243H30.1653"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.7688 42.3076C51.9183 42.456 51.993 42.7528 51.993 42.9754C51.8435 44.237 51.5445 46.5374 51.4698 48.2441C51.3203 49.283 50.498 50.0993 49.4515 50.2477C46.2372 50.6929 40.5982 51.1391 40.5982 51.1391L38.7632 50.2477L43.8096 45.634C43.8096 45.634 46.6677 42.5302 47.9384 42.3076C49.2092 42.085 49.6757 42.3076 51.096 42.085C51.096 42.085 51.6193 42.1592 51.7688 42.3076Z"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.7025 41.7881C56.4035 41.7881 56.1045 41.9365 55.8802 42.1592C55.656 42.3818 55.5812 42.6786 55.656 42.9754C56.0297 44.608 56.6277 47.4278 57.0762 49.283C57.3005 50.3961 58.347 51.1382 59.4683 51.1382H99.1613C99.4603 51.1382 99.834 50.9897 100.058 50.7671C100.283 50.5445 100.432 50.1735 100.357 49.8766C100.208 48.1699 99.9835 45.0532 99.834 43.198C99.7593 42.3076 99.0118 41.6397 98.1147 41.6397C90.4901 41.7139 63.5049 41.7881 56.7025 41.7881Z"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.403 37.5583C102.525 40.7492 102.824 47.131 103.123 53.2902V61.8981"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.767 42.6044L105.365 50.5445C105.365 50.9156 105.664 51.2866 105.963 51.2866H109.252C109.85 51.2866 110.373 50.6187 110.299 49.8025L110 43.4949C109.925 42.5302 109.327 41.7139 108.579 41.7139H105.365C104.991 41.7139 104.767 42.1592 104.767 42.6044Z"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.2402 64.1243C30.2402 61.3045 32.5575 59.0041 35.3981 59.0041C38.2386 59.0041 40.5559 61.3045 40.5559 64.1243C40.5559 66.9442 38.2386 69.2446 35.3981 69.2446C32.5575 69.2446 30.2402 66.9442 30.2402 64.1243ZM33.1555 64.1243C33.1555 65.3859 34.1273 66.3505 35.3981 66.3505C36.6689 66.3505 37.6406 65.3859 37.6406 64.1243C37.6406 62.8628 36.6689 61.8982 35.3981 61.8982C34.2021 61.8982 33.1555 62.8628 33.1555 64.1243Z"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115 139C128.255 139 139 128.255 139 115C139 101.745 128.255 91 115 91C101.745 91 91 101.745 91 115C91 128.255 101.745 139 115 139Z"
        fill="#D63F49"
      />
      <path
        d="M122.7 107.3L115 115M115 115L107.3 122.7M115 115L107.3 107.3M115 115L122.7 122.7"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_49_42027"
          x1="90.9024"
          y1="97.2353"
          x2="90.9024"
          y2="81.3016"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#87AAFF" stopOpacity="0.62" />
          <stop offset="1" stopColor="#195FE9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_49_42027"
          x1="90.6962"
          y1="50.8453"
          x2="90.6962"
          y2="41.4294"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#87AAFF" stopOpacity="0.62" />
          <stop offset="1" stopColor="#195FE9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_49_42027"
          x1="41.6084"
          y1="50.8453"
          x2="41.6084"
          y2="41.4295"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#87AAFF" stopOpacity="0.62" />
          <stop offset="1" stopColor="#195FE9" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_49_42027"
          x1="95.3155"
          y1="62.8499"
          x2="95.3155"
          y2="41.1935"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#87AAFF" stopOpacity="0.62" />
          <stop offset="1" stopColor="#195FE9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default VehicleUnavailableDialogIcon;
