import React from "react";

import { Box, Typography } from "@mui/material";

import { TemporaryPassenger } from "types";
import { formatPhoneNumber } from "utils/phoneNumberFormatter/phoneNumberFormatter";
import { SelectedPassenger } from "globals/hooks/useDefaultPassenger";

type PassengerDetailsContactProps = {
  temporaryPassenger?: TemporaryPassenger;
  passenger: SelectedPassenger;
};

function PassengerDetailsContact(props: PassengerDetailsContactProps) {
  const { temporaryPassenger } = props;

  if (!props.passenger) return null;

  const name = temporaryPassenger?.name
    ? temporaryPassenger.name
    : `${props.passenger.firstName} ${props.passenger.lastName}`;

  const mobilePhone = temporaryPassenger?.name
    ? formatPhoneNumber(
        temporaryPassenger.mobilePhone,
        temporaryPassenger.phoneCountryCode,
        temporaryPassenger.phoneCountryDialCode
      )?.formatted
    : props.passenger.mobilePhone;

  return (
    <Box width="100%" mb={2}>
      <Typography variant="overline">Passenger Contact</Typography>
      <Typography mt={0.5} variant={name ? "subtitle2" : "body2"}>
        {name || "-"}
      </Typography>
      <Typography variant="body2">{mobilePhone || ""}</Typography>
    </Box>
  );
}

export default PassengerDetailsContact;
