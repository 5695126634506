import React from "react";
import { Box } from "@mui/material";
import { CheckIcon } from ".";
import { successGreen, tintGreen } from "../colors";

type CircleGreenCheckIconProps = {
  size?: "small";
  bgcolor?: string;
  color?: string;
};

function CircleGreenCheckIcon(props: CircleGreenCheckIconProps) {
  const { size, bgcolor, color } = props;

  return (
    <Box
      display="flex"
      height={size === "small" ? "24px" : "32px"}
      width={size === "small" ? "24px" : "32px"}
      justifyContent="center"
      alignItems="center"
      borderRadius="50%"
      bgcolor={bgcolor || tintGreen}
    >
      <CheckIcon color={color || successGreen} size={size} />
    </Box>
  );
}

export default CircleGreenCheckIcon;
