import React from "react";

type InVehicleBarIconProps = {
  color?: string;
  size: "small";
};

const InVehicleBarIcon = (props: InVehicleBarIconProps) => {
  const { color } = props;

  // size === small
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.26939 14.6667H9.70529M7.05738 14.6667V9.49024M7.05738 9.49024L2.48134 4.82572C2.41925 4.76235 2.37697 4.68164 2.35985 4.59378C2.34273 4.50593 2.35153 4.41488 2.38515 4.33213C2.41876 4.24937 2.47568 4.17863 2.54872 4.12883C2.62176 4.07903 2.70763 4.05241 2.7955 4.05233H11.3187C11.4065 4.05241 11.4924 4.07903 11.5654 4.12883C11.6385 4.17863 11.6954 4.24937 11.729 4.33213C11.7626 4.41488 11.7714 4.50593 11.7543 4.59378C11.7372 4.68164 11.6949 4.76235 11.6328 4.82572L7.05738 9.49024ZM0.833496 1.33337H2.50624C2.67139 1.33332 2.83328 1.38016 2.97378 1.46863C3.11428 1.5571 3.22783 1.68371 3.30171 1.83427L4.39 4.05233M9.76921 4.05293C9.73966 3.90356 9.72477 3.75155 9.72476 3.59917C9.72526 3.1036 9.88526 2.62189 10.1802 2.22794C10.4752 1.834 10.8888 1.54959 11.3576 1.41835C11.8264 1.28712 12.3245 1.3163 12.7755 1.50143C13.2265 1.68656 13.6054 2.01741 13.8541 2.4432C14.1029 2.869 14.2077 3.36623 14.1525 3.8586C14.0973 4.35096 13.8851 4.81126 13.5485 5.16887C13.2119 5.52647 12.7695 5.76163 12.2892 5.83826C11.8089 5.91489 11.3172 5.82875 10.8895 5.59307"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InVehicleBarIcon;
