import React from "react";

import MoovsDialog from "components/globals/MoovsDialog";
import ATIForm from "./ATIForm";
import { AdditionalTripInfoState } from "../form/schemaValidation";
import { TemporaryPassenger } from "types";
import { SelectedPassenger } from "globals/hooks/useDefaultPassenger";

type ATIDialogProps = {
  onClose: () => void;
  onSubmit: (additionalTripInfoState: AdditionalTripInfoState) => void;
  isSubmitting: boolean;
  initialState?: {
    totalGroupSize: number;
    note?: string;
    temporaryPassenger?: TemporaryPassenger;
    route: {
      carryOnLuggage?: number;
      checkedLuggage?: number;
      oversizeLuggage?: number;
    };
    selectedPassenger?: SelectedPassenger;
  };
  open: boolean;
};

function ATIDialog(props: ATIDialogProps) {
  const { onClose, initialState, onSubmit, isSubmitting, open } = props;

  return (
    <MoovsDialog
      fixedFooter
      open={open}
      onClose={onClose}
      acceptDisabled={isSubmitting}
      dialogTitle="Additional Trip Info"
      acceptButtonText="Save"
      size="sm"
      submitFormId="additional-trip-info-form"
    >
      <ATIForm onSubmit={onSubmit} initialState={initialState} />
    </MoovsDialog>
  );
}

export default ATIDialog;
