import { TextField, TextFieldProps } from "@mui/material";
import React from "react";
import { Control, Controller, useFormContext } from "react-hook-form";

export type RHFTextFieldProps = {
  name: string;
  control?: Control<any>;
  onAdditionalAction?: (value: any, nameOfUpdatedField?: string) => void;
} & Omit<TextFieldProps, "value" | "onChange" | "error" | "helperText">;

export const RHFTextField = (props: RHFTextFieldProps) => {
  const { name, children, required, onAdditionalAction, ...textFieldProps } =
    props;

  // hooks
  const context = useFormContext();

  // manually add "*" to required fields without passing in as prop to TextField, as 'required' prop collides with React Hook Form validation
  const label = required && props.label ? `${props.label} *` : props.label;

  // derived state
  const control = props.control || context.control;

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, ref: inputRef, value, ...fieldProps },
        fieldState: { error },
      }) => {
        return (
          // ordering of props is delicate because of what we want to override
          // be careful when changing
          <TextField
            variant="outlined"
            {...textFieldProps}
            {...fieldProps}
            onChange={(e) => {
              onChange(e);
              if (onAdditionalAction) {
                onAdditionalAction(e.target.value, name);
              }
            }}
            label={label}
            inputRef={inputRef}
            value={value === null ? "" : value}
            error={!!error}
            helperText={error?.message}
            // this onBlur is a temporary override for FlightNumberInput to prioritize passed in onBlur
            // in the future, RHF components should have flexible overrides. This isn't incorporated right now b/c most areas
            // using Conditional RHF components don't want to be overriden
            // (aka we want to ignore passed in onChange for RHF component, but not the Mui component)
            onBlur={textFieldProps.onBlur || fieldProps.onBlur}
          >
            {children}
          </TextField>
        );
      }}
    />
  );
};
