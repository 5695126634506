import React, { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment-timezone";

import { Box, CircularProgress } from "@mui/material";

import GQLQueryStatusIndicator from "../../components/GQLQueryStatusIndicator";
import {
  CREATE_INVOICE_PAYMENT_MUTATION,
  LOAD_INVOICE_QUERY,
} from "../../globals/graphql";
import { currency } from "../../utils/helpers";
import ChargeCustomerBlock from "../../components/pay/ChargeCustomerBlock";
import { Invoice, InvoiceStatus } from "../../types";
import PaymentSourceBlock from "../../components/pay/PaymentSourceBlock";
import SimpleAuthTopBar from "../../components/SimpleAuthTopBar";
import {
  useAnalytics,
  useOperator,
  useScreenSize,
  useSnackbar,
} from "../../globals/hooks";
import SubmitSuccessDialog from "../../components/globals/SubmitSuccessDialog";
import PaySummaryBlock from "../../components/pay/PaySummaryBlock";
import { getErrorMessage } from "../../moovsErrors/getErrorMessage";
import { white, grayLight, successGreen } from "design-system/colors";

const INVOICE_PAGE_MAX_WIDTH = 580;

function InvoicePayPage() {
  // state
  const [isCreatingPayment, setIsCreatingPayment] = useState(false);

  // hooks
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const { isMobileView } = useScreenSize();
  const history = useHistory();
  const { operator } = useOperator();
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // queries
  const {
    data: invoiceData,
    loading: invoiceLoading,
    error: invoiceError,
    refetch: invoiceRefetch,
  } = useQuery(LOAD_INVOICE_QUERY, {
    variables: {
      id: invoiceId,
    },
    skip: !invoiceId,
    fetchPolicy: "network-only",
  });

  // mutations
  const [createInvoicePayment] = useMutation(CREATE_INVOICE_PAYMENT_MUTATION, {
    onCompleted() {
      setIsCreatingPayment(false);
      // If payment successful, show invoice paid pop-up
      history.push({
        pathname: `${history.location.pathname}`,
        search: "?successDialog=true",
      });
    },
    refetchQueries: [
      {
        query: LOAD_INVOICE_QUERY,
        variables: { id: invoiceId },
      },
    ],
    onError(error) {
      setIsCreatingPayment(false);
      const errorMessage =
        getErrorMessage(error) || "Error creating invoice payment";

      snackbar.error(errorMessage);
      console.log(error);
    },
  });

  const dueDate = invoiceData?.node?.dueDate;
  const smartDueDate = useMemo(() => {
    const todaysDate = moment();
    const dateDiff = todaysDate.diff(dueDate, "days");
    const formattedDate = moment(dueDate).format("MMM DD, YYYY");
    if (Math.abs(dateDiff) >= 7) {
      return formattedDate;
    }
    return `${moment(dueDate).fromNow()}, ${formattedDate}`;
  }, [dueDate]);

  if (invoiceLoading) {
    return (
      <Box
        width="100%"
        height="90vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress size={40} thickness={2} />
      </Box>
    );
  }

  if (invoiceError && !invoiceLoading) {
    return (
      <Box
        width="100%"
        height="90vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <GQLQueryStatusIndicator
          name="Invoice"
          data={invoiceData}
          error={invoiceError}
          refetch={invoiceRefetch}
        />
      </Box>
    );
  }

  const invoice: Invoice = invoiceData.node;
  const { invoiceNumber, contact, farmAffiliate, company } = invoice;

  // note: an invoice can have status 'PAID' but still have an amount due
  // in this case, invoice should still be payable
  const isPayable =
    invoice.status !== InvoiceStatus.Paid || invoice.amountDue > 0;

  const invoiceAmountDue = currency(invoice.amountDue);
  const invoiceTotal = currency(invoice.totalAmount);

  const operatorSlug = operator?.nameSlug;

  // event handlers
  const handleViewInvoice = () => {
    history.push(`/${operatorSlug}/invoice/${invoiceId}`);
  };

  const handleCreatePayment = (paymentMethodId: string) => {
    setIsCreatingPayment(true);
    // check if user has a payment method
    // if not, prompt user to add a new card
    if (!paymentMethodId) {
      snackbar.error(
        "Oops! Please add a credit card to continue creating payment."
      );
      return;
    }

    track("invoice_paySubmitted");
    createInvoicePayment({
      variables: {
        input: {
          paymentMethodId,
          invoiceId: invoiceId,
        },
      },
    });
  };

  return (
    <>
      <Box width="100%" pb={2} display="flex" justifyContent="center">
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          maxWidth={INVOICE_PAGE_MAX_WIDTH}
          px={isMobileView ? 2 : 0}
          mt={isMobileView ? 3 : 5}
        >
          <Box mb={6.5}>
            <SimpleAuthTopBar hideLogin={!!farmAffiliate?.id} />
          </Box>
          <Box
            bgcolor={white}
            border={`1px solid ${grayLight}`}
            boxShadow="0px 4px 15px rgba(0, 0, 0, 0.03)"
            {...(!isPayable && { borderBottom: `8px solid ${successGreen}` })}
          >
            <PaySummaryBlock
              header="Amount Due"
              headerAmount={invoiceAmountDue}
              subHeader1="Invoice Number"
              subContent1={invoiceNumber}
              subHeader2="Invoice Total"
              subContent2={invoiceTotal}
              subHeader3="Due Date"
              subContent3={smartDueDate}
              viewEntityLabel="View Invoice"
              onViewEntityClick={handleViewInvoice}
              isPaid={!isPayable}
            />
          </Box>

          {!isPayable ? (
            <Box mt={5}>
              <PaymentSourceBlock subPayments={invoice.subPayments} />
            </Box>
          ) : (
            <Box mt={5}>
              <ChargeCustomerBlock
                onCreatePayment={handleCreatePayment}
                isCreatingPayment={isCreatingPayment}
                totalDue={invoiceAmountDue}
                contactId={contact?.id}
                farmAffiliateId={farmAffiliate?.id}
                companyId={company?.id}
              />
            </Box>
          )}
        </Box>
      </Box>
      <SubmitSuccessDialog message="Invoice is Paid!" />
    </>
  );
}

export default InvoicePayPage;
