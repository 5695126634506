import React from "react";

import { Box, Typography } from "@mui/material";

import { alabaster, black, swoopGreen } from "design-system/colors";
import { CheckIcon, PendingIcon } from "design-system/icons";
import { SubmitButton } from "./form/components";
import ConsentSignatureSubmitButton from "./form/components/ConsentSignatureSubmitButton";

type RequestStatusFixedFooterProps = {
  variant: "quote-request" | "reservation-confirmation" | "review-and-reserve";
  isSubmitting?: boolean;
  formId?: string;
  requireSignatureWhenBooking?: boolean;
  onConsentSignatureDialogOpen?: () => void;
  disabled?: boolean;
  autoChargeButtonText?: string;
  validToOpenSignatureDialog?: boolean;
};

function RequestStatusFixedFooter(props: RequestStatusFixedFooterProps) {
  const {
    formId,
    isSubmitting,
    variant,
    requireSignatureWhenBooking,
    onConsentSignatureDialogOpen,
    disabled,
    autoChargeButtonText,
    validToOpenSignatureDialog,
  } = props;

  return (
    <Box
      position="fixed"
      bottom="0"
      left="0"
      right="0"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor={alabaster}
      width="100%"
      boxShadow="0px 0px 30px rgba(30, 30, 30, 0.1)"
      zIndex={20} // higher than googleMap opacity overlay
      p={2}
    >
      {variant === "quote-request" && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <PendingIcon color={swoopGreen} />
          <Typography
            variant="overline"
            style={{ color: black, marginLeft: "11px" }}
          >
            YOUR QUOTE IS PENDING
          </Typography>
        </Box>
      )}
      {variant === "review-and-reserve" &&
        (requireSignatureWhenBooking ? (
          <ConsentSignatureSubmitButton
            isSubmitting={isSubmitting}
            onConsentSignatureDialogOpen={onConsentSignatureDialogOpen}
            disabled={disabled}
            autoChargeButtonText={autoChargeButtonText}
            validToOpenSignatureDialog={validToOpenSignatureDialog}
          />
        ) : (
          <SubmitButton
            isSubmitting={isSubmitting}
            formId={formId}
            disabled={disabled}
            autoChargeButtonText={autoChargeButtonText}
          />
        ))}
      {variant === "reservation-confirmation" && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <CheckIcon color={swoopGreen} />
          <Typography
            variant="overline"
            style={{ color: black, marginLeft: "11px" }}
          >
            YOUR RESERVATION IS CONFIRMED
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default RequestStatusFixedFooter;
