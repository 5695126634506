import { FlightStatus } from "../../types";
import {
  grayMedium,
  errorRed,
  granite,
  successGreen,
  tintGreen,
  moovsBlueDark,
  moovsBlueLight,
  orange,
  tintPink,
  tintOrange,
} from "../../design-system/colors";

export default function flightStatusMap(flightStatus: FlightStatus) {
  const statusMap = (status: FlightStatus) =>
    ({
      SCHEDULED: {
        statusColor: granite,
        planeFillColor: grayMedium,
        planeBorderColor: granite,
        planePosition: "start",
        allowRefresh: true,
      },
      DEPARTED: {
        statusColor: moovsBlueDark,
        planeFillColor: moovsBlueLight,
        planeBorderColor: moovsBlueDark,
        planePosition: "middle",
        allowRefresh: true,
      },
      ARRIVED: {
        statusColor: successGreen,
        planeFillColor: tintGreen,
        planeBorderColor: successGreen,
        planePosition: "end",
        allowRefresh: false,
      },
      CANCELLED: {
        statusColor: errorRed,
        planeFillColor: tintPink,
        planeBorderColor: errorRed,
        planePosition: "start",
        allowRefresh: false,
      },
      DIVERTED: {
        statusColor: orange,
        planeFillColor: tintOrange,
        planeBorderColor: orange,
        planePosition: "middle",
        allowRefresh: false,
      },
    }[status]);

  return statusMap(flightStatus);
}
