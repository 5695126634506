import React, { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment-timezone";
import filter from "lodash/filter";
import map from "lodash/map";
import uniqBy from "lodash/uniqBy";
import last from "lodash/last";

import { Box, Button, Typography } from "@mui/material";

import { grayDark, successGreen } from "../../design-system/colors";
import { currency } from "../../utils/helpers";
import { PaymentPaymentStatus, SubPayment } from "../../types";
import PaymentMethodSummary from "./PaymentMethodSummary";
import { useOperator, useScreenSize } from "../../globals/hooks";

type InvoiceSummaryBlockProps = {
  subPayments: SubPayment[];
  dueDate: string;
  amountDue: number;
  isPaid: boolean;
  memo: string;
};

function InvoiceSummaryBlock(props: InvoiceSummaryBlockProps) {
  const { dueDate, amountDue, subPayments, isPaid, memo } = props;

  const invoiceAmountDue = currency(amountDue);

  // hooks
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const history = useHistory();
  const { operator } = useOperator();
  const { isMobileView } = useScreenSize();

  // event handlers
  const handleRedirectToPay = () => {
    history.push(`/${operator.nameSlug}/invoice/${invoiceId}/pay`);
  };

  // derived state
  const smartDueDate = useMemo(() => {
    const todaysDate = moment();
    const dateDiff = todaysDate.diff(dueDate, "days");
    const formattedDate = moment(dueDate).format("MMM DD, YYYY");
    if (Math.abs(dateDiff) >= 7) {
      return formattedDate;
    }
    return `${moment(dueDate).fromNow()}, ${formattedDate}`;
  }, [dueDate]);

  const payments = uniqBy(map(subPayments, "payment"), "id");

  const lastSuccessfulPayment = last(
    filter(
      payments,
      (payment) => payment.paymentStatus === PaymentPaymentStatus.Succeeded
    )
  );

  let invoiceText;
  let paymentInfo;
  if (amountDue > 0) {
    invoiceText = `${invoiceAmountDue} due ${smartDueDate}`;
  }

  // gather relevant data from invoice payment
  if (lastSuccessfulPayment) {
    const formattedDate = moment(lastSuccessfulPayment.createdAt).format(
      "MMM DD, YYYY"
    );
    const paymentAmount = currency(lastSuccessfulPayment.amount);
    invoiceText = `${paymentAmount} paid ${formattedDate}`;
    paymentInfo = lastSuccessfulPayment;
  }

  // EDGECASE: if an invoice is manually marked "paid",
  // there are no charges on it,
  // and the amount due is === 0,
  // technically there is no paymentMethod
  if (!paymentInfo) {
    paymentInfo = { paymentMethod: "Other", method: "Other" };
  }

  return (
    <Box
      display="flex"
      flexDirection={isMobileView ? "column" : "row"}
      alignItems={isMobileView ? "start" : "center"}
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="column">
        <Box mb={1}>
          <Typography
            variant="h2"
            style={{
              fontSize: "24px",
              color: isPaid ? successGreen : "inherit",
            }}
          >
            {invoiceText}
          </Typography>
        </Box>
        <Box mb={isMobileView ? 3 : 0}>
          <Typography
            variant="body2"
            style={{ color: grayDark, whiteSpace: "pre-wrap" }}
          >
            {memo}
          </Typography>
        </Box>
      </Box>
      {amountDue > 0 ? (
        <Button
          fullWidth={isMobileView}
          onClick={handleRedirectToPay}
          variant="contained"
          color="primary"
        >
          Pay Now
        </Button>
      ) : (
        <PaymentMethodSummary paymentInfo={paymentInfo} />
      )}
    </Box>
  );
}

export default InvoiceSummaryBlock;
