import React, { ChangeEvent } from "react";
import {
  Control,
  Controller,
  useFormContext,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import { Box, Button, Checkbox, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { grayDark, grayLight, white } from "design-system/colors";
import { useScreenSize } from "globals/hooks";
import { ConfirmReservationFormState } from "pages/order/components/ConfirmReservationForm/form/schemaValidation";
import { CreateReservationFormState } from "pages/new/steps/confirm/components/CreateReservationForm/form/schemaValidation";

type ConsentSignatureFooterProps = {
  control: Control<CreateReservationFormState | ConfirmReservationFormState>;
  formId: string;
  watch: UseFormWatch<CreateReservationFormState | ConfirmReservationFormState>;
  setValue: UseFormSetValue<any>;
  isSubmitting: boolean;
  onDialogClose: () => void;
  consentSignature: string;
  userConsentAgreement: boolean;
  onConsentSignatureChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onConsentAgreementClick: () => void;
  hasCancellationPolicy: boolean;
};

function ConsentSignatureFooter(props: ConsentSignatureFooterProps) {
  const {
    formId,
    watch,
    setValue,
    isSubmitting,
    onDialogClose,
    consentSignature,
    userConsentAgreement,
    onConsentSignatureChange,
    onConsentAgreementClick,
    hasCancellationPolicy,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const context = useFormContext();

  // derived state
  const control = props.control || context.control;
  const isTermsAccepted = watch("isTermsAccepted");

  // event handlers
  const handleCheckBoxToggle = () => {
    onConsentAgreementClick();
    setValue("isTermsAccepted", !isTermsAccepted);
  };

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      px={isMobileView ? 2 : 4}
      py={isMobileView ? 1 : 2}
      borderTop={`1px solid ${grayLight}`}
      bgcolor={white}
      sx={{ position: "sticky", bottom: "0px" }}
    >
      <Box display="flex" flexDirection="column" width="100%">
        <Box
          mb={1}
          mx={isMobileView ? -2 : -4}
          borderBottom={`1px solid ${grayLight}`}
        >
          <Box px={3} py={1}>
            <Box
              display="flex"
              flexDirection={isMobileView ? "column" : "row"}
              justifyContent="space-between"
            >
              <Controller
                control={control}
                name="printedName"
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      fullWidth
                      value={consentSignature}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        field.onChange(e);
                        onConsentSignatureChange(e);
                      }}
                      label="Type Name"
                      sx={{ maxWidth: "35%" }}
                    />
                  );
                }}
              />

              <TextField
                value={consentSignature}
                label="Your Signature"
                fullWidth
                inputProps={{
                  style: {
                    textAlign: "center",
                    fontSize: "40px",
                    fontWeight: 500,
                    fontFamily: "DancingScript",
                    border: `4px solid ${grayLight}`,
                    borderRadius: "4px",
                  },
                }}
                InputProps={{
                  sx: {
                    "& fieldset": {
                      border: `2px dashed ${grayDark}`,
                    },
                    pointerEvents: "none",
                  },
                }}
                sx={{
                  ...(isMobileView ? { mt: 2 } : { ml: 2, maxWidth: "60%" }),
                }}
              />
            </Box>

            <Box display="flex" my={2} alignItems="center">
              <Checkbox
                checked={userConsentAgreement}
                onClick={handleCheckBoxToggle}
                sx={{ padding: 0 }}
              />
              <Typography variant="body2" ml={1}>
                {`I agree to the Terms & Conditions${
                  hasCancellationPolicy
                    ? " including the Cancellation Policy"
                    : ""
                }`}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display="flex" justifyContent="flex-end" pt={1}>
          <Box display="flex">
            <Button color="primary" onClick={onDialogClose}>
              Close
            </Button>
            <Box ml={2}>
              <LoadingButton
                disabled={
                  !consentSignature.trim().length || !userConsentAgreement
                }
                disableRipple
                variant="contained"
                color="primary"
                loading={isSubmitting}
                type="submit"
                form={formId}
              >
                Sign Electronically
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ConsentSignatureFooter;
