import React from "react";
import { Controller, FormProvider, UseFormReturn } from "react-hook-form";

import {
  Box,
  Button,
  Divider,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

import { LocationAutoComplete } from "components/autocompletes";
import { RHFDateTimePicker } from "components/react-hook-form/date-time/RHFDateTimePicker";
import { RHFTextField } from "components/react-hook-form/text-field/RHFTextField";
import { white } from "design-system/colors";
import { TripState } from "../../IframeRequestPage";
import DudaIframeIncrementer from "../DudaIframeIncrementer";
import { OrderType, TripCategory } from "types";
import TripCategoryToggle from "pages/new/steps/info/components/TripCategoryToggle";
import { NumberFormatNatural } from "design-system/components/inputs/NumberFormat";
import { hourlyOptions } from "pages/new/steps/info/components/data";
import { RHFAutocomplete } from "components/react-hook-form/autocomplete/RHFAutocomplete";
import { useFilteredOrderTypes } from "./hooks/useFilterOrderTypes";
import { PoweredByMoovsIcon } from "design-system/icons";

type IframeRequestFormProps = {
  methods: UseFormReturn<TripState>;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  isSample: boolean;
  enabledTripCategories: TripCategory[];
  enabledOrderTypes: OrderType[];
};

function IframeRequestForm(props: IframeRequestFormProps) {
  const {
    methods,
    onSubmit,
    isSample,
    enabledTripCategories,
    enabledOrderTypes,
  } = props;
  const { watch } = methods;

  // hooks
  const { filteredOrderTypes, handleFilterOrderType } = useFilteredOrderTypes({
    enabledOrderTypes,
  });

  // watch
  const tripCategory = watch("tripCategory", enabledTripCategories[0]);
  const orderType = watch("orderType");

  return (
    <FormProvider {...methods}>
      <form
        noValidate // prevents default html validation
        onSubmit={(e) => {
          e.stopPropagation();
          onSubmit(e);
        }}
        id={FORM_ID}
      >
        <Box
          display="flex"
          flexDirection="column"
          height="950px"
          width="100%"
          justifyContent="flex-start"
          bgcolor={white}
        >
          {/* Header */}
          <Box>
            <Typography sx={{ mx: 3, my: 2 }} variant="h3">
              Find a vehicle to book
            </Typography>

            <Divider />
          </Box>

          {/* Trip Type */}
          <Box mx={3} mt={1}>
            <Box mb={0.5}>
              <Typography variant="h4">Trip Type</Typography>
            </Box>

            <Box>
              <Controller
                name="tripCategory"
                control={methods.control}
                render={({ field }) => {
                  const { value, onChange } = field;
                  return (
                    <TripCategoryToggle
                      onChange={onChange}
                      enabledTripCategories={enabledTripCategories}
                      value={value}
                    />
                  );
                }}
              />
            </Box>

            {tripCategory === TripCategory.Hourly && (
              <Box mt={1}>
                <RHFTextField
                  name="totalDuration"
                  required
                  select
                  fullWidth
                  id="duration"
                  label="Duration"
                  variant="outlined"
                  InputProps={{
                    inputComponent: NumberFormatNatural as any,
                  }}
                >
                  {hourlyOptions.map((option) => (
                    <MenuItem key={option} value={option * 60}>
                      {!!option ? `${option}h` : ""}
                    </MenuItem>
                  ))}
                </RHFTextField>
              </Box>
            )}
          </Box>

          {/* Order Type */}
          <Box mx={3} mt={1}>
            <Box mb={0.5}>
              <Typography variant="h4">Order Type</Typography>
            </Box>

            <Box>
              <RHFAutocomplete
                name="orderType"
                disabled={!enabledOrderTypes.length}
                options={filteredOrderTypes}
                transform={{
                  input: (value) => value?.name,
                }}
                groupBy={(option) => option.category}
                getOptionLabel={(option) => option.name || ""}
                filterOptions={(options) => options}
                renderOption={(props, { name }) => (
                  <li {...props}>
                    <Typography variant="body2">{name}</Typography>
                  </li>
                )}
                onInputChange={handleFilterOrderType}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Order Type"
                    fullWidth
                    required
                    variant="outlined"
                    value={orderType}
                  />
                )}
              />
            </Box>
          </Box>

          {/* Date & Time */}
          <Box mx={3} mt={1}>
            {/* Pick-up */}
            <Box>
              <Typography sx={{ mb: 0.5 }} variant="h4">
                Pick-up Date & Time
              </Typography>

              <RHFDateTimePicker
                name="dateTime"
                clearable
                inputFormat="ddd, MMM Do, YYYY, h:mm a"
                label={"Departure Date & Time"}
                renderInputProps={{
                  name: "dateTime",
                  required: true,
                }}
                displayConfirmTime
              />
            </Box>

            {/* Return */}
            {tripCategory === TripCategory.RoundTrip && (
              <Box mt={1}>
                <Typography sx={{ mb: 0.5 }} variant="h4">
                  Return Date & Time
                </Typography>

                <RHFDateTimePicker
                  name="returnDateTime"
                  clearable
                  inputFormat="ddd, MMM Do, YYYY, h:mm a"
                  label={"Departure Date & Time"}
                  renderInputProps={{
                    name: "dateTime",
                    required: true,
                  }}
                  displayConfirmTime
                />
              </Box>
            )}
          </Box>

          {/* Pick-Up Location */}
          <Box mx={3} mt={1}>
            <Typography sx={{ mb: 0.5 }} variant="h4">
              Pick-Up
            </Typography>

            <LocationAutoComplete
              name="pickupLocation"
              label="Address"
              fullWidth
              required
              transform={{
                input: (value) => value?.description,
              }}
              inputSx={{
                "&.MuiAutocomplete-inputRoot": { backgroundColor: white },
              }}
            />
          </Box>

          {/* Drop-off Location */}
          <Box mx={3} mt={1}>
            <Typography sx={{ mb: 0.5 }} variant="h4">
              Drop-off
            </Typography>

            <LocationAutoComplete
              name="dropoffLocation"
              label="Address"
              fullWidth
              required
              transform={{
                input: (value) => value?.description,
              }}
              inputSx={{
                "&.MuiAutocomplete-inputRoot": { backgroundColor: white },
              }}
            />
          </Box>

          {/* Additional Trip Details */}
          <Box mx={3} mt={1} mb={2}>
            <Typography variant="h4">Additional Trip Details</Typography>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              minHeight={80}
            >
              <Box>
                <Typography sx={{ mr: 2 }} variant="body2">
                  Passenger Count
                </Typography>
              </Box>

              <DudaIframeIncrementer />
            </Box>

            {/* Note */}
            <Box mt={1}>
              <RHFTextField
                name="note"
                fullWidth
                multiline={!isSample} // prevents content overflow on operator widget sample
                variant="outlined"
                label="Special request? Anything else?"
              />
            </Box>
          </Box>

          <Box justifyContent="flex-end" mt="auto">
            <Divider />

            <Box my={3} mx={3} display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <PoweredByMoovsIcon />
              </Box>
              <Button
                variant="contained"
                sx={[{ width: "116px" }, isSample && { pointerEvents: "none" }]}
                type="submit"
                color="primary"
                form={FORM_ID}
              >
                Search
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
}

export default IframeRequestForm;

// constants
const FORM_ID = "iframe-request-trip";
