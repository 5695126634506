import React from "react";
import { TemporaryPassenger } from "types";

import ATICreateWrapper from "./components/ATICreateWrapper";
import ATIUpdateWrapper from "./components/ATIUpdateWrapper";

type AdditionalTripInfoDialogProps = {
  mode: "create" | "update";
  onClose: () => void;
  open: boolean;
  trip: {
    id: string;
    totalGroupSize: number;
    note?: string;
    temporaryPassenger?: TemporaryPassenger;
    routes: {
      id: string;
      carryOnLuggage?: number;
      checkedLuggage?: number;
      oversizeLuggage?: number;
    }[];
  };
};

function AdditionalTripInfoDialog(props: AdditionalTripInfoDialogProps) {
  const { mode, onClose, open, trip } = props;

  if (mode === "create") {
    return <ATICreateWrapper onClose={onClose} open={open} trip={trip} />;
  }

  return <ATIUpdateWrapper onClose={onClose} open={open} trip={trip} />;
}

export default AdditionalTripInfoDialog;
