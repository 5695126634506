import { TextRecipientEnum } from "types";

const getRecipient = (
  isRecipientPassenger: boolean,
  isRecipientBookingContact: boolean
) => {
  if (isRecipientBookingContact && isRecipientPassenger) {
    return TextRecipientEnum.Both;
  }

  if (isRecipientBookingContact && !isRecipientPassenger) {
    return TextRecipientEnum.BookingContact;
  }

  if (isRecipientPassenger && !isRecipientBookingContact) {
    return TextRecipientEnum.Passenger;
  }

  return TextRecipientEnum.None;
};

export default getRecipient;
