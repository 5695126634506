import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { usePubNub } from "pubnub-react";
import Pubnub from "pubnub";
import map from "lodash/map";
import filter from "lodash/filter";
import size from "lodash/size";

import { Trip } from "../../../types";
import { useOperator } from "../../../globals/hooks/useOperator";
import { PubNubMessage, TrackedDriver } from "../types";

function useTrackedDriver(trips: Trip[]) {
  // hooks
  const pubnub = usePubNub();
  const { operator } = useOperator();

  // refs
  const hasInitialized = useRef(false);

  // state
  const [trackedDrivers, setTrackedDrivers] = useState<
    Map<string, TrackedDriver>
  >(new Map());

  // derived state
  const activeOrUpcomingTrips = useMemo(() => {
    const activeTrips = filter(
      trips,
      ({ routes }) => routes[0].dispatchStatus !== "done"
    );

    return new Map(map(activeTrips, (trip) => [trip.id, trip]));
  }, [trips]);

  const routeIds = useMemo(() => {
    const routeIdArray = [];
    for (const trip of activeOrUpcomingTrips.values()) {
      const routeId = trip.routes[0].id;
      routeIdArray.push(routeId);
    }
    return routeIdArray;
  }, [activeOrUpcomingTrips]);

  // event handlers
  // handle incoming pubnub messages
  const handleMessage = useCallback(
    (messageEvent: Pubnub.MessageEvent) => {
      const { tripId, location }: PubNubMessage = messageEvent.message;

      const activeTrip = activeOrUpcomingTrips.get(tripId);

      if (!activeTrip) return;

      setTrackedDrivers((prevTrackedDrivers) => {
        prevTrackedDrivers.set(tripId, {
          location,
          ...activeTrip.routes[0].driver,
        });

        return new Map(prevTrackedDrivers);
      });
    },
    [activeOrUpcomingTrips]
  );

  // effects
  // initialize
  useEffect(() => {
    // prevent initializing more than once,
    // but wait for incoming trips query to initialize
    if (hasInitialized.current || !size(activeOrUpcomingTrips)) return;
    hasInitialized.current = true;

    const channels = [operator.id, ...routeIds];
    // call pubnub to get most recent 100 messages.
    // filter for unique drivers that are included in trips
    // (and the trip is not closed)
    const fetchMessages = async () => {
      const response = await pubnub.fetchMessages({ channels });
      const messages = response.channels[channels[0]];

      if (!messages) return;

      messages.forEach(({ message }: { message: PubNubMessage }) => {
        const { tripId, location } = message;

        const activeTrip = activeOrUpcomingTrips.get(tripId);
        if (!activeTrip) return;

        trackedDrivers.set(tripId, {
          location,
          ...activeTrip.routes[0].driver,
        });
      });

      setTrackedDrivers(new Map(trackedDrivers));
    };

    fetchMessages();
  }, [activeOrUpcomingTrips, operator?.id, pubnub, trackedDrivers, routeIds]);

  // clean up handleMessage callback
  useEffect(() => {
    pubnub.addListener({ message: handleMessage });

    return () => {
      pubnub.removeListener({ message: handleMessage });
    };
  }, [handleMessage, pubnub]);

  // clean up subscription
  useEffect(() => {
    const channels = [operator?.id, ...routeIds];
    pubnub.subscribe({ channels });

    return () => {
      pubnub.unsubscribe({ channels });
    };
  }, [pubnub, operator?.id, routeIds]);

  // remove tracked drivers without corresponding active trip
  useEffect(() => {
    let hasTriggeredChange = false;

    trackedDrivers.forEach((_, tripId) => {
      if (!activeOrUpcomingTrips.has(tripId)) {
        trackedDrivers.delete(tripId);
        hasTriggeredChange = true;
      }
    });

    // prevent loop by only updating if there is a change
    if (hasTriggeredChange) {
      setTrackedDrivers(new Map(trackedDrivers));
    }
  }, [activeOrUpcomingTrips, trackedDrivers]);

  return { trackedDriver: [...trackedDrivers.values()] };
}

export { useTrackedDriver };
