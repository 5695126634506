/**
 * @file MoovsMenu.tsx
 * Reusable drop down menu w/ moovs styling.
 */
import React, { ComponentType, ReactNode, useRef, useState } from "react";

import {
  Box,
  Button,
  MenuList,
  MenuItem,
  Typography,
  styled,
} from "@mui/material";

import { granite, grayDark, grayLight } from "../../design-system/colors";
import AnchorPopper from "./AnchorPopper";

type IconComponent = {
  color: string;
  size: "small";
};

type MoovsMenuProps = {
  buttonContent: ReactNode;
  menuItems: {
    label: string;
    icon?: ComponentType<IconComponent>;
    value?: string; // comparison used for selected
    onClick: () => void;
  }[];
  selectedItem?: string;
};

function MoovsMenu(props: MoovsMenuProps) {
  const { buttonContent, menuItems } = props;

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  // event handlers
  const handleMenuOpenToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleMenuItemClick = (callback: () => void) => () => {
    callback();
    handleMenuOpenToggle();
  };

  return (
    <>
      <Box sx={{ "& .MuiButton-text": { padding: "6px 16px" } }}>
        <Button
          disableTouchRipple
          ref={anchorRef}
          variant={open ? "contained" : "text"}
          onClick={handleMenuOpenToggle}
        >
          {buttonContent}
        </Button>
      </Box>
      <AnchorPopper
        open={open}
        anchorRef={anchorRef.current}
        onClickAway={() => setOpen(false)}
      >
        <MenuList autoFocusItem={open}>
          {menuItems.map(({ icon, label, onClick, value }) => {
            //const isSelected = selectedItem && selectedItem === value;

            const Icon = icon;

            const StyledMenuItem = styled(MenuItem)(() => ({
              minWidth: 191,
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }));

            return (
              <StyledMenuItem
                // selected={isSelected}
                // not passed in parent component
                sx={{
                  justifyContent: "center",
                  marginY: "2px",
                  marginX: "4px",
                  textAlign: "center",
                  width: "95%",
                  ":hover": {
                    backgroundColor: grayLight,
                    borderRadius: "5px",
                  },
                  // "& .Mui-selected": {
                  //   ...(isSelected && styles.selectedMenuItem),
                  // },
                }}
                key={value || label}
                onClick={handleMenuItemClick(onClick)}
              >
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  width="100%"
                >
                  {icon && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      mr={1.5}
                    >
                      <Icon color={grayDark} size="small" />
                    </Box>
                  )}
                  <Typography style={{ color: granite }}>{label}</Typography>
                </Box>
              </StyledMenuItem>
            );
          })}
        </MenuList>
      </AnchorPopper>
    </>
  );
}

export default MoovsMenu;
