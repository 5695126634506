import React from "react";

type GameConsoleIconProps = {
  color?: string;
  size: "small";
};

const GameConsoleIcon = (props: GameConsoleIconProps) => {
  const { color } = props;

  // size === small
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.41199 5.33337H6.13744M4.77471 4.00004V6.66671M9.54425 6.00004H9.55107M11.5883 4.66671H11.5952M6.44334 1.33337H8.55699C10.3455 1.33337 11.2397 1.33337 11.9417 1.66499C12.5597 1.95697 13.0731 2.42477 13.4148 3.0071C13.8029 3.66851 13.8666 4.54124 13.994 6.28672L14.1617 8.58304C14.2439 9.70899 13.3325 10.6667 12.1788 10.6667C11.5888 10.6667 11.0293 10.4103 10.6516 9.96684L10.396 9.66671C10.1622 9.3922 10.0452 9.25491 9.91211 9.14401C9.63305 8.91149 9.29521 8.75667 8.934 8.69576C8.7617 8.66671 8.57908 8.66671 8.21385 8.66671H6.78648C6.42125 8.66671 6.23863 8.66671 6.06633 8.69576C5.70511 8.75667 5.36728 8.91149 5.08821 9.14401C4.95509 9.25492 4.83819 9.39218 4.60437 9.66671L4.34875 9.96684C3.97105 10.4103 3.41154 10.6667 2.82156 10.6667C1.66786 10.6667 0.756453 9.70899 0.83865 8.58304L1.00629 6.28672C1.13371 4.54124 1.19743 3.66851 1.58551 3.0071C1.92719 2.42477 2.44067 1.95697 3.05867 1.66499C3.76059 1.33337 4.65484 1.33337 6.44334 1.33337Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GameConsoleIcon;
