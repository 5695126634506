import { FirebaseApp, initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import { getStorage, FirebaseStorage } from "firebase/storage";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
};

let storage: FirebaseStorage;
let auth: Auth;
let firebaseApp: FirebaseApp;

try {
  firebaseApp = initializeApp(config);
  storage = getStorage(firebaseApp);
  auth = getAuth(firebaseApp);
} catch (error) {
  console.error(
    "Error initializing Firebase, ensure firebase environment variables are set and contain valid values:",
    error
  );
}

export { storage, auth, firebaseApp as default };
