import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import isNil from "lodash/isNil";

import { Box, Button, Card, Link, Typography } from "@mui/material";

import { useAnalytics, useScreenSize } from "globals/hooks";
import { currency } from "utils/helpers";
import { OpenIcon, CreditCardIcon } from "design-system/icons";
import { granite } from "design-system/colors";
import CancellationPolicyDialog from "../CancellationPolicyDialog/CancellationPolicyDialog";
import DownloadButton from "../buttons/DownloadButton";
import { Request } from "types";
import { useCancellationPolicies } from "./form/components/TermsAndConditionsBlock/hooks/useCancellationPolicies";

type ConfirmedReservationPriceCardProps = {
  request: Request;
};

function ConfirmedReservationPriceCard(
  props: ConfirmedReservationPriceCardProps
) {
  const { request } = props;

  // state
  const [isCancellationPolicyOpen, setIsCancellationPolicyOpen] =
    useState(false);
  // hooks
  const { isMobileView } = useScreenSize();
  const { operatorSlug, requestId } = useParams<{
    operatorSlug: string;
    requestId: string;
  }>();
  const { track } = useAnalytics();
  const { cancellationPolicies, shouldRenderCancellationPolicies } =
    useCancellationPolicies({ trips: request.trips });

  // memo
  const showBlock = useMemo(() => {
    // Check if any trip has a non-null baseRateAmt
    const anyTripHasNonNullBaseRate = request.trips.some(
      (trip) => !isNil(trip.routes[0].pricing?.baseRateAmt)
    );

    return anyTripHasNonNullBaseRate;
  }, [request.trips]);

  // event handlers
  const handleViewPriceSummaryClick = () => {
    track("reservationConfirmation_viewPricesummary");
    window.open(`/${operatorSlug}/price-summary/${requestId}`, "_blank");
  };

  const handleCancellationPolicyOpen = () => {
    setIsCancellationPolicyOpen(true);
  };
  const handleCancellationPolicyClose = () => {
    setIsCancellationPolicyOpen(false);
  };

  const { last4, brand, expYear, expMonth } =
    request?.preferredPaymentMethod?.card || {};

  if (!showBlock) {
    return null;
  }

  return (
    <Card variant="outlined" sx={{ mb: isMobileView ? 2 : 3 }}>
      <Box
        display="flex"
        flexDirection={isMobileView ? "column" : "row"}
        justifyContent="space-between"
        alignItems="center"
        m={3}
      >
        <Box {...(isMobileView && { mb: 2 })}>
          <Typography variant="h3">
            Price - {currency(request.totalAmount)}
          </Typography>
        </Box>
        {isMobileView && request?.preferredPaymentMethod && (
          <Box
            mb={2}
            mx={3}
            display="flex"
            flexDirection="row"
            alignItems="center"
            {...(isMobileView && { justifyContent: "center" })}
          >
            <Box mr={2}>
              <CreditCardIcon brand={brand} />
            </Box>
            <Typography
              variant="caption"
              style={{ whiteSpace: "pre" }}
            >{`****-${last4} Exp:    ${expMonth}/${expYear
              .toString()
              .slice(-2)}`}</Typography>
          </Box>
        )}
      </Box>
      <Box
        display="flex"
        flexDirection={isMobileView ? "column" : "row"}
        justifyContent="space-between"
        alignItems="center"
        m={2}
      >
        <Button
          color="primary"
          variant="outlined"
          fullWidth={isMobileView}
          startIcon={<OpenIcon size="small" />}
          onClick={handleViewPriceSummaryClick}
        >
          View Price Summary
        </Button>
        <Box alignSelf="center" {...(isMobileView && { mt: 2 })}>
          <DownloadButton
            endpoint={"/endpoint/priceSummary/customerPdfDownloadHandler"}
            buttonText={"Price Summary PDF"}
            params={{
              id: requestId,
            }}
            analyticsName="reservationConfirmation_priceSummary"
            variant="desktop"
          />
        </Box>
      </Box>
      {!isMobileView && request?.preferredPaymentMethod && (
        <Box
          my={2}
          mx={3}
          display="flex"
          flexDirection="row"
          alignItems="center"
          {...(isMobileView && { justifyContent: "center" })}
        >
          <Box mr={2}>
            <CreditCardIcon brand={brand} />
          </Box>
          <Typography
            variant="caption"
            style={{ whiteSpace: "pre" }}
          >{`****-${last4} Exp:    ${expMonth}/${expYear
            .toString()
            .slice(-2)}`}</Typography>
        </Box>
      )}
      <Box
        m={3}
        {...(isMobileView && { display: "flex", justifyContent: "center" })}
      >
        <Typography variant="body2" style={{ color: granite }}>
          View our{" "}
          <Link href={`/${operatorSlug}/terms-and-conditions`} target="_blank">
            Terms & Conditions
          </Link>
          {shouldRenderCancellationPolicies && (
            <>
              {" "}
              and{" "}
              {
                <>
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={handleCancellationPolicyOpen}
                  >
                    Cancellation Policy.
                  </Link>
                  <CancellationPolicyDialog
                    cancellationPolicyOpen={isCancellationPolicyOpen}
                    onCancellationPolicyClose={handleCancellationPolicyClose}
                    cancellationPolicies={cancellationPolicies}
                  />
                </>
              }
            </>
          )}
        </Typography>
      </Box>
    </Card>
  );
}

export default ConfirmedReservationPriceCard;
