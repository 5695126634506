import React from "react";

import { useTheme } from "@mui/material";
import StepConnector, { StepConnectorProps } from "@mui/material/StepConnector";

import { grayLight } from "design-system/colors";
import { useScreenSize } from "globals/hooks";

function ProgressConnector(props: StepConnectorProps) {
  // hooks
  const { isMobileView } = useScreenSize();
  const theme = useTheme();

  return (
    <StepConnector
      sx={{
        "&.MuiStepConnector-alternativeLabel": {
          top: "20px",
          left: `calc(-50% - ${isMobileView ? 8 : 24}px)`,
          width: `${isMobileView ? 52.5 : 100}px`,
        },
        "&.Mui-active": {
          "& .MuiStepConnector-line": {
            backgroundColor: "primary.main",
          },
        },
        "&.Mui-completed": {
          "& .MuiStepConnector-line": {
            backgroundColor: "primary.main",
          },
        },
        "&.Mui-disabled": {
          "& .MuiStepConnector-line": {
            background: `linear-gradient(90deg, ${theme.palette.primary.main} 33%, ${grayLight} 33%)`,
          },
        },
        "& .MuiStepConnector-line": {
          borderTopWidth: 0,
          borderRadius: "5px",
          backgroundColor: grayLight,
          height: "5px",
        },
      }}
      {...props}
    />
  );
}

export default ProgressConnector;
