import React from "react";

type LockIconProps = {
  color?: string;
  size?: "small";
};

const LockIcon = (props: LockIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.22222 6.95V4.75C5.22222 4.02065 5.51488 3.32118 6.03581 2.80546C6.55675 2.28973 7.26329 2 8 2C8.73671 2 9.44325 2.28973 9.96419 2.80546C10.4851 3.32118 10.7778 4.02065 10.7778 4.75V6.95M4.11111 6.95H11.8889C12.5025 6.95 13 7.44249 13 8.05V11.9C13 12.5075 12.5025 13 11.8889 13H4.11111C3.49746 13 3 12.5075 3 11.9V8.05C3 7.44249 3.49746 6.95 4.11111 6.95Z"
          stroke={color || "#B3B3B3"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.77778 11.1V7.5C7.77778 6.30653 8.2753 5.16193 9.16088 4.31802C10.0465 3.47411 11.2476 3 12.5 3C13.7524 3 14.9535 3.47411 15.8391 4.31802C16.7247 5.16193 17.2222 6.30653 17.2222 7.5V11.1M5.88889 11.1H19.1111C20.1543 11.1 21 11.9059 21 12.9V19.2C21 20.1941 20.1543 21 19.1111 21H5.88889C4.84568 21 4 20.1941 4 19.2V12.9C4 11.9059 4.84568 11.1 5.88889 11.1Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LockIcon;
