/**
 * @file GoogleStaticMap.tsx
 * Google map of a trip.
 *
 * Uses GoogleStaticMaps API
 * reference: https://developers.google.com/maps/documentation/maps-static/overview
 */
import React from "react";

import { Box } from "@mui/material";

import { useScreenSize } from "globals/hooks";

type GoogleStaticMapProps = {
  googleDirectionsResult: any;
  aspectRatio: number;
};

function GoogleStaticMap(props: GoogleStaticMapProps) {
  const { googleDirectionsResult, aspectRatio } = props;

  const { isMobileView } = useScreenSize();

  if (!googleDirectionsResult) {
    return null;
  }

  const leg = googleDirectionsResult.routes[0].legs[0];
  const origin = `${leg.start_location.lat},${leg.start_location.lng}`;
  const destination = `${leg.end_location.lat},${leg.end_location.lng}`;
  const waypoints = leg.via_waypoint
    .map(({ location }, i) => {
      return `&markers=color:black|label:${i + 1}|${location.lat},${
        location.lng
      }`;
    })
    .join("|");

  const sizeFactor = isMobileView ? 200 : 400;
  const fixedAspectRatio = Number(aspectRatio.toFixed(2));

  const mapOptions = {
    key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    size: `${sizeFactor * fixedAspectRatio}x${sizeFactor}`,
    scale: "2", // scale 2 for retina displays
    format: "png",
    path: `color:0x719EF1FF|weight:4|enc:${googleDirectionsResult.routes[0].overview_polyline?.points}`,
  };

  //orange for origin, purple for destination and granite for waypoints
  const markers = `markers=color:0xFC881D|${origin}${waypoints}&markers=color:0xA444B7|${destination}`;

  const searchParams = new URLSearchParams(mapOptions);

  return (
    <Box width="100%" borderRadius="4px" overflow="hidden">
      <img
        alt="map"
        src={`https://maps.googleapis.com/maps/api/staticmap?${searchParams}&${markers}`}
        style={{
          objectFit: "contain",
          width: "100%",
        }}
      />
    </Box>
  );
}

export default GoogleStaticMap;
