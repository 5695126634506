import React from "react";

import { Box, Typography } from "@mui/material";

import { black, granite } from "../../../../../../design-system/colors";
import { currency } from "../../../../../../utils/helpers";

type TotalPricingLineItemProps = {
  tripIndex: number;
  tripTotal: number;
};

export function TotalPricingLineItem(props: TotalPricingLineItemProps) {
  const { tripIndex, tripTotal } = props;

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      mb={1.5}
    >
      <Typography variant="body2" style={{ color: black }}>
        Trip {tripIndex}
      </Typography>
      <Typography variant="body2" style={{ color: granite }}>
        {currency(tripTotal)}
      </Typography>
    </Box>
  );
}

export default TotalPricingLineItem;
