import React, { useState } from "react";

import { Box, Typography } from "@mui/material";

import CustomGratuityInput from "components/confirm/form/components/DriverGratuityBlock/components/CustomGratuityInput";
import GratuityToggleButtonGroup from "components/confirm/form/components/DriverGratuityBlock/components/GratuityToggleButtonGroup";
import { useOperator } from "globals/hooks";
import MoovsTooltip from "components/globals/MoovsTooltip";
import { InfoIcon } from "design-system/icons";
import { grayDark } from "design-system/colors";
import { currency } from "utils/helpers";

type PriceSummaryPaymentDriverGratuityBlockProps = {
  totalBaseRateAmt: number;
  driverGratuityPct: number;
  setDriverGratuityPct: React.Dispatch<React.SetStateAction<number>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  errorMessage: string;
};

function PriceSummaryPaymentDriverGratuityBlock(
  props: PriceSummaryPaymentDriverGratuityBlockProps
) {
  const {
    totalBaseRateAmt,
    driverGratuityPct,
    setDriverGratuityPct,
    setErrorMessage,
    errorMessage,
  } = props;

  // hooks
  const { operator } = useOperator();

  // state
  const [customGratuityInputOpen, setCustomGratuityInputOpen] = useState(false);

  const handleGratuityButtonChange = (pct: number) => {
    setErrorMessage(null);
    setDriverGratuityPct(pct);
    setCustomGratuityInputOpen(false);
  };

  const handleCustomGratuityChange = (pct: number) => {
    setErrorMessage(null);
    setCustomGratuityInputOpen(true);
    setDriverGratuityPct(pct);
  };

  return (
    <Box p={4}>
      <Box mb={2}>
        <Box display="flex" alignItems="center">
          <Typography variant="overline" mr={1} mt={-0.5}>
            Add Gratuity
          </Typography>
          <MoovsTooltip
            noMaxWidth
            tooltipText={`Based off the base rate: ${currency(
              totalBaseRateAmt
            )}`}
          >
            <InfoIcon size="small" color={grayDark} />
          </MoovsTooltip>
        </Box>
      </Box>
      <GratuityToggleButtonGroup
        value={driverGratuityPct}
        baseRateAmt={totalBaseRateAmt}
        onChange={handleGratuityButtonChange}
        customGratuityInputOpen={customGratuityInputOpen}
        hideCashOption
      />

      {/* custom gratuity */}
      {operator.customGratuityWhenBooking && (
        <>
          <CustomGratuityInput
            value={driverGratuityPct}
            baseRateAmt={totalBaseRateAmt}
            onChange={handleCustomGratuityChange}
            customGratuityInputOpen={customGratuityInputOpen}
          />
          <Box mb={1}>
            <Typography color="error" variant="caption">
              {errorMessage}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
}

export default PriceSummaryPaymentDriverGratuityBlock;
