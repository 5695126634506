import React, { useMemo } from "react";
import toArray from "lodash/toArray";
import groupBy from "lodash/groupBy";

import { Tabs, Tab, useTheme } from "@mui/material";

type MobileTripTabsProps = {
  tripTabs: {
    value: number;
    label: string;
  }[];
  onTabSelect: (
    e: React.SyntheticEvent<Element, Event>,
    tabIndex: number
  ) => void;
  activeTripIndex: number;
};

function MobileTripTabs(props: MobileTripTabsProps) {
  const { tripTabs, onTabSelect, activeTripIndex } = props;

  // hooks
  const theme = useTheme();

  // mobileTabs is an array of tab arrays grouped in two.
  // reference: https://stackoverflow.com/questions/20257889/unflatten-arrays-into-groups-of-fours
  const mobileTabs = useMemo(() => {
    return toArray(
      groupBy(tripTabs, (trip) => {
        return Math.floor(trip.value / 2);
      })
    );
  }, [tripTabs]);

  return (
    <>
      {mobileTabs.map((tabRow, index) => (
        <Tabs
          key={index}
          sx={{
            "& .MuiTabs-root": {
              [theme.breakpoints.up("md")]: {
                width: "calc(min(100vw, 1140px) * .6 - 24px)",
              },
              [theme.breakpoints.up("sm")]: {
                width: "calc(min(100vw - 32px)",
              },
            },
            "& .MuiTabs-indicator": {
              backgroundColor: theme.palette.primary.main,
            },
          }}
          onChange={onTabSelect}
          value={tabRow.find(({ value }) => value === activeTripIndex)?.value}
          variant="fullWidth"
        >
          {tabRow.map(({ value, label }) => (
            <Tab
              key={value}
              value={value}
              label={label}
              sx={{
                "& .MuiTab-root": {
                  minWidth: 120,
                },
                "& .MuiTab-indicator": {
                  color: theme.palette.primary.main,
                },
              }}
            />
          ))}
        </Tabs>
      ))}
    </>
  );
}

export default MobileTripTabs;
