import React from "react";

import { useTheme } from "@mui/material";

import { RHFMoovsIncrementer } from "components/react-hook-form/RHFMoovsIncrementer";
import { MIN_GROUP_SIZE, MAX_GROUP_SIZE } from "../data";

type DudaIframeIncrementerProps = {
  buttonColor?:
    | "dudaGreen"
    | "dudaOrangeRed"
    | "dudaLightBlue"
    | "dudaDarkBlue";
};

function DudaIframeIncrementer(props: DudaIframeIncrementerProps) {
  const { buttonColor } = props;

  // hooks
  const theme = useTheme();

  return (
    <RHFMoovsIncrementer
      name="totalGroupSize"
      limits={[MIN_GROUP_SIZE, MAX_GROUP_SIZE]}
      ButtonProps={{
        ...(buttonColor && { color: buttonColor }),
        ...(!buttonColor && { variant: "text" }),
      }}
      iconColor={
        buttonColor
          ? theme.palette[buttonColor].main
          : theme.palette.primary.main
      }
    />
  );
}

export default DudaIframeIncrementer;
