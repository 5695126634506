/**
 * @file TopBar.tsx
 * Toolbar that contains button to expand to show navigation menu on mobile.
 *
 * reference: https://material-ui.com/components/drawers/#responsive-drawer
 *
 * reactComponents:
 *  TopBar
 */

import React from "react";
import { useHistory } from "react-router-dom";
import { Box, useTheme } from "@mui/material";

import { useOperator, useScreenSize } from "../globals/hooks";

function TopBar() {
  const { operator } = useOperator();

  const theme = useTheme();
  const { isMobileView } = useScreenSize();

  const { companyLogoUrl, widgetBannerUrl, name } = operator || {};

  // hooks
  const history = useHistory();

  const isPayPage = history.location.pathname.includes("/pay");

  // set favicon to companyLogoUrl
  const faviconEl: any = document.getElementById("favicon");
  faviconEl.href = companyLogoUrl;

  const handleLogoClick = () => {
    history.push(`/${operator.nameSlug}/new/info`);
  };

  // styles
  const appBarStyles = {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    width: "100%",
    maxWidth: "1140px",
    height: "120px",
    [theme.breakpoints.up("sm")]: {
      height: "160px",
    },
    zIndex: theme.zIndex.drawer + 1,
    backgroundImage: `url("${widgetBannerUrl}")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    boxShadow: "0 4px 15px 0 rgba(0, 0, 0, 0.03)",
  } as const;

  const logoStyles = {
    cursor: "pointer",
    width: "250px",
    height: "100px",
    [theme.breakpoints.down("sm")]: {
      height: "60px",
      width: "150px",
    },
    backgroundImage: `url("${companyLogoUrl}")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "transparent",
    color: isMobileView ? "black" : "white",
  } as const;

  if (isPayPage) {
    return null;
  }

  return isMobileView ? (
    <Box
      onClick={handleLogoClick}
      display="flex"
      sx={logoStyles}
      marginTop={1}
      alignItems="center"
      justifyContent="center"
    >
      {companyLogoUrl ? "" : name}
    </Box>
  ) : (
    <Box
      sx={appBarStyles}
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
    >
      <Box onClick={handleLogoClick} ml="69px" sx={logoStyles}>
        {companyLogoUrl ? "" : name}
      </Box>
    </Box>
  );
}

export default TopBar;
