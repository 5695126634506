/**
 * @file MoovsApolloProvider.tsx
 * Migration from Apollo Boost to Apollo Client to enable advanced configurations of Apollo Link.
 * Adds operator slug to header for backend authentication on requests.
 * For a more complex implementation see operator app.
 *
 * components:
 *  MoovsApolloProvider
 */
import React from "react";
import {
  InMemoryCache,
  ApolloClient,
  ApolloLink,
  HttpLink,
  ApolloProvider,
} from "@apollo/client";

import { useOperatorSlug } from "../../globals/hooks";
import { headerMiddlewareLink } from "./ApolloLinks";
import introspection from "../../possibleTypes";
import { getServerLink } from "globals/utils/getServerLink";

const cache = new InMemoryCache({
  possibleTypes: introspection.possibleTypes,
});

const client = (operatorSlug: string) =>
  new ApolloClient({
    link: ApolloLink.from([
      headerMiddlewareLink(operatorSlug),
      new HttpLink({
        uri: `${getServerLink()}/customer`,
        credentials: "include",
      }),
    ]),
    connectToDevTools: process.env.NODE_ENV !== "production",
    cache,
  });

function MoovsApolloProvider(props) {
  const { children } = props;
  const operatorSlug = useOperatorSlug();
  return (
    <ApolloProvider client={client(operatorSlug)}>
      {children}
    </ApolloProvider>
  );
}

export default MoovsApolloProvider;
