import { ApolloError, useQuery } from "@apollo/client";
import { LOAD_REQUESTS_QUERY } from "globals/graphql";
import map from "lodash/map";

import {
  LoadRequestsConnection,
  RequestStage,
  RequestTimeFrameEnum,
  Request,
} from "types";

type UseRequestsTuple = [Request[], ApolloError, boolean];

type Options = {
  stage: RequestStage;
  timeFrame: RequestTimeFrameEnum;
  skip?: boolean;
};

function useRequests(options: Options): UseRequestsTuple {
  const { stage, timeFrame, skip } = options;

  // queries
  const { data, error, loading } = useQuery<{
    loadRequests: LoadRequestsConnection;
  }>(LOAD_REQUESTS_QUERY, {
    skip,
    variables: {
      stage,
      timeFrame,
    },
    fetchPolicy: "cache-and-network",
  });

  const requests = skip
    ? []
    : map(data?.loadRequests?.edges, ({ node }) => node);

  return [requests, error, loading];
}

export { useRequests };
