import React from "react";

type KaraokeIconProps = {
  color?: string;
  size: "small";
};

const KaraokeIcon = (props: KaraokeIconProps) => {
  const { color } = props;

  // size === small
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0002 4.66663H13.3335M13.3335 4.66663H14.6668M13.3335 4.66663V3.33329M13.3335 4.66663V5.99996M9.3335 1.99996H10.6668M10.6668 1.99996H12.0002M10.6668 1.99996V0.666626M10.6668 1.99996V3.33329M1.3335 2.63411L8.66683 5.23573M5.66683 7.18695L3.30886 12.4774C3.21406 12.6921 3.04752 12.8632 2.84056 12.9585C2.63361 13.0537 2.40051 13.0666 2.18512 12.9947L1.97805 12.9255C1.76334 12.8536 1.58103 12.7023 1.46522 12.5C1.3494 12.2977 1.30801 12.0581 1.3488 11.8262L2.66683 6.21127M2.00016 13.0406L1.46522 14.6666M10.6668 13.3333V9.40822C10.6668 9.23227 10.7235 9.06078 10.8288 8.91802C10.9342 8.77526 11.0828 8.66846 11.2538 8.61274L13.5372 8.04295C13.6664 8.0009 13.804 7.98948 13.9386 8.00964C14.0733 8.0298 14.201 8.08095 14.3114 8.15887C14.4218 8.23678 14.5116 8.33923 14.5734 8.45774C14.6352 8.57624 14.6672 8.70741 14.6668 8.84039V12.2042M8.00016 4.26012C8.00016 5.87657 6.65702 7.18695 5.00016 7.18695C3.34331 7.18695 2.00016 5.87657 2.00016 4.26012C2.00016 2.64368 3.34331 1.33329 5.00016 1.33329C6.65702 1.33329 8.00016 2.64368 8.00016 4.26012ZM10.6668 13.6666C10.6668 14.2189 10.2191 14.6666 9.66683 14.6666C9.11454 14.6666 8.66683 14.2189 8.66683 13.6666C8.66683 13.1143 9.11454 12.6666 9.66683 12.6666C10.2191 12.6666 10.6668 13.1143 10.6668 13.6666ZM14.6668 12.3333C14.6668 12.5311 14.6082 12.7244 14.4983 12.8889C14.3884 13.0533 14.2322 13.1815 14.0495 13.2572C13.8668 13.3329 13.6657 13.3527 13.4717 13.3141C13.2778 13.2755 13.0996 13.1803 12.9597 13.0404C12.8199 12.9005 12.7246 12.7224 12.686 12.5284C12.6475 12.3344 12.6673 12.1333 12.743 11.9506C12.8186 11.7679 12.9468 11.6117 13.1113 11.5018C13.2757 11.3919 13.469 11.3333 13.6668 11.3333C13.7982 11.3333 13.9282 11.3592 14.0495 11.4094C14.1708 11.4597 14.2811 11.5333 14.3739 11.6262C14.4668 11.719 14.5405 11.8293 14.5907 11.9506C14.641 12.0719 14.6668 12.202 14.6668 12.3333Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default KaraokeIcon;
