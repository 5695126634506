import * as yup from "yup";

import {
  MIN_GROUP_SIZE,
  MAX_GROUP_SIZE,
} from "pages/new/steps/info/components/PassengerCountBlock";

export type AdditionalTripInfoState = yup.InferType<
  typeof additionalTripInfoErrorSchema
>;

export const additionalTripInfoErrorSchema = yup.object({
  totalGroupSize: yup
    .number()
    .integer()
    .required("Please enter a passenger count")
    .typeError("Please enter a passenger count")
    .test({
      name: "Validate valid value",
      message: "Please enter a valid passenger count.",
      test: (val) => {
        const numberVal = Number(val);
        return numberVal >= MIN_GROUP_SIZE && numberVal <= MAX_GROUP_SIZE;
      },
    }),

  // temporary passenger fields, flattened to work with separate inputs for each field
  temporaryPassenger: yup.object({
    name: yup
      .string()
      .when("mobilePhone", {
        is: (val) => {
          // if mobile phone has any fields other than country code, we also require a name
          return val?.mobilePhone !== val?.phoneCountryDialCode;
        },
        then: (schema) =>
          schema.required("Name is required when a phone number is entered"),
      })
      .max(120, "Max character limit of 120 reached"),
    phoneFields: yup.object({
      mobilePhone: yup.string(),
      phoneCountryCode: yup.string(),
      phoneCountryDialCode: yup.string(),
      phoneCountryFormat: yup.string(),
      phoneCountryName: yup.string(),
    }),
  }),

  // luggage fields
  route: yup
    .object({
      id: yup.string().nullable(),
      carryOnLuggage: yup.number().nullable(),
      checkedLuggage: yup.number().nullable(),
      oversizeLuggage: yup.number().nullable(),
    })
    .nullable(),

  note: yup.string().nullable().max(500, "Max character limit of 500 reached"),

  selectedPassenger: yup
    .object({
      id: yup.string(),
      firstName: yup.string(),
      lastName: yup.string(),
      email: yup.string(),
      mobilePhone: yup.string(),
    })
    .nullable(),
});
