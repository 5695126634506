/**
 * @file AllTripsPriceSummaryBlock.tsx
 * Renders trip total line items for each trip in request
 * - Two states: roundtrip and single trip
 * - Both states render quote total at bottom
 */

import React from "react";

import { Box, Divider, Typography } from "@mui/material";

import { CustomerPricingType } from "../../../../../../globals/hooks/utils";
import { alabaster, black } from "../../../../../../design-system/colors";
import { currency } from "../../../../../../utils/helpers";
import TotalPricingLineItem from "./TotalPricingLineItem";

type AllTripsPriceSummaryBlockProps = {
  pricings: CustomerPricingType[];
  orderTotalAmt: number;
};

function AllTripsPriceSummaryBlock(props: AllTripsPriceSummaryBlockProps) {
  const { pricings, orderTotalAmt } = props;

  const finalOrderTotalAmt = currency(orderTotalAmt);

  return (
    <Box p={1} bgcolor={alabaster}>
      {pricings.map((tripPricing: CustomerPricingType) => {
        const {
          returnTripTotalAmt,
          outboundTripTotalAmt,
          returnTripPricing,
          tripIndex,
        } = tripPricing;

        const isRoundTrip = !!returnTripPricing;

        return (
          <Box key={tripPricing.tripIndex}>
            {/* outbound trip total */}
            <TotalPricingLineItem
              tripIndex={tripIndex || 1}
              tripTotal={outboundTripTotalAmt}
            />
            {/* return trip total */}
            {isRoundTrip && (
              <TotalPricingLineItem
                tripIndex={tripIndex + 1 || 2}
                tripTotal={returnTripTotalAmt}
              />
            )}
          </Box>
        );
      })}

      <Box pt={1}>
        <Divider />
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="space-between"
        mt={2}
      >
        <Typography variant="h4" style={{ color: black }}>
          Total
        </Typography>
        <Typography variant="h2" style={{ color: black }}>
          {finalOrderTotalAmt}
        </Typography>
      </Box>
    </Box>
  );
}

export default AllTripsPriceSummaryBlock;
