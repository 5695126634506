import React from "react";

import { Box, Typography } from "@mui/material";
import { useLuggage } from "globals/hooks";

type LuggageCountProps = {
  carryOnLuggage?: number;
  checkedLuggage?: number;
  oversizeLuggage?: number;
};

function LuggageCount(props: LuggageCountProps) {
  const { carryOnLuggage, checkedLuggage, oversizeLuggage } = props;

  const { hasLuggage } = useLuggage(props);

  return (
    <Box width="100%" mb={2}>
      <Typography variant="overline">Luggage Count</Typography>
      <Box mt={0.5}>
        {hasLuggage ? (
          <>
            {!!carryOnLuggage && (
              <Typography variant="body2">{carryOnLuggage} Carry On</Typography>
            )}
            {!!checkedLuggage && (
              <Typography variant="body2">{checkedLuggage} Checked</Typography>
            )}
            {!!oversizeLuggage && (
              <Typography variant="body2">
                {oversizeLuggage} Oversize
              </Typography>
            )}
          </>
        ) : (
          <Typography variant="body2">0</Typography>
        )}
      </Box>
    </Box>
  );
}

export default LuggageCount;
