import React, { useEffect, useMemo, useState } from "react";
import { Control, UseFormSetValue } from "react-hook-form";
import size from "lodash/size";
import map from "lodash/map";

import { Box, Typography, Button } from "@mui/material";

import AddCreditCardDialog from "components/globals/AddCreditCardDialog";
import PaymentForm from "./components/PaymentForm";
import {
  useCurrentUser,
  useDefaultPassenger,
  useLaunchDarklyFlags,
  useOperator,
} from "globals/hooks";
import RHFSingleSelect from "components/react-hook-form/RHFSingleSelect";
import { getPaymentMethodInfoString } from "globals/utils/helpers";
import PaymentMethodOption from "./components/PaymentMethodOption";
import { useCreateRequestContext } from "pages/new/context/useCreateRequestContext";
import { Request } from "types";
import { getDefaultPaymentMethodId } from "./util";

type PaymentMethodBlockProps = {
  control: Control<any>;
  trackingFrom?: "reserve" | "quoteRequest";
  request?: Request;
  setValue: UseFormSetValue<any>;
};

function PaymentMethodBlock(props: PaymentMethodBlockProps) {
  const { control, trackingFrom, request, setValue } = props;

  // hooks
  const {
    operator: { enableCreditCardWhenBooking },
  } = useOperator();
  const currentUser = useCurrentUser();
  const creditCardOnFile = !!size(currentUser?.paymentMethods);
  const { enableLinkedPassenger } = useLaunchDarklyFlags();
  const [requestContext] = useCreateRequestContext();
  const { selectedPassenger } = useDefaultPassenger() || {};

  // state
  const [addCreditCardDialogOpen, setAddCreditCardDialogOpen] = useState(false);

  // derived state
  const paymentMethodOptions = map(
    currentUser?.paymentMethods,
    ({ id, stripeId, card, billingDetails, linkedPassenger }) => ({
      id,
      value: stripeId,
      label: enableLinkedPassenger ? (
        <PaymentMethodOption
          card={card}
          billingDetails={billingDetails}
          linkedPassenger={linkedPassenger}
        />
      ) : (
        getPaymentMethodInfoString(card)
      ),
    })
  );

  // help auto select payment method if passenger has linked card
  const defaultPaymentMethodId = useMemo(() => {
    if (!enableLinkedPassenger || !currentUser) return null;

    return getDefaultPaymentMethodId({
      trackingFrom,
      requestContext,
      currentUser,
      request,
      selectedPassenger,
    });
  }, [
    currentUser,
    requestContext,
    trackingFrom,
    enableLinkedPassenger,
    request,
    selectedPassenger,
  ]);

  useEffect(() => {
    setValue("stripePaymentMethodId", defaultPaymentMethodId);
  }, [defaultPaymentMethodId, setValue]);

  return (
    <>
      {enableCreditCardWhenBooking && (
        <>
          <Box my={2}>
            <Typography variant="h3">Payment Information</Typography>
          </Box>
          {creditCardOnFile ? (
            <Box mb={2}>
              <RHFSingleSelect
                name="stripePaymentMethodId"
                label="Payment Card"
                control={control}
                options={paymentMethodOptions}
                defaultValue={defaultPaymentMethodId}
              />
              <Box mt={2}>
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  onClick={() => {
                    setAddCreditCardDialogOpen(true);
                  }}
                >
                  Add a new card
                </Button>
              </Box>
            </Box>
          ) : (
            <PaymentForm control={control} trackingFrom={trackingFrom} />
          )}
        </>
      )}
      {currentUser && (
        <AddCreditCardDialog
          open={addCreditCardDialogOpen}
          setAddCreditCardDialogOpen={setAddCreditCardDialogOpen}
          trackingName="bookingTool_addCard"
        />
      )}
    </>
  );
}

export default PaymentMethodBlock;
