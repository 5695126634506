import React, { useEffect, useMemo } from "react";
import size from "lodash/size";
import first from "lodash/first";
import find from "lodash/find";
import map from "lodash/map";

import MoovsToggleButtonGroup from "components/MoovsToggleButtonGroup";
import { ClockIcon, OneWayIcon, RoundTripIcon } from "design-system/icons";
import { TripCategory } from "types";
import { useScreenSize } from "globals/hooks";

type TripCategoryToggleProps = {
  value: TripCategory;
  enabledTripCategories: TripCategory[];
  onChange: (tripCategory: TripCategory) => void;
};

function TripCategoryToggle(props: TripCategoryToggleProps) {
  const { value, enabledTripCategories = [], onChange } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  const options = useMemo(
    () =>
      map(enabledTripCategories, (tripCategory) =>
        find(tripCategories, ({ value }) => tripCategory === value)
      ),
    [enabledTripCategories]
  );

  // event handlers
  const handleTripCategoryChange = (e: any, tripCategory: TripCategory) => {
    if (!tripCategory) return;
    onChange(tripCategory);
  };

  // effects
  // set initial active trip category
  useEffect(() => {
    if (!size(options) || value) return;
    onChange(first(options).value);
  });

  return (
    <MoovsToggleButtonGroup
      size="large"
      onChange={handleTripCategoryChange}
      options={options}
      value={value}
      sx={{ minWidth: isMobileView ? "100px" : "150px" }}
    />
  );
}

export default TripCategoryToggle;

const getTripCategoryLabel = (tripCategory: TripCategory) => {
  if (tripCategory === TripCategory.Hourly) return "Hourly";
  if (tripCategory === TripCategory.OneWay) return "One Way";
  if (tripCategory === TripCategory.RoundTrip) return "Round Trip";
  return null;
};

const tripCategories = [
  {
    value: TripCategory.Hourly,
    ariaLabel: TripCategory.Hourly,
    label: getTripCategoryLabel(TripCategory.Hourly),
    icon: ClockIcon,
  },
  {
    value: TripCategory.OneWay,
    ariaLabel: TripCategory.OneWay,
    label: getTripCategoryLabel(TripCategory.OneWay),
    icon: OneWayIcon,
  },
  {
    value: TripCategory.RoundTrip,
    ariaLabel: TripCategory.RoundTrip,
    label: getTripCategoryLabel(TripCategory.RoundTrip),
    icon: RoundTripIcon,
  },
];
