import size from "lodash/size";
import React from "react";
import capitalize from "lodash/capitalize";

import {
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";

export enum ReservationFilterStatusVariant {
  Confirmed = "CONFIRMED",
  Cancelled = "CANCELLED",
  Unconfirmed = "UNCONFIRMED",
  Declined = "DECLINED",
}

export const defaultFilterStatuses = [
  ReservationFilterStatusVariant.Confirmed,
  ReservationFilterStatusVariant.Cancelled,
  ReservationFilterStatusVariant.Unconfirmed,
  ReservationFilterStatusVariant.Declined,
];

type ReservationDropdownFilterProps = {
  filterStatuses: ReservationFilterStatusVariant[];
  setFilterStatuses: React.Dispatch<
    React.SetStateAction<ReservationFilterStatusVariant[]>
  >;
};

function ReservationDropdownFilter(props: ReservationDropdownFilterProps) {
  const { filterStatuses, setFilterStatuses } = props;

  const handleChange = (e) => {
    const { value } = e.target;

    setFilterStatuses(value);
  };

  const allSelected = size(filterStatuses) === defaultFilterStatuses.length;

  return (
    <>
      <FormControl sx={{ m: 1, width: 300 }}>
        <Select
          id="reservation-status-filter"
          multiple
          value={filterStatuses}
          onChange={handleChange}
          renderValue={(selected) => {
            if (allSelected) return "All";

            return selected.map((status) => capitalize(status)).join(", ");
          }}
        >
          {defaultFilterStatuses.map((status) => {
            return (
              <MenuItem key={status} value={status}>
                <Checkbox checked={filterStatuses.indexOf(status) > -1} />
                <ListItemText primary={capitalize(status)} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
}

export default ReservationDropdownFilter;
