import React, { useReducer } from "react";

import { CreateRequestAdditionalProps, CreateRequestContext } from "./context";
// import { devInitialState } from "./initialState/devData";
import { initialRequest } from "./initialState/initialState";
import { reducer } from "./reducer";

const CreateRequestProvider = ({
  children,
  skipVehicleSelectionEnabled,
  skipVehicleDefaultVehicle,
}: React.PropsWithChildren<CreateRequestAdditionalProps>) => {
  const [createRequestState, dispatch] = useReducer(
    reducer,
    initialRequest
    // devInitialState
  );

  return (
    <CreateRequestContext.Provider
      value={[
        createRequestState,
        dispatch,
        { skipVehicleDefaultVehicle, skipVehicleSelectionEnabled },
      ]}
    >
      {children}
    </CreateRequestContext.Provider>
  );
};

export { CreateRequestProvider };
