import { Box, FormHelperText, useTheme } from "@mui/material";
import first from "lodash/first";
import isString from "lodash/isString";
import React from "react";
import { Control, Controller, useFormContext } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import { formatPhoneNumber } from "utils/phoneNumberFormatter/phoneNumberFormatter";
import { errorRed, granite } from "design-system/colors";

type RHFInternationalPhoneInputProps = {
  name: string;
  label?: string;
  control?: Control<any>;
  onBlur?: () => void;
  onFocus?: () => void;
};

function RHFInternationalPhoneInput(props: RHFInternationalPhoneInputProps) {
  const { name, label, onBlur, onFocus } = props;
  const specialLabel = label || "Mobile Phone Number *";

  // hooks
  const context = useFormContext();
  const theme = useTheme();

  // derived state
  const control = props.control || context.control;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange, ...fieldProps }, fieldState }) => {
        // coerce type
        const error = fieldState.error as unknown as {
          mobilePhone: { message: string };
        };

        // reformat default values for pre-populated form upon user logout
        if (first(value?.mobilePhone) === "(") {
          value.mobilePhone = formatPhoneNumber(
            value?.mobilePhone
          ).international.slice(1);
        }

        // PhoneInput expects value to be a string
        const renderValue = isString(value?.mobilePhone)
          ? value.mobilePhone
          : isString(value)
          ? value
          : "";

        return (
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              "& .react-tel-input": {
                ":focus-within": {
                  "& .special-label": {
                    color: theme.palette.primary.main,
                  },
                },
                "& .special-label": {
                  color: granite,
                },
                "& .form-control": {
                  width: "100%",
                  height: 58,
                  ":focus": {
                    // height: 56,
                    border: `2px solid ${
                      error ? errorRed : theme.palette.primary.main
                    } !important`,
                    boxShadow: "none",
                  },
                },
                "& .form-control.invalid-number": {
                  ":focus": {
                    // border: `2px solid ${error ? errorRed : moovsBlue}`,
                    boxShadow: "none",
                  },
                },
              },
            }}
          >
            <PhoneInput
              {...fieldProps}
              value={renderValue}
              onChange={(val, country) => {
                onChange({
                  mobilePhone: val,
                  phoneCountryName: "name" in country ? country.name : "",
                  phoneCountryFormat: "format" in country ? country.format : "",
                  phoneCountryCode:
                    "countryCode" in country ? country.countryCode : "",
                  phoneCountryDialCode:
                    "dialCode" in country ? country.dialCode : "",
                });
              }}
              specialLabel={specialLabel}
              isValid={() => (!!error ? specialLabel : true)}
              country="us"
              countryCodeEditable={false}
              onBlur={onBlur}
              onFocus={onFocus}
            />
            <FormHelperText error={!!error}>
              {error?.mobilePhone?.message}
            </FormHelperText>
          </Box>
        );
      }}
    />
  );
}
export default RHFInternationalPhoneInput;
