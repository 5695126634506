import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";

import { Box, CircularProgress } from "@mui/material";

import MoovsDialog from "../../globals/MoovsDialog";
import {
  useAddTripToQuoteReducer,
  addTripReducerActionTypesEnum,
} from "../hooks/useAddTripToQuoteReducer";
import { useAddTripErrors } from "../hooks/useAddTripErrors";
import AddVehicleToTripPanel from "./AddVehicleToTripPanel";
import {
  ADD_TRIP_TO_QUOTE,
  LOAD_REQUEST_QUERY,
} from "../../../globals/graphql";
import {
  useAnalytics,
  useCurrentUser,
  useDefaultPassenger,
  useLaunchDarklyFlags,
  useSnackbar,
} from "../../../globals/hooks";
import AddTripDetailsPanel from "./AddTripDetailsPanel";
import { getErrorMessage } from "../../../moovsErrors/getErrorMessage";

type AddTripDialogProps = {
  open: boolean;
  onClose: () => void;
  tripCount: number;
  contactId: string;
};

const { INTIAL_STATE } = addTripReducerActionTypesEnum;

function AddTripDialog(props: AddTripDialogProps) {
  const { open, onClose, tripCount, contactId } = props;

  // state
  const [activePanelIndex, setActivePanelIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // hooks
  const { requestId } = useParams<{ requestId: string }>();
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const { tripErrors, validateAddTrip, setErrorsToIntialState } =
    useAddTripErrors();
  const { selectedPassenger, passengerType } = useDefaultPassenger() || {};
  const { enableLinkedPassenger } = useLaunchDarklyFlags();
  const currentUser = useCurrentUser();

  // mutations
  const [addTrip] = useMutation(ADD_TRIP_TO_QUOTE, {
    refetchQueries: [
      { query: LOAD_REQUEST_QUERY, variables: { id: requestId } },
    ],
    onCompleted() {
      if (enableLinkedPassenger && passengerType) {
        track("bookingTool_tripAdded", {
          passengerType,
        });
      } else {
        track("bookingTool_tripAdded");
      }

      handleCloseAddTripDialog();
      setIsLoading(false);
    },
    onError(error) {
      const errorMessage = getErrorMessage(error) || "Error adding new trip";

      snackbar.error(errorMessage);
      setIsLoading(false);
    },
  });

  // handlers
  const handleShowVehicleDialog = () => {
    const stops = newTripAddedOnQuote.stops;

    const hasErrors = validateAddTrip(stops);

    if (!hasErrors) {
      setActivePanelIndex(1);
    }
  };

  const handlePreviousClick = () => {
    setActivePanelIndex(0);
  };

  const selectedContactId =
    enableLinkedPassenger && currentUser && selectedPassenger?.id
      ? selectedPassenger.id
      : contactId;

  const handleAddTrip = () => {
    handleShowVehicleDialog();
    setIsLoading(true);
    addTrip({
      variables: {
        input: {
          requestId,
          contactId: selectedContactId,
          ...newTripAddedOnQuote,
        },
      },
    });
  };

  const handleDetailsChange = (type: string, value?: any) => {
    dispatch({
      type,
      value,
    });
  };

  const handleCloseAddTripDialog = () => {
    setActivePanelIndex(0);
    handleDetailsChange(INTIAL_STATE, "");
    setErrorsToIntialState();
    onClose();
  };

  // Reducer
  const [newTripAddedOnQuote, dispatch] = useAddTripToQuoteReducer();

  return (
    <MoovsDialog
      hideLoadingIndicator
      open={open}
      onClose={handleCloseAddTripDialog}
      dialogTitle={`Add Trip ${tripCount + 1}`}
      size="sm"
      fixedFooter
      removeCloseButton={true}
      {...(activePanelIndex === 1 && {
        auxillaryButtonText: "Previous",
        onAccept: handleAddTrip,
        acceptButtonText: "Add Trip",
        onAuxillaryButtonClick: handlePreviousClick,
      })}
      {...(activePanelIndex === 0 && {
        onAccept: handleShowVehicleDialog,
        acceptButtonText: "Select Vehicle",
      })}
    >
      <>
        {isLoading && (
          <Box m="auto">
            <CircularProgress />
          </Box>
        )}
        {!isLoading && (
          <>
            {activePanelIndex === 0 && (
              <AddTripDetailsPanel
                tripErrors={tripErrors}
                newTripAddedOnQuote={newTripAddedOnQuote}
                onDetailsChange={handleDetailsChange}
              />
            )}
            {/* Vehicle Panel on successful click of Add Vehicle Button */}
            {activePanelIndex === 1 && (
              <AddVehicleToTripPanel
                selectedVehicleId={newTripAddedOnQuote.vehicleId}
                onVehicleSelected={handleDetailsChange}
              />
            )}
          </>
        )}
      </>
    </MoovsDialog>
  );
}

export default AddTripDialog;
