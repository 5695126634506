import React from "react";

type AuxIconProps = {
  color?: string;
  size: "small";
};

const AuxIcon = (props: AuxIconProps) => {
  const { color } = props;

  // size === small
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5456 8.48952V3.5556C12.5456 2.96623 12.3062 2.401 11.88 1.98425C11.4537 1.5675 10.8757 1.33337 10.2729 1.33337C9.67013 1.33337 9.09205 1.5675 8.66583 1.98425C8.23961 2.401 8.00016 2.96623 8.00016 3.5556V12.4445C8.00016 13.0339 7.76072 13.5991 7.3345 14.0158C6.90828 14.4326 6.3302 14.6667 5.72744 14.6667C5.12467 14.6667 4.54659 14.4326 4.12037 14.0158C3.69416 13.5991 3.45471 13.0339 3.45471 12.4445V7.60063M3.75774 1.48152H3.15168V3.2593H3.75774V1.48152ZM5.57592 3.2593H1.3335V5.03708C1.3335 6.18256 2.2832 7.11115 3.45471 7.11115C4.62622 7.11115 5.57592 6.18256 5.57592 5.03708V3.2593ZM12.2426 14.2223H12.8486V12.4445H12.2426V14.2223ZM10.4244 12.4445H14.6668V10.6667C14.6668 9.52123 13.7171 8.59263 12.5456 8.59263C11.3741 8.59263 10.4244 9.52123 10.4244 10.6667L10.4244 12.4445Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AuxIcon;
