import isNil from "lodash/isNil";
import isNull from "lodash/isNull";
import { PickUpVariant, PromoCode } from "types";
import * as yup from "yup";

export type ConfirmReservationFormState = yup.InferType<
  typeof confirmReservationFormErrorSchema
>;

export const confirmReservationFormErrorSchema = yup.object({
  driverGratuityPctCustomerInput: yup.mixed().when("$isGratuityEnabled", {
    is: false,
    then: (schema) => schema.nullable(),
    otherwise: (schema) => {
      return schema.when({
        is: "cash",
        otherwise: (schema) => {
          return schema
            .required("You have to select a gratuity")
            .test("minGratuityPct", (value, { createError, path, options }) => {
              if (!isNull(value) && value < options.context.minGratuityPct) {
                return createError({
                  path,
                  message: `Gratuity must be at least ${options.context.minGratuityPct}%`,
                });
              }

              return true;
            });
        },
      });
    },
  }),

  pickUpVariantSelected: yup
    .mixed<PickUpVariant>()
    .nullable()
    .when("$isPickUpVariantRequired", {
      is: true,
      then: (schema) => schema.required().oneOf(Object.values(PickUpVariant)),
      otherwise: (schema) => schema.notRequired(),
    }),

  // if payment new payment method block is not showing, this is null
  paymentMethod: yup
    .object({
      creditCardError: yup
        .object()
        .nullable()
        .test("creditCardErrorExists", (value, { createError, path }) => {
          // we always error out, but in this pattern, the existance of any values
          // means we have a fail condition
          if (value?.message) {
            return createError({
              path,
              message: value.message,
            });
          }

          return true;
        }),
      cardholderName: yup
        .string()
        .nullable()
        .when("creditCardError", {
          is: (value) => isNil(value), // undefined handles case where credit cards are not enabled on reservations
          otherwise: (schema) => schema.required("Cardholder name is required"),
        }),
    })
    .when("$isNewPaymentMethodRequired", {
      is: false,
      then: (schema) => schema.nullable(),
    }),

  isTermsAccepted: yup.boolean().when("$isTermsRequired", {
    is: true,
    then: (schema) =>
      schema
        .required("Please accept the Terms & Conditions")
        .oneOf([true], "The terms and conditions must be accepted."),
  }),

  // an existing payment method, only used when authenticated
  stripePaymentMethodId: yup
    .string()
    .nullable()
    .when("$isExistingPaymentMethodRequired", {
      is: true,
      then: (schema) => schema.required("Please select a payment card."),
    }),

  printedName: yup.string().when("$isConsentSignatureRequired", {
    is: true,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),

  autoPaymentAmt: yup.number().nullable(),

  autoPaymentType: yup.string().nullable(),

  promoCodeCustomerInput: yup.mixed<PromoCode>().nullable(),

  additonalItemsByTrip: yup.array(
    yup.object({
      childSeats: yup.object({
        boosterSeatQuantity: yup.number().nullable(),
        boosterSeatAmt: yup.number().nullable(),
        forwardFacingSeatQuantity: yup.number().nullable(),
        forwardFacingSeatAmt: yup.number().nullable(),
        rearFacingSeatQuantity: yup.number().nullable(),
        rearFacingSeatAmt: yup.number().nullable(),
      }),
    })
  ),
});
