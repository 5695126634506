import React from "react";

type AlcoholIconProps = {
  color?: string;
  size?: "small";
};

const AlcoholIcon = (props: AlcoholIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.932 6.228C10.6446 4.70581 10.4999 3.16009 10.5 1.611V1.5C10.5 1.30109 10.421 1.11032 10.2803 0.96967C10.1397 0.829018 9.94891 0.75 9.75 0.75H8.25C8.05109 0.75 7.86032 0.829018 7.71967 0.96967C7.57902 1.11032 7.5 1.30109 7.5 1.5V1.611C7.49983 4.51471 6.99219 7.39606 6 10.125C5.00781 12.8539 4.50017 15.7353 4.5 18.639V21.75C4.5 22.1478 4.65804 22.5294 4.93934 22.8107C5.22064 23.092 5.60218 23.25 6 23.25H11.25M10.562 12.531C10.0541 12.6767 9.52835 12.7504 9 12.75C7.73941 12.7571 6.51504 12.3286 5.534 11.537M7.408 3.75H10.592M15 23.25H18M16.5 23.25V19.5M16.5 19.5C17.0889 19.5 17.6658 19.3331 18.164 19.019C18.6189 18.7376 18.9841 18.3323 19.217 17.8508C19.4499 17.3692 19.5408 16.8313 19.479 16.3L18.679 8.705C18.6627 8.57698 18.5997 8.4595 18.5021 8.37513C18.4044 8.29077 18.279 8.24549 18.15 8.248H14.85C14.721 8.24549 14.5956 8.29077 14.4979 8.37513C14.4003 8.4595 14.3373 8.57698 14.321 8.705L13.521 16.3C13.4592 16.8313 13.5501 17.3692 13.783 17.8508C14.0159 18.3323 14.3811 18.7376 14.836 19.019C15.3342 19.3331 15.9111 19.5 16.5 19.5ZM13.891 12.75H19.109M10.5 18C10.5 18.8284 9.82843 19.5 9 19.5C8.17157 19.5 7.5 18.8284 7.5 18C7.5 17.1716 8.17157 16.5 9 16.5C9.82843 16.5 10.5 17.1716 10.5 18Z"
          stroke={color || "#B3B3B3"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.932 6.228C10.6446 4.70581 10.4999 3.16009 10.5 1.611V1.5C10.5 1.30109 10.421 1.11032 10.2803 0.96967C10.1397 0.829018 9.94891 0.75 9.75 0.75H8.25C8.05109 0.75 7.86032 0.829018 7.71967 0.96967C7.57902 1.11032 7.5 1.30109 7.5 1.5V1.611C7.49983 4.51471 6.99219 7.39606 6 10.125C5.00781 12.8539 4.50017 15.7353 4.5 18.639V21.75C4.5 22.1478 4.65804 22.5294 4.93934 22.8107C5.22064 23.092 5.60218 23.25 6 23.25H11.25M10.562 12.531C10.0541 12.6767 9.52835 12.7504 9 12.75C7.73941 12.7571 6.51504 12.3286 5.534 11.537M7.408 3.75H10.592M15 23.25H18M16.5 23.25V19.5M16.5 19.5C17.0889 19.5 17.6658 19.3331 18.164 19.019C18.6189 18.7376 18.9841 18.3323 19.217 17.8508C19.4499 17.3692 19.5408 16.8313 19.479 16.3L18.679 8.705C18.6627 8.57698 18.5997 8.4595 18.5021 8.37513C18.4044 8.29077 18.279 8.24549 18.15 8.248H14.85C14.721 8.24549 14.5956 8.29077 14.4979 8.37513C14.4003 8.4595 14.3373 8.57698 14.321 8.705L13.521 16.3C13.4592 16.8313 13.5501 17.3692 13.783 17.8508C14.0159 18.3323 14.3811 18.7376 14.836 19.019C15.3342 19.3331 15.9111 19.5 16.5 19.5ZM13.891 12.75H19.109M10.5 18C10.5 18.8284 9.82843 19.5 9 19.5C8.17157 19.5 7.5 18.8284 7.5 18C7.5 17.1716 8.17157 16.5 9 16.5C9.82843 16.5 10.5 17.1716 10.5 18Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AlcoholIcon;
