import React from "react";
import moment from "moment-timezone";

import {
  Box,
  Typography,
  CircularProgress,
  IconButton,
  useTheme,
} from "@mui/material";

import { granite } from "../../design-system/colors";
import { SyncIcon } from "../../design-system/icons";
import RoundChip from "../../design-system/components/RoundChip";
import { AirportInfo, FlightProgressDisplay } from "./components";
import { FlightInfoCardContentProps } from "./FlightInfoCard";
import flightStatusMap from "./flightStatusMap";

function FlightInfoCardContentMobile(props: FlightInfoCardContentProps) {
  const { airlineHeader, refreshFlightProps = {}, trackedFlight } = props;

  const { origin, destination, flightStatus, updatedAt } = trackedFlight;
  const { isRefreshingFlight, onClickRefreshFlight } = refreshFlightProps;

  const { statusColor, allowRefresh } = flightStatusMap(flightStatus);

  // hooks
  const theme = useTheme();

  return (
    <>
      <Box
        pb={2}
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
      >
        <Box
          display="flex"
          alignItems="space-between"
          justifyContent="space-between"
          mb={1}
        >
          <Typography
            sx={{ color: theme.palette.text.primary, fontWeight: 600 }}
            variant="body2"
          >
            {airlineHeader}
          </Typography>
          {isRefreshingFlight ? (
            <Box p={1.5}>
              <CircularProgress size={20} thickness={4} />
            </Box>
          ) : (
            !!onClickRefreshFlight &&
            allowRefresh && (
              <IconButton onClick={onClickRefreshFlight} size="large">
                <SyncIcon color={granite} />
              </IconButton>
            )
          )}
        </Box>
        <Box display="flex" width="100%" justifyContent="space-between">
          <RoundChip
            label={flightStatus}
            color="white"
            backgroundColor={statusColor}
            textTransform="uppercase"
          />
          <Box
            display="flex"
            justifyContent="flex-start"
            textAlign="right"
            ml={1}
          >
            <Typography
              sx={{ color: theme.palette.text.primary, fontWeight: 600 }}
              variant="h6"
            >
              Updated:{" "}
              <Typography variant="h6">
                {moment(updatedAt).calendar()}
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box mb={3}>
        <FlightProgressDisplay flightStatus={flightStatus} />
        <Box pt={2} display="flex" justifyContent="space-between">
          <AirportInfo
            airportCode={origin.airport.iataCode || "-"}
            airportName={origin.airport.airportName || ""}
            transitStopType="origin"
          />
          <AirportInfo
            airportCode={destination.airport.iataCode || "-"}
            airportName={destination.airport.airportName || ""}
            transitStopType="destination"
          />
        </Box>
      </Box>
    </>
  );
}

export default FlightInfoCardContentMobile;
