// if base rate variant is HOURLY, append hourly rate and hours to label
export const renderBaseRateFieldLabel = (
  hourlyBaseRate: number,
  hourlyBaseRateHours: number
) => {
  if (!!hourlyBaseRate && !!hourlyBaseRateHours) {
    const hourLabel = hourlyBaseRateHours === 1 ? "Hour" : "Hours";
    return `Base Rate ($${hourlyBaseRate.toFixed(
      2
    )} * ${hourlyBaseRateHours} ${hourLabel})`;
  }
  return "Base Rate";
};
