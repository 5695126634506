import React, { ChangeEvent } from "react";
import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form";

import MoovsDialog from "components/globals/MoovsDialog";
import ConsentSignatureFooter from "./ConsentSignatureFooter";
import { CreateReservationFormState } from "pages/new/steps/confirm/components/CreateReservationForm/form/schemaValidation";
import { ConfirmReservationFormState } from "pages/order/components/ConfirmReservationForm/form/schemaValidation";
import { TermsAndConditionsBody } from "..";
import { useCancellationPolicies } from "../../hooks/useCancellationPolicies";
import { deriveStrictestCancellationPolicy } from "pages/new/utils/deriveStrictestCancellationPolicy";

type ConsentSignatureDialogProps = {
  control: Control<CreateReservationFormState | ConfirmReservationFormState>;
  formId: string;
  watch: UseFormWatch<CreateReservationFormState | ConfirmReservationFormState>;
  setValue: UseFormSetValue<any>;
  isSubmitting: boolean;
  isDialogOpen: boolean;
  onClose: () => void;
  consentSignature: string;
  userConsentAgreement: boolean;
  onConsentSignatureChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onUserConsentAgreementToggle: () => void;
  selectedVehicleIds: string[];
};

function ConsentSignatureDialog(props: ConsentSignatureDialogProps) {
  const {
    control,
    formId,
    watch,
    setValue,
    isSubmitting,
    isDialogOpen,
    onClose,
    consentSignature,
    userConsentAgreement,
    onConsentSignatureChange,
    onUserConsentAgreementToggle,
    selectedVehicleIds,
  } = props;

  // hooks
  const { cancellationPolicies: cancellationPoliciesData } =
    useCancellationPolicies({ trips: null });

  // derived state
  const cancellationPolicies = deriveStrictestCancellationPolicy({
    cancellationPoliciesData,
    selectedVehicleIds,
  });
  const hasCancellationPolicy = !!cancellationPolicies.length;

  const handleCloseDialog = () => {
    setValue("isTermsAccepted", false);
    setValue("printedName", "");
    onClose();
  };
  return (
    <MoovsDialog
      dialogTitle={`Terms & Conditions${
        hasCancellationPolicy ? " and Cancellation Policy" : ""
      }`}
      open={isDialogOpen}
      onClose={handleCloseDialog}
      onAccept={onClose} // let parent formSubmit handle onAccept
      acceptButtonText="Sign Electronically"
      fixedFooter
      customFooter={
        <ConsentSignatureFooter
          control={control}
          watch={watch}
          setValue={setValue}
          formId={formId}
          isSubmitting={isSubmitting}
          onDialogClose={handleCloseDialog}
          consentSignature={consentSignature}
          userConsentAgreement={userConsentAgreement}
          onConsentSignatureChange={onConsentSignatureChange}
          onConsentAgreementClick={onUserConsentAgreementToggle}
          hasCancellationPolicy={hasCancellationPolicy}
        />
      }
    >
      <TermsAndConditionsBody selectedVehicleIds={selectedVehicleIds} />
    </MoovsDialog>
  );
}

export default ConsentSignatureDialog;
