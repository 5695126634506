import React, { useMemo } from "react";
import moment from "moment-timezone";

import { Typography, Box } from "@mui/material";

import { moovsBlue, successGreen } from "../../../design-system/colors";
import { RouteDispatch, RouteDispatchLogStatusEnum } from "types";

type DriverStatusRibbonProps = {
  routeDispatch?: RouteDispatch;
};

function DriverStatusRibbon(props: DriverStatusRibbonProps) {
  const { routeDispatch } = props;

  // routeDispatch is the driver app route info
  const stopLabel = routeDispatch?.currentStopName;
  const routeDispatchLogStatus = routeDispatch?.currentStatus;
  const driverEta = routeDispatch?.driverEta;

  const [heading, label, ribbonBgColor, ribbonTextColor] = useMemo(() => {
    const driverETATimeLabel = moment.utc(driverEta).format("h:mm A");

    switch (routeDispatchLogStatus) {
      case RouteDispatchLogStatusEnum.EnRoute:
        return ["Heading To", stopLabel, moovsBlue, "#FFF"];
      case RouteDispatchLogStatusEnum.Arrived:
        return ["Arrived At", stopLabel, successGreen, "#FFF"];
      default:
        return ["Driver ETA", driverETATimeLabel, "#FFF", "#000"];
    }
  }, [driverEta, routeDispatchLogStatus, stopLabel]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      bgcolor={ribbonBgColor}
      borderRadius="4px"
      px="12px"
      py="6px"
    >
      <Box display="flex">
        <Typography variant="h4" color={ribbonTextColor} fontWeight={600}>
          {heading}
        </Typography>
        <Typography
          sx={{ ml: 1 }}
          variant="h4"
          color={ribbonTextColor}
          fontWeight={700}
        >
          {label}
        </Typography>
      </Box>

      {routeDispatchLogStatus === RouteDispatchLogStatusEnum.EnRoute && (
        <Typography variant="caption" color={ribbonTextColor}>
          ETA: {moment(driverEta).format("h:mm a")}
        </Typography>
      )}
    </Box>
  );
}

export default DriverStatusRibbon;
