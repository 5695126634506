import React from "react";

import { Box, Card, Button } from "@mui/material";

import { EllipsesVertIcon, StarIcon } from "design-system/icons";
import { moovsBlue, orange } from "design-system/colors";
import { PaymentMethod } from "types";
import { CardDetails, UnlinkCardMenuButton } from "./components";

type CurrentUserCardProps = {
  paymentMethod: PaymentMethod;
  setLinkCardToPassengerDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  onPaymentMethodClick: (paymentMethodId: string) => void;
};

function CurrentUserCard(props: CurrentUserCardProps) {
  const {
    paymentMethod,
    setLinkCardToPassengerDialogOpen,
    onPaymentMethodClick,
  } = props;

  const { isPrimary, linkedPassenger, billingDetails, card } = paymentMethod;

  // event handlers
  const handleCurrentUserCardEllipsesClick = () => {
    setLinkCardToPassengerDialogOpen(true);
    onPaymentMethodClick(paymentMethod.id);
  };

  if (!card || !billingDetails) return null;

  return (
    <Card variant="outlined" sx={{ marginTop: 2, marginBottom: 1 }}>
      <Box p={2} display="flex" justifyContent="space-between">
        <CardDetails card={card} name={billingDetails.name} />

        {/* linking + unlinking */}
        <Box display="flex" flex="2" justifyContent="flex-end">
          <Box>
            <Box display="flex" alignItems="center">
              {!linkedPassenger ? (
                <Button onClick={handleCurrentUserCardEllipsesClick}>
                  <EllipsesVertIcon color={moovsBlue} />
                </Button>
              ) : (
                <UnlinkCardMenuButton
                  buttonText={`${linkedPassenger.firstName} ${linkedPassenger.lastName}`}
                  menuOptionText="Unlink from Passenger"
                  paymentMethodId={paymentMethod.id}
                />
              )}
              <Box>{isPrimary && <StarIcon size="small" color={orange} />}</Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default CurrentUserCard;
