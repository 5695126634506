/**
 * retrieves google directions estimated duration and results and saves them to the form
 */
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useGoogleDirectionsQuery } from "globals/graphql/hooks/useGoogleDirectionsQuery";
import isNil from "lodash/isNil";
import { InfoStepState } from "../InfoStepFormProvider";

const useEstimatedDurationOnInfoPage = () => {
  // form hooks
  const { watch, setValue } = useFormContext<InfoStepState>();
  const [stops, returnTripStops] = watch(["trip.stops", "returnTrip.stops"]);

  const [
    tripGoogleDirectionsEstimatedDuration,
    tripGoogleDirectionsMap,
    isDirectionsLoading,
  ] = useGoogleDirectionsQuery({
    stops,
  });

  const [
    returnTripGoogleDirectionsEstimatedDuration,
    returnTripGoogleDirectionsMap,
    isReturnDirectionsLoading,
  ] = useGoogleDirectionsQuery({
    stops: returnTripStops,
  });

  // update estimated duration values of outbound trip when they change
  useEffect(() => {
    if (isNil(tripGoogleDirectionsEstimatedDuration)) return;

    setValue("trip.estimatedDuration", tripGoogleDirectionsEstimatedDuration);
    setValue("trip.googleDirectionsResult", tripGoogleDirectionsMap);
  }, [
    setValue,
    tripGoogleDirectionsEstimatedDuration,
    tripGoogleDirectionsMap,
  ]);

  // update estimated duration values of return trip when they change
  useEffect(() => {
    if (isNil(returnTripGoogleDirectionsEstimatedDuration)) return;

    setValue(
      "returnTrip.estimatedDuration",
      returnTripGoogleDirectionsEstimatedDuration
    );
    setValue(
      "returnTrip.googleDirectionsResult",
      returnTripGoogleDirectionsMap
    );
  }, [
    returnTripGoogleDirectionsEstimatedDuration,
    returnTripGoogleDirectionsMap,
    setValue,
  ]);

  return isDirectionsLoading || isReturnDirectionsLoading;
};

export { useEstimatedDurationOnInfoPage };
