import React from "react";

type DancePoleIconProps = {
  color?: string;
  size: "small";
};

const DancePoleIcon = (props: DancePoleIconProps) => {
  const { color } = props;

  // size === small
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33325 1.33337H7.99992M7.99992 1.33337H10.6666M7.99992 1.33337V10.6667M12.9999 3.33337V4.33337M12.9999 4.33337V5.33337M12.9999 4.33337H13.9999M12.9999 4.33337H11.9999M2.99992 6.00004V7.00004M2.99992 7.00004V8.00004M2.99992 7.00004H3.99992M2.99992 7.00004H1.99992M13.3333 12.6667V10.6667H2.66659V12.6667M13.3333 12.6667H2.66659M13.3333 12.6667H14.6666V14.6667H1.33325V12.6667H2.66659"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DancePoleIcon;
