import React, { useEffect, useMemo, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { Box, useTheme } from "@mui/material";

import {
  useOperator,
  useOperatorSlug,
  useQueryParams,
} from "../../globals/hooks";
import { CreateRequestProvider } from "./context/CreateRequestProvider";
import { InfoStepFormProvider } from "./steps/info/context/InfoStepFormProvider";
import InfoStep from "./steps/info/InfoStep";
import VehicleStep from "./steps/vehicle/VehicleStep";
import ConfirmStep from "./steps/confirm/ConfirmStep";
import { ColorThemeVariant } from "types";
import { moovsBlue } from "design-system/colors";
import { LOAD_SKIP_VEHICLE_ENABLED } from "globals/graphql";

function CreateRequestStep() {
  // hooks
  const queryParams = useQueryParams();
  const history = useHistory();
  const operatorSlug = useOperatorSlug();
  const { operator } = useOperator();
  const theme = useTheme();

  // TODO: deprecate duda iframe variant 11/2/2022
  const dudaTrip = queryParams.get("dudaTrip") || queryParams.get("trip");

  // state
  const [isLoading, setIsLoading] = useState(true);

  // query
  const { data } = useQuery(LOAD_SKIP_VEHICLE_ENABLED, {
    variables: {
      operatorSlug: operatorSlug,
    },
    skip: !operatorSlug,
  });

  const [skipVehicleSelectionEnabled, skipVehicleDefaultVehicle] =
    useMemo(() => {
      return [
        data?.operator?.settings?.skipVehicleSelectionEnabled || false,
        data?.operator?.settings?.skipVehicleDefaultVehicle,
      ];
    }, [data]);

  // on window load, redirects all "steps" to info
  // UNLESS dudaTrip params is present
  useEffect(() => {
    const pathname = `/${operatorSlug}/new/${dudaTrip ? "vehicle" : "info"}`;

    history.replace({
      ...history.location,
      pathname,
    });
  }, [operatorSlug, history, dudaTrip]);

  // Page is considered loading:
  // - if user doesn't have default moovsBlue color theme, wait for theme to update to correct color before loading page
  // - if operator settings are not loaded
  useEffect(() => {
    const isDefaultThemeLoaded =
      operator?.settings?.colorTheme === ColorThemeVariant.Moovs ||
      theme.palette.primary.main !== moovsBlue;
    const isOperatorLoaded = operator && data?.operator?.id;

    if (isOperatorLoaded && isDefaultThemeLoaded) {
      setIsLoading(false);
    }
  }, [operator, data, theme.palette.primary.main]);

  if (isLoading) {
    return null;
  }

  return (
    <CreateRequestProvider
      skipVehicleDefaultVehicle={skipVehicleDefaultVehicle}
      skipVehicleSelectionEnabled={skipVehicleSelectionEnabled}
    >
      <Box width="100%" display="flex" justifyContent="center">
        <Box
          width="100%"
          mx={2}
          display="flex"
          flexDirection="column"
          maxWidth="1140px"
        >
          <Switch>
            <Route path="/:operatorSlug/new/info">
              <InfoStepFormProvider>
                <InfoStep />
              </InfoStepFormProvider>
            </Route>
            <Route path="/:operatorSlug/new/vehicle">
              <VehicleStep />
            </Route>
            <Route path="/:operatorSlug/new/confirm">
              <ConfirmStep />
            </Route>
          </Switch>
        </Box>
      </Box>
    </CreateRequestProvider>
  );
}

export default CreateRequestStep;
