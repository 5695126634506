import React from "react";
import { Control, Controller } from "react-hook-form";

import { Box, ToggleButtonGroup, Typography } from "@mui/material";

import { PickUpVariant } from "types";
import PickUpVariantToggleButton from "./components/PickUpVariantToggleButton";

type MeetGreetBlockProps = {
  control: Control<any>;
  meetGreetAmt: number;
};

function MeetGreetBlock(props: MeetGreetBlockProps) {
  const { control, meetGreetAmt } = props;

  return (
    <Controller
      control={control}
      name="pickUpVariantSelected"
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        // event handlers
        const handlePickUpVariantChange = (variant: PickUpVariant) => {
          onChange(variant);
        };

        return (
          <Box>
            <Box mb={2} id="meet-and-greet">
              <Typography variant="overline">Choose Pick-Up Option</Typography>
            </Box>
            <ToggleButtonGroup
              exclusive
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <PickUpVariantToggleButton
                value={value}
                onClick={() =>
                  handlePickUpVariantChange(PickUpVariant.Curbside)
                }
                selected={value === PickUpVariant.Curbside}
                label="Curbside"
                subText="You will be contacted to coordinate pick-up"
                meetGreetAmt={meetGreetAmt}
                error={!!error?.message}
              />

              <PickUpVariantToggleButton
                value={value}
                onClick={() =>
                  handlePickUpVariantChange(PickUpVariant.MeetGreet)
                }
                selected={value === PickUpVariant.MeetGreet}
                label="Meet & Greet"
                subText="A dedicated greeter will assist you to your vehicle"
                meetGreetAmt={meetGreetAmt}
                error={!!error?.message}
              />
            </ToggleButtonGroup>

            {error?.message && (
              <Box mb={1}>
                <Typography color="error" variant="caption">
                  {"Please select an option"}
                </Typography>
              </Box>
            )}
          </Box>
        );
      }}
    ></Controller>
  );
}

export default MeetGreetBlock;
