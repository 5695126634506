import React from "react";

type NoReservationIconProps = {
  color?: string;
};

function NoReservationIcon(props: NoReservationIconProps) {
  const { color } = props;

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3366 8.80975L7.82056 5.56331M28.6635 8.80975L32.1795 5.56331M20.0522 6.23344V1.45312"
        stroke={color || "#739CFF"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.48058 21.2102H12.5803C13.1188 21.2102 13.5826 21.5999 13.6574 22.1396C14.1061 25.2425 16.7689 27.611 19.985 27.611C23.2012 27.611 25.8639 25.2276 26.3127 22.1396C26.3875 21.5999 26.8512 21.2102 27.3898 21.2102H37.7564M2.24363 20.8954L11.249 13.5053C11.4584 13.2654 11.7576 13.1455 12.0568 13.1455H27.9433C28.2574 13.1455 28.5566 13.2804 28.751 13.5053L37.7564 20.8954C37.9359 21.0903 38.0256 21.3601 38.0256 21.6149V34.3566C38.0256 35.5558 37.0533 36.5301 35.8566 36.5301H4.14343C2.9467 36.5301 1.97437 35.5558 1.97437 34.3566V21.6149C1.97437 21.3601 2.07908 21.0903 2.24363 20.8954Z"
        stroke="#195FE9"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default NoReservationIcon;
