import React from "react";

import { SxProps, Typography } from "@mui/material";

import { granite } from "design-system/colors";
import { useAuth } from "globals/hooks";
import { getSmsConsentMessage } from "./helpers";

type SmsConsentMessageProps = {
  actionButtonName: "Pay" | "Send Request" | "Reserve";
  typographySx?: SxProps;
};

function SmsConsentMessage(props: SmsConsentMessageProps) {
  const { actionButtonName, typographySx } = props;

  // hooks
  const { authStage } = useAuth();

  return (
    <>
      {authStage !== "authenticated" && (
        <Typography color={granite} variant="body2" sx={typographySx}>
          {getSmsConsentMessage(actionButtonName)}
        </Typography>
      )}
    </>
  );
}

export default SmsConsentMessage;
