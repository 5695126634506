import React, { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { Control, UseFormSetValue } from "react-hook-form";

import { Box, Typography } from "@mui/material";

import MoovsDialog from "components/globals/MoovsDialog";
import { RHFTextField } from "components/react-hook-form/text-field/RHFTextField";
import RHFInternationalPhoneInput from "components/react-hook-form/RHFInternationalPhoneInput";
import { useScreenSize } from "globals/hooks";
import { AdditionalTripInfoState } from "../../../form/schemaValidation";

export type TemporaryPassengerDialogProps = {
  open: boolean;
  setTemporaryPassengerDialogOpen: Dispatch<SetStateAction<boolean>>;
  onCreateLinkedPassenger?: React.Dispatch<any>;
  setMenuOpen: React.Dispatch<React.SetStateAction<HTMLElement>>;
  control: Control<AdditionalTripInfoState>;
  setValue: UseFormSetValue<any>;
};

function TemporaryPassengerDialog(props: TemporaryPassengerDialogProps) {
  const {
    open,
    setTemporaryPassengerDialogOpen,
    setMenuOpen,
    control,
    setValue,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  const clearTemporaryPassenger = useCallback(() => {
    setValue("temporaryPassenger.name", "");
    setValue("temporaryPassenger.phoneFields", {
      mobilePhone: "",
      mobilePhoneInternational: "",
      phoneCountryCode: "",
      phoneCountryDialCode: "",
      phoneCountryName: "",
      phoneCountryFormat: "",
    });
  }, [setValue]);

  // event handlers
  const handleClose = () => {
    clearTemporaryPassenger();
    setTemporaryPassengerDialogOpen(false);
    setMenuOpen(null);
  };

  const handleSave = async () => {
    setMenuOpen(null);
    setTemporaryPassengerDialogOpen(false);
  };

  // effect
  useEffect(() => {
    if (open) {
      clearTemporaryPassenger();
    }
  }, [open, clearTemporaryPassenger]);

  return (
    <MoovsDialog
      hideTopBorder
      size={isMobileView ? "md" : "sm"}
      dialogTitle="Temporary Passenger"
      acceptButtonText="Update"
      closeButtonText="Cancel"
      open={open}
      onClose={handleClose}
      onAccept={handleSave}
    >
      <Box mb={3}>
        <Typography sx={{ mb: 1.5 }} variant="h4">
          Passenger Contact
        </Typography>
        <Box mb={1}>
          <RHFTextField
            fullWidth
            variant="outlined"
            label="Name"
            name="temporaryPassenger.name"
            control={control}
          />
        </Box>
        <RHFInternationalPhoneInput
          name="temporaryPassenger.phoneFields"
          label="Mobile Phone"
          control={control}
        />
      </Box>
    </MoovsDialog>
  );
}

export default TemporaryPassengerDialog;
