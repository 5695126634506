import React from "react";

import {
  Popper,
  Box,
  Grow,
  Paper,
  ClickAwayListener,
  styled,
} from "@mui/material";

type AnchorPopperProps = {
  anchorRef: HTMLInputElement;
  open: boolean;
  children: React.ReactChild;
  onClickAway: () => void;
};

const StyledPopper = styled(Popper)(() => ({
  zIndex: 2010, // higher z-index than fixed header
  marginTop: 5,
}));

function AnchorPopper(props: AnchorPopperProps) {
  const { anchorRef, open, children, onClickAway } = props;
  return (
    <StyledPopper
      open={open}
      anchorEl={anchorRef}
      placement="bottom-end"
      transition
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: "center top",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={onClickAway}>
              <Box>{children}</Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </StyledPopper>
  );
}

export default AnchorPopper;
