import React from "react";
import isNil from "lodash/isNil";

import { Box, Tooltip, Typography } from "@mui/material";

import { currency } from "../../utils/helpers";
import { RoutePricing } from "../../types";
import { toPercentString } from "../../utils/currency";
import { renderBaseRateFieldLabel } from "./utils/displayUtils";

export type PricingItemsBlockProps = {
  pricing: RoutePricing;
};

function PricingItemsBlock(props: PricingItemsBlockProps) {
  const { pricing } = props;

  const pricingList = [
    {
      title: renderBaseRateFieldLabel(
        pricing.hourlyBaseRate,
        pricing.hourlyBaseRateHours
      ),
      amount: pricing.baseRateAmt,
    },
    {
      title: "Driver Gratuity",
      amount: pricing.driverGratuityAmt,
      percent: pricing.driverGratuityPercent,
    },
    {
      title: "Discount",
      amount: pricing.promoDiscountAmt,
      percent: pricing.promoDiscountPercent,
      isNegative: true,
    },
    {
      title: "Tax",
      amount: pricing.taxAmt,
      percent: pricing.taxPercent,
    },
    {
      title: "Tolls",
      amount: pricing.tollsAmt,
    },
    {
      title:
        pricing.rearFacingSeatQuantity > 1
          ? `${pricing.rearFacingSeatQuantity} X Rear-Facing Seat`
          : "Rear-Facing Seat",
      amount: pricing.rearFacingSeatAmt,
    },
    {
      title:
        pricing.forwardFacingSeatQuantity > 1
          ? `${pricing.forwardFacingSeatQuantity} X Forward-Facing Seat`
          : "Forward-Facing Seat",
      amount: pricing.forwardFacingSeatAmt,
    },
    {
      title:
        pricing.boosterSeatQuantity > 1
          ? `${pricing.boosterSeatQuantity} X Booster Seat`
          : "Booster Seat",
      amount: pricing.boosterSeatAmt,
    },
    {
      title: pricing.otherName || "Other",
      amount: pricing.otherAmt,
      percent: pricing.otherPercent,
    },
    {
      title: pricing.other2Name || "Other 2",
      amount: pricing.other2Amt,
      percent: pricing.other2Percent,
    },
    {
      title: pricing.other3Name || "Other 3",
      amount: pricing.other3Amt,
      percent: pricing.other3Percent,
    },
    {
      title: "Meet & Greet",
      amount: pricing.meetGreetAmt,
    },
    {
      title: `Promo Code (${pricing.promoCodeName})`,
      amount: pricing.promoCodeAmt,
      percent: pricing.promoCodePercent,
      isNegative: true,
    },
  ];

  // if cancellation fee is provided, render it alone
  if (!!pricing.cancellationFee) {
    return (
      <Box display="flex" flexDirection="column">
        <PricingItem
          title="Cancellation Fee"
          amount={pricing.cancellationFee}
        />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column">
      {pricingList.map((pricingItem) => {
        const { amount, percent, title, isNegative } = pricingItem;

        if (isNil(amount) && isNil(percent)) return null;

        const displayTitle = !isNil(percent)
          ? `${title} (${toPercentString(percent)})`
          : title;

        const displayAmount = !isNil(amount)
          ? amount
          : percent * pricing.baseRateAmt;

        return (
          <PricingItem
            key={title}
            title={displayTitle}
            isNegative={isNegative}
            amount={displayAmount}
          />
        );
      })}
    </Box>
  );
}

export default PricingItemsBlock;

type PricingItemType = {
  title: string;
  amount: number;
  isNegative?: boolean;
};

function PricingItem(props: PricingItemType) {
  const { amount, isNegative, title } = props;
  return (
    <Box display="flex" flex="1" flexDirection="row" mb={2}>
      <Box display="flex" flex="1.5" minWidth={10}>
        <Tooltip title={title} style={{ cursor: "pointer" }}>
          <Typography variant="h6" noWrap>
            {title}
          </Typography>
        </Tooltip>
      </Box>
      <Box display="flex" flex=".5" justifyContent="flex-end">
        <Typography variant="h6">{`${isNegative ? "-" : ""}${currency(
          amount
        )}`}</Typography>
      </Box>
    </Box>
  );
}
