/**
 * @file useUpdateBookingContact.tsx
 *
 * Handles updating global state when navigating away from this form.
 * Only useCase right now is so that clicking on previous button and
 * returning still has the form state.
 */
import { useCallback, useEffect } from "react";
import { UseFormGetValues, UseFormWatch } from "react-hook-form";

import { CreateQuoteFormState } from "pages/new/steps/confirm/components/CreateQuoteForm/form/schemaValidation";
import { CreateRequestReduxActionTypes } from "pages/new/context/reducer/types";
import { useCreateRequestContext } from "pages/new/context/useCreateRequestContext";

type UseUpdateBookingContactParams = {
  watch: UseFormWatch<any>;
  getValues: UseFormGetValues<any>;
};

export const useUpdateBookingContact = (
  params: UseUpdateBookingContactParams
) => {
  const { watch, getValues } = params;

  // hooks
  const [_, dispatch] = useCreateRequestContext();

  const bookingContactFirstName = watch("bookingContact.firstName");
  const bookingContactLastName = watch("bookingContact.lastName");
  const bookingContactMobilePhone = watch(
    "bookingContact.phoneFields.mobilePhone"
  );

  // event handlers
  const handleUpdateBookingContact = useCallback(
    (createQuoteFormState: CreateQuoteFormState) => {
      dispatch({
        type: CreateRequestReduxActionTypes.UpdateConfirmPage,
        payload: createQuoteFormState,
      });
    },
    [dispatch]
  );

  // on unmount, update parent state
  useEffect(() => {
    handleUpdateBookingContact({ bookingContact: getValues("bookingContact") });
  }, [
    bookingContactFirstName,
    bookingContactLastName,
    bookingContactMobilePhone,
    handleUpdateBookingContact,
    getValues,
  ]);
};
