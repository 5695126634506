import React, { useMemo } from "react";
import capitalize from "lodash/capitalize";
import size from "lodash/size";

import { Box, Card, Typography, Divider } from "@mui/material";

import { grayDark, moovsBlue, moovsBlueSelected } from "design-system/colors";
import {
  BriefcaseIcon,
  PageViewIcon,
  SeatIcon,
  USBIcon,
  VehicleIcon,
} from "design-system/icons";
import MoovsIconPanel from "../../../globals/MoovsIconPanel";
import { useScreenSize } from "globals/hooks";
import { Vehicle } from "types";

type VehicleCardProps = {
  vehicle: Vehicle;
  isNameAndCapacityOnly?: boolean;
  isSelectedVehicle?: boolean;
  isWhiteLabel?: boolean;
};

function VehicleCard(props: VehicleCardProps) {
  const { vehicle, isNameAndCapacityOnly, isSelectedVehicle, isWhiteLabel } =
    props;

  // hooks
  const { isMobileView } = useScreenSize();

  // derived state

  const vehiclePic = vehicle?.photos[0] && vehicle?.photos[0].url;

  const vehicleFeaturesByCategory = useMemo(() => {
    const features = vehicle?.features || [];

    return {
      general: features
        .filter(({ category }) => category === "general")
        .map(({ name }) => name),
      multimedia: features
        .filter(({ category }) => category === "multimedia")
        .map(({ name }) => name),
      policies: features
        .filter(({ category }) => category === "policies")
        .map(({ name }) => name),
    };
  }, [vehicle?.features]);

  const vehicleName = useMemo(() => {
    if (!isWhiteLabel) return vehicle?.name;

    const vehicleColor = capitalize(vehicle.exteriorColor);
    const vehicleType = capitalize(vehicle.vehicleType.typeSlug);

    return `${vehicleColor} ${vehicleType}`;
  }, [
    isWhiteLabel,
    vehicle.exteriorColor,
    vehicle?.name,
    vehicle.vehicleType.typeSlug,
  ]);

  return (
    <Card
      variant="outlined"
      {...(isSelectedVehicle && {
        style: {
          backgroundColor: moovsBlueSelected,
          border: `2px solid ${moovsBlue}`,
          maxWidth: "552px",
        },
      })}
    >
      <Box display="flex" flexDirection="column" my={2} mx={3}>
        {!isNameAndCapacityOnly && (
          <Box mb={3}>
            <Typography variant="h3">Vehicle</Typography>
          </Box>
        )}
        <Box
          display="flex"
          flexDirection={isMobileView ? "column" : "row"}
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="row">
            <Box
              component="img"
              mr={2}
              sx={{
                borderRadius: 1,
                width: "80px", // 4:3 aspect ratio
                height: "60px",
                objectFit: "cover",
              }}
              alt={vehicle?.name}
              src={vehiclePic}
            />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="60px"
            >
              <Box mb={1}>
                {/* name */}
                <Typography variant="body2" style={{ fontWeight: 700 }}>
                  {vehicleName}
                </Typography>
              </Box>
              {isNameAndCapacityOnly && (
                <Box display="flex" alignItems="center">
                  <Typography variant="caption">
                    {`${vehicle?.vehicleType.typeName} . ${vehicle?.capacity} ${
                      vehicle.capacity === 1 ? "person" : "persons"
                    }`}
                  </Typography>
                </Box>
              )}
              {!isNameAndCapacityOnly && (
                <Box display="flex" flexDirection="row">
                  {/* type */}
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <VehicleIcon />
                    <Typography
                      variant="caption"
                      style={{ marginLeft: "10px" }}
                    >
                      {vehicle?.vehicleType.typeName}
                    </Typography>
                  </Box>

                  <Box mx={2}>
                    <Divider orientation="vertical" />
                  </Box>

                  {/* capacity */}
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <SeatIcon />
                    <Typography
                      variant="caption"
                      style={{ marginLeft: "10px" }}
                    >
                      {vehicle?.capacity}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          {!isNameAndCapacityOnly && (
            <Box
              display="flex"
              flexDirection={isMobileView ? "row" : "column"}
              alignItems={isMobileView ? "center" : "flex-end"}
              {...(isMobileView && { marginTop: 2 })}
            >
              <Box
                display="flex"
                justifyContent="flex-end"
                width={80}
                {...(isMobileView
                  ? { marginRight: "10px" }
                  : { marginBottom: 1 })}
              >
                <Typography variant="overline">FEATURES</Typography>
              </Box>
              {/* features */}
              <MoovsIconPanel
                centerAlignDrawerText={false}
                bottomDrawerHeader="Vehicle Features"
                items={[
                  {
                    mainText: "General",
                    subText: vehicleFeaturesByCategory.general,
                    icon: <BriefcaseIcon color={grayDark} />,
                    renderItem: !!size(vehicleFeaturesByCategory?.general),
                  },
                  {
                    mainText: "Multimedia",
                    subText: vehicleFeaturesByCategory.multimedia,
                    icon: <USBIcon color={grayDark} />,
                    renderItem: !!size(vehicleFeaturesByCategory?.multimedia),
                  },
                  {
                    mainText: "Policies",
                    subText: vehicleFeaturesByCategory.policies,
                    icon: <PageViewIcon color={grayDark} />,
                    renderItem: !!size(vehicleFeaturesByCategory?.policies),
                  },
                ]}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Card>
  );
}

export default VehicleCard;
