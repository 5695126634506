import * as yup from "yup";
import moment from "moment";

import { TripCategory } from "types";

export const MAX_GROUP_SIZE = 99;
export const MIN_GROUP_SIZE = 1;

export const errorSchema = yup.object({
  dateTime: yup
    .string()
    .nullable()
    .required("Please enter pick-up date time")
    .typeError("Please enter pick-up date time"),
  returnDateTime: yup
    .string()
    .nullable()
    .typeError("Please enter return date time")
    .test({
      name: "Validate return date time",
      message: "Please enter return date time",
      test: function () {
        const { parent } = this;

        return (
          parent.tripCategory !== TripCategory.RoundTrip ||
          !!parent.returnDateTime
        );
      },
    })
    .test({
      name: "Validate order of round trip date time",
      message: "Return date & time must be later than pick-up date & time",
      test: function () {
        const {
          parent: { dateTime, returnDateTime, tripCategory },
        } = this;

        return (
          tripCategory !== TripCategory.RoundTrip ||
          moment(dateTime).isSameOrBefore(returnDateTime)
        );
      },
    }),
  pickupLocation: yup
    .object()
    .required("Please enter pickup location")
    .typeError("Please enter location")
    .test({
      name: "Validate pickup location",
      message: "Please enter location",
      test: (val) => val?.description,
    }),
  dropoffLocation: yup
    .object()
    .required("Please enter location")
    .typeError("Please enter location")
    .test({
      name: "Validate dropoff location",
      message: "Please enter location",
      test: (val) => val?.description,
    }),
  note: yup.string().max(120, "Max character limit of 120 reached"),
  totalGroupSize: yup
    .string()
    .required("Please enter a total passenger count")
    .typeError("Please enter a total passenger count")
    .test({
      name: "Validate valid value",
      message: "Please enter a valid passenger count.",
      test: (val) => {
        const numberVal = Number(val);
        return numberVal >= MIN_GROUP_SIZE && numberVal <= MAX_GROUP_SIZE;
      },
    }),
  totalDuration: yup
    .number()
    .nullable()
    .typeError("Please select duration")
    .test({
      name: "Validate duration",
      message: "Please select duration",
      test: function () {
        const { parent } = this;

        return (
          parent.tripCategory !== TripCategory.Hourly || !!parent.totalDuration
        );
      },
    }),
  orderType: yup.object().required("Please select an order type").nullable(),
});
