import React from "react";

import { Box, Divider } from "@mui/material";

import { useScreenSize } from "globals/hooks";
import { Trip } from "types";
import AdditionalTripInfoBlock from "./components/TripItineraryCard/components/AdditionalTripInfoBlock";
import TripItineraryCard from "./components/TripItineraryCard/TripItineraryCard";
import VehicleCard from "./components/VehicleCard";
import SolicitChangeBanner from "./SolicitChangeBanner";
import first from "lodash/first";

type TripDetailsUpdateBlockProps = {
  isQuote: boolean;
  orderNumber: string;
  activeTrip: Trip;
  tripTabs?: React.ReactNode;
  hideRequestChangeBanner?: boolean;
};

function TripDetailsUpdateBlock(props: TripDetailsUpdateBlockProps) {
  const {
    isQuote,
    activeTrip,
    tripTabs,
    orderNumber,
    hideRequestChangeBanner,
  } = props;

  // hooks
  const { isTabletView, isMobileView } = useScreenSize();

  return (
    <>
      <Box
        display="flex"
        flex="3"
        flexDirection="column"
        {...(!isTabletView && { mr: 2 })}
      >
        {tripTabs && <Box mb={2}>{tripTabs}</Box>}

        {!hideRequestChangeBanner && (
          <Box mb={3}>
            <SolicitChangeBanner trip={activeTrip} />
          </Box>
        )}
        <Box mb={2}>
          <TripItineraryCard
            mode="update"
            isQuote={isQuote}
            orderNumber={orderNumber}
            hasReturnTrip={!!activeTrip.returnTrip}
            trip={activeTrip}
            additionalTripInfoBlock={
              <AdditionalTripInfoBlock
                mode="update"
                trip={activeTrip}
                passenger={activeTrip.contact}
              />
            }
          />
          <Box my={2}>
            <VehicleCard vehicle={first(activeTrip.routes).vehicle} />
          </Box>

          {/* return trip */}
          {activeTrip.returnTrip && (
            <>
              <Divider sx={{ mt: 3 }} />
              {!isQuote && (
                <Box mb={3}>
                  <SolicitChangeBanner trip={activeTrip.returnTrip} />
                </Box>
              )}

              <Box
                display="flex"
                flexDirection={isMobileView ? "column" : "row"}
                pt={3}
              />
              <TripItineraryCard
                mode="update"
                isQuote={isQuote}
                orderNumber={orderNumber}
                isReturnTrip
                trip={activeTrip.returnTrip}
                additionalTripInfoBlock={
                  <AdditionalTripInfoBlock
                    mode="update"
                    trip={activeTrip.returnTrip}
                    passenger={activeTrip.returnTrip.contact}
                  />
                }
              />
            </>
          )}
        </Box>
      </Box>
    </>
  );
}

export default TripDetailsUpdateBlock;
