import React from "react";

type LogoutIconProps = {
  color?: string;
  size: "small";
};

const LogoutIcon = (props: LogoutIconProps) => {
  const { color } = props;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.25 8H15.0001M4.25 8L7.5 4.66667M4.25 8L7.5 11.3333"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 3.5V2C12 1.44772 11.5523 1 11 1H2C1.44772 1 1 1.44772 1 2V14C1 14.5523 1.44771 15 2 15H11C11.5523 15 12 14.5523 12 14V12.5"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LogoutIcon;
