import React from "react";

import { Box, Typography } from "@mui/material";

import { granite, grayLight } from "../../../design-system/colors";
import RoundChip from "../../../design-system/components/RoundChip";
import { Vehicle } from "types";

type CancellationPolicyVehicleListProps = {
  vehicles: Vehicle[];
};

function CancellationPolicyVehicleList(
  props: CancellationPolicyVehicleListProps
) {
  const { vehicles } = props;

  return (
    <Box mt={2} mb={4} ml={-0.5}>
      <Typography variant="h6">APPLIES TO</Typography>
      <Box display="flex" flex={1} flexWrap="wrap" ml={-0.5}>
        {vehicles.map((vehicle, index) => {
          return (
            <Box key={index}>
              <Box m={0.5}>
                <RoundChip
                  label={vehicle.name}
                  color={granite}
                  backgroundColor={grayLight}
                  textTransform="uppercase"
                />
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default CancellationPolicyVehicleList;
