/**
 * Future Improvements
 * - add optional prop to enable chrome-auto suggestions
 * - allow transform output function - not implemented now because the manually controlled textinput would require reversal of outputted values + need to consider behavior with input
 *   - introduces many additional edge cases, resulting in a problem not worth solving at the moment
 */
import { Autocomplete, AutocompleteProps } from "@mui/material";
import isEqual from "lodash/isEqual";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { usePrevious } from "../../../globals/hooks";

// transform - https://react-hook-form.com/advanced-usage#TransformandParse
export type RHFTransform = {
  input?: (value: any) => any;
  output?: (value: any) => any;
};

type RHFAutocompleteProps<T> = {
  name: string;
  required?: boolean;
  label?: string;
  transform?: RHFTransform;
  onAdditionalAction?: (value: any, nameOfUpdatedField?: string) => void;
} & AutocompleteProps<T, false, false, undefined>; // T is an option

export const RHFAutocomplete = <T,>(props: RHFAutocompleteProps<T>) => {
  const {
    name,
    transform,
    onAdditionalAction,
    required,
    label: propLabel,
    ...autocompleteProps
  } = props;

  // manually add "*" to required fields without passing in as prop to TextField,
  // as 'required' prop collides with React Hook Form validation
  const label = required ? `${propLabel} *` : propLabel;

  // state
  // manually control text field b/c sometimes controlled externally
  const [textFieldValue, setTextFieldValue] = useState("");

  // form hooks
  const { control, register, watch } = useFormContext();
  const { ref: inputRef } = register(name);
  const watchedFieldValue = watch(name);
  const prevWatchedFieldValue = usePrevious(watchedFieldValue);

  // transform functions
  const transformInput = useMemo(
    () => transform?.input || ((val) => val),
    [transform?.input]
  );

  // effects
  // update the TextField value if the field value has changed externally
  useEffect(() => {
    if (
      !isEqual(prevWatchedFieldValue, watchedFieldValue) &&
      !isEqual(watchedFieldValue, textFieldValue)
    ) {
      setTextFieldValue(
        (transform?.input
          ? transformInput(watchedFieldValue)
          : watchedFieldValue) || ""
      );
    }
  }, [
    prevWatchedFieldValue,
    textFieldValue,
    transform?.input,
    transformInput,
    watchedFieldValue,
  ]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          // ordering of props is important/delicate because of what we want react hook form to always override
          <Autocomplete
            {...autocompleteProps}
            onChange={(event, item) => {
              onChange(transform?.output ? transform.output(item) : item);

              if (onAdditionalAction) {
                onAdditionalAction(item, name);
              }

              // update text field with selected value
              setTextFieldValue(transformInput(item) || "");
            }}
            value={transformInput(value) || ""}
            // future: move TextField into the renderInput and remove renderInput prop from component usage
            renderInput={(params) => {
              return props.renderInput({
                ...params,
                // note: the below props works because we currently expect autocompletes to always render with Mui TextFields
                inputProps: {
                  ...params.inputProps,
                  onChange: (e) => {
                    if (params.inputProps?.onChange) {
                      params.inputProps.onChange(e);
                    }
                    //MUI TextField uses HTMLTextAreaElement, instead of HTMLInputElement
                    setTextFieldValue(
                      (e.target as HTMLTextAreaElement).value || ""
                    );
                  },
                  // @ts-ignore
                  value: textFieldValue,
                  autoComplete: "new-password", // prevent chrome auto-suggest.
                },
                error: !!error?.message,
                helperText: error?.message,
                required: false,
                label: label,
                inputRef: inputRef,
              });
            }}
          />
        );
      }}
    />
  );
};
