import { useCallback, useEffect, useState } from "react";

import { TrackedDriver } from "../types";
import { setIconPathByRotation } from "../utils/setIconPathByRotation";

type UseActiveDriverIconProps = {
  map: google.maps.Map;
  trackedDrivers: TrackedDriver[];
};

function useActiveDriverIcon(props: UseActiveDriverIconProps) {
  const { map, trackedDrivers } = props;

  // state
  const [driverMarkers, setDriverMarkers] = useState<
    Map<string, google.maps.Marker>
  >(new Map());

  // event handlers
  const setMapBound = useCallback(() => {
    if (trackedDrivers.length === 0) return;
    const bounds = new google.maps.LatLngBounds();
    trackedDrivers.forEach(
      ({
        location: {
          coords: { latitude: lat, longitude: lng },
        },
      }) => {
        if (lat && lng) {
          bounds.extend({ lat, lng });
        }
      }
    );

    if (map) {
      map.setCenter(bounds.getCenter());
      map.fitBounds(bounds);
      if (trackedDrivers.length === 1) {
        map.setZoom(11);
      }
    }
  }, [trackedDrivers, map]);

  // add, remove, and update drivers by comparing previous markers to incoming trackedDrivers
  useEffect(() => {
    let shouldResetMapBounds = false;
    let shouldUpdateDriverMarkers = false;
    const newDriverMarkers = new Map();
    trackedDrivers.forEach(
      ({
        id,
        location: {
          coords: { latitude: lat, longitude: lng, heading },
          timestamp: currentTimeStamp,
        },
      }) => {
        if (lat && lng) {
          // if trackedDriver id does not exist in previous markers, create new Marker
          if (!driverMarkers.has(id)) {
            shouldResetMapBounds = true;
            shouldUpdateDriverMarkers = true;
            const marker = new google.maps.Marker({
              position: { lat, lng },
              icon: createDriverIcon(heading),
              map: map,
              zIndex: 100,
            });
            marker.set("timestamp", currentTimeStamp);
            newDriverMarkers.set(id, marker);
          } else {
            // if trackedDriver id exists in previous markers, and timestamp is different (change of coordinates), update Marker
            const previousMarker = driverMarkers.get(id);
            if (currentTimeStamp !== previousMarker["timestamp"]) {
              shouldUpdateDriverMarkers = true;
              previousMarker.setPosition({ lat, lng });
              previousMarker.setIcon(createDriverIcon(heading));
              previousMarker.set("timestamp", currentTimeStamp);
            }
            newDriverMarkers.set(id, previousMarker);
          }
        }
      }
    );

    // remove inactive drivers on map
    driverMarkers.forEach((prevMarker, prevDriverId) => {
      if (!newDriverMarkers.has(prevDriverId)) {
        shouldUpdateDriverMarkers = true;
        shouldResetMapBounds = true;
        prevMarker.setMap(null);
      }
    });

    if (shouldResetMapBounds) setMapBound();
    if (shouldUpdateDriverMarkers) setDriverMarkers(newDriverMarkers);
  }, [trackedDrivers, map, setMapBound, driverMarkers]);
}

const createDriverIcon = (rotation: number) => {
  return {
    url: setIconPathByRotation(rotation),
    anchor: new google.maps.Point(12, 20),
    labelOrigin: new google.maps.Point(16, 42),
    scaledSize: new google.maps.Size(32, 32),
  };
};

export default useActiveDriverIcon;
