import React from "react";
import { Control, Controller, useFormContext } from "react-hook-form";

import { Box, ButtonProps } from "@mui/material";

import MoovsIncrementer from "components/globals/MoovsIncrementer";

type RHFMoovsIncrementerProps = {
  name: string;
  limits: [number, number];
  textFieldAdornment?: string;
  disabled?: boolean;
  ButtonProps?: ButtonProps;
  iconColor?: string;
  control?: Control<any>;
};

export const RHFMoovsIncrementer = (props: RHFMoovsIncrementerProps) => {
  const { name, limits, disabled, textFieldAdornment, ButtonProps, iconColor } =
    props;

  const context = useFormContext();

  const control = props.control || context.control;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const handleIncrement = () => onChange(Number(value) + 1);
        const handleDecrement = () => onChange(Number(value) - 1);

        return (
          <Box display="flex" flexDirection="column" alignItems="center">
            <MoovsIncrementer
              value={value}
              limits={limits}
              decrement={handleDecrement}
              increment={handleIncrement}
              onTextFieldChange={onChange}
              disabled={disabled}
              textFieldAdornment={textFieldAdornment}
              errorMessage={error?.message}
              ButtonProps={ButtonProps}
              iconColor={iconColor}
            />
          </Box>
        );
      }}
    />
  );
};
