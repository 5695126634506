import React from "react";
import size from "lodash/size";

import { Box, CircularProgress } from "@mui/material";

import { RequestStage, RequestTimeFrameEnum } from "types";
import EmptyList from "components/EmptyList";
import GQLQueryStatusIndicator from "components/GQLQueryStatusIndicator";
import { useAnalytics, useOperatorSlug } from "globals/hooks";
import { useHistory } from "react-router-dom";
import { useRequests } from "../hooks/useRequests";
import emptyOrders from "../../../illustrations/emptyOrders.png";
import OrdersList from "./OrdersList";

function QuotesTab() {
  // hooks
  const history = useHistory();
  const { track } = useAnalytics();
  const operatorSlug = useOperatorSlug();
  const [quotes, quotesError, quotesLoading] = useRequests({
    stage: RequestStage.Quote,
    timeFrame: RequestTimeFrameEnum.Upcoming, // we only show customers upcoming quotes
  });

  // derived state
  const isEmpty = !size(quotes);

  if (quotesError) {
    return <GQLQueryStatusIndicator error={quotesError} name="Orders Data" />;
  }

  if (quotesLoading) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        flex="1"
        px={3}
        mt={2}
        mb={1}
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isEmpty) {
    return (
      <EmptyList
        title={`You have no quotes.`}
        ctaCopy="Create an Order"
        imageUrl={emptyOrders as string}
        onCreateClick={() => {
          track("orders_createOrder");
          history.push(`/${operatorSlug}/request/new`);
        }}
      />
    );
  }

  return (
    <Box flex="1" mt={2} mb={8}>
      <OrdersList requests={quotes} />
    </Box>
  );
}

export default QuotesTab;
