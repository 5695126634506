/**
 * @file Layout.tsx
 * Top and SideNav HOC that wraps operator-app
 *
 * reactComponents:
 *   Layout
 */

import React, { useEffect, useState } from "react";
import { Box, CircularProgress, styled, useTheme } from "@mui/material";
import { Helmet } from "react-helmet";
import { Redirect, useLocation } from "react-router-dom";
import isEmpty from "lodash/isEmpty";

import TopBar from "./TopBar";
import { useOperator } from "../globals/hooks";

const ContentDiv = styled("div")({
  display: "flex",
  flexGrow: 1,
  minHeight: "100%",
});

const RootDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  minHeight: "100%",
  backgroundColor: "#FFFFFF",
});

function Layout({ children }) {
  const [redirectTo404, setRedirectTo404] = useState(false);

  const location = useLocation();
  const { operator, loading, error } = useOperator();
  const theme = useTheme();

  // effects
  useEffect(() => {
    if (!loading && (error || isEmpty(operator))) {
      setRedirectTo404(true);
    }
  }, [loading, error, operator]);

  return (
    <>
      <Helmet>
        <title>{operator?.name}</title>
      </Helmet>
      <RootDiv
        sx={{
          paddingBottom: theme.breakpoints.down("sm") ? theme.spacing(1) : 0,
        }}
      >
        <Box display="flex" justifyContent="center">
          <TopBar />
        </Box>

        {loading && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100vw"
            height="100vh"
          >
            <CircularProgress />
          </Box>
        )}

        <ContentDiv>{children}</ContentDiv>

        {redirectTo404 && (
          <Redirect
            push
            to={{
              pathname: "/404",
              state: { referrer: location.pathname },
            }}
          />
        )}
      </RootDiv>
    </>
  );
}

export default Layout;
