import startCase from "lodash/startCase";
import React from "react";

import { Box, Typography } from "@mui/material";

import { CardItem } from "types";
import { useScreenSize } from "globals/hooks";

type CardDetailsProps = {
  name: string;
  card: CardItem;
};

function CardDetails(props: CardDetailsProps) {
  const {
    name,
    card: { brand, last4, expMonth, expYear },
  } = props;

  // hook
  const { isMobileView } = useScreenSize();

  return (
    <Box display="flex" flexDirection="column" flex="3">
      {/* top row */}
      <Box display="flex" flexDirection={isMobileView ? "column" : "row"}>
        <Box
          display="flex"
          flexDirection="column"
          flex="1"
          mb={isMobileView ? 1 : 0}
        >
          <Typography variant="overline">Card Name</Typography>
          <Typography variant="body2">{startCase(name) || "-"}</Typography>
        </Box>
        <Box display="flex" flexDirection="column" flex="1">
          <Typography variant="overline">Card Brand</Typography>
          <Typography variant="body2">{startCase(brand) || "-"}</Typography>
        </Box>
      </Box>
      {/* bottom row */}
      <Box
        display="flex"
        mt={isMobileView ? 1 : 2}
        flexDirection={isMobileView ? "column" : "row"}
      >
        <Box
          display="flex"
          flexDirection="column"
          flex="1"
          mb={isMobileView ? 1 : 0}
        >
          <Typography variant="overline">Card Number</Typography>
          <Typography variant="body2">{`****-${last4 || "-"}`}</Typography>
        </Box>
        <Box display="flex" flexDirection="column" flex="1">
          <Typography variant="overline">Expiration Date</Typography>
          <Typography variant="body2">{`${expMonth || "-"}/${
            expYear || "-"
          }`}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default CardDetails;
