import React from "react";

import RoundChip from "design-system/components/RoundChip";
import { Request, RequestStage } from "types";
import { getReservationFilterStatus } from "./util/getReservationFilterStatus";
import { ReservationFilterStatusVariant } from "./ReservationDropdownFilter";
import {
  white,
  successGreen,
  errorRed,
  moovsBlue,
  granite,
  tintGreen,
  tintRed,
  moovsBlueSelected,
} from "design-system/colors";

type RequestStatusRoundChipProps = {
  request: Request;
};

const StatusTextColor = {
  QUOTE: white,
  CONFIRMED: successGreen,
  CANCELLED: errorRed,
  DECLINED: errorRed,
  UNCONFIRMED: moovsBlue,
};

const StatusBgColor = {
  QUOTE: granite,
  CONFIRMED: tintGreen,
  CANCELLED: tintRed,
  DECLINED: tintRed,
  UNCONFIRMED: moovsBlueSelected,
};

const StatusLabel = {
  QUOTE: RequestStage.Quote,
  CONFIRMED: RequestStage.Reservation,
  CANCELLED: ReservationFilterStatusVariant.Cancelled,
  DECLINED: ReservationFilterStatusVariant.Declined,
  UNCONFIRMED: ReservationFilterStatusVariant.Unconfirmed,
};

function RequestStatusRoundChip(props: RequestStatusRoundChipProps) {
  const { request } = props;

  const chipStatus =
    request.stage === RequestStage.Quote
      ? RequestStage.Quote
      : getReservationFilterStatus(request);

  return (
    <RoundChip
      label={StatusLabel[chipStatus]}
      color={StatusTextColor[chipStatus]}
      backgroundColor={StatusBgColor[chipStatus]}
    />
  );
}

export default RequestStatusRoundChip;
