import React from "react";
import { UseFormSetValue } from "react-hook-form";

import {
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";

import ReplacePassengerIcon from "design-system/icons/navigation/ReplacePassengerIcon";
import { useCurrentUser, useScreenSize } from "globals/hooks";
import { SelectedPassenger } from "globals/hooks/useDefaultPassenger";

type ReplacePassengerMenuButtonProps = {
  setAddLinkedPassengerDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setMenuOpen: React.Dispatch<React.SetStateAction<HTMLElement>>;
  menuOpen: HTMLElement;
  setTemporaryPassengerDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  temporaryPassengerSelected: boolean;
  setSelectedPassenger: React.Dispatch<React.SetStateAction<SelectedPassenger>>;
  selectedPassengerIsBookingContact: boolean;
  setValue: UseFormSetValue<any>;
};

function ReplacePassengerMenuButton(props: ReplacePassengerMenuButtonProps) {
  const {
    setAddLinkedPassengerDialogOpen,
    setMenuOpen,
    menuOpen,
    setTemporaryPassengerDialogOpen,
    temporaryPassengerSelected,
    setSelectedPassenger,
    selectedPassengerIsBookingContact,
    setValue,
  } = props;

  // hooks
  const currentUser = useCurrentUser();
  const { isMobileView } = useScreenSize();
  const theme = useTheme();

  // derived state
  const open = Boolean(menuOpen);

  // event handlers
  const handleCloseMenuItem = () => {
    setMenuOpen(null);
  };

  const handleReplacePassengerButtonClick = (
    e: React.MouseEvent<HTMLElement>
  ) => {
    setMenuOpen(e.currentTarget);
  };

  const handleReplacePassengeMenuItemClick = () => {
    setAddLinkedPassengerDialogOpen(true);
  };

  const handleTemporaryPassengerMenuItemClick = () => {
    setTemporaryPassengerDialogOpen(true);
  };

  const handlePassengerIsMeMenuItemClick = () => {
    setSelectedPassenger({
      id: currentUser.id,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      email: currentUser.email,
      mobilePhone: currentUser.mobilePhone,
    });
    setValue("temporaryPassenger.name", "");
    setValue("temporaryPassenger.phoneFields", {
      mobilePhone: "",
      mobilePhoneInternational: "",
      phoneCountryCode: "",
      phoneCountryDialCode: "",
      phoneCountryName: "",
      phoneCountryFormat: "",
    });
    handleCloseMenuItem();
  };

  return (
    <>
      <Button
        id="replace-passenger-button"
        aria-controls={open ? "replace-passenger-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleReplacePassengerButtonClick}
      >
        {isMobileView ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <ReplacePassengerIcon />
            {selectedPassengerIsBookingContact &&
            !temporaryPassengerSelected ? (
              <Box mr={1} mt={0.5}>
                <Typography sx={{ fontSize: "12px", width: "70px" }}>
                  Passenger is not me
                </Typography>
              </Box>
            ) : null}
          </Box>
        ) : (
          <Box display="flex">
            {selectedPassengerIsBookingContact &&
            !temporaryPassengerSelected ? (
              <Box mr={1}>
                <Typography sx={{ fontSize: "14px" }}>
                  Passenger is not me
                </Typography>
              </Box>
            ) : null}

            <ReplacePassengerIcon color={theme.palette.primary.main} />
          </Box>
        )}
      </Button>
      <Menu
        id="replace-passenger-menu"
        aria-labelledby="replace-passenger-button"
        anchorEl={menuOpen}
        open={open}
        onClose={handleCloseMenuItem}
      >
        <Box ml={2.5} mb={1}>
          <Typography variant="overline">Replace Passenger</Typography>
        </Box>

        <MenuItem
          onClick={handleReplacePassengeMenuItemClick}
          sx={{ marginX: 1.2, paddingY: 0.5, marginY: 0.5 }}
        >
          <Typography variant="body2">Add Linked Passenger</Typography>
        </MenuItem>

        <MenuItem
          onClick={handleTemporaryPassengerMenuItemClick}
          sx={{ marginX: 1.2, paddingY: 0.5, marginY: 0.5 }}
        >
          <Typography variant="body2">Temporary Passenger</Typography>
        </MenuItem>
        {(!selectedPassengerIsBookingContact || temporaryPassengerSelected) && (
          <MenuItem
            onClick={handlePassengerIsMeMenuItemClick}
            sx={{ marginX: 1.2, paddingY: 0.5, marginY: 0.5 }}
          >
            <Typography variant="body2">Passenger Is Me</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

export default ReplacePassengerMenuButton;
