/**
 * @file OrderPage.tsx
 * View existing orders
 *
 * Could have one of three variants:
 *   QuoteRequest - a quote pending
 *   ReviewAndReserve - a quote that has been marked ready for payment by operator (by sending quote or sales automation)
 *   ReservationConfirmation - Reservation
 */
import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import { Box, CircularProgress } from "@mui/material";
import GQLQueryStatusIndicator from "components/GQLQueryStatusIndicator";
import { LOAD_REQUEST_QUERY } from "globals/graphql";
import { Request } from "types";
import OrderDetailsPage from "./components/OrderDetailsPage";

function OrderPage() {
  // hooks
  const { requestId } = useParams<{ requestId: string }>();

  // queries
  const {
    data: requestData,
    error: requestError,
    refetch: requestRefetch,
    loading: requestLoading,
  } = useQuery(LOAD_REQUEST_QUERY, {
    variables: {
      id: requestId,
    },
  });

  const request: Request = requestData?.node;

  if (requestLoading) {
    return (
      <Box
        width="100%"
        height="90vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress size={40} thickness={2} />
      </Box>
    );
  }

  if (requestError) {
    return (
      <Box
        width="100%"
        height="90vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <GQLQueryStatusIndicator
          name="Order"
          data={requestData}
          error={requestError}
          refetch={requestRefetch}
        />
      </Box>
    );
  }

  return (
    <Box width="100%" display="flex" justifyContent="center">
      <Box
        width="100%"
        mx={2}
        display="flex"
        flexDirection="column"
        maxWidth="1140px"
      >
        <OrderDetailsPage request={request} />
      </Box>
    </Box>
  );
}

export default OrderPage;
