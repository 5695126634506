import React from "react";
import isNull from "lodash/isNull";
import { useFormContext } from "react-hook-form";

import { Autocomplete } from "@mui/material";

import { RHFAutocomplete } from "./RHFAutocomplete";

export const ConditionalAutocomplete = (props) => {
  const methods = useFormContext();
  const hasValidFormContext = !isNull(methods);

  if (hasValidFormContext) {
    return <RHFAutocomplete {...props} />;
  } else {
    return <Autocomplete {...props} />;
  }
};
