import React from "react";

import { useScreenSize } from "globals/hooks";
import { Vehicle } from "types";
import WhiteLabelVehicleListItemDesktop from "./WhiteLabelVehicleListItem.desktop";
import WhiteLabelVehicleListItemMobile from "./WhiteLabelVehicleListItem.mobile";

export type WhiteLabelVehicleListItemProps = {
  vehicle: Vehicle;
  buttonText: string;
  onVehicleSelect: () => void;
};

function WhiteLabelVehicleListItem(props: WhiteLabelVehicleListItemProps) {
  const { isMobileView } = useScreenSize();

  return isMobileView ? (
    <WhiteLabelVehicleListItemMobile {...props} />
  ) : (
    <WhiteLabelVehicleListItemDesktop {...props} />
  );
}

export default WhiteLabelVehicleListItem;
