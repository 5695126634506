import React from "react";

import { Box, Card } from "@mui/material";

import { RoundTripVariant } from "types";
import PassengerCountBlock from "./PassengerCountBlock";
import CreateRequestStopsBlock from "./StopsBlock/CreateRequestStopsBlock";

type TripInfoBlockProps = {
  roundTripVariant: RoundTripVariant;
};

function TripInfoBlock(props: TripInfoBlockProps) {
  const { roundTripVariant } = props;

  const passengerCountName =
    roundTripVariant === RoundTripVariant.Return
      ? "returnTrip.totalGroupSize"
      : "trip.totalGroupSize";

  if (roundTripVariant) {
    return (
      <Card variant="outlined" sx={{ p: 3 }}>
        <CreateRequestStopsBlock roundTripVariant={roundTripVariant} />
        <Box mt={3}>
          <PassengerCountBlock name={passengerCountName} />
        </Box>
      </Card>
    );
  }

  return (
    <>
      <CreateRequestStopsBlock roundTripVariant={roundTripVariant} />
      <PassengerCountBlock name={passengerCountName} />
    </>
  );
}

export default TripInfoBlock;
