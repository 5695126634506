import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import moment from "moment-timezone";

import { Box, Divider, Typography } from "@mui/material";

import { Invoice, InvoiceStatus } from "../../types";
import { LOAD_OPERATOR_QUERY } from "../../globals/graphql";
import {
  alabaster,
  grayDark,
  greenDark,
  successGreen,
} from "../../design-system/colors";
import { useScreenSize } from "../../globals/hooks";
import { CheckIcon } from "../../design-system/icons";
import DownloadButton from "../buttons/DownloadButton";
import { formatPhoneNumber } from "utils/phoneNumberFormatter/phoneNumberFormatter";

type InvoiceHeaderProps = {
  invoice: Invoice;
};

function InvoiceHeader(props: InvoiceHeaderProps) {
  const { invoice } = props;

  // hooks
  const { operatorSlug } = useParams<{ operatorSlug: string }>();
  const { isMobileView } = useScreenSize();

  // queries
  const { data: operatorData } = useQuery(LOAD_OPERATOR_QUERY, {
    variables: {
      operatorSlug: operatorSlug,
    },
    skip: !operatorSlug,
    fetchPolicy: "cache-and-network",
  });

  const operator = operatorData?.operator;
  const invoiceNumber = invoice.invoiceNumber;
  const isPaid = invoice.status === InvoiceStatus.Paid;
  const showMobilePaidCheckmark = isPaid && isMobileView;
  const formattedTwilioNumber = formatPhoneNumber(
    operator.twilioPhoneNumber?.phoneNumber
  )?.formatted;
  const operatorPhoneNumber = formatPhoneNumber(
    operator.voicePhoneNumber
  )?.formatted;

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="center"
      flexWrap="wrap"
      maxWidth="1140px"
      bgcolor={alabaster}
      py={4}
      mx="auto"
      {...(isPaid && { borderBottom: `8px solid ${successGreen}` })}
    >
      <Box width={840} px={2}>
        {/* Invoice Title */}
        <Box mb={isMobileView ? 2 : 3}>
          <Box
            mb={isMobileView ? 2 : 3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h3" style={{ color: grayDark }}>
              Invoice
            </Typography>
            <Box display="flex" mt={-1}>
              {showMobilePaidCheckmark && (
                <Box display="flex" alignItems="center">
                  <CheckIcon color={greenDark} />
                  <Box ml={1}>
                    <Typography variant="h5" color={greenDark}>
                      Paid
                    </Typography>
                  </Box>
                </Box>
              )}
              {
                <Box mt={1}>
                  <DownloadButton
                    endpoint={"/endpoint/invoice/customerPdfDownloadHandler"}
                    buttonText={"Download as PDF"}
                    params={{
                      id: invoice.id,
                    }}
                    analyticsName="invoice_customerPdf"
                    variant="desktop"
                  />
                </Box>
              }
            </Box>
          </Box>
          <Divider />
        </Box>

        <Box display="flex" flexWrap="wrap" justifyContent="space-between">
          {/* Operator and Billing Info */}
          <Box mb={2} mr={5}>
            <Box mb={1}>
              <Typography variant="h2">{operator?.name}</Typography>
            </Box>
            <Box mb={isMobileView ? 0.5 : 1}>
              <Typography variant="body2">
                {operatorPhoneNumber || formattedTwilioNumber}
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography variant="body2">{operator?.generalEmail}</Typography>
            </Box>
            <Box mb={0.5}>
              <Typography variant="overline" style={{ color: grayDark }}>
                Bill to
              </Typography>
            </Box>
            <Box mb={0.5}>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                {invoice.farmAffiliate?.operatorName ||
                  invoice.company?.name ||
                  `${invoice.contact?.firstName} ${invoice.contact?.lastName}`}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2">
                {invoice.farmAffiliate?.operatorEmail ||
                  invoice.company?.email ||
                  invoice.contact?.email}
              </Typography>
            </Box>
          </Box>

          {/* Invoice No, created at, and updated at */}
          <Box width="220px">
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection={isMobileView ? "column" : "row"}
              mb={1}
            >
              <Typography variant="body2" style={{ color: grayDark }}>
                Invoice No
              </Typography>
              <Typography variant="body2">{invoiceNumber}</Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection={isMobileView ? "column" : "row"}
              mb={1}
            >
              <Typography variant="body2" style={{ color: grayDark }}>
                Created
              </Typography>
              <Typography variant="body2">
                {moment(invoice?.createdAt).format("MMM DD, YYYY")}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection={isMobileView ? "column" : "row"}
              mb={1}
            >
              <Typography variant="body2" style={{ color: grayDark }}>
                Updated
              </Typography>
              <Typography variant="body2">
                {moment(invoice?.updatedAt).format("MMM DD, YYYY")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default InvoiceHeader;
