import React, {
  useState,
  useEffect,
  Ref,
  ChangeEvent,
  KeyboardEvent,
} from "react";
import { useDebounce } from "use-debounce";
import { useLazyQuery } from "@apollo/client";

import { TextField, Divider, Typography, Box } from "@mui/material";

import { LOAD_AIRPORTS_QUERY } from "../../globals/graphql";
import { Airport } from "../../types";
import { ConditionalAutocomplete } from "../react-hook-form/autocomplete/ConditionalAutocomplete";
import { RHFTransform } from "../react-hook-form/autocomplete/RHFAutocomplete";

type AirportAutoCompleteProps = {
  id?: string;
  label?: string;
  error?: boolean;
  helperText?: string;
  value?: any;
  name?: string;
  placeholder?: string;
  inputRef?: Ref<any>;
  className?: any;
  disabled?: boolean;
  fullWidth?: boolean;
  required?: boolean;
  inputClasses?: any;
  transform?: RHFTransform;
  onChange?: (event: any, value: any) => void;
  onBlur?: () => void;
  onKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void;
  onAdditionalAction?: (value: any, nameOfUpdatedField?: string) => void;
};

function AirportAutoComplete(props: AirportAutoCompleteProps) {
  const {
    id,
    label,
    onChange,
    error,
    value,
    inputRef,
    disabled,
    helperText,
    name,
    placeholder,
    inputClasses,
    required,
    fullWidth,
    onBlur,
    onKeyDown,
    transform,
    onAdditionalAction,
  } = props;

  // state
  const [inputValue, setInputValue] = useState("");
  const [queryLoading, setQueryLoading] = useState(false);
  const [debouncedInput] = useDebounce(inputValue, 200);

  const [options, setOptions] = useState<Airport[]>([]);

  // queries
  const [getAirports] = useLazyQuery(LOAD_AIRPORTS_QUERY, {
    onCompleted(data) {
      const airports = data.airports.edges.map((edge) => edge.node);

      setQueryLoading(false);
      setOptions(airports);
    },
    onError(error) {
      setQueryLoading(false);
      console.log(error);
    },
    fetchPolicy: "network-only",
  });

  // event handlers
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleUpdateField = (event: object, value: any) => {
    if (onChange && value) {
      onChange(value, event);
    }
  };

  useEffect(() => {
    if (debouncedInput === "") {
      setOptions([]);
      return undefined;
    }

    if (debouncedInput.length) {
      getAirports({
        variables: {
          globalSearch: debouncedInput,
        },
      });
    }
  }, [debouncedInput, getAirports]);

  // display loading as soon as user makes an input change
  useEffect(() => {
    setQueryLoading(!!inputValue);
  }, [inputValue]);

  return (
    <ConditionalAutocomplete
      name={name}
      fullWidth
      autoHighlight
      disabled={disabled}
      onChange={handleUpdateField}
      id={id}
      getOptionLabel={(option: Airport) =>
        typeof option === "string"
          ? option
          : `${option.iataCode} - ${option.airportName}`
      }
      isOptionEqualToValue={(option: Airport, value: Airport) =>
        option.icaoCode === value.icaoCode
      }
      filterOptions={(x) => x}
      options={options}
      loading={queryLoading}
      loadingText="Loading..."
      noOptionsText={inputValue ? "No Results Found" : "Enter Airport Name"}
      autoComplete={false}
      includeInputInList
      placeholder={placeholder}
      value={value || ""}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      transform={transform}
      onAdditionalAction={onAdditionalAction}
      renderInput={(params) => {
        const { InputProps, ...restParams } = params;
        return (
          <TextField
            inputRef={inputRef}
            error={error}
            helperText={helperText}
            {...restParams}
            InputProps={{
              ...InputProps,
              classes: inputClasses,
              autoComplete: "new-password",
            }}
            label={label}
            variant="outlined"
            name={name}
            fullWidth={fullWidth}
            required={required}
            onChange={handleChange}
          />
        );
      }}
      renderOption={(props, { airportName, iataCode }) => (
        <li {...props}>
          <Box>
            <Typography variant="body2">{airportName}</Typography>
            <Typography style={{ fontSize: "12px" }}>{iataCode}</Typography>
          </Box>
          <Divider />
        </li>
      )}
    />
  );
}

export default AirportAutoComplete;
