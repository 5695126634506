import React, { useMemo } from "react";
import size from "lodash/size";
import some from "lodash/some";
import filter from "lodash/filter";

import { Box, CircularProgress, Typography } from "@mui/material";

import { RequestStage, RequestTimeFrameEnum } from "types";
import EmptyList from "components/EmptyList";
import GQLQueryStatusIndicator from "components/GQLQueryStatusIndicator";
import { useAnalytics, useOperatorSlug, useScreenSize } from "globals/hooks";
import { useHistory } from "react-router-dom";
import { useRequests } from "../hooks/useRequests";
import emptyOrders from "../../../illustrations/emptyOrders.png";
import OrdersList from "./OrdersList";
import ReservationDropdownFilter, {
  ReservationFilterStatusVariant,
} from "./ReservationDropdownFilter";
import { getReservationFilterStatus } from "./util/getReservationFilterStatus";
import NoReservationMessage from "./NoReservationMessage";

type ReservationsTabProps = {
  setFilterStatuses: React.Dispatch<React.SetStateAction<string[]>>;
  filterStatuses: ReservationFilterStatusVariant[];
};
function ReservationsTab(props: ReservationsTabProps) {
  const { setFilterStatuses, filterStatuses } = props;

  // hooks
  const history = useHistory();
  const { track } = useAnalytics();
  const operatorSlug = useOperatorSlug();
  const [
    upcomingReservations,
    upcomingReservationsError,
    upcomingReservationsLoading,
  ] = useRequests({
    stage: RequestStage.Reservation,
    timeFrame: RequestTimeFrameEnum.Upcoming,
  });
  const [pastReservations, pastReservationsError, pastReservationsLoading] =
    useRequests({
      stage: RequestStage.Reservation,
      timeFrame: RequestTimeFrameEnum.Past,
    });
  const { isMobileView } = useScreenSize();

  // derived state
  const error = upcomingReservationsError || pastReservationsError;
  const isLoading = upcomingReservationsLoading || pastReservationsLoading;
  const isEmpty = !size(upcomingReservations) && !size(pastReservations);

  // reservations by filter statuses
  const filteredUpcomingReservations = useMemo(() => {
    return filter(upcomingReservations, (request) =>
      some(
        filterStatuses,
        (status) => status === getReservationFilterStatus(request)
      )
    );
  }, [upcomingReservations, filterStatuses]);

  const filteredPastReservations = useMemo(() => {
    return filter(pastReservations, (request) =>
      some(
        filterStatuses,
        (status) => status === getReservationFilterStatus(request)
      )
    );
  }, [pastReservations, filterStatuses]);

  if (error) {
    return <GQLQueryStatusIndicator error={error} name="Orders Data" />;
  }

  if (isLoading) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        flex="1"
        px={3}
        mt={2}
        mb={1}
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isEmpty) {
    return (
      <EmptyList
        title={`You have no reservations.`}
        ctaCopy="Create an Order"
        imageUrl={emptyOrders as string}
        onCreateClick={() => {
          track("orders_createOrder");
          history.push(`/${operatorSlug}/request/new`);
        }}
      />
    );
  }

  return (
    <Box flex="1" mt={2} mb={8} display="flex" flexDirection="column">
      {isMobileView ? (
        <ReservationDropdownFilter
          setFilterStatuses={setFilterStatuses}
          filterStatuses={filterStatuses}
        />
      ) : null}
      <Box my={2}>
        <Typography variant="h3" mb={2}>
          Upcoming
        </Typography>
        {size(filteredUpcomingReservations) ? (
          <OrdersList requests={filteredUpcomingReservations} />
        ) : (
          <NoReservationMessage message="No Upcoming Reservations" />
        )}
      </Box>
      <Box my={2}>
        <Typography variant="h3" mb={2}>
          Past
        </Typography>
        {size(filteredPastReservations) ? (
          <OrdersList requests={filteredPastReservations} />
        ) : (
          <NoReservationMessage message="No Past Reservations" />
        )}
      </Box>
    </Box>
  );
}

export default ReservationsTab;
