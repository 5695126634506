import React from "react";
import { TableCell } from "@mui/material";

type TripNumberCellProps = {
  tripNumber: string;
};

function TripNumberCell(props: TripNumberCellProps) {
  return (
    <TableCell>
      <TableCell style={{ fontWeight: 600 }}>{props.tripNumber}</TableCell>
    </TableCell>
  );
}

export default TripNumberCell;
