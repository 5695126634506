import React from "react";

type DVDPlayerIconProps = {
  color?: string;
  size: "small";
};

const DVDPlayerIcon = (props: DVDPlayerIconProps) => {
  const { color } = props;

  // size === small
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0301 8.44449C13.2313 7.87338 13.3339 7.27222 13.3335 6.66671C13.3335 8.25963 12.6352 9.68945 11.5279 10.6667M2.96847 8.43912C2.6692 7.58636 2.59134 6.67158 2.74218 5.78051C2.89301 4.88945 3.26761 4.05126 3.83087 3.34451C4.39413 2.63776 5.1276 2.08558 5.96255 1.73973C6.7975 1.39387 7.46669 1.34297 8.18052 1.34301M3.85201 12.6667H12.1483M8.00016 1.33337C5.05464 1.33337 2.66683 3.72119 2.66683 6.66671C2.66683 8.25963 3.36517 9.68945 4.4724 10.6667M10.0001 6.66674C10.0001 7.77131 9.10471 8.66674 8.00015 8.66674C6.89558 8.66674 6.00014 7.77131 6.00014 6.66674C6.00014 5.56217 6.89558 4.66674 8.00015 4.66674C9.10471 4.66674 10.0001 5.56217 10.0001 6.66674ZM11.8837 4.62645C11.8258 4.65549 11.7613 4.66922 11.6966 4.66633C11.6318 4.66345 11.5689 4.64404 11.5137 4.60997C11.4586 4.5759 11.4131 4.52828 11.3815 4.47166C11.35 4.41503 11.3335 4.35128 11.3335 4.28646V1.71362C11.3335 1.6488 11.35 1.58505 11.3815 1.52842C11.4131 1.4718 11.4586 1.42418 11.5137 1.39011C11.5689 1.35604 11.6318 1.33663 11.6966 1.33375C11.7613 1.33087 11.8258 1.34459 11.8837 1.37363L14.4566 2.65983C14.5198 2.69143 14.5729 2.74 14.61 2.80009C14.6472 2.86018 14.6668 2.92941 14.6668 3.00004C14.6668 3.07067 14.6472 3.13991 14.61 3.19999C14.5729 3.26008 14.5198 3.30865 14.4566 3.34026L11.8837 4.62645ZM2.22239 10.6667H13.7779C14.2689 10.6667 14.6668 11.0249 14.6668 11.4667V13.8667C14.6668 14.3085 14.2689 14.6667 13.7779 14.6667H2.22239C1.73147 14.6667 1.3335 14.3085 1.3335 13.8667V11.4667C1.3335 11.0249 1.73147 10.6667 2.22239 10.6667Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DVDPlayerIcon;
