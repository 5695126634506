/**
 * @file useScreenSize.tsx
 * Hook that returns size of screen
 */
import { useMediaQuery, useTheme } from "@mui/material";

function useScreenSize() {
  const theme = useTheme();

  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const isTabletView = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  return {
    isMobileView,
    isTabletView,
  };
}

export { useScreenSize };
