import { gql } from "@apollo/client";

export const LOAD_CANCELLATION_POLICIES_QUERY = gql`
  query CancellationPolicies($operatorSlug: String!) {
    cancellationPolicies(operatorSlug: $operatorSlug) {
      id
      createdAt
      updatedAt
      variant
      refund100 {
        periodName
        periodValue
        active
      }
      refund50 {
        periodName
        periodValue
        active
      }
      refund25 {
        periodName
        periodValue
        active
      }
      vehicles {
        id
        name
      }
    }
  }
`;
