import { ApolloError, useQuery } from "@apollo/client";
import map from "lodash/map";

import { LOAD_TRIPS_AS_PASSENGER_QUERY } from "globals/graphql";

import {
  LoadTripsAsPassengerConnection,
  RequestTimeFrameEnum,
  Trip,
} from "types";

type UseTripsAsPassengerTuple = [Trip[], ApolloError, boolean];

type Options = {
  timeFrame: RequestTimeFrameEnum;
  skip?: boolean;
};

function useLoadTripsAsPassenger(options: Options): UseTripsAsPassengerTuple {
  const { timeFrame, skip } = options;

  // queries
  const { data, error, loading } = useQuery<{
    loadTripsAsPassenger: LoadTripsAsPassengerConnection;
  }>(LOAD_TRIPS_AS_PASSENGER_QUERY, {
    skip,
    variables: {
      timeFrame,
    },
    fetchPolicy: "cache-and-network",
  });

  const trips = skip
    ? []
    : map(data?.loadTripsAsPassenger?.edges, ({ node }) => node);

  return [trips, error, loading];
}

export { useLoadTripsAsPassenger };
