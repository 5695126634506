import React from "react";

import { Box, Button, Typography, CircularProgress } from "@mui/material";

import { grayLight, white } from "design-system/colors";
import { VehiclePricingText } from "./components";
import { BaseRateAutomation } from "types";
import { ArrowRightIcon } from "design-system/icons";

type VehiclePricingSelectProps = {
  isLoadingBRA: boolean;
  onVehicleSelect: (e: any) => void;
  baseRateAutomation?: BaseRateAutomation;
  returnBaseRateAutomation?: BaseRateAutomation;
};

function VehiclePricingSelect(props: VehiclePricingSelectProps) {
  const {
    isLoadingBRA,
    onVehicleSelect,
    baseRateAutomation,
    returnBaseRateAutomation,
  } = props;

  return (
    <Box
      bgcolor={grayLight}
      borderRadius="4px"
      p={2}
      minWidth={PRICING_SELECT_MIN_WIDTH}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
        mb={1}
        height={PRICING_SELECT_TEXT_HEIGHT}
      >
        {isLoadingBRA ? (
          <Box display="flex">
            <CircularProgress size={20} />
          </Box>
        ) : baseRateAutomation?.total !== undefined ? (
          <VehiclePricingText
            baseRateAutomation={baseRateAutomation}
            returnBaseRateAutomation={returnBaseRateAutomation}
          />
        ) : (
          <Box display="flex" alignItems="center">
            <Typography variant="h4">Request for Pricing</Typography>
          </Box>
        )}
      </Box>

      <Button
        fullWidth
        variant="contained"
        onClick={onVehicleSelect}
        disabled={isLoadingBRA}
      >
        Choose Vehicle
        <Box ml={1} />
        <ArrowRightIcon size="small" color={white} />
      </Button>
    </Box>
  );
}

export default VehiclePricingSelect;

// constants
export const PRICING_SELECT_MIN_WIDTH = "240px"; // fixed width to prevent adjusting size when vehicle text changes
const PRICING_SELECT_TEXT_HEIGHT = "24px"; // fixed height to prevent height changes between loading indicator and text
