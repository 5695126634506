import React from "react";
import { Control, Controller, useFormContext } from "react-hook-form";

import {
  FormControl,
  FormHelperText,
  Box,
  Checkbox,
  useTheme,
} from "@mui/material";

type RHFCheckboxProps = {
  name: string;
  disabled?: boolean;
  label?: React.ReactNode;
  control?: Control<any>;
};

function RHFCheckbox(props: RHFCheckboxProps) {
  const { name, disabled, label } = props;

  // hooks
  const context = useFormContext();
  const theme = useTheme();

  // derived state
  const control = props.control || context.control;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        return (
          <FormControl error={!!error?.message}>
            <Box display="flex" alignItems="center">
              <Box mr={1.5}>
                <Checkbox
                  {...field}
                  color="primary"
                  disabled={disabled}
                  sx={{
                    color: theme.palette.primary.main,
                    width: "2rem",
                    height: "2rem",
                    transform: "scale(1.33)", // icon default is 1.5rem, scale it to 2rem
                  }}
                />
              </Box>
              <Box>{label}</Box>
            </Box>
            <FormHelperText>{error?.message}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
}

export default RHFCheckbox;
