import React from "react";
import { useQuery } from "@apollo/client";
import first from "lodash/first";

import { Box } from "@mui/material";

import { LOAD_VEHICLES_QUERY } from "../../../globals/graphql";
import VehicleCard from "../TripDetailsBlock/components/VehicleCard";
import { LoadVehiclesEdge } from "../../../types";
import { addTripReducerActionTypesEnum } from "../hooks/useAddTripToQuoteReducer";

type AddVehicleToTripPanelProps = {
  selectedVehicleId: string;
  onVehicleSelected: (type: string, value?: any) => void;
};

const { VEHICLE_ID } = addTripReducerActionTypesEnum;

function AddVehicleToTripPanel(props: AddVehicleToTripPanelProps) {
  const { selectedVehicleId, onVehicleSelected } = props;

  // queries
  const { data: vehicleData } = useQuery(LOAD_VEHICLES_QUERY, {
    onCompleted(data) {
      // select the 1st vehicle by default
      const vehicleEdge: LoadVehiclesEdge = first(data?.loadVehicles?.edges);
      const vehicleId = vehicleEdge.node.id;
      if (!selectedVehicleId) {
        onVehicleSelected(VEHICLE_ID, vehicleId);
      }
    },
  });
  return (
    <>
      {vehicleData?.loadVehicles?.edges.map((vehicle) => (
        <Box
          key={vehicle.node.id}
          onClick={() => onVehicleSelected(VEHICLE_ID, vehicle.node.id)}
          m={1}
        >
          <VehicleCard
            vehicle={vehicle.node}
            isNameAndCapacityOnly
            isSelectedVehicle={selectedVehicleId === vehicle.node.id}
          />
        </Box>
      ))}
    </>
  );
}

export default AddVehicleToTripPanel;
