import React from "react";

type PassengerUnlinkIconProps = {
  color?: string;
};

const PassengerUnlinkIcon = (props: PassengerUnlinkIconProps) => {
  const { color } = props;

  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8892 6.64552L11.7731 5.76164C12.2854 5.29091 12.5914 4.6371 12.6246 3.94214C12.6578 3.24719 12.4155 2.5672 11.9503 2.0498C11.4331 1.58428 10.753 1.34172 10.0578 1.37482C9.36272 1.40792 8.70875 1.71401 8.23804 2.22657L7.35415 3.11046M6.64656 10.8881L5.76267 11.772C5.29195 12.2844 4.63814 12.5903 3.94318 12.6235C3.24823 12.6567 2.56823 12.4144 2.05083 11.9493C1.58548 11.4319 1.34303 10.7518 1.37613 10.0568C1.40923 9.36171 1.7152 8.70776 2.22761 8.23696L3.11149 7.35308M3.81814 3.81708L1.69682 1.69576M2.40392 5.23129H0.989711M5.23235 2.40287V0.988654M10.1821 10.181L12.3034 12.3024M8.76788 11.5953V13.0095M11.5963 8.76683H13.0105"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PassengerUnlinkIcon;
