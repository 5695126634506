import React, { useState } from "react";

import { Box, Button } from "@mui/material";

import { useCurrentUser } from "../../../../globals/hooks";
import AddCreditCardDialog from "../../../globals/AddCreditCardDialog";
import { PaymentMethodCardSelect } from "components/confirm/form/components/PaymentMethodBlock/components";
import PayButton from "./PayButton";

type AuthenticatedChargeCustomerBlockProps = {
  totalDue: string;
  selectedPaymentMethodId: string;
  onSetPaymentMethod: (handleAuthenticatedPay) => void;
  isLoading: boolean;
  onPayClick: () => void;
  companyId?: string;
};

function AuthenticatedChargeCustomerBlock(
  props: AuthenticatedChargeCustomerBlockProps
) {
  const {
    totalDue,
    selectedPaymentMethodId,
    onSetPaymentMethod,
    isLoading,
    onPayClick,
    companyId,
  } = props;

  // hooks
  const currentUser = useCurrentUser();

  // state
  const [addCreditCardDialogOpen, setAddCreditCardDialogOpen] = useState(false);

  const paymentMethodsEnabled =
    currentUser?.paymentMethods?.length ||
    currentUser?.company?.paymentMethods.length;

  return (
    <Box display="flex" flexDirection="column">
      {!!paymentMethodsEnabled && (
        <Box mb={2}>
          <PaymentMethodCardSelect
            existingContactPaymentMethods={currentUser?.paymentMethods}
            onSetPaymentMethod={onSetPaymentMethod}
            selectedPaymentMethodId={selectedPaymentMethodId}
            {...(companyId && {
              existingCompanyPaymentMethods:
                currentUser?.company?.paymentMethods,
            })}
          />
        </Box>
      )}
      <Box mb={3} display="flex" justifyContent="space-between">
        <PayButton
          isDisabled={
            isLoading || addCreditCardDialogOpen || !paymentMethodsEnabled
          }
          isLoading={isLoading}
          onClick={onPayClick}
          totalDue={totalDue}
        />
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            setAddCreditCardDialogOpen(true);
          }}
        >
          Add a New Card
        </Button>
      </Box>
      <AddCreditCardDialog
        open={addCreditCardDialogOpen}
        setAddCreditCardDialogOpen={setAddCreditCardDialogOpen}
        onSetPaymentMethod={onSetPaymentMethod}
      />
    </Box>
  );
}

export default AuthenticatedChargeCustomerBlock;
