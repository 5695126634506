/**
 * @file PassengerContactBlock.tsx
 * Passesnger Contact section of customer public trip page
 *
 * components:
 *  PassengerContactBlock
 */

import React from "react";

import { Box, Card, Typography } from "@mui/material";

import { white } from "../../design-system/colors";
import CircleButton from "../../design-system/components/CircleButton";
import { MessageIcon, PhoneIcon } from "../../design-system/icons";
import { formatPhoneNumber } from "utils/phoneNumberFormatter/phoneNumberFormatter";

type PassengerContactBlockProps = {
  name: string;
  phone: string;
};

function PassengerContactBlock(props: PassengerContactBlockProps) {
  const { name, phone } = props;

  // derived state
  const phoneLinkFormat = formatPhoneNumber(phone)?.e164;

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h3">Passenger Contact</Typography>
      </Box>
      <Card
        sx={{
          flex: 1,
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        variant="outlined"
      >
        <Box ml="1px">
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            {name}
          </Typography>
          <Typography variant="body2">{phone || "-"}</Typography>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box mr={1.5}>
            <CircleButton
              disabled={!phone}
              component="a"
              href={`sms:${phoneLinkFormat}`}
            >
              <MessageIcon color={white} />
            </CircleButton>
          </Box>
          <CircleButton
            disabled={!phone}
            component="a"
            href={`tel:${phoneLinkFormat}`}
          >
            <PhoneIcon color={white} />
          </CircleButton>
        </Box>
      </Card>
    </Box>
  );
}

export default PassengerContactBlock;
