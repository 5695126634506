import React, { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import first from "lodash/first";
import isNumber from "lodash/isNumber";

import { Box, CircularProgress } from "@mui/material";

import GQLQueryStatusIndicator from "components/GQLQueryStatusIndicator";
import {
  CREATE_RESERVATION_PAYMENT_MUTATION,
  LOAD_REQUEST_QUERY,
} from "globals/graphql";
import ChargeCustomerBlock from "components/pay/ChargeCustomerBlock";
import { FarmRelationshipEnum, Request } from "types";
import { currency } from "utils/helpers";
import {
  useAnalytics,
  useOperator,
  useScreenSize,
  useSnackbar,
} from "globals/hooks";
import SubmitSuccessDialog from "components/globals/SubmitSuccessDialog";
import PaySummaryBlock from "components/pay/PaySummaryBlock";
import SimpleAuthTopBar from "components/SimpleAuthTopBar";
import PaymentSourceBlock from "components/pay/PaymentSourceBlock";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { PriceSummaryPaymentDriverGratuityBlock } from "./components";
import { white, grayLight, successGreen } from "design-system/colors";
import { useAdditionalDriverGratuityByRoute } from "./hooks";

const PRICE_SUMMARY_PAGE_MAX_WIDTH = 580;

function PriceSummaryPayPage() {
  // state
  const [isCreatingPayment, setIsCreatingPayment] = useState(false);
  const [driverGratuityPct, setDriverGratuityPct] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const { requestId } = useParams<{ requestId: string }>();

  // queries
  const {
    data: requestData,
    error: requestError,
    refetch: requestRefetch,
    loading: requestLoading,
  } = useQuery(LOAD_REQUEST_QUERY, {
    variables: {
      id: requestId,
    },
    skip: !requestId,
    fetchPolicy: "network-only",
  });

  const request: Request = requestData?.node;

  // mutations
  const [createReservationPayment] = useMutation(
    CREATE_RESERVATION_PAYMENT_MUTATION,
    {
      onCompleted() {
        setIsCreatingPayment(false);
        // If payment successful, show reservation paid pop-up
        history.push({
          pathname: `${history.location.pathname}`,
          search: "?successDialog=true",
        });
      },
      refetchQueries: [
        {
          query: LOAD_REQUEST_QUERY,
          variables: { id: requestId },
        },
      ],
      onError(error) {
        setIsCreatingPayment(false);
        const errorMessage =
          getErrorMessage(error) || "Error creating reservation payment";

        snackbar.error(errorMessage);
        requestRefetch();
      },
    }
  );

  // hooks
  const { isMobileView } = useScreenSize();
  const history = useHistory();
  const { operator } = useOperator();
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const {
    additionalDriverGratuityByRoute,
    displayGratuityBlock,
    additionalDriverGratuityAmt,
  } = useAdditionalDriverGratuityByRoute({
    request,
    userSelectedDriverGratuityPercent: driverGratuityPct,
  });

  // useMemo
  const totalBaseRateAmt = useMemo(() => {
    if (!request) return 0;
    const totalBaseRateAmt = request?.trips.reduce(
      (totalBaseRateAmt, trip) =>
        trip.routes[0].pricing.baseRateAmt + totalBaseRateAmt,
      0
    );

    return Number(totalBaseRateAmt.toFixed(2));
  }, [request]);

  if (requestLoading) {
    return (
      <Box
        width="100%"
        height="90vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress size={40} thickness={2} />
      </Box>
    );
  }

  if (requestError && !requestLoading) {
    return (
      <Box
        width="100%"
        height="90vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <GQLQueryStatusIndicator
          name="Request"
          data={requestData}
          error={requestError}
          refetch={requestRefetch}
        />
      </Box>
    );
  }

  const { orderNumber } = request;
  const bookingContactId = request?.bookingContact?.id;
  const isPaid = request.amountDue === 0;
  const requestAmountDue = currency(
    request.amountDue + additionalDriverGratuityAmt
  );
  const requestTotalAmount = currency(
    request.totalAmount + additionalDriverGratuityAmt
  );
  const requestAmountPaid = currency(request.totalAmount - request.amountDue);
  const operatorSlug = operator?.nameSlug;

  // farmee is viewing price summary
  const farmedRouteOfFarmee = first(
    request?.trips.find(
      (trip) =>
        first(trip.routes).isFarmedRoute &&
        first(trip.routes).farmRelationship === FarmRelationshipEnum.Farmee
    )?.routes
  );

  // redirect to price summary
  // we do not support paying for an individual route as a farmor
  if (farmedRouteOfFarmee) {
    history.replace(`/${operatorSlug}/price-summary/${requestId}`);
  }

  // event handlers
  const handlePriceSummary = () => {
    history.push(`/${operatorSlug}/price-summary/${requestId}`);
  };

  const handleCreatePayment = (paymentMethodId: string) => {
    // check if user has a payment method
    // if not, prompt user to add a new card
    if (!paymentMethodId) {
      snackbar.error(
        "Oops! Please add a credit card to continue creating payment."
      );
      return;
    }

    if (
      displayGratuityBlock &&
      isNumber(driverGratuityPct) &&
      driverGratuityPct < first(operator.driverGratuityPresets)
    ) {
      setErrorMessage(`At least ${first(operator.driverGratuityPresets)}%.`);
      return;
    }

    setErrorMessage(null);
    setIsCreatingPayment(true);

    track("reservation_paySubmitted", {
      ...(additionalDriverGratuityAmt && {
        gratuityAdded: additionalDriverGratuityAmt,
      }),
    });

    createReservationPayment({
      variables: {
        input: {
          paymentMethodId,
          requestId,
          ...(additionalDriverGratuityByRoute && {
            additionalDriverGratuityByRoute,
          }),
        },
      },
    });
  };

  return (
    <>
      <Box width="100%" pb={2} display="flex" justifyContent="center">
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          maxWidth={PRICE_SUMMARY_PAGE_MAX_WIDTH}
          px={isMobileView ? 2 : 0}
          mt={isMobileView ? 3 : 5}
        >
          <Box mb={6.5}>
            <SimpleAuthTopBar />
          </Box>
          <Box
            bgcolor={white}
            border={`1px solid ${grayLight}`}
            boxShadow="0px 4px 15px rgba(0, 0, 0, 0.03)"
            {...(isPaid && { borderBottom: `8px solid ${successGreen}` })}
          >
            <PaySummaryBlock
              header="Amount Due"
              headerAmount={requestAmountDue}
              subHeader1="Conf No."
              subContent1={orderNumber}
              subHeader2="Total Amount"
              subContent2={requestTotalAmount}
              subHeader3="Amount Paid"
              subContent3={requestAmountPaid}
              viewEntityLabel="View Price Summary"
              onViewEntityClick={handlePriceSummary}
              isPaid={isPaid}
            />
            {displayGratuityBlock && (
              <PriceSummaryPaymentDriverGratuityBlock
                totalBaseRateAmt={totalBaseRateAmt}
                driverGratuityPct={driverGratuityPct}
                setDriverGratuityPct={setDriverGratuityPct}
                setErrorMessage={setErrorMessage}
                errorMessage={errorMessage}
              />
            )}
          </Box>

          {isPaid ? (
            <Box mt={5}>
              <PaymentSourceBlock subPayments={request.combinedSubPayments} />
            </Box>
          ) : (
            <Box mt={5}>
              <ChargeCustomerBlock
                onCreatePayment={handleCreatePayment}
                isCreatingPayment={isCreatingPayment}
                totalDue={requestAmountDue}
                contactId={bookingContactId}
              />
            </Box>
          )}
        </Box>
      </Box>
      <SubmitSuccessDialog message="Reservation is Paid!" />
    </>
  );
}

export default PriceSummaryPayPage;
