import { Box, Button, CircularProgress } from "@mui/material";
import React from "react";

type PayButtonProps = {
  isDisabled: boolean;
  isLoading: boolean;
  onClick: () => void;
  totalDue: string;
};

function PayButton(props: PayButtonProps) {
  const { isDisabled, isLoading, onClick, totalDue } = props;

  return (
    <Box maxWidth={150}>
      <Box position="relative">
        <Button
          fullWidth
          variant="contained"
          color="primary"
          disabled={isDisabled}
          onClick={onClick}
        >
          Pay {totalDue}
        </Button>
        {isLoading && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              margin: "auto",
            }}
          />
        )}
      </Box>
    </Box>
  );
}

export default PayButton;
