import React from "react";

import { Box, Tabs, Tab, useTheme } from "@mui/material";

import { useScreenSize } from "globals/hooks";

type DesktopTripTabsProps = {
  tripTabs: {
    value: number;
    label: string;
  }[];
  onTabSelect: (
    e: React.SyntheticEvent<Element, Event>,
    tabIndex: number
  ) => void;
  activeTripIndex: number;
};

function DesktopTripTabs(props: DesktopTripTabsProps) {
  const { tripTabs, onTabSelect, activeTripIndex } = props;

  // hooks
  const { isTabletView } = useScreenSize();
  const theme = useTheme();

  return (
    <Box
      maxWidth={
        isTabletView
          ? "calc(min(100vw - 32px)"
          : "calc(min(100vw, 1140px) * .6 - 24px)"
      }
    >
      <Tabs
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: theme.palette.primary.main,
          },
        }}
        onChange={onTabSelect}
        value={activeTripIndex}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        {tripTabs.map(({ value, label }) => (
          <Tab
            key={value}
            value={value}
            label={label}
            sx={{
              "& .MuiTab-root": {
                minWidth: 120,
              },
              "& .MuiTab-indicator": {
                color: theme.palette.primary.main,
              },
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
}

export default DesktopTripTabs;
