import React from "react";

import { Box } from "@mui/material";

import {
  refund100Icon,
  refund50Icon,
  refund25Icon,
  noRefundIcon,
} from "../../../design-system/icons/CancellationPolicyIcons";
import {
  aqua,
  warningYellow,
  redDark,
  yellow,
} from "../../../design-system/colors";
import { Refund } from "../../../types";

function Line(props: {
  color: string;
  isLastElement?: boolean;
  isFirstElement?: boolean;
  height: string;
}) {
  return (
    <>
      <Box height="2px" />
      <Box bgcolor={props.color} width="2px" height={props.height} />
      <Box height="1px" />
    </>
  );
}

type PolicyConditionProps = {
  variant: "refund100" | "refund50" | "refund25" | "noRefund";
  refund: Refund;
  isFirstElement?: boolean;
  isLastElement?: boolean;
};

function PolicyCondition(props: PolicyConditionProps) {
  const { refund, variant, isFirstElement, isLastElement } = props;

  // pick icon color and label based on variant.
  const { icon, color, label } = {
    refund100: {
      icon: refund100Icon,
      color: aqua,
      label: "Full",
    },
    refund50: {
      icon: refund50Icon,
      color: yellow,
      label: "50%",
    },
    refund25: {
      icon: refund25Icon,
      color: warningYellow,
      label: "25%",
    },
    noRefund: {
      icon: noRefundIcon,
      color: redDark,
      label: "No",
    },
  }[variant];

  return (
    <Box display="flex" flexDirection="row">
      <Box display="flex" flexDirection="column" alignItems="center">
        {isFirstElement && <Line color={color} height="50%" />}
        <Box
          height="18px"
          width="18px"
          style={{ position: "relative" }}
          m={0.25}
        >
          {icon}
        </Box>
        <Line height={isLastElement ? "15%" : "100%"} color={color} />
      </Box>

      <Box display="flex" flexDirection="column" ml={2} mb={2} mt={0.5}>
        <Box fontWeight={700} display="inline">
          {label} refund
          <Box fontWeight={400} display="inline">{` if cancelled`}</Box>
          {`${isLastElement ? " less than " : " more than "} ${
            refund.periodValue
          } ${refund.periodName.toLowerCase()}s`}
          <Box
            fontWeight={400}
            display="inline"
          >{` prior to scheduled trip.`}</Box>
        </Box>
      </Box>
    </Box>
  );
}

export default PolicyCondition;
