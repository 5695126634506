import React from "react";
import truncate from "lodash/truncate";

import { Card, CardActionArea, Box, Typography } from "@mui/material";

import { grayLight, grayMedium } from "design-system/colors";
import CircleAvatar from "design-system/components/CircleAvatar";
import { useScreenSize } from "globals/hooks";
import { Contact } from "types";

type PassengerListItemProps = {
  passenger: Contact;
  onPassengerClick: (passenger: Contact) => void;
};

function PassengerListItem(props: PassengerListItemProps) {
  const { passenger, onPassengerClick } = props;

  const { firstName, lastName, email, mobilePhone } = passenger;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          marginBottom: 1.5,
          borderColor: grayLight,
          "&:hover": {
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
            borderColor: grayMedium,
          },
        }}
      >
        <CardActionArea
          onClick={() => onPassengerClick(passenger)}
          sx={{
            ".MuiCardActionArea-focusHighlight": { background: "transparent" },
          }}
        >
          <Box
            py={3}
            display="flex"
            flexDirection={isMobileView ? "column" : "row"}
            alignItems={isMobileView ? "start" : "center"}
          >
            {/* Name and Avatar */}
            <Box display="flex" flex="4">
              <Box ml={1.5} mr={1.3}>
                <CircleAvatar
                  placeholder={[firstName, lastName]}
                  size="small"
                />
              </Box>
              <Box alignSelf="center">
                <Typography variant={isMobileView ? "h4" : "subtitle1"}>
                  {truncate(`${firstName} ${lastName}`)}
                </Typography>
              </Box>
            </Box>

            {/* Email */}
            <Box
              flex="1.5"
              ml={isMobileView ? 2 : 0}
              mt={isMobileView ? 2 : 0}
              mb={isMobileView ? 1 : 0}
            >
              <Typography variant={isMobileView ? "body2" : "caption"}>
                {truncate(email)}
              </Typography>
            </Box>
            {/* Phone Number */}
            <Box flex="1" ml={isMobileView ? 2 : 0}>
              <Typography variant={isMobileView ? "body2" : "caption"}>
                {mobilePhone}
              </Typography>
            </Box>
          </Box>
        </CardActionArea>
      </Card>
    </>
  );
}

export default PassengerListItem;
