import React from "react";

import { Box, TableCell, Typography } from "@mui/material";

import { Vehicle } from "../../../../types";
import first from "lodash/first";

type VehicleCellProps = {
  vehicle: Vehicle;
};

function VehicleCell(props: VehicleCellProps) {
  const { vehicle } = props;

  const vehiclePic = first(vehicle.photos).url;

  return (
    <TableCell>
      <Box display="flex" flexDirection="row" flex="0">
        <img
          alt={vehicle.name}
          src={vehiclePic}
          style={{
            borderRadius: 1,
            width: "72px", // 4:3 aspect ratio
            height: "54px",
            objectFit: "cover",
          }}
        />
        <Box display="flex" flexDirection="column" ml={2}>
          <Typography variant="body2">{vehicle.name}</Typography>
          <Typography variant="overline">
            {vehicle.vehicleType?.typeName}
          </Typography>
        </Box>
      </Box>
    </TableCell>
  );
}

export default VehicleCell;
