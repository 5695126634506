import React, { useEffect, useMemo } from "react";
import filter from "lodash/filter";

import { Box } from "@mui/material";

import {
  UnavailableButton,
  VehicleDescriptionAndFeatures,
  VehicleImageCarousel,
  VehicleInfo,
  VehiclePricingSelect,
  VehicleUnavailable,
} from "./components";
import { Vehicle, VehicleChildSeat } from "types";
import { useAnalytics, useCurrentUser, useScreenSize } from "globals/hooks";
import PricelessSelect from "./components/PricelessSelect";
import { useIsPricelessBooking } from "pages/new/hooks/useIsPricelessBooking";

type VehicleCardProps = {
  isLoadingBRA: boolean;
  onVehicleSelect: (e: any) => void;
  vehicle: Vehicle;
};

function VehicleCard(props: VehicleCardProps) {
  const { isLoadingBRA, onVehicleSelect, vehicle } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();
  const currentUser = useCurrentUser();
  const displayPricelessBooking = useIsPricelessBooking({
    vehicle,
    authenticatedUser: currentUser,
  });

  // memo
  const activeChildSeats = useMemo(() => {
    const { settings } = vehicle;

    let childSeats: VehicleChildSeat[] = [];

    if (!settings) return childSeats;

    const { rearFacingSeat, boosterSeat, forwardFacingSeat } = settings;

    childSeats = filter(
      [rearFacingSeat, forwardFacingSeat, boosterSeat],
      "active"
    );

    return childSeats;
  }, [vehicle]);

  // effects
  useEffect(() => {
    if (vehicle.hasTripConflict) {
      track("vehicle_unavailable", {
        type: vehicle.baseRateAutomation ? "BRA" : "Quote Request",
        vehicleType: vehicle.vehicleType,
      });
    }
  }, [vehicle, track]);

  return (
    <Box
      my={2}
      mx={isMobileView ? 0.5 : 0}
      {...(isMobileView
        ? { display: "flex", flexDirection: "column" }
        : { display: "flex", justifyContent: "start" })}
    >
      {/* Image */}
      <Box>
        <VehicleImageCarousel name={vehicle.name} photos={vehicle.photos} />
      </Box>

      {/* Info, Pricing Select, Description, Features */}
      <Box
        display="flex"
        flexDirection="column"
        ml={isMobileView ? 0 : 5}
        width="100%"
      >
        {vehicle.hasTripConflict && <VehicleUnavailable />}

        {/* trip conflict */}
        <Box display="flex" justifyContent="space-between">
          {isMobileView ? (
            <VehicleInfo
              name={vehicle.name}
              vehicleType={vehicle.vehicleType}
              capacity={vehicle.capacity}
              hasTripConflict={vehicle.hasTripConflict}
            />
          ) : (
            <>
              <VehicleInfo
                name={vehicle.name}
                vehicleType={vehicle.vehicleType}
                capacity={vehicle.capacity}
                hasTripConflict={vehicle.hasTripConflict}
              />
              {vehicle.hasTripConflict ? (
                <UnavailableButton />
              ) : displayPricelessBooking ? (
                <PricelessSelect
                  onVehicleSelect={onVehicleSelect}
                  showTooltip={!currentUser}
                />
              ) : (
                <VehiclePricingSelect
                  onVehicleSelect={onVehicleSelect}
                  isLoadingBRA={isLoadingBRA}
                  baseRateAutomation={vehicle.baseRateAutomation}
                  returnBaseRateAutomation={vehicle.returnBaseRateAutomation}
                />
              )}
            </>
          )}
        </Box>
        <VehicleDescriptionAndFeatures
          description={vehicle.description}
          features={vehicle.features}
          hasTripConflict={vehicle.hasTripConflict}
          activeChildSeats={activeChildSeats}
        />
        {isMobileView && (
          <Box>
            {vehicle.hasTripConflict ? (
              <UnavailableButton />
            ) : displayPricelessBooking ? (
              <PricelessSelect
                onVehicleSelect={onVehicleSelect}
                userIsLoggedOut={!currentUser}
              />
            ) : (
              <VehiclePricingSelect
                onVehicleSelect={onVehicleSelect}
                isLoadingBRA={isLoadingBRA}
                baseRateAutomation={vehicle.baseRateAutomation}
                returnBaseRateAutomation={vehicle.returnBaseRateAutomation}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default VehicleCard;
