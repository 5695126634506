import { useQuery } from "@apollo/client";

import { LOAD_TERMS_QUERY } from "globals/graphql/term.graphql";
import { useOperatorSlug, useSnackbar } from "globals/hooks";

function useTermsAndConditions() {
  // hooks
  const operatorSlug = useOperatorSlug();
  const snackbar = useSnackbar();

  const { data: termsData, error } = useQuery(LOAD_TERMS_QUERY, {
    variables: {
      operatorSlug,
    },
  });

  if (error) {
    snackbar.error(error.message);
  }

  return termsData?.terms;
}

export { useTermsAndConditions };
