import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import { Box, FormHelperText, useTheme } from "@mui/material";

import { granite } from "../../design-system/colors";

type InternationalPhoneInputProps = {
  value: string;
  onChange: (value, country, e?, formattedValue?) => void;
  error?: string;
  label?: string;
  onBlur?: () => void;
};

function InternationalPhoneInput(props: InternationalPhoneInputProps) {
  const { value, error, onChange, label, onBlur } = props;
  const specialLabel = label || "Phone Number *";

  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      sx={{
        "& .react-tel-input": {
          ":focus-within": {
            "& .special-label": {
              color: theme.palette.primary.main,
            },
          },
          "& .special-label": {
            color: granite,
          },
          "& .form-control": {
            width: "100%",
            ":focus": {
              border: `2px solid ${theme.palette.primary.main} !important`,
              boxShadow: "none !important",
            },
          },
        },
      }}
    >
      <PhoneInput
        value={value || ""}
        specialLabel={specialLabel}
        isValid={() => (!!error ? specialLabel : true)}
        country="us"
        onChange={onChange}
        countryCodeEditable={false}
        onBlur={onBlur}
      />
      <FormHelperText error={!!error}>{error}</FormHelperText>
    </Box>
  );
}
export default InternationalPhoneInput;
