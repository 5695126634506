import React, { useState } from "react";

import { Box, Typography } from "@mui/material";

import { white } from "design-system/colors";

import {
  useCreateTripReview,
  useOperator,
  useAnalytics,
  useParamTripExists,
} from "globals/hooks";
import StarRatingText from "./components/StarRatingText";
import StarRating from "./components/StarRating";
import ReviewComment from "./components/ReviewComment";
import ThankYouForReview from "./components/ThankYouForReview";

function TripReviewPage() {
  // state
  const [stars, setStars] = useState(0);

  // hooks
  const { operator } = useOperator();
  const { tripExists, tripId } = useParamTripExists();
  const { createTripReview, createTripReviewLoading, tripReview } =
    useCreateTripReview();
  const { track } = useAnalytics();

  // event handlers
  const handleStarsChange = (_, newValue) => {
    setStars(newValue);

    if (newValue === 5) {
      track("tripReview_submitted", { rating: newValue });

      createTripReview({
        variables: {
          input: {
            rating: newValue,
            tripId,
          },
        },
      });

      // open link to review platform if exists and review is five stars
      if (newValue === 5 && operator?.settings?.reviewSiteId) {
        const { reviewSiteUrlPrefix, reviewSiteId, reviewSiteUrlSuffix } =
          operator.settings;

        const writeReviewPlatformLink =
          "https://www." +
          reviewSiteUrlPrefix +
          reviewSiteId +
          reviewSiteUrlSuffix;

        window.open(writeReviewPlatformLink);
      }
    }
  };

  // tracking on render
  if (tripExists) {
    track("tripReview_opened");
  }

  return (
    <Box display="flex" bgcolor={white} justifyContent="center" width="100%">
      <Box
        mx={2}
        my={5}
        display="flex"
        flexDirection="column"
        alignItems="center"
        maxWidth="1140px"
      >
        {tripExists ? (
          <>
            <Typography variant="h2" textAlign="center" mt="60px">
              How was your experience with {operator.name}?
            </Typography>
            <StarRatingText stars={stars} tripReview={tripReview} />
            <StarRating
              stars={stars}
              handleStarsChange={handleStarsChange}
              tripReview={tripReview}
            />
            <ReviewComment
              stars={stars}
              handleStarsChange={handleStarsChange}
              createTripReview={createTripReview}
              tripId={tripId}
              createTripReviewLoading={createTripReviewLoading}
              tripReview={tripReview}
            />
            <ThankYouForReview
              tripReview={tripReview}
              createTripReviewLoading={createTripReviewLoading}
              stars={stars}
            />
          </>
        ) : (
          <Box display="flex" alignItems="center" height="100%">
            <Typography>Error: This trip does not exist.</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default TripReviewPage;
