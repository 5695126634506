import { fromBase64 } from "b64-lite";

/**
 * Strips phone number of country code and formatting for server call.
 * This will most likely be re-written / removed entirely as we
 * move to handle international numbers.
 */
export const sanitizePhoneNumberForAuth = (phoneNumber: string | number) => {
  return phoneNumber
    .toString()
    .replace(/[+]/, "")
    .replace(/^1/, "")
    .replace(/\s/g, "")
    .replace(/[()]/g, "")
    .replace(/-/g, "");
};

export function fromGlobalId(globalId: string) {
  const unBasedGlobalId = fromBase64(globalId);
  const delimiterPos = unBasedGlobalId.indexOf(":");
  return {
    type: unBasedGlobalId.substring(0, delimiterPos),
    id: unBasedGlobalId.substring(delimiterPos + 1),
  };
}
