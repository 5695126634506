import { CreateRequestState } from "pages/new/context/initialState";
import { CreateQuoteFormState } from "./schemaValidation";

export const getCreateQuoteFormDefaultValues = (
  bookingContact: CreateRequestState["bookingContact"]
): CreateQuoteFormState => {
  return {
    bookingContact: {
      firstName: bookingContact.firstName || "",
      lastName: bookingContact.lastName || "",
      email: bookingContact.email || "",

      // store phoneFields as object instead of flattended into booking contact
      // so that they can be passed into RHF international phone number format component
      phoneFields: {
        mobilePhone: bookingContact.mobilePhone || "",
        phoneCountryCode: bookingContact.phoneCountryCode || "",
        phoneCountryDialCode: bookingContact.phoneCountryDialCode || "",
        phoneCountryFormat: bookingContact.phoneCountryFormat || "",
        phoneCountryName: bookingContact.phoneCountryName || "",
      },
    },
  };
};
