/**
 * @file AllTripsPage.tsx
 * List View for All Trips. Only used for Swoop Dispatch.
 */
import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import { Box, CircularProgress } from "@mui/material";

import { LOAD_REQUEST_QUERY } from "../../../globals/graphql";
import { Request } from "../../../types";
import GQLQueryStatusIndicator from "../../../components/GQLQueryStatusIndicator";
import AllTripsPageContent from "./components/AllTripsPageContent";
import { useScreenSize } from "../../../globals/hooks";

function AllTripsPage() {
  // hooks
  const { requestId } = useParams<{ requestId: string }>();
  const { isMobileView } = useScreenSize();

  // queries
  const {
    data: requestData,
    error: requestError,
    refetch: requestRefetch,
    loading: requestLoading,
  } = useQuery(LOAD_REQUEST_QUERY, {
    variables: {
      id: requestId,
    },
    pollInterval: 30 * 1000, // every 30 seconds
  });

  const request: Request = requestData?.node;

  if (requestLoading) {
    return (
      <Box
        width="100%"
        height="90vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress size={40} thickness={2} />
      </Box>
    );
  }

  if (requestError) {
    return (
      <Box
        width="100%"
        height="90vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <GQLQueryStatusIndicator
          name="Order"
          data={requestData}
          error={requestError}
          refetch={requestRefetch}
        />
      </Box>
    );
  }

  return (
    <Box width="100%" display="flex" justifyContent="center">
      <Box
        width="100%"
        px={isMobileView ? 0 : 4}
        display="flex"
        flexDirection="column"
      >
        <AllTripsPageContent request={request} />
      </Box>
    </Box>
  );
}

export default AllTripsPage;
