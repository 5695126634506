import React from "react";

type ReplacePassengerIconProps = {
  color?: string;
};

const ReplacePassengerIcon = (props: ReplacePassengerIconProps) => {
  const { color } = props;

  return (
    <svg
      width="24"
      height="21"
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8584 10.4858L20.3575 8.57766L22.5003 10.0812M20.4296 8.87767C20.8972 10.8523 20.674 12.9396 19.7974 14.7885C18.9208 16.6373 17.4444 18.1349 15.6165 19.0293C13.7886 19.9237 11.7207 20.1605 9.76069 19.6996C8.7612 19.4647 7.82085 19.0556 6.97827 18.4977M4.94462 11.1329L3.17763 13.1562L1.30273 11.5376M3.17763 12.2778C2.71302 10.304 2.93825 8.2186 3.8155 6.37184C4.69276 4.52509 6.16861 3.02941 7.99523 2.13597C9.82185 1.24252 11.888 1.00573 13.8467 1.46534C14.9313 1.71983 15.9464 2.17927 16.8426 2.81148M16.0003 15V12.7071C16.0003 12.2544 15.8204 11.8201 15.5003 11.5C15.1802 11.1799 14.7459 11 14.2932 11H9.7074C9.25465 11 8.82044 11.1799 8.50029 11.5C8.18015 11.8201 8.00029 12.2544 8.00029 12.7071V15M14.0003 7C14.0003 8.10457 13.1049 9 12.0003 9C10.8957 9 10.0003 8.10457 10.0003 7C10.0003 5.89543 10.8957 5 12.0003 5C13.1049 5 14.0003 5.89543 14.0003 7Z"
        stroke={color || "#195FE9"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReplacePassengerIcon;
