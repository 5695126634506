import React, { Fragment } from "react";

import { Typography, Box, Divider, CircularProgress } from "@mui/material";

import { TripCategory } from "types";
import { CreateTripState } from "pages/new/context/initialState";
import VehicleCard from "./components";
import useLoadVehicles from "./hooks/useLoadVehicles";
import { useScreenSize } from "globals/hooks";

type VehicleListProps = {
  trip: CreateTripState;
  tripCategory: TripCategory;
  vehicleId: string;
  onVehicleSelect: (vehicle: any) => () => void;
  returnTrip?: CreateTripState;
};

function VehicleList(props: VehicleListProps) {
  const { trip, returnTrip, onVehicleSelect, tripCategory } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const { vehicles, isLoadingVehicles, isLoadingBRA } = useLoadVehicles({
    trip,
    returnTrip,
    tripCategory,
  });

  return (
    <>
      <Box mt={5} mb={2} mx={isMobileView ? 0.5 : 0}>
        <Typography variant="h3">Choose Vehicle</Typography>
      </Box>
      {isLoadingVehicles && !vehicles && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box mb={2}>Loading vehicles...</Box>
            <Box mb={2}>
              <CircularProgress />
            </Box>
          </Box>
          <Divider />
        </>
      )}

      {vehicles.map((vehicle) => {
        return (
          <Fragment key={vehicle.id}>
            <VehicleCard
              isLoadingBRA={isLoadingBRA}
              onVehicleSelect={onVehicleSelect(vehicle)}
              vehicle={vehicle}
            />
            <Box mt={3}>
              <Divider />
            </Box>
          </Fragment>
        );
      })}
    </>
  );
}

export default VehicleList;
