import { Box, Typography } from "@mui/material";
import React from "react";

type PassengerCountProps = {
  totalGroupSize: number;
};

function PassengerCount(props: PassengerCountProps) {
  const { totalGroupSize } = props;

  return (
    <Box width="100%" mb={2}>
      <Typography variant="overline">Passenger Count</Typography>
      <Typography mt={0.5} variant="body2">
        {totalGroupSize || "-"}
      </Typography>
    </Box>
  );
}

export default PassengerCount;
