import React from "react";
import { useQuery } from "@apollo/client";

import { Box, useTheme } from "@mui/material";

import { useOperatorSlug } from "../globals/hooks/useOperatorSlug";
import { LOAD_OPERATOR_QUERY } from "../globals/graphql";
import LoginLogoutBlock from "./auth/LoginLogoutBlock/LoginLogoutBlock";

type SimpleAuthTopBarProps = {
  hideLogin?: boolean;
};

function SimpleAuthTopBar(props: SimpleAuthTopBarProps) {
  const { hideLogin } = props;
  // hooks
  const operatorSlug = useOperatorSlug();
  const theme = useTheme();

  // queries
  const { data: operatorData } = useQuery(LOAD_OPERATOR_QUERY, {
    variables: {
      operatorSlug,
    },
    skip: !operatorSlug,
  });

  const { companyLogoUrl, name } = operatorData?.operator || {};

  const appBarStyles = {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    width: "100%",
    maxWidth: 1140,
    zIndex: theme.zIndex.drawer + 1,
  } as const;

  const logoStyles = {
    cursor: "pointer",
    width: 205,
    height: 82,
    [theme.breakpoints.down("sm")]: {
      height: 42,
      width: 105,
    },
    backgroundImage: `url(${companyLogoUrl})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "transparent",
    color: "white",
    borderRadius: 1,
  } as const;

  return (
    <Box
      sx={appBarStyles}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box sx={logoStyles}>{companyLogoUrl ? "" : name}</Box>
      <Box>{!hideLogin && <LoginLogoutBlock />}</Box>
    </Box>
  );
}
export default SimpleAuthTopBar;
