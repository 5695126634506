import React from "react";

type SmokingIconProps = {
  color?: string;
  size: "small";
};

const SmokingIcon = (props: SmokingIconProps) => {
  const { color } = props;

  // size === small
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0002 9V13M1.3335 9V13M6.00016 1C6.00016 1.74261 5.70516 2.4548 5.18006 2.9799C4.65496 3.505 3.94277 3.8 3.20016 3.8C2.70509 3.8 2.2303 3.99667 1.88023 4.34673C1.53016 4.6968 1.3335 5.1716 1.3335 5.66667M8.66683 1C8.66683 2.41449 8.10493 3.77104 7.10473 4.77124C6.10454 5.77143 4.74798 6.33333 3.3335 6.33333M13.795 13H3.3335V9H13.795C14.0262 9 14.248 9.10536 14.4115 9.29289C14.575 9.48043 14.6668 9.73478 14.6668 10V12C14.6668 12.2652 14.575 12.5196 14.4115 12.7071C14.248 12.8946 14.0262 13 13.795 13Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SmokingIcon;
