import React from "react";
import moment from "moment";

import { Box, Button, Typography } from "@mui/material";

import MoovsDialog from "components/globals/MoovsDialog";
import { grayLight, white } from "design-system/colors";
import { useAnalytics, useScreenSize } from "globals/hooks";

type ConfirmTimeDialogProps = {
  openConfirmTimeDialog: boolean;
  setOpenConfirmTimeDialog: React.Dispatch<React.SetStateAction<boolean>>;
  dateTime: any;
  onAccept: (date: any) => void;
  setOpenDateTimePicker: React.Dispatch<React.SetStateAction<boolean>>;
};

function ConfirmTimeDialog(props: ConfirmTimeDialogProps) {
  const {
    openConfirmTimeDialog,
    setOpenConfirmTimeDialog,
    dateTime,
    onAccept,
    setOpenDateTimePicker,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();

  const dateTimeInAM = moment(dateTime);
  const dateTimeInPM = moment(dateTime).add(12, "hours");

  // event handler
  const handleConfirmAM = () => {
    onAccept(dateTimeInAM);
    setOpenConfirmTimeDialog(false);
    setOpenDateTimePicker(false);
    track("pickupTime_confirmed", { pickupTime: "AM" });
  };

  const handleConfirmPM = () => {
    onAccept(dateTimeInPM);
    setOpenConfirmTimeDialog(false);
    setOpenDateTimePicker(false);
    track("pickupTime_confirmed", { pickupTime: "PM" });
  };

  return (
    <MoovsDialog
      hideTopBorder
      open={openConfirmTimeDialog}
      onClose={() => {
        setOpenConfirmTimeDialog(false);
      }}
      dialogTitle="Confirm Trip Pick-up Time"
      size={isMobileView ? "sm" : "xs"}
      customFooter={
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          flexDirection={isMobileView ? "column" : "row"}
          px={isMobileView ? 2 : 4}
          py={isMobileView ? 1 : 2}
          borderTop={`1px solid ${grayLight}`}
          bgcolor={white}
          width="100%"
        >
          <Box
            ml={isMobileView ? 0 : 1}
            my={isMobileView ? 0.5 : 0}
            {...(isMobileView && { width: "100%" })}
          >
            <Button
              onClick={() => setOpenConfirmTimeDialog(false)}
              {...(isMobileView && { fullWidth: true })}
            >
              Cancel
            </Button>
          </Box>
          <Box
            ml={isMobileView ? 0 : 1}
            {...(isMobileView && { width: "100%" })}
          >
            <Button
              variant="outlined"
              onClick={handleConfirmPM}
              {...(isMobileView && { fullWidth: true })}
            >
              {`No, ${dateTimeInPM.format("h:mm A")}`}
            </Button>
          </Box>
          <Box
            ml={isMobileView ? 0 : 1}
            my={isMobileView ? 1 : 0}
            {...(isMobileView && { width: "100%" })}
          >
            <Button
              variant="contained"
              onClick={handleConfirmAM}
              {...(isMobileView && { fullWidth: true })}
            >
              {`Yes, ${dateTimeInAM.format("h:mm A")}`}
            </Button>
          </Box>
        </Box>
      }
    >
      <Box mb={2}>
        <Typography variant="body2">
          Please confirm your trip pick-up time is{" "}
          <Typography
            variant="subtitle2"
            lineHeight="25px"
            display={isMobileView ? "block" : "inline-block"}
          >
            {dateTimeInAM.format("h:mm A")}
          </Typography>
        </Typography>
      </Box>
    </MoovsDialog>
  );
}

export default ConfirmTimeDialog;
