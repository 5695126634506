import React from "react";
import includes from "lodash/includes";
import isNil from "lodash/isNil";

import { Box, Divider, Typography } from "@mui/material";

import { currency } from "utils/helpers";
import { black, grayDark, grayLight } from "design-system/colors";
import { UseOrderPricingType } from "globals/hooks/utils";
import MoovsTooltip from "components/globals/MoovsTooltip";

type VehiclePricingTooltipProps = {
  outboundTripPricing: UseOrderPricingType;
  returnTripPricing: UseOrderPricingType;
};

function VehiclePricingTooltip(props: VehiclePricingTooltipProps) {
  const { outboundTripPricing, returnTripPricing } = props;
  const isRoundtrip = !!returnTripPricing;

  return (
    <MoovsTooltip
      noMaxWidth
      tooltipContent={
        <Box width={336}>
          {isRoundtrip ? (
            <Box mb={1} display="flex" flexDirection="column">
              <Typography sx={{ mt: 1, mb: 3 }} color={black} variant="h4">
                Pricing
              </Typography>
              <Box mb={2}>
                <Typography
                  sx={{ mb: 0.5, fontWeight: 700 }}
                  color={black}
                  variant="subtitle2"
                >
                  Pick-up
                </Typography>
                <PricingBlock pricing={outboundTripPricing} />
              </Box>
              <Divider sx={{ height: "1px" }} color={grayLight} />
              <Box mt={2}>
                <Typography
                  sx={{ mb: 0.5, fontWeight: 700 }}
                  color={black}
                  variant="subtitle2"
                >
                  Return
                </Typography>
                <PricingBlock pricing={returnTripPricing} />
              </Box>
            </Box>
          ) : (
            <Box mb={1} display="flex" flexDirection="column">
              <Typography
                sx={{ mt: 1, mb: 3, fontWeight: 700 }}
                color={black}
                variant="h4"
              >
                Pricing
              </Typography>
              <PricingBlock pricing={outboundTripPricing} />
            </Box>
          )}
        </Box>
      }
    />
  );
}

type PricingBlockProps = {
  pricing: UseOrderPricingType;
};

const PricingBlock = (props: PricingBlockProps) => {
  const { pricing } = props;

  return (
    <>
      {Object.values(pricing).map(({ label, value }) => {
        if (isNil(value)) return null;

        // Currently an operator cannot apply a promo code to a vehicle by default
        // like they can with other pricing items (Operator Settings => General => Preferences => Pricing Layout),
        // but it's better to include promo codes here as well just in case that ever changes
        const showMinus = label === "Discount" || includes(label, "Promo Code");
        const currencyValue = currency(value);

        return (
          <Box
            key={label}
            py={0.25}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              color={grayDark}
              sx={{ marginRight: "24px" }}
              variant="body2"
            >
              {label}
            </Typography>
            <Typography color={black} variant="body2">
              {showMinus && "- "}
              {currencyValue}
            </Typography>
          </Box>
        );
      })}
    </>
  );
};

export default VehiclePricingTooltip;
