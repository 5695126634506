/**
 * @file ATIUpdateWrapper.tsx
 *
 * Wrapper around ATIDialog to handle submitting and feeding in initial data, specifically for updating a request
 */
import React from "react";
import { useMutation } from "@apollo/client";
import first from "lodash/first";
import { useParams } from "react-router-dom";

import { LOAD_REQUEST_QUERY, UPDATE_TRIP_MUTATION } from "globals/graphql";
import {
  useCurrentUser,
  useLaunchDarklyFlags,
  useSnackbar,
} from "globals/hooks";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { TemporaryPassenger } from "types";
import ATIDialog from "./ATIDialog";
import { AdditionalTripInfoState } from "../form/schemaValidation";
import { formatPhoneNumber } from "utils/phoneNumberFormatter/phoneNumberFormatter";

type ATIUpdateWrapperProps = {
  onClose: () => void;
  open: boolean;
  trip: {
    id: string;
    totalGroupSize: number;
    note?: string;
    temporaryPassenger?: TemporaryPassenger;
    routes: {
      id: string;
      carryOnLuggage?: number;
      checkedLuggage?: number;
      oversizeLuggage?: number;
    }[];
  };
};

function ATIUpdateWrapper(props: ATIUpdateWrapperProps) {
  const { onClose, open, trip } = props;

  // hooks
  const snackbar = useSnackbar();
  const { enableLinkedPassenger } = useLaunchDarklyFlags();
  const currentUser = useCurrentUser();
  const { requestId } = useParams<{ requestId: string }>();

  // derived state
  const route = first(trip.routes);

  // mutations
  const [updateTrip, { loading }] = useMutation(UPDATE_TRIP_MUTATION, {
    onError(error) {
      const errorMessage = getErrorMessage(error) || "Error updating trip.";
      snackbar.error(errorMessage);
    },
    onCompleted: onClose,
    refetchQueries: [
      {
        query: LOAD_REQUEST_QUERY,
        variables: { id: requestId },
      },
    ],
  });

  // event handlers
  const handleSubmit = (additionalTripInfoState: AdditionalTripInfoState) => {
    const {
      note,
      totalGroupSize,
      temporaryPassenger,
      route,
      selectedPassenger,
    } = additionalTripInfoState;

    const { formatted, international } =
      formatPhoneNumber(
        temporaryPassenger.phoneFields.mobilePhone,
        temporaryPassenger.phoneFields.phoneCountryCode,
        temporaryPassenger.phoneFields.phoneCountryDialCode
      ) || {};

    updateTrip({
      variables: {
        input: {
          note,
          totalGroupSize,
          id: trip.id,
          temporaryPassenger: {
            // merge phone fields back into temporaryPassenger to match graphql schema for TemporaryPassenger
            ...temporaryPassenger.phoneFields,
            name: temporaryPassenger.name,
            mobilePhone: formatted,
            mobilePhoneInternational: international,
          },
          route: {
            id: route.id,
            carryOnLuggage: route.carryOnLuggage,
            checkedLuggage: route.checkedLuggage,
            oversizeLuggage: route.oversizeLuggage,
          },
          ...(enableLinkedPassenger &&
            currentUser &&
            !temporaryPassenger.name && { contactId: selectedPassenger.id }),
        },
      },
    });
  };

  return (
    <ATIDialog
      onSubmit={handleSubmit}
      onClose={onClose}
      open={open}
      isSubmitting={loading}
      initialState={{
        ...trip,
        route,
      }}
    />
  );
}

export default ATIUpdateWrapper;
