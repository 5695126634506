import React from "react";
import { useMutation } from "@apollo/client";

import { Box, Button, Typography } from "@mui/material";

import MoovsDialog from "components/globals/MoovsDialog";
import { grayLight, errorRed, white } from "design-system/colors";
import {
  LOAD_ME_QUERY,
  REMOVE_LINKED_PASSENGER_MUTATION,
} from "globals/graphql";
import { useAnalytics, useScreenSize, useSnackbar } from "globals/hooks";

type DeletePassengerDialogProps = {
  open: boolean;
  setDeleteLinkedPassengerDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  selectedPassengerId: string;
  onUpdateDialogClose: () => void;
};

function DeletePassengerDialog(props: DeletePassengerDialogProps) {
  const {
    open,
    setDeleteLinkedPassengerDialogOpen,
    selectedPassengerId,
    onUpdateDialogClose,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // mutation
  const [removeLinkedPassenger, { loading }] = useMutation(
    REMOVE_LINKED_PASSENGER_MUTATION,
    {
      onCompleted: () => {
        snackbar.success("Successfully deleted passenger!");
        setDeleteLinkedPassengerDialogOpen(false);
        onUpdateDialogClose();
        track("linkPassenger_wasRemoved");
      },
      onError() {
        snackbar.error("Error deleting passenger");
      },
      refetchQueries: [{ query: LOAD_ME_QUERY }],
    }
  );

  // event handlers
  const handleDeleteDialogClose = () => {
    setDeleteLinkedPassengerDialogOpen(false);
  };

  const handleDeleteLinkedPassengerClick = async () => {
    await removeLinkedPassenger({
      variables: {
        input: {
          passengerId: selectedPassengerId,
        },
      },
    });
  };

  return (
    <MoovsDialog
      hideTopBorder
      size={isMobileView ? "md" : "sm"}
      dialogTitle="Delete Passenger"
      acceptButtonText="Delete"
      onClose={handleDeleteDialogClose}
      open={open}
      customFooter={
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          px={isMobileView ? 2 : 4}
          py={2}
          borderTop={`1px solid ${grayLight}`}
        >
          <Box mx={2}>
            <Button onClick={handleDeleteDialogClose}>No, Keep</Button>
          </Box>
          <Box>
            <Button
              sx={{
                color: white,
                backgroundColor: `${errorRed}!important`,
                font: "Poppins",
                paddingRight: 2,
                paddingLeft: 2,
              }}
              disabled={loading}
              onClick={handleDeleteLinkedPassengerClick}
            >
              Delete
            </Button>
          </Box>
        </Box>
      }
    >
      <Typography variant="body2" mb={2}>
        Are you sure you want to delete passenger?
      </Typography>
    </MoovsDialog>
  );
}

export default DeletePassengerDialog;
