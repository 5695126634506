/**
 * @file useWindowPostMessage.tsx
 * Hook that helps capture messages between windows using Window.postMessage()
 */
import { useCallback, useEffect, useState } from "react";

type useWindowPostMessageProps = {
  watch: string;
  eventHandler?: (_, payload) => void;
};

const useWindowPostMessage = (props: useWindowPostMessageProps) => {
  const { watch, eventHandler } = props;

  // state
  const [history, setHistory] = useState([]);
  const [origin, setOrigin] = useState();
  const [source, setSource] = useState();

  // event handlers
  const sendToSender = useCallback(
    (data) => postMessage(data, source, origin),
    [source, origin]
  );

  const sendToParent = (data) => {
    const { opener } = window;
    if (!opener) throw new Error("Parent window has closed");

    postMessage(data, opener);
  };

  const onWatchEventHandler = useCallback(
    ({ origin, source, data }) => {
      const { payload, name } = data;

      if (name === watch) {
        setSource(source);
        setOrigin(origin);
        setHistory((old) => [...old, payload]);

        if (eventHandler) {
          eventHandler(sendToSender, payload);
        }
      }
    },
    [watch, eventHandler, sendToSender]
  );

  useEffect(() => {
    window.addEventListener("message", onWatchEventHandler);
    return () => window.removeEventListener("message", onWatchEventHandler);
  }, [watch, source, origin, onWatchEventHandler]);

  return { history, sendToParent };
};

export { useWindowPostMessage };
