import React from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  CircularProgress,
  Typography,
  ThemeProvider,
} from "@mui/material";

import { LOAD_OPERATOR_QUERY } from "globals/graphql";
import { errorSchema } from "./data";
import useFormSubmit from "./hooks/useFormSubmit";
import IframeRequestForm from "./components/IframeRequestForm";
import { useOperatorSlug, useQueryParams } from "globals/hooks";
import { Operator, OrderType, PlanVariant, TripCategory } from "types";
import errorLoading from "design-system/illustrations/errorLoading.svg";
import useTheme from "useTheme";

function IframeRequestPage() {
  // hooks
  const queryParams = useQueryParams();
  const operatorSlug = useOperatorSlug();

  const methods = useForm({
    defaultValues: initialTripRequestState,
    mode: "onSubmit",
    resolver: yupResolver(errorSchema),
  });
  const colorTheme = useTheme();

  // queries
  const { data: operatorData, loading } = useQuery(LOAD_OPERATOR_QUERY, {
    variables: { operatorSlug },
  });

  const operator: Operator = operatorData?.operator;

  // form handler
  const handleSubmit = useFormSubmit({
    operator,
    methods,
  });

  if (!operator || loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        minHeight="90vh"
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress size={40} />
      </Box>
    );
  }

  // derived state
  const isSample = queryParams.get("sample") === "true";
  const { enabledTripCategories, enabledOrderTypes } = operator;

  const { Free, Standard, Pro, Vip } = PlanVariant;

  if (
    ![Free, Standard, Pro, Vip].includes(operator.plan) &&
    isSample === false
  ) {
    return (
      <ThemeProvider theme={colorTheme}>
        <Box
          display="flex"
          flexDirection="column"
          flex="1"
          minHeight="90vh"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <img
            src={errorLoading}
            alt="Error Loading"
            style={{ height: "140px" }}
          />
          <Typography variant="h3" mt="20px">
            Error Loading Booking Widget
          </Typography>
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={colorTheme}>
      <IframeRequestForm
        methods={methods}
        onSubmit={handleSubmit}
        isSample={isSample}
        enabledTripCategories={enabledTripCategories}
        enabledOrderTypes={enabledOrderTypes}
      />
    </ThemeProvider>
  );
}

export default IframeRequestPage;

export type TripState = {
  totalGroupSize: number;
  note: string;
  dateTime: string;
  returnDateTime: string;
  pickupLocation: {};
  dropoffLocation: {};
  tripCategory: TripCategory;
  totalDuration: number;
  orderType: OrderType;
  pickUpGooglePlaceTypes?: string[];
};

const initialTripRequestState: TripState = {
  totalGroupSize: null,
  note: "",
  dateTime: null,
  returnDateTime: null,
  pickupLocation: { description: "" },
  dropoffLocation: { description: "" },
  tripCategory: null,
  totalDuration: null,
  orderType: null,
};
