import React from "react";

import { Box, Button, Divider, Typography, useTheme } from "@mui/material";

import { greenDark } from "../../design-system/colors";
import { CheckIcon, OpenIcon } from "../../design-system/icons";
import { useScreenSize } from "../../globals/hooks";

type PaySummaryBlockProps = {
  header: string;
  headerAmount: string;
  subHeader1: string;
  subContent1: string;
  subHeader2: string;
  subContent2: string;
  subHeader3: string;
  subContent3: string;
  viewEntityLabel: string;
  onViewEntityClick: () => void;
  isPaid: boolean;
};

function PaySummaryBlock(props: PaySummaryBlockProps) {
  const {
    header,
    headerAmount,
    subHeader1,
    subContent1,
    subHeader2,
    subContent2,
    subHeader3,
    subContent3,
    viewEntityLabel,
    onViewEntityClick,
    isPaid,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const theme = useTheme();

  return (
    <Box p={4} display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between">
        <Box mb={3} display="flex" flexDirection="column">
          <Box mb={1}>
            <Typography variant="h4">{header}</Typography>
          </Box>

          <Typography variant="h1">{headerAmount}</Typography>
        </Box>
        {isPaid && (
          <Box display="flex" alignItems="flex-start">
            <Box display="flex" alignItems="center">
              <CheckIcon color={greenDark} />
              <Box ml={1.5}>
                <Typography variant="h5" style={{ color: greenDark }}>
                  Paid
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      <Divider />

      <Box
        display="flex"
        mt={3}
        flexDirection={isMobileView ? "column" : "row"}
      >
        <Box
          display="flex"
          flexDirection={isMobileView ? "row" : "column"}
          justifyContent={isMobileView ? "space-between" : "flex-start"}
          mr={isMobileView ? 0 : 3}
        >
          <Box mb={isMobileView ? 1 : 0.5}>
            <Typography variant="h4">{subHeader1}</Typography>
          </Box>
          <Typography variant="body1">{subContent1}</Typography>
        </Box>
        <Box
          display="flex"
          flexDirection={isMobileView ? "row" : "column"}
          justifyContent={isMobileView ? "space-between" : "flex-start"}
          mr={isMobileView ? 0 : 3}
        >
          <Box mb={isMobileView ? 1 : 0.5}>
            <Typography variant="h4">{subHeader2}</Typography>
          </Box>
          <Typography variant="body1">{subContent2}</Typography>
        </Box>
        <Box
          display="flex"
          flexDirection={isMobileView ? "row" : "column"}
          justifyContent={isMobileView ? "space-between" : "flex-start"}
        >
          <Box mb={0.5}>
            <Typography variant="h4">{subHeader3}</Typography>
          </Box>
          <Typography variant="body1">{subContent3}</Typography>
        </Box>
      </Box>

      <Box mt={4}>
        <Button
          variant="outlined"
          color="primary"
          onClick={onViewEntityClick}
          fullWidth={isMobileView}
        >
          <Box display="flex" alignItems="center">
            <Box mr={1}>{viewEntityLabel}</Box>
            <OpenIcon color={theme.palette.primary.main} />
          </Box>
        </Button>
      </Box>
    </Box>
  );
}

export default PaySummaryBlock;
