import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

type StripeElementsProviderProps = {
  children: React.ReactNode;
};

function StripeElementsProvider(props: StripeElementsProviderProps) {
  const { children } = props;

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  const elementsOptions = {
    fonts: [
      {
        cssSrc:
          "https://fonts.googleapis.com/css?family=Poppins:300,300i,400,500,600",
      },
    ],
  };

  return (
    <Elements options={elementsOptions} stripe={stripePromise}>
      {children}
    </Elements>
  );
}

export default StripeElementsProvider;
