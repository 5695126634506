import React from "react";
import size from "lodash/size";

import { Box, CircularProgress, Typography } from "@mui/material";

import { RequestTimeFrameEnum } from "types";
import EmptyList from "components/EmptyList";
import GQLQueryStatusIndicator from "components/GQLQueryStatusIndicator";
import emptyOrders from "../../../illustrations/emptyOrders.png";
import { useLoadTripsAsPassenger } from "../hooks/useLoadTripsAsPassenger";
import TripsList from "./TripsList";

function TripsAsPassengerTab() {
  // hooks
  const [upcomingTrips, tripsError, isTripsLoading] = useLoadTripsAsPassenger({
    timeFrame: RequestTimeFrameEnum.Upcoming, // we only show customers upcoming quotes
  });
  const [pastTrips, pastTripsError, isPastTripsLoading] =
    useLoadTripsAsPassenger({
      timeFrame: RequestTimeFrameEnum.Past,
    });

  // derived state
  const isEmpty = !size(upcomingTrips) && !size(pastTrips);

  if (tripsError || pastTripsError) {
    return <GQLQueryStatusIndicator error={tripsError} name="Trips Data" />;
  }

  if (isTripsLoading || isPastTripsLoading) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        flex="1"
        px={3}
        mt={2}
        mb={1}
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isEmpty) {
    return <EmptyList title={`No Trips`} imageUrl={emptyOrders as string} />;
  }

  return (
    <Box flex="1" mt={2} mb={8} display="flex" flexDirection="column">
      <Box my={2}>
        <Typography variant="h3" my={3}>
          Upcoming
        </Typography>
        {size(upcomingTrips) ? (
          <TripsList trips={upcomingTrips} />
        ) : (
          <Typography sx={{ my: 1 }}>No Upcoming Trips</Typography>
        )}
      </Box>
      <Box my={2}>
        <Typography variant="h3" my={3}>
          Past
        </Typography>
        {size(pastTrips) ? (
          <TripsList trips={pastTrips} />
        ) : (
          <Typography sx={{ my: 1 }}>No Past Trips</Typography>
        )}
      </Box>
    </Box>
  );
}

export default TripsAsPassengerTab;
