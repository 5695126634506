import React, { useState } from "react";

import { Button, IconButton } from "@mui/material";

import { EditIcon } from "design-system/icons";
import { useAnalytics, useScreenSize } from "globals/hooks";
import AdditionalTripInfoDialog from "./AdditionalTripInfoDialog";
import { TemporaryPassenger } from "types";

type EditButtonProps = {
  mode: "create" | "update";
  trip: {
    id: string;
    totalGroupSize: number;
    note?: string;
    temporaryPassenger?: TemporaryPassenger;
    routes: {
      id: string;
      carryOnLuggage?: number;
      checkedLuggage?: number;
      oversizeLuggage?: number;
    }[];
  };
};

function EditButton(props: EditButtonProps) {
  const { mode, trip } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();

  // state
  const [dialogOpen, setDialogOpen] = useState(false);

  // event handlers
  const handleClose = () => setDialogOpen(false);
  const handleOpen = () => {
    track("customerLink_editAdditionalInfo");
    setDialogOpen(true);
  };

  return (
    <>
      {isMobileView ? (
        <IconButton onClick={handleOpen}>
          <EditIcon size="small" />
        </IconButton>
      ) : (
        <Button onClick={handleOpen} endIcon={<EditIcon size="small" />}>
          Edit
        </Button>
      )}
      <AdditionalTripInfoDialog
        mode={mode}
        onClose={handleClose}
        open={dialogOpen}
        trip={trip}
      />
    </>
  );
}

export default EditButton;
