import React from "react";

type PassengersIconProps = {
  color?: string;
};

const PassengersIcon = (props: PassengersIconProps) => {
  const { color } = props;

  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 16.3337H2.5C2.10218 16.3337 1.72064 16.1791 1.43934 15.9041C1.15804 15.629 1 15.256 1 14.867M1 14.867V3.62255C1 3.1039 1.21071 2.6065 1.58579 2.23976C1.96086 1.87302 2.46957 1.66699 3 1.66699H12.5C12.6326 1.66699 12.7598 1.7185 12.8536 1.81018C12.9473 1.90187 13 2.02622 13 2.15588V12.9114C13 13.0411 12.9473 13.1654 12.8536 13.2571C12.7598 13.3488 12.6326 13.4003 12.5 13.4003H2.5C2.10218 13.4003 1.72064 13.5548 1.43934 13.8299C1.15804 14.105 1 14.478 1 14.867ZM12 16.3337V13.4003M7 7.84479C6.20435 7.84479 5.44129 8.15383 4.87868 8.70394C4.31607 9.25405 4 10.0002 4 10.7781H10C10 10.0002 9.68393 9.25405 9.12132 8.70394C8.55871 8.15383 7.79565 7.84479 7 7.84479ZM7 7.84479C8.10457 7.84479 9 6.96925 9 5.88923C9 4.80921 8.10457 3.93367 7 3.93367C5.89543 3.93367 5 4.80921 5 5.88923C5 6.96925 5.89543 7.84479 7 7.84479Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PassengersIcon;
