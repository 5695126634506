import React from "react";

import RightSliderArrowIcon from "design-system/icons/actions/RightSliderArrowIcon";

type RightNavProps = {
  disabled: boolean;
  onClick: React.MouseEventHandler<HTMLElement>;
};

function RightNav(props: RightNavProps) {
  const { disabled, onClick } = props;

  return (
    <button
      type="button"
      className="image-gallery-icon image-gallery-right-nav"
      disabled={disabled}
      onClick={onClick}
      aria-label="Next Slide"
    >
      <RightSliderArrowIcon />
    </button>
  );
}

export default RightNav;
