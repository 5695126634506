import React, { Fragment } from "react";

import { Box, Hidden, Typography, Divider, useTheme } from "@mui/material";

import { black, granite } from "../../design-system/colors";
import { Invoice } from "../../types";
import OrderInvoiceItem from "./OrderInvoiceItem";

type InvoiceOrdersListProps = {
  invoice: Invoice;
};

function InvoiceOrdersList(props: InvoiceOrdersListProps) {
  const { invoice } = props;

  // hooks
  const theme = useTheme();

  const indexOfLastRequest = invoice.requests.length - 1;
  const isCompanyInvoice = !!invoice.company;

  return (
    <Box>
      <Hidden smDown>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "center",
            color: black,
            opacity: 0.6,
          }}
        >
          <Box display="flex" flexDirection="row" flex="1" px={2}>
            <Box display="flex" flex=".25">
              <Typography variant="overline">Order</Typography>
            </Box>
            {isCompanyInvoice && (
              <Box display="flex" flex=".25">
                <Typography variant="overline">Booking Contact</Typography>
              </Box>
            )}
            <Box display="flex" flex=".23">
              <Typography variant="overline">Pick up Address</Typography>
            </Box>
            <Box display="flex" flex=".2">
              <Typography variant="overline">Amount</Typography>
            </Box>
            <Box display="flex" flex=".14">
              <Typography variant="overline">Paid</Typography>
            </Box>
            <Box display="flex" flex=".13" justifyContent="flex-end">
              <Typography variant="overline">Amount Due</Typography>
            </Box>
          </Box>
        </Box>
      </Hidden>
      {invoice.requests?.map((request, index) => (
        <Fragment key={`${request.id} ${index}`}>
          {index !== 0 && (
            <Divider
              sx={{
                "& .MuiDivider-root": {
                  backgroundColor: granite,
                  [theme.breakpoints.down("sm")]: {
                    marginLeft: 16,
                    marginRight: 16,
                  },
                },
              }}
            />
          )}

          <OrderInvoiceItem
            request={request}
            isCompanyInvoice={isCompanyInvoice}
          />

          {index === indexOfLastRequest && (
            <Divider
              sx={{
                root: {
                  backgroundColor: granite,
                  [theme.breakpoints.down("sm")]: {
                    marginLeft: 16,
                    marginRight: 16,
                  },
                },
              }}
            />
          )}
        </Fragment>
      ))}
    </Box>
  );
}

export default InvoiceOrdersList;
