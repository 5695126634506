import React from "react";

import { Box, Typography } from "@mui/material";

import { black, grayLight } from "../../design-system/colors";
import { Contact } from "../../types";
import { useScreenSize } from "../../globals/hooks";

type AccountInfoBlockProps = { user: Contact };

function AccountInfoBlock(props: AccountInfoBlockProps) {
  const { user } = props;

  const { email, firstName, lastName, mobilePhone } = user;
  const { isMobileView } = useScreenSize();

  return (
    <Box
      border={`1px solid ${grayLight}`}
      borderRadius="4px"
      boxShadow="0px 4px 15px rgba(0, 0, 0, 0.03)"
      p={3}
      minHeight={isMobileView ? "345px" : "381px"}
      display="flex"
      flexDirection="column"
    >
      <Typography variant="h3" style={{ color: black }}>
        Account Info
      </Typography>
      {/* first name */}
      <Box mt={3}>
        <Typography variant="overline">first name</Typography>
        <Typography variant="body2">{firstName}</Typography>
      </Box>
      {/* last name */}
      <Box mt={1.5}>
        <Typography variant="overline">last name</Typography>
        <Typography variant="body2">{lastName}</Typography>
      </Box>
      {/* email */}
      <Box mt={1.5}>
        <Typography variant="overline">email</Typography>
        <Typography variant="body2">{email}</Typography>
      </Box>
      {/* mobile phone */}
      <Box mt={1.5}>
        <Typography variant="overline">mobile phone</Typography>
        <Typography variant="body2">{mobilePhone}</Typography>
      </Box>
    </Box>
  );
}

export default AccountInfoBlock;
