import * as yup from "yup";
import validator from "validator";

import { formatPhoneNumber } from "utils/phoneNumberFormatter/phoneNumberFormatter";
import { InternalOptions } from "yup/lib/types";

export type CreateLinkedPassengerState = yup.InferType<
  typeof createLinkedPassengerErrorSchema
>;

export const createLinkedPassengerErrorSchema = yup.object({
  firstName: yup
    .string()
    .required("Please provide a first name")
    .max(120, "Max character limit of 120 reached"),

  lastName: yup
    .string()
    .required("Please provide a last name")
    .max(120, "Max character limit of 120 reached"),

  email: yup
    .string()
    .required("Please provide a valid email address")
    .test("Email is valid", (value, { path, createError }) => {
      if (!validator.isEmail(value)) {
        return createError({
          path,
          message: "Please provide a valid email address",
        });
      }

      return true;
    }),

  phoneFields: yup.object({
    mobilePhone: yup
      .string()
      .required("Please provide a valid phone number")
      .test(
        "Is possible phone number",
        (value, { path, createError, options }) => {
          const { parent } = options as InternalOptions; // yup TS is off

          const isPossible = formatPhoneNumber(
            value,
            parent.phoneCountryCode,
            parent.phoneCountryDialCode
          )?.isPossible;

          if (!isPossible) {
            return createError({
              path,
              message: "Please provide a valid phone number.",
            });
          }

          return true;
        }
      ),
    phoneCountryCode: yup.string(),
    phoneCountryDialCode: yup.string(),
    phoneCountryFormat: yup.string(),
    phoneCountryName: yup.string(),
  }),
});
