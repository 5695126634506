import React from "react";

import { Typography, Link } from "@mui/material";

import { granite } from "design-system/colors";
import { useOperator } from "globals/hooks";
import CancellationPolicyLink from "./CancellationPolicyLink";

type TermsAndCancellationPolicyLabelProps = {
  trips: { routes: { vehicle?: { id: string } }[] }[];
};

function TermsAndCancellationPolicyLabel(
  props: TermsAndCancellationPolicyLabelProps
) {
  const { trips } = props;

  // hooks
  const {
    operator: { nameSlug, requireConsentWhenBooking },
  } = useOperator();

  return (
    <span>
      <Typography component="span" variant="body2" style={{ color: granite }}>
        {requireConsentWhenBooking
          ? "I agree"
          : "By confirming below you are agreeing"}{" "}
        to the{" "}
        <Link
          style={{ fontWeight: 700 }}
          href={`/${nameSlug}/terms-and-conditions`}
          target="_blank"
        >
          Terms & Conditions
        </Link>{" "}
      </Typography>
      <CancellationPolicyLink trips={trips} />
    </span>
  );
}

export default TermsAndCancellationPolicyLabel;
