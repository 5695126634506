import React from "react";
import first from "lodash/first";
import isNil from "lodash/isNil";

import { Box, Divider, Typography } from "@mui/material";

import { adjustTripIndex, CustomerPricingType } from "globals/hooks/utils";
import AllTripsPriceSummaryBlock from "./components/AllTripsPriceSummaryBlock";
import ActiveTripPricingBlock from "./components/ActiveTripPricingBlock";
import { useOperator } from "globals/hooks";
import PromoCodeBlock from "./components/PromoCodeBlock";

type PriceOverviewBlockProps = {
  pricings: CustomerPricingType[];
  orderTotalAmt: number;
  activeTripIndex?: number;
  pickUpDateTime?: string;
};

function PriceOverviewBlock(props: PriceOverviewBlockProps) {
  const {
    pricings,
    orderTotalAmt,
    activeTripIndex = 0,
    pickUpDateTime,
  } = props;

  // hooks
  const {
    operator: {
      settings: { hasActivePromoCodes },
    },
  } = useOperator();

  const indexedPricings = adjustTripIndex(pricings);

  const shouldRenderSummary =
    pricings.length > 1 || first(pricings).returnTripPricing;
  const activeTripPricing = indexedPricings[activeTripIndex];

  // boolean for in any outboundTripPricing or returnTripPricing has promoCode.value
  const pricingsHasPromoCode = indexedPricings.some(
    (pricing) =>
      !isNil(pricing.outboundTripPricing.promoCode.value) ||
      !isNil(pricing.returnTripPricing?.promoCode.value)
  );

  // should render promo code input if operator has active promo codes and the first trip does not have a promo code
  const shouldRenderPromoCodeInput =
    hasActivePromoCodes && !pricingsHasPromoCode;

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h3" data-testid="price-overview">
          Price Overview
        </Typography>
      </Box>

      <ActiveTripPricingBlock pricing={activeTripPricing} />

      {shouldRenderPromoCodeInput && (
        <PromoCodeBlock pickUpDateTime={pickUpDateTime} />
      )}

      {shouldRenderSummary && (
        <AllTripsPriceSummaryBlock
          pricings={indexedPricings}
          orderTotalAmt={orderTotalAmt}
        />
      )}
      <Box mt={4}>
        <Divider />
      </Box>
    </Box>
  );
}

export default PriceOverviewBlock;
