import React, { ReactChild } from "react";

import { Box, Typography } from "@mui/material";
import { grayDark, grayLight } from "../../design-system/colors";

type IconInfoSegmentProps = {
  label: string;
  value: string | ReactChild;
  icon?: any;
};

function IconInfoSegment(props: IconInfoSegmentProps) {
  const { label, value, icon } = props;

  const Icon = icon;

  return (
    <Box key={label} display="flex" flexDirection="row" alignItems="center">
      <Box display="flex">
        {icon && (
          <Box
            sx={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              width: 26,
              height: 26,
              marginRight: 1,
              backgroundColor: grayLight,
              borderRadius: "50%",
            }}
          >
            <Icon color={grayDark} size="small" />
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography
          variant="overline"
          sx={{
            color: grayDark,
            lineHeight: 1.5,
            height: 18,
            whiteSpace: "nowrap",
          }}
        >
          {label}
        </Typography>
        <Box
          sx={{
            lineHeight: 1.33,
            fontSize: 12,
            height: 16,
            whiteSpace: "nowrap",
          }}
        >
          {value}
        </Box>
      </Box>
    </Box>
  );
}

export default IconInfoSegment;
