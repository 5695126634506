import React from "react";
import { Box, Divider, Typography } from "@mui/material";

import MoovsIncrementer from "components/globals/MoovsIncrementer";
import {
  addTripReducerActionTypesEnum,
  newTripAddedOnQuoteProps,
} from "../hooks/useAddTripToQuoteReducer";

type LuggageDetailsBlockProps = {
  newTripAddedOnQuote: newTripAddedOnQuoteProps;
  onDetailsChange: (type: string, value: number) => void;
};

const LuggageDetailsBlock = (props: LuggageDetailsBlockProps) => {
  const { newTripAddedOnQuote, onDetailsChange } = props;

  const { CARRY_ON_LUGGAGE, CHECKED_LUGGAGE, OVERSIZE_LUGGAGE } =
    addTripReducerActionTypesEnum;

  return (
    <>
      <Box>
        <Box mb={2}>
          <Divider />
        </Box>
        <Box mb={2}>
          <Typography variant="subtitle2">Luggage</Typography>
        </Box>

        {/* Carry-on luggage */}
        <Box
          display="flex"
          flex={1}
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="body2">Carry-On</Typography>
          <MoovsIncrementer
            value={newTripAddedOnQuote.carryOnLuggage}
            limits={[0, 99]}
            decrement={() =>
              onDetailsChange(
                CARRY_ON_LUGGAGE,
                newTripAddedOnQuote.carryOnLuggage - 1
              )
            }
            increment={() =>
              onDetailsChange(
                CARRY_ON_LUGGAGE,
                newTripAddedOnQuote.carryOnLuggage + 1
              )
            }
            onTextFieldChange={(newCarryOnLuggageSize) =>
              onDetailsChange(CARRY_ON_LUGGAGE, newCarryOnLuggageSize)
            }
            ButtonProps={{ variant: "text" }}
          />
        </Box>

        {/* Checked luggage */}
        <Box
          display="flex"
          flex={1}
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="body2">Checked</Typography>

          <MoovsIncrementer
            value={newTripAddedOnQuote.checkedLuggage}
            limits={[0, 99]}
            decrement={() =>
              onDetailsChange(
                CHECKED_LUGGAGE,
                newTripAddedOnQuote.checkedLuggage - 1
              )
            }
            increment={() =>
              onDetailsChange(
                CHECKED_LUGGAGE,
                newTripAddedOnQuote.checkedLuggage + 1
              )
            }
            onTextFieldChange={(newCheckedLuggageSize) =>
              onDetailsChange(CHECKED_LUGGAGE, newCheckedLuggageSize)
            }
            ButtonProps={{ variant: "text" }}
          />
        </Box>

        {/* Oversize luggage */}
        <Box
          display="flex"
          flex={1}
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="body2">Oversize</Typography>

          <MoovsIncrementer
            value={newTripAddedOnQuote.oversizeLuggage}
            limits={[0, 99]}
            decrement={() =>
              onDetailsChange(
                OVERSIZE_LUGGAGE,
                newTripAddedOnQuote.oversizeLuggage - 1
              )
            }
            increment={() =>
              onDetailsChange(
                OVERSIZE_LUGGAGE,
                newTripAddedOnQuote.oversizeLuggage + 1
              )
            }
            onTextFieldChange={(newOversizeLuggageSize) =>
              onDetailsChange(OVERSIZE_LUGGAGE, newOversizeLuggageSize)
            }
            ButtonProps={{ variant: "text" }}
          />
        </Box>
      </Box>
    </>
  );
};

export default LuggageDetailsBlock;
