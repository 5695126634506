import React, { useMemo } from "react";

import { Box, Typography } from "@mui/material";

import {
  granite,
  grayMedium,
  orange,
  pickUpVariantColors,
  purple,
} from "design-system/colors";
import MoreInfoPanel from "./MoreInfoPanel";
import { PinFilledIcon } from "design-system/icons";
import { Airport, PickUpVariant } from "types";
import RoundChip from "design-system/components/RoundChip";

function Line(props: { height: string }) {
  return (
    <Box
      bgcolor={grayMedium}
      width="1.5px"
      height={props.height}
      my={0.5}
      mr={0.75}
    />
  );
}

type TripTimelineStepProps = {
  stop: {
    location: string;
    airport: Airport;
    stopIndex: number;
    variant: "airport" | "address";
    pickUpVariant: PickUpVariant;
  };
  estimatedDropOffTime: string;
  isLastStop?: boolean;
  isQuote: boolean;
};

function TripTimelineStep(props: TripTimelineStepProps) {
  const { stop, isLastStop, estimatedDropOffTime, isQuote } = props;

  //hooks
  const stopLabel = useMemo(() => {
    if (stop.stopIndex === 1) {
      return "Pick-up";
    }
    if (isLastStop) {
      return `Drop-off`;
    }

    return `Stop ${stop.stopIndex - 1}`;
  }, [isLastStop, stop.stopIndex]);

  const airportText =
    stop.variant === "airport" &&
    `${stop.airport.airportName} Airport (${stop.airport.iataCode})`;

  // todo: why is location ever null on return trips with airports. it should be empty string
  const [address, ...rest] = (stop.location || "").split(",");
  const city = rest.join(",");

  return (
    <Box display="flex" flexDirection="row">
      {/* stepper */}
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box height="18px" width="18px" style={{ position: "relative" }}>
          <PinFilledIcon
            color={
              stop.stopIndex === 1 ? orange : isLastStop ? purple : granite
            }
            size="small"
            text={stop.stopIndex !== 1 && !isLastStop ? stop.stopIndex - 1 : ""}
          />
        </Box>
        {!isLastStop && <Line height="100%" />}
      </Box>

      {/* label */}
      <Box display="flex" flexDirection="column" ml={2} mt={-0.5} mb={5}>
        <Box>
          <Typography variant="overline" mr={1}>
            {stopLabel}
          </Typography>
          {!isQuote && (
            <>
              {stop.pickUpVariant === PickUpVariant.MeetGreet && (
                <RoundChip
                  label="MEET & GREET"
                  {...pickUpVariantColors[stop.pickUpVariant]}
                />
              )}
              {stop.pickUpVariant === PickUpVariant.Curbside && (
                <RoundChip
                  label="CURBSIDE"
                  {...pickUpVariantColors[stop.pickUpVariant]}
                />
              )}
            </>
          )}
        </Box>
        <Typography variant="body2" style={{ marginTop: 8 }}>
          {airportText || address}
        </Typography>
        <Typography variant="body2" style={{ maxWidth: 200 }}>
          {city}
        </Typography>

        {/* more info panel */}
        <MoreInfoPanel
          stop={stop}
          stopLabel={stopLabel}
          estimatedDropOffTime={estimatedDropOffTime}
        />
      </Box>
    </Box>
  );
}

export default TripTimelineStep;
