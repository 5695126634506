import { ConfirmReservationFormState } from "./schemaValidation";

export const confirmReservationFormDefaultValues: ConfirmReservationFormState =
  {
    paymentMethod: {
      cardholderName: "",
      creditCardError: null,
    },
    isTermsAccepted: false,
    driverGratuityPctCustomerInput: null,
    stripePaymentMethodId: null,
    pickUpVariantSelected: null,
    printedName: null,
    autoPaymentAmt: null,
    autoPaymentType: null,
    promoCodeCustomerInput: null,
    additonalItemsByTrip: [
      {
        childSeats: {
          boosterSeatAmt: null,
          boosterSeatQuantity: null,
          forwardFacingSeatAmt: null,
          forwardFacingSeatQuantity: null,
          rearFacingSeatAmt: null,
          rearFacingSeatQuantity: null,
        },
      },
    ],
  };
