import * as yup from "yup";

// the validate function to use in the schema for stripe card element values
export const validateStripeCreditCard = yup
  .object()
  .nullable()
  .test((val, { createError }) => {
    if (val === null) return true;
    return createError(val);
  });
