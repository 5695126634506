import React from "react";
import capitlize from "lodash/capitalize";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "../../VehicleImageCarousel.css";

import { Box, Typography, Button } from "@mui/material";

import MissingImage from "design-system/images/MissingImage.png";
import { granite, black, grayMedium } from "design-system/colors";
import { VehiclePhoto } from "types";
import { WhiteLabelVehicleListItemProps } from "./WhiteLabelVehicleListItem";

function WhiteLabelVehicleListItemMobile(
  props: WhiteLabelVehicleListItemProps
) {
  const { vehicle, buttonText, onVehicleSelect } = props;

  return (
    <Box
      mx={1}
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <Box mt={2} display="flex" flexDirection="row">
        {vehicle.operator.companyLogoUrl && (
          <img
            src={vehicle.operator.companyLogoUrl}
            alt=""
            style={{
              height: "30px",
              marginRight: "8px", // equivalent to mui's mr={1}
            }}
          />
        )}
        <Typography variant="h3" my="auto">
          {vehicle.operator.name}
        </Typography>
      </Box>

      <Box display="flex" my={1}>
        <Typography variant="subtitle2">
          {capitlize(vehicle.exteriorColor) || ""}{" "}
          {capitlize(vehicle.vehicleType.typeName) || ""}
        </Typography>
        <Typography mx={1} color={grayMedium} fontWeight={800}>
          ·
        </Typography>
        <Typography variant="body2" color={granite}>
          {vehicle.capacity} person{vehicle.capacity > 1 ? "s" : ""}
        </Typography>
      </Box>

      <ImageGallery
        lazyLoad
        showBullets
        showThumbnails
        showNav={false}
        showFullscreenButton={false}
        showPlayButton={false}
        onErrorImageURL={MissingImage}
        items={vehicle.photos?.map((photo: VehiclePhoto) => ({
          original: photo.url,
          originalTitle: vehicle.exteriorColor + vehicle.vehicleType.typeName,
          originalAlt: vehicle.exteriorColor + vehicle.vehicleType.typeName,
          thumbnail: photo.url,
        }))}
      />

      <Box
        mb={1}
        display="flex"
        flexDirection="row"
        alignContent="space-between"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex">
          <Box display="flex" alignItems="center">
            <Typography
              variant="body2"
              style={{ color: black, fontWeight: 500 }}
            >
              Request for Pricing
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box>
        <Button
          fullWidth
          onClick={onVehicleSelect}
          color="primary"
          variant="contained"
        >
          <Box mx={1}>{buttonText}</Box>
        </Button>
      </Box>
    </Box>
  );
}

export default WhiteLabelVehicleListItemMobile;
