import React from "react";

import { Stepper, Step, StepLabel, Box } from "@mui/material/";

import { granite, grayLight, white } from "design-system/colors";
import { useAuth, useScreenSize } from "globals/hooks";
import LoginLogoutBlock from "components/auth/LoginLogoutBlock/LoginLogoutBlock";
import ProgressConnector from "./componets/ProgressConnector";
import { useCreateRequestContext } from "pages/new/context/useCreateRequestContext";

type StatusStepperProps = {
  activeStep: 0 | 1 | 2;
  hideLogin?: boolean;
};

function StatusStepper(props: StatusStepperProps) {
  const { activeStep = 0, hideLogin } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const { authStage } = useAuth();
  const [, , { skipVehicleSelectionEnabled }] = useCreateRequestContext();

  const steps = skipVehicleSelectionEnabled
    ? ["Request Information", "Confirm"]
    : ["Request Information", "Choose Vehicle", "Confirm"];

  return (
    <Box
      width="100%"
      bgcolor={white}
      zIndex={1001}
      borderBottom={
        activeStep === 2 || isMobileView ? "none" : `1px solid ${grayLight}`
      }
      py={2}
    >
      {/* Mobile Login/Logout Button */}
      {isMobileView && !hideLogin && (
        <Box pt={2} pb={2} display="flex" justifyContent="flex-end" mr={1}>
          <Box>
            <LoginLogoutBlock renderContinueAsGuest />
          </Box>
        </Box>
      )}

      <Box
        display="flex"
        flexDirection="row"
        maxWidth="1140px"
        justifyContent="center"
        alignItems="center"
      >
        {/* for spacing */}
        {!isMobileView && !hideLogin && <Box width="80px"></Box>}

        <Stepper
          sx={{
            m: "auto",
            gap: isMobileView ? "52.5px" : "100px",
          }}
          alternativeLabel
          activeStep={activeStep}
          connector={<ProgressConnector />}
        >
          {steps.map((label, index) => {
            const showPartialProgress = index === activeStep + 1; // connector to next step

            return (
              <Step
                sx={{ px: 0, minWidth: isMobileView ? "90px" : "150px" }}
                key={index}
                disabled={showPartialProgress} // this will display partial progress bar
              >
                <StepLabel
                  sx={{
                    "&& .MuiStepLabel-alternativeLabel": {
                      mt: 1,
                    },
                    "&& .Mui-active": {
                      color: "primary.main",
                    },
                    "&& .Mui-completed": {
                      color: granite,
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {!isMobileView && !hideLogin && (
          <Box minWidth="185px">
            <Box
              alignSelf="center"
              margin="0 0 0 auto"
              {...(authStage !== "authenticated" && { maxWidth: "74px" })}
            >
              <LoginLogoutBlock renderContinueAsGuest />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default StatusStepper;
