/**
 * @file useCreateReservation
 *
 * Creates a request w/o pricing (Reservation)
 */

import { useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import pick from "lodash/pick";
import { useHistory } from "react-router-dom";
import first from "lodash/first";
import map from "lodash/map";
import isNull from "lodash/isNull";
import { UseFormSetError } from "react-hook-form";

import { formatPhoneNumber } from "utils/phoneNumberFormatter/phoneNumberFormatter";
import {
  CREATE_CONTACT_MUTATION,
  CREATE_RESERVATION_MUTATION,
  CREATE_WALLET_MUTATION,
  ROLLBACK_CREATE_CONTACT_MUTATION,
  UPDATE_CONTACT_MUTATION,
} from "../../../globals/graphql";
import {
  useAnalytics,
  useAuth,
  useCurrentUser,
  useDefaultPassenger,
  useGoogleTracking,
  useLaunchDarklyFlags,
  useOperator,
  useQueryParams,
  useSnackbar,
} from "../../../globals/hooks";
import {
  getErrorCode,
  getErrorMessage,
} from "../../../moovsErrors/getErrorMessage";
import { Contact, PricingLayout } from "../../../types";
import { fromGlobalId } from "../../../utils/auth/helpers";
import { useCreateRequestContext } from "../context/useCreateRequestContext";
import { sanitizeRequestForCreateMutation } from "components/confirm/hooks/helpers";
import { calculatePricing } from "globals/hooks/utils";
import { CreateReservationFormState } from "../steps/confirm/components/CreateReservationForm/form/schemaValidation";
import { mergeConfirmRequestFormState } from "../context/helpers/mergeConfirmRequestFormState";
import { useTermsAndConditions } from ".";
import useIntroTermsAndConditions from "pages/new/hooks/useIntroTermsAndConditions";
import { deriveStrictestCancellationPolicy } from "../utils/deriveStrictestCancellationPolicy";
import { useCancellationPolicies } from "components/confirm/form/components/TermsAndConditionsBlock/hooks/useCancellationPolicies";
import { usePricelessBookingContext } from "../steps/confirm/context";
import { getIsNetflixLogin } from "../../../utils/auth/isNetflixLogin";
import getDataForUpdateContact from "../utils/getDataForUpdateContact";

type UseCreateReservationParams = {
  setError: UseFormSetError<any>;
  createReservationFormState: CreateReservationFormState;
};

function useCreateReservation(params: UseCreateReservationParams) {
  const { setError, createReservationFormState } = params;

  // contexts
  const [request] = useCreateRequestContext();
  const { isPricelessBooking } = usePricelessBookingContext();

  // hooks
  const { authStage, loginData } = useAuth();
  const currentUser = useCurrentUser();
  const snackbar = useSnackbar();
  const history = useHistory();
  const { operator } = useOperator();
  const stripe = useStripe();
  const stripeElements = useElements();
  const {
    operator: {
      pricingLayout,
      settings: { tripStatusUpdateTextTo },
    },
  } = useOperator();
  const { track } = useAnalytics();
  const { googleTrack, getGclid } = useGoogleTracking();
  const queryParams = useQueryParams();
  const termsData = useTermsAndConditions();
  const { cancellationPolicies: cancellationPoliciesData } =
    useCancellationPolicies({ trips: null });
  const intro = useIntroTermsAndConditions();
  const { enableLinkedPassenger } = useLaunchDarklyFlags();
  const { selectedPassenger } = useDefaultPassenger() || {};
  const isNetflixLogin = getIsNetflixLogin();

  // refs
  // used to make sure data passed to createReservation when a callback
  // is updated (otherwise is a closure). This also requires a
  // setTimeout to wait for next render
  const authStageRef = useRef(authStage);
  const currentUserRef = useRef(currentUser);
  const isAuthenticated = authStageRef.current === "authenticated";

  // effects
  useEffect(() => {
    authStageRef.current = authStage;
  }, [authStage]);

  useEffect(() => {
    currentUserRef.current = currentUser;
  }, [currentUser]);

  // state
  const [isCreatingReservation, setIsCreatingReservation] = useState(false);
  const [newContactId, setNewContactId] = useState("");

  // mutations
  const [createContact] = useMutation(CREATE_CONTACT_MUTATION, {
    onError(error) {
      setIsCreatingReservation(false);
      const errorMessage = getErrorMessage(error) || "Error creating contact.";

      snackbar.error(errorMessage);
      console.log(error);
    },
  });

  const [rollbackCreateContact] = useMutation(
    ROLLBACK_CREATE_CONTACT_MUTATION,
    {
      onError(error) {
        setIsCreatingReservation(false);
        const errorMessage =
          getErrorMessage(error) || "Error rolling back contact.";

        snackbar.error(errorMessage);
        console.log(error);
      },
    }
  );

  const [createWallet] = useMutation(CREATE_WALLET_MUTATION, {
    onError(error) {
      setIsCreatingReservation(false);
      const errorMessage = getErrorMessage(error) || "Error creating payment.";

      snackbar.error(errorMessage);
      console.log(error);
    },
  });

  const [createReservationMutation] = useMutation(CREATE_RESERVATION_MUTATION, {
    onCompleted(data) {
      const { autoPaymentType, promoCodeCustomerInput } =
        createReservationFormState;

      const trackingChargeType = !autoPaymentType
        ? "no charge"
        : autoPaymentType === "partial"
        ? "partial charge"
        : "full charge";

      const hasChildSeatApplied = [
        "rearFacingSeatAmt",
        "boosterSeatAmt",
        "forwardFacingSeatAmt",
      ].some(
        (childSeatField) =>
          !isNull(
            data.createReservation.request.trips[0].routes[0].pricing[
              childSeatField
            ]
          )
      );

      // linked passenger tracking
      let linkedPassengerCount = 0;
      let temporaryPassengerCount = 0;
      let bookingContactCount = 0;

      data.createReservation.request.trips.forEach((trip) => {
        if (trip.tempPassenger?.name) {
          temporaryPassengerCount++;
        } else if (
          trip.contact.id === data.createReservation.request.bookingContact.id
        ) {
          bookingContactCount++;
        } else {
          linkedPassengerCount++;
        }
      });

      if (enableLinkedPassenger && linkedPassengerCount) {
        track("trip_linkedPassengerAdded", {
          totalCount: linkedPassengerCount,
        });
      }

      track(
        isPricelessBooking
          ? "reservationRequest_newReceived"
          : "reservations_newReceived",
        {
          type: isPricelessBooking ? "reservationRequest" : "BRA",
          charge_type: trackingChargeType,
          ...(enableLinkedPassenger && {
            passengerType: {
              bookingContact: bookingContactCount,
              temporaryPassenger: temporaryPassengerCount,
              linkedPassengerCount: linkedPassengerCount,
            },
          }),
          ...(promoCodeCustomerInput && { promo: "code_applied" }),
          ...(hasChildSeatApplied && { child_seat: "seat_added" }),
        }
      );

      const operatorId = fromGlobalId(operator.id).id;

      // set analytics to track user if not authenticated
      // if authenticated this already happened
      if (!isAuthenticated) {
        const bookingContact = data.createReservation.request.bookingContact;
        const formattedTwilioNumber = formatPhoneNumber(
          operator.twilioPhoneNumber?.phoneNumber
        )?.formatted;

        window.analytics.identify(fromGlobalId(bookingContact.id).id, {
          operator_id: operatorId,
          email: bookingContact.email,
          name: `${bookingContact.firstName} ${bookingContact.lastName}`,
          source: "customer",
        });

        window.analytics.group(operatorId, {
          operator_id: operatorId,
          name: operator.name,
          phone: formattedTwilioNumber || operator.voicePhoneNumber,
          email: operator.generalEmail,
          location: operator.address,
          source: "customer",
          plan: operator.plan,
        });
      }

      googleTrack(
        "moovs_create_reservation",
        data.createReservation.request.totalAmount
      );
      setIsCreatingReservation(false);

      history.push({
        pathname: `/${operator.nameSlug}/order/${data.createReservation.request.id}`,
        search: "?successDialog=true",
        state: { autoPaymentType },
      });
    },
    onError(error) {
      setIsCreatingReservation(false);
      const errorCode = getErrorCode(error);
      if (errorCode === "MOOVS_INVALID_EMAIL") {
        setError("bookingContact.email", {
          message: "Please enter valid email address",
        });
      }

      let errorMessage = "";
      if (errorCode === "MOOVS_DAILY_PAYMENT_LIMIT_REACHED") {
        errorMessage = `We're unable to process your payment at this time. ${operator.generalEmail ? `Please reach out to ${operator.generalEmail} for assistance.` : ''}`;
      } else {
        errorMessage = getErrorMessage(error) || "Error creating reservation.";
      }

      snackbar.error(errorMessage);

      // only run if server error and new contact was created
      if (newContactId && error.networkError) {
        rollbackCreateContact({
          variables: {
            input: {
              contactId: newContactId,
            },
          },
        });
      }
    },
  });

  // creates stripe wallet and card. If new contact,
  // also creates a contact so it can be linked
  // to the stripe customer. If card error, will
  // rollback creating new contact.
  const createStripeWalletCardAndContact = async (
    cardholderName: string,
    contact: Omit<
      Contact,
      "__typenmame" | "createdAt" | "updatedAt" | "mobilePhoneInternational"
    >
  ) => {
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = stripeElements.getElement(CardElement);

    const shouldCreateNewContact = !contact.id;
    let contactId = contact.id;

    // create contact if doesn't exist
    if (shouldCreateNewContact) {
      const inputContact = pick(contact, [
        "firstName",
        "lastName",
        "email",
        "mobilePhone",
        "phoneCountryCode",
        "phoneCountryDialCode",
        "phoneCountryName",
        "phoneCountryFormat",
      ]);

      const { data } = await createContact({
        variables: {
          input: inputContact,
        },
      });

      // set new contact id to delete later in event of error
      setNewContactId(data.createContact.contact.id);
      contactId = data.createContact.contact.id;
    }

    const { data } = await createWallet({
      variables: {
        input: {
          contactId,
        },
      },
    });

    if (!data) {
      return;
    }

    try {
      const confirmCardSetupResult = await stripe.confirmCardSetup(
        data.createWallet.clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: cardholderName,
              email: contact.email,
            },
          },
        }
      );

      if (confirmCardSetupResult.error) {
        if (confirmCardSetupResult.error.code === "email_invalid") {
          const invalidEmailMessage = "Please enter valid email address";

          setError("bookingContact.email", {
            message: invalidEmailMessage,
          });

          snackbar.error(invalidEmailMessage);
        } else {
          snackbar.error("Error creating reservation");
        }

        return;
      }

      return {
        contact: { ...contact, id: contactId },
        setupIntent: confirmCardSetupResult.setupIntent,
      };
    } catch (error) {
      // rollback creating new contact
      if (shouldCreateNewContact) {
        await rollbackCreateContact({
          variables: {
            input: {
              contactId,
            },
          },
        });
      }
    }
  };

  const [updateContact] = useMutation(UPDATE_CONTACT_MUTATION, {
    onCompleted: () => {
      snackbar.success("Successfully updated contact!");
    },
    onError: (error) => {
      console.error(error);
      snackbar.error(error.message || "Error updating passenger");
    },
  });

  const createReservation = async (
    createReservationFormState: CreateReservationFormState
  ) => {
    const authenticatedCustomerWithCreditCardOnFile =
      isAuthenticated && !!currentUserRef?.current?.paymentMethods?.length;

    if (
      operator.enableCreditCardWhenBooking &&
      !authenticatedCustomerWithCreditCardOnFile &&
      (!stripe || !stripeElements)
    ) {
      // if stripe elements fail to load, prevent form submission.
      snackbar.error("error loading payment elements");
    }

    setIsCreatingReservation(true);

    const updatedRequest = mergeConfirmRequestFormState(
      request,
      createReservationFormState
    );

    let verifiedContact = currentUserRef.current;

    if (
      isNetflixLogin &&
      createReservationFormState.bookingContact &&
      loginData
    ) {
      const dataForResponse = getDataForUpdateContact(
        createReservationFormState,
        loginData
      );
      const updatedContact = await updateContact(dataForResponse);

      verifiedContact.mobilePhone =
        updatedContact.data.updateContact.contact.mobilePhone;

      if (enableLinkedPassenger) {
        selectedPassenger.mobilePhone =
          updatedContact.data.updateContact.contact.mobilePhone;
      }
    }

    const createRequestInput = sanitizeRequestForCreateMutation({
      request: updatedRequest,
      pricingLayout: operator.settings?.pricingLayout,
      verifiedContact: currentUserRef.current,
      pickUpVariantSelected: createReservationFormState.pickUpVariantSelected,
      enableLinkedPassenger,
      selectedPassenger,
      tripStatusUpdateTextTo,
    });

    // fix base rate amt for priceless booking
    if (
      isPricelessBooking &&
      !createRequestInput.trips[0].routes[0].baseRateAmt
    ) {
      createRequestInput.trips[0].routes[0].baseRateAmt = null;
      if (
        createRequestInput.trips[0].returnTrip &&
        !createRequestInput.trips[0].returnTrip.routes[0].baseRateAmt
      ) {
        createRequestInput.trips[0].returnTrip.routes[0].baseRateAmt = null;
      }
    }

    let stripePaymentMethodId;
    let setupIntent;
    let newContact;

    // create stripe wallet if user has not selected a credit card
    if (
      operator.enableCreditCardWhenBooking &&
      !authenticatedCustomerWithCreditCardOnFile
    ) {
      const createStripeWalletResponse = await createStripeWalletCardAndContact(
        createReservationFormState.paymentMethod.cardholderName,
        currentUserRef.current || {
          id: null,
          ...updatedRequest.bookingContact,
        }
      );

      setupIntent = createStripeWalletResponse?.setupIntent;
      newContact = createStripeWalletResponse?.contact;
      stripePaymentMethodId = setupIntent?.payment_method;

      if (!stripePaymentMethodId) {
        setIsCreatingReservation(false);
        return;
      }

      // use newly created contact
      createRequestInput.trips[0].contact = pick(newContact, [
        "id",
        "firstName",
        "lastName",
        "email",
        "mobilePhone",
      ]);
    }

    // create request only if new card went through,
    // or when its a verified contact with an existing card,
    // or the operator has disabled credit cards when booking.
    if (
      !stripePaymentMethodId &&
      !authenticatedCustomerWithCreditCardOnFile &&
      operator.enableCreditCardWhenBooking
    ) {
      setIsCreatingReservation(false);
      snackbar.error("Error creating reservation");
      return;
    }

    // if authenticated and using an existing card, set stripePaymentMethodId equal to that card
    if (
      operator.enableCreditCardWhenBooking &&
      authenticatedCustomerWithCreditCardOnFile
    ) {
      stripePaymentMethodId = createReservationFormState.stripePaymentMethodId;
    }

    const { driverGratuity, returnDriverGratuity, cashGratuity } =
      getDriverGratuity(
        request.trip,
        pricingLayout,
        createReservationFormState.driverGratuityPctCustomerInput
      );

    const baseRateAutomation =
      request.trip.routes[0].vehicle.baseRateAutomation;

    const printedName = createReservationFormState.printedName?.trim();

    // format terms & conditions and cancellation policy
    const terms = termsData.map(({ name, description }) => {
      return { name, description };
    });

    const selectedVehicleIds = request.trip.routes.map(
      (route) => route.vehicle.id
    );

    const cancellationPolicies = deriveStrictestCancellationPolicy({
      cancellationPoliciesData,
      selectedVehicleIds,
    });

    createReservationMutation({
      variables: {
        input: {
          ...createRequestInput,
          stripePaymentMethodId,
          clientSecret: setupIntent?.id,
          gclid: getGclid(),
          queryString: queryParams.toString(),
          ...(baseRateAutomation && {
            baseRateAutomation: {
              total: baseRateAutomation.total,
              lineItems: map(
                baseRateAutomation.lineItems,
                ({ __typename, ...lineItem }) => lineItem
              ),
            },
          }),
          // user entered driver gratuity. should only
          // have a value if no driver gratuity was
          // included in pricing layout
          ...(operator.gratuityWhenBooking && {
            driverGratuity,
            returnDriverGratuity,
            cashGratuity,
          }),
          autoPaymentAmt: createReservationFormState.autoPaymentAmt,
          printedName,
          intro,
          terms,
          cancellationPolicies,
          isUnconfirmedRequest: isPricelessBooking,
        },
      },
    });
  };

  return {
    createReservation,
    isCreatingReservation,
  };
}

export { useCreateReservation };

const getDriverGratuity = (
  trip: {
    routes: {
      vehicle?: {
        id: string;
        baseRateAutomation?: {
          total: number;
        };
        returnBaseRateAutomation?: {
          total: number;
        };
      };
    }[];
  },
  pricingLayout: PricingLayout,
  driverGratuityPctCustomerInput: number | "cash"
) => {
  const cashGratuity = driverGratuityPctCustomerInput === "cash";

  if (cashGratuity) {
    return {
      cashGratuity,
      driverGratuity: 0,
      returnDriverGratuity: 0,
    };
  }

  const { baseRateAutomation, returnBaseRateAutomation } = first(
    trip.routes
  ).vehicle;

  const automatedBaseRate = baseRateAutomation?.total || null;
  const returnAutomatedBaseRate = returnBaseRateAutomation?.total || null;

  const driverGratuity = calculatePricing({
    baseRateAmt: automatedBaseRate,
    ...(!pricingLayout?.driverGratuityAmt &&
    !pricingLayout?.driverGratuityPercent
      ? {
          percent: driverGratuityPctCustomerInput / 100 || 0,
        }
      : {
          percent: pricingLayout.driverGratuityPercent,
          amt: pricingLayout.driverGratuityAmt,
        }),
  });

  const returnDriverGratuity = calculatePricing({
    baseRateAmt: returnAutomatedBaseRate,
    ...(!pricingLayout?.driverGratuityAmt &&
    !pricingLayout?.driverGratuityPercent
      ? {
          percent: driverGratuityPctCustomerInput / 100 || 0,
        }
      : {
          percent: pricingLayout.driverGratuityPercent,
          amt: pricingLayout.driverGratuityAmt,
        }),
  });

  return {
    driverGratuity,
    returnDriverGratuity,
    cashGratuity: false,
  };
};
