import { Box, Link, Typography } from "@mui/material";
import React from "react";

import { errorRed, grayDark, tintRed, white } from "design-system/colors";
import UnavailableCalendarIcon from "design-system/icons/info/UnavailableCalendarIcon";
import { useOperator, useScreenSize } from "globals/hooks";
import { formatPhoneNumber } from "utils/phoneNumberFormatter/phoneNumberFormatter";

function VehicleUnavailable() {
  // hooks
  const { isMobileView } = useScreenSize();
  const {
    operator: { voicePhoneNumber, twilioPhoneNumber },
  } = useOperator();

  const formattedTwilioNumber = formatPhoneNumber(
    twilioPhoneNumber?.phoneNumber
  )?.formatted;

  return (
    <Box
      display="flex"
      bgcolor={tintRed}
      py={1.5}
      borderRadius="5px"
      mb={2}
      mt={isMobileView ? -2 : 0}
    >
      {/* icon */}
      <Box p={1.5}>
        <Box
          borderRadius="25px"
          bgcolor={white}
          width="45px"
          height="45px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <UnavailableCalendarIcon />
        </Box>
      </Box>
      {/* Description */}
      <Box display="flex" flexDirection={isMobileView ? "column" : "row"}>
        <Box>
          <Box maxWidth={"80%"}>
            <Typography variant="subtitle2" fontSize="14px" color={errorRed}>
              Vehicle is unavailable for trip time selected.
            </Typography>
            <Typography
              variant="body2"
              color={errorRed}
              lineHeight="16px"
              fontSize="12px"
              mt={0.5}
            >
              Please select a different vehicle, date/time, or contact us to
              check vehicle availability.
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          mt={isMobileView ? 2 : 0}
          minWidth="140px"
        >
          <Typography variant="overline" color={grayDark}>
            Phone
          </Typography>
          <Link
            fontSize="12px"
            href={`tel:${voicePhoneNumber || formattedTwilioNumber}`}
          >
            {voicePhoneNumber || formattedTwilioNumber}
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default VehicleUnavailable;
