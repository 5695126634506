import first from "lodash/first";
import last from "lodash/last";
import size from "lodash/size";
import moment from "moment-timezone";

export function currency(money: number | string) {
  if (money === undefined) return "-";

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(Number(money));
}

export function convertMinutesToHoursMinutes(
  inputMinutes: number,
  formal?: boolean
) {
  const hours = Math.floor(inputMinutes / 60);
  const minutes = Math.round(inputMinutes % 60);

  let result = "";

  if (formal) {
    if (hours) {
      result += `${hours} Hour${hours > 1 ? "s" : ""}`;
    }
    if (minutes) {
      result += ` ${minutes} Minute${minutes > 1 ? "s" : ""}`;
    }
  } else {
    if (hours) {
      result += `${hours}h`;
    }
    if (minutes) {
      result += ` ${minutes}min`;
    }
  }

  return result || "N/A";
}

export const parseNumberFromFlightNumber = (ident: string): string => {
  if (!ident) return "";
  return ident.replace(/([^0-9])/g, "");
};

export const isValidPhoneNumber = (phoneNumber: string) => {
  const unmaskedPhone = phoneNumber
    .toString()
    .replace(/[+]/, "")
    .replace(/^1/, "")
    .replace(/\s/g, "")
    .replace(/[()]/g, "")
    .replace(/-/g, "");

  return unmaskedPhone.length === 10;
};

/**
 * get total duration of a trips using moment difference
 * time between first and last stop dateTime
 * @param stops array of stops on a trip
 */
export const calculateTotalDurationMinutes = (stops: any[]): number => {
  const startDateTime = stops[0].dateTime;
  const endDateTime = stops[stops.length - 1].dateTime;

  // do not calculate if missing fields
  if (!startDateTime || !endDateTime) return null;

  return Math.floor(
    moment.duration(moment(endDateTime).diff(moment(startDateTime))).asMinutes()
  );
};

const orderTripsByAscendingStopTimes = (
  trips: { stops: { dateTime?: string }[] }[]
) => {
  if (size(trips) < 2) return trips;

  return trips.slice().sort((tripA, tripB) => {
    return moment(first(tripA.stops).dateTime).diff(
      moment(first(tripB.stops).dateTime)
    );
  });
};

export const calculateRangeOfTripDates = (
  trips: { stops: { dateTime?: string }[] }[]
) => {
  // will render a range if first stop of multiple trips span multiple days
  const orderedTrips = orderTripsByAscendingStopTimes(trips);
  const firstTrip = first(orderedTrips);
  const lastTrip = last(orderedTrips);

  const firstTripStartTime = moment.utc(first(firstTrip.stops).dateTime);
  const lastTripStartTime = moment.utc(first(lastTrip.stops).dateTime);
  const lastTripIsOnDifferentDayThanFirst = lastTripStartTime.isAfter(
    firstTripStartTime,
    "day"
  );

  const firstTripStartTimeDisplay = firstTripStartTime.format(
    "MMM Do YYYY, h:mm A"
  );
  const lastTripStartTimeDisplay = lastTripStartTime.format(
    "MMM Do YYYY, h:mm A"
  );

  if (size(trips) === 1 || !lastTripIsOnDifferentDayThanFirst) {
    return { firstTripStartTimeDisplay };
  }

  return {
    firstTripStartTimeDisplay,
    lastTripStartTimeDisplay,
  };
};

export const decodeGlobalId = (globalId: string) => {
  const decodedString = atob(globalId);
  const delimiterIndex = decodedString.indexOf(":");

  return decodedString.substring(delimiterIndex + 1);
};
