import startCase from "lodash/startCase";
import moment, { Moment } from "moment-timezone";
import { DeepPartial } from "ts-essentials";
import { CardItem, Stop, Trip, Request, BillingDetails } from "../../types";

import airportTimeZone from "./airportTimeZone.json";

// Description: Builds url for route in Google Maps.
export function getGoogleMapsURL(address: string) {
  const url = `https://maps.google.com/?saddr=My+Location&daddr=${encodeURIComponent(
    address
  )}&t=m`;

  return url;
}

// takes current time and corrects offset by browser timezone.
// then manually replaces the timezone with UTC
export function convertTimeToUTC(time) {
  const dateTimeString = (
    time?._isAMomentObject
      ? time.tz(moment.tz.guess(true))
      : moment(time).tz(moment.tz.guess(true))
  ).format("MM-DD-YYYY hh:mm:ss A");

  return moment.utc(dateTimeString, "MM-DD-YYYY hh:mm:ss A");
}

export function applyUTCOffsetToTime(time: any, event: "add" | "subtract") {
  if (!time) return time;
  const isMomentObj = time?._isAMomentObject;
  const timezoneGuess = moment.tz.guess();

  const UTCOffset = moment.tz
    .zone(timezoneGuess)
    .utcOffset(isMomentObj ? time.unix() * 1000 : moment(time).unix() * 1000);

  return isMomentObj
    ? time[event](UTCOffset, "m")
    : moment(time)[event](UTCOffset, "m");
}

// disable date picker date with proper UTC date comparison
export function disablePastDatePickerDates(date) {
  const dateUnix = date.unix();
  const currentUnixTime = convertTimeToUTC(moment()).unix();

  // disable if the date is before current time
  return dateUnix + 24 * 60 * 60 < currentUnixTime;
}

export function convertTimeToLocalAirlineTimezone(date: Date, icao: string) {
  return moment(date).tz(airportTimeZone[icao]);
}

// "digits" is the number of digits total
// pad(10, 4);       0010
// pad(8, 3);       008
// "z" is the character used to pad the value.
// pad(123, 4);      0123
// pad(10, 4, '-');  --10
export function pad(value, digits, z?: any) {
  z = z || "0";
  value = value + "";
  return value.length >= digits
    ? value
    : new Array(digits - value.length + 1).join(z) + value;
}

type DateFormatterOptions = {
  mode?: "date" | "dateTime";
  returnFormatString?: boolean;
};

export function dateFormatter(
  date: Moment | null,
  variant: "long" | "medium" | "abbreviated" | "numerical" | "timestamp",
  options?: DateFormatterOptions
) {
  if (variant === "timestamp") {
    // calendar uses local time, so need to convert utc to date string without time
    const day = moment(date.format("YYYY-MM-DD")).calendar({
      sameDay: "[Today at]",
      nextDay: "[Tomorrow at]",
      lastDay: "[Yesterday at]",
      nextWeek: "dddd, MMM Do",
      lastWeek: "dddd, MMM Do",
      sameElse: "M/DD/YY",
    });

    const time = date.format("h:mm A");
    return `${day} ${time}`;
  }

  const formatStringMap = {
    long: "dddd, MMMM Do, YYYY",
    medium: "dddd, MMM Do, YYYY",
    abbreviated: "ddd, MMM Do, YYYY",
    numerical: "M/DD/YY",
  };

  let formatString = formatStringMap[variant];

  if (options?.mode === "dateTime") formatString = `${formatString} h:mm A`;

  return options?.returnFormatString ? formatString : date.format(formatString);
}

// reorders stops during and after drags
export function reorderStops(
  stops: any[],
  sourceIndex: number,
  destinationIndex: number
) {
  const nextStops = [...stops];

  const [removedStop] = nextStops.splice(sourceIndex, 1);
  nextStops.splice(destinationIndex, 0, removedStop);

  return stops.map((stop, index) => ({
    ...stop,
    stopIndex: index + 1,
    id: nextStops[index].id,
    location: nextStops[index].location,
    airport: nextStops[index].airport,
    airline: nextStops[index].airline,
    flightNumber: nextStops[index].flightNumber,
    trackedFlight: nextStops[index].trackedFlight,
    variant: nextStops[index].variant,
  }));
}

export function checkIfStopTimesAreInOrder(
  stops: DeepPartial<Stop[]>
): boolean[] {
  let largestTime = 0;

  return stops.map((stop) => {
    const time = stop.dateTime;
    if (!time) return false;

    const unixTime = moment(time).unix();
    const prevLargestTime = largestTime;

    largestTime = Math.max(largestTime, unixTime);

    return unixTime < prevLargestTime;
  });
}

export type CoordinatesType = {
  x: string;
  y: string;
};

/**
 * Converts coordinates object to string in textual lat/long format,
 * which google api wants. If an address string is provided returns that address,
 * google api will convert an address to coordinates. But providing coordinates
 * directly has less room for error.
 *
 * e.g. { x: 33.9415889, y: -118.40853} => "33.9415889,-118.40853".
 */
export const convertCoordinatesToTextualLatLong = (
  location: string | CoordinatesType
): string => {
  if (typeof location === "string") {
    return location;
  }

  return `${location.x},${location.y}`;
};

// Visa Taylor Smith ****-1111 EXP: 12/2024
export const getPaymentMethodInfoString = (
  card: CardItem,
  billingDetails?: BillingDetails
) => {
  const { brand, last4, expMonth, expYear } = card;

  let value = "";

  if (billingDetails) {
    value = `${startCase(brand)} ${startCase(
      billingDetails.name
    )} ****-${last4} EXP: ${expMonth}/${expYear.toString().slice(-2)}`;
  } else {
    value = `${startCase(brand)} ****-${last4} EXP: ${expMonth}/${expYear
      .toString()
      .slice(-2)}`;
  }

  return value;
};

/**
 * @function confirmationNumberFromRequest
 * Takes a request and trip and outputs the confirmation number.
 *
 * Note: requires trip to be more forward thinking. While right now
 * we could just take the trip at first index from request, as we add
 * multiple trips we would want to know which trip it is.
 */
export function confirmationNumberFromRequest(request?: Request, trip?: Trip) {
  if (!request) return "";

  return trip?.tripNumber
    ? `${request.orderNumber}-${trip.tripNumber}`
    : request.orderNumber;
}
