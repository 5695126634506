/**
 * @file DriverNoteBlock.tsx
 * Driver Note section of driver dispatch view
 *
 * components:
 *  DriverNoteBlock
 */
import React from "react";

import { Box, Typography } from "@mui/material";

import { grayDark } from "../../design-system/colors";
import { useLuggage } from "globals/hooks";
import { Route } from "types";

type TripNoteBlockProps = {
  note: string;
  luggage: Pick<Route, "carryOnLuggage" | "checkedLuggage" | "oversizeLuggage">;
};

function TripNoteBlock(props: TripNoteBlockProps) {
  const { note, luggage } = props;
  const { carryOnLuggage, checkedLuggage, oversizeLuggage } = luggage;

  // hooks
  const { hasLuggage } = useLuggage(luggage);

  const checkOnLuggageText = carryOnLuggage ? `${carryOnLuggage} Carry-on` : "";
  const carryOnLuggageText = checkedLuggage ? `${checkedLuggage} Checked` : "";
  const overSizeLuggageText = oversizeLuggage
    ? `${oversizeLuggage} Oversize`
    : "";
  const luggageText = `${checkOnLuggageText} ${carryOnLuggageText} ${overSizeLuggageText}`;

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h3">Trip Notes</Typography>
        <Box my={2}>
          {note ? (
            <Typography variant="body2" sx={{ whiteSpace: "break-spaces" }}>
              {note}
            </Typography>
          ) : (
            <Typography variant="body2" style={{ color: grayDark }}>
              There are no trip notes added yet
            </Typography>
          )}
        </Box>
        <Box>
          {hasLuggage && (
            <Box display="flex" flexDirection="row">
              <Typography variant="body2" sx={{ mr: 0.5 }}>
                Luggage:
              </Typography>
              <Typography variant="body2">{luggageText}</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default TripNoteBlock;
