import React from "react";
import { Control } from "react-hook-form";

import { Box, Typography } from "@mui/material";

import { RHFMoovsIncrementer } from "components/react-hook-form/RHFMoovsIncrementer";

export const MAX_GROUP_SIZE = 999;
export const MIN_GROUP_SIZE = 1;

type PassengerCountBlockProps = {
  name: string;
  control?: Control<any>;
};

function PassengerCountBlock(props: PassengerCountBlockProps) {
  const { name, control } = props;

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      mt={1}
    >
      <Typography variant="h4">Passenger Count*</Typography>
      <RHFMoovsIncrementer
        name={name}
        control={control}
        limits={[MIN_GROUP_SIZE, MAX_GROUP_SIZE]}
        ButtonProps={{ variant: "text" }}
      />
    </Box>
  );
}

export default PassengerCountBlock;
