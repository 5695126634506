import { CreateQuoteFormState } from "pages/new/steps/confirm/components/CreateQuoteForm/form/schemaValidation";
import { CreateReservationFormState } from "pages/new/steps/confirm/components/CreateReservationForm/form/schemaValidation";
import { InfoStepState } from "pages/new/steps/info/context/InfoStepFormProvider";
import { OrderType, TextRecipientEnum, Vehicle } from "types";
import { CreateRequestState } from "../initialState";

export enum CreateRequestReduxActionTypes {
  UpdateInfoPage,
  UpdateVehiclePage,
  UpdateConfirmPage,
  ReceiveBookingWidgetData,
  ReceiveDudaData,
  UpdateEstimatedDuration,
  UpdateAdditionalTripInfo,
  UpdateTripNotification,
}

const {
  UpdateInfoPage,
  UpdateVehiclePage,
  UpdateConfirmPage,
  ReceiveBookingWidgetData,
  ReceiveDudaData,
  UpdateEstimatedDuration,
  UpdateAdditionalTripInfo,
  UpdateTripNotification,
} = CreateRequestReduxActionTypes;

export type CreateRequestAction =
  | {
      type: typeof UpdateInfoPage;
      payload: InfoStepState;
    }
  | {
      type: typeof UpdateVehiclePage;
      payload: Vehicle;
    }
  | {
      type: typeof ReceiveBookingWidgetData;
      payload: {
        orderType: OrderType;
        trip: Pick<
          CreateRequestState["trip"],
          | "stops"
          | "totalGroupSize"
          | "note"
          | "tripCategory"
          | "totalDuration"
          | "useTotalDuration"
        >;
        returnTrip?: Pick<
          CreateRequestState["trip"],
          "stops" | "totalGroupSize" | "tripCategory"
        >;
      };
    }
  | {
      // deprecated 10/31/22
      type: typeof ReceiveDudaData;
      payload: Pick<
        CreateRequestState["trip"],
        "stops" | "totalGroupSize" | "note"
      >;
    }
  | {
      type: typeof UpdateAdditionalTripInfo;
      payload: Pick<
        CreateRequestState["trip"],
        | "id"
        | "totalGroupSize"
        | "note"
        | "temporaryPassenger"
        | "routes"
        | "selectedPassenger"
      >;
    }
  | {
      type: typeof UpdateConfirmPage;
      payload: CreateQuoteFormState | CreateReservationFormState;
    }
  | {
      type: typeof UpdateEstimatedDuration;
      payload: {
        trip: {
          estimatedDuration: number;
          googleDirectionsResult: {};
        };
        returnTrip?: {
          estimatedDuration: number;
          googleDirectionsResult: {};
        };
      };
    }
  | {
      type: typeof UpdateTripNotification;
      payload: {
        trip: {
          statusUpdateTextTo?: TextRecipientEnum;
        };
        returnTrip?: {
          statusUpdateTextTo?: TextRecipientEnum;
        };
      };
    };
