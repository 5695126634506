import startCase from "lodash/startCase";
import React, { useEffect, useState } from "react";
import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form";

import { Box, Typography } from "@mui/material";

import CircleAvatar from "design-system/components/CircleAvatar";
import { ReplacePassengerMenuButton } from "./components";
import AddLinkedPassengerDialog from "./components/AddLinkedPassengerDialog";
import { alabaster } from "design-system/colors";
import TemporaryPassengerDialog from "./components/TemporaryPassengerDialog";
import { formatPhoneNumber } from "utils/phoneNumberFormatter/phoneNumberFormatter";
import { AdditionalTripInfoState } from "../../form/schemaValidation";
import { useDefaultPassenger, useScreenSize } from "globals/hooks";
import { useCreateRequestContext } from "pages/new/context/useCreateRequestContext";

type PassengerDetailsCardProps = {
  setValue: UseFormSetValue<any>;
  control: Control<AdditionalTripInfoState>;
  watch: UseFormWatch<any>;
};

function PassengerDetailsCard(props: PassengerDetailsCardProps) {
  const { setValue, control, watch } = props;

  const { temporaryPassenger, contact } = watch();

  // hooks
  const [request] = useCreateRequestContext();
  const {
    selectedPassenger,
    setSelectedPassenger,
    selectedPassengerIsBookingContact,
  } = useDefaultPassenger(contact || request.trip.selectedPassenger);

  const { isMobileView } = useScreenSize();

  // state
  const [menuOpen, setMenuOpen] = useState<null | HTMLElement>(null);
  const [addLinkedPassengerDialogOpen, setAddLinkedPassengerDialogOpen] =
    useState(false);
  const [temporaryPassengerDialogOpen, setTemporaryPassengerDialogOpen] =
    useState(false);

  // effects
  useEffect(() => {
    if (selectedPassenger) {
      setValue("selectedPassenger", selectedPassenger);
    }
  }, [selectedPassenger, setValue]);

  if (!selectedPassenger) return null;

  return (
    <Box bgcolor={alabaster} p={2} mt={1} mb={2}>
      <Box mb={1.5}>
        {temporaryPassenger?.name && (
          <Typography variant="h6">Temporary Passenger</Typography>
        )}
      </Box>

      <Box display="flex" flexDirection={isMobileView ? "column" : "row"}>
        <Box
          display={isMobileView ? "flex" : "block"}
          justifyContent="space-between"
        >
          <CircleAvatar
            placeholder={[
              selectedPassenger.firstName || "",
              selectedPassenger.lastName || "",
            ]}
            size="large"
          />
          {isMobileView && (
            <ReplacePassengerMenuButton
              setAddLinkedPassengerDialogOpen={setAddLinkedPassengerDialogOpen}
              setMenuOpen={setMenuOpen}
              menuOpen={menuOpen}
              setTemporaryPassengerDialogOpen={setTemporaryPassengerDialogOpen}
              temporaryPassengerSelected={!!temporaryPassenger?.name}
              setSelectedPassenger={setSelectedPassenger}
              selectedPassengerIsBookingContact={
                selectedPassengerIsBookingContact
              }
              setValue={setValue}
            />
          )}
        </Box>

        <Box display="flex" justifyContent="space-between" width="100%">
          <Box
            ml={isMobileView ? 0 : 2}
            mt={isMobileView ? 1 : 0}
            display="flex"
            flexDirection="column"
          >
            {temporaryPassenger?.name ? (
              <>
                {/* Temporay Passenger details */}
                <Typography variant="subtitle2">
                  {`${startCase(temporaryPassenger.name)}`}
                </Typography>
                <Typography variant="caption" sx={{ marginY: 0.5 }}>
                  {
                    formatPhoneNumber(
                      temporaryPassenger.phoneFields.mobilePhone,
                      temporaryPassenger.phoneFields.phoneCountryCode,
                      temporaryPassenger.phoneFields.phoneCountryDialCode
                    )?.formatted
                  }
                </Typography>
              </>
            ) : (
              <>
                {/* Passenger Details */}
                <Typography variant="subtitle2">
                  {`${startCase(selectedPassenger.firstName)} ${startCase(
                    selectedPassenger.lastName
                  )}`}
                </Typography>
                <Typography variant="caption" sx={{ marginY: 0.5 }}>
                  {selectedPassenger.email}
                </Typography>
                <Typography variant="caption">
                  {selectedPassenger.mobilePhone}
                </Typography>
              </>
            )}
          </Box>
          <Box>
            {!isMobileView && (
              <ReplacePassengerMenuButton
                setAddLinkedPassengerDialogOpen={
                  setAddLinkedPassengerDialogOpen
                }
                setMenuOpen={setMenuOpen}
                menuOpen={menuOpen}
                setTemporaryPassengerDialogOpen={
                  setTemporaryPassengerDialogOpen
                }
                temporaryPassengerSelected={!!temporaryPassenger?.name}
                setSelectedPassenger={setSelectedPassenger}
                selectedPassengerIsBookingContact={
                  selectedPassengerIsBookingContact
                }
                setValue={setValue}
              />
            )}
          </Box>
        </Box>
      </Box>

      {/* Add Linked Passenger Dialog */}
      <AddLinkedPassengerDialog
        open={addLinkedPassengerDialogOpen}
        setAddLinkedPassengerDialogOpen={setAddLinkedPassengerDialogOpen}
        setSelectedPassenger={setSelectedPassenger}
        setMenuOpen={setMenuOpen}
        setValue={setValue}
      />

      {/* Temporary Passenger Dialog */}
      <TemporaryPassengerDialog
        open={temporaryPassengerDialogOpen}
        setTemporaryPassengerDialogOpen={setTemporaryPassengerDialogOpen}
        setMenuOpen={setMenuOpen}
        control={control}
        setValue={setValue}
      />
    </Box>
  );
}

export default PassengerDetailsCard;
