import React from "react";

type PassengerLinkIconProps = {
  color?: string;
};

const PassengerLinkIcon = (props: PassengerLinkIconProps) => {
  const { color } = props;

  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.92723 7.53725C6.15755 7.84549 6.45142 8.10052 6.7889 8.2851C7.12637 8.46969 7.49957 8.57943 7.88312 8.60692C8.26673 8.63441 8.65173 8.579 9.012 8.44446C9.37233 8.30996 9.69951 8.09939 9.97139 7.82718L11.5805 6.21648C12.069 5.71015 12.3393 5.03204 12.3332 4.32817C12.3271 3.6243 12.045 2.95099 11.5478 2.45326C11.0505 1.95553 10.3779 1.67321 9.67478 1.66709C8.97161 1.66097 8.29419 1.93156 7.7884 2.42057L6.86586 3.33867M8.07267 6.46345C7.84231 6.15516 7.54844 5.90012 7.21096 5.71556C6.87348 5.531 6.50034 5.42124 6.11673 5.39374C5.73317 5.36624 5.34818 5.42165 4.98788 5.55618C4.62757 5.69073 4.30039 5.90126 4.02852 6.17352L2.41944 7.78423C1.93093 8.29053 1.66061 8.96863 1.66673 9.67251C1.67284 10.3764 1.95488 11.0497 2.4521 11.5474C2.94933 12.0451 3.62196 12.3274 4.32512 12.3336C5.02828 12.3397 5.7057 12.0691 6.21151 11.5801L7.12868 10.662"
        stroke={color || "#195FE9"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PassengerLinkIcon;
