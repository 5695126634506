import React from "react";

import { Box, Stack, Typography } from "@mui/material";

import { useTermsAndConditions } from "pages/new/hooks";
import VehicleCancellationPolicy from "./VehicleCancellationPolicy";
import TermsAndConditionsIntro from "./TermsAndConditionsIntro";

type TermsAndConditionsBodyProps = {
  selectedVehicleIds: string[];
};

function TermsAndConditionsBody(props: TermsAndConditionsBodyProps) {
  const { selectedVehicleIds } = props;

  // hooks
  const terms = useTermsAndConditions();

  return (
    <>
      {/* Standard Terms & Conditions for all consent signature forms */}
      <TermsAndConditionsIntro />

      {/* Terms List */}
      <Stack>
        {terms?.map(({ id, name, description }) => {
          return (
            <Box display="flex" flexDirection="column" my={1} key={id}>
              <Typography variant="h4" mb={2}>
                {name}
              </Typography>
              <Typography variant="body2" style={{ whiteSpace: "pre-wrap" }}>
                {description}
              </Typography>
            </Box>
          );
        })}
      </Stack>

      {/* Cancellation Policy */}
      <VehicleCancellationPolicy selectedVehicleIds={selectedVehicleIds} />
    </>
  );
}

export default TermsAndConditionsBody;
