import React from "react";

import { Typography, Box } from "@mui/material";

import { rideStatusLabelColors } from "../../design-system/colors";
import { InProgressIcon } from "../../design-system/icons";

type TripStatusProps = {
  statusSlug: string;
  hideCircle?: boolean;
};

enum DispatchStatusEnum {
  pending = "Pending",
  confirmed = "Confirmed",
  otw = "On The Way",
  "on-location" = "On Location",
  pob = "On Board", // Not Passenger On Board because this is customer facing.
  done = "Done",
  cancelled = "Cancelled",
}

function TripStatus(props: TripStatusProps) {
  const { statusSlug, hideCircle } = props;

  const { color } = (rideStatusLabelColors as any)[statusSlug];

  return (
    <Box display="flex" flexDirection="row" flex="1" alignItems="center">
      {!hideCircle && <InProgressIcon color={color} />}
      <Box ml={!hideCircle ? 1 : 0}>
        <Typography sx={{ color }}>
          {(DispatchStatusEnum as any)[statusSlug]}
        </Typography>
      </Box>
    </Box>
  );
}

export default TripStatus;
