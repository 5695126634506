import React, { useState } from "react";
import moment from "moment";

import { MobileDateTimePicker, MobileDateTimePickerProps } from "@mui/lab";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";

import { CalendarIcon } from "../../../design-system/icons";
import MoovsDateTimePickerToobar from "../MoovsDateTimePickerToobar";
import { ConfirmTimeDialog } from "./components";

export type MoovsDateTimePickerProps = {
  onAccept: (date: any) => void;
  renderInputProps?: TextFieldProps;
  clearable?: boolean;
  name?: string;
} & Omit<
  MobileDateTimePickerProps,
  "onChange" | "renderInput" | "onClose" | "InputProps" | "clearable" | "open"
>;

function MoovsDateTimePickerWithConfirm(props: MoovsDateTimePickerProps) {
  const {
    renderInputProps = {},
    clearable = false,
    name,
    onAccept,
    ...otherProps
  } = props;

  // state
  const [openDateTimePicker, setOpenDateTimePicker] = useState(false);
  const [dateTime, setDateTime] = useState(null);
  const [openConfirmTimeDialog, setOpenConfirmTimeDialog] = useState(false);

  // event handlers
  const handleDateTimePickerChange = (dateTime) => {
    setDateTime(dateTime);
  };

  const handleDateTimePickerAccept = () => {
    if (!dateTime) {
      setOpenDateTimePicker(false);
    } else {
      // onAccept triggers onClose, thus we need to set DatePicker open when clicking OK
      setOpenDateTimePicker(true);

      const dateTimeMomentObj = moment(dateTime);
      const hour = dateTimeMomentObj.hour();
      const minute = dateTimeMomentObj.minute();

      // Open ConfirmTimeDialog if selected time is between 12 AM and 8AM
      if (
        (hour === 0 && minute >= 0) ||
        (hour === 8 && minute < 1) ||
        (hour > 0 && hour < 8)
      ) {
        setOpenConfirmTimeDialog(true);
      } else {
        onAccept(dateTime);
        setOpenDateTimePicker(false);
      }
    }
  };

  const dateTimeNow = moment(new Date());

  return (
    <>
      <MobileDateTimePicker
        {...otherProps}
        open={openDateTimePicker}
        clearable={clearable}
        onChange={handleDateTimePickerChange}
        onAccept={handleDateTimePickerAccept}
        onClose={() => {
          setOpenDateTimePicker(false);
        }}
        minDateTime={dateTimeNow}
        ToolbarComponent={MoovsDateTimePickerToobar}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CalendarIcon />
            </InputAdornment>
          ),
          style: { cursor: "pointer" },
        }}
        renderInput={(params) => (
          <TextField
            name={name}
            {...params}
            onClick={() => setOpenDateTimePicker(true)}
            variant="outlined"
            fullWidth
            inputProps={{
              ...params.inputProps,
              style: { cursor: "pointer" },
            }}
            {...renderInputProps}
          />
        )}
      />
      <ConfirmTimeDialog
        openConfirmTimeDialog={openConfirmTimeDialog}
        setOpenConfirmTimeDialog={setOpenConfirmTimeDialog}
        dateTime={dateTime}
        onAccept={onAccept}
        setOpenDateTimePicker={setOpenDateTimePicker}
      />
    </>
  );
}
export default MoovsDateTimePickerWithConfirm;
