import React from "react";

import { Link, Typography } from "@mui/material";

import CancellationPolicyDialog from "components/CancellationPolicyDialog/CancellationPolicyDialog";
import { granite } from "design-system/colors";
import { useCancellationPolicies } from "../hooks/useCancellationPolicies";

type CancellationPolicyLinkProps = {
  trips: { routes: { vehicle?: { id: string } }[] }[];
};

function CancellationPolicyLink(props: CancellationPolicyLinkProps) {
  const { trips } = props;

  // hooks
  const {
    cancellationPolicies,
    shouldRenderCancellationPolicies,
    cancellationPolicyDialogOpen,
    onCancellationPolicyClick,
    onCancellationPolicyClose,
  } = useCancellationPolicies({ trips });

  if (!shouldRenderCancellationPolicies) {
    return null;
  }

  return (
    <>
      <Typography component="span" variant="body2" style={{ color: granite }}>
        including the{" "}
        <Link
          style={{ fontWeight: 700, cursor: "pointer" }}
          onClick={onCancellationPolicyClick}
        >
          Cancellation Policy.
        </Link>
      </Typography>
      <CancellationPolicyDialog
        cancellationPolicyOpen={cancellationPolicyDialogOpen}
        onCancellationPolicyClose={onCancellationPolicyClose}
        cancellationPolicies={cancellationPolicies}
      />
    </>
  );
}

export default CancellationPolicyLink;
