import React from "react";

type WheelchairIconProps = {
  color?: string;
  size: "small";
};

const WheelchairIcon = (props: WheelchairIconProps) => {
  const { color } = props;

  // size === small
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.66675 5.33337V9.5758C5.66675 9.80083 5.76709 10.0166 5.94569 10.1758C6.1243 10.3349 6.36654 10.4243 6.61913 10.4243H9.47627C9.98145 10.4243 10.4659 10.6031 10.8231 10.9213C11.1804 11.2396 11.381 11.6712 11.381 12.1213V14.6667H12.3334M9.00008 7.33337H5.33342C4.60822 7.33337 3.89931 7.54842 3.29633 7.95132C2.69335 8.35422 2.22338 8.92687 1.94586 9.59687C1.66834 10.2669 1.59572 11.0041 1.7372 11.7154C1.87868 12.4266 2.2279 13.08 2.74069 13.5928C3.25348 14.1056 3.90682 14.4548 4.61808 14.5963C5.32935 14.7377 6.06659 14.6651 6.73659 14.3876C7.40658 14.1101 7.97924 13.6401 8.38214 13.0371C8.78503 12.4341 9.00008 11.7252 9.00008 11V10.6667M7.00008 2.66671C7.00008 3.40309 6.40313 4.00004 5.66675 4.00004C4.93037 4.00004 4.33341 3.40309 4.33341 2.66671C4.33341 1.93033 4.93037 1.33337 5.66675 1.33337C6.40313 1.33337 7.00008 1.93033 7.00008 2.66671Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WheelchairIcon;
