import React from "react";
import { Control, SetFieldValue, UseFormWatch } from "react-hook-form";

import { Card, Box } from "@mui/material";

import {
  BookingContactBlock,
  SubmitButton,
} from "components/confirm/form/components";
import { CreateQuoteFormState } from "../form/schemaValidation";
import SmsConsentMessage from "components/sms-consent/SmsConsentMessage";

type CreateQuoteFormFieldsProps = {
  control: Control<CreateQuoteFormState>;
  watch: UseFormWatch<CreateQuoteFormState>;
  formId: string;
  isSubmitting: boolean;
  authStage: string;
  setValue: SetFieldValue<CreateQuoteFormState>;
  getValues: any;
};

function CreateQuoteFormFields(props: CreateQuoteFormFieldsProps) {
  const { control, watch, formId, isSubmitting, setValue, getValues } = props;

  return (
    <Card variant="outlined">
      <Box display="flex" flexDirection="column" m={3}>
        {/* Booking Contact */}
        <Box mb={3}>
          <BookingContactBlock
            control={control}
            watch={watch}
            trackingFrom="quoteRequest"
            setValue={setValue}
            getValues={getValues}
          />
        </Box>
        {/* Submit Button */}
        <SubmitButton
          isCreateQuote
          formId={formId}
          isSubmitting={isSubmitting}
        />

        {/* SMS Consent Message */}
        <SmsConsentMessage
          actionButtonName="Send Request"
          typographySx={{ mt: 3 }}
        />
      </Box>
    </Card>
  );
}

export default CreateQuoteFormFields;
