/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const currencyConverter = {
  toCents: (amount?: number) => {
    if (!amount && amount !== 0) return amount;
    return Math.round(amount * 100);
  },
  toDollars: (amount?: number) => {
    if (!amount && amount !== 0) return amount;
    return Number((amount / 100).toFixed(2));
  },
};

export const toPercentString = (decimalValue?: number) =>
  `${(decimalValue * 100).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  })}%`;
