/**
 * This component should work for create/update
 */
import React from "react";

import { Box, Divider, Typography } from "@mui/material";

import { OrderType, RequestStage } from "types";
import { orderTypeEnumToNameMap, orderTypeToEnumMap } from "utils/enumMaps";
import {
  errorRed,
  granite,
  moovsBlue,
  moovsBlueSelected,
  successGreen,
  tintGreen,
  tintRed,
  white,
} from "../../design-system/colors";
import RoundChip from "../../design-system/components/RoundChip";
import { TripIcon } from "../../design-system/icons";
import { useScreenSize } from "../../globals/hooks";
import { calculateRangeOfTripDates } from "../../utils/helpers";
import VehicleUnavailableBanner from "./components/VehicleUnavailableBanner";

const UNCONFIRMED_SUBHEADER_TEXT = `The reservation will remain unconfirmed until the operator accepts it.\nYou’ll be notified when they reply to your reservation request.`;

export type OrderDetailsHeaderText =
  | "Quote Request"
  | "Review & Reserve"
  | "Reservation Confirmation"
  | "Reservation Request"
  | "Reservation Declined";

type OrderDetailsHeaderProps = {
  headerText: OrderDetailsHeaderText;
  stage: RequestStage | "DECLINED";
  orderTypeSlug?: OrderType["slug"];
  trips: { stops: { dateTime?: string }[] }[];
  orderNumber?: string;
  orderDetailsTopBar?: React.ReactNode;
  hasTripConflict?: boolean;
};

export default function OrderDetailsHeader(props: OrderDetailsHeaderProps) {
  const {
    headerText,
    orderNumber,
    stage,
    orderTypeSlug,
    trips,
    orderDetailsTopBar,
    hasTripConflict,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // derived state
  const isReservationRequest = stage === RequestStage.Unconfirmed;
  const { firstTripStartTimeDisplay, lastTripStartTimeDisplay } =
    calculateRangeOfTripDates(trips);

  return (
    <>
      {/* order details topbar */}
      {orderDetailsTopBar}

      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          flexDirection={isMobileView ? "column" : "row"}
          justifyContent="space-between"
          alignItems="center"
          my={2}
        >
          <Typography
            variant={isMobileView ? "h2" : "h1"}
            justifyItems="center"
          >
            {lastTripStartTimeDisplay ? (
              <>
                {`${firstTripStartTimeDisplay} -`} <br />
                {`${lastTripStartTimeDisplay}`}{" "}
              </>
            ) : (
              firstTripStartTimeDisplay
            )}
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
            {...(isMobileView && { mt: 1 })}
          >
            {/* orderNumber */}
            {orderNumber && (
              <Typography variant="overline" style={{ fontSize: 16 }} mr={2}>
                {orderNumber}
              </Typography>
            )}

            {/* request type */}
            <Box display="flex" alignItems="center" flexDirection="row">
              <TripIcon />
              {orderTypeSlug && (
                <Typography variant="body2" style={{ marginLeft: "4px" }}>
                  {/* if orderTypeSlug comes from creation steps, orderTypeToEnumMap to get enum */}
                  {orderTypeEnumToNameMap[orderTypeSlug] ||
                    orderTypeEnumToNameMap[orderTypeToEnumMap[orderTypeSlug]]}
                </Typography>
              )}
            </Box>

            {/* request stage */}
            <Box ml={2}>
              <RoundChip
                label={
                  {
                    QUOTE: "QUOTE",
                    RESERVATION: "RESERVATION",
                    UNCONFIRMED: "UNCONFIRMED",
                    DECLINED: "DECLINED",
                  }[stage]
                }
                color={
                  {
                    QUOTE: white,
                    RESERVATION: successGreen,
                    UNCONFIRMED: moovsBlue,
                    DECLINED: errorRed,
                  }[stage]
                }
                backgroundColor={
                  {
                    QUOTE: granite,
                    RESERVATION: tintGreen,
                    UNCONFIRMED: moovsBlueSelected,
                    DECLINED: tintRed,
                  }[stage]
                }
              />
            </Box>
          </Box>
        </Box>
        <Divider />
        {hasTripConflict && <VehicleUnavailableBanner />}
        <Box my={4}>
          <Typography variant="h2">{headerText}</Typography>
          {isReservationRequest && (
            <Typography
              variant="body2"
              sx={{
                mt: 1,
                ...(!isMobileView && { whiteSpace: "pre-line" }),
              }}
            >
              {UNCONFIRMED_SUBHEADER_TEXT}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
}
