/**
 * @file PassengerContactBlock.tsx
 * Passesnger Contact section of driver dispatch view
 *
 * components:
 *  PassengerContactBlock
 */

import React, { useState } from "react";

import { Box, Card, Typography } from "@mui/material";

import { grayDark, white } from "../../design-system/colors";
import { MessageIcon, PhoneIcon } from "../../design-system/icons";
import CircleButton from "../../design-system/components/CircleButton";
import CircleAvatar from "../../design-system/components/CircleAvatar";
import MoovsDialog from "../globals/MoovsDialog";
import { formatPhoneNumber } from "utils/phoneNumberFormatter/phoneNumberFormatter";

type DriverContactBlockProps = {
  name: string;
  phone: string;
  driverProfilePhoto: string;
};

function DriverContactBlock(props: DriverContactBlockProps) {
  const { name, phone, driverProfilePhoto } = props;

  // state
  const [viewPhotoDialogOpen, setViewPhotoDialogOpen] = useState(false);

  // derived state
  const phoneLinkFormat = formatPhoneNumber(phone)?.e164;

  return (
    <>
      <Box>
        <Box mb={2}>
          <Typography variant="h3">Driver Contact</Typography>
        </Box>
        {name ? (
          <Card
            variant="outlined"
            sx={{
              p: 2,
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              display="flex"
              flex={1}
              flexDirection="row"
              alignItems="center"
            >
              {!!driverProfilePhoto && (
                <>
                  <Box
                    mr={1.5}
                    onClick={() => {
                      setViewPhotoDialogOpen(true);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <CircleAvatar src={driverProfilePhoto} />{" "}
                  </Box>
                </>
              )}
              <Box ml="1px">
                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                  {name}
                </Typography>
                <Typography variant="body2">{phone}</Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row">
              <Box mr={1.5}>
                <CircleButton
                  disabled={!phone}
                  component="a"
                  href={`sms:${phoneLinkFormat}`}
                >
                  <MessageIcon color={white} />
                </CircleButton>
              </Box>
              <CircleButton
                disabled={!phone}
                component="a"
                href={`tel:${phoneLinkFormat}`}
              >
                <PhoneIcon color={white} />
              </CircleButton>
            </Box>
          </Card>
        ) : (
          <Box my={2}>
            <Typography variant="body2" style={{ color: grayDark }}>
              There is no driver added yet
            </Typography>
          </Box>
        )}
      </Box>

      <MoovsDialog
        open={viewPhotoDialogOpen}
        onClose={() => {
          setViewPhotoDialogOpen(false);
        }}
        dialogTitle="Driver Photo"
        closeButtonText="close"
        size="xs"
      >
        <img src={driverProfilePhoto} alt="driver" />
      </MoovsDialog>
    </>
  );
}

export default DriverContactBlock;
