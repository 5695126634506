import React from "react";

import { Box, Typography } from "@mui/material";

import { useOperator, useScreenSize } from "../../globals/hooks";
import mailCheck from "../../illustrations/mailCheck.svg";

function PendingQuoteMessage() {
  // hooks
  const { operator } = useOperator();
  const { isMobileView } = useScreenSize();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      {...(isMobileView && { mx: 3 })}
    >
      <Box
        display="flex"
        flexDirection={isMobileView ? "column" : "row"}
        width="100%"
        mb={2}
        {...(!isMobileView && { justifyContent: "center" })}
      >
        <img src={mailCheck} alt="Swoop" style={{ height: "48px" }} />
        <Box
          maxWidth="300px"
          {...(!isMobileView ? { ml: 3 } : { mt: 3, mb: 2 })}
        >
          <Typography variant="h3">
            {`Your quote request has been sent to ${operator.name}`}
          </Typography>
        </Box>
      </Box>

      <Typography variant="body1">
        You will receive a response with a price within 24 hours.
      </Typography>
    </Box>
  );
}

export default PendingQuoteMessage;
