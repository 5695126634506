import React from "react";

import { Grid } from "@mui/material";

import { useScreenSize } from "globals/hooks";
import { Trip } from "types";
import TripCard from "./TripCard";

type TripsListProps = {
  trips: Trip[];
};

function TripsList(props: TripsListProps) {
  const { trips } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <Grid container spacing={isMobileView ? 2 : 3}>
      {trips.map((trip) => (
        <Grid item xs={12} md={6} key={trip.id}>
          <TripCard trip={trip} />
        </Grid>
      ))}
    </Grid>
  );
}

export default TripsList;
