import { createContext, Dispatch } from "react";
import { initialRequest, CreateRequestState } from "./initialState";
// import { devInitialState } from "./initialState/devData";
import { CreateRequestAction } from "./reducer/types";
import { Vehicle } from "types";

export type CreateRequestAdditionalProps = {
  skipVehicleSelectionEnabled?: boolean;
  skipVehicleDefaultVehicle?: Vehicle;
};

type CreateRequestContextType = [
  CreateRequestState,
  Dispatch<CreateRequestAction>,
  CreateRequestAdditionalProps
];

export const CreateRequestContext = createContext<CreateRequestContextType>([
  initialRequest,
  // devInitialState,
  () => {
    console.error(
      "No CreateRequestContext provider wrapping this usage of context"
    );
  },
  {},
]);
