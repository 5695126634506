import React from "react";
import moment from "moment-timezone";

import { Box, Typography, TableCell } from "@mui/material";

import RoundChip from "design-system/components/RoundChip";
import { PickUpVariant, Stop } from "types";
import { dateFormatter } from "globals/utils/helpers";
import { pickUpVariantColors } from "design-system/colors";

type StopsCellProps = {
  stops: Stop[];
};

function StopsCell(props: StopsCellProps) {
  const { stops } = props;

  const getStopLabel = (stopIndex: number) => {
    if (stopIndex === 1) return "Pick-up";
    if (stopIndex === stops.length) return "Drop off";

    return `Stop ${stopIndex - 1}`;
  };

  return (
    <TableCell>
      <Box display="flex" flexDirection="row">
        {stops?.map((stop: Stop) => {
          return (
            <StopsCellItem
              key={stop.id}
              stop={stop}
              label={getStopLabel(stop.stopIndex)}
            />
          );
        })}
      </Box>
    </TableCell>
  );
}

export default StopsCell;

type StopsCellItemProps = {
  stop: Stop;
  label: string;
};

function StopsCellItem(props: StopsCellItemProps) {
  const {
    stop: { location, dateTime, stopIndex, pickUpVariant },
    label,
  } = props;

  const timeDisplay = dateTime
    ? stopIndex === 1
      ? dateFormatter(moment.utc(dateTime), "timestamp", {
          mode: "dateTime",
        })
      : moment.utc(dateTime).format("h:mm a")
    : "-";
  const isPickUp = stopIndex === 1;

  return (
    <Box
      display="flex"
      flex="1"
      flexDirection="row"
      justifyContent="space-between"
      minWidth={200}
    >
      <Box display="flex" flexDirection="column">
        <Box
          key={label}
          display="flex"
          flexDirection="row"
          alignItems="center"
          mr={2}
        >
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" mb={0.5}>
              <Typography
                variant="overline"
                sx={{
                  marginRight: 1,
                }}
              >
                {label}
              </Typography>
              {isPickUp && pickUpVariant && (
                <RoundChip
                  {...pickUpVariantColors[pickUpVariant]}
                  label={
                    pickUpVariant === PickUpVariant.MeetGreet
                      ? "MEET & GREET"
                      : "CURBSIDE"
                  }
                />
              )}
            </Box>
            <Typography
              style={{ fontSize: 13 }}
              sx={{
                lineHeight: "24px",
                fontSize: 16,
                fontWeight: "bold",
                height: 16,
                whiteSpace: "nowrap",
              }}
            >
              {timeDisplay}
            </Typography>
          </Box>
        </Box>
        <Box maxWidth="375px" pt={1}>
          <Typography style={{ fontSize: 13 }} variant="body2">
            {location}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
