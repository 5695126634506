import React from "react";

import { Box, Typography } from "@mui/material";

import { useOperator } from "globals/hooks";
import { calculateCreateTripPricing } from "globals/hooks/useAutomatedPricing";
import { currency } from "utils/helpers";
import { VehiclePricingTooltip } from "./components";
import { BaseRateAutomation } from "types";

type VehiclePricingTextProps = {
  baseRateAutomation?: BaseRateAutomation;
  returnBaseRateAutomation?: BaseRateAutomation;
};

function VehiclePricingText(props: VehiclePricingTextProps) {
  const { baseRateAutomation, returnBaseRateAutomation } = props;

  // hooks
  const { operator } = useOperator();

  const {
    tripPricing: outboundTripPricing,
    tripTotalAmt: outboundTripTotalAmt,
  } = calculateCreateTripPricing({
    baseRateAmt: baseRateAutomation?.total,
    pricingLayout: operator.pricingLayout,
  });

  let returnTripPricing = null;
  let returnTripTotalAmt = 0;

  if (returnBaseRateAutomation) {
    const { tripPricing, tripTotalAmt } = calculateCreateTripPricing({
      baseRateAmt: returnBaseRateAutomation?.total,
      pricingLayout: operator.pricingLayout,
    });

    returnTripPricing = tripPricing;
    returnTripTotalAmt = tripTotalAmt;
  }

  const orderTotalAmt = outboundTripTotalAmt + (returnTripTotalAmt || 0);

  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" flexDirection="column" alignItems="flex-end">
        <Typography variant="h2">{currency(orderTotalAmt)}</Typography>
      </Box>

      <Box ml={1}>
        <VehiclePricingTooltip
          outboundTripPricing={outboundTripPricing}
          returnTripPricing={returnTripPricing}
        />
      </Box>
    </Box>
  );
}

export default VehiclePricingText;
