import React from "react";

type ChildSeatForwardIconProps = {
  color?: string;
  size?: "small";
};

const ChildSeatForwardIcon = (props: ChildSeatForwardIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.4252 2.976C10.5477 2.68647 10.7528 2.43949 11.0147 2.2659C11.2766 2.09232 11.5838 1.99984 11.898 2H13.2034C13.3125 2.0008 13.4203 2.02386 13.5202 2.06778C13.6201 2.11169 13.71 2.17553 13.7844 2.2554C13.8588 2.33527 13.9162 2.42949 13.953 2.5323C13.9898 2.6351 14.0052 2.74434 13.9984 2.85333L13.359 12.5067C13.332 12.9123 13.1517 13.2923 12.8548 13.5697C12.5579 13.847 12.1666 14.0009 11.7605 14H4.48888C4.13773 14.0007 3.79613 13.8856 3.51684 13.6726C3.23755 13.4596 3.03611 13.1604 2.94365 12.8213L2.72305 12.0101C2.65247 11.7512 2.64808 11.4787 2.71029 11.2177C2.7725 10.9566 2.89931 10.7155 3.07905 10.5163C3.25878 10.3172 3.48567 10.1665 3.73884 10.0782C3.992 9.98979 4.2633 9.96658 4.52777 10.0107L9.25937 10.8C8.96096 8.13488 9.36283 5.43796 10.4252 2.976Z"
          stroke={color || "#565656"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6378 4.464C15.8216 4.0297 16.1291 3.65923 16.522 3.39886C16.9149 3.13848 17.3758 2.99976 17.847 3H19.8052C19.9688 3.0012 20.1305 3.0358 20.2803 3.10167C20.4302 3.16754 20.565 3.2633 20.6766 3.3831C20.7883 3.50291 20.8743 3.64423 20.9295 3.79844C20.9847 3.95265 21.0079 4.11651 20.9977 4.28L20.0385 18.76C19.9979 19.3684 19.7275 19.9385 19.2821 20.3545C18.8368 20.7706 18.2499 21.0014 17.6408 21H6.73331C6.2066 21.001 5.69419 20.8284 5.27526 20.5089C4.85632 20.1893 4.55417 19.7406 4.41547 19.232L4.08458 18.0152C3.9787 17.6268 3.97212 17.2181 4.06544 16.8265C4.15875 16.435 4.34896 16.0732 4.61857 15.7745C4.88817 15.4758 5.22851 15.2498 5.60825 15.1172C5.988 14.9847 6.39495 14.9499 6.79166 15.016L13.8891 16.2C13.4414 12.2023 14.0442 8.15695 15.6378 4.464Z"
        stroke={color || "#565656"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChildSeatForwardIcon;
