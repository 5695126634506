import React, { useEffect, useRef, useState } from "react";

import { Box, Button, CircularProgress, Typography } from "@mui/material";

import { alabaster, grayDark } from "design-system/colors";
import { useOperator } from "globals/hooks";
import { toTitleCase } from "globals/utils/string";
import { TripReview } from "types";

type ThankYouForReviewProps = {
  tripReview: TripReview;
  createTripReviewLoading: boolean;
  stars: number;
};

function ThankYouForReview(props: ThankYouForReviewProps) {
  const { tripReview, createTripReviewLoading, stars } = props;

  // derived state
  const rating = tripReview?.rating;

  // state
  const [finalStage, setFinalStage] = useState(false);
  const timeoutRef = useRef(null);

  // hooks
  const { operator } = useOperator();

  // event handlers
  const handleStopRedirect = () => {
    clearTimeout(timeoutRef.current);
    setFinalStage(true);
  };

  const handleForceRedirect = () => {
    const { reviewSiteUrlPrefix, reviewSiteId, reviewSiteUrlSuffix } =
      operator.settings;

    const writeReviewPlatformLink =
      "https://www." + reviewSiteUrlPrefix + reviewSiteId + reviewSiteUrlSuffix;

    window.open(writeReviewPlatformLink);
  };

  // effects
  // display final page when rating is present
  useEffect(() => {
    if (rating) {
      setFinalStage(true);
    }
  }, [rating, finalStage]);

  if (createTripReviewLoading && stars === 5) {
    return (
      <Box mt={3}>
        <CircularProgress size={40} />
      </Box>
    );
  }

  if (!tripReview) return null;

  return (
    <Box textAlign="center">
      {/*  REDIRECT */}
      {!finalStage && rating === 5 ? (
        <>
          <Box bgcolor={alabaster} p={4} mt={5}>
            <Typography variant="body1">
              {`You will be redirected to ${toTitleCase(
                operator?.settings?.reviewSite
              )} to make it official in a moment.`}
            </Typography>
          </Box>
          <Box mt={3}>
            <Typography variant="caption" color={grayDark} display="block">
              Change your mind?
            </Typography>
            <Button sx={{ fontSize: "12px" }} onClick={handleStopRedirect}>
              Don't Redirect
            </Button>
          </Box>
        </>
      ) : null}

      {/* THANK YOU FINAL PAGE */}
      {finalStage && rating && (
        <Box bgcolor={alabaster} p={4} mt={5}>
          <Typography variant="subtitle1">
            {rating === 5
              ? " Thank you for the excellent rating!"
              : "Thank you for the feedback."}
          </Typography>
          {rating === 5 && operator?.settings?.reviewSiteId && (
            <Button sx={{ fontSize: "16px" }} onClick={handleForceRedirect}>
              Click Here To Leave A Review
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
}

export default ThankYouForReview;
