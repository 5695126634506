import React from "react";
import first from "lodash/first";

import { TableRow } from "@mui/material";

import { Trip } from "../../../../types";
import { alabaster } from "../../../../design-system/colors";
import StopsCell from "./StopsCell";
import MapCell from "./MapCell";
import TripNumberCell from "./TripNumberCell";
import DispatchStatusCell from "./DispatchStatusCell";
import VehicleCell from "./VehicleCell";
import DriverCell from "./DriverCell";
import PassengerCell from "./PassengerCell";

type AllTripsRowProps = {
  trip: Trip;
};

function AllTripsRow(props: AllTripsRowProps) {
  const { trip } = props;

  const route = first(trip.routes);

  return (
    <TableRow
      sx={{
        "&:nth-of-type(odd)": {
          backgroundColor: alabaster,
        },
      }}
    >
      <TripNumberCell tripNumber={trip.tripNumber} />
      <DispatchStatusCell statusSlug={route.dispatchStatus} />
      <VehicleCell vehicle={route.vehicle} />
      <DriverCell driver={route.driver} />
      <PassengerCell trip={trip} />
      <StopsCell stops={trip.stops} />
      <MapCell stops={trip.stops} />
    </TableRow>
  );
}

export default AllTripsRow;
