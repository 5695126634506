import { gql } from "@apollo/client";

export const LOAD_VEHICLES_QUERY = gql`
  query loadVehicles(
    $stops: [StopInformationInputForAutomatedPricing]
    $returnTripStops: [StopInformationInputForAutomatedPricing]
    $tripType: String
  ) {
    loadVehicles(
      stops: $stops
      returnTripStops: $returnTripStops
      tripType: $tripType
    ) {
      edges {
        node {
          id
          capacity
          description
          name
          available
          licensePlate
          hasTripConflict
          baseRateAutomation {
            total
            lineItems {
              name
              description
              lineTotal
              source
            }
          }
          returnBaseRateAutomation {
            total
            lineItems {
              name
              description
              lineTotal
              source
            }
          }
          weekendHourlyCost
          weekdayHourlyCost
          weekendMinMinutes
          weekdayMinMinutes
          operator {
            id
            name
          }
          features {
            id
            name
            category
          }
          photos {
            id
            url
            photoIndex
          }
          vehicleType {
            typeName
            typeSlug
          }
          settings {
            id
            pricelessBookingEnabled
            pricelessBookingTarget
            pricelessBookingCompanies {
              id
            }
            pricelessBookingContacts {
              id
            }
            forwardFacingSeat {
              id
              type
              active
              quantity
              amt
              imageUrl
              description
            }
            rearFacingSeat {
              id
              type
              active
              quantity
              amt
              imageUrl
              description
            }
            boosterSeat {
              id
              type
              active
              quantity
              amt
              imageUrl
              description
            }
          }
        }
      }
    }
  }
`;

export const LOAD_WHITE_LABEL_VEHICLES_QUERY = gql`
  query LoadWhiteLabelVehicles(
    $totalGroupSize: String!
    $pickUpStop: BookingToolStopInput
    $dropOffStop: BookingToolStopInput
  ) {
    loadWhiteLabelVehicles(
      totalGroupSize: $totalGroupSize
      pickUpStop: $pickUpStop
      dropOffStop: $dropOffStop
    ) {
      id
      capacity
      name
      exteriorColor
      vehicleType {
        typeSlug
      }
      operator {
        id
        name
        nameSlug
        companyLogoUrl
        googleTrackingId
      }
      photos {
        id
        url
        photoIndex
      }
      features {
        id
        name
        category
      }
      settings {
        id
        forwardFacingSeat {
          id
          type
          active
          quantity
          amt
          imageUrl
          description
        }
        rearFacingSeat {
          id
          type
          active
          quantity
          amt
          imageUrl
          description
        }
        boosterSeat {
          id
          type
          active
          quantity
          amt
          imageUrl
          description
        }
      }
    }
  }
`;

export const LOAD_VEHICLE_BRA_QUERY = gql`
  query loadVehicleBRA(
    $vehicleId: ID!
    $stops: [StopInformationInputForAutomatedPricing]!
    $returnTripStops: [StopInformationInputForAutomatedPricing]
    $tripType: String!
  ) {
    loadVehicleBRA(
      vehicleId: $vehicleId
      stops: $stops
      returnTripStops: $returnTripStops
      tripType: $tripType
    ) {
      id
      capacity
      description
      name
      available
      licensePlate
      hasTripConflict
      baseRateAutomation {
        total
        lineItems {
          name
          description
          lineTotal
          source
        }
      }
      returnBaseRateAutomation {
        total
        lineItems {
          name
          description
          lineTotal
          source
        }
      }
      weekendHourlyCost
      weekdayHourlyCost
      weekendMinMinutes
      weekdayMinMinutes
      operator {
        id
        name
      }
      features {
        id
        name
        category
      }
      photos {
        id
        url
        photoIndex
      }
      vehicleType {
        typeName
        typeSlug
      }
      settings {
        id
        pricelessBookingEnabled
        pricelessBookingTarget
        pricelessBookingCompanies {
          id
        }
        pricelessBookingContacts {
          id
        }
        forwardFacingSeat {
          id
          type
          active
          quantity
          amt
          imageUrl
          description
        }
        rearFacingSeat {
          id
          type
          active
          quantity
          amt
          imageUrl
          description
        }
        boosterSeat {
          id
          type
          active
          quantity
          amt
          imageUrl
          description
        }
      }
    }
  }
`;
