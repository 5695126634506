import React, { useState } from "react";

import { Box, Button, Typography, useTheme } from "@mui/material";

import { MinusIcon, PlusIcon } from "design-system/icons";
import { useScreenSize } from "globals/hooks";
import { grayDark } from "design-system/colors";

type VehicleDescriptionProps = {
  description: string;
  hasTripConflict: boolean;
};

function VehicleDescription(props: VehicleDescriptionProps) {
  const { description, hasTripConflict } = props;

  // state
  const [descriptionExpand, setDescriptionExpand] = useState(false);

  // hooks
  const { isMobileView } = useScreenSize();
  const theme = useTheme();

  // derived state
  const colorTheme = theme.palette.primary.main;

  // event handler
  const handleDescriptionToggle = () => {
    setDescriptionExpand((prev) => !prev);
  };

  if (!description) return null;

  if (isMobileView || description.length <= 260) {
    return (
      <Box mb={3}>
        <Typography
          variant="body1"
          {...(hasTripConflict && { color: grayDark })}
        >
          {description}
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography
        variant="body1"
        {...(hasTripConflict && { color: grayDark })}
        sx={{
          overflow: "hidden",
          display: "-webkit-box",
          WebkitLineClamp: descriptionExpand ? "0" : "3",
          WebkitBoxOrient: "vertical",
        }}
      >
        {description}
      </Typography>
      <Box>
        <Button
          onClick={handleDescriptionToggle}
          sx={{ color: colorTheme, pl: 0, py: 0.3 }}
        >
          <Typography fontWeight={500} fontSize="16px">
            {descriptionExpand ? "Show Less" : "Show More"}
          </Typography>
          <Box ml={1} mt={0.5}>
            {descriptionExpand ? (
              <MinusIcon color={colorTheme} size="small" />
            ) : (
              <PlusIcon color={colorTheme} size="small" />
            )}
          </Box>
        </Button>
      </Box>
    </Box>
  );
}

export default VehicleDescription;
