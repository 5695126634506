import React, { useState } from "react";

import { Box, Typography, Button } from "@mui/material";

import emptyCardBlue from "../../illustrations/emptyCardBlue.png";
import { grayLight, black } from "../../design-system/colors";
import CardListItemDesktop from "./CardListItem.desktop";
import CardListItemMobile from "./CardListItem.mobile";
import { Contact } from "../../types";
import AddCreditCardDialog from "../globals/AddCreditCardDialog";
import {
  useAnalytics,
  useLaunchDarklyFlags,
  useScreenSize,
} from "../../globals/hooks";
import CurrentUserCard from "./CardListItem/CurrentUserCard";
import LinkedToYouCard from "./CardListItem/LinkedToYouCard";
import { LinkCardToPassengerDialog } from "./CardListItem/components";

type CardsOnFileBlockProps = { user: Contact };

function CardsOnFileBlock(props: CardsOnFileBlockProps) {
  const { user } = props;
  const { paymentMethods, stripeCustomer, passengerLinkedPaymentMethods } =
    user;

  const noCardsOnFile = paymentMethods.length === 0;
  const canAddCard = noCardsOnFile || stripeCustomer;

  // state
  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [addCreditCardDialogOpen, setAddCreditCardDialogOpen] = useState(false);
  const [linkCardToPassengerDialogOpen, setLinkCardToPassengerDialogOpen] =
    useState(false);

  // hooks
  const { track } = useAnalytics();
  const { isMobileView } = useScreenSize();
  const { enableLinkedPassenger } = useLaunchDarklyFlags();

  // event handlers
  const handleOpenAddCardDialog = () => {
    track("Button click on 'Add card'");
    setAddCreditCardDialogOpen(true);
  };

  const handlePaymentClick = (paymentMethodId) => {
    setPaymentMethodId(paymentMethodId);
  };

  return (
    <>
      <Box
        border={`1px solid ${grayLight}`}
        borderRadius="4px"
        boxShadow="0px 4px 15px rgba(0, 0, 0, 0.03)"
        p={3}
        minHeight={isMobileView ? "345px" : "381px"}
        display="flex"
        flexDirection="column"
        flex="1"
      >
        <Typography variant="h3" style={{ color: black }}>
          Cards on File
        </Typography>
        <Box justifyContent="center" alignItems="center">
          {noCardsOnFile ? (
            <>
              <Box textAlign="center" mt={isMobileView ? 6 : 8}>
                <img
                  alt="empty card illustration"
                  src={emptyCardBlue}
                  width="140px"
                  height="140px"
                />
                <Box mt={0.75}>
                  <Typography variant="body1" style={{ fontWeight: 500 }}>
                    No cards on file
                  </Typography>
                </Box>
              </Box>
            </>
          ) : (
            <Box display="flex" flexDirection="column" flex="1">
              {/* if someone else links card to current user */}
              {enableLinkedPassenger &&
                passengerLinkedPaymentMethods.map((paymentMethod) => (
                  <Box key={paymentMethod.id}>
                    <LinkedToYouCard paymentMethod={paymentMethod} />
                  </Box>
                ))}

              {/* user's own credit cards */}
              {enableLinkedPassenger
                ? paymentMethods.map((paymentMethod) => (
                    <Box key={paymentMethod.id}>
                      <CurrentUserCard
                        paymentMethod={paymentMethod}
                        setLinkCardToPassengerDialogOpen={
                          setLinkCardToPassengerDialogOpen
                        }
                        onPaymentMethodClick={handlePaymentClick}
                      />
                    </Box>
                  ))
                : paymentMethods.map((paymentMethod, i) => {
                    return isMobileView ? (
                      <Box mt={2} key={paymentMethod.id}>
                        <CardListItemMobile paymentMethod={paymentMethod} />
                      </Box>
                    ) : (
                      <Box mt={i === 0 ? 3 : 1} key={paymentMethod.id}>
                        <CardListItemDesktop paymentMethod={paymentMethod} />
                      </Box>
                    );
                  })}
            </Box>
          )}

          {canAddCard && (
            <Box mt={3} textAlign={isMobileView ? "center" : "left"}>
              <Button
                variant={noCardsOnFile ? "outlined" : "contained"}
                color="primary"
                size="large"
                onClick={handleOpenAddCardDialog}
              >
                Add Card
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      <AddCreditCardDialog
        open={addCreditCardDialogOpen}
        setAddCreditCardDialogOpen={setAddCreditCardDialogOpen}
        contactId={user?.id}
        trackingName="profile_addCard"
      />

      {enableLinkedPassenger ? (
        <LinkCardToPassengerDialog
          open={linkCardToPassengerDialogOpen}
          setLinkCardToPassengerDialogOpen={setLinkCardToPassengerDialogOpen}
          paymentMethodId={paymentMethodId}
        />
      ) : null}
    </>
  );
}

export default CardsOnFileBlock;
