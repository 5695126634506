import React from "react";
import moment from "moment";

import { Box, Typography } from "@mui/material";

import { Request, Trip } from "../../types";
import { currency } from "../../utils/helpers";
import { alabaster } from "../../design-system/colors";
import { confirmationNumberFromRequest } from "../../globals/utils/helpers";
import { currencyConverter } from "../../utils/currency";

type TripListItemMobileProps = {
  trip: Trip;
  request: Request;
};

function TripListItemMobile(props: TripListItemMobileProps) {
  const { trip, request } = props;

  const { stops, contact, totalAmount, amountDue, temporaryPassenger } = trip;

  const firstStopDateTime = moment
    .utc(stops[0].dateTime)
    .format("MM/DD/YY h:mm a");

  const totalPaid = currencyConverter.toDollars(
    currencyConverter.toCents(totalAmount) -
      currencyConverter.toCents(amountDue)
  );

  const tripContactName = temporaryPassenger?.name
    ? temporaryPassenger.name
    : `${contact.firstName} ${contact.lastName}`;

  return (
    <Box
      display="flex"
      bgcolor={alabaster}
      flexDirection="column"
      flex="1"
      p={2}
      alignItems="center"
    >
      {/* row 1 */}
      <Box display="flex" flexDirection="row" mb={1} width="100%">
        {/* date */}
        <Box display="flex" flexDirection="column" flex="1">
          <Typography variant="overline">date</Typography>
          <Typography variant="subtitle2">
            {confirmationNumberFromRequest(request, trip)}
          </Typography>
          <Typography variant="body2">{firstStopDateTime}</Typography>
          <Typography variant="body2">{tripContactName}</Typography>
        </Box>

        {/* pickup address */}
        <Box display="flex" flexDirection="column" flex="1">
          <Typography variant="overline">pick up address</Typography>
          <Typography variant="body2">{stops[0].location}</Typography>
        </Box>
      </Box>

      {/* row 2 */}
      <Box display="flex" flexDirection="row" alignSelf="stretch">
        {/* amount */}
        <Box display="flex" flexDirection="column" flex="1">
          <Typography variant="overline">amount</Typography>
          <Typography variant="body2">{currency(totalAmount)}</Typography>
        </Box>

        {/* paid */}
        <Box display="flex" flexDirection="column" flex="1">
          <Typography variant="overline">paid</Typography>
          <Typography variant="body2">{currency(totalPaid)}</Typography>
        </Box>

        {/* amountDue */}
        <Box display="flex" flexDirection="column" flex="2">
          <Typography variant="overline">amount due</Typography>
          <Typography variant="body2">{currency(amountDue)}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default TripListItemMobile;
