import React, { ChangeEvent, useEffect } from "react";
import size from "lodash/size";

import { Box, Divider, MenuItem, TextField, Typography } from "@mui/material";

import { PaymentMethod } from "types";
import { getPaymentMethodInfoString } from "globals/utils/helpers";

type PaymentMethodCardSelectProps = {
  existingContactPaymentMethods?: PaymentMethod[];
  existingCompanyPaymentMethods?: PaymentMethod[];
  onSetPaymentMethod?: (selectedPaymentMethodId: string) => void;
  selectedPaymentMethodId?: string;
};

function PaymentMethodCardSelect(props: PaymentMethodCardSelectProps) {
  const {
    existingContactPaymentMethods,
    existingCompanyPaymentMethods,
    onSetPaymentMethod,
    selectedPaymentMethodId,
  } = props;

  const hasContactCards = size(existingContactPaymentMethods);
  const hasCompanyCards = size(existingCompanyPaymentMethods);

  let contactPaymentOptions: (PaymentMethod | String)[] = hasContactCards
    ? [...existingContactPaymentMethods]
    : [];
  let companyPaymentOptions: (PaymentMethod | String)[] = hasCompanyCards
    ? [...existingCompanyPaymentMethods]
    : [];

  // only show contact label if company cards also exist
  if (hasContactCards && hasCompanyCards) {
    contactPaymentOptions.unshift("CONTACT");
    contactPaymentOptions.push("divider");
  }

  if (hasCompanyCards) {
    companyPaymentOptions.unshift("COMPANY");
  }

  useEffect(() => {
    if (!selectedPaymentMethodId) {
      onSetPaymentMethod(
        existingContactPaymentMethods[0]?.id ||
          existingCompanyPaymentMethods[0]?.id
      );
    }
  }, [
    existingContactPaymentMethods,
    existingCompanyPaymentMethods,
    onSetPaymentMethod,
    selectedPaymentMethodId,
  ]);

  // event handlers
  const handlePaymentCardChange = (event: ChangeEvent<HTMLInputElement>) => {
    const paymentMethodId = event.target.value;
    onSetPaymentMethod(paymentMethodId);
  };

  return (
    <TextField
      label="Payment Card"
      fullWidth
      variant="outlined"
      select
      value={selectedPaymentMethodId}
      onChange={handlePaymentCardChange}
    >
      {(contactPaymentOptions.concat(companyPaymentOptions) || []).map(
        (option) => {
          if (option === "divider") {
            return (
              <Box my={2} mx={1}>
                <Divider />
              </Box>
            );
          }

          if (typeof option === "string") {
            return (
              <Box m={1}>
                <Typography variant="overline">{option}</Typography>
              </Box>
            );
          }

          return (
            <MenuItem
              key={(option as PaymentMethod).id}
              value={(option as PaymentMethod).id}
            >
              {getPaymentMethodInfoString((option as PaymentMethod).card)}
            </MenuItem>
          );
        }
      )}
    </TextField>
  );
}

export default PaymentMethodCardSelect;
