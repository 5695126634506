import React from "react";

type RightSliderArrowIconProps = {
  color?: string;
};

const RightSliderArrowIcon = (props: RightSliderArrowIconProps) => {
  const { color } = props;

  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="46" height="46" rx="23" fill="white" fill-opacity="0.6" />
      <path
        d="M14 23H32M32 23L23 32M32 23L23 14"
        stroke={color || "#1E1E1E"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RightSliderArrowIcon;
