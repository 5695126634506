import React from "react";

import { TableCell, Typography } from "@mui/material";

import { Driver } from "../../../../types";
import { formatPhoneNumber } from "../../../../utils/phoneNumberFormatter/phoneNumberFormatter";

type DriverCellProps = {
  driver: Driver;
};

function DriverCell(props: DriverCellProps) {
  const { driver } = props;

  // derived state
  const driverName = `${driver?.firstName || ""} ${driver?.lastName || ""}`;
  const driverPhone = driver?.mobilePhone
    ? formatPhoneNumber(driver?.mobilePhone).formatted
    : "";

  return (
    <TableCell style={{ minWidth: 120 }}>
      {driver ? (
        <>
          <Typography
            variant="body1"
            style={{ fontWeight: "bold", fontSize: 13 }}
          >
            {driverName}
          </Typography>
          <Typography style={{ fontSize: 13 }} variant="body2">
            {driverPhone}
          </Typography>
        </>
      ) : (
        "-"
      )}
    </TableCell>
  );
}

export default DriverCell;
