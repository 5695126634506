import React from "react";

import { Box, Button, Typography } from "@mui/material";

import { errorRed } from "../../../design-system/colors";
import { OpenIcon, WarningTriangleIcon } from "../../../design-system/icons";
import { useScreenSize } from "../../../globals/hooks";

type DivertedFlightInfoProps = {
  flightNumber: string;
};

function DivertedFlightInfo(props: DivertedFlightInfoProps) {
  const { flightNumber } = props;

  const { isMobileView } = useScreenSize();

  // event handlers
  const handleViewFlightAwareLink = () => {
    window.open(
      `https://flightaware.com/live/flight/${flightNumber}`,
      "_blank"
    );
  };

  return (
    <Box display="flex" flexDirection={isMobileView ? "column" : "row"}>
      <Box
        display="flex"
        flex={1}
        justifyContent={isMobileView ? "center" : "flex-end"}
        alignItems="center"
        {...(isMobileView ? { mb: 2 } : { mr: 2 })}
      >
        <Box mt="4px" mr={1}>
          <WarningTriangleIcon size="small" color={errorRed} />
        </Box>
        <Typography
          variant="body2"
          style={{ fontWeight: 500, color: errorRed }}
        >
          Flight has been diverted
        </Typography>
      </Box>
      <Box flex={1}>
        <Button
          color="primary"
          fullWidth
          variant="outlined"
          endIcon={<OpenIcon size="small" />}
          onClick={handleViewFlightAwareLink}
        >
          View on FlightAware
        </Button>
      </Box>
    </Box>
  );
}

export default DivertedFlightInfo;
