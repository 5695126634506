/**
 * Pricing Breakdown of the trip currently being viewed
 */
import React from "react";

import { CustomerPricingType } from "../../../../../../globals/hooks/utils";
import ActiveRoundTrip from "./ActiveRoundTrip";
import ActiveSingleTripBlock from "./ActiveSingleTrip";

type ActiveTripPricingBlockProps = {
  pricing: CustomerPricingType;
};

function ActiveTripPricingBlock(props: ActiveTripPricingBlockProps) {
  const { pricing } = props;

  const isRoundTrip = !!pricing.returnTripPricing;

  return isRoundTrip ? (
    <ActiveRoundTrip activeTripPricing={pricing} />
  ) : (
    <ActiveSingleTripBlock activeTripPricing={pricing} />
  );
}

export default ActiveTripPricingBlock;
