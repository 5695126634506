/**
 * @file graphql/stripe.graphql.ts
 * queries for stripe.
 *
 */
import { gql } from "@apollo/client";

export const LOAD_STRIPE_PAYMENT_RECEIPT_LINK = gql`
  query stripePaymentReceiptLink($paymentId: ID!) {
    stripePaymentReceiptLink(paymentId: $paymentId)
  }
`;
