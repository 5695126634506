import { OrderType, TripCategory } from "types";

const hourlyOptions = [
  0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5,
  10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17,
  17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 21.5, 22, 22.5, 23, 23.5, 24,
];

const allTripTypes: TripCategory[] = [
  TripCategory.Hourly,
  TripCategory.OneWay,
  TripCategory.RoundTrip,
];

const allOrderTypes: OrderType[] = [
  {
    __typename: "OrderType",
    id: "1",
    category: "Airport",
    name: "Airport",
    slug: "airport",
  },
  {
    __typename: "OrderType",
    id: "2",
    category: "Airport",
    name: "Airport Drop Off",
    slug: "airport-drop-off",
  },
  {
    __typename: "OrderType",
    id: "3",
    category: "Airport",
    name: "Airport Pick Up",
    slug: "airport-pick-up",
  },
  {
    __typename: "OrderType",
    id: "4",
    category: "Birthday",
    name: "21st Birthday",
    slug: "birthday-21",
  },
  {
    __typename: "OrderType",
    id: "5",
    category: "Birthday",
    name: "Bar/Bat Mitzvah",
    slug: "bar-bat-mitzvah",
  },
  {
    __typename: "OrderType",
    id: "6",
    category: "Birthday",
    name: "Birthday",
    slug: "birthday",
  },
  {
    __typename: "OrderType",
    id: "7",
    category: "Birthday",
    name: "Kids Birthday",
    slug: "kids-birthday",
  },
  {
    __typename: "OrderType",
    id: "8",
    category: "Birthday",
    name: "Quinceanera",
    slug: "quinceanera",
  },
  {
    __typename: "OrderType",
    id: "9",
    category: "Birthday",
    name: "Sweet 16",
    slug: "sweet-16",
  },
  {
    __typename: "OrderType",
    id: "10",
    category: "Corporate",
    name: "Business Trip",
    slug: "business-trip",
  },
  {
    __typename: "OrderType",
    id: "11",
    category: "Corporate",
    name: "Corporate",
    slug: "corporate",
  },
  {
    __typename: "OrderType",
    id: "12",
    category: "Corporate",
    name: "Corporate Event",
    slug: "corporate-event",
  },
  {
    __typename: "OrderType",
    id: "13",
    category: "Leisure",
    name: "Bar",
    slug: "bar",
  },
  {
    __typename: "OrderType",
    id: "14",
    category: "Leisure",
    name: "Brew Tour",
    slug: "brew-tour",
  },
  {
    __typename: "OrderType",
    id: "15",
    category: "Leisure",
    name: "Concert",
    slug: "concert",
  },
  {
    __typename: "OrderType",
    id: "16",
    category: "Leisure",
    name: "Leisure",
    slug: "leisure",
  },
  {
    __typename: "OrderType",
    id: "17",
    category: "Leisure",
    name: "Night Out",
    slug: "night-out",
  },
  {
    __typename: "OrderType",
    id: "18",
    category: "Leisure",
    name: "Wine Tour",
    slug: "wine-tour",
  },
  {
    __typename: "OrderType",
    id: "19",
    category: "Other",
    name: "Family Reunion",
    slug: "family-reunion",
  },
  {
    __typename: "OrderType",
    id: "20",
    category: "Other",
    name: "Funeral",
    slug: "funeral",
  },
  {
    __typename: "OrderType",
    id: "21",
    category: "Other",
    name: "Holiday",
    slug: "holiday",
  },
  {
    __typename: "OrderType",
    id: "22",
    category: "Other",
    name: "Medical",
    slug: "medical",
  },
  {
    __typename: "OrderType",
    id: "23",
    category: "Other",
    name: "Special Occasion",
    slug: "special-occasion",
  },
  {
    __typename: "OrderType",
    id: "24",
    category: "School",
    name: "Field Trip",
    slug: "field-trip",
  },
  {
    __typename: "OrderType",
    id: "25",
    category: "School",
    name: "Graduation",
    slug: "graduation",
  },
  {
    __typename: "OrderType",
    id: "26",
    category: "School",
    name: "Prom/Homecoming",
    slug: "prom-homecoming",
  },
  {
    __typename: "OrderType",
    id: "27",
    category: "School",
    name: "School",
    slug: "School",
  },
  {
    __typename: "OrderType",
    id: "28",
    category: "School",
    name: "School Fundraiser",
    slug: "school-fundraiser",
  },
  {
    __typename: "OrderType",
    id: "29",
    category: "Sporting Event",
    name: "Baseball",
    slug: "baseball",
  },
  {
    __typename: "OrderType",
    id: "30",
    category: "Sporting Event",
    name: "Basketball",
    slug: "basketball",
  },
  {
    __typename: "OrderType",
    id: "31",
    category: "Sporting Event",
    name: "Football",
    slug: "football",
  },
  {
    __typename: "OrderType",
    id: "32",
    category: "Sporting Event",
    name: "Golf",
    slug: "golf",
  },
  {
    __typename: "OrderType",
    id: "33",
    category: "Sporting Event",
    name: "Hockey",
    slug: "hockey",
  },
  {
    __typename: "OrderType",
    id: "34",
    category: "Sporting Event",
    name: "Sporting Event",
    slug: "sporting-event",
  },
  {
    __typename: "OrderType",
    id: "35",
    category: "Wedding",
    name: "Bachelor/Bachelorette",
    slug: "bachelor-bachelorette",
  },
  {
    __typename: "OrderType",
    id: "36",
    category: "Wedding",
    name: "Bridal Party",
    slug: "bridal-party",
  },
  {
    __typename: "OrderType",
    id: "37",
    category: "Wedding",
    name: "Bride/Groom",
    slug: "bride-groom",
  },
  {
    __typename: "OrderType",
    id: "38",
    category: "Wedding",
    name: "Wedding",
    slug: "wedding",
  },
  {
    __typename: "OrderType",
    id: "39",
    category: "Anniversary",
    name: "Anniversary",
    slug: "anniversary",
  },
  {
    __typename: "OrderType",
    id: "40",
    category: "Train",
    name: "Train",
    slug: "train",
  },
  {
    __typename: "OrderType",
    id: "41",
    category: "Seaport",
    name: "Seaport",
    slug: "seaport",
  },
  {
    __typename: "OrderType",
    id: "42",
    category: "Other",
    name: "Other",
    slug: "other",
  },
  {
    __typename: "OrderType",
    id: "43",
    category: "Other",
    name: "Point-to-Point",
    slug: "point-to-point",
  },
  {
    __typename: "OrderType",
    id: "44",
    category: "Leisure",
    name: "Day Tour",
    slug: "day-tour",
  },
  {
    __typename: "OrderType",
    id: "45",
    category: "Corporate",
    name: "Personal Trip",
    slug: "personal-trip",
  },
];

export { hourlyOptions, allTripTypes, allOrderTypes };
