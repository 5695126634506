import { gql } from "@apollo/client";

export const UPDATE_BOOKING_CONTACT_AS_RECIPIENT_FOR_TRIP_NOTIFICATIONS_MUTATION = gql`
  mutation UpdateTripTextRecipients($input: UpdateTripTextRecipientsInput!) {
    updateTripTextRecipients(input: $input) {
      trip {
        id
        statusUpdateTextTo
        reminderTextTo
      }
    }
  }
`;
