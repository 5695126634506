import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import startCase from "lodash/startCase";

import { Box } from "@mui/material";

import MoovsDialog from "components/globals/MoovsDialog";
import { RHFTextField } from "components/react-hook-form/text-field/RHFTextField";
import RHFInternationalPhoneInput from "components/react-hook-form/RHFInternationalPhoneInput";
import {
  CREATE_CONTACT_AND_LINK_PASSENGER_MUTATION,
  LOAD_ME_QUERY,
} from "globals/graphql";
import { useAnalytics, useScreenSize, useSnackbar } from "globals/hooks";
import { createLinkedPassengerDefaultValues } from "./form/defaultValues";
import {
  CreateLinkedPassengerState,
  createLinkedPassengerErrorSchema,
} from "./form/schemaValidation";

export type CreateLinkedPassengerDialogProps = {
  open: boolean;
  setLinkedPassengerDialogOpen: Dispatch<SetStateAction<boolean>>;
  onCreateLinkedPassenger?: React.Dispatch<any>;
};

function CreateLinkedPassengerDialog(props: CreateLinkedPassengerDialogProps) {
  const { open, setLinkedPassengerDialogOpen, onCreateLinkedPassenger } = props;

  // hooks
  const snackbar = useSnackbar();
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();

  // form
  const formId = `create-linked-passenger-form`;
  const methods = useForm<CreateLinkedPassengerState>({
    defaultValues: createLinkedPassengerDefaultValues,
    mode: "onSubmit",
    resolver: yupResolver(createLinkedPassengerErrorSchema),
  });

  // mutation
  const [createContactAndLinkPassenger, { loading }] = useMutation(
    CREATE_CONTACT_AND_LINK_PASSENGER_MUTATION,
    {
      onCompleted: (data) => {
        snackbar.success("Successfully created passenger!");
        setLinkedPassengerDialogOpen(false);
        track("linkPassenger_newAdded");

        const {
          createContactAndLinkPassenger: { contact },
        } = data;

        // used for LinkCardToPassenger Dialog
        onCreateLinkedPassenger &&
          onCreateLinkedPassenger({
            id: contact.id,
            email: contact.email,
            mobilePhone: contact.mobilePhone,
            firstName: startCase(contact.firstName),
            lastName: startCase(contact.lastName),
          });
      },
      onError() {
        snackbar.error("Error creating passenger");
      },
      refetchQueries: [{ query: LOAD_ME_QUERY }],
    }
  );

  // event handlers
  const handleClose = () => {
    setLinkedPassengerDialogOpen(false);
  };

  const handleCreateLinkedPassenger = async (
    createLinkedPassengerState: CreateLinkedPassengerState
  ) => {
    const { firstName, lastName, email, phoneFields } =
      createLinkedPassengerState;

    await createContactAndLinkPassenger({
      variables: {
        input: {
          firstName,
          lastName,
          email,
          ...phoneFields,
        },
      },
    });
  };

  // effects
  useEffect(() => {
    if (open) {
      methods.reset();
    }
  }, [methods, open]);

  return (
    <MoovsDialog
      hideTopBorder
      size={isMobileView ? "md" : "sm"}
      dialogTitle="Add New Passenger"
      acceptButtonText="Add New Passenger"
      closeButtonText="Cancel"
      open={open}
      onClose={handleClose}
      acceptDisabled={loading || methods.formState.isSubmitting}
      submitFormId={formId}
    >
      <FormProvider {...methods}>
        <form
          id={formId}
          onSubmit={(e) => {
            e.stopPropagation();
            methods.handleSubmit(handleCreateLinkedPassenger)(e);
          }}
        >
          <Box mr={0.5} mb={isMobileView ? 0 : 1}>
            <Box display="flex">
              <Box flex={1} mr={0.5}>
                <RHFTextField
                  fullWidth
                  variant="outlined"
                  label="First Name"
                  name="firstName"
                />
              </Box>
              <Box flex={1}>
                <RHFTextField
                  fullWidth
                  variant="outlined"
                  label="Last Name"
                  name="lastName"
                />
              </Box>
            </Box>

            <Box my={1.5}>
              <RHFTextField
                fullWidth
                variant="outlined"
                label="Email"
                name="email"
              />
            </Box>

            <RHFInternationalPhoneInput
              name="phoneFields"
              label="Mobile Phone"
            />
          </Box>
        </form>
      </FormProvider>
    </MoovsDialog>
  );
}

export default CreateLinkedPassengerDialog;
