import React from "react";

type USBIconProps = {
  color?: string;
  size?: "small";
};

const USBIcon = (props: USBIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.25 8.25H6.75M17.25 8.25V1.5C17.25 1.30109 17.171 1.11032 17.0303 0.96967C16.8897 0.829018 16.6989 0.75 16.5 0.75H7.5C7.30109 0.75 7.11032 0.829018 6.96967 0.96967C6.82902 1.11032 6.75 1.30109 6.75 1.5V8.25M17.25 8.25C17.6478 8.25 18.0294 8.40804 18.3107 8.68934C18.592 8.97064 18.75 9.35218 18.75 9.75V15.75C18.75 16.9435 18.2759 18.0881 17.432 18.932C16.5881 19.7759 15.4435 20.25 14.25 20.25M6.75 8.25C6.35218 8.25 5.97064 8.40804 5.68934 8.68934C5.40804 8.97064 5.25 9.35218 5.25 9.75V15.75C5.25 16.9435 5.72411 18.0881 6.56802 18.932C7.41193 19.7759 8.55653 20.25 9.75 20.25M9.75 3.75V5.25M14.25 3.75V5.25M14.25 23.25V20.25M14.25 20.25H9.75M9.75 20.25V23.25"
          stroke={color || "#B3B3B3"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.25 8.25H6.75M17.25 8.25V1.5C17.25 1.30109 17.171 1.11032 17.0303 0.96967C16.8897 0.829018 16.6989 0.75 16.5 0.75H7.5C7.30109 0.75 7.11032 0.829018 6.96967 0.96967C6.82902 1.11032 6.75 1.30109 6.75 1.5V8.25M17.25 8.25C17.6478 8.25 18.0294 8.40804 18.3107 8.68934C18.592 8.97064 18.75 9.35218 18.75 9.75V15.75C18.75 16.9435 18.2759 18.0881 17.432 18.932C16.5881 19.7759 15.4435 20.25 14.25 20.25M6.75 8.25C6.35218 8.25 5.97064 8.40804 5.68934 8.68934C5.40804 8.97064 5.25 9.35218 5.25 9.75V15.75C5.25 16.9435 5.72411 18.0881 6.56802 18.932C7.41193 19.7759 8.55653 20.25 9.75 20.25M9.75 3.75V5.25M14.25 3.75V5.25M14.25 23.25V20.25M14.25 20.25H9.75M9.75 20.25V23.25"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default USBIcon;
