import React from "react";

import { Box, Typography } from "@mui/material";

import { useScreenSize } from "../../globals/hooks";
import { Payment, PaymentMethodEnum } from "../../types";
import { CreditCardIcon } from "../../design-system/icons";
import { toTitleCase } from "../../globals/utils/string";

type PaymentMethodSummaryProps = {
  paymentInfo: Payment;
};

function PaymentMethodSummary(props: PaymentMethodSummaryProps) {
  const { paymentInfo } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  const { method: methodOfPayment, paymentMethod } = paymentInfo;

  return (
    <Box
      flex={isMobileView ? 0 : 0.5}
      flexDirection={isMobileView ? "row" : "column"}
    >
      {methodOfPayment === PaymentMethodEnum.Card ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems={isMobileView ? "flex-start" : "flex-end"}
        >
          <Typography variant="overline">Payment Card</Typography>
          <Box mt={0.25} display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <CreditCardIcon brand={paymentMethod.card.brand} />
              <Box ml={2.25}>
                <Typography variant="body2">
                  {`**** - ${paymentMethod.card.last4}`}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems={isMobileView ? "flex-end" : "flex-start"}
        >
          <Typography variant="overline">Payment Method</Typography>
          <Typography variant="body2">
            {toTitleCase(methodOfPayment)}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default PaymentMethodSummary;
