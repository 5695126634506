/**
 * @file useUnmountUpdate.tsx
 *
 * Handles updating global state when navigating away from this form.
 * Only useCase right now is so that clicking on previous button and
 * returning still has the form state.
 *
 * Alternatively could update on every change, but this is more performant.
 */
import { useCallback, useEffect, useRef } from "react";
import { UseFormWatch } from "react-hook-form";

import { CreateQuoteFormState } from "pages/new/steps/confirm/components/CreateQuoteForm/form/schemaValidation";
import { CreateReservationFormState } from "pages/new/steps/confirm/components/CreateReservationForm/form/schemaValidation";
import { CreateRequestReduxActionTypes } from "pages/new/context/reducer/types";
import { useCreateRequestContext } from "pages/new/context/useCreateRequestContext";

type UseUnmountUpdateParams = {
  watch: UseFormWatch<any>;
};

export const useUnmountUpdate = (params: UseUnmountUpdateParams) => {
  const { watch } = params;

  // hooks
  const state = watch();
  const [_, dispatch] = useCreateRequestContext();

  // event handlers
  const handleUnmount = useCallback(
    (createQuoteFormState: CreateQuoteFormState) => {
      dispatch({
        type: CreateRequestReduxActionTypes.UpdateConfirmPage,
        payload: createQuoteFormState,
      });
    },
    [dispatch]
  );

  // use ref so that it only sends on updates
  const formStateRef = useRef<
    CreateQuoteFormState | CreateReservationFormState
  >();

  useEffect(() => {
    formStateRef.current = state;
  }, [state]);

  // on unmount, update parent state
  useEffect(() => {
    return () => {
      handleUnmount(formStateRef.current);
    };
  }, [handleUnmount]);
};
