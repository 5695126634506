import React from "react";

import { Box, Divider, Typography } from "@mui/material";
import { grayDark } from "../../../../../../design-system/colors";

type HeaderConfirmationNumberProps = {
  confirmationNumber?: string;
};

function HeaderConfirmationNumber(props: HeaderConfirmationNumberProps) {
  const { confirmationNumber } = props;

  if (!confirmationNumber) return null;

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        flexWrap="wrap"
      >
        <Typography sx={{ color: grayDark }} noWrap>
          Conf No:&nbsp;
        </Typography>
        <Typography noWrap>{confirmationNumber}</Typography>
      </Box>
      <Divider
        flexItem
        orientation="vertical"
        style={{ marginLeft: "16px", marginRight: "16px" }}
      />
    </>
  );
}

export default HeaderConfirmationNumber;
