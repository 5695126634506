import React from "react";

import { Box, IconButton, Tooltip } from "@mui/material";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined";
import { granite } from "design-system/colors";

const ACTIVE_TOOLTIP_TEXT =
  "You'll receive status update notifications for this trip";
const INACTIVE_TOOLTIP_TEXT =
  "You won’t receive status update notifications for this trip";

type TripBellProps = {
  handleToggleTripNotification: () => void;
  isNotificationActive: boolean;
};
function TripBell(props: TripBellProps) {
  const { isNotificationActive, handleToggleTripNotification } = props;
  return (
    <Box display="flex" mr={1}>
      <Tooltip
        title={
          isNotificationActive ? ACTIVE_TOOLTIP_TEXT : INACTIVE_TOOLTIP_TEXT
        }
        placement="top"
        followCursor
      >
        <IconButton
          onClick={handleToggleTripNotification}
          aria-label="trip notification"
        >
          {isNotificationActive ? (
            <NotificationsActiveOutlinedIcon htmlColor={granite} />
          ) : (
            <NotificationsOffOutlinedIcon htmlColor={granite} />
          )}
        </IconButton>
      </Tooltip>
    </Box>
  );
}

export default TripBell;
