import React from "react";

import { Box, Typography } from "@mui/material";

import { black, grayLight } from "design-system/colors";
import TruncatedChip from "components/globals/TruncatedChip";

type TripNotesProps = {
  note: string;
  childSeats?: {
    boosterSeatQuantity: number;
    forwardFacingSeatQuantity: number;
    rearFacingSeatQuantity: number;
  };
};

const SEAT_NAME_MAP = {
  boosterSeatQuantity: "Booster Seat",
  forwardFacingSeatQuantity: "Forward-Facing Seat",
  rearFacingSeatQuantity: "Rear-Facing Seat",
};

function TripNotes(props: TripNotesProps) {
  const { note, childSeats } = props;

  // derived state
  const childSeatKeys = Object.keys(childSeats || {});

  return (
    <Box width="100%" mb={2}>
      <Typography variant="overline">Trip Note</Typography>
      <Typography mt={0.5} variant="body2" sx={{ whiteSpace: "break-spaces" }}>
        {note || "-"}
      </Typography>
      {/* Child Seat Chips */}
      {childSeatKeys.map((key) => {
        const qty = childSeats[key];
        return qty ? (
          <TruncatedChip
            key={key}
            label={
              qty > 1 ? `${qty} X ${SEAT_NAME_MAP[key]}` : SEAT_NAME_MAP[key]
            }
            maxWidth="148px"
            textColor={black}
            backgroundColor={grayLight}
          />
        ) : null;
      })}
    </Box>
  );
}

export default TripNotes;
