import React from "react";

import { Typography, Button, Box } from "@mui/material";
import { black, granite } from "design-system/colors";

type EmptyListProps = {
  title: string;
  copy?: string;
  ctaCopy?: string;
  imageUrl?: string;
  onCreateClick?: () => void;
};

function EmptyList(props: EmptyListProps) {
  const { title, copy, ctaCopy, onCreateClick, imageUrl } = props;

  const image = imageUrl ? (
    <img
      src={imageUrl}
      alt=""
      style={{
        width: 140,
        height: 140,
        marginBottom: "24px",
      }}
    />
  ) : null;

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="0.8"
      alignItems="center"
      justifyContent="center"
    >
      {image}
      <Typography
        variant="h2"
        sx={{ color: black, fontWeight: 500, textAlign: "center" }}
      >
        {title}
      </Typography>
      {copy && (
        <Box my={1} maxWidth={410} textAlign="center">
          <Typography variant="body1" sx={{ color: granite }}>
            {copy}
          </Typography>
        </Box>
      )}
      {ctaCopy && onCreateClick && (
        <Button
          variant="contained"
          color="primary"
          onClick={onCreateClick}
          sx={{ mt: 2 }}
        >
          {ctaCopy}
        </Button>
      )}
    </Box>
  );
}

export default EmptyList;
