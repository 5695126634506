/**
 * @file useWhiteLabelRequest
 *
 * Creates a request for white label - quote
 */

import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import first from "lodash/first";

import {
  CREATE_WHITE_LABEL_QUOTE,
  UPDATE_CONTACT_MUTATION,
} from "globals/graphql";
import {
  useAnalytics,
  useAuth,
  useGoogleTracking,
  useOperator,
  useSnackbar,
} from "globals/hooks";
import { Request } from "types";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { useCreateRequestContext } from "../context/useCreateRequestContext";
import { sanitizeRequestForCreateMutation } from "components/confirm/hooks/helpers";
import { CreateQuoteFormState } from "../steps/confirm/components/CreateQuoteForm/form/schemaValidation";
import { mergeCreateQuoteFormState } from "./useCreateQuote";
import { getIsNetflixLogin } from "utils/auth/isNetflixLogin";
import { decodeGlobalId } from "../../../utils/helpers";

function useCreateWhiteLabelQuote() {
  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const { getGclid, googleTrack } = useGoogleTracking();
  const { track } = useAnalytics();
  const [request] = useCreateRequestContext();
  const {
    operator: {
      settings: { tripStatusUpdateTextTo },
    },
  } = useOperator();
  const { loginData } = useAuth();
  const isNetflixLogin = getIsNetflixLogin();

  // state
  const [isCreatingWhiteLabel, setIsCreatingWhiteLabel] = useState(false);

  // derived state
  const recipientOperator = first(request.trip.routes).vehicle.operator;
  const recipientOperatorSlug = recipientOperator.nameSlug;

  // mutations
  const [createWhiteLabel] = useMutation(CREATE_WHITE_LABEL_QUOTE, {
    onCompleted(data) {
      track("Create White Label Quote");
      googleTrack("moovs_create_quote");
      setIsCreatingWhiteLabel(false);

      const request: Request = data.createWhiteLabelQuote.request;
      history.push(
        `/${recipientOperatorSlug}/order/${request.id}/?successDialog=true`
      );
    },
    onError(error) {
      setIsCreatingWhiteLabel(false);
      const errorMessage = getErrorMessage(error) || "Error creating request";

      snackbar.error(errorMessage);
      console.log(error);
    },
  });

  const [updateContact] = useMutation(UPDATE_CONTACT_MUTATION, {
    onCompleted: () => {
      snackbar.success("Successfully updated contact!");
    },
    onError: (error) => {
      snackbar.error(error.message || "Error updating passenger");
    },
  });

  const createWhiteLabelQuote = async (
    createQuoteFormState: CreateQuoteFormState
  ) => {
    setIsCreatingWhiteLabel(true);

    const updatedRequest = mergeCreateQuoteFormState(
      request,
      createQuoteFormState
    );

    const createRequestInput = sanitizeRequestForCreateMutation({
      request: updatedRequest,
      tripStatusUpdateTextTo,
    });

    if (isNetflixLogin && createQuoteFormState.bookingContact && loginData) {
      await updateContact({
        variables: {
          input: {
            id: loginData.contactId
              ? loginData.contactId
              : decodeGlobalId(loginData.id),
            firstName: createQuoteFormState.bookingContact.firstName,
            lastName: createQuoteFormState.bookingContact.lastName,
            mobilePhone:
              createQuoteFormState.bookingContact.phoneFields.mobilePhone,
            phoneCountryCode:
              createQuoteFormState.bookingContact.phoneFields.phoneCountryCode,
            phoneCountryDialCode:
              createQuoteFormState.bookingContact.phoneFields
                .phoneCountryDialCode,
            phoneCountryName:
              createQuoteFormState.bookingContact.phoneFields.phoneCountryName,
            phoneCountryFormat:
              createQuoteFormState.bookingContact.phoneFields
                .phoneCountryFormat,
          },
        },
      });
    }

    await createWhiteLabel({
      variables: {
        input: {
          ...createRequestInput,
          recipientOperatorId: recipientOperator.id,
          gclid: getGclid(),
        },
      },
    });
  };

  return {
    createWhiteLabelQuote,
    isCreatingWhiteLabel,
  };
}

export { useCreateWhiteLabelQuote };
