import React from "react";

import { Box, Typography } from "@mui/material";

import { successGreen } from "design-system/colors";
import CircleGreenCheckIcon from "design-system/icons/CircleGreenCheckIcon";

function YourCardWillNotBeChargedBlock() {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <CircleGreenCheckIcon size="small" />
      <Box ml={1}>
        <Typography
          variant="body2"
          style={{
            wordWrap: "break-word",
            color: successGreen,
          }}
        >
          Your card will not be charged at this time
        </Typography>
      </Box>
    </Box>
  );
}

export default YourCardWillNotBeChargedBlock;
