import React from "react";

import { Box, Typography } from "@mui/material";
import { TemporaryPassenger } from "types";
import { formatPhoneNumber } from "utils/phoneNumberFormatter/phoneNumberFormatter";

type PassengerContactProps = {
  temporaryPassenger?: TemporaryPassenger;
  passenger: {
    firstName: string;
    lastName: string;
    mobilePhone: string;
    phoneCountryCode: string;
    phoneCountryDialCode: string;
  };
};

function PassengerContact(props: PassengerContactProps) {
  const { temporaryPassenger } = props;

  const passenger = temporaryPassenger?.name
    ? temporaryPassenger
    : props.passenger;

  const name =
    "name" in passenger
      ? passenger.name
      : `${props.passenger.firstName} ${props.passenger.lastName}`;

  const formattedPhoneNumber = formatPhoneNumber(
    passenger.mobilePhone,
    passenger.phoneCountryCode,
    passenger.phoneCountryDialCode
  )?.formatted;

  return (
    <Box width="100%" mb={2}>
      <Typography variant="overline">Passenger Contact</Typography>
      <Typography mt={0.5} variant={name ? "subtitle2" : "body2"}>
        {name || "-"}
      </Typography>
      <Typography variant="body2">{formattedPhoneNumber || ""}</Typography>
    </Box>
  );
}

export default PassengerContact;
