import React from "react";

const UnavailableVehicleIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_49_63376)">
        <path
          d="M18.4 14.4H20V11.3538C20 11.1335 19.9157 10.9222 19.7657 10.7664C19.6157 10.6106 19.4122 10.5231 19.2 10.5231H4.8C4.58783 10.5231 4.38434 10.6106 4.23431 10.7664C4.08429 10.9222 4 11.1335 4 11.3538V14.4H5.6M18.4 14.4C18.4 15.2837 17.6837 16 16.8 16C15.9163 16 15.2 15.2837 15.2 14.4M18.4 14.4C18.4 13.5163 17.6837 12.8 16.8 12.8C15.9163 12.8 15.2 13.5163 15.2 14.4M15.2 14.4H8.8M5.6 14.4C5.6 15.2837 6.31634 16 7.2 16C8.08366 16 8.8 15.2837 8.8 14.4M5.6 14.4C5.6 13.5163 6.31634 12.8 7.2 12.8C8.08366 12.8 8.8 13.5163 8.8 14.4M16.2667 10.5231L14.8 8.55385C14.7209 8.3945 14.6029 8.25949 14.4579 8.1623C14.3128 8.06511 14.1457 8.00915 13.9733 8H8.08C7.92706 8.01876 7.78186 8.08014 7.6598 8.17765C7.53773 8.27516 7.44335 8.40515 7.38667 8.55385L6.05333 10.5231H16.2667Z"
          stroke="#D63F49"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.777 19.777L4.22115 4.22112M12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1Z"
          stroke="#D63F49"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_49_63376">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UnavailableVehicleIcon;
