import React from "react";

import { Box, Typography, Link } from "@mui/material";

import { grayDark, grayLight } from "../../design-system/colors";
import { HelpIcon } from "../../design-system/icons";
import { useOperator } from "../../globals/hooks";
import { formatPhoneNumber } from "utils/phoneNumberFormatter/phoneNumberFormatter";

function ContactUs() {
  const {
    operator: { voicePhoneNumber, generalEmail, twilioPhoneNumber },
  } = useOperator();

  const formattedTwilioNumber = formatPhoneNumber(
    twilioPhoneNumber?.phoneNumber
  )?.formatted;

  return (
    <Box
      display="flex"
      flexDirection="row"
      p={2}
      border={`1px solid ${grayLight}`}
      borderRadius="4px"
      width="100%"
      flexGrow={0}
    >
      <Box display="flex" alignItems="center" marginRight={2}>
        <HelpIcon size="large" color={grayDark} />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography variant="overline">Having trouble?</Typography>

        {/* phone number */}
        {(voicePhoneNumber || formattedTwilioNumber) && (
          <Box display="flex" flexDirection="row" mt={0.25} mb={0.5}>
            <Typography
              variant="body2"
              style={{
                marginRight: "4px",
                fontWeight: 500,
              }}
            >
              Call or Text us:
            </Typography>
            <Link
              variant="body2"
              href={`tel:${voicePhoneNumber || formattedTwilioNumber}`}
            >
              {voicePhoneNumber || formattedTwilioNumber}
            </Link>
          </Box>
        )}

        {/* email */}
        {generalEmail && (
          <Box display="flex" flexDirection="row">
            <Typography
              variant="body2"
              style={{
                marginRight: "4px",
                fontWeight: 500,
              }}
            >
              Email us:
            </Typography>
            <Link
              target="_blank"
              variant="body2"
              href={`mailto:${generalEmail}`}
            >
              {generalEmail}
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ContactUs;
