import React from "react";
import uniqBy from "lodash/uniqBy";
import map from "lodash/map";

import { Box, Typography } from "@mui/material";

import { grayDark, alabaster } from "design-system/colors";
import { PaymentRefund, SubPayment } from "types";
import PaymentListItem from "./PaymentListItem.desktop";

type PaymentsListProps = {
  subPayments: SubPayment[];
  refunds: PaymentRefund[];
};

function PaymentsList(props: PaymentsListProps) {
  const { subPayments, refunds } = props;

  // this is deduplicating the subpayments by payment id and then
  // setting the amount field to that of the total payment amount
  // so that payments are not divided by trip.
  const uniqByPayment = uniqBy(subPayments, "paymentId");

  const paymentsList = map(uniqByPayment, (subPayment) => ({
    ...subPayment,
    amount: subPayment.payment.amount,
  }));

  return (
    <Box display="flex" flex="1" flexDirection="column" mt={6}>
      <Box mb={3}>
        <Typography variant="h3">Payments</Typography>
      </Box>

      <Box display="flex" flex="1" flexDirection="row" px={2} mb={1}>
        <Box display="flex" flex=".2">
          <Typography variant="overline" sx={{ color: grayDark }}>
            type
          </Typography>
        </Box>
        <Box display="flex" flex=".4">
          <Typography variant="overline" sx={{ color: grayDark }}>
            payment method
          </Typography>
        </Box>
        <Box display="flex" flex=".4">
          <Typography variant="overline" sx={{ color: grayDark }}>
            note
          </Typography>
        </Box>
        <Box display="flex" flex=".5" ml={3}>
          <Typography variant="overline" sx={{ color: grayDark }}>
            date & time
          </Typography>
        </Box>
        <Box display="flex" flex=".4" justifyContent="flex-end">
          <Typography variant="overline" sx={{ color: grayDark }}>
            amount
          </Typography>
        </Box>
        <Box display="flex" flex="0.4" />
      </Box>
      <Box mb={3} px={2} py={0.5} bgcolor={alabaster}>
        {paymentsList.map((payment) => (
          <Box key={payment.id} display="flex" my={1.5} minHeight={"30px"}>
            <PaymentListItem subPayment={payment} />
          </Box>
        ))}
        {refunds.map((refund) => (
          <Box key={refund.id} display="flex" my={1.5} minHeight={"30px"}>
            <PaymentListItem refund={refund} />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default PaymentsList;
