import React, { useState } from "react";

import {
  useAuth,
  useOperator,
} from "../../../globals/hooks";
import authApi from "utils/auth/authApi";
import InternationalPhoneInput from "components/globals/InternationalPhoneInput";
import { Box, TextField } from "@mui/material";

function MobileAndEmailAuthentication() {
  // state
  const [mobilePhoneInput, setMobilePhoneInput] = useState({
    mobilePhone: "",
    phoneCountryCode: "",
    phoneCountryDialCode: "",
    phoneCountryName: "",
    phoneCountryFormat: "",
  });
  const [email, setEmail] = useState(null);

  // hooks
  const { operator } = useOperator();
  const { onLoginDialogOpen } = useAuth();

  // event handlers
  const handleMobilePhoneChange = (value, country) => {
    setMobilePhoneInput({
      mobilePhone: value,
      phoneCountryCode: country.countryCode,
      phoneCountryDialCode: country.dialCode,
      phoneCountryName: country.name,
      phoneCountryFormat: country.format,
    });
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
  };

  const findExistingUserWithMobile = async () => {
    // if phone number is not appropriate length, do not make api call
    // some international numbers can be 8 digits (solomon islands)
    if (mobilePhoneInput.mobilePhone.length < 8) {
      return;
    }

    // check to see if existing contact
    const { status } = await authApi.sendCode({
      mobilePhone: mobilePhoneInput,
      email: null,
      operatorSlug: operator.nameSlug,
    });

    // code was successfully sent, meaning a user exists
    if (status === 200) {
      onLoginDialogOpen({
        mobilePhone: mobilePhoneInput,
        verifyCodeText: "We found an account with your mobile number",
        verifyButtonText: "Login",
      });
    }
  };

  const findExistingUserWithEmail = async () => {
    if (!email) return;

    const { status } = await authApi.sendCode({
      email,
      mobilePhone: null,
      operatorSlug: operator.nameSlug,
    });

    if (status === 200) {
      onLoginDialogOpen({
        email,
        verifyButtonText: "Login",
        verifyCodeText: "We found an account with your email",
      });
    }
  };

  return (
    <>
      <InternationalPhoneInput
        value={mobilePhoneInput.mobilePhone}
        onChange={handleMobilePhoneChange}
        onBlur={findExistingUserWithMobile}
        label={"Mobile Phone Number"}
      />

      <Box mt={1} mb={2}>
        <TextField
          fullWidth
          name="email"
          variant="outlined"
          placeholder="Email address"
          label="Email address"
          onChange={handleEmailChange}
          onBlur={findExistingUserWithEmail}
          value={email}
        />
      </Box>
    </>
  );
}

export default MobileAndEmailAuthentication;
