import { useMutation } from "@apollo/client";
import startCase from "lodash/startCase";
import React, { useState } from "react";
import sortBy from "lodash/sortBy";

import { Box } from "@mui/material";

import MoovsDialog from "components/globals/MoovsDialog";
import {
  LINK_PASSENGER_TO_PAYMENT_METHOD_MUTATION,
  LOAD_ME_QUERY,
} from "globals/graphql";
import { useAnalytics, useCurrentUser, useSnackbar } from "globals/hooks";
import { CreateLinkedPassengerDialog } from "pages/passengers/components";
import { SearchLinkedPassengerAutocomplete } from "components/autocompletes";

type LinkCardToPassengerDialogProps = {
  open: boolean;
  setLinkCardToPassengerDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  paymentMethodId: string;
};

type SelectedPassenger = {
  id: string;
  email: string;
  mobilePhone: string;
  firstName: string;
  lastName: string;
};

function LinkCardToPassengerDialog(props: LinkCardToPassengerDialogProps) {
  const { open, setLinkCardToPassengerDialogOpen, paymentMethodId } = props;

  // state
  const [selectedPassenger, setSelectedPassenger] =
    useState<SelectedPassenger | null>(null);
  const [selectPassengerErrorMessage, setSelectPassengerErrorMessage] =
    useState("");
  const [linkedPassengerDialogOpen, setLinkedPassengerDialogOpen] =
    useState(false);

  // hooks
  const currentUser = useCurrentUser();
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  const passengers: SelectedPassenger[] = currentUser
    ? sortBy(
        currentUser.linkedPassengers.map((passenger) => {
          return {
            id: passenger.id,
            email: passenger.email,
            mobilePhone: passenger.mobilePhone,
            firstName: startCase(passenger.firstName),
            lastName: startCase(passenger.lastName),
          };
        }),
        ["firstName", "lastName"]
      )
    : [];

  // mutation
  const [linkPassengerToPaymentMethod, { loading: linkPaymentMethodLoading }] =
    useMutation(LINK_PASSENGER_TO_PAYMENT_METHOD_MUTATION, {
      onCompleted: () => {
        snackbar.success("Successfully linked passenger!");
        handleDialogClose();
        track("linkPassenger_cardLinked");
      },
      onError() {
        snackbar.error("Error linking passenger");
      },
      refetchQueries: [{ query: LOAD_ME_QUERY }],
    });

  // event handlers
  const handleDialogClose = () => {
    setLinkCardToPassengerDialogOpen(false);
    setSelectPassengerErrorMessage("");
    setSelectedPassenger(null);
  };

  const handleSelectPassengerOnChange = (
    _,
    selectedPassenger: SelectedPassenger
  ) => {
    setSelectPassengerErrorMessage("");
    setSelectedPassenger(selectedPassenger);
  };

  const handleLinkCardToPassengerSave = async () => {
    if (!selectedPassenger) {
      setSelectPassengerErrorMessage("Please select a passenger");
      return;
    }

    await linkPassengerToPaymentMethod({
      variables: {
        input: {
          linkedPassengerId: selectedPassenger.id,
          paymentMethodId,
        },
      },
    });
  };

  const handleAddNewPassengerClick = () => {
    setLinkedPassengerDialogOpen(true);
  };

  return (
    <>
      <MoovsDialog
        size="xs"
        dialogTitle="Link Card to Passenger"
        acceptButtonText="Save"
        closeButtonText="Cancel"
        open={open}
        onClose={handleDialogClose}
        acceptDisabled={linkPaymentMethodLoading}
        onAccept={handleLinkCardToPassengerSave}
        dialogMarginTop="-10vh"
      >
        <Box my={3}>
          <SearchLinkedPassengerAutocomplete
            id="search-linked-passengers-card-dialog"
            value={selectedPassenger}
            passengers={passengers}
            handleSelectPassengerOnChange={handleSelectPassengerOnChange}
            selectPassengerErrorMessage={selectPassengerErrorMessage}
            handleAddNewPassengerClick={handleAddNewPassengerClick}
          />
        </Box>
      </MoovsDialog>

      <CreateLinkedPassengerDialog
        open={linkedPassengerDialogOpen}
        setLinkedPassengerDialogOpen={setLinkedPassengerDialogOpen}
        onCreateLinkedPassenger={setSelectedPassenger}
      />
    </>
  );
}
export default LinkCardToPassengerDialog;
