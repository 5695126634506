import { useOperator } from "globals/hooks";

type UseDisplayMeetGreetParams = {
  isAirportPickUpStop: boolean;
  mode: "create" | "update";
  operatorSetMeetGreet?: boolean;
  createPickUpAddressIsAirport?: boolean;
};

export const useDisplayMeetGreet = (params: UseDisplayMeetGreetParams) => {
  const {
    isAirportPickUpStop,
    mode,
    operatorSetMeetGreet,
    createPickUpAddressIsAirport,
  } = params;

  // hooks
  const {
    operator: {
      settings: { pricingLayout },
    },
  } = useOperator();

  let displayMeetGreet = true;

  // do not enable if operator has setting toggled off
  if (!pricingLayout.meetGreetAmtEnabled) {
    displayMeetGreet = false;
  }

  // do not enable if stop is not an airport pickup
  if (!isAirportPickUpStop && !createPickUpAddressIsAirport) {
    displayMeetGreet = false;
  }

  // do not enable if operator already selected meet & greet
  if (mode === "update" && operatorSetMeetGreet) {
    displayMeetGreet = false;
  }

  return {
    displayMeetGreet,
    meetGreetAmt: pricingLayout.meetGreetAmt,
  };
};
