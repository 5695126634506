import React, { useMemo } from "react";
import find from "lodash/find";
import partition from "lodash/partition";
import size from "lodash/size";
import last from "lodash/last";

import { Box, Typography, Stack } from "@mui/material";

import { CircleRejectCancelIcon } from "design-system/icons";
import { VehicleFeature } from "types";
import { black, granite, grayDark } from "design-system/colors";
import { FeatureItem } from ".";

// constants
const POLICY_FEATURES = [
  "Alcohol Friendly",
  "Food Allowed",
  "Pets Allowed",
  "Smoking Allowed",
];

type PolicyFeaturesProps = {
  features: VehicleFeature[];
  hasTripConflict: boolean;
};

function PolicyFeatures(props: PolicyFeaturesProps) {
  const { features, hasTripConflict } = props;

  const [allowedFeatures, notAllowedFeatures] = partition(
    POLICY_FEATURES,
    (name) => find(features, { name })
  );

  // constructs text that is combination of all not allowed policy features
  // e.g. "No food, pets, or smoking allowed"
  const notAllowedFeaturesText = useMemo(() => {
    if (!size(notAllowedFeatures)) return "";

    const shortenedFeatures = notAllowedFeatures.reduce(
      (text, feature, index) => {
        const shortenedFeature = feature.split(" ")[0].toLowerCase();

        let prefix = "";

        if (index > 0) prefix += ", ";
        if (index > 0 && feature === last(notAllowedFeatures)) prefix += "or ";

        return text + prefix + shortenedFeature;
      },
      ""
    );

    return `No ${shortenedFeatures} allowed`;
  }, [notAllowedFeatures]);

  return (
    <Box>
      <Typography variant="overline">Policy</Typography>

      <Stack spacing={1.5} mt={1.5}>
        {/* allowed features */}
        {allowedFeatures.map((name) => (
          <Box key={name}>
            <FeatureItem name={name} hasTripConflict={hasTripConflict} />
          </Box>
        ))}

        {/* not allowed features */}
        {!!notAllowedFeaturesText && (
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box
              width={16}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircleRejectCancelIcon
                size="small"
                color={hasTripConflict ? grayDark : granite}
              />
            </Box>
            <Typography
              ml={2}
              maxWidth={200}
              variant="caption"
              color={hasTripConflict ? grayDark : black}
            >
              {notAllowedFeaturesText}
            </Typography>
          </Box>
        )}
      </Stack>
    </Box>
  );
}

export default PolicyFeatures;
