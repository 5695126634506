import React, { useState } from "react";
import map from "lodash/map";
import mapValues from "lodash/mapValues";

import { Typography, Box } from "@mui/material";

import MoovsTabs from "../../components/globals/MoovsTabs";
import { RequestStage } from "../../types";
import LoginLogoutBlock from "../../components/auth/LoginLogoutBlock/LoginLogoutBlock";
import { useOperator, useScreenSize } from "../../globals/hooks";
import ReservationsTab from "./components/ReservationsTab";
import QuotesTab from "./components/QuotesTab";
import TripsAsPassengerTab from "./components/TripsAsPassengerTab";
import ReservationDropdownFilter, {
  ReservationFilterStatusVariant,
  defaultFilterStatuses,
} from "./components/ReservationDropdownFilter";

enum TripType {
  TripAsPassenger = "TRIP_AS_PASSENGER",
}

function OrdersPage() {
  const { isMobileView } = useScreenSize();
  const { operator } = useOperator();
  // state
  const [activeTab, setActiveTab] = useState<RequestStage>(
    RequestStage.Reservation
  );
  const [filterStatuses, setFilterStatuses] = useState<
    ReservationFilterStatusVariant[]
  >(defaultFilterStatuses);

  // derived state
  const showQuotes = operator?.settings?.customerPortalQuotesVisible;

  const tabConfig = {
    [RequestStage.Reservation]: {
      label: "RESERVATIONS",
      component: (
        <ReservationsTab
          filterStatuses={filterStatuses}
          setFilterStatuses={setFilterStatuses}
        />
      ),
    },
    ...(showQuotes && {
      [RequestStage.Quote]: { label: "QUOTES", component: <QuotesTab /> },
    }),
    [TripType.TripAsPassenger]: {
      label: "TRIPS AS PASSENGER",
      component: <TripsAsPassengerTab />,
    },
  };

  const tabs = mapValues(tabConfig, "component");
  const tabValues = map(tabConfig, (config, value) => ({
    label: config.label,
    value,
  }));

  return (
    <Box display="flex" justifyContent="center" flex="1">
      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        maxWidth="1140px"
        px={isMobileView ? 2 : 0}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          my={2}
        >
          <Typography variant="h1" style={{ fontWeight: 600 }}>
            Orders
          </Typography>
          <LoginLogoutBlock />
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <MoovsTabs
            onChange={setActiveTab}
            activeTab={activeTab}
            tabValues={tabValues}
          />
          {!isMobileView && activeTab === RequestStage.Reservation ? (
            <Box minHeight="75px">
              <ReservationDropdownFilter
                filterStatuses={filterStatuses}
                setFilterStatuses={setFilterStatuses}
              />
            </Box>
          ) : (
            <Box minHeight="75px"></Box>
          )}
        </Box>
        {tabs[activeTab]}
      </Box>
    </Box>
  );
}

export default OrdersPage;
