import React from "react";

type PinIconProps = {
  size?: "small";
  color?: string;
  text?: number | string;
};

const PinIcon = (props: PinIconProps) => {
  const { size, color, text } = props;

  if (size === "small") {
    return (
      <svg
        width="12"
        height="16"
        viewBox="0 0 12 16"
        fill={color || "none"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 5.61309C12 8.31569 7.43822 14.367 6.27022 15.8713C6.23931 15.9112 6.19875 15.9436 6.15185 15.966C6.10496 15.9883 6.05308 16 6.00044 16C5.94781 16 5.89593 15.9883 5.84903 15.966C5.80214 15.9436 5.76158 15.9112 5.73067 15.8713C4.56267 14.367 0 8.31569 0 5.61309C0 4.12441 0.632141 2.69669 1.75736 1.64404C2.88258 0.591377 4.4087 0 6 0C7.5913 0 9.11742 0.591377 10.2426 1.64404C11.3679 2.69669 12 4.12441 12 5.61309Z"
          fill={color || "#999999"}
        />
        <text
          fill="white"
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize="6px"
          fontWeight="700"
        >
          {text}
        </text>
      </svg>
    );
  }

  return (
    <svg
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill={color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 8.41963C18 12.4735 11.1573 21.5505 9.40533 23.807C9.35896 23.8668 9.29812 23.9154 9.22778 23.949C9.15745 23.9825 9.07962 24 9.00067 24C8.92171 24 8.84389 23.9825 8.77355 23.949C8.70322 23.9154 8.64237 23.8668 8.596 23.807C6.844 21.5505 0 12.4735 0 8.41963C0 6.18661 0.948212 4.04504 2.63604 2.46605C4.32387 0.887066 6.61305 0 9 0C11.3869 0 13.6761 0.887066 15.364 2.46605C17.0518 4.04504 18 6.18661 18 8.41963Z"
        fill={color || "#999999"}
      />
      <text
        fill="white"
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="8px"
        fontWeight="700"
      >
        {text}
      </text>
    </svg>
  );
};

export default PinIcon;
