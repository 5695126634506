import React from "react";

import { Box, Card, Link, Typography } from "@mui/material";

import MoovsDialog from "components/globals/MoovsDialog";
import { granite, moovsBlue } from "design-system/colors";
import { useOperator, useScreenSize } from "globals/hooks";
import { formatPhoneNumber } from "utils/phoneNumberFormatter/phoneNumberFormatter";
import VehicleUnavailableDialogIcon from "design-system/icons/info/VehicleUnavailableDialogIcon";

type VehicleUnavailableDialogProps = {
  vehicleUnavailableDialogOpen: boolean;
  setVehicleUnavailableDialogOpen: (open: boolean) => void;
};

function VehicleUnavailableDialog(props: VehicleUnavailableDialogProps) {
  const { setVehicleUnavailableDialogOpen, vehicleUnavailableDialogOpen } =
    props;

  // hooks
  const {
    operator: { voicePhoneNumber, twilioPhoneNumber, generalEmail },
  } = useOperator();
  const { isMobileView } = useScreenSize();

  // derived state
  const formattedTwilioNumber = formatPhoneNumber(
    twilioPhoneNumber?.phoneNumber
  )?.formatted;

  return (
    <MoovsDialog
      open={vehicleUnavailableDialogOpen}
      hideTopBorder
      size="sm"
      onClose={() => setVehicleUnavailableDialogOpen(false)}
    >
      <Box
        alignSelf="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        maxWidth="320px"
        mt={isMobileView ? "5%" : 0}
      >
        <Box alignSelf="center">
          <VehicleUnavailableDialogIcon />
        </Box>
        <Typography variant="h2" mt={1.5} textAlign="center">
          Vehicle is no longer available at this time
        </Typography>
        <Typography variant="body1" color={granite} mt={1.5} textAlign="center">
          Please contact us to check availability for a replacement vehicle.
        </Typography>
      </Box>
      <Box textAlign={isMobileView ? "start" : "center"} mt={6} mb={3}>
        <Card variant="outlined" sx={{ padding: 2 }}>
          <Box
            display="flex"
            mb={1}
            flexDirection={isMobileView ? "column" : "row"}
          >
            <Typography variant="subtitle1" mr={1}>
              Call or Text Us:{" "}
            </Typography>
            <Link
              color={moovsBlue}
              underline="none"
              fontSize="16px"
              fontWeight={700}
              variant="subtitle1"
              href={`tel:${voicePhoneNumber || formattedTwilioNumber}`}
            >
              {voicePhoneNumber || formattedTwilioNumber}
            </Link>
          </Box>
          {generalEmail && (
            <Box display="flex" flexDirection={isMobileView ? "column" : "row"}>
              <Typography variant="subtitle1" mr={1}>
                Email us:{" "}
              </Typography>
              <Link
                color={moovsBlue}
                target="_blank"
                variant="subtitle1"
                underline="none"
                href={`mailto:${generalEmail}`}
              >
                {generalEmail}
              </Link>
            </Box>
          )}
        </Card>
      </Box>
    </MoovsDialog>
  );
}

export default VehicleUnavailableDialog;
