import React, { ChangeEvent, Dispatch, SetStateAction, useEffect } from "react";
import first from "lodash/first";

import { MenuItem, TextField } from "@mui/material";

import { getPaymentMethodInfoString } from "globals/utils/helpers";
import { PaymentMethod } from "types";

type PaymentCardSelectProps = {
  existingPaymentMethods: PaymentMethod[];
  setStripePaymentMethodId?: Dispatch<SetStateAction<string>>;
  stripePaymentMethodId?: string;
};

function PaymentCardSelect(props: PaymentCardSelectProps) {
  const {
    existingPaymentMethods,
    setStripePaymentMethodId,
    stripePaymentMethodId,
  } = props;

  useEffect(() => {
    setStripePaymentMethodId(first(existingPaymentMethods).stripeId);
  }, [existingPaymentMethods, setStripePaymentMethodId]);

  // event handlers
  const handlePaymentCardChange = (event: ChangeEvent<HTMLInputElement>) => {
    const stripePaymentMethodId = event.target.value;
    setStripePaymentMethodId(stripePaymentMethodId);
  };

  return (
    <TextField
      label="Payment Card"
      fullWidth
      variant="outlined"
      select
      value={stripePaymentMethodId || existingPaymentMethods[0].stripeId}
      onChange={handlePaymentCardChange}
    >
      {(existingPaymentMethods || []).map(({ card, stripeId }) => {
        return (
          <MenuItem key={stripeId} value={stripeId}>
            {getPaymentMethodInfoString(card)}
          </MenuItem>
        );
      })}
    </TextField>
  );
}

export default PaymentCardSelect;
