import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@apollo/client";

import { Box, Button } from "@mui/material";

import MoovsDialog from "components/globals/MoovsDialog";
import { useScreenSize, useSnackbar } from "globals/hooks";
import {
  UpdateLinkedPassengerState,
  updateLinkedPassengerErrorSchema,
} from "./form/schemaValidation";
import RHFInternationalPhoneInput from "components/react-hook-form/RHFInternationalPhoneInput";
import { RHFTextField } from "components/react-hook-form/text-field/RHFTextField";
import { Contact } from "types";
import { UPDATE_PASSENGER_CONTACT_MUTATION } from "globals/graphql";
import { errorRed, grayLight } from "design-system/colors";
import { TrashIcon } from "design-system/icons";
import DeletePassengerDialog from "../DeletePassengerDialog";

type UpdateLinkedPassengerDialogProps = {
  open: boolean;
  setUpdateLinkedPassengerDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  selectedPassenger: Contact;
};

function UpdateLinkedPassengerDialog(props: UpdateLinkedPassengerDialogProps) {
  const { open, setUpdateLinkedPassengerDialogOpen, selectedPassenger } = props;

  // state
  const [deleteLinkedPassengerDialogOpen, setDeleteLinkedPassengerDialogOpen] =
    useState(false);

  // hooks
  const { isMobileView } = useScreenSize();
  const snackbar = useSnackbar();

  // mutation
  const [updateContact] = useMutation(UPDATE_PASSENGER_CONTACT_MUTATION, {
    onCompleted() {
      snackbar.success("Successfully updated passenger!");
      setUpdateLinkedPassengerDialogOpen(false);
    },
    onError(error) {
      snackbar.error(error.message || "Error updating passenger");
    },
  });

  // form
  const formId = `update-linked-passenger-form`;
  const methods = useForm<UpdateLinkedPassengerState>({
    mode: "onSubmit",
    resolver: yupResolver(updateLinkedPassengerErrorSchema),
  });

  // event handlers
  const handleDeletePassengerClick = () => {
    setDeleteLinkedPassengerDialogOpen(true);
  };

  const handleUpdateDialogClose = () => {
    setUpdateLinkedPassengerDialogOpen(false);
  };

  const handleUpdateLinkedPassengerClick = (
    updateLinkedPassengerState: UpdateLinkedPassengerState
  ) => {
    updateContact({
      variables: {
        input: {
          passengerId: selectedPassenger.id,
          firstName: updateLinkedPassengerState.firstName,
          lastName: updateLinkedPassengerState.lastName,
          email: updateLinkedPassengerState.email,
          mobilePhone: updateLinkedPassengerState.phoneFields.mobilePhone,
          phoneCountryCode:
            updateLinkedPassengerState.phoneFields.phoneCountryCode,
          phoneCountryDialCode:
            updateLinkedPassengerState.phoneFields.phoneCountryDialCode,
          phoneCountryName:
            updateLinkedPassengerState.phoneFields.phoneCountryName,
          phoneCountryFormat:
            updateLinkedPassengerState.phoneFields.phoneCountryFormat,
        },
      },
    });
  };

  // effects
  useEffect(() => {
    if (open && selectedPassenger) {
      methods.reset({
        firstName: selectedPassenger.firstName,
        lastName: selectedPassenger.lastName,
        email: selectedPassenger.email,
        phoneFields: {
          mobilePhone: selectedPassenger.mobilePhone,
          phoneCountryCode: selectedPassenger.phoneCountryCode,
          phoneCountryDialCode: selectedPassenger.phoneCountryDialCode,
          phoneCountryName: selectedPassenger.phoneCountryName,
          phoneCountryFormat: selectedPassenger.phoneCountryFormat,
        },
      });
    }
  }, [methods, open, selectedPassenger]);

  return (
    <>
      <MoovsDialog
        hideTopBorder
        size={isMobileView ? "md" : "sm"}
        dialogTitle="Edit Passenger"
        open={open}
        submitFormId={formId}
        onClose={handleUpdateDialogClose}
        customFooter={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            px={isMobileView ? 2 : 4}
            py={2}
            borderTop={`1px solid ${grayLight}`}
          >
            <Box>
              <Button
                sx={{ color: errorRed }}
                onClick={handleDeletePassengerClick}
              >
                <Box mt={0.5} mr={0.5}>
                  <TrashIcon color={errorRed} size="small" />
                </Box>
                Delete
              </Button>
            </Box>
            <Box display="flex" alignItems="center">
              <Box mx={1}>
                <Button onClick={handleUpdateDialogClose}>Cancel</Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  disabled={
                    !selectedPassenger || methods.formState.isSubmitting
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    methods.handleSubmit(handleUpdateLinkedPassengerClick)(e);
                  }}
                >
                  Update
                </Button>
              </Box>
            </Box>
          </Box>
        }
      >
        <FormProvider {...methods}>
          <form id={formId}>
            <Box mr={0.5} mb={isMobileView ? 0 : 1}>
              <Box display="flex">
                <Box flex={1} mr={0.5}>
                  <RHFTextField
                    fullWidth
                    variant="outlined"
                    label="First Name"
                    name="firstName"
                  />
                </Box>
                <Box flex={1}>
                  <RHFTextField
                    fullWidth
                    variant="outlined"
                    label="Last Name"
                    name="lastName"
                  />
                </Box>
              </Box>

              <Box my={1.5}>
                <RHFTextField
                  fullWidth
                  variant="outlined"
                  label="Email"
                  name="email"
                />
              </Box>

              <RHFInternationalPhoneInput
                name="phoneFields"
                label="Mobile Phone"
              />
            </Box>
          </form>
        </FormProvider>
      </MoovsDialog>

      <DeletePassengerDialog
        open={deleteLinkedPassengerDialogOpen}
        setDeleteLinkedPassengerDialogOpen={setDeleteLinkedPassengerDialogOpen}
        selectedPassengerId={selectedPassenger?.id}
        onUpdateDialogClose={handleUpdateDialogClose}
      />
    </>
  );
}

export default UpdateLinkedPassengerDialog;
