/**
 * @file contact.graphql.ts
 * queries/mutations for Contact.
 *
 */

import { gql } from "@apollo/client";

export const CREATE_CONTACT_MUTATION = gql`
  mutation CreateContact($input: CreateContactInput!) {
    createContact(input: $input) {
      contact {
        id
        createdAt
        updatedAt
        firstName
        lastName
        email
        mobilePhone
        mobilePhoneInternational
        companyPosition
        preferences
        homeAddress
        workAddress
        company {
          id
          name
        }
        paymentMethods {
          id
          createdAt
          updatedAt
          contactId
          cardNote
          stripeId
          card {
            id
            expMonth
            expYear
            last4
            brand
          }
        }
      }
    }
  }
`;

// only used to delete a contact on stripe error when creating reservation
export const ROLLBACK_CREATE_CONTACT_MUTATION = gql`
  mutation RollbackCreateContact($input: RollbackCreateContactInput!) {
    rollbackCreateContact(input: $input) {
      contact {
        id
        createdAt
        updatedAt
        firstName
        lastName
        email
        mobilePhone
        mobilePhoneInternational
        companyPosition
        preferences
        homeAddress
        workAddress
        company {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_PASSENGER_CONTACT_MUTATION = gql`
  mutation UpdatePassengerContact($input: UpdatePassengerContactInput!) {
    updatePassengerContact(input: $input) {
      contact {
        id
        firstName
        lastName
        email
        mobilePhone
        mobilePhoneInternational
      }
    }
  }
`;

export const UPDATE_CONTACT_MUTATION = gql`
  mutation UpdateContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      contact {
        id
        firstName
        lastName
        email
        mobilePhone
        mobilePhoneInternational
      }
    }
  }
`;

export const CREATE_CONTACT_AND_LINK_PASSENGER_MUTATION = gql`
  mutation CreateContactAndLinkPassenger(
    $input: CreateContactAndLinkPassengerInput!
  ) {
    createContactAndLinkPassenger(input: $input) {
      contact {
        id
        firstName
        lastName
        email
        mobilePhone
        mobilePhoneInternational
      }
    }
  }
`;

export const REMOVE_LINKED_PASSENGER_MUTATION = gql`
  mutation RemoveLinkedPassenger($input: RemoveLinkedPassengerInput!) {
    removeLinkedPassenger(input: $input) {
      contact {
        id
      }
    }
  }
`;
