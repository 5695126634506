import React, { ReactNode } from "react";

import { Box, Drawer, IconButton, Typography } from "@mui/material";

import { granite } from "../../design-system/colors";
import { EditIcon, CrossIcon } from "../../design-system/icons";

type MoovsBottomDrawerProps = {
  open: boolean;
  onClose?: () => void;
  children?: ReactNode;
  onEditClick?: () => void;
  title: string;
};

function MoovsBottomDrawer(props: MoovsBottomDrawerProps) {
  const { open, children, onClose, onEditClick, title } = props;

  return (
    <Drawer
      variant="temporary"
      open={open}
      onClose={onClose}
      anchor="bottom"
      sx={{
        "& .MuiDrawer-paper": {
          borderTopLeftRadius: "14px",
          borderTopRightRadius: "14px",
          boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.08)",
          display: "block", // default of display: flex causes iOS safari issues
          // ref: https://github.com/theswoopapp/dooms-customer/pull/295}
        },
      }}
    >
      <Box display="flex" flexDirection="column" m={3}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography variant="h3">{title}</Typography>

          <Box display="flex" flexDirection="row" mt={-1.5} mr={-1.5}>
            {onEditClick && (
              <IconButton onClick={onEditClick} size="large">
                <EditIcon color={granite} />
              </IconButton>
            )}
            <IconButton onClick={onClose} size="large">
              <CrossIcon color={granite} />
            </IconButton>
          </Box>
        </Box>
        {children}
      </Box>
    </Drawer>
  );
}

export default MoovsBottomDrawer;
