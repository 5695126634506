import React, { Fragment } from "react";
import { useParams } from "react-router-dom";

import { Box, Divider } from "@mui/material";

import { Request } from "../../types";
import OrderListItemDesktop from "./OrderListItem.desktop";
import TripListItemDesktop from "./TripListItem.desktop";
import { useScreenSize } from "../../globals/hooks";
import OrderListItemMobile from "./OrderListItem.mobile";
import TripListItemMobile from "./TripListItem.mobile";
import { currencyConverter } from "../../utils/currency";

type OrderInvoiceItemProps = {
  request: Request;
  isCompanyInvoice: boolean;
};

function OrderInvoiceItem(props: OrderInvoiceItemProps) {
  const { request, isCompanyInvoice } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const { operatorSlug } = useParams<{ operatorSlug: string }>();

  // derived variables
  const reservationLink = `/${operatorSlug}/request/${request.id}`;

  const requestTotals = {
    totalAmount: request.totalAmount,
    amountDue: request.amountDue,
    totalPaid: currencyConverter.toDollars(
      currencyConverter.toCents(request.totalAmount) -
        currencyConverter.toCents(request.amountDue)
    ),
  };

  const activeTrips = request.trips.filter((trip) => !trip.cancelledAt);

  return (
    <Box display="flex" flexDirection="column">
      {isMobileView ? (
        <OrderListItemMobile
          orderNumber={request.orderNumber}
          reservationLink={reservationLink}
          requestTotals={requestTotals}
          {...(isCompanyInvoice && { bookingContact: request.bookingContact })}
        />
      ) : (
        <OrderListItemDesktop
          orderNumber={request.orderNumber}
          reservationLink={reservationLink}
          requestTotals={requestTotals}
          {...(isCompanyInvoice && { bookingContact: request.bookingContact })}
        />
      )}

      {activeTrips.map((trip) => (
        <Fragment key={trip.id}>
          <Box {...(isMobileView && { mx: 2 })}>
            <Divider />
          </Box>
          {isMobileView ? (
            <Box mx={2}>
              <TripListItemMobile request={request} trip={trip} />
            </Box>
          ) : (
            <TripListItemDesktop
              request={request}
              trip={trip}
              isCompanyInvoice={isCompanyInvoice}
            />
          )}
        </Fragment>
      ))}
    </Box>
  );
}

export default OrderInvoiceItem;
