import { useCallback } from "react";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import startsWith from "lodash/startsWith";
import isNumber from "lodash/isNumber";
import isFunction from "lodash/isFunction";

import { useOperator, useQueryParams } from "./";

let isInitialized = false;
let isGtm = false;

function useGoogleTracking() {
  const {
    operator: { googleTrackingId },
  } = useOperator();
  const queryParams = useQueryParams();

  const googleTrack = useCallback(
    (action: string, requestTotal?: number) => {
      if (!googleTrackingId) return;

      if (isGtm) {
        const segmentUser = isFunction(window.analytics?.user)
          ? window.analytics.user().id()
          : null;

        (((window as any).dataLayer as any[]) || []).push({
          segmentUser,
          event: action,
          category: "Moovs_Tracking",
          ...(isNumber(requestTotal) && { value: requestTotal }),
        });
      } else {
        ReactGA.event({
          action,
          category: "Moovs_Tracking",
          ...(isNumber(requestTotal) && { value: requestTotal }),
        });
      }
    },
    [googleTrackingId]
  );

  const getGclid = useCallback(
    () => googleTrackingId && queryParams.get("gclid"),
    [queryParams, googleTrackingId]
  );

  if (!isInitialized && googleTrackingId) {
    isGtm = startsWith(googleTrackingId, "GTM");

    if (isGtm) {
      TagManager.initialize({ gtmId: googleTrackingId });
    } else {
      ReactGA.initialize(googleTrackingId, {
        gaOptions: { allowLinker: true },
      });
    }

    isInitialized = true;
  }

  return { googleTrack, getGclid };
}

export { useGoogleTracking };
