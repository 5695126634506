import React from "react";

type OrdersIconProps = {
  color?: string;
  size?: "small";
};

const OrdersIcon = (props: OrdersIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5 2.3H11.75C12.0815 2.3 12.3995 2.43696 12.6339 2.68076C12.8683 2.92456 13 3.25522 13 3.6V12.7C13 13.0448 12.8683 13.3754 12.6339 13.6192C12.3995 13.863 12.0815 14 11.75 14H4.25C3.91848 14 3.60054 13.863 3.36612 13.6192C3.1317 13.3754 3 13.0448 3 12.7V3.6C3 3.25522 3.1317 2.92456 3.36612 2.68076C3.60054 2.43696 3.91848 2.3 4.25 2.3H5.5M6.125 1H9.875C10.2202 1 10.5 1.29101 10.5 1.65V2.95C10.5 3.30898 10.2202 3.6 9.875 3.6H6.125C5.77982 3.6 5.5 3.30898 5.5 2.95V1.65C5.5 1.29101 5.77982 1 6.125 1Z"
          stroke={color || "#B3B3B3"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 2.2H18.75C19.3467 2.2 19.919 2.43179 20.341 2.84437C20.7629 3.25695 21 3.81652 21 4.4V20.8C21 21.3835 20.7629 21.9431 20.341 22.3556C19.919 22.7682 19.3467 23 18.75 23H5.25C4.65326 23 4.08097 22.7682 3.65901 22.3556C3.23705 21.9431 3 21.3835 3 20.8V4.4C3 3.81652 3.23705 3.25695 3.65901 2.84437C4.08097 2.43179 4.65326 2.2 5.25 2.2H7.5M8.625 1H15.375C15.9963 1 16.5 1.49249 16.5 2.1V2.3C16.5 2.90751 15.9963 3.4 15.375 3.4H8.625C8.00368 3.4 7.5 2.90751 7.5 2.3V2.1C7.5 1.49249 8.00368 1 8.625 1Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 7H18M6 11H18M6 15H18M6 19H18"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default OrdersIcon;
