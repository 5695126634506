import { ChangeEvent, useState } from "react";

type UseConsentSignatureProps = {
  trips: {
    routes: {
      vehicle?: {
        id: string;
      };
    }[];
  }[];
};

function useConsentSignature(props: UseConsentSignatureProps) {
  const { trips } = props;

  // state
  const [consentSignatureDialogOpen, setConsentSignatureDialogOpen] =
    useState(false);
  const [consentSignature, setConsentSignature] = useState("");
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);

  // derived state
  const selectedVehicleIds = trips.map((trip) => trip.routes[0].vehicle?.id);

  // event handlers
  const handleConsentSignatureDialogOpen = () => {
    setConsentSignatureDialogOpen(true);
  };

  const handleConsentSignatureDialogClose = () => {
    setConsentSignature("");
    setIsAgreementChecked(false);
    setConsentSignatureDialogOpen(false);
  };

  const handleConsentSignatureChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConsentSignature(e.target.value);
  };

  const handleUserConsentAgreementToggle = () => {
    setIsAgreementChecked((prev) => !prev);
  };

  return {
    consentSignatureDialogOpen,
    handleConsentSignatureDialogOpen,
    handleConsentSignatureDialogClose,
    consentSignature,
    handleConsentSignatureChange,
    isAgreementChecked,
    handleUserConsentAgreementToggle,
    selectedVehicleIds,
  };
}

export { useConsentSignature };
