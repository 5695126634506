import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { Contact, TemporaryPassenger } from "types";
import { getAdditionalTripInfoDefaultValues } from "../form/defaultValues";
import {
  additionalTripInfoErrorSchema,
  AdditionalTripInfoState,
} from "../form/schemaValidation";
import ATIFormFields from "./ATIFormFields";
import { useAnalytics } from "globals/hooks";
import { SelectedPassenger } from "globals/hooks/useDefaultPassenger";

type ATIFormProps = {
  onSubmit: (additionalTripInfoState: AdditionalTripInfoState) => void;
  initialState?: {
    totalGroupSize: number;
    note?: string;
    temporaryPassenger?: TemporaryPassenger;
    route?: {
      carryOnLuggage?: number;
      checkedLuggage?: number;
      oversizeLuggage?: number;
    };
    selectedPassenger?: SelectedPassenger;
    contact?: Contact;
  };
};

function ATIForm(props: ATIFormProps) {
  const { onSubmit, initialState } = props;

  // hooks
  const { handleSubmit, control, watch, setValue } =
    useForm<AdditionalTripInfoState>({
      defaultValues: getAdditionalTripInfoDefaultValues(initialState),
      mode: "onSubmit",
      resolver: yupResolver(additionalTripInfoErrorSchema),
    });
  const { track } = useAnalytics();

  return (
    <form
      id="additional-trip-info-form"
      noValidate
      onSubmit={(e) => {
        // prevent submit from bubbling up to parent form
        e.stopPropagation();
        // prevent html submit

        e.preventDefault();
        track("customerLink_saveAdditionalinfo");
        handleSubmit(onSubmit)(e);
      }}
    >
      <ATIFormFields control={control} setValue={setValue} watch={watch} />
    </form>
  );
}

export default ATIForm;
