import { useEffect, useState } from "react";

import { useAnalytics } from "globals/hooks";

type UsePageViewedTrackingProps = {
  trackName: string;
  trackProperties?: Object;
  trackConfig?: {
    timeLapsed: number;
    scrollThreshold: number;
  };
  skip?: boolean;
};

function usePageViewedTracking(props: UsePageViewedTrackingProps) {
  const {
    trackName,
    trackProperties,
    // default tracking config, can accept new config input
    trackConfig = { timeLapsed: 60000, scrollThreshold: 75 },
    skip,
  } = props;

  const { timeLapsed, scrollThreshold } = trackConfig;

  // state
  const [pageViewTimeMet, setPageViewTimeMet] = useState(false);
  const [pageViewScrollMet, setPageViewScrollMet] = useState(false);

  // hooks
  const { track } = useAnalytics();

  // effects
  useEffect(() => {
    if (pageViewTimeMet && pageViewScrollMet) {
      track(trackName, trackProperties);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageViewTimeMet, pageViewScrollMet]);

  // only runs once
  useEffect(() => {
    if (skip) return null;

    const timeout = setTimeout(() => setPageViewTimeMet(true), timeLapsed);

    // handlers
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const pageHeight = document.documentElement.scrollHeight;
      const currentScroll = window.scrollY;

      const scrollPercentage =
        (currentScroll / (pageHeight - windowHeight)) * 100;

      if (scrollPercentage >= scrollThreshold) {
        setPageViewScrollMet(true);
        window.removeEventListener("scroll", handleScroll);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // clean up
    return () => {
      clearTimeout(timeout);
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export { usePageViewedTracking };
