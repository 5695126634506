import React from "react";

import LeftSliderArrowIcon from "design-system/icons/actions/LeftSliderArrowIcon";

type LeftNavProps = {
  disabled: boolean;
  onClick: React.MouseEventHandler<HTMLElement>;
};

function LeftNav(props: LeftNavProps) {
  const { disabled, onClick } = props;

  return (
    <button
      type="button"
      className="image-gallery-icon image-gallery-left-nav"
      disabled={disabled}
      onClick={onClick}
      aria-label="Previous Slide"
    >
      <LeftSliderArrowIcon />
    </button>
  );
}

export default LeftNav;
