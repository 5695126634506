import {
  AUXIcon,
  ACIcon,
  InVehicleBarIcon,
  BluetoothIcon,
  BathroomIcon,
  DancePoleIcon,
  IceChestIcon,
  LuggageIcon,
  RefrigeratorIcon,
  TableIcon,
  TrashIcon,
  WheelchairIcon,
  USBIcon,
  AlcoholIcon,
  DVDPlayerIcon,
  FoodIcon,
  GameConsoleIcon,
  KaraokeIcon,
  PetsIcon,
  PowerOutletsIcon,
  SmokingIcon,
  TVIcon,
  WifiIcon,
  ChildSeatBoosterIcon,
  ChildSeatForwardIcon,
  ChildSeatRearIcon,
} from "design-system/icons";

export const getVehicleFeatureIcon = (
  name: string
): ((props: any) => JSX.Element) => {
  const icons = {
    // General
    AC: ACIcon,
    "In-Vehicle Bar": InVehicleBarIcon,
    Bathroom: BathroomIcon,
    "Dance Pole": DancePoleIcon,
    "Ice Chest": IceChestIcon,
    Luggage: LuggageIcon,
    Refrigerator: RefrigeratorIcon,
    Tables: TableIcon,
    "Trash Can": TrashIcon,
    "Wheelchair Accessible": WheelchairIcon,

    // Multimedia
    AUX: AUXIcon,
    "DVD Player": DVDPlayerIcon,
    Karaoke: KaraokeIcon,
    USB: USBIcon,
    "Power Outlets": PowerOutletsIcon,
    Bluetooth: BluetoothIcon,
    "Game Console": GameConsoleIcon,
    TV: TVIcon,
    Wifi: WifiIcon,

    // Policy
    "Alcohol Friendly": AlcoholIcon,
    "Pets Allowed": PetsIcon,
    "Food Allowed": FoodIcon,
    "Smoking Allowed": SmokingIcon,

    // Child Seats
    Booster: ChildSeatBoosterIcon,
    "Forward-Facing": ChildSeatForwardIcon,
    "Rear-Facing": ChildSeatRearIcon,
  };

  return icons[name];
};
