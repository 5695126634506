import omit from "lodash/omit";
import pick from "lodash/pick";
import {
  Contact,
  PickUpVariant,
  RoundTripVariant,
  TextRecipientEnum,
} from "./../../../types";
import { CreateRequestInput } from "../../../types";
import { orderTypeToEnumMap } from "../../../utils/enumMaps";
import {
  CreateRequestState,
  CreateStopState,
} from "pages/new/context/initialState";
import first from "lodash/first";
import { SelectedPassenger } from "globals/hooks/useDefaultPassenger";
import getRecipient from "globals/utils/getRecipients";

type sanitizeRequestForCreateMutationProps = {
  request: CreateRequestState;
  tripStatusUpdateTextTo: TextRecipientEnum;
  pricingLayout?: any;
  verifiedContact?: Contact;
  pickUpVariantSelected?: PickUpVariant;
  enableLinkedPassenger?: boolean;
  selectedPassenger?: SelectedPassenger;
};

export const sanitizeRequestForCreateMutation = (
  props: sanitizeRequestForCreateMutationProps
): CreateRequestInput => {
  const {
    request,
    pricingLayout,
    verifiedContact,
    pickUpVariantSelected,
    enableLinkedPassenger,
    selectedPassenger,
    tripStatusUpdateTextTo,
  } = props;

  const {
    driverGratuityAmt,
    driverGratuityPercent,
    promoDiscountAmt,
    promoDiscountPercent,
    taxAmt,
    taxPercent,
    tollsAmt,
    meetGreetAmt,
    otherName,
    otherAmt,
    otherPercent,
    other2Name,
    other2Amt,
    other2Percent,
    other3Name,
    other3Amt,
    other3Percent,
  } = pricingLayout || {};

  const orderType = orderTypeToEnumMap[request.orderType?.slug];

  const isTripStatusRecipientPassenger =
    tripStatusUpdateTextTo === TextRecipientEnum.Passenger ||
    tripStatusUpdateTextTo === TextRecipientEnum.Both;

  const isTripStatusRecipientBookingContact =
    tripStatusUpdateTextTo === TextRecipientEnum.BookingContact ||
    tripStatusUpdateTextTo === TextRecipientEnum.Both;

  // booking contact is always verified contact if one is logged in
  // otherwise, it is the inputted booking contact
  // this is handled by ConfirmPageContext
  const contact = verifiedContact
    ? pick(verifiedContact, [
        "id", // id is only important one here, rest are minimum required fields
        "firstName",
        "lastName",
        "email",
        "mobilePhone",
      ])
    : omit(request.bookingContact, [
        "phoneFields",
        "name",
        "creditCard",
        "cardholderName",
      ]);

  const deriveTripFields = (roundTripVariant: RoundTripVariant) => {
    const isOutboundTrip = roundTripVariant === RoundTripVariant.Outbound;
    const isReturnTrip = roundTripVariant === RoundTripVariant.Return;
    const tripKey = (() => {
      if (isOutboundTrip) return "trip";
      if (isReturnTrip) return "returnTrip";
    })();

    const shapeStop = (stop: CreateStopState, arrayIndex: number) => {
      // fields that can be taken directly from stop without sanitizing
      const stopFields = pick(stop, ["dateTime", "flightNumber", "note"]);

      if (stop.trackedFlight) {
        delete stop.trackedFlight.__typename;
        delete stop.trackedFlight.airline.__typename;
        delete stop.trackedFlight.origin.__typename;
        delete stop.trackedFlight.origin.airport.__typename;
        delete stop.trackedFlight.destination.__typename;
        delete stop.trackedFlight.destination.airport.__typename;
      }

      return {
        ...stopFields,
        location: stop.airport ? stop.airport.airportName : stop.location,
        stopIndex: arrayIndex + 1,
        flightNumber: stop.trackedFlight
          ? stop.trackedFlight.flightNumber
          : null, // use flightNumber that includes airline code e.g. UA301 instead of 301
        groupSize: Number(stop.groupSize),
        airportIcao: stop.airport ? stop.airport.icaoCode : null,
        airlineIcao: stop.airline ? stop.airline.icaoCode : null,
        trackedFlight: stop.trackedFlight || null,
      };
    };

    const stops = request[tripKey].stops.map(shapeStop);
    const route = first(request.trip.routes);
    const vehicle = route.vehicle;

    const isAirportPickUp =
      !!first(stops).airportIcao ||
      request[tripKey].stops[0].pickUpGooglePlaceTypes?.includes("airport");

    const baseRateAmt: number = (() => {
      if (isOutboundTrip) return vehicle.baseRateAutomation?.total || null;
      if (isReturnTrip) return vehicle.returnBaseRateAutomation?.total || null;
    })();

    // currently, server only ever expects 1 route
    const routes = [
      {
        vehicle: { id: vehicle.id },
        ...(pricingLayout && {
          driverGratuityAmt,
          driverGratuityPercent,
          promoDiscountAmt,
          promoDiscountPercent,
          taxAmt,
          taxPercent,
          tollsAmt,
          otherName,
          otherAmt,
          otherPercent,
          other2Name,
          other2Amt,
          other2Percent,
          other3Name,
          other3Amt,
          other3Percent,
          meetGreetAmt: !isAirportPickUp
            ? null
            : pickUpVariantSelected === PickUpVariant.MeetGreet
            ? meetGreetAmt
            : null,
        }),
        ...(baseRateAmt && { baseRateAmt }),
        carryOnLuggage: route.carryOnLuggage,
        checkedLuggage: route.checkedLuggage,
        oversizeLuggage: route.oversizeLuggage,
        ...(isOutboundTrip && {
          promoCodeId: route.promoCodeId,
          promoCodeAmt: route.promoCodeAmt,
          promoCodePercent: route.promoCodePercent,
        }),
        // merging child seat fields
        boosterSeatAmt: route.boosterSeatAmt,
        boosterSeatQuantity: route.boosterSeatQuantity,
        forwardFacingSeatAmt: route.forwardFacingSeatAmt,
        forwardFacingSeatQuantity: route.forwardFacingSeatQuantity,
        rearFacingSeatAmt: route.rearFacingSeatAmt,
        rearFacingSeatQuantity: route.rearFacingSeatQuantity,
      },
    ];

    // if additional trip dialog is never opened and updated,
    // request[tripKey].selectedPassenger is null so we use selectedPassenger from useDefaultPassenger hook
    const passengerInfo = request[tripKey].selectedPassenger?.id
      ? { ...request[tripKey].selectedPassenger }
      : { ...selectedPassenger };

    return {
      stops,
      routes,
      contact:
        enableLinkedPassenger && contact?.id
          ? // new feature: contact is either booking contact or linked passenger
            passengerInfo
          : // old feature: contact is always booking contact
            {
              ...contact,
              email: contact.email.trim(),
            },
      note: request[tripKey].note,
      totalGroupSize: Number(request[tripKey].totalGroupSize),
      temporaryPassenger: request[tripKey].temporaryPassenger,
      statusUpdateTextTo:
        request[tripKey].statusUpdateTextTo ||
        getRecipient(
          isTripStatusRecipientPassenger,
          isTripStatusRecipientBookingContact
        ),
    };
  };

  const outboundTrip = deriveTripFields(RoundTripVariant.Outbound);
  const returnTrip = request.returnTrip
    ? deriveTripFields(RoundTripVariant.Return)
    : undefined;

  return {
    orderType: orderType,
    trips: [{ ...outboundTrip, returnTrip }],
    ...(enableLinkedPassenger &&
      contact?.id && {
        bookingContact: {
          ...contact,
          email: contact.email.trim(),
        },
      }),
  };
};
