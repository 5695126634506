import React, { useState } from "react";

import { LoadingButton } from "@mui/lab";
import { Box, TextField } from "@mui/material";

import { useAnalytics, useOperator } from "globals/hooks";
import { MutationFunctionOptions } from "@apollo/client";
import { TripReview } from "types";

type ReviewCommentProps = {
  stars: number;
  handleStarsChange: (_, newValue: number) => void;
  createTripReview: (options?: MutationFunctionOptions<any>) => Promise<any>;
  tripId: string;
  createTripReviewLoading: boolean;
  tripReview: TripReview;
};

function ReviewComment(props: ReviewCommentProps) {
  const {
    stars,
    createTripReview,
    tripId,
    createTripReviewLoading,
    tripReview,
  } = props;

  // state
  const [reviewText, setReviewText] = useState("");
  const [reviewErrorMessage, setReviewErrorMessage] = useState("");

  // hooks
  const { operator } = useOperator();
  const { track } = useAnalytics();

  const handleReviewTextChange = (e) => {
    if (e.target.value.length <= 4000) {
      setReviewText(e.target.value);
      setReviewErrorMessage("");
    } else {
      setReviewErrorMessage("Maximum limit is 4000 characters.");
    }
  };

  const handleReviewSubmit = () => {
    track("tripReview_submitted", { rating: stars });
    createTripReview({
      variables: {
        input: {
          tripId,
          rating: stars,
          body: reviewText,
        },
      },
    });
  };

  if (tripReview || stars === 0 || stars === 5) return null;

  return (
    <Box textAlign="center" width="100%">
      <Box>
        <TextField
          fullWidth
          multiline
          name="review"
          variant="outlined"
          value={reviewText}
          placeholder={`Send ${operator.name} feedback about your experience`}
          onChange={handleReviewTextChange}
          error={!!reviewErrorMessage}
          helperText={reviewErrorMessage}
          inputProps={{
            style: {
              height: 154,
              overflow: "auto",
            },
            maxLength: 4001,
          }}
          sx={{
            marginTop: 4,
            marginBottom: 5,
          }}
        />
      </Box>
      <LoadingButton
        loading={createTripReviewLoading}
        variant="contained"
        onClick={handleReviewSubmit}
      >
        Submit
      </LoadingButton>
    </Box>
  );
}

export default ReviewComment;
