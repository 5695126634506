/**
 * @file MoovsDialog.tsx
 * Called moovs differentiate from our more commonly used MUI Dialog.
 *
 * components: MoovsDialog
 */
import React, { ReactNode } from "react";

import {
  Typography,
  Dialog,
  Box,
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@mui/material";

import { CrossIcon, InfoIcon } from "../../design-system/icons";
import { white, grayLight, granite } from "../../design-system/colors";
import { useScreenSize } from "../../globals/hooks";

type MoovsDialogPropsOnClickSubmitVariant = {
  open: boolean;
  onClose?: () => void;
  children?: ReactNode;
  dialogTitle?: string;
  acceptButtonText?: string;
  acceptButtonColor?:
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
  closeButtonText?: string;
  hideTopBorder?: boolean;
  hideBottomBorder?: boolean;
  fixedFooter?: boolean;
  size?: false | "xs" | "sm" | "md" | "lg" | "xl";
  titleToolTip?: string;
  acceptDisabled?: boolean;
  hideLoadingIndicator?: boolean;
  removeCloseButton?: boolean;
  SubHeaderComponent?: any;
  acceptButtonVariant?: "contained" | "outlined" | "text";
  removeTopRightCloseButton?: boolean;
  onAccept: () => void;
  auxillaryButtonText?: string;
  onAuxillaryButtonClick?: () => void;
  errorText?: string;
  customFooter?: React.ReactNode;
  dialogMarginTop?: string;
  onExited?: () => void;
};

type MoovsDialogNoAcceptVariant = Omit<
  MoovsDialogPropsOnClickSubmitVariant,
  "onAccept"
>;
type MoovsDialogFormSubmitVariant = {
  submitFormId: string; // override onClick behavior of button and use HTML type="submit" form behavior instead
} & MoovsDialogNoAcceptVariant;

type MoovsDialogProps =
  | MoovsDialogPropsOnClickSubmitVariant
  | MoovsDialogFormSubmitVariant
  | MoovsDialogNoAcceptVariant;

function MoovsDialog(props: MoovsDialogProps) {
  const {
    open,
    onClose,
    children,
    dialogTitle,
    acceptButtonText,
    acceptButtonColor = "primary",
    closeButtonText,
    hideTopBorder,
    hideBottomBorder,
    fixedFooter,
    size = "md",
    titleToolTip,
    acceptDisabled,
    hideLoadingIndicator,
    SubHeaderComponent,
    removeCloseButton,
    acceptButtonVariant,
    removeTopRightCloseButton,
    auxillaryButtonText,
    onAuxillaryButtonClick,
    errorText,
    customFooter,
    dialogMarginTop,
    onExited,
  } = props;

  const { isMobileView } = useScreenSize();

  return (
    <Dialog
      maxWidth={size}
      onClose={onClose}
      open={open}
      fullWidth
      fullScreen={isMobileView && size !== "xs"}
      PaperProps={{
        style: {
          ...(dialogMarginTop && { marginTop: dialogMarginTop }),
        },
      }}
      TransitionProps={{
        onExited,
      }}
    >
      <Box display="flex" flex="1 0 auto" flexDirection="column">
        <Box
          display="flex"
          position="sticky"
          top="0px"
          flexDirection="row"
          px={isMobileView ? 2 : 4}
          py={isMobileView ? 1 : 2}
          justifyContent="space-between"
          alignItems="center"
          bgcolor={white}
          borderBottom={hideTopBorder ? "none" : `1px solid ${grayLight}`}
          zIndex={10}
        >
          <Box display="flex" flex="1" flexDirection="column">
            <Box display="flex">
              <Box display="flex" flex="1" alignItems="center">
                <Typography variant="h3">{dialogTitle}</Typography>

                {titleToolTip && (
                  <Tooltip
                    enterDelay={200}
                    enterNextDelay={200}
                    title={titleToolTip}
                    placement="top"
                  >
                    <IconButton aria-label="info" size="large">
                      <InfoIcon size="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
              {!removeTopRightCloseButton && (
                <IconButton
                  onClick={onClose}
                  style={{ marginRight: "-12px" }}
                  size="large"
                >
                  <CrossIcon color={granite} />
                </IconButton>
              )}
            </Box>
            {SubHeaderComponent && <SubHeaderComponent />}
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          minHeight="0"
          flex={1}
        >
          <Box
            display="flex"
            py={1}
            px={isMobileView ? 2 : 4}
            flexDirection="column"
            overflow="auto"
            flex={1}
          >
            {children}
          </Box>

          {customFooter || (
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              px={isMobileView ? 2 : 4}
              py={2}
              borderTop={hideBottomBorder ? "none" : `1px solid ${grayLight}`}
              bgcolor={white}
              {...(fixedFooter && {
                sx: { position: "sticky", bottom: "0px" },
              })}
            >
              <Box
                display="flex"
                flexDirection="row"
                {...(isMobileView && { flex: 1, justifyContent: "flex-end" })}
              >
                {!removeCloseButton && (
                  <Button color="primary" onClick={onClose}>
                    {closeButtonText || "Close"}
                  </Button>
                )}
                {!!auxillaryButtonText && (
                  <Button color="primary" onClick={onAuxillaryButtonClick}>
                    {auxillaryButtonText}
                  </Button>
                )}
                {!!errorText && (
                  <Typography variant="body2" color="error">
                    {errorText}
                  </Typography>
                )}
                {("onAccept" in props || "submitFormId" in props) && (
                  <Box position="relative" ml={1}>
                    <Button
                      color={acceptButtonColor}
                      variant={acceptButtonVariant || "contained"}
                      disabled={acceptDisabled}
                      // if submitFormId is passed in, use HTML type="submit" behavior instead of onClick
                      {...("submitFormId" in props
                        ? {
                            type: "submit",
                            form: props.submitFormId,
                          }
                        : { onClick: props.onAccept })}
                      sx={{
                        "&:hover": {
                          // override theme hover color
                          ...(acceptButtonColor === "error" && {
                            backgroundColor: "#952c33",
                          }),
                        },
                      }}
                    >
                      {acceptButtonText || "Accept"}
                    </Button>
                    {acceptDisabled && !hideLoadingIndicator && (
                      <CircularProgress
                        size={24}
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          margin: "auto",
                        }}
                      />
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}

export default MoovsDialog;
