import isNil from "lodash/isNil";
import { useEffect, useMemo, useState } from "react";
import isNull from "lodash/isNull";

import { useOperator } from "globals/hooks";
import { CustomerPricingType } from "globals/hooks/utils";
import { AutoPaymentPreference } from "types";
import { currency } from "utils/helpers";

const { NoCharge, FullCharge, Deposit } = AutoPaymentPreference;

type UseAutoChargePaymentType = {
  pricings: CustomerPricingType[];
  skip?: boolean;
};

function useAutoChargePayment({ pricings, skip }: UseAutoChargePaymentType) {
  // hooks
  const { operator } = useOperator();

  // state
  const [autoPaymentAmt, setAutoPaymentAmt] = useState(null);

  // derived state
  const orderTotalAmt = pricings.reduce(
    (orderTotalAmt, pricing) => pricing.orderTotalAmt + orderTotalAmt,
    0
  );

  const roundedOrderTotalAmt = Math.round(orderTotalAmt * 100) / 100;

  const {
    settings: {
      autoPaymentDeposit: { amt, percent },
      autoPaymentPreference,
    },
    enableCreditCardWhenBooking,
  } = operator;

  // effects
  useEffect(() => {
    if (
      skip ||
      !enableCreditCardWhenBooking ||
      autoPaymentPreference === NoCharge ||
      (autoPaymentPreference === Deposit && (amt === 0 || percent === 0))
    ) {
      return;
    }

    if (
      autoPaymentPreference === FullCharge ||
      (autoPaymentPreference === Deposit && percent === 100)
    ) {
      setAutoPaymentAmt(roundedOrderTotalAmt);
    } else if (!isNil(amt)) {
      amt > roundedOrderTotalAmt
        ? setAutoPaymentAmt(roundedOrderTotalAmt)
        : setAutoPaymentAmt(amt);
    } else {
      setAutoPaymentAmt(roundedOrderTotalAmt * percent);
    }
  }, [
    amt,
    autoPaymentPreference,
    enableCreditCardWhenBooking,
    percent,
    roundedOrderTotalAmt,
    skip,
  ]);

  const autoChargeButtonText = useMemo(() => {
    if (!autoPaymentAmt) return null;

    return autoPaymentAmt === roundedOrderTotalAmt
      ? `Pay ${currency(roundedOrderTotalAmt)}`
      : `Pay Deposit: ${currency(autoPaymentAmt)}`;
  }, [autoPaymentAmt, roundedOrderTotalAmt]);

  const autoPaymentType = isNull(autoPaymentAmt)
    ? null
    : autoPaymentAmt === roundedOrderTotalAmt
    ? "full"
    : "partial";

  return {
    autoPaymentAmt,
    autoChargeButtonText,
    autoPaymentType,
  };
}

export { useAutoChargePayment };
