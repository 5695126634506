import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import isNil from "lodash/isNil";

import { Box, Button, Typography, useTheme } from "@mui/material";

import { grayMedium, grayDark, black } from "../../../design-system/colors";
import { TripIcon } from "../../../design-system/icons";
import RoundChip from "../../../design-system/components/RoundChip";
import { Request, RequestStage } from "../../../types";
import { calculateRangeOfTripDates, currency } from "../../../utils/helpers";
import { useAnalytics, useOperatorSlug } from "../../../globals/hooks";
import { CreditCardIcon } from "../../../design-system/icons/CreditCardIcons";
import MoovsTooltip from "../../../components/globals/MoovsTooltip";
import { getPaymentMethodInfoString } from "../../../globals/utils/helpers";
import { toTitleCase } from "../../../globals/utils/string";
import RequestStatusRoundChip from "./RequestStatusRoundChip";

export type OrderCardProps = {
  request: Request;
};

function OrderCard(props: OrderCardProps) {
  const { request } = props;

  const {
    totalAmount,
    orderNumber,
    stage,
    orderType,
    trips,
    preferredPaymentMethod,
    activeTripsCount,
  } = request;

  // hooks
  const history = useHistory();
  const operatorSlug = useOperatorSlug();
  const { track } = useAnalytics();
  const theme = useTheme();

  const vehicleNames = trips
    .map(({ routes }) => routes.map(({ vehicle }) => vehicle?.name))
    .flat();

  const showTotalAmount = useMemo(() => {
    // Check if any trip has a non-null baseRateAmt
    const anyTripHasNonNullBaseRate = trips.some((trip) => {
      return !isNil(trip.routes[0].pricing?.baseRateAmt);
    });

    return anyTripHasNonNullBaseRate && totalAmount >= 0;
  }, [trips, totalAmount]);

  const displayValues = {
    [RequestStage.Quote]: {
      totalAmountCopy: totalAmount ? currency(totalAmount) : "Pending",
      buttonCopy: totalAmount ? "Confirm" : "View",
    },
    RESERVATION: {
      totalAmountCopy: showTotalAmount ? currency(totalAmount) : "",
      buttonCopy: "View",
    },
    UNCONFIRMED: {
      totalAmountCopy: showTotalAmount ? currency(totalAmount) : "",
      buttonCopy: "View",
    },
  };

  // event handlers
  const handleViewOrderClick = () => {
    track("orders_view");

    history.push({
      pathname: `/${operatorSlug}/order/${request.id}`,
      state: {
        from: "orders",
      },
    });
  };

  const { firstTripStartTimeDisplay, lastTripStartTimeDisplay } =
    calculateRangeOfTripDates(trips);
  const { brand } = request?.preferredPaymentMethod?.card || {};

  return (
    <Box
      sx={{
        position: "relative",
        border: `1px solid ${grayMedium}`,
        borderRadius: "4px",
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.03)",
        minHeight: "178px",
        height: "100%",
        width: "100%",
        padding: theme.spacing(2),
        // if an order has multiple trips, show layered cards
        ...(activeTripsCount > 1 && {
          "&::before": {
            content: '" "',
            display: "block",
            position: "absolute",
            right: "8px",
            bottom: "-7px",
            left: "8px",
            height: "7px",
            background: "transparent",
            borderRadius: "0px 0px 4px 4px",
            borderBottom: `1px solid ${grayMedium}`,
            borderLeft: `1px solid ${grayMedium}`,
            borderRight: `1px solid ${grayMedium}`,
          },
        }),
      }}
    >
      <Box display="flex" flexDirection="column" height="100%">
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box display="flex" flexDirection="row">
            <Box mr={1}>
              <Typography
                variant="body2"
                style={{ fontWeight: 700, color: grayDark }}
              >
                {orderNumber}
              </Typography>
            </Box>
            <Typography variant="body2" style={{ color: grayDark }}>
              {toTitleCase(orderType)}
            </Typography>
          </Box>
          <Box>
            <RequestStatusRoundChip request={request} />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="body1" style={{ fontWeight: 700 }}>
            {lastTripStartTimeDisplay ? (
              <>
                {`${firstTripStartTimeDisplay} -`} <br />
                {`${lastTripStartTimeDisplay}`}{" "}
              </>
            ) : (
              firstTripStartTimeDisplay
            )}
          </Typography>
          {/* Only show credit card/pricing if reservation has a price */}
          {displayValues[stage].totalAmountCopy && (
            <Box mt={1} display="flex" flexDirection="row">
              {preferredPaymentMethod && (
                <Box mr={1}>
                  <MoovsTooltip
                    tooltipPretext="Card Used:"
                    tooltipText={getPaymentMethodInfoString(
                      request?.preferredPaymentMethod?.card
                    )}
                    noMaxWidth={true}
                  >
                    <CreditCardIcon brand={brand} />
                  </MoovsTooltip>
                </Box>
              )}
              <Typography
                variant="h3"
                style={{ fontWeight: 500, color: black }}
              >
                {displayValues[stage].totalAmountCopy}
              </Typography>
            </Box>
          )}
        </Box>
        <Box display="flex" flexDirection="row">
          {activeTripsCount > 1 && (
            <Box
              display="flex"
              flexDirection="row"
              flex="1"
              alignItems="center"
              mt={1}
            >
              <TripIcon size="small" />

              <Box ml={1}>
                <Typography variant="body2" style={{ color: black }}>
                  {activeTripsCount} Trips
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginTop="auto"
          alignItems="flex-end"
        >
          <Box display="flex" flexDirection="row" flexWrap="wrap">
            {vehicleNames.map((vehicleName, i) => (
              <Box mr={1} key={i} mt={1}>
                <RoundChip label={vehicleName} />
              </Box>
            ))}
          </Box>
          <Box>
            <Button
              color="primary"
              fullWidth
              variant={
                displayValues[stage].buttonCopy === "View"
                  ? "outlined"
                  : "contained"
              }
              onClick={handleViewOrderClick}
            >
              {displayValues[stage].buttonCopy}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default OrderCard;
