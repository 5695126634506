import React from "react";

import { useTheme } from "@mui/material";

import { grayDark, white } from "design-system/colors";
import StarIconFilled from "design-system/icons/info/StarIconFilled";
import StarsRating from "./StarsRating";
import { TripReview } from "types";

type StarRatingProps = {
  stars: number;
  handleStarsChange: (_, newValue: number) => void;
  tripReview: TripReview;
};

function StarRating(props: StarRatingProps) {
  const { handleStarsChange, stars, tripReview } = props;

  // hook
  const theme = useTheme();

  return (
    <StarsRating
      readOnly={!!tripReview}
      handleStarsChange={handleStarsChange}
      stars={stars}
      filledStarIcon={
        <StarIconFilled
          fillColor={
            stars === 0
              ? theme.palette.primary.light
              : theme.palette.primary.main
          }
          borderColor={theme.palette.primary.main}
        />
      }
      emptyStarIcon={
        <StarIconFilled fillColor={white} borderColor={grayDark} />
      }
    />
  );
}

export default StarRating;
