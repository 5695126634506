import { SelectedPassenger } from "globals/hooks/useDefaultPassenger";
import every from "lodash/every";

import { CreateRequestState } from "pages/new/context/initialState";
import { Contact, Request } from "types";

type GetDefaultPaymentMethodIdParams = {
  trackingFrom: "reserve" | "quoteRequest";
  requestContext: CreateRequestState;
  currentUser: Contact;
  request: Request;
  selectedPassenger: SelectedPassenger;
};

type GetDefaultPaymentMethodForConfirmQuoteParams = {
  request: Request;
  currentUser: Contact;
};

type GetDefaultPaymentMethodForCreateReservationParams = {
  requestContext: CreateRequestState;
  currentUser: Contact;
  selectedPassenger: SelectedPassenger;
};

type GetLinkedPassengerPaymentMethodIdParams = {
  currentUser: Contact;
  defaultPaymentMethodPassenger: SelectedPassenger;
};

export const getDefaultPaymentMethodId = (
  params: GetDefaultPaymentMethodIdParams
) => {
  const {
    trackingFrom,
    requestContext,
    currentUser,
    request,
    selectedPassenger,
  } = params;

  if (trackingFrom === "reserve") {
    return getDefaultPaymentMethodForCreateReservation({
      requestContext,
      currentUser,
      selectedPassenger,
    });
  } else if (trackingFrom === "quoteRequest") {
    return getDefaultPaymentMethodForConfirmQuote({ request, currentUser });
  } else {
    return null;
  }
};

const getDefaultPaymentMethodForConfirmQuote = (
  params: GetDefaultPaymentMethodForConfirmQuoteParams
) => {
  const { request, currentUser } = params;

  // check if trips have any passengers that are temporary passenger or booking contact
  let hasTemporaryPassengerOrBookingContact = false;

  const passengers = request.trips.reduce((acc, trip) => {
    if (
      trip.tempPassenger?.name ||
      trip.returnTrip?.tempPassenger?.name ||
      trip.contact.id === currentUser.id ||
      (trip.returnTrip && trip.returnTrip.contact.id === currentUser.id)
    ) {
      hasTemporaryPassengerOrBookingContact = true;
      return acc;
    }

    trip.contact && acc.push(trip.contact);
    trip.returnTrip?.contact && acc.push(trip.returnTrip.contact);

    return acc;
  }, []);

  if (hasTemporaryPassengerOrBookingContact) return null;

  // check if all linked passengers are the same passenger
  const passengersAreSame = every(
    passengers,
    (passenger) => passenger.id === passengers[0].id
  );

  if (!passengersAreSame) return null;

  // check if linked passenger has any linked credit cards
  const defaultPaymentMethodPassenger = passengers[0];

  return getLinkedPassengerPaymentMethodId({
    currentUser,
    defaultPaymentMethodPassenger,
  });
};

const getDefaultPaymentMethodForCreateReservation = (
  params: GetDefaultPaymentMethodForCreateReservationParams
) => {
  const { requestContext, currentUser, selectedPassenger } = params;

  // if user does not open Additional Trip Info Dialog, selectedPassenger on requestContext is null.
  // thus, we assign the default selectedPassenger
  const outboundTripPassenger =
    requestContext.trip?.selectedPassenger || selectedPassenger;
  const returnTripPassenger =
    requestContext.returnTrip?.selectedPassenger || selectedPassenger;

  if (!outboundTripPassenger || !returnTripPassenger) return null;

  // check if any of the passenger(s) are temporary passenger or booking contact
  if (
    requestContext.trip.temporaryPassenger?.name ||
    requestContext.returnTrip?.temporaryPassenger?.name ||
    outboundTripPassenger.id === currentUser.id ||
    (requestContext.returnTrip && returnTripPassenger.id === currentUser.id)
  ) {
    return null;
  }

  // check if linked passengers are the same passenger
  let defaultPaymentMethodPassenger;
  if (requestContext.trip && requestContext.returnTrip) {
    // round trip
    if (outboundTripPassenger.id === returnTripPassenger.id) {
      defaultPaymentMethodPassenger = outboundTripPassenger;
    } else {
      defaultPaymentMethodPassenger = null;
    }
  } else {
    // one way trip
    defaultPaymentMethodPassenger = outboundTripPassenger;
  }

  if (!defaultPaymentMethodPassenger) return null;

  // check if linked passenger has any linked credit cards
  return getLinkedPassengerPaymentMethodId({
    currentUser,
    defaultPaymentMethodPassenger,
  });
};

const getLinkedPassengerPaymentMethodId = (
  params: GetLinkedPassengerPaymentMethodIdParams
) => {
  const { currentUser, defaultPaymentMethodPassenger } = params;

  const paymentMethodsForPassenger = currentUser.paymentMethods.filter(
    (paymentMethod) => {
      return (
        paymentMethod.linkedPassenger?.id === defaultPaymentMethodPassenger.id
      );
    }
  );

  // if linked passenger has 1 linked card, auto select this credit card
  // if linked passenger has 0 or multiple, do not auto select any credit card
  if (paymentMethodsForPassenger.length === 1) {
    return paymentMethodsForPassenger[0].stripeId;
  }

  return null;
};
