import React, { useState } from "react";

import { Box, Typography } from "@mui/material";

import { useAuth } from "../../../globals/hooks";

import AuthenticatedChargeCustomerBlock from "./components/AuthenticatedChargeCustomerBlock";
import UnauthenticatedChargeCustomerBlock from "./components/UnauthenticatedChargeCustomerBlock";
import { LockIcon } from "../../../design-system/icons";
import { grayLight, white } from "../../../design-system/colors";

type ChargeCustomerBlockProps = {
  contactId?: string;
  farmAffiliateId?: string;
  totalDue: string;
  isCreatingPayment: boolean;
  onCreatePayment: (paymentMethodId: string) => void;
  companyId?: string;
};

function ChargeCustomerBlock(props: ChargeCustomerBlockProps) {
  const {
    onCreatePayment,
    isCreatingPayment,
    contactId,
    farmAffiliateId,
    totalDue,
    companyId,
  } = props;

  // hooks
  const { authStage } = useAuth();

  // state
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState("");

  // event handlers
  const handleSetPaymentMethod = (selectedPaymentMethodId: string) => {
    setSelectedPaymentMethodId(selectedPaymentMethodId);
  };

  // cannot login as a farm affiliate
  const isAuthenticated = authStage === "authenticated" && !farmAffiliateId;

  return (
    <>
      <Box
        p={4}
        display="flex"
        flexDirection="column"
        bgcolor={white}
        border={`1px solid ${grayLight}`}
        boxShadow="0px 4px 15px rgba(0, 0, 0, 0.03)"
      >
        {isAuthenticated ? (
          <>
            <AuthenticatedChargeCustomerBlock
              totalDue={totalDue}
              selectedPaymentMethodId={selectedPaymentMethodId}
              onSetPaymentMethod={handleSetPaymentMethod}
              isLoading={isCreatingPayment}
              onPayClick={() => onCreatePayment(selectedPaymentMethodId)}
              companyId={companyId}
            />
            <Box
              mt={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <LockIcon size="small" />
              <Box ml={1.5}>
                <Typography variant="overline">Secure Checkout</Typography>
              </Box>
            </Box>
          </>
        ) : (
          <UnauthenticatedChargeCustomerBlock
            onCreatePayment={onCreatePayment}
            contactId={contactId}
            farmAffiliateId={farmAffiliateId}
            totalDue={totalDue}
            companyId={companyId}
            isLoading={isCreatingPayment}
          />
        )}
      </Box>
    </>
  );
}

export default ChargeCustomerBlock;
