import React, { Ref, useEffect, useMemo, useState } from "react";
import debounce from "lodash/debounce";
import textParse from "autosuggest-highlight/parse";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/material";

import { AddressIcon } from "../../design-system/icons";
import { ConditionalAutocomplete } from "../react-hook-form/autocomplete/ConditionalAutocomplete";
import { RHFTransform } from "../react-hook-form/autocomplete/RHFAutocomplete";
import { useAnalytics } from "globals/hooks";

const autocompleteService: any = { current: null };

type PlaceType = {
  structured_formatting: {
    secondary_text: string;
    main_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      }
    ];
  };
};

export type ChangeEvent = {
  newValue: string;
  method: "down" | "up" | "escape" | "enter" | "click" | "type";
};

type LocationAutoCompleteProps = {
  id?: string;
  label?: string;
  onChange?: (event: any, value: any) => void;
  error?: boolean;
  helperText?: string;
  value?: any;
  name?: string;
  placeholder?: string;
  inputRef?: Ref<any>;
  className?: any;
  disabled?: boolean;
  fullWidth?: boolean;
  required?: boolean;
  inputClasses?: any;
  transform?: RHFTransform;
  onAdditionalAction?: (value: any, nameOfUpdatedField?: string) => void;
  inputSx?: SxProps;
};

export default function LocationAutoComplete(
  inputProps: LocationAutoCompleteProps
) {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<PlaceType[]>([]);
  const {
    id,
    label,
    required,
    onChange,
    error,
    value,
    inputRef,
    disabled,
    helperText,
    name,
    transform,
    onAdditionalAction,
    inputSx,
  } = inputProps;

  // hooks
  const { track } = useAnalytics();

  // event handlers
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleUpdateField = (event: object, value: any) => {
    if (onChange) {
      onChange(value?.description || "", event);
    }
  };

  const fetch = useMemo(
    () =>
      debounce((input: any, callback: any) => {
        (autocompleteService.current as any).getPlacePredictions(
          input,
          callback
        );
      }, 350),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: PlaceType[]) => {
      if (active) {
        setOptions(results || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, track, fetch]);

  return (
    <ConditionalAutocomplete
      name={name}
      required={required}
      label={label}
      fullWidth
      autoHighlight
      disabled={disabled}
      onFocus={(e) => e.stopPropagation()}
      onChange={handleUpdateField}
      id={id}
      getOptionLabel={(option: any) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete={false}
      includeInputInList
      placeholder={inputProps.placeholder}
      value={value}
      transform={transform}
      isOptionEqualToValue={(option, value) => option?.description === value}
      onAdditionalAction={onAdditionalAction}
      renderInput={(params) => {
        const { InputProps, ...restParams } = params;

        return (
          <TextField
            inputRef={inputRef}
            error={error}
            required={required}
            helperText={helperText}
            {...restParams}
            InputProps={{
              ...InputProps,
              sx: inputSx,
              classes: inputProps.inputClasses,
              autoComplete: "new-password",
            }}
            variant="outlined"
            name={name}
            fullWidth={inputProps.fullWidth}
            onChange={handleChange}
          />
        );
      }}
      renderOption={(props, option) => {
        const matches =
          option?.structured_formatting?.main_text_matched_substrings || [];

        const parts = textParse(
          option?.structured_formatting?.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <AddressIcon />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
