/**
 * @file useConfirmReservation
 * Confirms a quote as a reservation
 */

import { useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { SetupIntent } from "@stripe/stripe-js";
import { useHistory } from "react-router-dom";
import flatMap from "lodash/flatMap";
import isNull from "lodash/isNull";

import {
  CREATE_WALLET_MUTATION,
  CONFIRM_REQUEST_MUTATION,
  LOAD_REQUEST_QUERY,
} from "../../../globals/graphql";
import {
  useOperator,
  useAuth,
  useCurrentUser,
  useScrollToTop,
  useSnackbar,
  useAnalytics,
  useGoogleTracking,
} from "../../../globals/hooks";
import { fromGlobalId } from "../../../utils/auth/helpers";
import {
  getErrorCode,
  getErrorMessage,
} from "../../../moovsErrors/getErrorMessage";
import { PickUpVariant, PromoCode, Request } from "types";
import { getOrderPricing } from "globals/hooks/getOrderPricing";
import { ConfirmReservationFormState } from "pages/order/components/ConfirmReservationForm/form/schemaValidation";
import { useTermsAndConditions } from "pages/new/hooks";
import useIntroTermsAndConditions from "../../../pages/new/hooks/useIntroTermsAndConditions";
import { deriveStrictestCancellationPolicy } from "pages/new/utils/deriveStrictestCancellationPolicy";
import { useCancellationPolicies } from "../form/components/TermsAndConditionsBlock/hooks/useCancellationPolicies";
import { UseOrderPricingType } from "globals/hooks/utils";

type confirmReservationProps = {
  request: Request;
  isGratuityEnabled: boolean;
  confirmReservationState: ConfirmReservationFormState;
};

function useConfirmReservation(props: confirmReservationProps) {
  const { request, isGratuityEnabled, confirmReservationState } = props;

  // hooks
  const stripe = useStripe();
  const stripeElements = useElements();
  const { authStage } = useAuth();
  const currentUser = useCurrentUser();
  const snackbar = useSnackbar();
  const {
    operator: { enableCreditCardWhenBooking, settings },
  } = useOperator();
  const { scrollToTop } = useScrollToTop();
  const history = useHistory();
  const { track } = useAnalytics();
  const { googleTrack } = useGoogleTracking();
  const termsData = useTermsAndConditions();
  const { cancellationPolicies: cancellationPoliciesData } =
    useCancellationPolicies({ trips: null });
  const intro = useIntroTermsAndConditions();

  // refs
  // used to make sure data passed to confirmReservation when a callback
  // is updated (otherwise is a closure). This also requires a
  // setTimeout to wait for next render
  const authStageRef = useRef(authStage);
  const currentUserRef = useRef(currentUser);

  // effects
  useEffect(() => {
    authStageRef.current = authStage;
  }, [authStage]);

  useEffect(() => {
    currentUserRef.current = currentUser;
  }, [currentUser]);

  // state
  const [isConfirmingReservation, setIsConfirmingReservation] = useState(false);
  const [isLastMinute, setIsLastMinute] = useState(false);

  // mutations
  const [createWallet] = useMutation(CREATE_WALLET_MUTATION, {
    onError(error) {
      setIsConfirmingReservation(false);
      const errorMessage = getErrorMessage(error) || "Error creating payment.";

      snackbar.error(errorMessage);
      console.log(error);
    },
  });
  const [confirmRequest] = useMutation(CONFIRM_REQUEST_MUTATION, {
    refetchQueries: [
      {
        query: LOAD_REQUEST_QUERY,
        variables: { id: request?.id },
      },
    ],
    onCompleted(data) {
      const { autoPaymentType, promoCodeCustomerInput } =
        confirmReservationState;

      const trackingChargeType = !autoPaymentType
        ? "no charge"
        : autoPaymentType === "partial"
        ? "partial charge"
        : "full charge";

      const hasChildSeatApplied = [
        "rearFacingSeatAmt",
        "boosterSeatAmt",
        "forwardFacingSeatAmt",
      ].some(
        (childSeatField) =>
          !isNull(
            data.confirmRequest.request.trips[0].routes[0].pricing[
              childSeatField
            ]
          )
      );

      track("reservations_newReceived", {
        type: "Quote",
        charge_type: trackingChargeType,
        ...(promoCodeCustomerInput && { promo: "code_applied" }),
        ...(hasChildSeatApplied && { child_seat: "seat_added" }),
      });

      // linked passenger tracking
      let linkedPassengerCount = 0;
      data.confirmRequest.request.trips.forEach((trip) => {
        if (
          !trip.tempPassenger?.name &&
          trip.contact.id !== data.confirmRequest.request.bookingContact.id
        ) {
          linkedPassengerCount++;
        }
      });

      if (linkedPassengerCount) {
        track("trip_linkedPassengerAdded", {
          totalCount: linkedPassengerCount,
        });
      }

      googleTrack(
        "moovs_confirm_reservation",
        data.confirmRequest.request.totalAmount
      );

      setIsConfirmingReservation(false);
      scrollToTop();

      history.push({
        pathname: history.location.pathname,
        search: "?successDialog=true",
        state: { autoPaymentType },
      });
    },
    onError(error) {
      setIsConfirmingReservation(false);
      if (
        getErrorCode(error) === "MOOVS_TRIP_TIMING_ERROR" ||
        // we present the same error message for archived quotes as
        // customer has no concept of "archived"
        getErrorCode(error) === "MOOVS_ARCHIVED_TRIP_CONFIRMATION_ERROR"
      ) {
        setIsLastMinute(true);
      } else {
        const errorMessage =
          getErrorMessage(error) || "Error confirming reservation.";
        snackbar.error(errorMessage);
      }
      console.log(error);
    },
  });

  // event handlers
  const createStripeWalletAndCard = async (
    contactId: string,
    cardholderName: string
  ): Promise<SetupIntent> => {
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = stripeElements.getElement(CardElement);

    const { data } = await createWallet({
      variables: {
        input: {
          contactId,
        },
      },
    });

    if (!data) {
      // TODO: figure out if this is this necessary?
      return;
    }

    const confirmCardSetupResult = await stripe.confirmCardSetup(
      data.createWallet.clientSecret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: cardholderName,
            ...(!!request.bookingContact.email && {
              email: request.bookingContact.email,
            }),
          },
        },
      }
    );

    if (confirmCardSetupResult.error) {
      console.error(confirmCardSetupResult.error);
      snackbar.error(confirmCardSetupResult.error.message);
      return;
    }

    return confirmCardSetupResult.setupIntent;
  };

  const confirmReservation = async (
    confirmReservationFormState: ConfirmReservationFormState
  ) => {
    const additionalChargePerTrip = calculateAdditionalChargePerTrip(
      request,
      confirmReservationFormState.driverGratuityPctCustomerInput,
      isGratuityEnabled,
      confirmReservationFormState.pickUpVariantSelected,
      settings.pricingLayout.meetGreetAmt,
      confirmReservationFormState.promoCodeCustomerInput,
      confirmReservationFormState.additonalItemsByTrip
    );

    const authenticatedCustomerWithCreditCardOnFile =
      authStageRef.current === "authenticated" &&
      !!currentUserRef?.current?.paymentMethods?.length;

    if (
      enableCreditCardWhenBooking &&
      !authenticatedCustomerWithCreditCardOnFile &&
      (!stripe || !stripeElements)
    ) {
      // if stripe elements fail to load, prevent form submission.
      snackbar.error("error loading payment elements");
    }

    setIsConfirmingReservation(true);

    const requestId = request.id;
    const contactId = request.bookingContact.id;

    let stripePaymentMethodId;
    let setupIntent;

    // create stripe wallet
    if (
      enableCreditCardWhenBooking &&
      !authenticatedCustomerWithCreditCardOnFile
    ) {
      setupIntent = await createStripeWalletAndCard(
        contactId,
        confirmReservationFormState.paymentMethod.cardholderName
      );
      stripePaymentMethodId = setupIntent?.payment_method;

      if (!stripePaymentMethodId) {
        setIsConfirmingReservation(false);
        return;
      }
    }

    // if authenticated and using an existing card, set stripePaymentMethodId equal to that card
    if (
      enableCreditCardWhenBooking &&
      authenticatedCustomerWithCreditCardOnFile
    ) {
      stripePaymentMethodId = confirmReservationFormState.stripePaymentMethodId;
    }

    const printedName = confirmReservationFormState.printedName?.trim();

    // format terms & conditions and cancellation policy
    const terms = termsData?.map(({ name, description }) => {
      return { name, description };
    });

    const selectedVehicleIds = request.trips.flatMap((trip) => {
      return trip.routes.map((route) => route.vehicle.id);
    });

    const cancellationPolicies = deriveStrictestCancellationPolicy({
      cancellationPoliciesData,
      selectedVehicleIds,
    });

    confirmRequest({
      variables: {
        input: {
          stripePaymentMethodId,
          requestId,
          clientSecret: setupIntent?.id,
          additionalChargePerTrip,
          printedName,
          intro,
          terms,
          cancellationPolicies,
          autoPaymentAmt: confirmReservationFormState.autoPaymentAmt,
        },
      },
    });
  };

  return {
    confirmReservation,
    isConfirmingReservation,
    isLastMinute,
  };
}

export { useConfirmReservation };

// used to create shaped object used in confirm mutation
const calculateAdditionalChargePerTrip = (
  request: Request,
  driverGratuityPctCustomerInput: number | "cash",
  isGratuityEnabled: boolean,
  pickUpVariantSelected: PickUpVariant,
  pricingLayoutMeetGreet: number,
  userAppliedPromoCode?: Pick<
    PromoCode,
    "id" | "promoCodeName" | "promoCodeAmt" | "promoCodePercent"
  >,
  additonalItemsByTrip?: {
    childSeats: {
      boosterSeatAmt?: number;
      boosterSeatQuantity?: number;
      forwardFacingSeatAmt?: number;
      forwardFacingSeatQuantity?: number;
      rearFacingSeatAmt?: number;
      rearFacingSeatQuantity?: number;
    };
  }[]
):
  | { tripId: string; driverGratuityAmt: number; meetGreetAmt: number }[]
  | [] => {
  const orderPricing = getOrderPricing({
    request,
    pricingLayoutMeetGreet,
    pickUpVariantSelected: pickUpVariantSelected,
    shouldRenderDriverGratuity: isGratuityEnabled,
    userSelectedDriverGratuityPercent:
      driverGratuityPctCustomerInput === "cash"
        ? 0
        : driverGratuityPctCustomerInput,
    additonalItemsByTrip,
  });

  return flatMap(orderPricing, (tripPricing) => {
    // construct childSeatInput obj from pricing
    const childSeatInput = constructChildSeatInput(tripPricing.pricing);

    const outboundTrip = {
      tripId: fromGlobalId(tripPricing.tripId).id,
      driverGratuityAmt: tripPricing.pricing.driverGratuity.value || 0,
      meetGreetAmt: tripPricing.pricing.meetGreet.value,
      ...(userAppliedPromoCode && {
        promoCodeInput: {
          promoCodeId: userAppliedPromoCode.id,
          promoCodeAmt: userAppliedPromoCode.promoCodeAmt,
          promoCodePercent: userAppliedPromoCode.promoCodePercent,
        },
      }),
      childSeatInput,
    };

    const isRoundTrip = !!tripPricing.returnTripPricing;
    if (!isRoundTrip) {
      return outboundTrip;
    }

    const returnChildSeatInput = constructChildSeatInput(
      tripPricing.returnTripPricing.pricing
    );

    const returnTrip = {
      tripId: fromGlobalId(tripPricing.returnTripPricing.tripId).id,
      driverGratuityAmt:
        tripPricing.returnTripPricing.pricing.driverGratuity.value || 0,
      meetGreetAmt: tripPricing.returnTripPricing.pricing.meetGreet.value,
      childSeatInput: returnChildSeatInput,
    };
    return [outboundTrip, returnTrip];
  });
};

const constructChildSeatInput = (tripPricing: UseOrderPricingType) => {
  const { boosterSeat, forwardFacingSeat, rearFacingSeat } = tripPricing;

  const childSeatInput = {
    boosterSeatAmt: boosterSeat.value,
    boosterSeatQuantity: boosterSeat.quantity,
    forwardFacingSeatAmt: forwardFacingSeat.value,
    forwardFacingSeatQuantity: forwardFacingSeat.quantity,
    rearFacingSeatAmt: rearFacingSeat.value,
    rearFacingSeatQuantity: rearFacingSeat.quantity,
  };

  return childSeatInput;
};
