import { useState } from "react";
import first from "lodash/first";
import last from "lodash/last";

const addTripErrorsIntialState = {
  pickUpDateTimeError: "",
  pickUpLocationError: "",
  dropOffLocationError: "",
};

type stopsTypeProps = {
  location: string;
  dateTime: string | null;
  stopIndex: number;
}[];

function useAddTripErrors() {
  // state
  const [tripErrors, setTripErrors] = useState(addTripErrorsIntialState);

  const validateAddTrip = (stops: stopsTypeProps) => {
    const pickUpDateTimeError = !!first(stops).dateTime
      ? ""
      : "Please enter a date & time";
    const pickUpLocationError = !!first(stops).location
      ? ""
      : "Please enter location";
    const dropOffLocationError = !!last(stops).location
      ? ""
      : "Please enter location";

    setTripErrors({
      pickUpLocationError,
      pickUpDateTimeError,
      dropOffLocationError,
    });

    return !!(
      pickUpLocationError ||
      dropOffLocationError ||
      pickUpDateTimeError
    );
  };

  const setErrorsToIntialState = () => setTripErrors(addTripErrorsIntialState);

  return { tripErrors, validateAddTrip, setErrorsToIntialState };
}

export { useAddTripErrors };
