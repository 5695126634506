import { useEffect } from "react";
import { useHistory } from "react-router-dom";

type UseGclidInUrlParams = {
  gclid: string;
};

export const useGclidInUrl = (params: UseGclidInUrlParams) => {
  const { gclid } = params;

  // hooks
  const history = useHistory();

  // if request was saved with a gclid, add it to the url so it can be tracked again
  useEffect(() => {
    if (gclid) {
      const queryParams = new URLSearchParams(history.location.search);
      queryParams.set("gclid", gclid);
      history.replace({
        ...history.location,
        search: queryParams.toString(),
      });
    }
  }, [gclid, history]);
};
