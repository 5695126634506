/**
 * @file useAutomatedPricing.tsx
 * Hook that helps calculate quote pricing when a vehicle
 * has an automated price added to it.
 */
import first from "lodash/first";
import isNil from "lodash/isNil";

import { useOperator } from "./useOperator";
import { PickUpVariant, PricingLayout, PromoCode } from "../../types";
import { CustomerPricingType } from "./utils";
import { CreateStopState } from "pages/new/context/initialState";

type UseAutomatedPricingParams = {
  trips: {
    stops: CreateStopState[];
    routes: {
      vehicle?: {
        id: string;
        baseRateAutomation?: {
          total: number;
        };
        returnBaseRateAutomation?: {
          total: number;
        };
      };
      pricing?: { driverGratuityAmt?: number };
    }[];
  }[];
  driverGratuityPctCustomerInput?: number | "cash";
  pickUpVariantSelected: PickUpVariant;
  userAppliedPromoCode?: Pick<
    PromoCode,
    "id" | "promoCodeName" | "promoCodeAmt" | "promoCodePercent"
  >;
  additonalItemsForOutboundTrip?: {
    childSeats: {
      boosterSeatAmt?: number;
      boosterSeatQuantity?: number;
      forwardFacingSeatAmt?: number;
      forwardFacingSeatQuantity?: number;
      rearFacingSeatAmt?: number;
      rearFacingSeatQuantity?: number;
    };
  };
};

function useAutomatedPricing(
  params: UseAutomatedPricingParams
): CustomerPricingType {
  const {
    trips,
    pickUpVariantSelected,
    userAppliedPromoCode,
    additonalItemsForOutboundTrip,
  } = params;

  // convert cash gratuity to 0 for price calculations
  const driverGratuityPctCustomerInput =
    params.driverGratuityPctCustomerInput === "cash"
      ? 0
      : params.driverGratuityPctCustomerInput;

  const { baseRateAutomation, returnBaseRateAutomation } = first(
    first(trips).routes
  ).vehicle;

  const automatedBaseRate = baseRateAutomation?.total || null;
  const returnAutomatedBaseRate = returnBaseRateAutomation?.total || null;

  // hooks
  const {
    operator: { pricingLayout },
  } = useOperator();

  const {
    tripPricing: outboundTripPricing,
    tripTotalAmt: outboundTripTotalAmt,
  } = calculateCreateTripPricing({
    baseRateAmt: automatedBaseRate || 0,
    pricingLayout,
    driverGratuityPctCustomerInput,
    pickUpVariantSelected,
    isAirportPickUpStop:
      !!trips[0].stops[0].trackedFlight ||
      !!trips[0].stops[0].pickUpGooglePlaceTypes?.includes("airport") ||
      !!trips[0].stops[0].airport?.icaoCode,
    userAppliedPromoCode, // promo code only applies to first trip
    childSeatInput: additonalItemsForOutboundTrip?.childSeats,
  });

  let returnTripPricing;
  let returnTripTotalAmt;

  if (returnAutomatedBaseRate !== null) {
    ({ tripPricing: returnTripPricing, tripTotalAmt: returnTripTotalAmt } =
      calculateCreateTripPricing({
        baseRateAmt: returnAutomatedBaseRate,
        pricingLayout,
        driverGratuityPctCustomerInput,
        pickUpVariantSelected,
        isAirportPickUpStop:
          !!trips[1]?.stops[0]?.trackedFlight ||
          !!trips[1].stops[0].pickUpGooglePlaceTypes?.includes("airport") ||
          !!trips[1]?.stops[0]?.airport?.icaoCode,
        childSeatInput: additonalItemsForOutboundTrip?.childSeats,
      }));
  }

  const orderTotalAmt = outboundTripTotalAmt + (returnTripTotalAmt || 0);

  return {
    outboundTripPricing,
    outboundTripTotalAmt,
    returnTripPricing,
    returnTripTotalAmt,
    orderTotalAmt,
  };
}

export { useAutomatedPricing };

type CalculateTripPricingParams = {
  baseRateAmt: number;
  pricingLayout: PricingLayout;
  driverGratuityPctCustomerInput?: number;
  pickUpVariantSelected?: PickUpVariant;
  isAirportPickUpStop?: boolean;
  userAppliedPromoCode?: Pick<
    PromoCode,
    "id" | "promoCodeName" | "promoCodeAmt" | "promoCodePercent"
  >;
  childSeatInput?: {
    boosterSeatAmt?: number;
    boosterSeatQuantity?: number;
    forwardFacingSeatAmt?: number;
    forwardFacingSeatQuantity?: number;
    rearFacingSeatAmt?: number;
    rearFacingSeatQuantity?: number;
  };
};

type CalculatePricingParams = {
  percent?: number;
  amt?: number;
  baseRateAmt: number;
};

const calculatePricing = (params: CalculatePricingParams) => {
  const { percent, amt, baseRateAmt } = params;

  if (isNil(amt) && isNil(percent)) return null;

  const amount = !isNil(percent) ? percent * baseRateAmt : amt;
  return isNaN(amount)
    ? 0
    : !isNil(amount)
    ? Math.round(amount * 100) / 100
    : null;
};

export const calculateCreateTripPricing = (
  params: CalculateTripPricingParams
) => {
  const {
    baseRateAmt,
    pricingLayout,
    driverGratuityPctCustomerInput,
    pickUpVariantSelected,
    isAirportPickUpStop,
    userAppliedPromoCode,
    childSeatInput: {
      boosterSeatAmt,
      boosterSeatQuantity,
      forwardFacingSeatAmt,
      forwardFacingSeatQuantity,
      rearFacingSeatAmt,
      rearFacingSeatQuantity,
    } = {},
  } = params;

  const {
    driverGratuityPercent,
    driverGratuityAmt,
    promoDiscountPercent,
    promoDiscountAmt,
    taxPercent,
    taxAmt,
    tollsAmt,
    meetGreetAmt,
    otherPercent,
    otherAmt,
    otherName,
    other2Percent,
    other2Amt,
    other2Name,
    other3Percent,
    other3Amt,
    other3Name,
  } = pricingLayout || {};

  // In case where trip already has driverGratuityAmt, we use it
  const normalizedDriverGratuity =
    driverGratuityAmt || driverGratuityPercent
      ? {
          amt: driverGratuityAmt,
          percent: driverGratuityPercent,
        }
      : {
          amt: calculatePricing({
            baseRateAmt,
            percent: driverGratuityPctCustomerInput / 100,
          }),
        };

  const tripPricing = {
    baseRate: {
      label: "Base Rate",
      value: baseRateAmt,
    },
    driverGratuity: {
      label: "Driver Gratuity",
      value: calculatePricing({
        baseRateAmt,
        ...normalizedDriverGratuity,
      }),
    },
    promoDiscount: {
      label: "Discount",
      value: calculatePricing({
        baseRateAmt,
        percent: promoDiscountPercent,
        amt: promoDiscountAmt,
      }),
      isNeg: true,
    },
    tax: {
      label: "Tax",
      value: calculatePricing({
        baseRateAmt,
        percent: taxPercent,
        amt: taxAmt,
      }),
    },
    tolls: {
      label: "Tolls",
      value: calculatePricing({
        baseRateAmt,
        amt: tollsAmt,
      }),
    },
    meetGreet: {
      label: "Meet & Greet",
      value: calculatePricing({
        baseRateAmt,
        amt:
          isAirportPickUpStop &&
          pickUpVariantSelected === PickUpVariant.MeetGreet
            ? meetGreetAmt
            : null,
      }),
      selected:
        isAirportPickUpStop &&
        pickUpVariantSelected === PickUpVariant.MeetGreet,
    },
    rearFacingSeat: {
      label:
        rearFacingSeatQuantity > 1
          ? `${rearFacingSeatQuantity} X Rear-Facing Seat`
          : "Rear-Facing Seat",
      value:
        rearFacingSeatAmt === 0
          ? 0
          : calculatePricing({ baseRateAmt, amt: rearFacingSeatAmt }),
      quantity: rearFacingSeatQuantity,
    },
    forwardFacingSeat: {
      label:
        forwardFacingSeatQuantity > 1
          ? `${forwardFacingSeatQuantity} X Forward-Facing Seat`
          : "Forward-Facing Seat",
      value:
        forwardFacingSeatAmt === 0
          ? 0
          : calculatePricing({ baseRateAmt, amt: forwardFacingSeatAmt }),
      quantity: forwardFacingSeatQuantity,
    },
    boosterSeat: {
      label:
        boosterSeatQuantity > 1
          ? `${boosterSeatQuantity} X Booster Seat`
          : "Booster Seat",
      value:
        boosterSeatAmt === 0
          ? 0
          : calculatePricing({ baseRateAmt, amt: boosterSeatAmt }),
      quantity: boosterSeatQuantity,
    },
    other: {
      label: otherName || "Other",
      value: calculatePricing({
        baseRateAmt,
        percent: otherPercent,
        amt: otherAmt,
      }),
    },
    other2: {
      label: other2Name || "Other 2",
      value: calculatePricing({
        baseRateAmt,
        percent: other2Percent,
        amt: other2Amt,
      }),
    },
    other3: {
      label: other3Name || "Other 3",
      value: calculatePricing({
        baseRateAmt,
        percent: other3Percent,
        amt: other3Amt,
      }),
    },
    promoCode: userAppliedPromoCode
      ? {
          label: `Promo Code (${userAppliedPromoCode.promoCodeName})`,
          value: calculatePricing({
            baseRateAmt,
            percent: userAppliedPromoCode.promoCodePercent,
            amt: userAppliedPromoCode.promoCodeAmt,
          }),
          isNeg: true,
          removeField: "promoCodeCustomerInput",
        }
      : {
          label: "Promo Code",
          value: null,
        },
  };

  const tripTotalAmt =
    tripPricing.baseRate.value +
    tripPricing.driverGratuity.value -
    tripPricing.promoDiscount.value +
    tripPricing.tax.value +
    tripPricing.tolls.value +
    tripPricing.meetGreet.value +
    tripPricing.boosterSeat.value +
    tripPricing.forwardFacingSeat.value +
    tripPricing.rearFacingSeat.value +
    tripPricing.other.value +
    tripPricing.other2.value +
    tripPricing.other3.value -
    tripPricing.promoCode.value;

  return { tripPricing, tripTotalAmt };
};
