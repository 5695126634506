import { useMutation } from "@apollo/client";
import React from "react";

import { UPDATE_BOOKING_CONTACT_AS_RECIPIENT_FOR_TRIP_NOTIFICATIONS_MUTATION } from "globals/graphql/tripNotifications.graphql";
import { useAnalytics, useSnackbar } from "globals/hooks";
import getRecipient from "globals/utils/getRecipients";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { useEffect, useState } from "react";
import { TextRecipientEnum } from "types";
import TripBell from "./TripBell";

type TripBellOnUpdateProps = {
  tripId: string;
  statusUpdateTextTo: TextRecipientEnum;
};

function TripBellOnUpdate(props: TripBellOnUpdateProps) {
  const { tripId, statusUpdateTextTo } = props;

  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  const isTripStatusRecipientPassenger =
    statusUpdateTextTo === TextRecipientEnum.Passenger ||
    statusUpdateTextTo === TextRecipientEnum.Both;

  const isTripStatusRecipientBookingContact =
    statusUpdateTextTo === TextRecipientEnum.BookingContact ||
    statusUpdateTextTo === TextRecipientEnum.Both;

  const [isNotificationActive, setIsNotificationActive] = useState(
    isTripStatusRecipientBookingContact
  );

  const [updateTripTextRecipient] = useMutation(
    UPDATE_BOOKING_CONTACT_AS_RECIPIENT_FOR_TRIP_NOTIFICATIONS_MUTATION,
    {
      onCompleted() {
        isNotificationActive
          ? track("statusNotifications_turnedOff")
          : track("statusNotifications_turnedOn");
        setIsNotificationActive(
          (isNotificationActive) => !isNotificationActive
        );
        snackbar.success(
          "Notification settings for this trip has been updated!"
        );
      },
      onError(error) {
        snackbar.error(
          getErrorMessage(error) ||
            "There were issues updating sms recipients settings for this trip"
        );
      },
    }
  );

  const handleToggleTripNotification = () => {
    updateTripTextRecipient({
      variables: {
        input: {
          tripId,
          statusUpdateTextTo: getRecipient(
            isTripStatusRecipientPassenger,
            !isNotificationActive
          ),
        },
      },
    });
  };

  useEffect(() => {
    if (isTripStatusRecipientBookingContact !== isNotificationActive) {
      setIsNotificationActive(isTripStatusRecipientBookingContact);
    }
  }, [isTripStatusRecipientBookingContact, isNotificationActive]);

  return (
    <TripBell
      handleToggleTripNotification={handleToggleTripNotification}
      isNotificationActive={isNotificationActive}
    />
  );
}

export default TripBellOnUpdate;
