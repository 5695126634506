import React from "react";
import startCase from "lodash/startCase";

import { Box, Card, Typography } from "@mui/material";

import CircleGreenCheckIcon from "../../../../../../design-system/icons/CircleGreenCheckIcon";
import { PaymentMethod } from "../../../../../../types";
import { successGreen, white } from "../../../../../../design-system/colors";

type CardListItemDesktopProps = { paymentMethod: PaymentMethod };

function CardListItemDesktop(props: CardListItemDesktopProps) {
  const { paymentMethod } = props;
  const { card } = paymentMethod;
  const { expMonth, expYear, last4, brand } = card;

  return (
    <Card variant="outlined">
      <Box
        display="flex"
        flex="1"
        flexDirection="row"
        alignItems="center"
        width="100%"
        p={2}
      >
        <Box display="flex" flexDirection="column" flex="1">
          <Typography variant="overline">brand</Typography>
          <Typography variant="body2">{startCase(brand)}</Typography>
        </Box>
        <Box display="flex" flexDirection="column" flex="2">
          <Typography variant="overline">card number</Typography>
          <Typography variant="body2">{`****-${last4}`}</Typography>
        </Box>
        <Box display="flex" flexDirection="column" flex="3">
          <Typography variant="overline">exp. date</Typography>
          <Typography variant="body2">{`${expMonth}/${expYear}`}</Typography>
        </Box>
        <CircleGreenCheckIcon bgcolor={successGreen} color={white} />
      </Box>
    </Card>
  );
}

export default CardListItemDesktop;
