import React from "react";

type RefrigeratorIconProps = {
  color?: string;
  size: "small";
};

const RefrigeratorIcon = (props: RefrigeratorIconProps) => {
  const { color } = props;

  // size === small
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.1059 13.7778V14.6667M9.89378 13.7778V14.6667M1.1665 9.33337H11.8332M4.56044 4.00004V6.66671M8.43923 4.00004V6.66671M6.49984 1.33337V9.33337M5.04529 11.1112H7.95438M1.84465 1.33337H11.155C11.5296 1.33337 11.8332 1.61169 11.8332 1.955V13.1562C11.8332 13.4995 11.5296 13.7778 11.155 13.7778H1.84465C1.47012 13.7778 1.1665 13.4995 1.1665 13.1562V1.955C1.1665 1.61169 1.47012 1.33337 1.84465 1.33337Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RefrigeratorIcon;
