import { useState } from "react";

export type ViewPhotoClickParams = {
  name: string;
  url: string;
};

function useViewChildSeatPhoto() {
  // state
  const [photoUrl, setPhotoUrl] = useState(null);
  const [photoDialogOpen, setPhotoDialogOpen] = useState(false);
  const [photoDialogTitle, setPhotoDialogTitle] = useState(null);

  // event handlers
  const handleViewPhotoClick = (params: ViewPhotoClickParams) => {
    const { url, name } = params;
    setPhotoUrl(url);
    setPhotoDialogTitle(name);
    setPhotoDialogOpen(true);
  };

  const handleClosePhotoDialog = () => {
    setPhotoDialogOpen(false);
    setPhotoUrl(null);
    setPhotoDialogTitle(null);
  };

  return {
    photoUrl,
    photoDialogTitle,
    photoDialogOpen,
    handleViewPhotoClick,
    handleClosePhotoDialog,
  };
}

export { useViewChildSeatPhoto };
