import React from "react";

import { Box, Link, Typography } from "@mui/material";

import { currency } from "../../utils/helpers";
import { OpenIcon } from "../../design-system/icons";
import { grayDark } from "../../design-system/colors";
import { Contact } from "types";

type OrderListItemMobileProps = {
  orderNumber: string;
  reservationLink: string;
  requestTotals: {
    totalAmount: number;
    amountDue: number;
    totalPaid: number;
  };
  bookingContact?: Contact;
};

function OrderListItemMobile(props: OrderListItemMobileProps) {
  const { orderNumber, reservationLink, requestTotals, bookingContact } = props;

  const { totalAmount, amountDue, totalPaid } = requestTotals;

  return (
    <Box display="flex" flexDirection="column" flex="1" px={2} py="10px">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent={"space-between"}
        flex="1"
        mb={1}
      >
        <Box>
          <Typography variant="overline">Order</Typography>
          <Box display="flex">
            <Typography
              variant="button"
              color="primary"
              style={{ fontWeight: 700 }}
            >
              <Link target="_blank" href={reservationLink}>
                {orderNumber}
              </Link>
            </Typography>
            <Box mt="6px" ml={1}>
              <Link target="_blank" href={reservationLink}>
                <OpenIcon color={grayDark} size="small" />
              </Link>
            </Box>
          </Box>
        </Box>

        {bookingContact && (
          <Box
            display="flex"
            flexDirection="column"
            flex="1"
            alignItems="flex-end"
          >
            <Typography variant="overline">Booking Contact</Typography>
            <Typography variant="subtitle2">{`${bookingContact.firstName} ${bookingContact.lastName}`}</Typography>
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column" flex="1">
          <Typography variant="overline">Amount</Typography>
          <Typography variant="subtitle2">{currency(totalAmount)}</Typography>
        </Box>
        <Box display="flex" flexDirection="column" flex="1">
          <Typography variant="overline">Paid</Typography>
          <Typography variant="subtitle2">{currency(totalPaid)}</Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          flex="1"
          alignItems="flex-end"
        >
          <Typography variant="overline">Amount Due</Typography>
          <Typography variant="subtitle2">{currency(amountDue)}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default OrderListItemMobile;
