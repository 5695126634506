import React from "react";
import moment from "moment-timezone";

import { Box, Typography, Tooltip, Button, useTheme } from "@mui/material";

import {
  SubPayment,
  PaymentAction,
  PaymentMethodEnum,
  PaymentRefund,
} from "types";
import { currency } from "utils/helpers";
import { toTitleCase } from "globals/utils/string";
import { OpenIcon } from "design-system/icons";
import { useViewReceiptClick } from "./hooks/useViewReceiptClick";

type PaymentListItemProps = { subPayment?: SubPayment; refund?: PaymentRefund };

function PaymentListItem(props: PaymentListItemProps) {
  const { subPayment, refund } = props;

  // hooks
  const theme = useTheme();
  const handleViewReceipt = useViewReceiptClick({ subPayment, refund });

  if (!subPayment && !refund) return null;

  // derived state
  const note = refund ? refund.note : subPayment.payment.note;
  const createdAt = refund ? refund.createdAt : subPayment.createdAt;
  const amount = refund ? refund.amount : subPayment.amount;
  const method = refund ? refund.method : subPayment.payment.method;
  const payment = refund ? refund.payment : subPayment.payment;

  const methodOfPayment =
    method === PaymentMethodEnum.Card
      ? `Card ****-${payment.paymentMethod.card.last4}`
      : method === PaymentMethodEnum.GenericCard
      ? "Credit Card"
      : toTitleCase(method);

  return (
    <Box display="flex" flex="1" flexDirection="row" alignItems="center">
      <Box display="flex" flex=".2">
        <Typography variant="h6">
          {toTitleCase(refund ? PaymentAction.Refund : PaymentAction.Charge)}
        </Typography>
      </Box>
      <Box display="flex" flex=".4">
        <Typography variant="h6">{methodOfPayment}</Typography>
      </Box>
      <Box display="flex" flex=".4" minWidth={10}>
        <Tooltip title={note || ""} style={{ cursor: "pointer" }}>
          <Typography variant="h6" noWrap>
            {note || "-"}
          </Typography>
        </Tooltip>
      </Box>
      <Box display="flex" flex=".5" ml={3}>
        <Typography variant="h6">{moment(createdAt).format("llll")}</Typography>
      </Box>
      <Box display="flex" flex=".4" justifyContent="flex-end">
        <Typography variant="h6">{`${refund ? "-" : ""} ${currency(
          amount
        )}`}</Typography>
      </Box>
      <Box display="flex" flex=".4" justifyContent="flex-end">
        {method === PaymentMethodEnum.Card && (
          <Button onClick={handleViewReceipt}>
            <Typography variant="h6" mr={1}>
              View Receipt
            </Typography>
            <OpenIcon size="small" color={theme.palette.primary.main} />
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default PaymentListItem;
