import React from "react";

import { Button } from "@mui/material";

import FlightIcon from "design-system/icons/info/FlightIcon";
import { useScreenSize } from "globals/hooks";

type ShowHideFlightDetailsButtonProps = {
  open: boolean;
  onClick: () => void;
};

function ShowHideFlightDetailsButton(props: ShowHideFlightDetailsButtonProps) {
  const { open, onClick } = props;

  const { isMobileView } = useScreenSize();

  return (
    <Button
      variant="outlined"
      endIcon={<FlightIcon size="small" />}
      onClick={onClick}
      fullWidth={isMobileView}
      sx={{
        ...(isMobileView && { my: 1 }),
      }}
    >
      {`${open ? "Hide" : "Enter"} Flight Details`}
    </Button>
  );
}

export default ShowHideFlightDetailsButton;
