import React from "react";

type ACIconProps = {
  color?: string;
  size: "small";
};

const ACIcon = (props: ACIconProps) => {
  const { color } = props;

  // size === small
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3779 7.51107C14.4579 7.51107 15.3334 6.63554 15.3334 5.55551V2.62218C15.3334 1.54216 14.4579 0.666626 13.3779 0.666626H2.6223C1.54228 0.666626 0.666748 1.54216 0.666748 2.62218V5.55551C0.666748 6.63554 1.54228 7.51107 2.6223 7.51107M13.3779 7.51107H2.6223M13.3779 7.51107V6.53329C13.3779 6.27397 13.2748 6.02527 13.0915 5.8419C12.9081 5.65853 12.6594 5.55551 12.4001 5.55551H3.60008C3.34076 5.55551 3.09206 5.65853 2.90869 5.8419C2.72532 6.02527 2.6223 6.27397 2.6223 6.53329V7.51107M3.60008 2.62218H12.4001M3.60008 9.46663C3.60008 9.46663 2.6223 9.46663 2.6223 10.9333C2.6223 12.4 3.60008 12.4 3.60008 13.8666C3.60008 15.3333 2.6223 15.3333 2.6223 15.3333M13.3779 9.46663C13.3779 9.46663 12.4001 9.46663 12.4001 10.9333C12.4001 12.4 13.3779 12.4 13.3779 13.8666C13.3779 15.3333 12.4001 15.3333 12.4001 15.3333M8.00008 10.4444V14.3555M6.30657 11.4222L9.69359 13.3777M6.30657 13.3777L9.69359 11.4222"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ACIcon;
