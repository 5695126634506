import React from "react";

type BathroomIconProps = {
  color?: string;
  size: "small";
};

const BathroomIcon = (props: BathroomIconProps) => {
  const { color } = props;

  // size === small
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.59212 5.14343C4.60781 5.14343 5.4312 4.29052 5.4312 3.2384C5.4312 2.18628 4.60781 1.33337 3.59212 1.33337C2.57642 1.33337 1.75304 2.18628 1.75304 3.2384C1.75304 4.29052 2.57642 5.14343 3.59212 5.14343Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9484 5.14343C11.9641 5.14343 12.7875 4.29052 12.7875 3.2384C12.7875 2.18628 11.9641 1.33337 10.9484 1.33337C9.93274 1.33337 9.10936 2.18628 9.10936 3.2384C9.10936 4.29052 9.93274 5.14343 10.9484 5.14343Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.97143 11.5233L4.64284 14.6667H2.54139L2.21281 11.5233H0.833496V9.61829C0.833496 8.86042 1.12414 8.1336 1.64148 7.5977C2.15882 7.06181 2.86049 6.76075 3.59212 6.76075C4.32375 6.76075 5.02541 7.06181 5.54276 7.5977C6.0601 8.1336 6.35074 8.86042 6.35074 9.61829V11.5233H4.97143Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3277 12.4758L11.868 14.6667H10.0289L9.56913 12.4758H7.73005L9.04806 8.66578C9.47717 7.33226 9.76346 6.76075 10.9484 6.76075C12.1334 6.76075 12.4197 7.33226 12.8488 8.66578L14.1668 12.4758H12.3277Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BathroomIcon;
