/**
 * @file LoginLogoutBlock.tsx
 * If not-authenticated, login button.
 * If authenticated, menu with logout / account / orders.
 *
 */
import React from "react";

import { Box } from "@mui/material";

import { useAuth } from "../../../globals/hooks";
import LoginButton from "./components/LoginButton";
import AuthenticatedContactMenu from "./components/AuthenticatedContactMenu";

type LoginLogoutBlockProps = {
  renderContinueAsGuest?: boolean;
};

function LoginLogoutBlock(props: LoginLogoutBlockProps) {
  const { renderContinueAsGuest } = props;

  const { authStage } = useAuth();

  const authStageMap = {
    pending: <Box height="46px" />,
    rejected: <LoginButton renderContinueAsGuest={renderContinueAsGuest} />,
    authenticated: <AuthenticatedContactMenu />,
  };

  return authStageMap[authStage];
}

export default LoginLogoutBlock;
