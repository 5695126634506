import React from "react";

import { Box, Button, Typography } from "@mui/material";

import { useScreenSize } from "../../globals/hooks";

export type MoovsBannerProps = {
  bannerText: string;
  bannerBgColor: string;
  bannerTextColor?: string;
  actionButtonText?: string;
  onActionButtonClick?: () => void;
  actionButtonStartIcon?: JSX.Element;
  actionButtonVariant?: "outlined";
  bannerIcon?: JSX.Element;
  secondaryActionButtonProps?: {
    btnText: string;
    onBtnClick: () => void;
    btnStartIcon?: JSX.Element;
  };
};

function MoovsBanner(props: MoovsBannerProps) {
  const {
    bannerText,
    actionButtonText,
    onActionButtonClick,
    bannerBgColor,
    bannerTextColor,
    actionButtonStartIcon,
    actionButtonVariant,
    bannerIcon,
    secondaryActionButtonProps,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <Box
      display="flex"
      flex="1"
      flexDirection={isMobileView ? "column" : "row"}
      px={3}
      py={2}
      borderRadius="4px"
      bgcolor={bannerBgColor}
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {!!bannerIcon && <Box mr={2}>{bannerIcon}</Box>}
        <Typography
          variant={!!secondaryActionButtonProps ? "subtitle2" : "subtitle1"}
          {...(!!bannerTextColor && { style: { color: bannerTextColor } })}
        >
          {bannerText}
        </Typography>
      </Box>

      {onActionButtonClick && (
        <Box
          display="flex"
          flexDirection={isMobileView ? "column" : "row-reverse"}
          gap={1}
          {...(isMobileView && { sx: { mt: 1 } })}
          {...(!!secondaryActionButtonProps && {
            minWidth: "320px",
          })}
        >
          {/* Primary Action Button */}
          <Button
            color="primary"
            size={!!secondaryActionButtonProps ? "small" : "medium"}
            variant={actionButtonVariant || "contained"}
            onClick={onActionButtonClick}
            {...(!!actionButtonStartIcon && {
              startIcon: actionButtonStartIcon,
            })}
          >
            {actionButtonText}
          </Button>

          {/* Secondary Action Button */}
          {!!secondaryActionButtonProps && (
            <Button
              color="primary"
              size="small"
              variant="text"
              onClick={secondaryActionButtonProps.onBtnClick}
              {...(!!secondaryActionButtonProps.btnStartIcon && {
                startIcon: secondaryActionButtonProps.btnStartIcon,
              })}
            >
              {secondaryActionButtonProps.btnText}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
}

export default MoovsBanner;
