import { matchPath, useLocation } from "react-router-dom";

type ParamType = {
  operatorSlug?: string;
};

function useOperatorSlug() {
  const location = useLocation();

  const match = matchPath(location.pathname, {
    path: "/:operatorSlug",
  });

  const params: ParamType = match?.params;
  return params?.operatorSlug;
}

export { useOperatorSlug };
