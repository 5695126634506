import React from "react";
import moment from "moment-timezone";

import { Box } from "@mui/material";

import TripTimelineStep from "./components/TripTimelineStep";

type TripTimelineProps = {
  trip: {
    useTotalDuration: boolean;
    estimatedDuration: number;
    stops: {
      stopIndex: number;
      dateTime: string;
      location: string;
    }[];
  };
  isQuote: boolean;
};

function TripTimeline(props: TripTimelineProps) {
  const {
    trip: { stops, useTotalDuration, estimatedDuration },
    isQuote,
  } = props;

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      {stops.map((stop, index) => {
        const isLastStop = index === stops.length - 1;

        const estimatedDropOffTime =
          isLastStop &&
          !useTotalDuration &&
          moment
            .utc(stops[0].dateTime)
            .add(estimatedDuration, "minutes")
            .toISOString();

        return (
          <TripTimelineStep
            key={index}
            stop={stop}
            isLastStop={isLastStop}
            estimatedDropOffTime={estimatedDropOffTime}
            isQuote={isQuote}
          />
        );
      })}
    </Box>
  );
}

export default TripTimeline;
