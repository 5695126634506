import { useReducer } from "react";
import { Airline, Airport, Stop, TrackedFlight } from "../../../types";

enum UpdatedTripReducerActionTypesEnum {
  INITIAL_STATE = "INITIAL_STATE",
  STOPS = "STOPS",
}

export type updatedTripProps = {
  stops: {
    location: string;
    dateTime: string | null;
    stopIndex: number;
    variant: string;
    airport: Airport;
    airline: Airline;
    flightNumber: string;
    trackedFlight: TrackedFlight;
  }[];
};

const init = (initialTrip: any) => {
  return {
    stops: initialTrip.stops.map((stop: Stop) => {
      return {
        id: stop.id,
        location: stop.location,
        dateTime: stop.dateTime,
        stopIndex: stop.stopIndex,
        variant: stop?.variant,
        airport: stop?.airport,
        airline: stop?.airline,
        flightNumber: stop?.flightNumber,
        trackedFlight: stop?.trackedFlight,
      };
    }),
  };
};

function updatedTripReducer(
  updatedTrip: any,
  action: { type: string; value?: any; payload?: any }
): { stops: any } {
  const { INITIAL_STATE, STOPS } = UpdatedTripReducerActionTypesEnum;

  switch (action.type) {
    case INITIAL_STATE: {
      return init(action.payload);
    }
    case STOPS:
      return { stops: action.value };
    default:
      return updatedTrip;
  }
}

const useUpdatedTripReducer = (trip: any) =>
  useReducer(
    updatedTripReducer,
    {
      stops: trip.stops.map((stop: any) => {
        return {
          id: stop.id,
          location: stop.location,
          dateTime: stop.dateTime,
          stopIndex: stop.stopIndex,
          variant: stop?.variant,
          airport: stop?.airport,
          airline: stop?.airline,
          flightNumber: stop?.flightNumber,
          trackedFlight: stop?.trackedFlight,
        };
      }),
    },
    init
  );

export { useUpdatedTripReducer, UpdatedTripReducerActionTypesEnum };
