import React from "react";

type PassengerCountIconProps = {
  color?: string;
};

const PassengerCountIcon = (props: PassengerCountIconProps) => {
  const { color } = props;

  // size === 'small'
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 17V14.0963C15 13.1623 14.6312 12.2665 13.9749 11.6061C13.3185 10.9456 12.4283 10.5746 11.5 10.5746H4.5C3.57174 10.5746 2.6815 10.9456 2.02513 11.6061C1.36875 12.2665 1 13.1623 1 14.0963V17M11.5 4.42857C11.5 6.32212 9.933 7.85714 8 7.85714C6.067 7.85714 4.5 6.32212 4.5 4.42857C4.5 2.53502 6.067 1 8 1C9.933 1 11.5 2.53502 11.5 4.42857Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PassengerCountIcon;
