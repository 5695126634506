import React, { useEffect } from "react";
import MoovsDialog from "../../../globals/MoovsDialog";
import * as yup from "yup";
import { Box, Button } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { RHFTextField } from "../../../react-hook-form/text-field/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import "react-phone-input-2/lib/style.css";

export interface BookingContactData {
  bookingContact: {
    firstName: string;
    lastName: string;
  };
}

const schemaValidation = yup.object({
  bookingContact: yup.object({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
  }),
});

type BookingContactFormProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: BookingContactData) => void;
  isShowCloseButton?: boolean;
  initialData: {
    firstName?: string;
    lastName?: string;
  };
};

const BookingContactBlock: React.FC<BookingContactFormProps> = ({
  isOpen,
  onClose,
  onSubmit,
  isShowCloseButton = true,
  initialData,
}) => {
  const methods = useForm({
    resolver: yupResolver(schemaValidation),
  });

  useEffect(() => {
    if (initialData) {
      methods.reset({
        bookingContact: {
          firstName: initialData.firstName || "",
          lastName: initialData.lastName || "",
        },
      });
    }
  }, [initialData, methods]);

  return (
    <MoovsDialog
      removeCloseButton
      hideTopBorder
      hideBottomBorder
      size="sm"
      open={isOpen}
      onClose={onClose}
      dialogTitle="Booking Contact"
      removeTopRightCloseButton={!isShowCloseButton}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Box display="flex" flexDirection="column" mb={1}>
              <Box flex="1" mb={2}>
                <RHFTextField
                  fullWidth
                  label="First name"
                  type="text"
                  required
                  name="bookingContact.firstName"
                  control={methods.control}
                />
              </Box>
              <Box flex="1" mb={2}>
                <RHFTextField
                  fullWidth
                  required
                  label="Last name"
                  type="text"
                  name="bookingContact.lastName"
                  control={methods.control}
                />
              </Box>
              <Button type="submit">Save</Button>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </MoovsDialog>
  );
};

export default BookingContactBlock;
