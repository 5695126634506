import React from "react";

type FoodIconProps = {
  color?: string;
  size: "small";
};

const FoodIcon = (props: FoodIconProps) => {
  const { color } = props;

  // size === small
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.88905 14.6667H2.66683L1.3335 4.88893H8.88905L8.67216 6.47886M4.44461 11.1112L5.77794 1.33337L8.44461 1.77782M7.55572 12.8889H13.7779M7.55572 12.8889V13.7778C7.55572 14.0136 7.64937 14.2397 7.81607 14.4064C7.98277 14.5731 8.20886 14.6667 8.44461 14.6667H12.8891C13.1248 14.6667 13.3509 14.5731 13.5176 14.4064C13.6843 14.2397 13.7779 14.0136 13.7779 13.7778V12.8889M7.55572 12.8889C7.0648 12.8889 6.66683 12.491 6.66683 12C6.66683 11.5091 7.0648 11.1112 7.55572 11.1112M13.7779 12.8889C14.2689 12.8889 14.6668 12.491 14.6668 12C14.6668 11.5091 14.2689 11.1112 13.7779 11.1112M13.7779 11.1112V10.2223C13.7779 9.75077 13.5906 9.29858 13.2572 8.96518C12.9238 8.63179 12.4717 8.44449 12.0002 8.44449H9.3335C8.862 8.44449 8.40982 8.63179 8.07642 8.96518C7.74302 9.29858 7.55572 9.75077 7.55572 10.2223V11.1112M13.7779 11.1112H7.55572"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FoodIcon;
