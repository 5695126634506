import React, { useEffect, useRef, useState } from "react";

import { Chip, ChipProps, Tooltip, Typography } from "@mui/material";

type TruncatedChipProps = {
  label: string;
  maxWidth: string;
  textColor?: string;
  backgroundColor?: string;
  ChipProps?: ChipProps;
};

function TruncatedChip(props: TruncatedChipProps) {
  const { label, maxWidth, textColor, backgroundColor, ChipProps } = props;

  // ref
  const chipRef = useRef<HTMLDivElement>(null);

  // state
  const [isTextTruncated, setIsTextTruncated] = useState(false);

  useEffect(() => {
    if (chipRef.current) {
      setIsTextTruncated(
        chipRef.current.scrollWidth > chipRef.current.offsetWidth
      );
    }
  }, [label, maxWidth]);

  return (
    <Tooltip title={isTextTruncated ? label : ""} placement="top" followCursor>
      <div style={{ maxWidth }} ref={chipRef}>
        <Chip
          {...ChipProps}
          component="div"
          label={
            <Typography
              variant="overline"
              textTransform="uppercase"
              color={textColor}
            >
              {label}
            </Typography>
          }
          size="small"
          sx={{ mt: 0.5, mr: 0.5, backgroundColor }}
        />
      </div>
    </Tooltip>
  );
}

export default TruncatedChip;
