import React from "react";
import { useQuery } from "@apollo/client";
import { useLocation, matchPath } from "react-router-dom";

import { LOAD_OPERATOR_QUERY } from "../../globals/graphql";
import { Typography, Box } from "@mui/material";
import { granite, black } from "../../design-system/colors";

type ParamType = {
  operatorSlug?: string;
};

type CompanyLogoProps = {
  size?: "small";
};

function CompanyLogo(props: CompanyLogoProps) {
  const { size } = props;

  const location = useLocation();

  const match = matchPath(location.pathname, {
    path: "/:operatorSlug",
  });

  const params: ParamType = match?.params;
  const operatorSlug = params?.operatorSlug;

  // queries
  const { data } = useQuery(LOAD_OPERATOR_QUERY, {
    variables: {
      operatorSlug: operatorSlug,
    },
    skip: !operatorSlug,
  });

  const { companyLogoUrl, name: companyName } = data?.operator || {};

  if (!companyLogoUrl) {
    return (
      <Box>
        <Typography
          variant="h3"
          style={size === "small" ? { color: black } : { color: granite }}
        >
          {companyName}
        </Typography>
      </Box>
    );
  }

  return (
    <img
      src={companyLogoUrl}
      alt={companyName}
      style={{ height: size === "small" ? 40 : 80 }}
    />
  );
}

export default CompanyLogo;
