import React from "react";

import { Rating, styled } from "@mui/material";

const StyledRating = styled(Rating)({
  "& .MuiRating-icon": {
    padding: "10px",
  },
});

type StarsRatingProps = {
  stars: number;
  emptyStarIcon: JSX.Element;
  filledStarIcon: JSX.Element;
  readOnly?: boolean;
  handleStarsChange?: (_, newValue: number) => void;
};

function StarsRating(props: StarsRatingProps) {
  const { handleStarsChange, stars, emptyStarIcon, filledStarIcon, readOnly } =
    props;

  return (
    <StyledRating
      readOnly={readOnly}
      value={stars}
      emptyIcon={emptyStarIcon}
      icon={filledStarIcon}
      {...(handleStarsChange && { onChange: handleStarsChange })}
    />
  );
}

export default StarsRating;
