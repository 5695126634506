import React from "react";

import {
  Box,
  TextField,
  Paper,
  Typography,
  List,
  Divider,
  Button,
  ListItem,
  useTheme,
} from "@mui/material";

import { RHFAutocomplete } from "components/react-hook-form/autocomplete/RHFAutocomplete";
import { white, grayLight } from "design-system/colors";
import { PlusIcon } from "design-system/icons";
import { SelectedPassenger } from "globals/hooks/useDefaultPassenger";

type PassengerRHFAutocompleteProps = {
  passengers: SelectedPassenger[];
  handleAddNewPassengerClick: () => void;
  linkToCreditCardPassengerId: string;
};
function PassengerRHFAutocomplete(props: PassengerRHFAutocompleteProps) {
  const {
    passengers,
    handleAddNewPassengerClick,
    linkToCreditCardPassengerId,
  } = props;

  // hooks
  const theme = useTheme();

  const getFullName = (item: SelectedPassenger) => {
    return !item?.firstName || !item?.firstName
      ? ""
      : `${item.firstName} ${item.lastName}`;
  };

  return (
    <RHFAutocomplete
      fullWidth
      id="link-to-credit-card-passenger"
      name="linkToCreditCardPassenger"
      options={passengers || []}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option: SelectedPassenger) => getFullName(option)}
      transform={{
        input: (value) => getFullName(value),
      }}
      renderInput={(params) => (
        <Box>
          <TextField {...params} label="Link to Contact (optional)" />
        </Box>
      )}
      PaperComponent={({ children }) => (
        <Paper>
          <Box px={2} pt={1}>
            <Typography sx={{ backgroundColor: white }} variant="overline">
              Add Linked Passenger
            </Typography>
          </Box>
          <List
            sx={{
              maxHeight: "200px",
              overflow: "scroll!important",
              paddingY: `0!important`,
            }}
          >
            {children}
          </List>
          <Divider sx={{ backgroundColor: grayLight }} />
          <Button
            onClick={handleAddNewPassengerClick}
            onMouseDown={(e) => e.preventDefault()}
            fullWidth
          >
            <Box display="flex" pt={1} pb={0.5}>
              <Box mr={1} mt={-0.2}>
                <PlusIcon size="small" color={theme.palette.primary.main} />
              </Box>
              <Typography fontWeight={500}>Add New Passenger</Typography>
            </Box>
          </Button>
        </Paper>
      )}
      renderOption={(props, passenger) => {
        return (
          <ListItem
            {...props}
            key={passenger.id}
            aria-selected={linkToCreditCardPassengerId === passenger.id}
            sx={{
              width: "97%",
              margin: "0 auto",
              "&.Mui-focused": {
                borderRadius: 1,
                width: "97%",
                margin: "0 auto",
              },
            }}
          >
            <Box p={0.5} display="flex" flexDirection="column">
              <Typography variant="subtitle2">
                {getFullName(passenger)}
              </Typography>
              <Typography variant="caption" sx={{ marginY: 0.5 }}>
                {passenger.email}
              </Typography>
              <Typography variant="caption">{passenger.mobilePhone}</Typography>
            </Box>
          </ListItem>
        );
      }}
    />
  );
}

export default PassengerRHFAutocomplete;
