import React, { useState } from "react";

import { Button, CircularProgress, IconButton } from "@mui/material";

import { DownloadIcon } from "../../design-system/icons";
import {
  useAnalytics,
  useDownloader,
  useScreenSize,
  useSnackbar,
} from "../../globals/hooks";
import { getErrorMessage } from "../../moovsErrors/getErrorMessage";
import MoovsTooltip from "components/globals/MoovsTooltip";

type DownloadButtonProps = {
  endpoint: string;
  buttonText: string;
  variant?: "mobile" | "desktop";
  params?: { id: string };
  analyticsName?: string;
};

function DownloadButton(props: DownloadButtonProps) {
  const { endpoint, buttonText, params, analyticsName } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();
  const snackbar = useSnackbar();
  const downloader = useDownloader();

  // states
  const [isDownloading, setIsDownloading] = useState(false);

  // event handlers
  const handleDownloadReportClick = async () => {
    try {
      setIsDownloading(true);
      await downloader(endpoint, params);
    } catch (error) {
      const errorMessage =
        getErrorMessage(error) ||
        "We had issues downloading your file, please try again.";
      snackbar.error(errorMessage);
    }
    setIsDownloading(false);

    if (analyticsName) track(analyticsName);
  };
  // derived state
  let variant = props.variant;
  if (!variant) {
    variant = isMobileView ? "mobile" : "desktop";
  }
  if (variant === "mobile")
    return (
      <IconButton
        onClick={handleDownloadReportClick}
        disabled={isDownloading}
        sx={{ mr: -1, mt: -1 }}
      >
        {isDownloading ? (
          <MoovsTooltip tooltipText="File is downloading, please wait...">
            <CircularProgress size={20} />
          </MoovsTooltip>
        ) : (
          <DownloadIcon />
        )}
      </IconButton>
    );

  return (
    <Button
      onClick={handleDownloadReportClick}
      disabled={isDownloading}
      endIcon={
        isDownloading ? (
          <MoovsTooltip tooltipText="File is downloading, please wait...">
            <CircularProgress size={15} />
          </MoovsTooltip>
        ) : (
          <DownloadIcon size="small" />
        )
      }
    >
      {buttonText}
    </Button>
  );
}

export default DownloadButton;
