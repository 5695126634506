import React from "react";
import { UseFormReturn } from "react-hook-form";
import {
  encodeQueryParams,
  JsonParam,
  StringParam,
} from "serialize-query-params";
import { stringify } from "querystring";

import { Operator, TripCategory } from "types";
import { TripState } from "../IframeRequestPage";
import { fromGlobalId } from "utils/auth/helpers";

type UseFormSubmitProps = {
  methods: UseFormReturn<TripState>;
  operator: Operator;
};

function useFormSubmit(props: UseFormSubmitProps) {
  const { methods, operator } = props;

  if (!operator) return;
  const { id, nameSlug } = operator;

  const handleSubmit = () => {
    const {
      dateTime,
      returnDateTime,
      totalGroupSize,
      note,
      pickupLocation,
      dropoffLocation,
      tripCategory,
      totalDuration,
      orderType,
    } = methods.getValues();

    const trip = {
      totalGroupSize,
      note,
      dateTime,
      stops: [
        {
          ...pickupLocation,
          pickUpGooglePlaceTypes: (pickupLocation as { [key: string]: unknown })
            ?.types,
        },
        dropoffLocation,
      ],
      tripCategory,
      orderType: orderType.slug,
      ...(tripCategory === TripCategory.Hourly && { totalDuration }),
      ...(tripCategory === TripCategory.RoundTrip && { returnDateTime }),
    };

    // track, can't use normal track hook b/c iframe relies on nameslug in url
    window.analytics.track("widget_vehicleSearched", {
      source: "customer",
      ...(id && { operator_id: fromGlobalId(id).id }),
    });

    // get current params and gclid
    const params = new URLSearchParams(window.location.search);
    const gclid = params.get("gclid");

    const encodedQuery = encodeQueryParams(
      { trip: JsonParam, ...(gclid && { gclid: StringParam }) },
      { trip, ...(gclid && { gclid }) }
    );

    window.open(`/${nameSlug}/new/vehicle?${stringify(encodedQuery)}`);
  };

  return (e: React.FormEvent<HTMLFormElement>) =>
    methods.handleSubmit(handleSubmit)(e);
}

export default useFormSubmit;
