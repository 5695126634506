import React from "react";

import { Box, Typography } from "@mui/material";

import { VehicleType } from "types";
import { black, grayDark } from "design-system/colors";
import { PassengerIcon } from "design-system/icons";
import { useScreenSize } from "globals/hooks";

type VehicleInfoProps = {
  name: string;
  vehicleType: VehicleType;
  capacity: number;
  hasTripConflict: boolean;
};

function VehicleInfo(props: VehicleInfoProps) {
  const { name, vehicleType, capacity, hasTripConflict } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <Box width="100%">
      <Typography
        variant="h2"
        mb={0.5}
        {...(hasTripConflict && { color: grayDark })}
      >
        {name}
      </Typography>
      <Box
        display="flex"
        flexDirection={isMobileView ? "row" : "column"}
        justifyContent={isMobileView ? "space-between" : "flex-start"}
      >
        <Typography variant="body2" color={grayDark} mb={1}>
          {vehicleType.typeName}
        </Typography>
        <Box display="flex" flexDirection="row">
          <PassengerIcon color={hasTripConflict ? grayDark : black} />
          <Typography
            variant="body2"
            ml={1.5}
            {...(hasTripConflict && { color: grayDark })}
          >
            {capacity} Passengers
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default VehicleInfo;
