import React from "react";

type TVIconProps = {
  color?: string;
  size: "small";
};

const TVIcon = (props: TVIconProps) => {
  const { color } = props;

  // size === small
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16683 12.3333H10.8335M1.41321 1.66663H13.5871C13.9073 1.66663 14.1668 1.89487 14.1668 2.17643V9.82349C14.1668 10.105 13.9073 10.3333 13.5871 10.3333H1.41321C1.09304 10.3333 0.833496 10.105 0.833496 9.82349V2.17643C0.833496 1.89487 1.09304 1.66663 1.41321 1.66663ZM6.8335 10.3333H8.16683V12.3333H6.8335V10.3333Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TVIcon;
