import React, { useEffect, useRef, useState } from "react";
import Lottie from "react-lottie-player";
import { useHistory, useLocation } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import { SubmitSuccessLottie } from "../../design-system/lottie";
import { useQueryParams } from "../../globals/hooks";
import MoovsDialog from "./MoovsDialog";

type SubmitSuccessDialogProps = {
  message: string;
};

function SubmitSuccessDialog(props: SubmitSuccessDialogProps) {
  const { message } = props;

  // hooks
  const queryParams = useQueryParams();
  const history = useHistory();
  const location = useLocation();

  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(message);
  const hasRun = useRef(false);

  // open dialog if query param has success
  useEffect(() => {
    const shouldRenderSubmitSuccessDialog =
      queryParams.get("successDialog") === "true" && hasRun.current === false;

    if (shouldRenderSubmitSuccessDialog) {
      const autoPaymentType = location.state?.autoPaymentType;

      autoPaymentType &&
        setDialogMessage(
          autoPaymentType === "full"
            ? "Reservation & Payment Submitted!"
            : "Reservation & Deposit Submitted!"
        );

      setSuccessDialogOpen(true);
      hasRun.current = true;

      queryParams.set("successDialog", "false");

      history.replace({
        ...history.location,
        search: queryParams.toString(),
      });
    }
  }, [history, queryParams, location]);

  return (
    <MoovsDialog
      open={successDialogOpen}
      hideTopBorder
      hideBottomBorder
      removeCloseButton
      removeTopRightCloseButton
      size="xs"
    >
      <Box mt={2} alignSelf="center">
        <Lottie
          onLoopComplete={() => {
            setSuccessDialogOpen(false);
          }}
          animationData={SubmitSuccessLottie}
          play
          style={{
            height: "190px",
            width: "190px",
          }}
        />
      </Box>
      <Box textAlign="center" mt={3} mb={3}>
        <Typography variant="subtitle1">{dialogMessage}</Typography>
      </Box>
    </MoovsDialog>
  );
}

export default SubmitSuccessDialog;
