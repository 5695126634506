/**
 * TripTabs.tsx
 *
 * Trip Tabs for multiple trips. On mobile uses
 * multiple tab components stacked on top of each
 * other so that there are always two tabs visible.
 */
import React, { useMemo } from "react";

import { useScreenSize } from "../../../../../globals/hooks";
import MobileTripTabs from "./components/MobileTripTabs";
import DesktopTripTabs from "./components/DesktopTripTabs";

type TripTabsProps = {
  trips: {
    returnTrip?: {};
  }[];
  activeTripIndex: number;
  onActiveTripIndexChange: (index: number) => void;
};

function TripTabs(props: TripTabsProps) {
  const { trips, activeTripIndex, onActiveTripIndexChange } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // event handlers
  const handleTabSelect = (
    _: React.SyntheticEvent<Element, Event>,
    tabIndex: number
  ) => {
    onActiveTripIndexChange(tabIndex);
  };

  // derived state
  const tripTabs = useMemo(() => {
    let tripCount = 0;

    return trips.map((trip, index) => ({
      value: index,
      label:
        `TRIP ${++tripCount}` + (trip.returnTrip ? ` & ${++tripCount}` : ""),
    }));
  }, [trips]);

  return isMobileView ? (
    <MobileTripTabs
      tripTabs={tripTabs}
      onTabSelect={handleTabSelect}
      activeTripIndex={activeTripIndex}
    />
  ) : (
    <DesktopTripTabs
      tripTabs={tripTabs}
      onTabSelect={handleTabSelect}
      activeTripIndex={activeTripIndex}
    />
  );
}

export default TripTabs;
