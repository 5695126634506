import React from "react";
import first from "lodash/first";

import { Box, Typography } from "@mui/material";

import {
  EditButton,
  PassengerContact,
  PassengerCount,
  LuggageCount,
  TripNotes,
} from "./components";
import {
  useScreenSize,
  useLuggage,
  useLaunchDarklyFlags,
  useCurrentUser,
  useDefaultPassenger,
} from "globals/hooks";
import { Contact, TemporaryPassenger } from "types";
import PassengerDetailsContact from "./components/PassengerDetailsContact";
import { SelectedPassenger } from "globals/hooks/useDefaultPassenger";

type AdditionalTripInfoBlockProps = {
  mode: "create" | "update";
  trip: {
    id: string;
    totalGroupSize: number;
    routes: {
      id: string;
      carryOnLuggage?: number;
      checkedLuggage?: number;
      oversizeLuggage?: number;
    }[];
    childSeats: {
      boosterSeatQuantity: number;
      forwardFacingSeatQuantity: number;
      rearFacingSeatQuantity: number;
    };
    note?: string;
    temporaryPassenger?: TemporaryPassenger;
    selectedPassenger?: SelectedPassenger;
    contact?: Contact;
  };
  passenger: {
    firstName: string;
    lastName: string;
    mobilePhone: string;
    phoneCountryCode?: string;
    phoneCountryDialCode?: string;
  };
};

function AdditionalTripInfoBlock(props: AdditionalTripInfoBlockProps) {
  const { mode, trip, passenger } = props;

  // derived state
  const route = first(trip.routes);

  // hooks
  const { isMobileView } = useScreenSize();
  const { isLuggageEnabled } = useLuggage(route);
  const currentUser = useCurrentUser();
  const { enableLinkedPassenger } = useLaunchDarklyFlags();
  const { selectedPassenger } = useDefaultPassenger() || {};

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {/* header */}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h3">Additional Trip Info</Typography>
        <EditButton mode={mode} trip={trip} />
      </Box>

      {/* body */}
      <Box
        mt={2}
        display="flex"
        flexDirection={isMobileView ? "column" : "row"}
        justifyContent="space-between"
      >
        <PassengerCount totalGroupSize={trip.totalGroupSize} />
        {enableLinkedPassenger && currentUser ? (
          <PassengerDetailsContact
            temporaryPassenger={trip.temporaryPassenger}
            passenger={
              trip.contact || trip.selectedPassenger || selectedPassenger
            }
          />
        ) : (
          <PassengerContact
            temporaryPassenger={trip.temporaryPassenger}
            passenger={passenger}
          />
        )}

        {isLuggageEnabled && <LuggageCount {...route} />}
        <TripNotes note={trip.note} childSeats={trip.childSeats} />
      </Box>
    </Box>
  );
}

export default AdditionalTripInfoBlock;
