import { Dispatch, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import isNil from "lodash/isNil";
import queryString from "query-string";

import { useQueryParams } from "globals/hooks";
import { CreateRequestReduxActionTypes } from "../context/reducer/types";
import { useGoogleDirectionsQuery } from "globals/graphql/hooks/useGoogleDirectionsQuery";
import { useCreateRequestContext } from "../context/useCreateRequestContext";
import { getInitialStop } from "../context/initialState";

function useDudaTrip() {
  // form hooks
  const [, dispatch] = useCreateRequestContext();
  const history = useHistory();
  const queryParams = useQueryParams();

  const [dudaTrip]: [
    {
      tripNote: string;
      totalGroupSize: number;
      stops: { description: string }[];
      dateTime: string;
    },
    Dispatch<any>
  ] = useState(() =>
    queryParams.get("dudaTrip") === "success"
      ? null
      : JSON.parse(queryParams.get("dudaTrip"))
  );
  const parsedQueryParams = queryString.parse(history.location.search);

  /************************************************************
   * Retrieve and Update Estimated Duration from Duda Trip
   ************************************************************/
  const stops = (dudaTrip?.stops || []).map((stop, index) => ({
    ...getInitialStop(index + 1),
    stopIndex: index + 1,
    dateTime: index === 0 ? dudaTrip.dateTime : undefined,
    location: stop.description,
  }));

  const [estimatedDuration, googleDirectionsResult] = useGoogleDirectionsQuery({
    stops,
    skip: !dudaTrip,
  });

  useEffect(() => {
    if (isNil(googleDirectionsResult)) return;

    dispatch({
      type: CreateRequestReduxActionTypes.UpdateEstimatedDuration,
      payload: { estimatedDuration, googleDirectionsResult },
    });
  }, [dispatch, estimatedDuration, googleDirectionsResult]);

  /************************************************************
   * Save Initial Duda Trip passed through Query Params
   ************************************************************/

  const handleReceiveDudaTripMessage = useCallback(() => {
    dispatch({
      type: CreateRequestReduxActionTypes.ReceiveDudaData,
      payload: {
        stops,
        totalGroupSize: dudaTrip.totalGroupSize,
        note: dudaTrip.tripNote || "",
      },
    });

    // remove trip information from url, but maintain awareness that the page was loaded from duda
    parsedQueryParams.dudaTrip = "success";
    history.replace({ search: queryString.stringify(parsedQueryParams) });
  }, [dispatch, stops, dudaTrip, parsedQueryParams, history]);

  // check if 'dudaTrip' queryParams exists in order
  // to determine which operator's customer app to
  // load in duda website iframe
  useEffect(() => {
    if (dudaTrip && queryParams.get("dudaTrip") !== "success") {
      handleReceiveDudaTripMessage();
    }
  }, [dudaTrip, handleReceiveDudaTripMessage, queryParams]);

  return { isDudaTrip: !!dudaTrip };
}

export { useDudaTrip };
