import React from "react";

import { Box, Typography } from "@mui/material";

type RequestSummaryItemProps = {
  label: string;
  value: string | number;
};

function RequestSummaryItem(props: RequestSummaryItemProps) {
  const { label, value } = props;

  return (
    <Box p={2}>
      <Typography variant="overline">{label}</Typography>
      <Typography variant="body2">{value}</Typography>
    </Box>
  );
}

export default RequestSummaryItem;
