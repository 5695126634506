import React from "react";

type PendingIconProps = {
  color?: string;
};

const PendingIcon = (props: PendingIconProps) => {
  const { color } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 2C7.5 2 3 6 3 11.5"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 3"
      />
      <path
        d="M2.99997 12C2.99997 17.5228 7.47712 22 13 22C18.5228 22 23 17.5228 23 12C23 6.47715 18.5228 2 13 2M13 5.93939V12.5051L17 15.5M1 13L2.88203 10.943L5 12.5051"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PendingIcon;
