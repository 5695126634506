import React, { useState } from "react";

import { useAnalytics, useOperator } from "globals/hooks";
import { TextRecipientEnum } from "types";
import getRecipient from "globals/utils/getRecipients";
import { CreateRequestReduxActionTypes } from "pages/new/context/reducer/types";
import { useCreateRequestContext } from "pages/new/context/useCreateRequestContext";
import TripBell from "./TripBell";

type TripBellOnCreateProps = {
  hasReturnTrip?: boolean;
};

function TripBellOnCreate(props: TripBellOnCreateProps) {
  const { hasReturnTrip } = props;

  const { track } = useAnalytics();
  const {
    operator: {
      settings: { tripStatusUpdateTextTo },
    },
  } = useOperator();
  const [_, dispatch] = useCreateRequestContext();

  const isTripStatusRecipientPassenger =
    tripStatusUpdateTextTo === TextRecipientEnum.Passenger ||
    tripStatusUpdateTextTo === TextRecipientEnum.Both;

  const isTripStatusRecipientBookingContact =
    tripStatusUpdateTextTo === TextRecipientEnum.BookingContact ||
    tripStatusUpdateTextTo === TextRecipientEnum.Both;

  const [isNotificationActive, setIsNotificationActive] = useState(
    isTripStatusRecipientBookingContact
  );

  const handleToggleTripNotification = () => {
    dispatch({
      type: CreateRequestReduxActionTypes.UpdateTripNotification,
      payload: {
        trip: {
          statusUpdateTextTo: getRecipient(
            isTripStatusRecipientPassenger,
            !isNotificationActive
          ),
        },
        ...(hasReturnTrip && {
          returnTrip: {
            statusUpdateTextTo: getRecipient(
              isTripStatusRecipientPassenger,
              !isNotificationActive
            ),
          },
        }),
      },
    });
    isNotificationActive
      ? track("statusNotifications_turnedOff")
      : track("statusNotifications_turnedOn");
    setIsNotificationActive((isNotificationActive) => !isNotificationActive);
  };

  return (
    <TripBell
      handleToggleTripNotification={handleToggleTripNotification}
      isNotificationActive={isNotificationActive}
    />
  );
}

export default TripBellOnCreate;
