import React, { useState } from "react";

import { Typography, Box, Button } from "@mui/material";

import LoginLogoutBlock from "../../components/auth/LoginLogoutBlock/LoginLogoutBlock";
import { useScreenSize } from "../../globals/hooks";
import { CreateLinkedPassengerDialog, PassengerList } from "./components";

function PassengersPage() {
  // hook
  const { isMobileView } = useScreenSize();

  // state
  const [linkedPassengerDialogOpen, setLinkedPassengerDialogOpen] =
    useState(false);

  // event handler
  const handleCreateLinkedPassengerClick = () => {
    setLinkedPassengerDialogOpen(true);
  };

  return (
    <Box display="flex" justifyContent="center" flex="1">
      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        maxWidth="1140px"
        px={isMobileView ? 2 : 1}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          my={2}
        >
          <Typography variant="h1" style={{ fontWeight: 600 }}>
            Passengers
          </Typography>
          <LoginLogoutBlock />
        </Box>

        {/* Create Passenger */}
        <Box display="flex" justifyContent="flex-start" mt={3}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleCreateLinkedPassengerClick}
            fullWidth={isMobileView ? true : false}
          >
            Create Passenger
          </Button>
        </Box>

        <CreateLinkedPassengerDialog
          open={linkedPassengerDialogOpen}
          setLinkedPassengerDialogOpen={setLinkedPassengerDialogOpen}
        />

        {/* Passengers List */}
        <PassengerList />
      </Box>
    </Box>
  );
}

export default PassengersPage;
