import React from "react";
import { Control } from "react-hook-form";

import { useOperator } from "globals/hooks";
import RHFCheckbox from "components/react-hook-form/RHFCheckbox";
import { TermsAndCancellationPolicyLabel } from "./components";
import { CreateReservationFormState } from "pages/new/steps/confirm/components/CreateReservationForm/form/schemaValidation";
import { ConfirmReservationFormState } from "pages/order/components/ConfirmReservationForm/form/schemaValidation";

type TermsAndConditionsBlockProps = {
  isSubmitting: boolean;
  trips: { routes: { vehicle?: { id: string } }[] }[];
  control: Control<CreateReservationFormState | ConfirmReservationFormState>;
};

function TermsAndConditionsBlock(props: TermsAndConditionsBlockProps) {
  const { isSubmitting, trips, control } = props;

  // hooks
  const {
    operator: {
      settings: { requireConsentWhenBooking, requireSignatureWhenBooking },
    },
  } = useOperator();

  // if signature is required, terms and conditions are in signature dialog
  if (requireSignatureWhenBooking) {
    return null;
  }

  // if require consent when booking and no signature, show checkbox
  if (requireConsentWhenBooking) {
    return (
      <RHFCheckbox
        name="isTermsAccepted"
        control={control}
        disabled={isSubmitting}
        label={<TermsAndCancellationPolicyLabel trips={trips} />}
      />
    );
  }

  return <TermsAndCancellationPolicyLabel trips={trips} />;
}

export default TermsAndConditionsBlock;
