import React from "react";

import { Box, ToggleButton, Typography, useTheme } from "@mui/material";

import {
  white,
  grayDark,
  granite,
  black,
  errorRed,
} from "design-system/colors";

import { currency } from "utils/helpers";

type GratuityToggleButtonProps = {
  selected: boolean;
  label: number | string;
  onClick: () => void;
  value: number | "cash";
  altAmountText?: string;
  error?: boolean;
};

function GratuityToggleButton(props: GratuityToggleButtonProps) {
  const { selected, label, value, onClick, altAmountText = "", error } = props;

  // hook
  const theme = useTheme();

  const amountText =
    !!value && value !== "cash" ? `+ ${currency(value)}` : altAmountText;

  const selectedStyles = {
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: `${theme.palette.primary.light} !important`,
    boxShadow: "0px 4px 8px 0px #000000 8%",
  };

  return (
    <ToggleButton
      disableRipple
      value={value}
      selected={selected}
      onClick={onClick}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        border: `1px solid ${error ? errorRed : grayDark}`,
        minWidth: "80px",
        minHeight: "87px",
        borderRadius: "4px",
        backgroundColor: white,
        cursor: "pointer",
        marginBottom: "6px",
        "&:hover": {
          borderColor: black,
        },
        "&:focus": {
          borderWidth: "2px",
          borderColor: theme.palette.primary.main,
        },
        ...(selected && selectedStyles),
      }}
    >
      <Typography
        style={{ fontWeight: 500, marginBottom: amountText ? "10px" : 0 }}
      >
        {label}
      </Typography>
      {!!amountText && (
        <Box
          display="flex"
          flexDirection="row"
          sx={{
            whiteSpace: "pre",
            color: granite,
            fontWeight: 500,
            lineHeight: "12px",
            letterSpacing: "-0.25px",
          }}
          mb="5px"
        >
          <Box>
            <Typography
              variant="body2"
              sx={{
                whiteSpace: "pre",
                color: granite,
                fontWeight: 500,
                lineHeight: "12px",
                letterSpacing: "-0.25px",
              }}
            >
              {amountText}
            </Typography>
          </Box>
        </Box>
      )}
    </ToggleButton>
  );
}

export default GratuityToggleButton;
