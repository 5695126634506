import React, { useState } from "react";
import size from "lodash/size";
import sortBy from "lodash/sortBy";

import { Box, Typography } from "@mui/material";

import {
  PassengerListHeader,
  PassengerListItem,
  UpdateLinkedPassengerDialog,
} from "./components";
import { useCurrentUser, useScreenSize } from "globals/hooks";
import { Contact } from "types";

function PassengerList() {
  // hooks
  const { isMobileView } = useScreenSize();
  const currentUser = useCurrentUser();

  // state
  const [selectedPassenger, setSelectedPassenger] = useState(null);
  const [updateLinkedPassengerDialogOpen, setUpdateLinkedPassengerDialogOpen] =
    useState(false);

  // event handlers
  const handlePassengerClick = (passenger: Contact) => {
    setUpdateLinkedPassengerDialogOpen(true);
    setSelectedPassenger(passenger);
  };

  const emptyList = size(currentUser.linkedPassengers) === 0;

  const sortedLinkedPassengers = currentUser
    ? sortBy(currentUser.linkedPassengers, ["firstName", "lastName"])
    : null;

  return (
    <>
      {/* Grid Header */}
      <Box mt={3}>
        {emptyList ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="55vh"
          >
            <Typography variant="body1" style={{ fontWeight: 500 }}>
              No Passengers
            </Typography>
          </Box>
        ) : !isMobileView ? (
          <PassengerListHeader />
        ) : null}
      </Box>

      {/* List */}
      {sortedLinkedPassengers
        ? sortedLinkedPassengers.map((passenger) => (
            <Box key={passenger.id}>
              <PassengerListItem
                passenger={passenger}
                onPassengerClick={handlePassengerClick}
              />
            </Box>
          ))
        : null}

      <UpdateLinkedPassengerDialog
        open={updateLinkedPassengerDialogOpen}
        setUpdateLinkedPassengerDialogOpen={setUpdateLinkedPassengerDialogOpen}
        selectedPassenger={selectedPassenger}
      />
    </>
  );
}

export default PassengerList;
