import { Dispatch, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import isNil from "lodash/isNil";
import cloneDeep from "lodash/cloneDeep";
import reverse from "lodash/reverse";
import size from "lodash/size";
import find from "lodash/find";
import moment from "moment";
import queryString from "query-string";

import { useGoogleDirectionsQuery } from "globals/graphql/hooks/useGoogleDirectionsQuery";
import { useQueryParams } from "globals/hooks";
import { getInitialStop } from "../context/initialState";
import { CreateRequestReduxActionTypes } from "../context/reducer/types";
import { useCreateRequestContext } from "../context/useCreateRequestContext";
import { TripCategory } from "types";
import { allOrderTypes } from "../steps/info/components/data";

function useBookingWidgetTrip() {
  // form hooks
  const [, dispatch] = useCreateRequestContext();
  const history = useHistory();
  const queryParams = useQueryParams();

  const [trip]: [
    {
      note: string;
      totalGroupSize: number;
      stops: { description: string; pickUpGooglePlaceTypes: string[] }[];
      dateTime: string;
      tripCategory: TripCategory;
      orderType: string;
      totalDuration?: number;
      returnDateTime?: string;
    },
    Dispatch<any>
  ] = useState(() =>
    queryParams.get("trip") === "success"
      ? null
      : JSON.parse(queryParams.get("trip"))
  );
  const parsedQueryParams = queryString.parse(history.location.search);

  const stops = (trip?.stops || []).map((stop, index) => ({
    ...getInitialStop(index + 1),
    stopIndex: index + 1,
    dateTime:
      index === 0
        ? trip.dateTime
        : trip.totalDuration && index === size(trip?.stops) - 1 // if hourly trip, add totalDuration to get dateTime on last stop
        ? moment
            .utc(trip.dateTime)
            .add(Number(trip.totalDuration), "minutes")
            .toISOString()
        : undefined,
    location: stop.description,
    pickUpGooglePlaceTypes: stop?.pickUpGooglePlaceTypes,
  }));

  const [estimatedDuration, googleDirectionsResult] = useGoogleDirectionsQuery({
    stops,
    skip: !trip,
  });

  // reverse stop order if returnDateTime is supplied
  const returnStops =
    trip?.returnDateTime &&
    reverse(cloneDeep(stops)).map((stop, index) => ({
      ...stop,
      stopIndex: index + 1,
      dateTime: index === 0 ? trip.returnDateTime : undefined,
    }));

  const [returnEstimatedDuration, returnGoogleDirectionsResult] =
    useGoogleDirectionsQuery({
      stops: returnStops,
      skip: !returnStops,
    });

  // use effects
  // update estimtated duration from booking widget trip
  useEffect(() => {
    if (isNil(googleDirectionsResult)) return;

    dispatch({
      type: CreateRequestReduxActionTypes.UpdateEstimatedDuration,
      payload: {
        trip: { estimatedDuration, googleDirectionsResult },
        ...(trip.returnDateTime && {
          returnTrip: {
            estimatedDuration: returnEstimatedDuration,
            googleDirectionsResult: returnGoogleDirectionsResult,
          },
        }),
      },
    });
  }, [
    dispatch,
    estimatedDuration,
    googleDirectionsResult,
    returnEstimatedDuration,
    returnGoogleDirectionsResult,
    trip,
  ]);

  // event handler
  const handleReceiveTripMessage = useCallback(() => {
    dispatch({
      type: CreateRequestReduxActionTypes.ReceiveBookingWidgetData,
      payload: {
        // encodedQuery for orderType is using slug instead of object being used
        orderType: find(allOrderTypes, { slug: trip.orderType }),
        trip: {
          stops,
          totalGroupSize: trip.totalGroupSize,
          note: trip.note || "",
          tripCategory: trip.tripCategory,
          // add additional trip params for hourly trip
          ...(trip.totalDuration && {
            totalDuration: trip.totalDuration,
            useTotalDuration: true,
          }),
        },
        ...(trip.returnDateTime && {
          returnTrip: {
            stops: returnStops,
            totalGroupSize: trip.totalGroupSize,
            tripCategory: trip.tripCategory,
          },
        }),
      },
    });

    parsedQueryParams.trip = "success";
    history.replace({ search: queryString.stringify(parsedQueryParams) });
  }, [dispatch, stops, returnStops, trip, parsedQueryParams, history]);

  // check if 'trip' queryParams exist in order to determine which operator to load
  useEffect(() => {
    if (trip && queryParams.get("trip") !== "success") {
      handleReceiveTripMessage();
    }
  }, [trip, handleReceiveTripMessage, queryParams]);

  return {
    isBookingWidgetTrip: !!trip || queryParams.get("trip") === "success",
  };
}

export { useBookingWidgetTrip };
