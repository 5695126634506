import React from "react";
import startCase from "lodash/startCase";

import { Box, Typography, useTheme } from "@mui/material";

import PassengerLinkIcon from "design-system/icons/actions/PassengerLinkIcon";
import { getPaymentMethodInfoString } from "globals/utils/helpers";
import { BillingDetails, CardItem, Contact } from "types";

type PaymentMethodOptionProps = {
  linkedPassenger: Contact;
  card: CardItem;
  billingDetails: BillingDetails;
};

function PaymentMethodOption(props: PaymentMethodOptionProps) {
  const { linkedPassenger, card, billingDetails } = props;

  // hooks
  const theme = useTheme();
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
      maxWidth="350px"
    >
      <Box width={linkedPassenger ? "65%" : "100%"}>
        <Typography variant="body2" noWrap>
          {getPaymentMethodInfoString(card, billingDetails)}
        </Typography>
      </Box>

      <Box>
        {linkedPassenger && (
          <Box display="flex" alignItems="center" ml={1}>
            <Box mr={0.5} mb={-0.5}>
              <PassengerLinkIcon color={theme.palette.primary.main} />
            </Box>
            <Typography
              color={theme.palette.primary.main}
              sx={{ fontSize: "14px", fontWeight: "500", maxWidth: "95px" }}
              noWrap
            >
              {`${startCase(linkedPassenger.firstName)} ${startCase(
                linkedPassenger.lastName
              )}`}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default PaymentMethodOption;
