import React from "react";
import first from "lodash/first";
import last from "lodash/last";

import { Box, Divider, TextField, Typography } from "@mui/material";

import {
  applyUTCOffsetToTime,
  dateFormatter,
} from "../../../globals/utils/helpers";
import LocationAutoComplete from "../../autocompletes/LocationAutoComplete";
import MoovsIncrementer from "../../globals/MoovsIncrementer";
import {
  addTripReducerActionTypesEnum,
  newTripAddedOnQuoteProps,
} from "../hooks/useAddTripToQuoteReducer";
import LuggageDetailsBlock from "./LuggageDetailsBlock";
import { useLuggage } from "globals/hooks";
import MoovsDateTimePickerWithConfirm from "components/globals/MoovsDateTimePickerWithConfirm";

type AddTripDetailsPanelProps = {
  tripErrors: {
    pickUpDateTimeError: string;
    pickUpLocationError: string;
    dropOffLocationError: string;
  };
  newTripAddedOnQuote: newTripAddedOnQuoteProps;
  onDetailsChange: (type: any, value: any) => void;
};

const { STOPS, TOTAL_GROUP_SIZE, TRIP_NOTES } = addTripReducerActionTypesEnum;

function AddTripDetailsPanel(props: AddTripDetailsPanelProps) {
  const { tripErrors, newTripAddedOnQuote, onDetailsChange } = props;
  const { pickUpDateTimeError, pickUpLocationError, dropOffLocationError } =
    tripErrors;

  // hooks
  const { isLuggageEnabled } = useLuggage();

  // derived state
  // format the selected date time to properly display.
  const pickUpTime = first(newTripAddedOnQuote.stops).dateTime;
  const adjustedStopDateTime = pickUpTime
    ? applyUTCOffsetToTime(pickUpTime, "add")
    : null;

  return (
    <>
      <Box mb={4}>
        <Box mb={1.5}>
          <Typography variant="h3">Date & Time</Typography>
        </Box>
        <Box mb={2}>
          <Divider />
        </Box>
        <MoovsDateTimePickerWithConfirm
          clearable
          onAccept={(dateTime) =>
            onDetailsChange(STOPS, {
              stopIndex: 1,
              dateTime: dateTime
                ? applyUTCOffsetToTime(dateTime, "subtract")
                : null,
            })
          }
          label="Pick-Up Date & Time"
          inputFormat={dateFormatter(null, "abbreviated", {
            mode: "dateTime",
            returnFormatString: true,
          })}
          value={adjustedStopDateTime}
          renderInputProps={{
            name: "pickupDateTime",
            required: true,
            error: !!pickUpDateTimeError,
            helperText: pickUpDateTimeError,
          }}
        />
      </Box>

      <Box width="100%" mb={4}>
        {/* pick up address */}
        <Box mb={1.5}>
          <Typography variant="h3">Pick-Up</Typography>
        </Box>
        <Box>
          <LocationAutoComplete
            required
            label="Address"
            fullWidth
            value={first(newTripAddedOnQuote.stops).location}
            onChange={(address) =>
              onDetailsChange(STOPS, {
                stopIndex: 1,
                location: address,
              })
            }
            error={!!pickUpLocationError}
            helperText={pickUpLocationError}
          />
        </Box>
      </Box>

      <Box width="100%" mb={4}>
        {/* drop off address */}
        <Box mb={1.5}>
          <Typography variant="h3">Drop-Off</Typography>
        </Box>
        <Box>
          <LocationAutoComplete
            required
            label="Address"
            fullWidth
            value={last(newTripAddedOnQuote.stops).location}
            onChange={(address) =>
              onDetailsChange(STOPS, {
                stopIndex: 2,
                location: address,
              })
            }
            error={!!dropOffLocationError}
            helperText={dropOffLocationError}
          />
        </Box>
      </Box>

      {/* Additional Info */}
      <Box>
        <Typography variant="h3">Additional Info</Typography>
        <Box mb={2}>
          <Divider />
        </Box>
      </Box>

      {/* Passenger count Incrementer */}
      <Box
        display="flex"
        flex={1}
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="body2">Passenger Count</Typography>

        <MoovsIncrementer
          value={newTripAddedOnQuote.totalGroupSize}
          limits={[0, 99]}
          decrement={() =>
            onDetailsChange(
              TOTAL_GROUP_SIZE,
              newTripAddedOnQuote.totalGroupSize - 1
            )
          }
          increment={() =>
            onDetailsChange(
              TOTAL_GROUP_SIZE,
              newTripAddedOnQuote.totalGroupSize + 1
            )
          }
          onTextFieldChange={(newtotalGroupSize) =>
            onDetailsChange(TOTAL_GROUP_SIZE, newtotalGroupSize)
          }
          ButtonProps={{ variant: "text" }}
        />
      </Box>

      {/* Trip Notes */}
      <Box mb={2}>
        <TextField
          fullWidth
          variant="outlined"
          name="note"
          label="Trip Notes"
          value={newTripAddedOnQuote.note}
          onChange={(event) => onDetailsChange(TRIP_NOTES, event.target.value)}
        />
      </Box>

      {/* Luggage Info */}
      {isLuggageEnabled && (
        <LuggageDetailsBlock
          newTripAddedOnQuote={newTripAddedOnQuote}
          onDetailsChange={onDetailsChange}
        />
      )}
    </>
  );
}

export default AddTripDetailsPanel;
