import moment from "moment-timezone";

import { TrackedFlight, TransitStop } from "../../types";

type FlightTime = {
  timeLabel: "Actual" | "Estimated" | "Scheduled";
  time: Date;
};

/**
 * Reduces scheduled, estimated, and actual tracked flight
 * times into a queue based on importance. Filters out results that
 * do not exist.
 */
export const getFlightTimePriorityQueue = (
  transitStop: TransitStop
): FlightTime[] => {
  const { actualDateTime, estimatedDateTime, scheduledDateTime, airport } =
    transitStop;

  const flightTimePriorityQueue = [];

  // use either actual or estimated, never both
  if (actualDateTime) {
    flightTimePriorityQueue.push({
      timeLabel: "Actual",
      time: moment.tz(actualDateTime, airport.timezone),
    });
  } else if (estimatedDateTime) {
    flightTimePriorityQueue.push({
      timeLabel: "Estimated",
      time: moment.tz(estimatedDateTime, airport.timezone),
    });
  }

  // if scheduled render it to compare to either actual or estimated
  if (scheduledDateTime) {
    flightTimePriorityQueue.push({
      timeLabel: "Scheduled",
      time: moment.tz(scheduledDateTime, airport.timezone),
    });
  }

  return flightTimePriorityQueue;
};

/**
 * returns single arrival and departure time for a stop.
 * leverages flight time priority queue to return the most relevent information
 */
export const getFlightTimes = (
  trackedFlight: TrackedFlight
): [string, string] => {
  if (!trackedFlight) return [null, null];

  const { origin, destination } = trackedFlight;

  const departureTime = moment(
    getFlightTimePriorityQueue(origin)[0].time
  ).format("LT");

  const arrivalTime = moment(
    getFlightTimePriorityQueue(destination)[0].time
  ).format("LT");

  return [departureTime, arrivalTime];
};
