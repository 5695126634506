import { CreateTripState } from "../context/initialState";

type FormatStopsForBRACalculationsParams = {
  trip: CreateTripState;
  returnTrip: CreateTripState;
};

type MinimumStopInfoForBRACalculations = {
  dateTime: string;
  location: string;
  airportIcao?: string;
};

export const formatStopsForBRACalculations = (
  params: FormatStopsForBRACalculationsParams
) => {
  const { trip, returnTrip } = params;

  const vehicleStops: MinimumStopInfoForBRACalculations[] = trip.stops.map(
    ({ airport, location, dateTime }) => ({
      dateTime,
      location: airport?.airportName || location,
      ...(airport?.icaoCode && { airportIcao: airport.icaoCode }),
    })
  );

  let returnTripStops;
  if (returnTrip) {
    returnTripStops = returnTrip.stops.map(
      ({ airport, location, dateTime }) => ({
        dateTime,
        location: airport?.airportName || location,
        ...(airport?.icaoCode && { airportIcao: airport.icaoCode }),
      })
    ) as MinimumStopInfoForBRACalculations[];
  }

  return { vehicleStops, returnTripStops };
};
