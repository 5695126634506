import React from "react";

import { Box, Typography } from "@mui/material";

import NoReservationIcon from "design-system/icons/info/NoReservationIcon";

type NoReservationMessageProps = {
  message: string;
};
function NoReservationMessage(props: NoReservationMessageProps) {
  const { message } = props;

  return (
    <Box display="flex" justifyContent="center" width="100%" mt={5}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <NoReservationIcon />
        <Typography variant="h5" sx={{ my: 3 }}>
          {message}
        </Typography>
      </Box>
    </Box>
  );
}

export default NoReservationMessage;
