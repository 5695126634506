import { Reducer } from "react";
import cloneDeep from "lodash/cloneDeep";
import first from "lodash/first";

import { CreateRequestState, getInitialTrip } from "../initialState";
import { CreateRequestAction, CreateRequestReduxActionTypes } from "./types";
import { mergeConfirmRequestFormState } from "../helpers/mergeConfirmRequestFormState";

const {
  UpdateInfoPage,
  UpdateVehiclePage,
  UpdateConfirmPage,
  ReceiveBookingWidgetData,
  ReceiveDudaData,
  UpdateEstimatedDuration,
  UpdateAdditionalTripInfo,
  UpdateTripNotification,
} = CreateRequestReduxActionTypes;

export const reducer: Reducer<CreateRequestState, CreateRequestAction> = (
  createRequestStore,
  action
) => {
  switch (action.type) {
    case UpdateInfoPage: {
      const requestClone = cloneDeep(createRequestStore);

      requestClone.orderType = action.payload.orderType;

      requestClone.trip = {
        ...requestClone.trip,
        ...action.payload.trip,
      };

      requestClone.returnTrip = action.payload.returnTrip
        ? {
            ...requestClone.returnTrip,
            ...action.payload.returnTrip,
          }
        : null;

      return requestClone;
    }

    case UpdateVehiclePage: {
      const requestClone = cloneDeep(createRequestStore);

      first(requestClone.trip.routes).vehicle = action.payload;

      return requestClone;
    }

    case UpdateAdditionalTripInfo: {
      const requestClone = cloneDeep(createRequestStore);

      const tripKey =
        requestClone.trip.id === action.payload.id ? "trip" : "returnTrip";

      requestClone[tripKey] = {
        ...requestClone[tripKey],
        ...action.payload,
        routes: [
          {
            ...first(requestClone[tripKey].routes),
            ...first(action.payload.routes),
          },
        ],
      };

      return requestClone;
    }

    case UpdateConfirmPage: {
      return mergeConfirmRequestFormState(createRequestStore, action.payload);
    }

    case ReceiveBookingWidgetData: {
      const requestClone = cloneDeep(createRequestStore);

      requestClone.orderType = action.payload.orderType;

      requestClone.trip = {
        ...requestClone.trip,
        ...action.payload.trip,
      };

      requestClone.returnTrip = action.payload.returnTrip
        ? {
            ...getInitialTrip(),
            ...action.payload.returnTrip,
          }
        : null;

      return requestClone;
    }

    // deprecated 10/31/22
    // input data from duda iframe
    case ReceiveDudaData: {
      return {
        ...createRequestStore,
        trip: {
          ...createRequestStore.trip,
          ...action.payload,
        },
      };
    }

    case UpdateEstimatedDuration:
    case UpdateTripNotification: {
      const requestClone = cloneDeep(createRequestStore);

      return {
        ...requestClone,
        trip: {
          ...requestClone.trip,
          ...action.payload.trip,
        },
        ...(action.payload.returnTrip && {
          returnTrip: {
            ...requestClone.returnTrip,
            ...action.payload.returnTrip,
          },
        }),
      };
    }
  }
};
