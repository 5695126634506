import { Box, Typography } from "@mui/material";
import React, { useState } from "react";

import { useOperator } from "../../globals/hooks";
import calendarUnavailable from "../../illustrations/calendarUnavailable.svg";
import MoovsDialog from "./MoovsDialog";
import { formatPhoneNumber } from "utils/phoneNumberFormatter/phoneNumberFormatter";

function SubmitTimingDialog() {
  // hooks
  const {
    operator: { name, voicePhoneNumber, twilioPhoneNumber },
  } = useOperator();

  const formattedTwilioNumber = formatPhoneNumber(
    twilioPhoneNumber?.phoneNumber
  )?.formatted;

  // state
  const [dialogOpen, setDialogOpen] = useState(true);

  // event handlers
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <MoovsDialog
      open={dialogOpen}
      onClose={handleDialogClose}
      hideTopBorder
      size="sm"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        px={4}
        pb={4}
      >
        <Box>
          <img src={calendarUnavailable} style={{ height: "140px" }} alt="" />
        </Box>
        <Box pt={3}>
          <Typography variant="h2" textAlign="center">
            Due to the timing of this trip, it is unable to be confirmed.
          </Typography>
        </Box>
        <Box pt={2}>
          <Typography variant="body1" color="textSecondary" lineHeight={1.5}>
            Your reservation request has been submitted. Please call {name} at
            <Box component="span" style={{ fontWeight: 700 }}>
              {` ${voicePhoneNumber || formattedTwilioNumber} `}
            </Box>
            to complete this booking.
          </Typography>
        </Box>
      </Box>
    </MoovsDialog>
  );
}

export default SubmitTimingDialog;
