import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import map from "lodash/map";

import { useCurrentUser, useOperator } from "globals/hooks";
import { useIsGratuityEnabled } from "components/confirm/form/hooks/useIsGratuityEnabled";
import { Request } from "types";
import { useConfirmReservation } from "components/confirm/hooks";
import ConfirmReservationFormFields from "./components/ConfirmReservationFormFields";
import { confirmReservationFormDefaultValues } from "./form/defaultValues";
import {
  ConfirmReservationFormState,
  confirmReservationFormErrorSchema,
} from "./form/schemaValidation";
import { useAuthenticationChangeUpdate } from "components/confirm/form/hooks";
import SubmitTimingDialog from "components/globals/SubmitTimingDialog";
import ConsentSignatureDialog from "components/confirm/form/components/TermsAndConditionsBlock/components/ConsentSignatureDialog/ConsentSignatureDialog";
import { useConsentSignature } from "pages/new/hooks/useConsentSignature";
import { useDisplayMeetGreet } from "components/confirm/form/hooks/useDisplayMeetGreet";
import { getIsNetflixLogin } from "utils/auth/isNetflixLogin";

type ConfirmReservationFormProps = {
  request: Request;
  activeTripIndex: number;
  hasTripConflict: boolean;
};

function ConfirmReservationForm(props: ConfirmReservationFormProps) {
  const { request, activeTripIndex, hasTripConflict } = props;

  // hooks
  const { displayMeetGreet } = useDisplayMeetGreet({
    mode: "update",
    isAirportPickUpStop:
      !!request.trips[activeTripIndex].stops[0].airport?.icaoCode ||
      !!request.trips[activeTripIndex].returnTrip?.stops[0]?.airport?.icaoCode,
    operatorSetMeetGreet:
      !isNil(request.trips[activeTripIndex].routes[0].pricing.meetGreetAmt) ||
      !isNil(
        request.trips[activeTripIndex].returnTrip?.routes[0]?.pricing
          ?.meetGreetAmt
      ),
  });
  const isGratuityEnabled = useIsGratuityEnabled({
    trips: request.trips,
    mode: "update",
  });
  const {
    operator: {
      enableCreditCardWhenBooking,
      driverGratuityPresets,
      settings: { requireConsentWhenBooking, requireSignatureWhenBooking },
    },
  } = useOperator();
  const currentUser = useCurrentUser();

  // mapping child seat pricing for default values
  const additonalItemsByTrip = map(request.trips, (trip) => {
    const { pricing } = trip.routes[0];
    const {
      boosterSeatAmt,
      boosterSeatQuantity,
      forwardFacingSeatAmt,
      forwardFacingSeatQuantity,
      rearFacingSeatAmt,
      rearFacingSeatQuantity,
    } = pricing || {};

    return {
      childSeats: {
        boosterSeatAmt,
        boosterSeatQuantity,
        forwardFacingSeatAmt,
        forwardFacingSeatQuantity,
        rearFacingSeatAmt,
        rearFacingSeatQuantity,
      },
    };
  });

  const methods = useForm<ConfirmReservationFormState>({
    defaultValues: {
      ...confirmReservationFormDefaultValues,
      additonalItemsByTrip,
    },
    mode: "onSubmit",
    resolver: yupResolver(confirmReservationFormErrorSchema),
    context: {
      isGratuityEnabled,
      minGratuityPct: first(driverGratuityPresets),
      isTermsRequired: requireConsentWhenBooking,
      isNewPaymentMethodRequired:
        enableCreditCardWhenBooking && isEmpty(currentUser?.paymentMethods),
      isExistingPaymentMethodRequired:
        enableCreditCardWhenBooking && !isEmpty(currentUser?.paymentMethods),
      isConsentSignatureRequired:
        requireConsentWhenBooking && requireSignatureWhenBooking,
      isPickUpVariantRequired: displayMeetGreet,
    },
  });

  const {
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    formState: { errors: confirmReservationErrors },
  } = methods;

  const isNetflixLogin = getIsNetflixLogin();
  const consentSignature = useConsentSignature({ trips: request.trips });

  useAuthenticationChangeUpdate({ getValues, setValue, isNetflixLogin });
  const { confirmReservation, isConfirmingReservation, isLastMinute } =
    useConfirmReservation({
      request,
      isGratuityEnabled,
      confirmReservationState: getValues(),
    });

  // derived state
  const {
    driverGratuityPctCustomerInput,
    paymentMethod,
    pickUpVariantSelected,
  } = confirmReservationErrors;

  const validToOpenSignatureDialog =
    !isEmpty(confirmReservationErrors) &&
    !driverGratuityPctCustomerInput &&
    !paymentMethod &&
    !pickUpVariantSelected;

  // scroll to the sections with errors
  const handleInvalidForm = (errors) => {
    if (errors.pickUpVariantSelected) {
      document
        .getElementById("meet-and-greet")
        ?.scrollIntoView({ behavior: "smooth" });
    } else if (errors.driverGratuityPctCustomerInput) {
      document
        .getElementById("driver-gratuity")
        ?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        id={FORM_ID}
        noValidate
        onSubmit={(e) => {
          e.preventDefault();

          handleSubmit(() => {
            consentSignature.handleConsentSignatureDialogClose();
            confirmReservation(getValues());
          }, handleInvalidForm)(e);
        }}
      >
        <ConfirmReservationFormFields
          request={request}
          control={control}
          watch={watch}
          onConsentSignatureDialogOpen={
            consentSignature.handleConsentSignatureDialogOpen
          }
          isSubmitting={isConfirmingReservation}
          formId={FORM_ID}
          activeTripIndex={activeTripIndex}
          hasTripConflict={hasTripConflict}
          setValue={setValue}
          validToOpenSignatureDialog={validToOpenSignatureDialog}
        />

        {!isConfirmingReservation && isLastMinute && <SubmitTimingDialog />}
        {requireSignatureWhenBooking && (
          <ConsentSignatureDialog
            control={control}
            watch={watch}
            setValue={setValue}
            formId={FORM_ID}
            isSubmitting={isConfirmingReservation}
            isDialogOpen={consentSignature.consentSignatureDialogOpen}
            onClose={consentSignature.handleConsentSignatureDialogClose}
            consentSignature={consentSignature.consentSignature}
            userConsentAgreement={consentSignature.isAgreementChecked}
            onConsentSignatureChange={
              consentSignature.handleConsentSignatureChange
            }
            onUserConsentAgreementToggle={
              consentSignature.handleUserConsentAgreementToggle
            }
            selectedVehicleIds={consentSignature.selectedVehicleIds}
          />
        )}
      </form>
    </FormProvider>
  );
}

export default ConfirmReservationForm;

// constants
const FORM_ID = "confirm-reservation-form";
