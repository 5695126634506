/* eslint-disable @typescript-eslint/no-unused-vars */
import { TextField } from "@mui/material";
import isNull from "lodash/isNull";
import React from "react";
import { useFormContext } from "react-hook-form";
import { RHFTextField } from "./RHFTextField";

export const ConditionalTextField = (props) => {
  const methods = useFormContext();
  const hasValidFormContext = !isNull(methods);

  if (hasValidFormContext) {
    const { children, value, onChange, error, helperText, ...rhfProps } = props;

    return (
      <RHFTextField variant="outlined" {...rhfProps}>
        {children}
      </RHFTextField>
    );
  } else {
    const { children, onAdditionalAction, ...textFieldProps } = props;

    return (
      <TextField
        // default props
        variant="outlined"
        {...textFieldProps}
      >
        {children}
      </TextField>
    );
  }
};
