import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Box, Button, Typography } from "@mui/material";

import { alabaster, granite, grayLight } from "../../design-system/colors";
import {
  useOperatorSlug,
  useScreenSize,
  useAnalytics,
} from "../../globals/hooks";
import { RequestStage, RequestStatus } from "../../types";
import AddTripDialog from "./AddTripDialog/AddTripDialog";

type CreateNewTripCardProps = {
  stage: RequestStage;
  status: RequestStatus;
  tripCount: number;
  contactId: string;
};

function CreateNewTripCard(props: CreateNewTripCardProps) {
  const { stage, status, tripCount, contactId } = props;

  // state
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  // hooks
  const history = useHistory();
  const { isMobileView } = useScreenSize();
  const operatorSlug = useOperatorSlug();
  const { track } = useAnalytics();

  // event handlers
  const handleCreateNewRequestClick = () => {
    track("reservationConfirmation_createNewrequest");
    history.push(`/${operatorSlug}/new`);
  };

  const handleAddTripClick = () => {
    setIsDialogVisible(true);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p={3}
        borderRadius="4px"
        border={`1px solid ${grayLight}`}
        bgcolor={alabaster}
      >
        {stage === RequestStage.Quote && status === RequestStatus.NewQuote ? (
          <>
            <Typography
              variant="h5"
              style={{ color: granite, marginBottom: "16px" }}
            >
              Need to add a trip to your order?
            </Typography>
            <Button
              color="primary"
              variant="contained"
              fullWidth={isMobileView}
              onClick={handleAddTripClick}
            >
              Add Trip
            </Button>
          </>
        ) : (
          <>
            <Typography
              variant="h5"
              style={{ color: granite, marginBottom: "16px" }}
            >
              Need to book another trip?
            </Typography>
            <Button
              color="primary"
              variant="outlined"
              fullWidth={isMobileView}
              onClick={handleCreateNewRequestClick}
            >
              Create a New Request
            </Button>
          </>
        )}
      </Box>
      <AddTripDialog
        open={isDialogVisible}
        onClose={() => setIsDialogVisible(false)}
        tripCount={tripCount}
        contactId={contactId}
      />
    </>
  );
}

export default CreateNewTripCard;
