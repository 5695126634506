import React, { ChangeEvent } from "react";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";

import { grayDark } from "design-system/colors";
import { ChildSeatVariant, VehicleChildSeat } from "types";
import { currency } from "utils/helpers";
import { Controller, useFormContext } from "react-hook-form";
import { CheckIcon } from "design-system/icons";
import { ViewPhotoClickParams } from "../hooks/useViewChildSeatPhoto";
import { useAnalytics } from "globals/hooks";

const CHILD_SEAT_MAP = {
  [ChildSeatVariant.Booster]: {
    name: "Booster Seat",
    qtyFieldName: "boosterSeatQuantity",
    amtFieldName: "boosterSeatAmt",
  },
  [ChildSeatVariant.ForwardFacing]: {
    name: "Forward-Facing Seat",
    qtyFieldName: "forwardFacingSeatQuantity",
    amtFieldName: "forwardFacingSeatAmt",
  },
  [ChildSeatVariant.RearFacing]: {
    name: "Rear-Facing Seat",
    qtyFieldName: "rearFacingSeatQuantity",
    amtFieldName: "rearFacingSeatAmt",
  },
};

type ChildSeatItemProps = {
  childSeat: VehicleChildSeat;
  onViewPhotoClick: (params: ViewPhotoClickParams) => void;
  childSeatsFormPath: string;
};

function ChildSeatItem(props: ChildSeatItemProps) {
  const { childSeat, onViewPhotoClick, childSeatsFormPath } = props;
  const { type, amt, description, quantity, imageUrl } = childSeat;

  // hooks
  const { control, setValue } = useFormContext();
  const theme = useTheme();
  const { track } = useAnalytics();

  // derived state
  const { name, qtyFieldName, amtFieldName } = CHILD_SEAT_MAP[type];
  const priceDisplay = amt === 0 ? "Free" : `${currency(amt)}/per`;

  // event handler
  const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);

    if (value === 0) {
      setValue(`${childSeatsFormPath}.${qtyFieldName}`, null);
      setValue(`${childSeatsFormPath}.${amtFieldName}`, null);
      return;
    }

    const amtValue = amt * value;

    setValue(`${childSeatsFormPath}.${qtyFieldName}`, value);
    setValue(`${childSeatsFormPath}.${amtFieldName}`, amtValue);
  };

  const handleViewPhotoClick = () => {
    track("childSeat_photoViewed");
    onViewPhotoClick({ name, url: imageUrl });
  };

  return (
    <Box display="flex" justifyContent="space-between" gap={2}>
      <Box display="flex" flexDirection="column" gap={1.5}>
        {/* Name and Description */}
        <Box>
          <Typography variant="body2">{name}</Typography>
          {description && (
            <Typography variant="caption" color={grayDark}>
              {description}
            </Typography>
          )}
        </Box>

        {/* View Photo Button */}
        <Button
          variant="outlined"
          size="small"
          sx={{ py: 1, px: 1.5, width: "100px" }}
          onClick={handleViewPhotoClick}
        >
          View Photo
        </Button>
        {/* hidden element to preload image */}
        <img
          src={imageUrl}
          alt="preload"
          style={{ display: "none" }}
          loading="eager"
        />
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        gap={1.5}
      >
        {/* Quantity select form field */}
        <Controller
          name={`${childSeatsFormPath}.${qtyFieldName}`}
          control={control}
          render={({ field: { value } }) => {
            return (
              <FormControl>
                <InputLabel>Quantity</InputLabel>
                <Select
                  value={value || 0}
                  label="Quantity"
                  variant="outlined"
                  renderValue={(val) => val || 0}
                  onChange={handleQuantityChange}
                  MenuProps={{ MenuListProps: { sx: { p: "6px" } } }} // padding for menu list
                  sx={{ width: "90px" }}
                >
                  {Array.from({ length: quantity + 1 }, (_, i) => i).map(
                    (num) => (
                      <MenuItem
                        key={num}
                        value={num}
                        sx={{
                          borderRadius: 1,
                          p: 1.5,
                        }}
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Typography variant="body2" fontWeight="500">
                            {num}
                          </Typography>
                          {
                            /* show check icon if selected */
                            num === value && (
                              <CheckIcon
                                size="small"
                                color={theme.palette.primary.main}
                              />
                            )
                          }
                        </Box>
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            );
          }}
        />
        <Typography variant="subtitle2">{priceDisplay}</Typography>
      </Box>
    </Box>
  );
}

export default ChildSeatItem;
