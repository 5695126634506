/**
 * @file useAnalytics.ts
 * hook that abstracts segment analytics calls
 */
import { useCallback } from "react";
import { useCurrentUser, useOperator } from ".";
import { fromGlobalId } from "../../utils/auth/helpers";

function useAnalytics() {
  const currentUser = useCurrentUser();
  const { operator } = useOperator();
  const isDevelopment = process.env.NODE_ENV === "development";

  const userId = currentUser?.id;
  const operatorId = operator?.id;

  const track = useCallback(
    (name: string, analytics?: {}) => {
      if (isDevelopment) console.log("track: ", name, analytics);
      window.analytics.track(name, {
        source: "customer",
        ...analytics,
        ...(userId && { user_id: fromGlobalId(userId).id }),
        ...(operatorId && { operator_id: fromGlobalId(operatorId).id }),
      });
    },
    [operatorId, userId, isDevelopment]
  );

  return { track };
}

export { useAnalytics };
