import React from "react";
import moment from "moment-timezone";

import { Box, Typography } from "@mui/material";

import {
  ClockIcon,
  EstimationIcon,
} from "../../../../../../design-system/icons";
import { convertMinutesToHoursMinutes } from "../../../../../../utils/helpers";

type DurationDisplayProps = {
  totalDuration?: number;
  estimatedDuration?: number;
  isEstimated?: boolean;
  dateTime?: string;
  size?: "small";
};

function DurationDisplay(props: DurationDisplayProps) {
  const { totalDuration, estimatedDuration, dateTime, size, isEstimated } =
    props;

  const formattedTime = dateTime ? moment.utc(dateTime).format("LT") : null;

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {/* duration */}
      <ClockIcon {...(size === "small" && { size: "small" })} />
      {isEstimated ? (
        <>
          <Box mx={1}>
            <Typography>
              {formattedTime ||
                convertMinutesToHoursMinutes(estimatedDuration, true)}
            </Typography>
          </Box>
          <EstimationIcon size="small" />
        </>
      ) : (
        <Box ml={1}>
          <Typography>
            {formattedTime || convertMinutesToHoursMinutes(totalDuration, true)}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default DurationDisplay;
