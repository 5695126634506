/**
 * @file graphql/invoice.ts
 * queries/mutations for Invoice.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_INVOICE_QUERY = gql`
  query Invoice($id: ID!) {
    node(id: $id) {
      id
      ... on Invoice {
        id
        createdAt
        updatedAt
        dueDate
        memo
        status
        isOutstanding
        totalAmount
        amountDue
        company {
          id
          name
          email
        }
        subPayments {
          id
          amount
          paymentId
          payment {
            id
            method
            paymentMethod {
              card {
                id
                expMonth
                expYear
                last4
                brand
              }
            }
            note
            amount
            action
            paymentStatus
            createdAt
            updatedAt
            paymentStatus
          }
        }
        invoiceNumber
        contact {
          id
          firstName
          lastName
          email
          mobilePhone
          mobilePhoneInternational
          paymentMethods {
            id
            card {
              id
              expMonth
              expYear
              last4
              brand
            }
            isPrimary
          }
        }
        company {
          id
          name
          phoneNumber
          email
          paymentMethods {
            id
            card {
              id
              expMonth
              expYear
              last4
              brand
            }
            isPrimary
          }
        }
        farmAffiliate {
          ... on MoovsNetworkOperator {
            id
            operatorName
            operatorEmail
          }
          ... on GriddnetOperator {
            id
            operatorName
            operatorEmail
          }
          ... on MoovsAiOperator {
            id
            operatorName
            operatorEmail
          }
        }
        requests {
          id
          orderNumber
          amountDue
          totalAmount
          bookingContact {
            id
            firstName
            lastName
          }
          trips {
            id
            tripNumber
            amountDue
            totalAmount
            paymentStatus
            temporaryPassenger
            contact {
              id
              firstName
              lastName
            }
            stops {
              id
              location
              dateTime
            }
            cancelledAt
          }
        }
        removedAt
      }
    }
  }
`;

export const CREATE_INVOICE_PAYMENT_MUTATION = gql`
  mutation CreateInvoicePayment($input: CreateInvoicePaymentInput!) {
    createInvoicePayment(input: $input) {
      invoice {
        id
      }
    }
  }
`;
