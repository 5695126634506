import React from "react";

import { Typography, Box } from "@mui/material";

import CancellationPolicyVehicleList from "./CancellationPolicyVehicleList";
import PolicyCondition from "./PolicyCondition";
import { CancellationPolicy } from "../../../types";
import { toTitleCase } from "../../../globals/utils/string";

type CancellationPolicyConditionsProps = {
  policies: CancellationPolicy[];
};
function CancellationPolicyConditions(
  props: CancellationPolicyConditionsProps
) {
  const { policies } = props;

  return (
    <>
      {policies
        ?.filter(
          (policy) =>
            (policy.refund25.active ||
              policy.refund50.active ||
              policy.refund100.active) &&
            policy.vehicles.length > 0
        )
        .map((policy, index) => {
          const { refund100, refund50, refund25, vehicles, variant } = policy;
          return (
            <Box key={index} m={2}>
              <Box my={2} ml={-0.5}>
                <Typography variant="h2">{toTitleCase(variant)}</Typography>
              </Box>
              {/* Full refund */}
              {refund100.active && (
                <PolicyCondition
                  variant="refund100"
                  refund={refund100}
                  isFirstElement
                />
              )}
              {/* 50% refund */}
              {refund50.active && (
                <PolicyCondition
                  variant="refund50"
                  refund={refund50}
                  isFirstElement={!refund100.active}
                />
              )}
              {/* 25% refund */}
              {refund25.active && (
                <PolicyCondition
                  variant="refund25"
                  refund={refund25}
                  isFirstElement={!refund100.active && !refund50.active}
                />
              )}
              {/* No Refund */}
              <PolicyCondition
                variant="noRefund"
                refund={
                  refund25.active
                    ? refund25
                    : refund50.active
                    ? refund50
                    : refund100
                }
                isLastElement
              />
              <CancellationPolicyVehicleList vehicles={vehicles} />
            </Box>
          );
        })}
    </>
  );
}

export default CancellationPolicyConditions;
