import { signInWithPopup, SAMLAuthProvider } from "firebase/auth";
import SamlAuthButton from "components/buttons/SamlAuthButton";
import { useAuth, useOperatorSlug } from "globals/hooks";
import { auth } from "../../../../globals/utils/firebaseApp";
import authApi from "../../../../utils/auth/authApi";
import React from "react";

type SSOCodeBlockProps = {
  onVerifySuccess?: (() => Promise<void>) | (() => void);
  onLoginDataSuccess?: (loginData: object) => void;
};

function SSOCodeBlock(props: SSOCodeBlockProps) {
  const { onVerifySuccess, onLoginDataSuccess } = props;

  const { onLogin } = useAuth();
  const operatorSlug = useOperatorSlug();

  const handleLoginWithSaml = async () => {
    try {
      const provider = new SAMLAuthProvider("saml.netflix-canary");
      const firebaseResponse = await signInWithPopup(auth, provider);
      const token = await firebaseResponse.user.getIdToken();

      const { error, data } = await authApi.loginSSO({
        operatorSlug,
        idToken: token,
        provider: "netflix",
      });

      if (error) {
        console.log(error);
        return;
      }

      await onLogin();

      onLoginDataSuccess(data);

      onVerifySuccess();
    } catch (error) {}
  };

  return <SamlAuthButton onClick={handleLoginWithSaml} />;
}

export default SSOCodeBlock;
