import React, { useEffect, useRef } from "react";
import first from "lodash/first";

import { Box, TextField, InputAdornment, Typography } from "@mui/material";

import { granite } from "design-system/colors";
import { NumberFormatPercent } from "design-system/components/inputs/NumberFormat";
import { currency } from "utils/helpers";
import GratuityToggleButton from "./GratuityToggleButton";
import { useOperator } from "globals/hooks";

type CustomGratuityInputProps = {
  value: number;
  onChange: (gratuityPct: number) => void;
  baseRateAmt: number;
  customGratuityInputOpen: boolean;
  error?: boolean;
};

function CustomGratuityInput(props: CustomGratuityInputProps) {
  const { value, onChange, baseRateAmt, customGratuityInputOpen, error } =
    props;

  // hooks
  const customGratuityInputRef = useRef(null);
  const {
    operator: { driverGratuityPresets },
  } = useOperator();

  const handleCustomDriverGratuityButtonClick = () => {
    onChange(null);
  };

  const handleCustomDriverGratuityChange = (e: any) => {
    onChange(Number(e.target.value) || null);
  };

  // focus on gratuity input after clicking button
  useEffect(() => {
    if (customGratuityInputOpen) {
      customGratuityInputRef.current.focus();
    }
  }, [customGratuityInputOpen]);

  return (
    <Box mb={1}>
      {customGratuityInputOpen ? (
        <Box mt={1}>
          <TextField
            fullWidth
            variant="outlined"
            label="Custom Gratuity Percentage"
            name="driverGratuity"
            value={value}
            inputRef={customGratuityInputRef}
            InputProps={{
              inputComponent: NumberFormatPercent as any,
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onChange={handleCustomDriverGratuityChange}
          />
          <Box my={1} display="flex">
            <Typography
              style={{ color: granite, textAlign: "center" }}
              variant="h6"
            >
              {value
                ? `+ ${currency((value / 100) * baseRateAmt)}`
                : `At least ${first(driverGratuityPresets)}%.`}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box flexDirection="column" flex="1">
          <GratuityToggleButton
            label="Custom"
            value={null}
            selected={false}
            onClick={handleCustomDriverGratuityButtonClick}
            altAmountText={`At least ${first(driverGratuityPresets)}%.`}
            error={error}
          />
        </Box>
      )}
    </Box>
  );
}

export default CustomGratuityInput;
