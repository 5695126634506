import React from "react";

import { TableCell, Typography } from "@mui/material";

import { Trip } from "../../../../types";
import { formatPhoneNumber } from "../../../../utils/phoneNumberFormatter/phoneNumberFormatter";

type MapCellProps = {
  trip: Trip;
};

function MapCell(props: MapCellProps) {
  const { trip } = props;

  const isTemporaryPassenger = !!trip?.temporaryPassenger?.name;
  const passengerName = isTemporaryPassenger
    ? trip.temporaryPassenger.name
    : `${trip?.contact.firstName} ${trip?.contact.lastName}`;
  const passengerMobilePhone = isTemporaryPassenger
    ? trip?.temporaryPassenger.phoneFormattedValue
    : formatPhoneNumber(
        trip?.contact.mobilePhone,
        trip?.contact.phoneCountryCode,
        trip?.contact.phoneCountryDialCode
      )?.formatted;

  return (
    <TableCell style={{ minWidth: 120 }}>
      <Typography variant="body1" style={{ fontWeight: "bold", fontSize: 13 }}>
        {passengerName}
      </Typography>
      <Typography variant="body2" style={{ fontSize: 13 }}>
        {passengerMobilePhone}
      </Typography>
    </TableCell>
  );
}

export default MapCell;
