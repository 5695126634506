import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Box, Button, useTheme } from "@mui/material/";

import LoginLogoutBlock from "../auth/LoginLogoutBlock/LoginLogoutBlock";
import { ArrowLeftIcon } from "../../design-system/icons";
import { white } from "../../design-system/colors";

import { useAuth } from "../../globals/hooks";

type OrderDetailsTopBarProps = {
  renderContinueAsGuest?: boolean;
};

function OrderDetailsTopBar(props: OrderDetailsTopBarProps) {
  const { renderContinueAsGuest } = props;

  // hooks
  const history = useHistory();
  const location = useLocation();
  const { authStage } = useAuth();
  const locationState = location?.state as any; // fixes typescript bug
  const theme = useTheme();

  // event handlers
  const handleBackButtonClick = () => {
    history.goBack();
  };

  return (
    <Box width="100%" bgcolor={white} zIndex={theme.zIndex.drawer + 1} pb={2}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        maxWidth="1140px"
        margin="auto"
      >
        {/* back button */}
        <Box minWidth="60px">
          {locationState?.from === "orders" &&
            authStage === "authenticated" && (
              <Button
                color="primary"
                startIcon={<ArrowLeftIcon size="small" />}
                onClick={handleBackButtonClick}
              >
                My Orders
              </Button>
            )}
        </Box>

        {/* auth */}
        <Box display="flex" justifyContent="center" alignItems="center">
          <LoginLogoutBlock renderContinueAsGuest={renderContinueAsGuest} />
        </Box>
      </Box>
    </Box>
  );
}

export default OrderDetailsTopBar;
