import React, { useState } from "react";

import { Box, Button, Divider, Typography, useTheme } from "@mui/material";

import { ChevronDownIcon, ChevronUpIcon, EditIcon } from "design-system/icons";
import { grayLight } from "design-system/colors";
import RequestSummaryItem from "./RequestSummaryItem";
import { RequestSummaryDesktopProps } from "./RequestSummary.desktop";

function RequestSummaryMobile(props: RequestSummaryDesktopProps) {
  const { requestSummaryItems, onEditClick } = props;

  // state
  const [expandedView, setExpandedView] = useState(false);

  // hooks
  const theme = useTheme();

  return (
    <>
      <Box display="flex" justifyContent="space-between" pb={1}>
        <Typography variant="h4" p={1}>
          Request Summary
        </Typography>
        <Box
          display="flex"
          p={1}
          onClick={() => setExpandedView((currentView) => !currentView)}
        >
          <Typography pr={1} color={theme.palette.primary.main}>
            {expandedView ? "Collapse" : "Expand"}
          </Typography>
          {expandedView ? (
            <ChevronUpIcon size="small" color={theme.palette.primary.main} />
          ) : (
            <ChevronDownIcon size="small" color={theme.palette.primary.main} />
          )}
        </Box>
      </Box>
      {expandedView &&
        requestSummaryItems.map(({ label, value }, i) =>
          value ? (
            <Box key={label}>
              <RequestSummaryItem label={label} value={value} key={label} />
              {i === requestSummaryItems.length - 1 ? (
                <Box px={2} pb={2}>
                  <Button variant="outlined" onClick={onEditClick}>
                    <Typography pr={1}>Edit</Typography>
                    <EditIcon size="small" color={theme.palette.primary.main} />
                  </Button>
                </Box>
              ) : (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Divider sx={{ width: "90%" }} />
                </Box>
              )}
            </Box>
          ) : null
        )}
      <Divider color={grayLight} />
    </>
  );
}

export default RequestSummaryMobile;
