import React from "react";

import { LoadingButton } from "@mui/lab";

type ConsentSignatureSubmitButtonProps = {
  isSubmitting: boolean;
  onConsentSignatureDialogOpen: () => void;
  disabled?: boolean;
  autoChargeButtonText?: string;
  formId?: string;
  validToOpenSignatureDialog?: boolean;
};

function ConsentSignatureSubmitButton(
  props: ConsentSignatureSubmitButtonProps
) {
  const {
    isSubmitting,
    onConsentSignatureDialogOpen,
    disabled,
    autoChargeButtonText,
    formId,
    validToOpenSignatureDialog,
  } = props;

  return (
    <LoadingButton
      {...(disabled && { disabled })}
      fullWidth
      disableRipple
      loading={isSubmitting}
      variant="contained"
      color="primary"
      onClick={() => {
        validToOpenSignatureDialog && onConsentSignatureDialogOpen();
      }}
      type="submit"
      form={formId}
    >
      {autoChargeButtonText || "Reserve"}
    </LoadingButton>
  );
}

export default ConsentSignatureSubmitButton;
