import React from "react";

import { TableCell } from "@mui/material";

import TripStatus from "../../../../components/trip/TripStatus";

type DispatchStatusCellProps = {
  statusSlug: string;
};

function DispatchStatusCell(props: DispatchStatusCellProps) {
  const { statusSlug } = props;

  return (
    <TableCell>
      <TripStatus hideCircle statusSlug={statusSlug} />
    </TableCell>
  );
}

export default DispatchStatusCell;
