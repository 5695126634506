/**
 * @file useForceReload.tsx
 * forces reload when out old version. This occurs when a
 * user bookmarks the app to their phone or ipad main page
 * and their device serves a cached version of client until manual
 * reload.
 *
 * reference: https://gist.github.com/kawazoe/fa3b5a3c998d16871ffb9e2fd721cb4b
 */
import { useCallback, useEffect } from "react";

function useForceReload() {
  const isIOS =
    !!global.navigator.userAgent &&
    /iPad|iPhone|iPod/.test(global.navigator.userAgent);

  // fetches app version from version.txt file in public
  const getAppVersion = () => {
    const url = `/version.txt?c=${Date.now()}`; // this should force it to always fetch latest file
    const headers = {
      "Cache-Control": "no-cache, no-store, must-revalidate",
    };

    return fetch(url, { headers }).then((response) => response.text());
  };

  const handleScreenFocus = useCallback(async () => {
    if (isIOS) {
      const storedVersion = global.localStorage.getItem(
        "mooovsCustomerVersion"
      );
      const txtVersion = await getAppVersion();

      if (txtVersion !== storedVersion) {
        global.localStorage.setItem("mooovsCustomerVersion", txtVersion);

        // reload page if storedVersion exists.
        // If it doesn't it is first time loading page and so has latest version.
        if (storedVersion) window.location.reload();
      }
    }
  }, [isIOS]);

  useEffect(() => {
    window.addEventListener("focus", handleScreenFocus, false);
  }, [handleScreenFocus]);
}

export { useForceReload };
