/**
 * @file graphql/operatorRoutes
 * queries/mutations for operatorRoutes.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_OPERATOR_ROUTE_QUERY = gql`
  query OperatorRoute($publicId: String!) {
    operatorRoute(publicId: $publicId) {
      id
      publicId
      orderNumber
      note
      operatorConfirmation
      driver {
        id
        firstName
        lastName
        mobilePhone
        email
        driverProfilePhoto
      }
      driverStatus {
        name
      }
      request {
        id
        orderNumber
        type
        bookingContact {
          id
          createdAt
          updatedAt
          firstName
          lastName
          email
          mobilePhone
          mobilePhoneInternational
          phoneCountryCode
          phoneCountryDialCode
          phoneCountryFormat
          phoneCountryName
          paymentMethods {
            id
            card {
              id
              expMonth
              expYear
              last4
              brand
            }
            isPrimary
          }
        }
      }
      alert {
        newDriver
        uniqueLocation
        airport
        largeVehicle
        partner
        luggage
        signage
        carSeat
      }
      driver {
        id
        firstName
        lastName
        mobilePhone
        email
      }
      vehicle {
        id
        name
        licensePlate
        photos {
          id
          url
          photoIndex
        }
        vehicleType {
          typeName
          typeSlug
        }
        operator {
          id
          name
          generalEmail
          companyLogoUrl
        }
      }
      statusSlug
      driverNote
      trip {
        id
        requestId
        totalGroupSize
        totalDuration
        estimatedDuration
        useTotalDuration
        tripNumber
        note
        temporaryPassenger
        tempPassenger {
          name
          mobilePhone
        }
        routes {
          id
          createdAt
          updatedAt
          dispatchStatus
          carryOnLuggage
          checkedLuggage
          oversizeLuggage
          routeDispatch {
            id
            currentStatus
            currentStopName
            currentStopArrivalTime
            driverEta
          }
          driver {
            id
            firstName
            lastName
            mobilePhone
            email
          }
          vehicle {
            id
            name
            capacity
            vehicleType {
              typeName
              typeSlug
            }
            description
            photos {
              id
              url
              photoIndex
            }
          }
        }
        stops {
          id
          stopIndex
          location
          dateTime
          logisticStreetViewUrl
          note
          flightNumber
          airportIcao
          airlineIcao
          coordinates
          originalDateTime
          flightOffset
          pickUpVariant
          airport {
            icaoCode
            iataCode
            airportName
            countryName
            countryIso2
            timezone
            gmt
            coordinates
          }
          trackedFlight {
            id
            faFlightId
            updatedAt
            airline {
              icaoCode
              airlineName
              iataCode
            }
            actualAirline {
              airlineName
              iataCode
              icaoCode
            }
            flightStatus
            flightNumber
            actualFlightNumber
            origin {
              id
              airport {
                airportName
                iataCode
                icaoCode
                countryName
                countryIso2
                timezone
                gmt
              }
              scheduledGate
              scheduledDateTime
              scheduledTerminal
              actualGate
              actualDateTime
              estimatedDateTime
              actualTerminal
            }
            destination {
              id
              airport {
                airportName
                iataCode
                icaoCode
                countryName
                countryIso2
                timezone
                gmt
              }
              scheduledGate
              scheduledDateTime
              scheduledTerminal
              actualGate
              estimatedDateTime
              actualDateTime
              actualTerminal
            }
          }
        }
        contact {
          id
          firstName
          lastName
          email
          mobilePhone
          mobilePhoneInternational
          paymentMethods {
            id
            card {
              id
              expMonth
              expYear
              last4
              brand
            }
            isPrimary
          }
        }
      }
    }
  }
`;
