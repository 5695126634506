/**
 * @file authenticateContact.graphql.ts
 * queries/mutations for authenticated Contacts.
 *
 * Authenticated Contact are contacts who
 * have logged in using twilio verify service.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_ME_QUERY = gql`
  query loadMe {
    loadMe {
      id
      firstName
      lastName
      email
      mobilePhone
      mobilePhoneInternational
      stripeCustomer
      passengerLinkedPaymentMethods {
        id
        card {
          id
          expMonth
          expYear
          last4
          brand
        }
      }
      paymentMethods {
        id
        stripeId
        card {
          id
          expMonth
          expYear
          last4
          brand
        }
        isPrimary
        billingDetails {
          name
        }
        linkedPassenger {
          id
          firstName
          lastName
        }
      }
      company {
        id
        paymentMethods {
          id
          stripeId
          card {
            id
            expMonth
            expYear
            last4
            brand
          }
        }
      }
      linkedPassengers {
        id
        firstName
        lastName
        email
        mobilePhone
        phoneCountryCode
        phoneCountryDialCode
        phoneCountryName
        phoneCountryFormat
      }
      passengerLinkedPaymentMethods {
        id
        card {
          id
          expMonth
          expYear
          last4
          brand
        }
        billingDetails {
          name
        }
      }
    }
  }
`;
