import { useEffect, useState } from "react";
import size from "lodash/size";

import { useCurrentUser } from "globals/hooks";
import { Contact } from "types";

export type SelectedPassenger = {
  id: string;
  email: string;
  mobilePhone: string;
  firstName: string;
  lastName: string;
};

enum DefaultPassengerType {
  BookingContact = "Booking Contact",
  LinkedPassenger = "Linked Passenger",
}

function useDefaultPassenger(initialContact?: Contact) {
  // state
  const [selectedPassenger, setSelectedPassenger] =
    useState<SelectedPassenger>(null);
  const [passengerType, setPassengerType] =
    useState<DefaultPassengerType | null>(null);

  // hooks
  const currentUser = useCurrentUser();

  // effects
  useEffect(() => {
    if (!currentUser) return;

    if (initialContact && !selectedPassenger) {
      setSelectedPassenger(initialContact);
      return;
    }

    if (selectedPassenger) return;

    const passengerIsBookingContact = !!(
      size(currentUser.linkedPassengers) === 0 ||
      size(currentUser.linkedPassengers) > 1
    );

    if (passengerIsBookingContact) {
      setSelectedPassenger({
        id: currentUser.id,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: currentUser.email,
        mobilePhone: currentUser.mobilePhone,
      });
      setPassengerType(DefaultPassengerType.BookingContact);
    } else if (size(currentUser.linkedPassengers) === 1) {
      const linkedPassenger = currentUser.linkedPassengers[0];
      setSelectedPassenger({
        id: linkedPassenger.id,
        firstName: linkedPassenger.firstName,
        lastName: linkedPassenger.lastName,
        email: linkedPassenger.email,
        mobilePhone: linkedPassenger.mobilePhone,
      });
      setPassengerType(DefaultPassengerType.LinkedPassenger);
    }
  }, [currentUser, selectedPassenger, initialContact]);

  if (!currentUser) return null;

  return {
    selectedPassenger,
    setSelectedPassenger,
    selectedPassengerIsBookingContact: selectedPassenger?.id === currentUser.id,
    passengerType,
  };
}

export { useDefaultPassenger };
