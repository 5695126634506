import React from "react";

type EditIconProps = {
  color?: string;
  size?: "small";
};

const EditIcon = (props: EditIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.66563 2.66878H2.25903C1.92511 2.66878 1.60487 2.80142 1.36876 3.03754C1.13265 3.27365 1 3.59389 1 3.92781V12.741C1 13.0749 1.13265 13.3952 1.36876 13.6313C1.60487 13.8674 1.92511 14 2.25903 14H11.0722C11.4062 14 11.7264 13.8674 11.9625 13.6313C12.1986 13.3952 12.3313 13.0749 12.3313 12.741V8.33441M11.387 1.7245C11.6374 1.47407 11.9771 1.33337 12.3313 1.33337C12.6854 1.33337 13.0251 1.47407 13.2755 1.7245C13.526 1.97494 13.6667 2.31461 13.6667 2.66878C13.6667 3.02295 13.526 3.36261 13.2755 3.61305L7.29515 9.59344L4.77709 10.223L5.4066 7.70489L11.387 1.7245Z"
          stroke={color || "#B3B3B3"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 4.12132H4C3.46957 4.12132 2.96086 4.33204 2.58579 4.70711C2.21071 5.08218 2 5.59089 2 6.12132V20.1213C2 20.6518 2.21071 21.1605 2.58579 21.5355C2.96086 21.9106 3.46957 22.1213 4 22.1213H18C18.5304 22.1213 19.0391 21.9106 19.4142 21.5355C19.7893 21.1605 20 20.6518 20 20.1213V13.1213M18.5 2.62132C18.8978 2.2235 19.4374 2 20 2C20.5626 2 21.1022 2.2235 21.5 2.62132C21.8978 3.01915 22.1213 3.55871 22.1213 4.12132C22.1213 4.68393 21.8978 5.2235 21.5 5.62132L12 15.1213L8 16.1213L9 12.1213L18.5 2.62132Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
