import React from "react";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import { useQuery } from "@apollo/client";

import { Box, Divider, Link, Typography, useTheme } from "@mui/material";

import { grayDark } from "../../design-system/colors";
import { OpenIcon } from "../../design-system/icons";
import { LOAD_OPERATOR_QUERY } from "../../globals/graphql";
import { useScreenSize } from "../../globals/hooks";
import DownloadButton from "../buttons/DownloadButton";
import { formatPhoneNumber } from "utils/phoneNumberFormatter/phoneNumberFormatter";

type PriceSummaryHeaderProps = {
  bookingContact: { name: string; email: string; mobilePhone: string };
  companyInfo: { name: string; email?: string; phoneNumber?: string };
  orderNumber: string;
  createdAt: string;
  updatedAt: string;
  requestId: string;
};

function PriceSummaryHeader(props: PriceSummaryHeaderProps) {
  const {
    bookingContact,
    companyInfo,
    orderNumber,
    createdAt,
    updatedAt,
    requestId,
  } = props;
  const { operatorSlug } = useParams<{ operatorSlug: string }>();
  const { isMobileView } = useScreenSize();
  const theme = useTheme();

  // queries
  const { data: operatorData } = useQuery(LOAD_OPERATOR_QUERY, {
    variables: {
      operatorSlug: operatorSlug,
    },
    skip: !operatorSlug,
    fetchPolicy: "cache-and-network",
  });

  const operator = operatorData?.operator;
  const formattedTwilioNumber = formatPhoneNumber(
    operator.twilioPhoneNumber?.phoneNumber
  )?.formatted;
  const operatorPhoneNumber = formatPhoneNumber(
    operator.voicePhoneNumber
  )?.formatted;

  return (
    <Box
      display="flex"
      flex="1"
      flexDirection="column"
      justifyContent="space-between"
      flexWrap="wrap"
    >
      <Box display="flex" flexDirection={isMobileView ? "column" : "row"}>
        {/* Header */}
        <Box mb={1} width="100%" maxWidth="300px" pr={4}>
          <Typography variant="h1" sx={{ fontWeight: 600 }}>
            Price Summary
          </Typography>
          {isMobileView && (
            <Box mt={1} ml={-1}>
              <DownloadButton
                endpoint={"/endpoint/priceSummary/customerPdfDownloadHandler"}
                buttonText={"Price Summary PDF"}
                params={{
                  id: requestId,
                }}
                analyticsName="customer_priceSummarypdf"
                variant="desktop"
              />
            </Box>
          )}
        </Box>

        {/* Additional Price Summary Details */}
        <Box
          display="flex"
          flexDirection={isMobileView ? "column" : "row"}
          mb={isMobileView ? 3 : 0}
        >
          <Box mr={3}>
            <Typography noWrap variant="overline" sx={{ color: grayDark }}>
              order no
            </Typography>
            <Box mb={1}>
              <Typography variant="h6">{orderNumber}</Typography>
            </Box>
          </Box>
          <Box mr={3}>
            <Typography variant="overline" sx={{ color: grayDark }}>
              created
            </Typography>
            <Box mb={1}>
              <Typography variant="h6" noWrap>
                {moment(createdAt).format("LLL")}
              </Typography>
            </Box>
          </Box>
          <Box mr={2}>
            <Typography variant="overline" sx={{ color: grayDark }}>
              updated
            </Typography>
            <Box mb={1}>
              <Typography variant="h6" noWrap>
                {moment(updatedAt).format("LLL")}
              </Typography>
            </Box>
          </Box>
          {!isMobileView && (
            <Box>
              <DownloadButton
                endpoint={"/endpoint/priceSummary/customerPdfDownloadHandler"}
                buttonText={"Price Summary PDF"}
                params={{
                  id: requestId,
                }}
                analyticsName="customer_priceSummarypdf"
                variant="desktop"
              />
            </Box>
          )}
        </Box>
      </Box>

      {!isMobileView && (
        <Box my={2}>
          <Divider />
        </Box>
      )}

      <Box display="flex" flexDirection={isMobileView ? "column" : "row"}>
        {/* Booking Contact Info */}
        <Box
          display="flex"
          flexDirection="column"
          mb={3}
          width="100%"
          maxWidth="300px"
        >
          <Typography variant="overline" sx={{ color: grayDark }}>
            Booking Contact
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {bookingContact.name}
          </Typography>
          <Typography variant="body2">{bookingContact.email}</Typography>
          <Typography variant="body2">{bookingContact.mobilePhone}</Typography>
        </Box>

        {companyInfo && (
          <Box
            display="flex"
            flexDirection="column"
            mb={3}
            width="100%"
            maxWidth="300px"
          >
            <Typography variant="overline" sx={{ color: grayDark }}>
              Company
            </Typography>
            <Typography noWrap variant="body1" sx={{ fontWeight: "bold" }}>
              {companyInfo.name}
            </Typography>
            <Typography variant="body2">{companyInfo.email}</Typography>
            <Typography variant="body2">
              {formatPhoneNumber(companyInfo.phoneNumber)?.formatted}
            </Typography>
          </Box>
        )}

        {/* Operator Info */}
        {operator && (
          <Box display="flex" flexDirection="column" pb={3}>
            <Typography variant="overline" sx={{ color: grayDark }}>
              Contact Us:
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {operator.name}
            </Typography>
            <Typography variant="body2">{operator.generalEmail}</Typography>
            <Typography variant="body2">
              {operatorPhoneNumber || formattedTwilioNumber}
            </Typography>
            <Link
              href={`/${operatorSlug}/terms-and-conditions`}
              target="_blank"
            >
              <Box display="flex">
                <Typography sx={{ fontWeight: 500 }} variant="body2">
                  Terms & Conditions
                </Typography>
                <Box ml={1}>
                  <OpenIcon size="small" color={theme.palette.primary.main} />
                </Box>
              </Box>
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default PriceSummaryHeader;
