import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import startCase from "lodash/startCase";

import {
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";

import PassengerLinkIcon from "design-system/icons/actions/PassengerLinkIcon";
import PassengerUnlinkIcon from "design-system/icons/actions/PassengerUnlinkIcon";
import { useAnalytics, useSnackbar } from "globals/hooks";
import {
  LOAD_ME_QUERY,
  UNLINK_PASSENGER_FROM_PAYMENT_METHOD_MUTATION,
} from "globals/graphql";

type UnlinkMenuButtonProps = {
  buttonText: string;
  menuOptionText: string;
  paymentMethodId: string;
};

function UnlinkCardMenuButton(props: UnlinkMenuButtonProps) {
  const { buttonText, menuOptionText, paymentMethodId } = props;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const theme = useTheme();

  // state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // reference from mui doc
  const open = Boolean(anchorEl);

  // mutation
  const [unlinkPassengerFromPaymentMethod] = useMutation(
    UNLINK_PASSENGER_FROM_PAYMENT_METHOD_MUTATION,
    {
      onCompleted: () => {
        snackbar.success("Successfully unlinked!");
        handleCloseMenuItem();
        track("linkPassenger_cardUnlinked");
      },
      onError() {
        snackbar.error("Error unlinking");
      },
      refetchQueries: [{ query: LOAD_ME_QUERY }],
    }
  );

  // event handlers
  const handleCloseMenuItem = () => {
    setAnchorEl(null);
  };

  const handleUnlinkPassengerButtonClick = (
    e: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(e.currentTarget);
  };

  const handleUnlinkPassengeMenuItemClick = async () => {
    await unlinkPassengerFromPaymentMethod({
      variables: {
        input: {
          paymentMethodId,
        },
      },
    });
  };

  return (
    <>
      <Button
        id="unlink-passenger-button"
        aria-controls={open ? "unlink-passenger-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleUnlinkPassengerButtonClick}
      >
        <Box display="flex" alignItems="center">
          <Box mr={1} mt={0.3}>
            <PassengerLinkIcon color={theme.palette.primary.main} />
          </Box>

          <Typography
            variant="body2"
            noWrap
            sx={{ fontWeight: 500, maxWidth: "100px" }}
          >
            {startCase(buttonText)}
          </Typography>
        </Box>
      </Button>
      <Menu
        id="unlink-passenger-menu"
        aria-labelledby="unlink-passenger-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenuItem}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={handleUnlinkPassengeMenuItemClick}
          sx={{ marginX: 1.2, paddingY: 0.5 }}
        >
          <Box mr={1.5}>
            <PassengerUnlinkIcon />
          </Box>
          <Typography variant="body2">{menuOptionText}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export default UnlinkCardMenuButton;
