import React from "react";

import { Box, Link, Typography } from "@mui/material";

import { tintRed, white, errorRed } from "design-system/colors";
import UnavailableVehicleIcon from "design-system/icons/info/UnavailableVehicleIcon";
import { useScreenSize, useOperator } from "globals/hooks";
import { formatPhoneNumber } from "utils/phoneNumberFormatter/phoneNumberFormatter";

function VehicleUnavailableBanner() {
  // hooks
  const { isMobileView } = useScreenSize();
  const {
    operator: { voicePhoneNumber, twilioPhoneNumber },
  } = useOperator();

  // derived state
  const formattedTwilioNumber = formatPhoneNumber(
    twilioPhoneNumber?.phoneNumber
  )?.formatted;

  return (
    <Box
      bgcolor={tintRed}
      width="100%"
      borderRadius="7px"
      p={2}
      mt={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box display="flex">
        <Box
          borderRadius="25px"
          bgcolor={white}
          width="45px"
          height="45px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <UnavailableVehicleIcon />
        </Box>
        <Box display="flex" flexDirection={isMobileView ? "column" : "row"}>
          <Box
            ml={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography variant="subtitle2" color={errorRed}>
              Vehicle no longer available at this time
            </Typography>
            <Typography variant="body2" fontSize="12px" color={errorRed}>
              Please contact us to check availability for a replacement vehicle
            </Typography>
          </Box>
          {isMobileView && (
            <Box display="flex" flexDirection="column" ml={1} mt={2}>
              <Typography variant="overline">Phone</Typography>
              <Link
                variant="body2"
                fontSize="12px"
                href={`tel:${voicePhoneNumber || formattedTwilioNumber}`}
              >
                {voicePhoneNumber || formattedTwilioNumber}
              </Link>
            </Box>
          )}
        </Box>
      </Box>
      {!isMobileView && (
        <Box display="flex" flexDirection="column">
          <Typography variant="overline">Phone</Typography>
          <Link
            fontSize="12px"
            variant="body2"
            href={`tel:${voicePhoneNumber || formattedTwilioNumber}`}
          >
            {voicePhoneNumber || formattedTwilioNumber}
          </Link>
        </Box>
      )}
    </Box>
  );
}

export default VehicleUnavailableBanner;
