import { Dispatch, SetStateAction } from "react";
import { useLazyQuery } from "@apollo/client";
import first from "lodash/first";

import { black, errorRed, white } from "design-system/colors";
import { LOAD_POTENTIAL_TRACKED_FLIGHT_QUERY } from "globals/graphql";
import { useSnackbar } from "globals/hooks";
import { Stop, TrackedFlight } from "types";

type UsePotentialTrackedFlightQueryProps = {
  stop: Partial<Stop>;
  onStopChange: (
    stop: Partial<Stop>
  ) => void | Dispatch<SetStateAction<Partial<Stop>>>;
  setMultipleFlightsDialogData: Dispatch<
    SetStateAction<{
      flights: TrackedFlight[];
      flightNumber: string;
      inputAirportIcao: string;
    }>
  >;
  setIsFetchingFlightInfo?: Dispatch<SetStateAction<boolean>>;
};

function usePotentialTrackedFlightQuery(
  props: UsePotentialTrackedFlightQueryProps
) {
  const {
    stop,
    onStopChange,
    setMultipleFlightsDialogData,
    setIsFetchingFlightInfo,
  } = props;

  // hooks
  const snackbar = useSnackbar();

  const [
    loadPotentialTrackedFlight,
    { loading: potentialTrackedFlightLoading },
  ] = useLazyQuery<{ loadPotentialTrackedFlight: TrackedFlight[] }>(
    LOAD_POTENTIAL_TRACKED_FLIGHT_QUERY,
    {
      onCompleted: ({ loadPotentialTrackedFlight: trackedFlights }) => {
        setIsFetchingFlightInfo && setIsFetchingFlightInfo(false);

        // variant can potentially change while waiting for query results to come back
        if (stop.variant !== "airport") return;

        // determine effects based on # of matching flights
        if (trackedFlights.length === 0) {
          // no flights
          snackbar.warning(
            "Flight does not exist. Please check the number and try again",
            { snackbarColor: white, iconColor: errorRed, textColor: black }
          );
        } else if (trackedFlights.length === 1) {
          onStopChange({
            trackedFlight: first(trackedFlights),
            flightNumber: first(trackedFlights).flightNumber,
          });
        } else {
          // multiple flights match
          setMultipleFlightsDialogData({
            flightNumber: first(trackedFlights).flightNumber,
            flights: trackedFlights,
            inputAirportIcao: stop.airport?.icaoCode,
          });
        }
      },
      onError: () => {
        setIsFetchingFlightInfo && setIsFetchingFlightInfo(false);
        snackbar.error("Could not retrieve flight");
      },
      fetchPolicy: "network-only",
    }
  );

  return {
    loadPotentialTrackedFlight,
    potentialTrackedFlightLoading,
  };
}

export { usePotentialTrackedFlightQuery };
