import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { useScreenSize } from "../../../../globals/hooks";

import { Request } from "../../../../types";
import { orderTypeEnumToNameMap } from "../../../../utils/enumMaps";
import AllTripsTable from "./AllTripsTable";

type AllTripsPageContentProps = {
  request: Request;
};

function AllTripsPageContent(props: AllTripsPageContentProps) {
  const { request } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  // derived state
  const bookingContact = request.bookingContact;
  const nonCancelledTrips = request.trips.filter((trip) => !trip.cancelledAt);

  return (
    <Box my={2} display="flex" flexDirection="column">
      <Box px={isMobileView ? 2 : 0}>
        <Box my={2}>
          <Typography variant="h1">
            All Trips for {request.orderNumber}
          </Typography>
          <Typography variant="h5">
            {orderTypeEnumToNameMap[request.orderType]}
          </Typography>
        </Box>

        <Divider />

        <Box mt={2} mb={4}>
          <Typography variant="overline">Booking Contact</Typography>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            {bookingContact.firstName} {bookingContact.lastName}
          </Typography>
          <Typography variant="body2">{bookingContact.email}</Typography>
          <Typography variant="body2">{bookingContact.mobilePhone}</Typography>
        </Box>
      </Box>

      <AllTripsTable trips={nonCancelledTrips} />
    </Box>
  );
}

export default AllTripsPageContent;
