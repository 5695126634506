/**
 * @file graphql/promoCode.graphql.ts
 * queries for Promo Codes.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_PROMO_CODE_VALIDATION_QUERY = gql`
  query loadPromoCodeValidation(
    $promoCodeName: String!
    $pickUpDateTime: DateTime!
  ) {
    loadPromoCodeValidation(
      promoCodeName: $promoCodeName
      pickUpDateTime: $pickUpDateTime
    ) {
      id
      promoCodeName
      promoCodeAmt
      promoCodePercent
    }
  }
`;
