import React from "react";
import { useHistory } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import { granite } from "../../../../design-system/colors";
import CircleAvatar from "../../../../design-system/components/CircleAvatar";
import {
  useOperatorSlug,
  useAuth,
  useCurrentUser,
  useAnalytics,
  useLaunchDarklyFlags,
} from "../../../../globals/hooks";
import MoovsMenu from "../../../globals/MoovsMenu";
import {
  ChevronDownIcon,
  UserIcon,
  OrdersIcon,
  LogoutIcon,
  PassengersIcon,
} from "design-system/icons";

function AuthenticatedContactMenu() {
  // hooks
  const history = useHistory();
  const operatorSlug = useOperatorSlug();
  const { firstName = "", lastName = "" } = useCurrentUser() ?? {};
  const { onLogout } = useAuth();
  const { track } = useAnalytics();
  const { enableLinkedPassenger } = useLaunchDarklyFlags();

  return (
    <MoovsMenu
      buttonContent={
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          width="180x"
        >
          <Box>
            <CircleAvatar placeholder={[firstName, lastName]} size="small" />
          </Box>

          <Box mx={1} width="80px">
            <Typography
              variant="body2"
              noWrap
            >{`${firstName} ${lastName}`}</Typography>
          </Box>

          <ChevronDownIcon color={granite} />
        </Box>
      }
      menuItems={[
        {
          label: "Profile",
          icon: UserIcon,
          onClick: () => {
            track("bookingTool_profile");
            history.push(`/${operatorSlug}/user/profile`);
          },
        },
        {
          label: "Orders",
          icon: OrdersIcon,
          onClick: () => {
            track("bookingTool_orders");
            history.push(`/${operatorSlug}/user/orders`);
          },
        },
        ...(enableLinkedPassenger
          ? [
              {
                label: "Passengers",
                icon: PassengersIcon,
                onClick: () => history.push(`/${operatorSlug}/user/passengers`),
              },
            ]
          : []),
        {
          label: "Logout",
          icon: LogoutIcon,
          onClick: onLogout,
        },
      ]}
    />
  );
}

export default AuthenticatedContactMenu;
