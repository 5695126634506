import { useCallback } from "react";
import { useLazyQuery } from "@apollo/client";

import { LOAD_VEHICLE_BRA_QUERY } from "globals/graphql";
import { CreateTripState } from "pages/new/context/initialState";
import { TripCategory, Vehicle } from "types";
import { formatStopsForBRACalculations } from "pages/new/utils/formatStopsForBRACalculations";

type LoadVehicleBRAParams = {
  vehicleId: string;
  trip: CreateTripState;
  returnTrip: CreateTripState;
  tripCategory: TripCategory;
};

const useLoadVehicleBRA = () => {
  const [loadVehicleBRA, { loading }] = useLazyQuery<{
    loadVehicleBRA: Vehicle;
  }>(LOAD_VEHICLE_BRA_QUERY, {
    fetchPolicy: "network-only",
  });

  const queryVehicleBRA = useCallback(
    (params: LoadVehicleBRAParams) => {
      const { vehicleId, trip, returnTrip, tripCategory } = params;

      const { vehicleStops: stops, returnTripStops } =
        formatStopsForBRACalculations({
          trip,
          returnTrip,
        });

      return loadVehicleBRA({
        variables: {
          vehicleId,
          stops,
          returnTripStops,
          tripType: tripCategory,
        },
      }).then((res) => res.data?.loadVehicleBRA);
    },
    [loadVehicleBRA]
  );

  return [queryVehicleBRA, loading] as const;
};

export default useLoadVehicleBRA;
