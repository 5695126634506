import { useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import first from "lodash/first";
import map from "lodash/map";

import { useOperatorSlug, useSnackbar } from "globals/hooks";
import { LOAD_CANCELLATION_POLICIES_QUERY } from "globals/graphql/cancellationPolicy.graphql";
import { Query } from "types";

type UseCancellationPoliciesParams = {
  trips: { routes: { vehicle?: { id: string } }[] }[];
};

function useCancellationPolicies(params: UseCancellationPoliciesParams) {
  const { trips } = params;

  // hooks
  const operatorSlug = useOperatorSlug();
  const snackbar = useSnackbar();

  // state
  const [cancellationPolicyDialogOpen, setCancellationPolicyDialogOpen] =
    useState(false);

  // query
  const { data } = useQuery<Query>(LOAD_CANCELLATION_POLICIES_QUERY, {
    variables: {
      operatorSlug,
    },
    onError: (error) => {
      snackbar.error("Error fetching cancelation policies");
      console.error(error);
    },
  });

  // derived state
  const cancellationPolicies = data?.cancellationPolicies;

  // shows cancellation policy link only if vehicle is linked to a cancellation policy with active conditions
  const shouldRenderCancellationPolicies = useMemo(() => {
    if (!trips || !cancellationPolicies) {
      return;
    }

    return trips.some((trip) =>
      cancellationPolicies.some(
        (policy) =>
          map(policy.vehicles, "id").includes(first(trip.routes).vehicle?.id) &&
          (policy.refund25.active ||
            policy.refund50.active ||
            policy.refund100.active)
      )
    );
  }, [trips, cancellationPolicies]);

  // event handlers
  const handleCancellationPolicyClick = () => {
    setCancellationPolicyDialogOpen(true);
  };

  const handleCancellationPolicyClose = () => {
    setCancellationPolicyDialogOpen(false);
  };

  return {
    cancellationPolicies,
    shouldRenderCancellationPolicies,
    cancellationPolicyDialogOpen,
    onCancellationPolicyClick: handleCancellationPolicyClick,
    onCancellationPolicyClose: handleCancellationPolicyClose,
  };
}

export { useCancellationPolicies };
