import first from "lodash/first";
import isNil from "lodash/isNil";

import { PickUpVariant, PromoCode, Stop } from "types";
import { renderBaseRateFieldLabel } from "../../components/price-summary/utils/displayUtils";

export type UseOrderPricingType = {
  baseRate: {
    label: string;
    value: number;
  };
  driverGratuity: {
    label: string;
    value: number;
  };
  promoDiscount: {
    label: string;
    value: number;
  };
  tax: {
    label: string;
    value: number;
  };
  tolls: {
    label: string;
    value: number;
  };
  meetGreet: {
    label: string;
    value: number;
    selected: boolean;
  };
  boosterSeat: {
    label: string;
    value: number;
    quantity?: number;
  };
  forwardFacingSeat: {
    label: string;
    value: number;
    quantity?: number;
  };
  rearFacingSeat: {
    label: string;
    value: number;
    quantity?: number;
  };
  other: {
    label: string;
    value: number;
  };
  other2: {
    label: string;
    value: number;
  };
  other3: {
    label: string;
    value: number;
  };
  promoCode?: {
    label: string;
    value: number;
    removeField?: string;
  };
};

export type CustomerPricingType = {
  outboundTripPricing: UseOrderPricingType;
  outboundTripTotalAmt: number;
  returnTripPricing?: UseOrderPricingType;
  returnTripTotalAmt?: number;
  orderTotalAmt: number;
  tripIndex?: number;
};

export type PricingByTripType = {
  tripId: string;
  returnTripPricing?: PricingByTripType;
} & CustomerPricingType;

export type CalculateTripPricingProps = {
  trip: any;
  userSelectedDriverGratuityPercent?: number;
  shouldRenderDriverGratuity: boolean;
  pickUpVariantSelected: PickUpVariant;
  pricingLayoutMeetGreet: number;
  userAppliedPromoCode?: Pick<
    PromoCode,
    "id" | "promoCodeName" | "promoCodeAmt" | "promoCodePercent"
  >;
  childSeatInput?: {
    boosterSeatAmt?: number;
    boosterSeatQuantity?: number;
    forwardFacingSeatAmt?: number;
    forwardFacingSeatQuantity?: number;
    rearFacingSeatAmt?: number;
    rearFacingSeatQuantity?: number;
  };
};

type CalculatePricingParams = {
  percent?: number;
  amt?: number;
  baseRateAmt: number;
};

export const calculatePricing = (params: CalculatePricingParams) => {
  const { percent, amt, baseRateAmt } = params;

  // TODO: consider letting customer know if value was calculated as a percentage.
  // right now just shows total amt.
  if (!amt && !percent) return 0;
  return percent * baseRateAmt || amt;
};

export const calculateTripPricing = (props: CalculateTripPricingProps) => {
  const {
    trip,
    shouldRenderDriverGratuity,
    userSelectedDriverGratuityPercent,
    pickUpVariantSelected,
    pricingLayoutMeetGreet,
    userAppliedPromoCode,
    childSeatInput: {
      boosterSeatAmt = null,
      boosterSeatQuantity,
      forwardFacingSeatAmt = null,
      forwardFacingSeatQuantity,
      rearFacingSeatAmt = null,
      rearFacingSeatQuantity,
    } = {},
  } = props;

  const {
    baseRateAmt,
    hourlyBaseRate,
    hourlyBaseRateHours,
    driverGratuityAmt,
    driverGratuityPercent,
    promoDiscountAmt,
    promoDiscountPercent,
    taxAmt,
    taxPercent,
    tollsAmt,
    meetGreetAmt: operatorSetMeetGreetAmt,
    otherAmt,
    otherPercent,
    otherName,
    other2Amt,
    other2Percent,
    other2Name,
    other3Amt,
    other3Percent,
    other3Name,
    promoCodeId,
    promoCodeName,
    promoCodeAmt,
    promoCodePercent,
  } = trip.routes[0].pricing || {};

  const convertNaN = (value) => {
    return isNaN(value)
      ? 0
      : !isNil(value)
      ? Math.round(value * 100) / 100
      : null;
  };

  // In case where trip already has driverGratuityAmt, we use it
  const normalizedDriverGratuity =
    driverGratuityAmt || driverGratuityPercent
      ? driverGratuityPercent * baseRateAmt || driverGratuityAmt
      : // Otherwise, we only want to set gratuity amount if block is rendered
      shouldRenderDriverGratuity
      ? calculatePricing({
          baseRateAmt,
          percent: userSelectedDriverGratuityPercent / 100,
        })
      : 0; // Else, there is not gratuity for trip

  let meetGreetAmtInput;
  let meetGreetSelected;
  const isAirportPickup = !!first<Stop>(trip.stops).airport?.icaoCode;

  if (!isNil(operatorSetMeetGreetAmt)) {
    // if operator already set meet & greet amt
    meetGreetAmtInput = operatorSetMeetGreetAmt;
    meetGreetSelected = true;
  } else if (isAirportPickup) {
    // if trip has an aiport pick up stop
    meetGreetAmtInput =
      pickUpVariantSelected === PickUpVariant.MeetGreet
        ? pricingLayoutMeetGreet
        : null;
    meetGreetSelected = pickUpVariantSelected === PickUpVariant.MeetGreet;
  } else {
    // if trip does not have an airport pick up stop
    meetGreetAmtInput = null;
    meetGreetSelected = false;
  }

  // use promo code if it exists on trip, else use promo code from user applied
  const promoCode = promoCodeId
    ? {
        label: `Promo Code (${promoCodeName})`,
        value: convertNaN(
          !isNil(promoCodePercent)
            ? promoCodePercent * baseRateAmt
            : promoCodeAmt
        ),
        isNeg: true,
      }
    : userAppliedPromoCode
    ? {
        label: `Promo Code (${userAppliedPromoCode.promoCodeName})`,
        value: convertNaN(
          !isNil(userAppliedPromoCode.promoCodePercent)
            ? userAppliedPromoCode.promoCodePercent * baseRateAmt
            : userAppliedPromoCode.promoCodeAmt
        ),
        isNeg: true,
        removeField: "promoCodeCustomerInput",
      }
    : {
        label: "Promo Code",
        value: null,
      };

  const pricing = {
    baseRate: {
      label: renderBaseRateFieldLabel(hourlyBaseRate, hourlyBaseRateHours),
      value: baseRateAmt,
    },
    driverGratuity: {
      label: "Driver Gratuity",
      value: convertNaN(normalizedDriverGratuity),
    },
    promoDiscount: {
      label: "Discount",
      value: convertNaN(promoDiscountPercent * baseRateAmt || promoDiscountAmt),
      isNeg: true,
    },
    tax: {
      label: "Tax",
      value: convertNaN(taxPercent * baseRateAmt || taxAmt),
    },
    tolls: {
      label: "Tolls",
      value: convertNaN(tollsAmt),
    },
    meetGreet: {
      label: "Meet & Greet",
      value: meetGreetAmtInput,
      selected: meetGreetSelected,
    },
    rearFacingSeat: {
      label:
        rearFacingSeatQuantity > 1
          ? `${rearFacingSeatQuantity} X Rear-Facing Seat`
          : "Rear-Facing Seat",
      value: rearFacingSeatAmt === 0 ? 0 : convertNaN(rearFacingSeatAmt),
      quantity: rearFacingSeatQuantity,
    },
    forwardFacingSeat: {
      label:
        forwardFacingSeatQuantity > 1
          ? `${forwardFacingSeatQuantity} X Forward-Facing Seat`
          : "Forward-Facing Seat",
      value: forwardFacingSeatAmt === 0 ? 0 : convertNaN(forwardFacingSeatAmt),
      quantity: forwardFacingSeatQuantity,
    },
    boosterSeat: {
      label:
        boosterSeatQuantity > 1
          ? `${boosterSeatQuantity} X Booster Seat`
          : "Booster Seat",
      value: boosterSeatAmt === 0 ? 0 : convertNaN(boosterSeatAmt),
      quantity: boosterSeatQuantity,
    },
    other: {
      label: otherName || "Other",
      value: convertNaN(otherPercent * baseRateAmt || otherAmt),
    },
    other2: {
      label: other2Name || "Other 2",
      value: convertNaN(other2Percent * baseRateAmt || other2Amt),
    },
    other3: {
      label: other3Name || "Other 3",
      value: convertNaN(other3Percent * baseRateAmt || other3Amt),
    },
    promoCode,
  };

  const totalAmt = convertNaN(
    pricing.baseRate.value +
      pricing.driverGratuity.value -
      pricing.promoDiscount.value +
      pricing.tax.value +
      pricing.tolls.value +
      pricing.meetGreet.value +
      pricing.boosterSeat.value +
      pricing.forwardFacingSeat.value +
      pricing.rearFacingSeat.value +
      pricing.other.value +
      pricing.other2.value +
      pricing.other3.value -
      pricing.promoCode.value
  );

  return {
    tripId: trip.id,
    pricing,
    totalAmt,
  };
};

export const adjustTripIndex = (pricings: CustomerPricingType[]) => {
  let currentIndex = 0;
  let previousHasReturn = false;
  return pricings.map((pricing) => {
    if (previousHasReturn) ++currentIndex;
    previousHasReturn = !!pricing.returnTripPricing;
    return {
      ...pricing,
      tripIndex: ++currentIndex,
    };
  });
};
