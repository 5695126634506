import React from "react";

import { Box, Typography } from "@mui/material";

import { UserIcon } from "../../../../../../design-system/icons";

type PassengerCountDisplayProps = {
  passengerCount: number;
  size?: "small";
};

function PassengerCountDisplay(props: PassengerCountDisplayProps) {
  const { passengerCount, size } = props;

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <UserIcon {...(size === "small" && { size })} />
      <Box ml={1}>
        <Typography>{passengerCount}</Typography>
      </Box>
    </Box>
  );
}

export default PassengerCountDisplay;
