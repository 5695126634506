export const createLinkedPassengerDefaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneFields: {
    mobilePhone: "",
    phoneCountryCode: "",
    phoneCountryDialCode: "",
    phoneCountryName: "",
    phoneCountryFormat: "",
  },
};
