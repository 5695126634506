import React from "react";

import { Box, Button, Typography } from "@mui/material";

import { grayLight, grayDark } from "design-system/colors";
import UnavailableCalendarIcon from "design-system/icons/info/UnavailableCalendarIcon";

function UnavailableButton() {
  return (
    <Box
      bgcolor={grayLight}
      borderRadius="4px"
      p={2}
      minWidth="240px"
      display="flex"
    >
      <Box bgcolor="rgba(237, 237, 237, 1)" display="flex" width="100%">
        <Button sx={{ bgcolor: "rgba(211, 211, 211, 0.5)" }} disabled fullWidth>
          <UnavailableCalendarIcon color={grayDark} size="small" />
          <Typography color={grayDark} fontWeight={500} ml={1.5}>
            Unavailable
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}

export default UnavailableButton;
