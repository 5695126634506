import React from "react";
import { Control, FormProvider, useForm, UseFormWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";

import { useCreateReservation } from "pages/new/hooks/useCreateReservation";
import { useCreateRequestContext } from "pages/new/context/useCreateRequestContext";
import CreateReservationFormFields from "./components/CreateReservationFormFields";
import { useAuth, useCurrentUser, useOperator } from "globals/hooks";
import {
  useAuthenticationChangeUpdate,
  useUnmountUpdate,
  useUpdateBookingContact,
} from "components/confirm/form/hooks";
import { useIsGratuityEnabled } from "components/confirm/form/hooks/useIsGratuityEnabled";
import { getCreateReservationFormDefaultValues } from "./form/defaultValues";
import {
  CreateReservationFormState,
  createReservationFormErrorSchema,
} from "./form/schemaValidation";
import { useConsentSignature } from "pages/new/hooks/useConsentSignature";
import ConsentSignatureDialog from "components/confirm/form/components/TermsAndConditionsBlock/components/ConsentSignatureDialog/ConsentSignatureDialog";
import { CreateTripState } from "pages/new/context/initialState";
import { useDisplayMeetGreet } from "components/confirm/form/hooks/useDisplayMeetGreet";
import { usePricelessBookingContext } from "../../context";
import { ConfirmReservationFormState } from "pages/order/components/ConfirmReservationForm/form/schemaValidation";
import { getIsNetflixLogin } from "utils/auth/isNetflixLogin";

type CreateReservationFormProps = {
  trips: CreateTripState[];
};

function CreateReservationForm(props: CreateReservationFormProps) {
  const { trips } = props;

  // contexts
  const [request] = useCreateRequestContext();
  const { isPricelessBooking } = usePricelessBookingContext();

  // hooks
  const {
    operator: {
      enableCreditCardWhenBooking,
      driverGratuityPresets,
      settings: { requireConsentWhenBooking, requireSignatureWhenBooking },
    },
  } = useOperator();
  const currentUser = useCurrentUser();
  const { authStage } = useAuth();
  const isGratuityEnabled = useIsGratuityEnabled({
    trips,
    mode: isPricelessBooking ? "priceless" : "create",
  });
  const consentSignature = useConsentSignature({ trips });

  const { displayMeetGreet } = useDisplayMeetGreet({
    isAirportPickUpStop:
      !!request.trip.stops[0].airport?.icaoCode ||
      !!request.returnTrip?.stops[0]?.airport?.icaoCode,
    mode: "create",
    createPickUpAddressIsAirport:
      request.trip.stops[0].pickUpGooglePlaceTypes?.includes("airport"),
  });

  // hooks continued
  const methods = useForm<CreateReservationFormState>({
    defaultValues: getCreateReservationFormDefaultValues(
      request.bookingContact
    ),
    mode: "onSubmit",
    resolver: yupResolver(createReservationFormErrorSchema),
    context: {
      isGratuityEnabled,
      minGratuityPct: first(driverGratuityPresets),
      isCreatingBookingContact: authStage !== "authenticated",
      isTermsRequired: requireConsentWhenBooking,
      isNewPaymentMethodRequired:
        enableCreditCardWhenBooking && isEmpty(currentUser?.paymentMethods),
      isExistingPaymentMethodRequired:
        enableCreditCardWhenBooking && !isEmpty(currentUser?.paymentMethods),
      isConsentSignatureRequired: requireSignatureWhenBooking,
      isPickUpVariantRequired: displayMeetGreet,
    },
  });

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    setError,
    formState: { errors: createReservationErrors },
  } = methods;

  const isNetflixLogin = getIsNetflixLogin();

  // update global state
  useUnmountUpdate({ watch });
  useUpdateBookingContact({ watch, getValues });
  useAuthenticationChangeUpdate({ getValues, setValue, isNetflixLogin });

  const { createReservation, isCreatingReservation } = useCreateReservation({
    setError,
    createReservationFormState: getValues(),
  });

  // scroll to the sections with errors
  const handleInvalidForm = (errors) => {
    if (errors.pickUpVariantSelected) {
      document
        .getElementById("meet-and-greet")
        ?.scrollIntoView({ behavior: "smooth" });
    } else if (errors.driverGratuityPctCustomerInput) {
      document
        .getElementById("driver-gratuity")
        ?.scrollIntoView({ behavior: "smooth" });
    } else if (errors.bookingContact) {
      document
        .getElementById("booking-contact")
        ?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        id={FORM_ID}
        noValidate
        onSubmit={(e) => {
          e.preventDefault();

          handleSubmit(() => {
            consentSignature.handleConsentSignatureDialogClose();
            createReservation(getValues());
          }, handleInvalidForm)(e);
        }}
      >
        <CreateReservationFormFields
          trips={trips}
          control={control}
          watch={watch}
          onConsentSignatureDialogOpen={
            consentSignature.handleConsentSignatureDialogOpen
          }
          isSubmitting={isCreatingReservation}
          formId={FORM_ID}
          setValue={setValue}
          createReservationErrors={createReservationErrors}
          getValues={getValues}
        />
        {/* Consent Signature Agreement */}
        {requireSignatureWhenBooking && (
          <ConsentSignatureDialog
            control={
              control as Control<
                CreateReservationFormState | ConfirmReservationFormState
              >
            }
            watch={
              watch as UseFormWatch<
                CreateReservationFormState | ConfirmReservationFormState
              >
            }
            setValue={setValue}
            formId={FORM_ID}
            isSubmitting={isCreatingReservation}
            isDialogOpen={consentSignature.consentSignatureDialogOpen}
            onClose={consentSignature.handleConsentSignatureDialogClose}
            consentSignature={consentSignature.consentSignature}
            userConsentAgreement={consentSignature.isAgreementChecked}
            onConsentSignatureChange={
              consentSignature.handleConsentSignatureChange
            }
            onUserConsentAgreementToggle={
              consentSignature.handleUserConsentAgreementToggle
            }
            selectedVehicleIds={consentSignature.selectedVehicleIds}
          />
        )}
      </form>
    </FormProvider>
  );
}

export default CreateReservationForm;

// constants
const FORM_ID = "create-reservation-form";
