import React from "react";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@mui/material";

import { Trip } from "../../../../types";
import AllTripsRow from "./AllTripsRow";
import { grayLight } from "../../../../design-system/colors";

type AllTripsTableProps = {
  trips: Trip[];
};

function AllTripsTable(props: AllTripsTableProps) {
  const { trips } = props;

  return (
    <Box border={`1px solid ${grayLight}`} borderRadius="4px">
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Trip Number</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Vehicle</TableCell>
              <TableCell>Driver</TableCell>
              <TableCell>Passenger</TableCell>
              <TableCell>Stops</TableCell>
              <TableCell>Map</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trips.map((trip) => (
              <AllTripsRow key={trip.id} trip={trip} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default AllTripsTable;
