import { ReservationFilterStatusVariant } from "../ReservationDropdownFilter";

import { Request, RequestStage } from "types";

export const getReservationFilterStatus = (request: Request) => {
  const { Declined, Cancelled, Confirmed, Unconfirmed } =
    ReservationFilterStatusVariant;

  let currentReservationStatus;

  if (request.isDeclined) {
    currentReservationStatus = Declined;
  } else if (!!request.cancelledAt) {
    currentReservationStatus = Cancelled;
  } else if (request.stage === RequestStage.Unconfirmed) {
    currentReservationStatus = Unconfirmed;
  } else {
    currentReservationStatus = Confirmed;
  }

  return currentReservationStatus;
};
