/**
 * @file TripPlannerBlock.tsx
 * Trip Planner section of driver dispatch view
 *
 * components:
 *  TripPlannerBlock
 *  StopItem
 */
import React from "react";
import moment from "moment-timezone";

import { Box, Typography, Divider, Card } from "@mui/material";

import FlightChangeDisplay from "components/globals/FlightChangeDisplay";
import RoundChip from "design-system/components/RoundChip";
import { PickUpVariant, Stop } from "types";
import { ClipboardIcon, TripIcon } from "design-system/icons";
import CircleButton from "design-system/components/CircleButton";
import { getGoogleMapsURL } from "globals/utils/helpers";
import {
  grayDark,
  moovsBlue,
  moovsBlueSelected,
  pickUpVariantColors,
  white,
} from "design-system/colors";
import FlightInfoCard from "components/FlightInfoCard/FlightInfoCard";
import { useOperator } from "globals/hooks";

type TripPlannerBlockProps = {
  stops: Stop[];
};

function TripPlannerBlock(props: TripPlannerBlockProps) {
  const { stops } = props;

  // hooks
  const { isElSegundoConnect } = useOperator();

  const getStopLabel = (stopIndex: number) => {
    // display stop numbers differently for moovs account 'el-segundo-connect'
    const stopNumber = {
      pickUpNum: isElSegundoConnect ? "Stop 1" : "Pick-up",
      dropOffNum: isElSegundoConnect ? `Stop ${stops.length}` : "Drop off",
      midStopDifference: isElSegundoConnect ? 0 : -1,
    };

    if (stopIndex === 1) return stopNumber.pickUpNum;
    if (stopIndex === stops.length) return stopNumber.dropOffNum;

    return `Stop ${stopIndex + stopNumber.midStopDifference}`;
  };

  return (
    <Box mb={2}>
      <Typography variant="h3">Trip Planner</Typography>
      <Card variant="outlined" sx={{ px: 2, mt: 2 }}>
        {stops?.map((stop: Stop) => {
          const isLastStop = stop.stopIndex === stops.length;

          return (
            <Box key={stop.id}>
              <StopItem stop={stop} label={getStopLabel(stop.stopIndex)} />
              {!isLastStop && <Divider />}
            </Box>
          );
        })}
      </Card>
    </Box>
  );
}

export default TripPlannerBlock;

type StopItemProps = {
  stop: Stop;
  label: string;
};

function StopItem(props: StopItemProps) {
  const {
    stop: {
      location,
      dateTime,
      originalDateTime,
      flightOffset,
      stopIndex,
      trackedFlight,
      pickUpVariant,
    },
    label,
  } = props;

  // derived state
  const isPickup = stopIndex === 1;
  const timeDisplay = dateTime ? moment.utc(dateTime).format("h:mm a") : "-";
  const isDateTimeSame =
    moment.utc(originalDateTime).diff(moment.utc(dateTime), "minutes") === 0;

  return (
    <Box display="flex" flexDirection="column" py={2}>
      <Box display="flex" flexDirection="row">
        <Box
          display="flex"
          flex="1"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column">
            <Box
              key={label}
              display="flex"
              flexDirection="row"
              alignItems="center"
              mr={2}
            >
              <Box display="flex">
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: 26,
                    height: 26,
                    marginRight: 1,
                    backgroundColor: moovsBlueSelected,
                    borderRadius: "50%",
                  }}
                >
                  <ClipboardIcon color={moovsBlue} size="small" />
                </Box>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              <Box display="flex" alignItems="center">
                <Typography
                  variant="overline"
                  sx={{
                    marginRight: 1,
                  }}
                >
                  {label}
                </Typography>
                {isPickup && pickUpVariant && (
                  <RoundChip
                    {...pickUpVariantColors[pickUpVariant]}
                    label={
                      pickUpVariant === PickUpVariant.MeetGreet
                        ? "MEET & GREET"
                        : "CURBSIDE"
                    }
                  />
                )}
              </Box>

              <Typography
                sx={{
                  lineHeight: "24px",
                  fontSize: 16,
                  fontWeight: "bold",
                  height: 16,
                  whiteSpace: "nowrap",
                }}
              >
                {isPickup && flightOffset && !isDateTimeSame ? (
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: "bold",
                        textDecorationLine: "line-through",
                        color: grayDark,
                        marginRight: "5px",
                      }}
                    >
                      {moment.utc(originalDateTime).format("h:mm A")}
                    </Typography>
                    <Box display="flex" alignItems="center">
                      <Typography
                        variant="body1"
                        style={{ fontWeight: "bold", marginRight: 4 }}
                      >
                        {moment.utc(dateTime).format("h:mm A")}
                      </Typography>
                      <FlightChangeDisplay
                        flightOffset={flightOffset}
                        originalDateTime={originalDateTime}
                        iconSize="small"
                      />
                    </Box>
                  </Box>
                ) : (
                  <Typography variant="body1" style={{ fontWeight: "bold" }}>
                    {timeDisplay}
                  </Typography>
                )}
              </Typography>
            </Box>
          </Box>
          <Box ml="33px" maxWidth="375px" pt={2}>
            <Typography variant="body2">{location}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" ml={2}>
          <Box>
            <CircleButton target="_blank" href={getGoogleMapsURL(location)}>
              <TripIcon color={white} />
            </CircleButton>
          </Box>
        </Box>
      </Box>
      {trackedFlight && (
        <Box pt={2}>
          <FlightInfoCard trackedFlight={trackedFlight} />
        </Box>
      )}
    </Box>
  );
}
