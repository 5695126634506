/**
 * Future improvement
 * - naming of this component is vague - should really be related to pricing
 */

import React from "react";
import isNil from "lodash/isNil";

import { Box, Divider } from "@mui/material";

import { CustomerPricingType } from "../../../../../../globals/hooks/utils";
import ActiveTripDetails from "./ActiveTripDetails";

type ActiveRoundTripProps = {
  activeTripPricing: CustomerPricingType;
};

function ActiveRoundTrip(props: ActiveRoundTripProps) {
  const { activeTripPricing } = props;

  const outboundPricingLineItems = Object.values(
    activeTripPricing.outboundTripPricing
  ).filter(({ value, selected }) => {
    return !isNil(value) || selected;
  });

  const returnTripPricingLineItems = Object.values(
    activeTripPricing.returnTripPricing
  ).filter(({ value, selected }) => {
    return !isNil(value) || selected;
  });

  return (
    <Box mb={4}>
      {/* outbound trip */}
      <ActiveTripDetails
        totalAmt={activeTripPricing.outboundTripTotalAmt}
        tripIndex={activeTripPricing.tripIndex || 1}
        pricingLineItems={outboundPricingLineItems}
      />

      <Box my={3}>
        <Divider />
      </Box>

      {/* return trip */}
      <ActiveTripDetails
        totalAmt={activeTripPricing.returnTripTotalAmt}
        tripIndex={activeTripPricing.tripIndex + 1 || 2}
        pricingLineItems={returnTripPricingLineItems}
      />
    </Box>
  );
}

export default ActiveRoundTrip;
