import React, { useEffect } from "react";
import { Control, SetFieldValue, UseFormWatch } from "react-hook-form";

import { Box } from "@mui/material";

import {
  useAnalytics,
  useAuth,
  useCurrentUser,
  useOperator,
  useScreenSize,
} from "../../../../../../globals/hooks";
import RHFInternationalPhoneInput from "../../../../../react-hook-form/RHFInternationalPhoneInput";
import { RHFTextField } from "../../../../../react-hook-form/text-field/RHFTextField";
import authApi from "utils/auth/authApi";
import { useIsWhiteLabel } from "pages/new/hooks";
import { getIsNetflixLogin } from "utils/auth/isNetflixLogin";

type BookingContactFormProps = {
  control: Control<any>;
  watch: UseFormWatch<any>;
  trackingFrom?: "reserve" | "quoteRequest";
  setValue: SetFieldValue<any>;
  getValues: any;
};

function BookingContactForm(props: BookingContactFormProps) {
  const { control, watch, trackingFrom, setValue, getValues } = props;
  const isNetflixLogin = getIsNetflixLogin();

  // hooks
  const currentUser = useCurrentUser();
  const { operator } = useOperator();
  const { isMobileView } = useScreenSize();
  const { onLoginDialogOpen } = useAuth();
  const bookingContact = watch("bookingContact");
  const isDisabledContactField = isNetflixLogin;
  const { isWhiteLabel } = useIsWhiteLabel();

  const { track } = useAnalytics();

  useEffect(() => {
    const bookingContact = getValues("bookingContact");

    if (
      isNetflixLogin &&
      currentUser?.email &&
      currentUser?.firstName &&
      currentUser?.lastName &&
      !bookingContact?.email?.length
    ) {
      setValue("bookingContact.email", currentUser.email);
      setValue("bookingContact.firstName", currentUser.firstName);
      setValue("bookingContact.lastName", currentUser.lastName);
    }
  }, [
    currentUser?.email,
    currentUser?.firstName,
    currentUser?.lastName,
    getValues,
    setValue,
    isNetflixLogin,
  ]);

  // event handlers
  const findExistingUserWithMobileInput = async () => {
    const { phoneFields } = bookingContact;

    if (isWhiteLabel || phoneFields.mobilePhone.length < 8) return;

    const { status } = await authApi.sendCode({
      mobilePhone: phoneFields,
      email: null,
      operatorSlug: operator.nameSlug,
    });

    // user exists
    if (status === 200) {
      onLoginDialogOpen({
        mobilePhone: phoneFields,
        verifyCodeText: "We found an account with your mobile number",
        verifyButtonText: "Login",
      });
    }
  };

  const findExistingUserWithEmailInput = async () => {
    const { email } = bookingContact;

    if (isWhiteLabel || !email) return;

    const { status } = await authApi.sendCode({
      email,
      mobilePhone: null,
      operatorSlug: operator.nameSlug,
    });

    // user exists
    if (status === 200) {
      onLoginDialogOpen({
        email,
        verifyCodeText: "We found an account with your email",
        verifyButtonText: "Login",
      });
    }
  };

  const onPhoneInputFocus = () => {
    if (trackingFrom) {
      track(`${trackingFrom}_phoneSelected`);
    }
  };

  const onEmailInputFocus = () => {
    if (trackingFrom) {
      track(`${trackingFrom}_emailSelected`);
    }
  };

  const onFirstNameInputFocus = () => {
    if (trackingFrom) {
      track(`${trackingFrom}_firstNameSelected`);
    }
  };

  const onLastNameInputFocus = () => {
    if (trackingFrom) {
      track(`${trackingFrom}_lastNameSelected`);
    }
  };

  return (
    <Box display="flex" flexDirection="column" mt={3} id="booking-contact">
      {/* mobile phone */}
      <Box mb={1}>
        <RHFInternationalPhoneInput
          name="bookingContact.phoneFields"
          control={control}
          onBlur={findExistingUserWithMobileInput}
          onFocus={onPhoneInputFocus}
        />
      </Box>
      {/* email */}
      <Box mb={1}>
        <RHFTextField
          required
          fullWidth
          label="Email"
          type="email"
          name="bookingContact.email"
          control={control}
          disabled={isDisabledContactField}
          onBlur={findExistingUserWithEmailInput}
          onFocus={onEmailInputFocus}
        />
      </Box>
      <Box
        display="flex"
        flexDirection={isMobileView ? "column" : "row"}
        mb={1}
      >
        {/* first name */}
        <Box
          mb={isMobileView ? 1 : undefined}
          mr={!isMobileView ? 1 : undefined}
          flex="1"
        >
          <RHFTextField
            required
            fullWidth
            label="First name"
            type="text"
            name="bookingContact.firstName"
            control={control}
            disabled={isDisabledContactField}
            onFocus={onFirstNameInputFocus}
          />
        </Box>

        {/* last name */}
        <Box flex="1">
          <RHFTextField
            required
            fullWidth
            label="Last name"
            type="text"
            name="bookingContact.lastName"
            control={control}
            disabled={isDisabledContactField}
            onFocus={onLastNameInputFocus}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default BookingContactForm;
