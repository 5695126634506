import React, { Fragment } from "react";
import { useQuery } from "@apollo/client";
import first from "lodash/first";
import last from "lodash/last";

import { Typography, Box, Divider, CircularProgress } from "@mui/material";

import { Airport, Vehicle } from "types";
import { LOAD_WHITE_LABEL_VEHICLES_QUERY } from "globals/graphql";
import WhiteLabelVehicleListItem from "./components/WhiteLabelVehicleListItem";

type WhiteLabelVehicleListProps = {
  totalGroupSize: number;
  stops: { location: string; airport?: Airport }[];
  vehicleId: string;
  onVehicleSelect: (vehicle: Vehicle) => () => void;
};

function WhiteLabelVehicleList(props: WhiteLabelVehicleListProps) {
  const { totalGroupSize, stops, vehicleId, onVehicleSelect } = props;

  // derived state
  const pickUpStop = first(stops);
  const dropOffStop = last(stops);

  // queries
  const { data, loading } = useQuery(LOAD_WHITE_LABEL_VEHICLES_QUERY, {
    skip: !totalGroupSize,
    variables: {
      totalGroupSize: totalGroupSize.toString(),
      pickUpStop: {
        location: pickUpStop.location,
        airport: pickUpStop.airport?.coordinates,
      },
      dropOffStop: {
        location: dropOffStop.location,
        airport: dropOffStop.airport?.coordinates,
      },
    },
  });

  return (
    <>
      <Box mt={5} mb={2}>
        <Typography variant="h2">Choose Vehicle</Typography>
      </Box>
      {loading && !data && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box mb={2}>Loading vehicles...</Box>
            <Box mb={2}>
              <CircularProgress />
            </Box>
          </Box>
          <Divider />
        </>
      )}
      {data?.loadWhiteLabelVehicles.length > 1
        ? data.loadWhiteLabelVehicles.map((vehicle) => {
            const isSelectedVehicle = vehicle.id === vehicleId;
            const buttonText = isSelectedVehicle
              ? "This vehicle is currently selected"
              : "Choose this vehicle";

            return (
              <Fragment key={vehicle.id}>
                <WhiteLabelVehicleListItem
                  vehicle={vehicle}
                  buttonText={buttonText}
                  onVehicleSelect={onVehicleSelect(vehicle)}
                />
                <Box mt={3}>
                  <Divider />
                </Box>
              </Fragment>
            );
          })
        : !loading && (
            <Fragment>
              <Typography>
                No Available Vehicles Match Request Criteria :(
              </Typography>
            </Fragment>
          )}
    </>
  );
}

export default WhiteLabelVehicleList;
