/**
 * @file SnackbarAlert.tsx
 * SnackbarAlert component for useSnackbar hook.
 *
 * reactComponents:
 *  SnackbarAlert
 *
 * author: jackv
 */

import React from "react";
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';

function SnackbarAlert({
  severity = "info",
  message,
  autoHideDuration,
  open,
  handleClose,
  handleExited,
  snackbarId,
}: {
  severity?: "success" | "error" | "info";
  message?: string;
  autoHideDuration?: number;
  open: boolean;
  handleClose: (event: any, reason?: any) => void;
  handleExited: () => void;
  snackbarId: string;
}) {
  const defaults = {
    success: {
      message: "Successfully updated!",
      autoHideDuration: 3000,
    },
    error: {
      message: "There was an error!",
      autoHideDuration: null,
    },
    info: {
      message: "",
      autoHideDuration: 3000,
    },
    warning: {
      message: "",
      autoHideDuration: 3000,
    },
  };

  return (
    <Snackbar
      key={snackbarId}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      autoHideDuration={autoHideDuration || defaults[severity].autoHideDuration}
      TransitionProps={{
        onExited: handleExited
      }}>
      <MuiAlert severity={severity} onClose={handleClose}>
        {message || defaults[severity].message}
      </MuiAlert>
    </Snackbar>
  );
}

export default SnackbarAlert;
