import { useQuery } from "@apollo/client";
import { LOAD_VEHICLES_QUERY } from "globals/graphql";
import map from "lodash/map";

import { CreateTripState } from "pages/new/context/initialState";
import { formatStopsForBRACalculations } from "pages/new/utils/formatStopsForBRACalculations";
import { TripCategory, Vehicle } from "types";

type UseLoadVehiclesProps = {
  trip: CreateTripState;
  returnTrip: CreateTripState;
  tripCategory: TripCategory;
};

function useLoadVehicles(props: UseLoadVehiclesProps) {
  const { trip, returnTrip, tripCategory } = props;

  const { vehicleStops, returnTripStops } = formatStopsForBRACalculations({
    trip,
    returnTrip,
  });

  // queries
  const { data, loading: isLoadingVehicles } = useQuery(LOAD_VEHICLES_QUERY, {
    fetchPolicy: "network-only", // set to ensure vehicles update for BRA cases
  });

  // second run, query for base rate
  const { loading: isLoadingBRA } = useQuery(LOAD_VEHICLES_QUERY, {
    skip: !data,
    variables: {
      tripType: tripCategory,
      stops: vehicleStops,
      returnTripStops: returnTripStops,
    },
    fetchPolicy: "network-only", // set to ensure vehicles update for BRA cases
  });

  const vehicles: Vehicle[] = map(data?.loadVehicles.edges, "node");

  return {
    vehicles,
    isLoadingVehicles,
    isLoadingBRA,
  };
}

export default useLoadVehicles;
