import React from "react";

import FlightIcon from "design-system/icons/info/FlightIcon";
import moment from "moment";
import { convertMinutesToHoursMinutes } from "utils/helpers";
import MoovsTooltip from "./MoovsTooltip";

type FlightChangeDisplayProps = {
  flightOffset: number;
  originalDateTime: Date;
  dateTime?: string;
  iconSize?: string;
};

function FlightChangeDisplay(props: FlightChangeDisplayProps) {
  const { flightOffset, originalDateTime, dateTime, iconSize } = props;

  const isDateTimeSame =
    moment.utc(dateTime).diff(moment.utc(originalDateTime), "minutes") === 0;

  if (!flightOffset || isDateTimeSame) return null;

  const flightOffsetOutput = convertMinutesToHoursMinutes(
    Math.abs(flightOffset)
  );
  const flightChangeText =
    flightOffset > 0
      ? `${flightOffsetOutput} late`
      : `${flightOffsetOutput} early`;
  const originalPickupTime = moment.utc(originalDateTime).format("h:mm a");

  const tooltipText = `Flight arriving ${flightChangeText}.`;
  const tooltipSubtext = `Original pick-up time: ${originalPickupTime}`;

  return (
    <MoovsTooltip
      tooltipText={tooltipText}
      tooltipSubtext={tooltipSubtext}
      showcaseMainText
      noMaxWidth
    >
      <FlightIcon size={iconSize} />
    </MoovsTooltip>
  );
}

export default FlightChangeDisplay;
