/**
 * @file useAuthenticatedChangeUpdate
 *
 * Updated bookingContact in forms when auth state changes.
 * We are setting the entire bookingContact as null if
 * we are instead using the logged in user.
 */
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";

import { useAuth, useCurrentUser } from "globals/hooks";
import { useEffect } from "react";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import { defaultPhoneNumber } from "../../../../globals/hooks/useCurrentUser";

type UseAuthenticationChangeUpdateParams = {
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  isNetflixLogin: boolean;
};

export const useAuthenticationChangeUpdate = (
  params: UseAuthenticationChangeUpdateParams
) => {
  const { getValues, setValue, isNetflixLogin } = params;

  const currentUser = useCurrentUser();
  const { loginData } = useAuth();

  useEffect(() => {
    const bookingContact = getValues("bookingContact");

    if (
      (currentUser?.firstName && isNull(bookingContact)) ||
      (!currentUser && !isNull(bookingContact)) ||
      (isNetflixLogin &&
        (!loginData.mobilePhone ||
          loginData.mobilePhone === defaultPhoneNumber))
    ) {
      return;
    }

    if (
      !isUndefined(currentUser) &&
      (!isNetflixLogin || loginData.mobilePhone !== defaultPhoneNumber)
    ) {
      setValue("bookingContact", null);
      setValue("paymentMethod", null);
    } else {
      setValue("bookingContact", {
        firstName: "",
        lastName: "",
        email: "",
        phoneFields: {
          mobilePhone: "",
          phoneCountryCode: "",
          phoneCountryDialCode: "",
          phoneCountryFormat: "",
          phoneCountryName: "",
        },
      });

      setValue("paymentMethod", {
        stripePaymentMethodId: null,
        cardholderName: "",
        creditCardError: null,
      });
    }
  }, [
    currentUser,
    getValues,
    setValue,
    isNetflixLogin,
    loginData?.mobilePhone,
  ]);
};
