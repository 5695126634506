import React, { createContext, useContext, ReactNode } from "react";

type PricelessBookingContextType = {
  isPricelessBooking: boolean;
};

const PricelessBookingContext = createContext<
  PricelessBookingContextType | undefined
>(undefined);

type PricelessBookingProviderProps = {
  children: ReactNode;
  isPricelessBooking: boolean;
};

export const PricelessBookingProvider = ({
  children,
  isPricelessBooking,
}: PricelessBookingProviderProps) => {
  return (
    <PricelessBookingContext.Provider value={{ isPricelessBooking }}>
      {children}
    </PricelessBookingContext.Provider>
  );
};

export const usePricelessBookingContext = () => {
  const context = useContext(PricelessBookingContext);
  if (context === undefined) {
    throw new Error(
      "usePricelessBookingContext must be used within a PricelessBookingProvider"
    );
  }
  return context;
};
