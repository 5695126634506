import React from "react";

import { Box, ToggleButton, Typography, useTheme } from "@mui/material";

import {
  white,
  grayDark,
  granite,
  black,
  green,
  errorRed,
} from "design-system/colors";
import { PickUpVariant } from "types";
import { currency } from "utils/helpers";

type PickUpVariantToggleButtonProps = {
  value: PickUpVariant;
  onClick: () => void;
  selected: boolean;
  label: number | string;
  subText: string;
  meetGreetAmt: number;
  error?: boolean;
};

function PickUpVariantToggleButton(props: PickUpVariantToggleButtonProps) {
  const { value, onClick, selected, label, subText, meetGreetAmt, error } =
    props;

  // hooks
  const theme = useTheme();

  const meetGreetHasAdditionalCharge = meetGreetAmt > 0;

  const selectedStyles = {
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: `${theme.palette.primary.light} !important`,
    boxShadow: "0px 4px 8px 0px #000000 8%",
    color: theme.palette.primary.main,
  };

  return (
    <Box>
      <ToggleButton
        disableRipple
        value={value}
        selected={false}
        onClick={onClick}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          border: `1px solid ${error ? errorRed : grayDark}`,
          borderRadius: "4px",
          backgroundColor: white,
          cursor: "pointer",
          marginBottom: "6px",
          "&:hover": {
            borderColor: black,
          },
          "&:focus": {
            borderWidth: "2px",
            borderColor: theme.palette.primary.main,
          },
          ...(selected && selectedStyles),
        }}
      >
        <Box display="flex" alignItems="start" flexDirection="column">
          <Typography
            sx={{
              fontWeight: 500,
              p: 1,
            }}
          >
            {label}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: granite,
              fontWeight: 400,
              wordWrap: "break-word",
              pb: 1,
              px: 1,
              textAlign: "left",
              fontSize: "11px",
            }}
          >
            {subText}
          </Typography>
        </Box>
        {meetGreetHasAdditionalCharge && label === "Curbside" && (
          <Typography sx={{ fontWeight: 600, color: green }}>
            Included
          </Typography>
        )}
        {meetGreetHasAdditionalCharge && label === "Meet & Greet" && (
          <Typography sx={{ fontWeight: 600, color: black }}>
            {currency(meetGreetAmt)}
          </Typography>
        )}
      </ToggleButton>
    </Box>
  );
}

export default PickUpVariantToggleButton;
