import first from "lodash/first";

import { useOperator } from "globals/hooks";
import { CreateTripState } from "pages/new/context/initialState";
import { Trip } from "types";

type UseIsGratuityEnabledParams =
  | {
      trips: CreateTripState[];
      mode: "create" | "priceless";
    }
  | {
      trips: Trip[];
      mode: "update";
    };

export const useIsGratuityEnabled = (params: UseIsGratuityEnabledParams) => {
  const { trips, mode } = params;

  // hooks
  const {
    operator: {
      settings: { pricingLayout },
      gratuityWhenBooking,
    },
  } = useOperator();

  // do not enable if operator has setting toggled off
  if (!gratuityWhenBooking) {
    return false;
  }
  // do not enable if pricing layout has default driver gratuity amounts
  if (
    mode === "create" &&
    (pricingLayout?.driverGratuityAmt || pricingLayout?.driverGratuityPercent)
  ) {
    return false;
  }

  // do not render if any trips have gratuity set by operator (this will only ever occur in update case)
  if (
    mode === "update" &&
    trips.some(({ routes }) => !!first(routes)?.pricing?.driverGratuityAmt)
  ) {
    return false;
  }

  // do not render if for priceless booking
  if (mode === "priceless") {
    return false;
  }

  return true;
};
