/**
 * Note - For round trips, Some actions of outbound trips will manipulate return trip stops.
 * Initially, we created a second instance of 'useFieldArray' for return trips to move, remove, and insert return trip stops as additional actions.
 * However, this is buggy with react-hook-form. Instead, we use 'setValue' to directly manipulate return trip stops.
 */
import React, { useEffect, useMemo, useState } from "react";
import isEqual from "lodash/isEqual";
import { DragDropContext, DragUpdate } from "react-beautiful-dnd";
import { useFormContext } from "react-hook-form";
import size from "lodash/size";

import { Box, Divider, Typography } from "@mui/material";

import { useAnalytics, useScreenSize } from "globals/hooks";
import { useSnackbar } from "globals/hooks/useSnackbar";
import {
  checkIfStopTimesAreInOrder,
  reorderStops,
} from "globals/utils/helpers";
import { RoundTripVariant, TripCategory } from "types";
import AddStopButton from "./AddStopButton";
import CreateRequestStopsBlockItem, {
  StopVariant,
} from "./CreateRequestStopsBlockItem/CreateRequestStopsBlockItem";
import {
  CreateStopState,
  getInitialStop,
} from "pages/new/context/initialState";
import { InfoStepState } from "../../context/InfoStepFormProvider";

type CreateRequestStopsBlockProps = {
  roundTripVariant: RoundTripVariant;
};

function CreateRequestStopsBlock(props: CreateRequestStopsBlockProps) {
  const { roundTripVariant } = props;

  const snackbar = useSnackbar();
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();

  // state
  const [reorderedStops, setReorderedStops] = useState<CreateStopState[]>(null);
  const [draggableIndexes, setDraggableIndexes] = useState<{
    sourceIndex: number;
    destinationIndex: number;
  }>(null);
  const [renderWarningForStop, setRenderWarningForStop] = useState<boolean[]>(
    []
  );

  // derived state
  const { header, formNameTripKey } = useMemo<{
    header: "Round Trip: Pick-Up" | "Round Trip: Return" | "";
    formNameTripKey: "trip" | "returnTrip";
  }>(() => {
    switch (roundTripVariant) {
      case RoundTripVariant.Outbound:
        return { header: "Round Trip: Pick-Up", formNameTripKey: "trip" };
      case RoundTripVariant.Return:
        return { header: "Round Trip: Return", formNameTripKey: "returnTrip" };
      default:
        return { header: "", formNameTripKey: "trip" };
    }
  }, [roundTripVariant]);

  const tripStopsKey:
    | "trip.stops"
    | "returnTrip.stops" = `${formNameTripKey}.stops`;
  const { watch, setValue, getValues } = useFormContext<InfoStepState>();
  const [stops, tripCategory] = watch([tripStopsKey, "trip.tripCategory"]);

  const isRoundTrip = tripCategory === TripCategory.RoundTrip;
  const isOutboundTripOfARoundTrip =
    isRoundTrip && roundTripVariant === RoundTripVariant.Outbound;

  // event handlers
  const handleCreateStop = () => {
    const stops = getValues(tripStopsKey);
    const newStopIndex = size(stops) - 1;
    const newStop = getInitialStop(newStopIndex);

    const newStops = stops
      .slice(0, newStopIndex)
      .concat(newStop)
      .concat(stops.slice(newStopIndex));

    setValue(
      tripStopsKey,
      newStops.map((stop, index) => ({
        ...stop,
        stopIndex: index + 1,
      }))
    );

    // if working with outbound trip stops and user has selected round trip variant, add stop to return trips.
    if (isOutboundTripOfARoundTrip) {
      const returnTripStops = getValues("returnTrip.stops");
      const newStopIndex = size(returnTripStops) - 1;
      const newReturnStop = getInitialStop(newStopIndex, newStop?.id);

      // insert a new stop at index 1 (bc we can only add outbound trip stops at 2nd to last index)
      const newReturnTripStops = returnTripStops
        .slice(0, 1)
        .concat(newReturnStop)
        .concat(returnTripStops.slice(1));

      setValue(
        "returnTrip.stops",
        newReturnTripStops.map((stop, index) => ({
          ...stop,
          stopIndex: index + 1,
        }))
      );
    }
    track("bookingTool_addStop");
  };

  const handleRemoveStop = (index: number) => () => {
    const stops = getValues(tripStopsKey);
    const removedStopId = stops[index].id;
    const newStops = stops.slice(0, index).concat(stops.slice(index + 1));

    setValue(tripStopsKey, newStops);

    // remove stop from return trip as well
    if (isOutboundTripOfARoundTrip) {
      const returnTripStops = getValues("returnTrip.stops");

      if (size(returnTripStops) <= 2) return;

      const matchingReturnTripStopIndex = returnTripStops.findIndex(
        ({ id }) => id === removedStopId
      );

      if (matchingReturnTripStopIndex === -1) return;

      const newReturnTripStops = returnTripStops
        .slice(0, matchingReturnTripStopIndex)
        .concat(returnTripStops.slice(matchingReturnTripStopIndex + 1));

      setValue("returnTrip.stops", newReturnTripStops);
    }
  };

  const handleDragEnd = (e: DragUpdate) => {
    const { destination, source } = e;
    if (!destination) return;

    const dateTimes = stops.map((stop) => stop.dateTime);

    // remove the source stop, then move it to the correct position
    const stopToMove = stops.splice(source.index, 1);
    const movedStops = stops
      .slice(0, destination.index)
      .concat(stopToMove)
      .concat(stops.slice(destination.index));

    // prevent dates from moving, and unlink all return trip stops
    const movedStopsWithOriginalDateTimes = movedStops.map((stop, index) => ({
      ...stop,
      dateTime: dateTimes[index],
    }));

    setValue(tripStopsKey, movedStopsWithOriginalDateTimes);
    setDraggableIndexes(null);
    setReorderedStops(null);
  };

  const handleDragUpdate = (e: DragUpdate) => {
    const { destination, source } = e;

    if (!destination) {
      setReorderedStops(null);
      setDraggableIndexes(null);
      return;
    }

    const nextStops = reorderStops(stops, source.index, destination.index);

    setReorderedStops(nextStops);
    setDraggableIndexes({
      sourceIndex: source.index,
      destinationIndex: destination.index,
    });
  };

  // show/hide stop time out of order warning
  useEffect(() => {
    const stopsInOrderArray = checkIfStopTimesAreInOrder(stops);

    if (!isEqual(stopsInOrderArray, renderWarningForStop)) {
      // if in previous render, any stop didn't have a warning and now does, render snackbar
      if (
        renderWarningForStop.some(
          (warning, index) => !warning && !!stopsInOrderArray[index]
        )
      ) {
        snackbar.warning("Date & times are not in order");
      }

      setRenderWarningForStop(stopsInOrderArray);
    }
  }, [setRenderWarningForStop, renderWarningForStop, snackbar, stops]);

  return (
    <DragDropContext onDragEnd={handleDragEnd} onDragUpdate={handleDragUpdate}>
      {/* Round Trip Header */}
      {header && (
        <Box mb={isMobileView ? 2 : 4}>
          <Typography variant="h2">{header}</Typography>
          <Divider sx={{ mt: 2 }} />
        </Box>
      )}

      {/* Stops */}
      {stops.map((stop, index) => {
        const { sourceIndex, destinationIndex } = draggableIndexes || {};
        let replacementStop;

        if (
          draggableIndexes &&
          reorderedStops &&
          destinationIndex !== sourceIndex
        ) {
          replacementStop = reorderedStops[index];
        }

        const stopVariant = (() => {
          switch (index) {
            case 0:
              return StopVariant.PICK_UP;
            case size(stops) - 1:
              return StopVariant.DROP_OFF;
            default:
              return StopVariant.MIDDLE;
          }
        })();

        return (
          <Box mb={2} key={stop.id}>
            <CreateRequestStopsBlockItem
              dndId={stop.id}
              stopVariant={stopVariant}
              formNameKey={formNameTripKey}
              showIncorrectStopOrderWarning={!!renderWarningForStop[index]}
              stopsArrayIndex={index}
              canRemoveStop={index !== 0 && index !== size(stops) - 1}
              onRemoveStop={handleRemoveStop(index)}
              roundTripVariant={roundTripVariant}
              // draggable props
              replacementStop={replacementStop}
              isSourceIndex={sourceIndex === index}
              isDestinationIndex={destinationIndex === index}
            />

            {index === size(stops) - 2 && (
              <AddStopButton onClick={handleCreateStop} />
            )}
          </Box>
        );
      })}
    </DragDropContext>
  );
}

export default CreateRequestStopsBlock;
