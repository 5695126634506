/**
 * @file graphql/tripReview.graphql.ts
 * queries/mutations for tripReview.
 *
 */

import { gql } from "@apollo/client";

export const CREATE_TRIP_REVIEW_MUTATION = gql`
  mutation CreateTripReview($input: CreateTripReviewInput!) {
    createTripReview(input: $input) {
      tripReview {
        id
        rating
      }
    }
  }
`;
