import React from "react";

import {
  Autocomplete,
  Box,
  TextField,
  Typography,
  Paper,
  List,
  Divider,
  Button,
  ListItem,
  useTheme,
} from "@mui/material";

import { white, grayLight } from "design-system/colors";
import { PlusIcon } from "design-system/icons";
import { SelectedPassenger } from "globals/hooks/useDefaultPassenger";

type SearchLinkedPassengerAutocompleteProps = {
  id: string;
  value: SelectedPassenger;
  passengers: SelectedPassenger[];
  handleSelectPassengerOnChange: (
    _: any,
    selectedLinkedPassenger: SelectedPassenger
  ) => void;
  selectPassengerErrorMessage: string;
  handleAddNewPassengerClick: () => void;
};

function SearchLinkedPassengerAutocomplete(
  props: SearchLinkedPassengerAutocompleteProps
) {
  const {
    id,
    value,
    passengers,
    handleSelectPassengerOnChange,
    selectPassengerErrorMessage,
    handleAddNewPassengerClick,
  } = props;

  // hooks
  const theme = useTheme();

  return (
    <Autocomplete
      value={value}
      fullWidth
      id={id}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
      options={passengers}
      onChange={handleSelectPassengerOnChange}
      renderInput={(params) => (
        <Box>
          <TextField {...params} label="Search For Passenger" />
          {selectPassengerErrorMessage && (
            <Typography variant="caption" color="error" sx={{ marginLeft: 1 }}>
              {selectPassengerErrorMessage}
            </Typography>
          )}
        </Box>
      )}
      PaperComponent={({ children }) => (
        <Paper>
          <Box px={2} pt={1}>
            <Typography sx={{ backgroundColor: white }} variant="overline">
              Add Linked Passenger
            </Typography>
          </Box>
          <List
            sx={{
              maxHeight: "200px",
              overflow: "scroll!important",
              paddingY: `0!important`,
            }}
          >
            {children}
          </List>
          <Divider sx={{ backgroundColor: grayLight }} />
          <Button
            onClick={handleAddNewPassengerClick}
            onMouseDown={(e) => e.preventDefault()}
            fullWidth
          >
            <Box display="flex" pt={1} pb={0.5}>
              <Box mr={1} mt={-0.2}>
                <PlusIcon size="small" color={theme.palette.primary.main} />
              </Box>
              <Typography fontWeight={500}> Add New Passenger</Typography>
            </Box>
          </Button>
        </Paper>
      )}
      renderOption={(props, passenger) => {
        return (
          <ListItem
            {...props}
            key={passenger.id}
            sx={{
              width: "97%",
              margin: "0 auto",
              "&.Mui-focused": {
                borderRadius: 1,
                width: "97%",
                margin: "0 auto",
              },
            }}
          >
            <Box p={0.5} display="flex" flexDirection="column">
              <Typography variant="subtitle2">
                {`${passenger.firstName} ${passenger.lastName}`}
              </Typography>
              <Typography variant="caption" sx={{ marginY: 0.5 }}>
                {passenger.email}
              </Typography>
              <Typography variant="caption">{passenger.mobilePhone}</Typography>
            </Box>
          </ListItem>
        );
      }}
    />
  );
}

export default SearchLinkedPassengerAutocomplete;
