import React from "react";

type ChildSeatBoosterIconProps = {
  color?: string;
  size?: "small";
};

const ChildSeatBoosterIcon = (props: ChildSeatBoosterIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.3481 4.59929C9.33341 7.33338 8.00008 5.00005 7.00008 6.00005C5.66675 7.66671 9.75866 7.00005 9.25948 9.13338L4.52787 8.34405C4.2634 8.29996 3.9921 8.32317 3.73894 8.41153C3.48578 8.4999 3.25888 8.65058 3.07915 8.84971C2.89941 9.04884 2.7726 9.29003 2.71039 9.55106C2.64818 9.8121 2.65257 10.0846 2.72315 10.3435L2.94375 11.1547C3.03621 11.4938 3.23765 11.7929 3.51694 12.006C3.79623 12.219 4.13783 12.3341 4.48898 12.3334H11.7606C12.1667 12.3343 12.558 12.1804 12.8549 11.9031C13.1518 11.6257 13.3321 11.2456 13.3591 10.84L13.3319 5.18671C13.3387 5.07772 13.3232 4.96848 13.2864 4.86568C13.2496 4.76287 12.8549 4.33333 12.8549 4.33333C12.8549 4.33333 12.3334 4 12.0001 4H12.0001C11.6667 4 10.5705 4 10.3481 4.59929Z"
          stroke={color || "#565656"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.522 6.89881C13.9999 10.9999 11.9999 7.49995 10.4999 8.99995C8.49994 11.4999 14.6378 10.4999 13.889 13.6999L6.79163 12.5159C6.39492 12.4498 5.98797 12.4846 5.60822 12.6172C5.22848 12.7497 4.88814 12.9757 4.61854 13.2744C4.34893 13.5731 4.15872 13.9349 4.06541 14.3265C3.97209 14.718 3.97867 15.1268 4.08455 15.5151L4.41544 16.7319C4.55414 17.2405 4.85629 17.6893 5.27523 18.0088C5.69416 18.3284 6.20657 18.501 6.73328 18.4999H17.6407C18.2499 18.5013 18.8368 18.2705 19.2821 17.8545C19.7275 17.4384 19.9979 16.8683 20.0385 16.2599L19.9976 7.77995C20.0078 7.61646 19.9846 7.4526 19.9295 7.29839C19.8743 7.14418 19.2821 6.49988 19.2821 6.49988C19.2821 6.49988 18.4999 5.99988 17.9999 5.99988H17.9999C17.4999 5.99988 15.8556 5.99988 15.522 6.89881Z"
        stroke={color || "#565656"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChildSeatBoosterIcon;
