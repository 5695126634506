import { useEffect, useState } from "react";

import { OrderType } from "types";

type UseFilteredOrderTypesProps = {
  enabledOrderTypes: OrderType[];
};

export const useFilteredOrderTypes = (props: UseFilteredOrderTypesProps) => {
  const { enabledOrderTypes } = props;

  // state
  const [filteredOrderTypes, setFilteredOrderTypes] = useState<OrderType[]>([]);

  // event handlers
  const handleFilterOrderType = (_, value: string) => {
    const searchOrderType = value;

    if (!searchOrderType) {
      setFilteredOrderTypes(enabledOrderTypes);
    } else {
      const filterOrderType = enabledOrderTypes.filter(
        (orderType) =>
          orderType.category
            .toLowerCase()
            .includes(searchOrderType.toLowerCase()) ||
          orderType.name.toLowerCase().includes(searchOrderType.toLowerCase())
      );
      setFilteredOrderTypes(filterOrderType);
    }
  };

  // set initial order types
  useEffect(() => {
    setFilteredOrderTypes(enabledOrderTypes);
  }, [enabledOrderTypes]);

  return { filteredOrderTypes, handleFilterOrderType };
};
