import React from "react";
import ReactDOM from "react-dom";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

import { Theme, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

declare module "@mui/material/styles" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

(async () => {
  /**
   * This is a temporary solution to persist ld flag across user sessions.
   */
  let value = { key: "anonymous", name: "operator", slug: "", plan: "" };

  try {
    const storedValue = window.localStorage.getItem("ldKey");
    value = storedValue ? JSON.parse(storedValue) : {};
  } catch (error) {
    console.error("Error reading localStorage key 'ldKey':", error);
  }

  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID as string,
    context: {
      kind: "operator",
      key: value.key || "",
      name: value.name || "",
      slug: value.slug || "",
      plan: value.plan || "",
    },
  });

  ReactDOM.render(
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <LDProvider>
        <App />
      </LDProvider>
    </StyledEngineProvider>,

    document.getElementById("root")
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
})();
